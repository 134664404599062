import React, { memo, useCallback, useMemo } from 'react';

import { Form, TimePicker as ATimePicker } from 'antd';
import { calculateHeight, cx } from 'utils';

import { ITimePickerProps } from './types';

import '../datePicker/styles.scss';

function TimePicker({
  className,
  label,
  rules,
  name,
  onChange,
  onChangeWithName,
  noForm,
  size,
  ...props
}: ITimePickerProps) {
  const classNames = cx('ccm-time-picker', className, {
    'ccm-time-picker--no-form': noForm,
    [`ccm-time-picker--${size}`]: size,
  });

  const handleOnChange = useCallback(
    (time: any, dateString: string) => {
      if (name && onChangeWithName) {
        onChangeWithName(name, time, dateString);
      }
    },
    [name, onChangeWithName]
  );

  const styles = useMemo(() => {
    return {
      height: `${calculateHeight(Boolean(label), Boolean(noForm), size)}px`,
    };
  }, [label, noForm, size]);

  if (noForm) {
    return (
      <ATimePicker
        style={styles}
        onChange={onChangeWithName ? handleOnChange : onChange}
        className={classNames}
        popupClassName="ccm-time-picker-dropdown"
        {...props}
      />
    );
  }

  return (
    <Form.Item
      style={styles}
      label={label}
      name={name}
      className={classNames}
      colon={false}
      labelAlign="left"
      rules={rules}
    >
      <ATimePicker
        onChange={onChangeWithName ? handleOnChange : onChange}
        className={classNames}
        popupClassName="ccm-time-picker-dropdown"
        {...props}
      />
    </Form.Item>
  );
}

export default memo(TimePicker);
