import { message, notification } from 'antd';
import { TFunction } from 'i18next';

import { importProgramFile } from '../../api/requests/project';
import { onGetAsyncStatusFile } from '../../api/requests/files';
import { checkMimeTypeImport } from '../../utils/import';
import Button from '../../components/button';
import { messageDurability } from '../../constants';
import i18n from '../../i18n';

const onCheckFileStatus = async (
  asyncFileJob: number,
  callback: () => Promise<any>,
  setLoading: (state: boolean) => void
) => {
  const response = await onGetAsyncStatusFile(asyncFileJob);
  if (response.state === 'in_progress') {
    setTimeout(() => onCheckFileStatus(asyncFileJob, callback, setLoading), 2000);
  } else {
    if (response?.state === 'rejected') {
      message.error(response?.state_message, messageDurability);
      setLoading(false);
    } else {
      callback()
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }
};

export const onImportProgramFile = async (
  t: TFunction,
  project_id: number,
  event: any,
  callback: () => Promise<any>,
  setLoading: (state: boolean) => void,
  showDrawer: () => void
) => {
  const selectedFile = event?.target?.files[0];
  const isAllowedFile = checkMimeTypeImport(selectedFile);
  const key = `open${Date.now()}`;
  const closeNotification = () => {
    notification.destroy(key);
    showDrawer();
  };
  if (isAllowedFile.isAllowed) {
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('project_id', project_id.toString());
    setLoading(true);
    await importProgramFile(project_id, formData)
      .then(async (resp: any) => {
        if (resp?.status === 401) {
          await importProgramFile(project_id, formData).then(async (response: any) => {
            await onCheckFileStatus(response?.async_task_id, callback, setLoading);
          });
        } else {
          await onCheckFileStatus(resp?.async_task_id, callback, setLoading);
        }
      })
      .catch((error) => {
        const errorTitle = i18n?.t<string>(`serverErrors.${error?.data?.error_code}.title`, {
          ...error?.data?.arguments,
        });
        const errorDescription = i18n?.t<string>(`serverErrors.${error?.data?.error_code}.description`, {
          ...error?.data?.arguments,
        });
        setLoading(false);
        notification.open({
          message: errorTitle,
          description: errorDescription,
          btn: (
            <Button onClick={closeNotification} size="small">
              {t<string>('pages.project.showTemplate')}
            </Button>
          ),
          type: 'error',
          key,
          onClose: close,
        });
      });
  } else {
    setLoading(false);
    message.error(t<string>('pages.project.importError'), messageDurability);
  }
};
