import React, { useCallback, useEffect, useRef } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';
import { createSeparateTaskMessage } from 'api/requests/projectTask';
import { executeScroll, executeScrollWithDelay } from 'utils';

import CommentPane from 'components/commentPane';

import useCommonContext from 'hooks/useCommonContext';
import useProjectTaskDiscussionsQuery from 'hooks/queries/useProjectTaskDiscussionsQuery';
import useOnScreen from 'hooks/useOnScreen';

import SeparateTaskDiscussion from './discussion';

import './styles.scss';

const CONTAINER = 'view-free-task-page__panel';

function SeparateTaskComments() {
  const { taskId } = useParams();
  const common = useCommonContext();
  const autoScroll = useRef<boolean>(false);
  const { discussions } = useProjectTaskDiscussionsQuery(taskId);
  const { discussionsRefetch } = useProjectTaskDiscussionsQuery(taskId);

  const [searchParams, setSearchParams] = useSearchParams();
  const issue = searchParams.get('issue');

  useEffect(() => {
    if (issue && discussions && !autoScroll.current) {
      const target = document.getElementById(issue || '');
      const container = document.getElementsByClassName(CONTAINER || '');

      if (target && container) {
        target.scrollIntoView({ block: 'start', behavior: 'smooth' });
        autoScroll.current = true;
      }
    }
  }, [discussions, issue, taskId]);

  const onCreateDiscussion = useCallback(
    async (text: string) => {
      const payload = {
        message: text,
      };
      await createSeparateTaskMessage(taskId || '', payload);
      await discussionsRefetch();
    },
    [discussionsRefetch, taskId]
  );

  return (
    <div className="separate-task-comments">
      <span className="separate-task-comments__label">{common.t<string>('pages.task.freeTask.comments.label')}</span>
      <div className="separate-task-comments__add mb_16">
        <CommentPane
          placeholder={common.t<string>('pages.task.freeTask.comments.newComment')}
          onSubmit={onCreateDiscussion}
        />
      </div>
      {discussions
        ?.filter((d) => d.parent_message === null)
        ?.map((discussion) => {
          return <SeparateTaskDiscussion key={discussion.id} item={discussion} />;
        })}
    </div>
  );
}

export default SeparateTaskComments;
