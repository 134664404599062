import React, { useRef } from 'react';

import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';

import 'gantt-task-react/dist/index.css';
import useParticipantsGanttData from '../../project/hooks/useParticipantsGanttData';

import GanttHead from './components/GanttHead';

import './styles.scss';
import GanttUser from './components/GanttUser';

import useRefDimensions from '../../../hooks/useRefDimensions';

const tasks: any[] = [
  {
    start: new Date('2022-04-17'),
    end: new Date('2022-08-25'),
    name: 'Idea',
    id: 'Task 0',
    type: 'task',
    progress: 45,
    isDisabled: true,
    styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
  },
  // {
  //   start: new Date(2022, 9, 1),
  //   end: new Date(2022, 11, 15),
  //   name: 'Idea',
  //   id: 'Task 2',
  //   type: 'task',
  //   progress: 45,
  //   isDisabled: true,
  //   styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d', listCellWidth: '80' },
  // },
];

type ParticipantsGanttProps = {
  companyId?: string;
  roleId?: string | null;
};

function ParticipantsGantt({ companyId, roleId }: ParticipantsGanttProps) {
  const { monthRange, users } = useParticipantsGanttData({ companyId, roleId });
  const headRef = useRef(null);
  const dimensions = useRefDimensions(headRef);

  return (
    <div className="participants-gantt">
      {/*<GanttHead headRef={headRef} monthRange={monthRange} />*/}

      {/*{users?.map((el) => {*/}
      {/*  return <GanttUser dimensions={dimensions} key={el.id} user={el} />;*/}
      {/*})}*/}

      {/*<Gantt*/}
      {/*  tasks={tasks}*/}
      {/*  preStepsCount={1}*/}
      {/*  viewMode={ViewMode.Month}*/}
      {/*  listCellWidth=""*/}
      {/*  locale="ru"*/}
      {/*  columnWidth={80}*/}
      {/*  rowHeight={20}*/}
      {/*  ganttHeight={500}*/}
      {/*/>*/}
    </div>
  );
}

export default ParticipantsGantt;
