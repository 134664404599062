import React from 'react';

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import modalService from 'services/modalService';

import { TitleVariantEnum } from 'components/title/types';
import Title from 'components/title/Title';
import Button from 'components/button';
import { ButtonTypesEnum } from 'components/button/types';
import Table from 'components/table';

import { getIconByUrl } from 'utils/icons';

import useCompanyArtefactTypes, { CompanyArtefactType } from 'hooks/queries/useCompanyArtefactTypes';
import useCompanyLearningObjectTypes from 'hooks/queries/useCompanyLearningObjectTypes';
import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

const ArtefactTypesPage = () => {
  const common = useCommonContext();
  const { loading, companyArtefactTypes, refetch } = useCompanyArtefactTypes();
  const { companyLOTAdminForSelect } = useCompanyLearningObjectTypes();

  const onAddArtType = () => {
    modalService.openCreateNewArtefactType({
      companyId: common.company?.id,
      optionsLO: companyLOTAdminForSelect,
      refetch,
    });
  };

  const columns = [
    {
      key: 'name',
      title: 'Name',
      render: (record: CompanyArtefactType) => record.name,
    },
    {
      key: 'company',
      title: 'Company',
      render: (record: CompanyArtefactType) => record.company.name,
    },
    {
      key: 'type',
      title: 'Type',
      render: (record: CompanyArtefactType) => record.type,
    },

    {
      key: 'lo',
      title: 'Learning object type',
      render: (record: CompanyArtefactType) => record.learning_object_type?.name,
    },
    {
      key: 'cob',
      title: 'COB artefact type',
      render: (record: CompanyArtefactType) => record.cob_artefact_type,
    },
    {
      key: 'order',
      title: 'Order',
      render: (record: CompanyArtefactType) => record.order,
    },
    {
      key: 'is_final',
      title: 'Is Final',
      render: (record: CompanyArtefactType) =>
        record.is_final ? (
          <CheckCircleOutlined style={{ color: '#65B129' }} />
        ) : (
          <CloseCircleOutlined style={{ color: '#ff4d4f' }} />
        ),
    },
    {
      key: 'icon',
      title: 'Icon',
      render: (record: CompanyArtefactType) => getIconByUrl(record.icon_image),
    },
  ];

  return (
    <div className="ms-artefact-types">
      <div className="ms-artefact-types__top">
        <Title variant={TitleVariantEnum.h4}>Artefact types</Title>
        <Button size="xl" type={ButtonTypesEnum.primary} onClick={onAddArtType}>
          New artefact type
        </Button>
      </div>
      <Table columns={columns} dataSource={companyArtefactTypes} loading={loading} pagination={false} />
    </div>
  );
};

export default ArtefactTypesPage;
