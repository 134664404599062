import { CcmUserState, CompanyUserTechnicalRole } from '../../schemas/types';
import { CompanyArtefactType } from '../models/CompanyArtefactType';

import { Company } from './company';

export enum BusinessRoleEnum {
  author = 'AUTHOR',
  authorManager = 'AUTHOR_MANAGER', //Руководитель авторского коллектива
  executiveManager = 'EXECUTIVE_MANAGER', //Руководитель производства
  manager = 'MANAGER', //Продюсер
  instructionalDesigner = 'INSTRUCTIONAL_DESIGNER', //Methodist
  learningTechnologist = 'LEARNING_TECHNOLOGIST',
  reviewer = 'REVIEWER',
  watcher = 'WATCHER',
  reader = 'READER',
  videoProductionExecutor = 'VIDEO_PRODUCTION_EXECUTOR',
  videoProductionManager = 'VIDEO_PRODUCTION_MANAGER', //Руководитель видеопроизводства
  productionExecutor = 'PRODUCTION_EXECUTOR', // Исполнитель
  stakeholder = 'STAKEHOLDER',
  admin = 'ADMIN',
  support = 'SUPPORT',
  support_manager = 'SUPPORT_MANAGER',
}

export enum TechnicalRoleEnum {
  ADMIN = 'ADMIN',
}

export interface CCMUser {
  id: string;
  first_name: string | null;
  last_name: string | null;
  email: string;
  email_validated: boolean;
  is_staff: boolean;
  is_active: boolean;
  created_at: string;
  updated_at: string;
}

export interface UserRole {
  id: string;
  name: string | null;
  company: Company;
  role: BusinessRoleEnum;
  state: CcmUserState;
}

export interface UserBase {
  id: string;
  email: string;
  name: string;
  first_name: string;
  icon_image?: string;
  last_name: string;
  timezone?: string;
  state: CcmUserState;
}

export interface UserExtended {
  id: string;
  pk: string;
  icon_image?: string;
  user: UserBase;
  company?: Company;
  technical_role?: CompanyUserTechnicalRole;
  business_roles: UserRoleBase[];
  isActive?: boolean;
  taskAmount?: number;
}

export interface UserExtendedSelect extends UserExtended {
  label: string;
  value: string;
}

export interface UserRoles {
  role: UserRoleBase;
}

export interface UserRoleBase {
  id: string;
  name: string;
  role: BusinessRoleEnum;
  pk?: string;
}

export interface CompanyUserBase {
  id: string;
  user: UserBase;
}

export interface CreatorBase {
  id: string;
  name: string;
}

export type CompanyUserItem = {
  id: string;
  is_active: boolean;
  roles: UserRoles[];
  company_user: {
    id: string;
    is_active?: boolean;
    company?: Company;
    user: UserBase;
    business_roles?: UserRoleBase[];
  };
};

export type ProjectTaskAssignees = {
  id?: string;
  project_task: {
    id: string;
  };
  is_current_executor: boolean;
  is_active: boolean;
  project_user: CompanyUserItem;
  by_artefact_role_assignment: {
    is_executor?: boolean;
    user_role: UserRoleBase;
  } | null;
};

export interface CompanyArtefactTypeId extends CompanyArtefactType {
  id: string;
}
