import { gql } from '@apollo/client';

export const GET_MATERIALS = gql`
  query materials($artefactId: Int!) {
    materials(artefact_id: $artefactId) {
      id
      artefact_type {
        id
        type
        name
        icon_image
      }
      current_file {
        id
        file_type
        state
        source_files {
          id
          source_url
          file_size
          external_source_url
          file_extension
        }
        related_files {
          ... on PresentationFileSlideType {
            id
            source_url
          }
          ... on ExternalUrlFileType {
            id
            url
          }
          ... on DocumentFileSectionType {
            id
            # source_url
          }
          ... on DocumentFileVersionType {
            id
            version
            original_data
            sections {
              id
              order
              level
              file {
                id
              }
              section_type
              parent_section {
                id
              }
              section_content
              external_id
            }
          }
          ... on VideoFileType {
            id
            source_url
            resolution
            is_source
          }
          ... on PresentationFileSlideType {
            id
            source_url
            is_active
            order
          }
        }
      }
    }
  }
`;
