import React, { memo, ReactNode } from 'react';

import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';

import Button from 'components/button';

import { cx } from '../../../utils';
import { ButtonTypesEnum } from '../../../components/button/types';

type SidebarHeaderProps = {
  collapsed?: boolean;
  onTrigger?: () => void;
  children?: ReactNode;
};

function SidebarHeader({ collapsed, onTrigger, children }: SidebarHeaderProps) {
  const classNames = cx('sidebar__trigger', {
    'testclass-sidebar-hide': !collapsed,
    'testclass-sidebar-show': collapsed,
  });
  return (
    <div className="sidebar__header">
      <div className="sidebar__header-content">{children}</div>
      <Button
        onClick={onTrigger}
        className={classNames}
        type={ButtonTypesEnum.text}
        size="large"
        icon={
          collapsed ? <DoubleRightOutlined style={{ fontSize: 20 }} /> : <DoubleLeftOutlined style={{ fontSize: 20 }} />
        }
      />
    </div>
  );
}

export default memo(SidebarHeader);
