import React, { memo, useCallback, useMemo } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { cx, declOfNum, declTitleDay, getFormatDateByStr, getFormattedStartEndByStr } from 'utils';
import { t } from 'i18next';
import { BusinessRoleEnum } from 'types/entities';

import PlanFactTag from 'components/tableTagDelay';
import AvatarGroup from 'components/avatarGroup';
import Tooltip from 'components/tooltip';
import { ProjectDeadlinesChangedPopup } from 'components/historyPopup';
import StatusLabelFlat from 'components/statusLabelFlat';
import Block from 'components/block';
import TagDelayWithFlag from 'components/tagDelayWithFlag';

import { getHealthIcon, getStageImageByType } from 'utils/icons';

import useCommonContext from 'hooks/useCommonContext';

import { DashboardCourseItem } from '../../dashboard/types';

import './styles.scss';

type CourseItemType = {
  item: DashboardCourseItem;
  onOpenDrawer: (projectId: string) => void;
  transparent?: boolean;
};

function CourseItem({ item, onOpenDrawer, transparent }: CourseItemType) {
  const common = useCommonContext();

  const onClick = useCallback(() => onOpenDrawer(item.pk), [item.pk, onOpenDrawer]);

  const startDate = getFormatDateByStr(item.production_start_date, 'MMM', '');
  const endDate = getFormatDateByStr(item.production_finish_date, 'MMM YYYY', '');
  const filteredUsers = item?.projectuser_set?.filter(
    (u) => u?.is_active && !u?.roles?.some((r) => r?.role?.role === BusinessRoleEnum.executiveManager)
  );
  const getTitle = useMemo(() => {
    if (item.metrics_maximium_artefact_delay === 0) {
      return 'pages.courses.drawer.noProblem';
    }
    if (
      item.metrics_maximium_artefact_delay &&
      item.metrics_maximium_artefact_delay >= 1 &&
      item.metrics_maximium_artefact_delay < 7
    ) {
      return 'pages.courses.drawer.minorProblems';
    }
    if (
      item.metrics_maximium_artefact_delay &&
      item.metrics_maximium_artefact_delay >= 7 &&
      item.metrics_maximium_artefact_delay < 30
    ) {
      return 'pages.courses.drawer.criticalProblem';
    }
    return 'pages.courses.drawer.fatalProblem';
  }, [item.metrics_maximium_artefact_delay]);
  const classNames = cx('dashboard-course-item', {
    'dashboard-course-item__transparent': transparent,
  });

  return (
    <div className={classNames} onClick={onClick}>
      <div className="dashboard-course-item__stage mr_12">
        <Tooltip
          title={`${common.t<string>('common.courseStage')}: ${
            item?.phase_state?.name || common.t<string>('common.noPhaseState')
          }`}
        >
          <div>{getStageImageByType(item?.phase_state?.icon_image)}</div>
        </Tooltip>
      </div>
      <div className="dashboard-course-item__name mr_8">{item.name}</div>
      <div className="dashboard-course-item__avatars mr_32">
        <AvatarGroup users={filteredUsers} hasPopover />
      </div>
      <div className="dashboard-course-item__period flex-row flex-align-items--center">
        <Tooltip
          title={getFormattedStartEndByStr(item.production_start_date, item.production_finish_date, 'DD MMM YYYY')}
        >
          <span className="mr_4">
            {startDate} — {endDate}
          </span>
        </Tooltip>
        {item?.metrics_project_changed_deadlines_exist && (
          <ProjectDeadlinesChangedPopup
            projectId={item.pk}
            icon={<InfoCircleOutlined style={{ color: '#E29416' }} />}
          />
        )}
      </div>
      <div className="dashboard-course-item__tasks">
        <Tooltip title={common.t<string>('pages.dashboard.courses.allAndTotalTasks')}>
          {item.metrics_total_completed_tasks || 0} {common.t<string>('pages.dashboard.courses.from')}{' '}
          {item.metrics_total_tasks || 0} {common.t<string>('pages.dashboard.courses.tasks')}
        </Tooltip>
      </div>
      <div className="dashboard-course-item__deadline">
        <div className="mr_8">
          <Tooltip title={common.t<string>('pages.dashboard.courses.overdueTasks')}>
            <span>
              <Block hidden={item?.metrics_missing_deadlines <= 0}>
                <TagDelayWithFlag
                  delay={item?.metrics_maximium_artefact_delay || 0}
                  text={(item?.metrics_missing_deadlines || 0).toString()}
                  noFlag
                />
              </Block>
            </span>
          </Tooltip>
        </div>
        <div>
          <Tooltip title={common.t<string>('pages.dashboard.courses.maximumDelay')}>
            <span>
              <Block
                className="dashboard-course-item__max-delay-span"
                hidden={item?.metrics_maximium_artefact_delay <= 0}
              >
                <PlanFactTag
                  plan={item.metrics_completed_tasks_plan || 0}
                  fact={item.metrics_completed_tasks_fact || 0}
                  delay={item.metrics_maximium_artefact_delay || 0}
                  text={`${item.metrics_maximium_artefact_delay || 0} ${t<string>(
                    declOfNum(item.metrics_maximium_artefact_delay || 0, declTitleDay)
                  )}`}
                  flat
                />
              </Block>
            </span>
          </Tooltip>
        </div>
      </div>
      <div className="dashboard-course-item__course-date flex-row flex-align-items--center">
        <span className="">{getFormatDateByStr(item.last_updated)}</span>
      </div>
      <div className="dashboard-course-item__progress flex-row flex-align-items--center">
        <Tooltip title={common.t<string>('pages.dashboard.courses.contentProgress')}>
          <span>{`${Math.ceil(item.production_progress)}%`}</span>
        </Tooltip>
      </div>

      <div className="dashboard-course-item__state">
        <Tooltip title={`${common.t<string>('pages.dashboard.courses.courseStatus')}${item?.state?.name}`}>
          <span>
            <StatusLabelFlat type={item?.state?.state_type} onlyIco />
          </span>
        </Tooltip>
      </div>

      <div className="dashboard-course-item__health">
        <Tooltip
          placement="topRight"
          title={common.t<string>('pages.dashboard.courses.minorIssues', {
            issue: common.t<string>(getTitle).toLowerCase(),
          })}
        >
          <div className="dashboard-course-item__health">{getHealthIcon(item.metrics_maximium_artefact_delay)}</div>
        </Tooltip>
      </div>
    </div>
  );
}

export default memo(CourseItem);
