import React, { useState } from 'react';

import { DropdownCheckboxList } from '../../../components/filters';

const data = [
  {
    id: '1',
    label: 'menu item 1',
    value: '1',
  },
  {
    id: '2',
    label: 'menu item 2',
    value: '2',
  },
  {
    id: '3',
    label: 'menu item 3',
    value: '3',
  },
  {
    id: '4',
    label: 'menu item 4',
    value: '4',
  },
  {
    id: '5',
    label: 'menu item 5',
    value: '5',
  },
];

const DropdownCheckboxStories = () => {
  const [state, setState] = useState<string[]>([]);

  const handleChange = (values: string[]) => setState(values);

  return (
    <div>
      <DropdownCheckboxList
        className="mb_16"
        placeholder="Large"
        size={'large'}
        name="test1"
        value={state}
        list={data}
        onChange={handleChange}
      />
      <DropdownCheckboxList
        disabled
        className="mb_16"
        placeholder="Large"
        size={'large'}
        name="test1"
        value={state}
        list={data}
        onChange={handleChange}
      />
      <DropdownCheckboxList
        className="mb_16"
        placeholder="Middle"
        size={'middle'}
        name="test1"
        value={state}
        list={data}
        onChange={handleChange}
      />
      <DropdownCheckboxList
        className="mb_16"
        placeholder="Small"
        size={'small'}
        name="test1"
        value={state}
        list={data}
        onChange={handleChange}
      />
    </div>
  );
};

export default DropdownCheckboxStories;
