import React, { memo, useCallback } from 'react';

import TruncateMarkup from 'react-truncate-markup';
import { FieldNumberOutlined } from '@ant-design/icons';
import { getFormatDateByStr, projectTaskAssigneesToCompanyUserItem } from 'utils';
import { generatePath } from 'react-router-dom';
import { PATH_NAMES } from 'router/constants';
import { ProjectSeparateTaskItem } from 'types/entities/separate';

import Tooltip from 'components/tooltip';
import AvatarGroup from 'components/avatarGroup';
import TagDelayWithFlag from 'components/tagDelayWithFlag/TagDelayWithFlag';
import ArtefactDeadlinesChanged from 'components/historyPopup/artefactDeadlinesChanged/ArtefactDeadlinesChanged';
import { ProjectStatusChangedPopup } from 'components/historyPopup';
import StatusLabelFlat from 'components/statusLabelFlat/StatusLabelFlat';

import { getIconByUrl } from 'utils/icons';

import useCommonContext from 'hooks/useCommonContext';
import useGlobalActionsContext from 'hooks/useGlobalActionsContext';

type GlobalTasksItemProps = {
  item: ProjectSeparateTaskItem;
};

function SeparateTasksItem({ item }: GlobalTasksItemProps) {
  const common = useCommonContext();
  const { data: actionsData, setData } = useGlobalActionsContext();

  const iterationCount = item.separate_task.cached_artefact_iteration_count;
  const convertAssignees = item.current_assignees.map((a) => projectTaskAssigneesToCompanyUserItem(a));

  const iterationDeadline = item?.separate_task?.deadline_first_iteration_review;
  const iterationDeadlineDelay = item.separate_task.first_iteration_delay_duration_summary_days;
  const iterationDeadlineChanged = item.separate_task.first_iteration_deadline_changed;

  const productionDeadline = item.separate_task?.deadline_production;
  const productionDeadlineDelay = item.separate_task.delay_duration_summary_days;
  const productionDeadlineChanged = item.separate_task?.production_deadline_changed;

  const onNavigateToTask = useCallback(() => {
    const path = generatePath(PATH_NAMES.separateTask.base, {
      taskId: item?.id,
    });
    setData({ ...actionsData, prevUrl: window.location.pathname });
    common.navigate(path);
  }, [actionsData, common, item?.id, setData]);

  return (
    <div id={item.id} onClick={onNavigateToTask} className="global-task-item">
      <div className="global-task-item__art-type">
        <Tooltip placement="top" title={item?.separate_task?.task_type?.name}>
          <div>{getIconByUrl(item?.separate_task?.task_type?.icon_image)}</div>
        </Tooltip>
      </div>
      <TruncateMarkup lines={1}>
        <span className="global-task-item__title">{item.name}</span>
      </TruncateMarkup>
      <div className="global-task-item__iteration">
        <Tooltip
          className="flex-row flex-align-items--center"
          title={common.t<string>('pages.dashboard.tooltips.iteration')}
        >
          <FieldNumberOutlined style={{ fontSize: 16, color: '#848484' }} />
          <span className="global-task-item__iteration-value">{iterationCount || '-'}</span>
        </Tooltip>
      </div>
      <div className="global-task-item__users">
        <AvatarGroup users={convertAssignees} hasPopover maxPopoverTrigger="hover" />
      </div>
      <div className="global-task-item__deadline">
        {iterationDeadline ? (
          <TagDelayWithFlag
            filled={false}
            text={iterationDeadline}
            delay={iterationDeadlineDelay}
            tooltip={`${common.t<string>('pages.dashboard.tooltips.iterationDeadline')} ${iterationDeadlineDelay}`}
            extraText={
              iterationDeadlineDelay > 0 ? `${iterationDeadlineDelay}${common.t<string>('common.shortDay')}` : null
            }
          />
        ) : null}
        {iterationDeadlineChanged ? (
          <ArtefactDeadlinesChanged isSeparate separateTaskId={item.id} deadlineType="first_iteration_deadline" />
        ) : null}
      </div>
      <div className="global-task-item__deadline global-task-item__deadline-second">
        {productionDeadline ? (
          <TagDelayWithFlag
            filled={false}
            text={productionDeadline}
            delay={productionDeadlineDelay}
            tooltip={`${common.t<string>('pages.dashboard.tooltips.finalDeadline')} ${productionDeadlineDelay}`}
            extraText={
              productionDeadlineDelay > 0 ? `${productionDeadlineDelay}${common.t<string>('common.shortDay')}` : null
            }
          />
        ) : null}
        {productionDeadlineChanged ? (
          <ArtefactDeadlinesChanged isSeparate separateTaskId={item.id} deadlineType="artefact_production_deadline" />
        ) : null}
      </div>
      <div className="global-task-item__status-change">
        <Tooltip
          className="flex-row flex-align-items--center"
          title={common.t<string>('pages.dashboard.tooltips.lastChanged')}
        >
          <span className="global-task-item__status-change-value">
            {getFormatDateByStr(item?.last_state_updated_at)}
          </span>
          {item?.last_state_is_changed && (
            <ProjectStatusChangedPopup isSeparate projectId={item.project.id} projectTaskId={item?.id} />
          )}
        </Tooltip>
      </div>
      <div className="global-task-item__status">
        <StatusLabelFlat type={item?.last_state?.state_type} name={item?.last_state?.name} />
      </div>
    </div>
  );
}

export default memo(SeparateTasksItem);
