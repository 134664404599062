export const messageDurability = 8;

export const DEV_AMAZON_API_URL = 'https://dev.cftry.me';
export const DEV_API_URL = 'https://dev-ccm.xpdev.net'; //RU dev
export const DEV_ENG_URL = 'https://eng-dev-ccm.xpdev.net';
//export const DEV_RU_API_URL = 'https://dev-ccm.xpdev.net';
export const DEMO_API_URL = 'https://demo.cftry.me';
export const DEMO_RU_API_URL = 'https://demo.edufactory.ru';
export const PROD_RU_API_URL = 'https://app.edufactory.ru';
export const PROD_API_URL = 'https://app.coursefactory.net';
export const PROD_TEST = 'https://test.cftry.me';
export const PROD_CONSTRUCTOR = 'https://workplace.cf.constructor.app';
