import { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { AdminCompanyLearningObjectType, ExportVideoParams } from 'types';
import { NodeModel } from '@minoru/react-dnd-treeview/dist/types';

import { tempLoader } from 'utils/dev';

import useCommonContext from 'hooks/useCommonContext';
import useBoolean from 'hooks/useBoolean';

import { textCourseOptions, videoResolutionOptions } from '../components/downloadDrawer/constants';
import { getExportOneFile } from '../components/downloadDrawer/fetches';
import { CustomData } from '../../../components/projectContentTree/types';

type SingleExportProps = {
  projectId?: string;
  node: NodeModel<CustomData>;
  onCloseExport: () => void;
};

function useSingleExport({ projectId, node, onCloseExport }: SingleExportProps) {
  const [opened, setOpened] = useState<boolean>(false);
  const [video, setVideo] = useState(videoResolutionOptions[2]);
  const [doc, setDoc] = useState(textCourseOptions[0].value);
  const [loading, onLoading] = useBoolean(false);
  const [coping, onCoping] = useBoolean(false);
  const common = useCommonContext();

  // Effects
  useEffect(() => {
    const loaderUid = tempLoader(loading, common.t<string>('common.preparingFiles'));
    return () => message.destroy(loaderUid);
  }, [loading]);

  useEffect(() => {
    const loaderUid = tempLoader(coping, common.t<string>('common.preparingLink'));
    return () => message.destroy(loaderUid);
  }, [coping]);

  const closeMessage = useCallback(() => {
    message.info({
      content: <>{common.t('common.downloadStarted')}</>,
      icon: null,
    });
  }, [common]);

  const onOpenChange = useCallback((open: boolean) => {
    setOpened(open);
  }, []);

  const onChangeVideo = useCallback((value: string, option: any) => {
    setVideo(option);
  }, []);

  const onChangeDocFormat = useCallback((e: RadioChangeEvent) => {
    setDoc(e.target.value);
  }, []);

  const onExport = useCallback(async () => {
    onLoading.on();
    let payload = {};

    if (node?.data?.learningObjectType?.type === AdminCompanyLearningObjectType.type.VIDEO) {
      payload = {
        file_extension: ExportVideoParams.file_extension.MP4,
        resolution: video.value,
      };
    }

    if (
      node?.data?.learningObjectType?.type === AdminCompanyLearningObjectType.type.TEXT ||
      node?.data?.learningObjectType?.type === AdminCompanyLearningObjectType.type.TASK
    ) {
      payload = {
        file_extension: doc,
      };
    }

    await getExportOneFile(projectId || '', node.id.toString() || '', payload, closeMessage, onLoading.off, common);
    onCloseExport();
  }, [
    closeMessage,
    common,
    doc,
    node?.data?.learningObjectType?.type,
    node.id,
    onCloseExport,
    onLoading,
    projectId,
    video.value,
  ]);

  const onCopyExportToClipboard = useCallback(async () => {
    onCoping.on();
    let payloadClipboard = {};

    if (node?.data?.learningObjectType?.type === AdminCompanyLearningObjectType.type.VIDEO) {
      payloadClipboard = {
        file_extension: ExportVideoParams.file_extension.MP4,
        resolution: video.value,
      };
    }

    if (
      node?.data?.learningObjectType?.type === AdminCompanyLearningObjectType.type.TEXT ||
      node?.data?.learningObjectType?.type === AdminCompanyLearningObjectType.type.TASK
    ) {
      payloadClipboard = {
        file_extension: doc,
      };
    }
    await getExportOneFile(
      projectId || '',
      node.id.toString() || '',
      payloadClipboard,
      closeMessage,
      onCoping.off,
      common,
      true
    );
    onCloseExport();
  }, [closeMessage, node.id, onCloseExport, onCoping, projectId, video.value]);
  return {
    video,
    doc,
    loading,
    opened,
    onExport,
    onCopyExportToClipboard,
    onOpenChange,
    onChangeVideo,
    onChangeDocFormat,
  };
}

export default useSingleExport;
