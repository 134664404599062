import { gql } from '@apollo/client';

export const GET_ARTEFACT_FLOW_STEPS = gql`
  query ArtefactFlowSteps(
    $company_artefact_type_id: Int
    $initiation_state_id: Int
    $company_state_list_version_id: Int
    $required_user_role_ids: [Int]
  ) {
    artefact_flow_steps(
      company_artefact_type_id: $company_artefact_type_id
      initiation_state_id: $initiation_state_id
      company_state_list_version_id: $company_state_list_version_id
      required_user_role_ids: $required_user_role_ids
    ) {
      edges {
        node {
          pk
          id
          company_artefact_type {
            id
            company {
              id
              name
            }
            name
            type
          }
          state_initiation {
            id
            state_type
            name
            button_name
          }
          artefactflowstepnext_set {
            user_role {
              id
              name
              role
            }
            state_next {
              state_initiation {
                id
                state_type
                name
                button_name
                order
                is_require_discuss_message
                is_could_close_without_complete_issues
              }
            }
            order
          }
          possible_next_steps {
            user_role {
              id
              name
              role
            }
            state_next {
              state_initiation {
                id
                state_type
                name
                button_name
                order
                is_require_discuss_message
                is_could_close_without_complete_issues
              }
            }
            order
          }
          artefactflowsteppipelinestepsequencerule_set {
            id
            set_state {
              id
              state_type
            }
          }
        }
      }
    }
  }
`;
