import React, { memo, useCallback } from 'react';

import { EdgeProps, getSmoothStepPath, EdgeLabelRenderer, BaseEdge, useReactFlow, useStoreApi } from 'reactflow';

import InputNum from 'components/inputNum';

export type EdgeData = {
  order: string;
};

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  source,
  target,
  data,
}: EdgeProps) => {
  const { setEdges } = useReactFlow();
  const store = useStoreApi();
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onChange = useCallback(
    (value: number | string | null) => {
      const { edges } = store.getState();
      setEdges(
        edges.map((edge) => {
          if (edge.source === source && edge.target === target) {
            edge.data = {
              ...edge.data,
              order: value,
            };
          }

          return edge;
        })
      );
    },
    [setEdges, source, store, target]
  );

  return (
    <>
      <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            background: '#E9E9E9',
            padding: 4,
            borderRadius: 5,
            pointerEvents: 'all',
          }}
          className="nodrag nopan"
        >
          <InputNum name="order" size="small" placeholder="Order" value={data?.order} onChange={onChange} />
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default memo(CustomEdge);
