import React from 'react';

import { noop } from 'lodash';
import { Form } from 'antd';
import { convertArrToSelectOptions, cx } from 'utils';

import HeaderModal from 'components/modal/components/header';
import FooterModal from 'components/modal/components/footer';
import Select from 'components/select';

import useBoolean from 'hooks/useBoolean';
import { CompanyArtefactType } from 'hooks/queries/useCompanyArtefactTypes';

type Props = {
  companyArtefactTypes: CompanyArtefactType[];
  onSubmit: (artId: string) => Promise<any>;
  onCancel: () => void;
};

type FormValues = {
  type: string;
};

const AddArtefactTypeToPipeline = ({ companyArtefactTypes, onSubmit, onCancel }: Props) => {
  const [loading, onLoading] = useBoolean(false);

  const classNames = cx('ccm-modal--wrapper');

  const options = convertArrToSelectOptions<CompanyArtefactType>(companyArtefactTypes);

  const onClickSubmit = (values: FormValues) => {
    onSubmit(values.type).then(() => onCancel());
  };

  return (
    <div style={{ width: 520 }} className={classNames}>
      <HeaderModal isSmallTitle title="Add artefact type" />
      <Form disabled={loading} name="new-list" onFinish={onClickSubmit}>
        <Select name="type" size="large" placeholder="Select artefact" options={options} />
        <FooterModal
          loading={loading}
          labelCancel="Cancel"
          labelSubmit="Save"
          onCancel={onCancel}
          onSubmit={noop}
          isForm
        />
      </Form>
    </div>
  );
};

export default AddArtefactTypeToPipeline;
