import React, { memo } from 'react';

import { Segmented as ASegmented } from 'antd';
import { SegmentedLabeledOption } from 'antd/lib/segmented';

import { cx } from '../../utils';

import './styles.scss';

type SegmentedProps = {
  className?: string;
  value?: string | number;
  options: SegmentedLabeledOption[];
  onChange?: (value: string | number) => void;
};

function Segmented({ className, options, value, onChange }: SegmentedProps) {
  const classNames = cx('ccm-segmented', className);
  return <ASegmented className={classNames} options={options} value={value} onChange={onChange} />;
}

export default memo(Segmented);
