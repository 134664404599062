import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { BusinessRoleEnum, UserBase, UserRoleBase, UserRoles } from 'types/entities/user';
import { GET_PROJECT_USER_INFO } from 'queries/getProjectUserInfo';

type ProjectUserData = {
  project_user: {
    id: string;
    roles: UserRoles[];
    company_user: {
      id: string;
      user: UserBase;
    };
  };
};

function useProjectUser(userId?: number, projectId?: string | null, fetchPolicy?: WatchQueryFetchPolicy) {
  const { data, loading } = useQuery<ProjectUserData>(GET_PROJECT_USER_INFO, {
    variables: {
      userId: Number(userId),
      projectId: Number(projectId),
    },
    skip: !projectId || !userId,
    fetchPolicy,
  });

  //TODO ROLES

  const userRole: BusinessRoleEnum | null = useMemo(
    () => data?.project_user?.roles[0]?.role?.role || null,
    [data?.project_user?.roles]
  );
  const userRoleId: string | null = useMemo(
    () => data?.project_user?.roles[0]?.role?.id || null,
    [data?.project_user?.roles]
  );
  return useMemo(() => {
    return {
      projectUser: data?.project_user,
      userRole,
      userRoles: data?.project_user?.roles || null,
      userRoleId,
      isProductExecutor: !!data?.project_user?.roles?.find(
        (r) => r?.role?.role === BusinessRoleEnum.productionExecutor
      ),
      isMethodist: !!data?.project_user?.roles?.find((r) => r?.role?.role === BusinessRoleEnum.instructionalDesigner),
      isAuthor: !!data?.project_user?.roles?.find((r) => r?.role?.role === BusinessRoleEnum.author),
      loadingProjectUser: loading,
    };
  }, [data?.project_user, loading, userRole, userRoleId]);
}

export default useProjectUser;
