import React, { memo } from 'react';

import { cx, getUrlToTask } from 'utils';
import { LoadingOutlined } from '@ant-design/icons';
import { generatePath, Link } from 'react-router-dom';
import TruncateMarkup from 'react-truncate-markup';
import { PATH_NAMES } from 'router/constants';

import Tag from 'components/tag/Tag';
import { TagShapeEnum, TagSizeEnum } from 'components/tag/types';

import { getIconByUrl } from 'utils/icons';

import { ArtefactDeadlinesChangedItem, ComposeArtefactDelaysProdDeadline } from 'hooks';

import './styles.scss';

type TaskInfoPopoverProps = {
  list?: ArtefactDeadlinesChangedItem[] | ComposeArtefactDelaysProdDeadline[];
  loading?: boolean;
};

function TaskInfoPopover({ list, loading }: TaskInfoPopoverProps) {
  const classNames = cx('info-drawer-pop-content', {
    'info-drawer-pop-content--loading': loading,
  });

  return (
    <div className={classNames}>
      {loading ? <LoadingOutlined /> : null}

      {!loading &&
        list?.map((item, index) => {
          const isSeparate = !!item.separateTask;
          const pathSeparate = generatePath(PATH_NAMES.separateTask.base, {
            taskId: item?.separateTask?.project_task?.id || '',
          });

          const path = getUrlToTask(
            item?.artefact?.cached_task?.artefact_production_sequence?.project_learning_object?.id,
            item?.artefact?.cached_task?.id,
            item?.artefact?.artefact_iteration_count?.toString(),
            item?.artefact?.id,
            item?.artefact?.artefact_type?.type,
            item?.artefact?.artefact_versions?.[0]?.file?.file_type
          );
          return (
            <Link to={isSeparate ? pathSeparate : path} key={index} className="info-drawer-pop-content__row">
              <span className="info-drawer-pop-content__count">
                {
                  item?.artefact?.cached_task?.artefact_production_sequence?.project_learning_object?.parent
                    ?.human_readable_number
                }
              </span>
              <div className="info-drawer-pop-content__icon">
                {isSeparate
                  ? getIconByUrl(item?.separateTask?.task_type?.icon_image)
                  : getIconByUrl(item?.artefact?.artefact_type?.icon_image)}
              </div>
              <div style={{ width: 300 }} className="info-drawer-pop-content__text">
                {isSeparate
                  ? item?.separateTask?.project_task?.name
                  : item?.artefact?.cached_task?.artefact_production_sequence?.project_learning_object?.name}
              </div>
              <Tag shape={TagShapeEnum.circle} size={TagSizeEnum.medium}>
                {isSeparate ? item?.separateTask?.task_type?.name : item?.artefact?.artefact_type?.name}
              </Tag>
            </Link>
          );
        })}
    </div>
  );
}

export default memo(TaskInfoPopover);
