import React from 'react';

import { SettingFilled } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import Dropdown from 'components/dropdown';
import { ButtonTypesEnum } from 'components/button/types';
import Button from 'components/button';

import useCommonContext from 'hooks/useCommonContext';
import useVisible from 'hooks/useVisible';

import { SettingsData } from '../../types';

type SettingsButtonProps = {
  settings: SettingsData;
  onChange?: (e: CheckboxChangeEvent) => void;
};

function SettingsButton({ settings, onChange }: SettingsButtonProps) {
  const { isVisible, setIsVisible } = useVisible(false);
  const common = useCommonContext();
  const list = [
    {
      id: '1',
      key: '1',
      label: (
        <Checkbox name="content" checked={settings.content} onChange={onChange}>
          {common.t<string>('pages.project.filter.content')}
        </Checkbox>
      ),
    },
    {
      id: '2',
      key: '2',
      label: (
        <Checkbox name="description" checked={settings.description} onChange={onChange}>
          {common.t<string>('pages.project.filter.description')}
        </Checkbox>
      ),
    },
    {
      id: '3',
      key: '3',
      label: (
        <Checkbox name="learningOutcomes" checked={settings.learningOutcomes} onChange={onChange}>
          {common.t<string>('pages.project.filter.learningOutcomes')}
        </Checkbox>
      ),
    },
  ];

  return (
    <Dropdown
      open={isVisible}
      onOpenChange={setIsVisible}
      className="settings-button"
      hasArrow={false}
      menu={{ items: list }}
    >
      <Button
        className="button-settings"
        type={ButtonTypesEnum.text}
        size="large"
        icon={<SettingFilled style={{ fontSize: 20 }} />}
      />
    </Dropdown>
  );
}

export default SettingsButton;
