import React, { memo, useMemo } from 'react';

import { StateType, UserBase } from 'types/entities';
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  DeleteFilled,
  EditFilled,
  LinkOutlined,
  MoreOutlined,
} from '@ant-design/icons';

import AvatarWithInfo from 'components/avatarWithInfo';
import Block from 'components/block';

import Button from '../button';
import { ButtonTypesEnum } from '../button/types';
import Tooltip from '../tooltip';
import useCommonContext from '../../hooks/useCommonContext';
import Dropdown from '../dropdown';
import DropdownMenu from '../dropdownMenu/DropdownMenu';

type DiscussionHeadProps = {
  user: UserBase;
  createdAt: string;
  commentId?: string;
  hasChangeStatus?: boolean;
  hasMore?: boolean;
  showLink?: boolean;
  canIRemove?: boolean;
  canIEdit?: boolean;
  isHideLinkAction?: boolean;
  status?: StateType;
  onCloseDiscussion?: () => void;
  onResumeDiscussion?: () => void;
  onEdit?: () => void;
  onEditComment?: (commentId?: string) => void;
  onRemove?: () => void;
  onRemoveComment?: (commentId?: string) => void;
  onCopyLink?: () => void;
};

function DiscussionHead({
  hasChangeStatus,
  hasMore,
  showLink,
  canIRemove,
  canIEdit,
  isHideLinkAction,
  user,
  createdAt,
  commentId,
  status,
  onCloseDiscussion,
  onResumeDiscussion,
  onEdit,
  onEditComment,
  onRemove,
  onRemoveComment,
  onCopyLink,
}: DiscussionHeadProps) {
  const common = useCommonContext();

  const menu = useMemo(() => {
    let temp = [];

    if (isHideLinkAction) {
      temp = [
        {
          id: '1',
          label: common.t<string>('comments.edit'),
          icon: <EditFilled />,
          action: () => onEditComment?.(commentId),
          type: 'EDIT',
        },
        {
          id: '2',
          label: common.t<string>('comments.delete'),
          icon: <DeleteFilled />,
          action: () => onRemoveComment?.(commentId),
          type: 'REMOVE',
        },
      ];
    } else {
      temp = [
        {
          id: '1',
          label: common.t<string>('comments.edit'),
          icon: <EditFilled />,
          action: onEdit,
          type: 'EDIT',
        },
        {
          id: '2',
          label: common.t<string>('comments.copyLink'),
          icon: <LinkOutlined />,
          action: onCopyLink,
          type: 'LINK',
        },
        {
          id: '3',
          label: common.t<string>('comments.delete'),
          icon: <DeleteFilled />,
          action: onRemove,
          type: 'REMOVE',
        },
      ];
    }
    return temp
      .filter((el) => {
        if (canIRemove) {
          return el;
        } else {
          return el.type !== 'REMOVE';
        }
      })
      .filter((el) => {
        if (canIEdit) {
          return el;
        } else {
          return el.type !== 'EDIT';
        }
      });
  }, [
    canIEdit,
    canIRemove,
    commentId,
    common,
    isHideLinkAction,
    onCopyLink,
    onEdit,
    onEditComment,
    onRemove,
    onRemoveComment,
  ]);

  return (
    <div className="discussion-head">
      <div className="flex-row">
        <AvatarWithInfo user={user} createdAt={createdAt} />
      </div>
      <div className="flex-row flex-align-items--center">
        <Block className="flex-row" hidden={!showLink}>
          <Button type={ButtonTypesEnum.text} icon={<LinkOutlined />} onClick={onCopyLink} />
        </Block>
        <Block hidden={!hasChangeStatus && !hasMore} className="discussion-head__actions">
          <Block empty hidden={!hasChangeStatus}>
            <Tooltip
              title={common.t<string>(
                status === StateType.NEW ? 'pages.task.tooltip.endDiscussion' : 'pages.task.tooltip.resumeDiscussion'
              )}
            >
              <Button
                size="large"
                type={ButtonTypesEnum.text}
                icon={status === StateType.NEW ? <CheckCircleOutlined /> : <CheckCircleFilled />}
                onClick={status === StateType.NEW ? onCloseDiscussion : onResumeDiscussion}
              />
            </Tooltip>
          </Block>
          <Block empty hidden={!hasMore}>
            <Dropdown hasArrow={false} overlay={<DropdownMenu list={menu} />}>
              <Button className="btn-more" size="large" type={ButtonTypesEnum.text} icon={<MoreOutlined />} />
            </Dropdown>
          </Block>
        </Block>
      </div>
    </div>
  );
}

export default memo(DiscussionHead);
