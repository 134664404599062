/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ArtefactAssignee } from './ArtefactAssignee';
import type { ArtefactBase } from './ArtefactBase';
import type { CompanyState } from './CompanyState';

export type FinalArtefactPipeline = {
    readonly id?: number;
    name: string;
    description: string;
    last_state: CompanyState;
    state_list_version_id: number;
    task_type: FinalArtefactPipeline.task_type;
    assignees: Array<ArtefactAssignee>;
    artefact_list: Array<ArtefactBase>;
};

export namespace FinalArtefactPipeline {

    export enum task_type {
        VIDEO_SHOOTING = 'video_shooting',
        ARTEFACT_PRODUCTION = 'artefact_production',
        ARTEFACT_ISSUE = 'artefact_issue',
        ARTEFACT_DISCUSSION = 'artefact_discussion',
        SEPARATE_TASK = 'separate_task',
    }


}
