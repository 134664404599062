import React, { memo } from 'react';

import { Skeleton } from 'antd';

function TaskErrorsSkeleton() {
  return (
    <>
      <div className="error-card">
        <Skeleton />
      </div>
      <div className="error-card">
        <Skeleton />
      </div>
    </>
  );
}

export default memo(TaskErrorsSkeleton);
