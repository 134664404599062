import React, { memo } from 'react';

import 'moment/locale/ru';
import 'moment/locale/en-gb';

import CourseItem from '../../../components/courseItem/CourseItem';
import { DashboardCourseItem } from '../../../dashboard/types';

import './styles.scss';

export interface ICoursesTableProps {
  data: DashboardCourseItem[] | null;
  onOpenDrawer: (projectId: string) => void;
  loading?: boolean;
  hasFirstLoad: boolean;
}

function CoursesTable({ data, onOpenDrawer }: ICoursesTableProps) {
  return (
    <>
      {data?.map((el) => {
        return <CourseItem key={el.id} item={el} onOpenDrawer={onOpenDrawer} transparent />;
      })}
    </>
  );
}

export default memo(CoursesTable);
