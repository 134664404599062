import React, { useState } from 'react';

import Pagination from '../../../components/pagination';

const init = {
  current: 3,
  total: 180,
};

const PaginationStories = () => {
  const [state, setState] = useState(init);

  const onChange = (page: number) => {
    setState({ ...state, current: page });
  };

  return (
    <div>
      <Pagination
        className="mb_16"
        size="small"
        current={state.current}
        total={state.total}
        onChange={onChange}
        showSizeChanger={false}
      />
      <Pagination current={state.current} total={state.total} onChange={onChange} showSizeChanger={false} />
    </div>
  );
};

export default PaginationStories;
