import React from 'react';

import { cx } from 'utils';

import { CompanyRole } from 'hooks/queries/useCompanyRoles';

import SelectUsersBlock from '../selectUsersBlock';
import { UsersState } from '../../addCourse/hooks/useAddCourseControl';

import './styles.scss';
import Skeleton from 'antd/es/skeleton';

type ParticipantsBlockProps = {
  className?: string;
  hasTitle?: boolean;
  canEdit?: boolean;
  title?: string;
  label?: string;
  hint?: string;
  role?: CompanyRole;
  users: UsersState;
  onClick: (roleId: string) => void;
};

function ParticipantsBlock({
  className,
  hasTitle,
  canEdit,
  title,
  label,
  hint,
  role,
  users,
  onClick,
}: ParticipantsBlockProps) {
  const selected = role?.id ? users?.[role?.id] : null;

  const classNames = cx('participants-block', className, {
    'has-children': role,
  });

  if (!role) {
    return null;
  }

  return (
    <div className={classNames}>
      {hasTitle && <span className="participants-block__label">{title}</span>}
      <SelectUsersBlock
        canEdit={canEdit}
        label={label}
        hint={hint}
        roleItem={role}
        users={selected}
        onClick={onClick}
      />
    </div>
  );
}

export default ParticipantsBlock;
