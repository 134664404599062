import React from 'react';

import TruncateMarkup from 'react-truncate-markup';
import { FieldNumberOutlined } from '@ant-design/icons';
import { cx, getFormatDateByStr, getUrlToTask } from 'utils';
import { generatePath } from 'react-router-dom';
import { PATH_NAMES } from 'router/constants';

import AvatarGroup from 'components/avatarGroup/AvatarGroup';
import TagDelayWithFlag from 'components/tagDelayWithFlag/TagDelayWithFlag';
import ArtefactDeadlinesChanged from 'components/historyPopup/artefactDeadlinesChanged/ArtefactDeadlinesChanged';
import { ProjectStatusChangedPopup } from 'components/historyPopup';
import StatusLabelFlat from 'components/statusLabelFlat/StatusLabelFlat';
import Tooltip from 'components/tooltip/Tooltip';

import { getIconByUrl } from 'utils/icons';

import { ProjectTaskItem } from 'hooks';

import useCommonContext from 'hooks/useCommonContext';
import useGlobalActionsContext from 'hooks/useGlobalActionsContext';

type ProjectTableRowProps = {
  item: ProjectTaskItem;
};

function ProjectTableRow({ item }: ProjectTableRowProps) {
  const common = useCommonContext();

  const isSeparateTask = !!item?.separate_task;

  const convertAssignees = item.current_assignees.map((a) => a.project_user);
  const { data: actionsData, setData } = useGlobalActionsContext();

  //Iteration First Deadline
  const iterationDeadline = isSeparateTask
    ? item?.separate_task?.deadline_first_iteration_review || ''
    : item.cached_linked_artefact?.first_iteration_deadline;
  const iterationDeadlineDelay = isSeparateTask
    ? item?.separate_task?.first_iteration_delay_duration_summary_days || 0
    : item.cached_linked_artefact?.first_iteration_deadline_delay;
  const iterationDeadlineChanged = isSeparateTask
    ? item.separate_task?.first_iteration_deadline_changed
    : item.cached_linked_artefact?.first_iteration_deadline_changed;

  //Production Second Deadline
  const productionDeadline = isSeparateTask
    ? item?.separate_task?.deadline_production || ''
    : item.cached_linked_artefact?.production_deadline;
  const productionDeadlineDelay = isSeparateTask
    ? item.separate_task?.first_iteration_delay_duration_summary_days || 0
    : item.cached_linked_artefact?.production_deadline_delay;
  const productionDeadlineChanged = isSeparateTask
    ? item.separate_task?.production_deadline_changed
    : item.cached_linked_artefact?.production_deadline_changed;

  const goTask = () => {
    const path = getUrlToTask(
      item.learning_object?.id,
      item?.pk,
      item?.cached_linked_artefact?.cached_artefact_iteration_count?.toString(),
      item?.artefacts?.[0]?.id,
      item?.artefacts[0]?.artefact_type.type,
      item?.artefacts[0]?.artefact_versions[0]?.file?.file_type
    );
    const pathSeparate = generatePath(PATH_NAMES.separateTask.base, {
      taskId: item?.separate_task?.project_task?.id || '',
    });
    setData({ ...actionsData, prevUrl: window.location.pathname });
    common.navigate(isSeparateTask ? pathSeparate : path);
  };

  return (
    <div role="button" onClick={goTask} className="project-table-row">
      <div className="project-table-cell__number">{item?.learning_object?.parent?.human_readable_number}</div>
      <div className="project-table-cell__icon mr_12">
        <div className={cx('art-type-icon-wrap', { 'art-type-icon-wrap-s': isSeparateTask })}>
          {isSeparateTask ? null : (
            <Tooltip title={item?.learning_object?.learning_object_type?.name}>
              {getIconByUrl(item?.learning_object?.learning_object_type?.icon_image)}
            </Tooltip>
          )}
        </div>
      </div>
      <div className="project-table-cell__element mr_16">
        <TruncateMarkup lines={1}>
          <span>{isSeparateTask ? common.t<string>('pages.project.globalTasks') : item?.learning_object?.name}</span>
        </TruncateMarkup>
      </div>
      <div className="project-table-cell__task-type mr_16">
        <div className="flex-row flex-align-items--center">
          <Tooltip
            title={
              isSeparateTask ? item?.separate_task?.task_type?.name : item?.cached_linked_artefact?.artefact_type?.name
            }
          >
            {isSeparateTask
              ? getIconByUrl(item?.separate_task?.task_type?.icon_image)
              : getIconByUrl(item?.cached_linked_artefact?.artefact_type?.icon_image)}
          </Tooltip>

          <TruncateMarkup lines={1}>
            <span className="art-type-name">
              {isSeparateTask ? item?.name : item?.cached_linked_artefact?.artefact_type?.name}
            </span>
          </TruncateMarkup>
        </div>
      </div>
      <div className="project-table-cell__iteration mr_16">
        <div className="flex-row flex-align-items--center">
          <Tooltip
            className="flex-row flex-align-items--center"
            title={common.t<string>('pages.dashboard.tooltips.iteration')}
          >
            <FieldNumberOutlined style={{ fontSize: 16, color: '#848484' }} />
            <span className="art-iter">
              {isSeparateTask
                ? item?.separate_task?.cached_artefact_iteration_count
                : item?.cached_linked_artefact?.cached_artefact_iteration_count}
            </span>
          </Tooltip>
        </div>
      </div>
      <div className="project-table-cell__team mr_16">
        <AvatarGroup users={convertAssignees} hasPopover maxPopoverTrigger="hover" />
      </div>
      <div className="project-table-cell__deadline mr_16">
        <div className="flex-row flex-align-items--center">
          {iterationDeadline ? (
            <TagDelayWithFlag
              filled={false}
              text={iterationDeadline}
              delay={iterationDeadlineDelay}
              tooltip={`${common.t<string>('pages.dashboard.tooltips.iterationDeadline')} ${iterationDeadlineDelay}`}
              extraText={
                iterationDeadlineDelay > 0 ? `${iterationDeadlineDelay}${common.t<string>('common.shortDay')}` : null
              }
            />
          ) : null}
          {iterationDeadlineChanged ? (
            <ArtefactDeadlinesChanged
              isSeparate={isSeparateTask}
              separateTaskId={isSeparateTask ? item?.id : undefined}
              projectId={isSeparateTask ? undefined : item?.project?.id}
              artefactId={isSeparateTask ? undefined : item?.cached_linked_artefact?.id}
              deadlineType="first_iteration_deadline"
            />
          ) : null}
        </div>
      </div>
      <div className="project-table-cell__deadline mr_16">
        <div className="flex-row flex-align-items--center">
          {productionDeadline ? (
            <TagDelayWithFlag
              filled={false}
              flagFilled
              text={productionDeadline}
              delay={productionDeadlineDelay}
              tooltip={`${common.t<string>('pages.dashboard.tooltips.finalDeadline')} ${productionDeadlineDelay}`}
              extraText={
                productionDeadlineDelay > 0 ? `${productionDeadlineDelay}${common.t<string>('common.shortDay')}` : null
              }
            />
          ) : null}

          {productionDeadlineChanged ? (
            <ArtefactDeadlinesChanged
              isSeparate={isSeparateTask}
              separateTaskId={isSeparateTask ? item?.id : undefined}
              projectId={isSeparateTask ? undefined : item?.project?.id}
              artefactId={isSeparateTask ? undefined : item?.cached_linked_artefact?.id}
              deadlineType="artefact_production_deadline"
            />
          ) : null}
        </div>
      </div>
      <div className="project-table-cell__changed mr_16">
        <div className="flex-row flex-align-items--center">
          <Tooltip
            className="flex-row flex-align-items--center"
            title={common.t<string>('pages.dashboard.tooltips.lastChanged')}
          >
            <span className="last-state-updated">{getFormatDateByStr(item?.last_state_updated_at)}</span>
            {item?.last_state_is_changed && (
              <ProjectStatusChangedPopup
                isSeparate={isSeparateTask}
                projectId={item?.project?.id}
                projectTaskId={item?.id}
              />
            )}
          </Tooltip>
        </div>
      </div>
      <div className="project-table-cell__status">
        <StatusLabelFlat type={item?.last_state?.state_type} name={item?.last_state?.name} />
      </div>
    </div>
  );
}

export default ProjectTableRow;
