import React from 'react';

import Button from '../../../components/button/Button';
import Popconfirm from '../../../components/popconfirm/Popconfirm';

const PopconfirmStories = () => {
  return (
    <div className="mb_8">
      <Popconfirm title="Are you sure delete this task?" okText="Yes" cancelText="No" onConfirm={() => {}}>
        <Button onClick={() => {}}>open</Button>
      </Popconfirm>
    </div>
  );
};

export default PopconfirmStories;
