import { ReactNode, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { BusinessRoleEnum } from 'types/entities';
import { GET_COMPANY_ROLES_LIST } from 'queries/getCompanyRoles';
import { allowedRolesToSelect, arrToSelect } from 'utils';

import useCommonContext from '../useCommonContext';

export type CompanyRole = {
  id: string;
  role: BusinessRoleEnum;
  name: string;
};

export interface CompanyRolesEx extends CompanyRole {
  label: ReactNode;
  value: string | number | null;
  disabled?: boolean;
}

function useCompanyRoles(companyId?: string | null, fetchPolicy?: WatchQueryFetchPolicy) {
  const common = useCommonContext();
  const { data, loading } = useQuery(GET_COMPANY_ROLES_LIST, {
    variables: {
      companyId: Number(companyId),
    },
    skip: !companyId,
    fetchPolicy: fetchPolicy || 'cache-and-network',
  });

  const valueMemoized: CompanyRole[] | undefined = useMemo(() => {
    return data?.user_roles?.edges.map((el: any) => {
      return {
        id: el?.node?.pk,
        role: el?.node?.role,
        name: el?.node?.name,
      };
    });
  }, [data?.user_roles?.edges]);

  const allowedRolesMemoized = useMemo(() => {
    const allowed = allowedRolesToSelect(valueMemoized, common.businessRoles);
    return valueMemoized?.filter((v) => allowed.some((a) => v.id === a));
  }, [common.businessRoles, valueMemoized]);

  return {
    loading,
    companyRoles: valueMemoized,
    companyRolesForSelect: arrToSelect<CompanyRole, CompanyRolesEx>(valueMemoized),
    allowedCompanyRolesForSelect: arrToSelect<CompanyRole, CompanyRolesEx>(allowedRolesMemoized),
  };
}

export default useCompanyRoles;
