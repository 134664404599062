import React, { memo, useMemo } from 'react';

import { updateSeparateTaskState } from 'api/requests/projectTask';
import { useParams } from 'react-router-dom';
import { MenuProps } from 'antd';
import useSeparateTaskAbility from 'pages/hooks/useSeparateTaskAbility';

import StatusLabelFlat from 'components/statusLabelFlat/StatusLabelFlat';
import Dropdown from 'components/dropdown/DropDown';
import { MenuInfo } from 'components/dropdownMenu/types';

import useCompanyState from 'hooks/queries/useCompanyState';
import useSeparateTaskQuery from 'hooks/queries/useSeparateTaskQuery';

function SeparateTaskState() {
  const { taskId } = useParams();
  const { separateTask, separateTaskRefetch } = useSeparateTaskQuery(taskId, 'cache-and-network');
  const { canIEdit } = useSeparateTaskAbility(taskId);
  const { companyStates } = useCompanyState(
    separateTask?.project?.company.id,
    'network-only',
    null,
    Number(separateTask?.project.separate_task_state_list_version.id)
  );
  const items: MenuProps['items'] = useMemo(() => {
    return companyStates
      ?.filter((cs) => cs.id !== separateTask?.last_state.id)
      ?.map((s) => {
        return {
          key: s.id,
          id: s.id,
          label: <StatusLabelFlat type={s.state_type} name={s.name} />,
          value: s.id,
        };
      });
  }, [companyStates, separateTask?.last_state.id]);

  const onClick = async ({ key }: MenuInfo) => {
    const payload = {
      state_id: Number(key),
    };
    await updateSeparateTaskState(separateTask.id, payload);
    await separateTaskRefetch();
  };

  return (
    <Dropdown disabled={!canIEdit} className="dropdown-status" menu={{ items, onClick }}>
      <StatusLabelFlat type={separateTask?.last_state?.state_type} name={separateTask?.last_state?.name} />
    </Dropdown>
  );
}

export default memo(SeparateTaskState);
