import React from 'react';

import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ConfirmModal, { ConfirmModalProps } from 'modals/confirm/ConfirmModal';
import EditUser from 'modals/editUser';
import InviteUser from 'modals/inviteUser';
import CourseCreateElement from 'modals/courseCreateElement';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import i18next from 'i18next';
import ruRu from 'antd/es/locale/ru_RU';
import enUS from 'antd/es/locale/en_US';

import DumbModal from 'components/modal';

import store from 'store/store';

import EditIssue from '../modals/editIssue';
import { CoursePreviewBaseProps } from '../modals/coursePreview/types';
import CoursePreview from '../modals/coursePreview';
import AssignersUsers from '../modals/assignersUsers';
import { client } from '../schemas';
import history from '../router/history/history';
import { theme } from '../theme/theme';
import AddNewStatusList from '../modals/addNewStatusList';
import EditStateItem from '../modals/editStateItem';
import AddStateRelationship from '../modals/addStateRelationship';
import CreateNewArtefactType from '../modals/createNewArtefactType';
import CreateNewLOType from '../modals/createNewLOType/CreateNewLOType';
import CreateNewPipeline from '../modals/createNewPipeline';
import AddArtefactTypeToPipeline from '../modals/addArtefactTypeToPipeline';
import AddSequenceRule from '../modals/addSequenceRule';
const locale = i18next.language === 'ru' ? ruRu : enUS;

const modalService = {
  id: 0,
  modals: {} as any,
  root: {} as any,
  open(options: any) {
    const target = document.createElement('div');
    document.body.appendChild(target);
    const { component: Component, props, onCancel, maskClosable, hideClose, keyboard, maskStyle, ...rest } = options;
    const { id } = this;
    this.modals[this.id] = target;
    const close = () => {
      if (onCancel) {
        onCancel();
      }
      this.close(id);
    };
    this.root = createRoot(target);
    this.root.render(
      <Provider store={store}>
        <ApolloProvider client={client}>
          <HistoryRouter history={history}>
            <ConfigProvider locale={locale} theme={theme}>
              <DumbModal
                id={id}
                show
                {...rest}
                onCancel={close}
                hideClose={hideClose}
                keyboard={keyboard}
                maskStyle={maskStyle}
              >
                <Component {...props} onCancel={close} />
              </DumbModal>
            </ConfigProvider>
          </HistoryRouter>
        </ApolloProvider>
      </Provider>
    );
    // eslint-disable-next-line no-plusplus
    this.id++;
    return id;
  },
  close(id: any) {
    const container: any = this.modals[id];
    if (container) {
      this.root.unmount();
      document.body.removeChild(container);
    }
    delete this.modals[id];
  },
  closeAll() {
    const keys = Object.keys(this.modals).map((key) => parseInt(key, 10));
    keys.forEach((key) => {
      this.close(key);
    });
  },
  openInviteUserModal: (props: any) => {
    return modalService.open({
      component: InviteUser,
      className: 'ccm-modal--invite-user',
      props,
    });
  },
  openEditUserModal: (props: any) => {
    return modalService.open({
      component: EditUser,
      className: 'ccm-modal--edit-user',
      props,
    });
  },
  openAssignersUserModal: (props: any) => {
    return modalService.open({
      component: AssignersUsers,
      className: 'ccm-modal--assigners-users',
      props,
    });
  },
  openCourseCreateElement: (props: any) => {
    return modalService.open({
      component: CourseCreateElement,
      props,
    });
  },
  openConfirmModal: (props: ConfirmModalProps) => {
    return modalService.open({
      component: ConfirmModal,
      className: 'ccm-modal--confirm',
      props,
    });
  },
  openEditIssueModal: (props: any) => {
    return modalService.open({
      component: EditIssue,
      className: 'ccm-modal--edit-issue',
      props,
    });
  },
  openCoursePreview: (props: CoursePreviewBaseProps) => {
    return modalService.open({
      component: CoursePreview,
      className: 'ccm-modal--course-preview',
      props,
    });
  },
  openAddNewStatusList: (props: any) => {
    return modalService.open({
      component: AddNewStatusList,
      className: 'ccm-modal--new-status-list',
      props,
    });
  },
  openEditStateItem: (props: any) => {
    return modalService.open({
      component: EditStateItem,
      className: 'ccm-modal--edit-state-item',
      props,
    });
  },
  openAddStateRelationship: (props: any) => {
    return modalService.open({
      component: AddStateRelationship,
      className: 'ccm-modal--edit-state-item',
      props,
    });
  },
  openCreateNewArtefactType: (props: any) => {
    return modalService.open({
      component: CreateNewArtefactType,
      className: 'ccm-modal--create-art-type',
      props,
    });
  },
  openCreateNewLOType: (props: any) => {
    return modalService.open({
      component: CreateNewLOType,
      className: 'ccm-modal--create-lo-type',
      props,
    });
  },
  openCreateNewPipeline: (props: any) => {
    return modalService.open({
      component: CreateNewPipeline,
      className: 'ccm-modal--create-pipeline',
      props,
    });
  },
  openAddArtefactType: (props: any) => {
    return modalService.open({
      component: AddArtefactTypeToPipeline,
      className: 'ccm-modal--add-art-to-pipeline',
      props,
    });
  },
  openAddSequenceRule: (props: any) => {
    return modalService.open({
      component: AddSequenceRule,
      className: 'ccm-modal--add-sequence-rule',
      props,
    });
  },
};

export default modalService;
