import API from '../api';
import { ApiModules, ENDPOINTS } from '../constants';

type CompanyStatePayload = {
  name: string;
  company_id: string;
  last_states: never[];
};

export type CompanyStateResponse = {
  id: string;
};

export type CompanyStatePublishPayload = {
  id: string;
};

export const onCompanyState = (payload: CompanyStatePayload) =>
  API(ApiModules.PM).post(ENDPOINTS.COMPANY_STATE, payload);

export const onCompanyStateVersion = (listId: string) => API(ApiModules.PM).post(ENDPOINTS.COMPANY_STATE_VER(listId));

export type LastState = {
  name: string;
  button_name?: string;
  state_type: string;
  //order: number;
  is_new_iteration_initiation: boolean;
  is_require_discuss_message: boolean;
  is_could_close_without_complete_issues: boolean;
};

type EditStatePayload = LastState;

export const onEditCompanyState = (id: string, payload: EditStatePayload) =>
  API(ApiModules.PM).post(ENDPOINTS.COMPANY_STATE_VER_STATE(id), payload);

export const onCompanyStatePublish = (payload: CompanyStatePublishPayload) =>
  API(ApiModules.PM).post(ENDPOINTS.COMPANY_STATE_PUBLISH, payload);

type ArtefactFlowStepPayload = {
  company_artefact_type_id: number;
  state_initiation_id: number;
};

export const onAddArtefactFlowStep = (payload: ArtefactFlowStepPayload) =>
  API(ApiModules.PM).post(ENDPOINTS.ART_FLOW_STEP, payload);

type SaveArtefactFlowStepNext = {
  artefact_flow_step_id_from: number;
  artefact_flow_step_id_to: number;
  order: number;
  management_data: any;
};

export type SaveArtefactFlowStep = {
  artefact_flow_step_id: number;
  assignee_user_role_id: number | null;
  management_data: any;
  links: SaveArtefactFlowStepNext[];
}[];

export const onSaveArtefactFlowStep = (artTypeId: string, payload: SaveArtefactFlowStep) =>
  API(ApiModules.PM).post(ENDPOINTS.ART_TYPE_FLOW_STEP(artTypeId), payload);

export const onCreateArtefactType = (companyId: string, payload: FormData) =>
  API(ApiModules.PM).post(ENDPOINTS.COMPANY_ARTEFACT_TYPE(companyId), payload);

export const onCreateLearningObjectType = (companyId: string, payload: FormData) =>
  API(ApiModules.PM).post(ENDPOINTS.COMPANY_LO_TYPE(companyId), payload);

export type PipelinePayload = {
  steps: {
    order: number;
    sequences: {
      order: number;
      artefact_type_id: number;
      default_state_id?: number;
      management_data: any;
    }[];
  }[];
};

export const onCreatePipeline = (payload: PipelinePayload) =>
  API(ApiModules.PM).post(ENDPOINTS.ARTEFACT_PIPELINE, payload);

export const onUpdatePipeline = (id: string, payload: PipelinePayload) =>
  API(ApiModules.PM).post(ENDPOINTS.ADD_ARTEFACT_PIPELINE_VER(id), payload);

export type SequenceRulePayload = {
  artefact_flow_step_id: number;
  artefact_pipeline_step_sequence_id: number;
  set_state_id: number;
};

export const onAddSequence = (payload: SequenceRulePayload) =>
  API(ApiModules.PM).post(ENDPOINTS.ARTEFACT_FLOW_STEP_SEQUENCE_RULE, payload);

export const onPublishPipelineVer = (verId: string) =>
  API(ApiModules.PM).post(ENDPOINTS.ARTEFACT_PIPELINE_VER_PUBLISH(verId));

export const onDeleteSequenceRule = (id: string) =>
  API(ApiModules.PM).delete(ENDPOINTS.ARTEFACT_FLOW_STEP_SEQUENCE_RULE_ID(id));
