import React, { memo } from 'react';

import { Skeleton } from 'antd';
import SidebarHeader from 'layout/sidebar/components/SidebarHeader';
import { useParams } from 'react-router-dom';

import useLearningObjectSidebar from '../../hooks/useLearningObjectSidebar';
import useArtefactSidebar from '../../hooks/useArtefactSidebar';
import { useSubscribe } from '../../../../hooks/useSubscribe';

import TaskSidebarHeaderContent from './TaskSidebarHeaderContent';
import TaskSidebarInfo from './TaskSidebarInfo';
import TaskSidebarMenu from './TaskSidebarMenu';

import './styles.scss';

type TaskSidebarContentProps = {
  collapsed: boolean;
  onToggle?: () => void;
};

const styles = {
  head: { width: '55px', marginLeft: '24px', marginTop: '16px' },
};

function TaskSidebarContent({ collapsed, onToggle }: TaskSidebarContentProps) {
  const { taskId } = useParams();

  const { data: learningObject, loading } = useLearningObjectSidebar();
  const { data: artefacts, loading: artLoading, refetch } = useArtefactSidebar();

  const currentArtefact = artefacts?.find((artefact) => Number(artefact?.project_task?.id) === Number(taskId));

  useSubscribe('update-sidebar', () => refetch());

  return (
    <>
      <Skeleton active loading={loading} style={styles.head} avatar={false} paragraph={false} />
      <SidebarHeader collapsed={collapsed} onTrigger={onToggle}>
        <TaskSidebarHeaderContent
          icon={learningObject?.learning_object_type.icon_image}
          humNum={learningObject?.parent.human_readable_number}
        />
      </SidebarHeader>
      <div className="sidebar__content">
        <TaskSidebarInfo
          loading={loading || artLoading}
          learningObject={learningObject}
          currentArtefact={currentArtefact}
        />
        <TaskSidebarMenu loading={artLoading} artefacts={artefacts} />
      </div>
    </>
  );
}

export default TaskSidebarContent;
