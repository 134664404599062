import React, { useCallback, useMemo, useState } from 'react';

import modalService from 'services/modalService';
import { onAddArtefactFlowStep, onSaveArtefactFlowStep } from 'api/requests/companyState';
import { PlusOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { Node, Edge } from 'reactflow';
import { message } from 'antd';

import Select from 'components/select';
import { TitleVariantEnum } from 'components/title/types';
import Title from 'components/title';
import Button from 'components/button';
import { ButtonTypesEnum } from 'components/button/types';

import useCompanyArtefactTypes from 'hooks/queries/useCompanyArtefactTypes';
import { CompanyStateListItem } from 'hooks/queries/useCompanyStateLists';
import useArtefactFlowStepsAdmin from 'hooks/queries/useArtefactFlowStepsAdmin';

import FlowContent from './flowContent';
import { NodeData } from './customNode/CustomNode';
import { EdgeData } from './customEdge/CustomEdge';

import 'reactflow/dist/style.css';
import './styles.scss';

type Props = {
  selectedList: CompanyStateListItem | null;
};

const ArtefactFlow = ({ selectedList }: Props) => {
  const [artefactType, setArtefactType] = useState<string | null>(null);

  const { flowSteps, stepsNode, stepsEdge, refetch } = useArtefactFlowStepsAdmin(
    artefactType,
    selectedList?.actual_version?.id
  );
  const { companyArtefactTypesForSelect } = useCompanyArtefactTypes();

  const options = useMemo(() => {
    return selectedList?.actual_version.states
      .filter((s) => {
        return !flowSteps?.some((f) => f?.state_initiation.id === s.id);
      })
      .map((el) => {
        return {
          id: el.id,
          label: el.name,
          value: el.id,
        };
      });
  }, [flowSteps, selectedList?.actual_version?.states]);

  const onSelectArtType = useCallback((value: string) => setArtefactType(value), []);

  const onSaveAddStateToFlow = useCallback(
    async (val: string) => {
      if (artefactType) {
        const payload = {
          company_artefact_type_id: Number(artefactType),
          state_initiation_id: Number(val),
        };
        await onAddArtefactFlowStep(payload);
        await refetch();
      }
    },
    [artefactType, refetch]
  );

  const onAddState = useCallback(() => {
    modalService.openAddStateRelationship({
      options,
      onSave: onSaveAddStateToFlow,
    });
  }, [onSaveAddStateToFlow, options]);

  const onSaveFlow = useCallback(
    async (nodes: Node<NodeData>[], edges: Edge<EdgeData>[]) => {
      const payload = nodes.map((node) => {
        const filteredEdges = edges.filter((edge) => edge.source === node.id);
        const links = filteredEdges.map((link) => {
          return {
            artefact_flow_step_id_from: Number(link.source),
            artefact_flow_step_id_to: Number(link.target),
            order: Number(link?.data?.order) || 0,
            management_data: {
              sourceHandle: link.sourceHandle,
              targetHandle: link.targetHandle,
            },
          };
        });
        return {
          artefact_flow_step_id: Number(node.id),
          assignee_user_role_id: Number(node.data.userRoleId) || null,
          management_data: {
            position: {
              ...node.position,
            },
          },
          links,
        };
      });
      if (artefactType) {
        await onSaveArtefactFlowStep(artefactType, payload).then(() => {
          message.info('Flow saved');
        });
      }
    },
    [artefactType]
  );

  return (
    <div className="flow">
      <div className="flow__top">
        <Button
          type={ButtonTypesEnum.primary}
          size="xl"
          icon={<PlusOutlined />}
          disabled={isEmpty(options) || !artefactType}
          onClick={onAddState}
        >
          Add state
        </Button>
        <Select
          noForm
          size="xl"
          placeholder="Artefact type"
          options={companyArtefactTypesForSelect}
          onChange={onSelectArtType}
        />
        <div className="flow-list">
          <Title variant={TitleVariantEnum.h5}>Status list:</Title>
          <Title variant={TitleVariantEnum.h6}>{selectedList?.name}</Title>
        </div>
      </div>
      {stepsNode && stepsEdge ? <FlowContent dataNodes={stepsNode} dataEdges={stepsEdge} onSave={onSaveFlow} /> : null}
    </div>
  );
};

export default ArtefactFlow;
