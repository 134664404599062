import React, { useCallback } from 'react';

import { Switch as ASwitch } from 'antd';
import { cx } from 'utils';

import { ISwitch } from './types';

function Switch({ className, label, name, onHandleChange, ...props }: ISwitch) {
  const classNames = cx('ccm-switch', className, {
    'ccm-switch--disabled': props.disabled,
    [`ccm-switch--small`]: props.size === 'small',
  });

  if (name && onHandleChange) {
  }

  const handleChange = useCallback(
    (checked: boolean) => {
      onHandleChange?.(name, checked);
    },
    [name, onHandleChange]
  );

  return (
    <div className={classNames}>
      <ASwitch onChange={handleChange} {...props} />
      {label ? <span className="ccm-switch__label">{label}</span> : null}
    </div>
  );
}

export default Switch;
