import { gql } from '@apollo/client';

export const GET_ARTEFACT_FLOW_STEPS_ADMIN = gql`
  query ArtefactFlowStepsAdmin($company_artefact_type_id: Int, $company_state_list_version_id: Int) {
    artefact_flow_steps_admin(
      company_artefact_type_id: $company_artefact_type_id
      company_state_list_version_id: $company_state_list_version_id
    ) {
      edges {
        node {
          pk
          id
          management_data
          assignee_user_role {
            id
            role
          }
          state_initiation {
            company_state_list_version {
              id
            }
            id
            state_type
            name
            button_name
          }
          possible_next_steps {
            management_data
            order
            state_next {
              pk
              state_initiation {
                id
                state_type
                name
                button_name
              }
            }
          }
        }
      }
    }
  }
`;
