import React from 'react';

import { Form } from 'antd';

import TextField from 'components/textField';
import Editor from 'components/editor';
import DatePicker from 'components/datePicker';
import Select from 'components/select';

import useCommonContext from 'hooks/useCommonContext';
import useCompanySeparateTaskTypes from 'hooks/queries/useCompanySeparateTaskTypes';

import { GlobalTaskOutput } from '../../hooks/useSeparateTask';
import GlobalTaskPerformers from '../globalTaskPerformers';
import { getGlobalTaskEditorTools } from '../../createSeparateTask/tools';
import useProjectAbility from '../../project/hooks/useProjectAbility';

import './styles.scss';

type CreateTaskFormProps = {
  instance: GlobalTaskOutput;
  onOpenDrawer: () => void;
};

function GlobalTaskForm({ instance, onOpenDrawer }: CreateTaskFormProps) {
  const common = useCommonContext();
  const { projectData } = useProjectAbility();

  const { companySeparateTaskTypesForSelect } = useCompanySeparateTaskTypes(projectData?.company?.id);

  return (
    <Form form={instance.form} id="global-task-form" className="global-task-form" layout="vertical">
      <div className="global-task-form__row mb_12">
        <Select
          name="task_type"
          size="xl"
          label={common.t<string>('form.field.globalTaskType.label')}
          placeholder={common.t<string>('form.field.globalTaskType.placeholder')}
          options={companySeparateTaskTypesForSelect}
        />
        <GlobalTaskPerformers instance={instance} onOpenDrawer={onOpenDrawer} />
      </div>
      <TextField
        className="mb_12"
        size="xl"
        name="name"
        label={common.t<string>('form.field.freeTaskName.label')}
        placeholder={common.t<string>('form.field.freeTaskName.placeholder')}
      />
      <div className="global-task-form__row mb_12">
        <DatePicker
          name="deadline_first_iteration_review"
          size="xl"
          label={common.t<string>('form.field.deadlineFirstIteration.label')}
          placeholder={common.t<string>('form.field.deadlineFirstIteration.placeholder')}
        />
        <DatePicker
          name="deadline_production"
          size="xl"
          label={common.t<string>('form.field.finalDeadline.label')}
          placeholder={common.t<string>('form.field.finalDeadline.placeholder')}
        />
      </div>
      <div className="global-task-form__editor-wrap">
        <span className="global-task-form__label">{common.t<string>('pages.project.create.editor.label')}</span>
        <Editor
          initialize={instance.initializeEditor}
          onReadyEditor={instance.onReadyEditor}
          placeholder={common.t<string>('pages.project.create.editor.placeholder')}
          tools={getGlobalTaskEditorTools()}
        />
      </div>
    </Form>
  );
}

export default GlobalTaskForm;
