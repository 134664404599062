// eslint-disable-next-line import/no-cycle
import { CompanyArtefactType } from '../models/CompanyArtefactType';

export enum ArtefactTypeEnum {
  video = 'VIDEO',
  presentation = 'PRESENTATION',
  text = 'TEXT',
  grader = 'GRADER',
}

export interface ArtefactContentVideoFoundIssue {
  id?: string;
  pk: string;
  video_id?: string;
  issue_id?: any; // Any temporarily
  timestamp_start?: number | null;
  timestamp_end?: number | null;
  reviewed_by?: string; //Will be User or user_id
  description?: string;
}

export interface CompanyArtefactTypeWithId extends CompanyArtefactType {
  id: string;
}

export type LearningObjectType = {
  id: string;
  name: string;
  type: string;
};
