import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';

import { CompanyIssueItem } from '../../pages/task/types';
import { GET_COMPANY_ISSUES_LIST } from '../../queries/getCompanyIssuesList';

function useArtefactContentIssues(
  companyId?: number,
  companyArtefactTypeId?: number,
  fetchPolicy?: WatchQueryFetchPolicy
) {
  const { data, loading, refetch } = useQuery<any>(GET_COMPANY_ISSUES_LIST, {
    variables: {
      companyId: companyId,
      company_artefact_type_id: companyArtefactTypeId,
      skip: !companyId || !companyArtefactTypeId,
    },
    fetchPolicy: fetchPolicy || 'network-only',
  });

  const contentIssuesList: CompanyIssueItem[] | null = useMemo(() => {
    const issuesTypesList = data?.artefact_content_issues?.edges;
    const typeListRemapped = issuesTypesList?.map((issueType: any) => {
      return { ...issueType?.node, value: issueType?.node?.id, label: issueType?.node?.name };
    });
    return typeListRemapped || null;
  }, [data?.artefact_content_issues?.edges]);

  return {
    issuesTypesList: contentIssuesList,
    issuesTypesLoading: loading,
    issuesTypesRefetch: refetch,
  };
}

export default useArtefactContentIssues;
