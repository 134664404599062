import { TFunction } from 'i18next';

export const delaySelectData = (t: TFunction) => [
  {
    id: '1',
    label: t<string>('common.noDelay'),
    value: '1',
  },
  {
    id: '2',
    label: t<string>('common.week'),
    value: '2',
  },
  {
    id: '3',
    label: t<string>('common.month'),
    value: '3',
  },
  {
    id: '4',
    label: t<string>('common.overMonth'),
    value: '4',
  },
];
