import { useCallback, useEffect, useRef } from 'react';

import { useQuery } from '@apollo/client';
import {
  GET_DASHBOARD_COURSES_DEADLINE,
  GET_DASHBOARD_COURSES_NO_ASSIGNEES,
  GET_DASHBOARD_COURSES_WIDGET,
  GET_DASHBOARD_COURSES_WITH_ISSUES,
} from 'queries/getDashboardCourses';
import { PageInfo } from 'schemas/types';
import { buildAbilityFor } from 'config/ability';
import { convertRoles } from 'utils';

import useBoolean from 'hooks/useBoolean';
import useCommonContext from 'hooks/useCommonContext';

import { DashboardCourseItem, DashboardCoursesWidget } from '../types';

type CoursesWidgetData = {
  front_page_courses: DashboardCoursesWidget;
};

type CoursesNoAssigneesData = {
  front_page_courses_no_assignees: {
    pageInfo: PageInfo;
    edges: {
      cursor: string;
      node: DashboardCourseItem;
    }[];
  };
};

type CoursesWithIssuesData = {
  front_page_courses_with_issues: {
    pageInfo: PageInfo;
    edges: {
      cursor: string;
      node: DashboardCourseItem;
    }[];
  };
};

type CoursesWithDeadlinesData = {
  front_page_courses_deadline_within_fortnight: {
    pageInfo: PageInfo;
    edges: {
      cursor: string;
      node: DashboardCourseItem;
    }[];
  };
};

const LIMIT_PAGE_ITEM = 10;

function useCoursesData() {
  const [readyAssignees, onReadyAssignees] = useBoolean(false);
  const [readyIssues, onReadyIssues] = useBoolean(false);
  const [readyDeadline, onReadyDeadline] = useBoolean(false);
  const common = useCommonContext();
  const ability = buildAbilityFor(convertRoles(common.businessRoles));

  const skipCourses = !ability.can('view', 'Dashboard.courses');
  const skipNoAssignees = !ability.can('view', 'Dashboard.courses.noAssignees');
  const skipHasIssues = !ability.can('view', 'Dashboard.courses.hasIssues');
  const skipDeadline = !ability.can('view', 'Dashboard.courses.deadline');

  const onCompletedAssignees = useCallback(() => onReadyAssignees.on(), [onReadyAssignees]);
  const onCompletedIssues = useCallback(() => onReadyIssues.on(), [onReadyIssues]);
  const onCompletedDeadline = useCallback(() => onReadyDeadline.on(), [onReadyDeadline]);

  useEffect(() => {
    if (skipCourses && common.businessRoles) {
      onCompletedAssignees();
      onCompletedIssues();
      onCompletedDeadline();
    }
    if (skipNoAssignees && common.businessRoles) {
      onCompletedAssignees();
    }
    if (skipHasIssues && common.businessRoles) {
      onCompletedIssues();
    }
    if (skipDeadline && common.businessRoles) {
      onCompletedDeadline();
    }
  }, [
    common.businessRoles,
    onCompletedAssignees,
    onCompletedDeadline,
    onCompletedIssues,
    skipCourses,
    skipDeadline,
    skipHasIssues,
    skipNoAssignees,
  ]);

  //Courses widget
  const coursesWidgetData = useQuery<CoursesWidgetData>(GET_DASHBOARD_COURSES_WIDGET, {
    notifyOnNetworkStatusChange: true,
    skip: skipCourses,
    fetchPolicy: 'network-only',
  });

  //No assignees
  const coursesNoAssigneesData = useQuery<CoursesNoAssigneesData>(GET_DASHBOARD_COURSES_NO_ASSIGNEES, {
    variables: {
      offset: 0,
      first: LIMIT_PAGE_ITEM,
    },
    notifyOnNetworkStatusChange: true,
    skip: skipCourses || skipNoAssignees,
    onCompleted: onCompletedAssignees,
    fetchPolicy: 'network-only',
  });

  const onLoadMoreNoAssignees = () =>
    coursesNoAssigneesData.fetchMore({
      variables: {
        after: coursesNoAssigneesData.data?.front_page_courses_no_assignees.pageInfo.endCursor,
        first: LIMIT_PAGE_ITEM,
      },
    });

  // With issues
  const coursesWithIssuesData = useQuery<CoursesWithIssuesData>(GET_DASHBOARD_COURSES_WITH_ISSUES, {
    variables: {
      offset: 0,
      first: LIMIT_PAGE_ITEM,
    },
    notifyOnNetworkStatusChange: true,
    skip: skipCourses || skipHasIssues,
    onCompleted: onCompletedIssues,
    fetchPolicy: 'network-only',
  });

  const onLoadMoreWithIssues = () =>
    coursesWithIssuesData.fetchMore({
      variables: {
        after: coursesWithIssuesData.data?.front_page_courses_with_issues.pageInfo.endCursor,
        first: LIMIT_PAGE_ITEM,
      },
    });

  //Deadline within fortnight
  const coursesWithDeadlineData = useQuery<CoursesWithDeadlinesData>(GET_DASHBOARD_COURSES_DEADLINE, {
    variables: {
      offset: 0,
      first: LIMIT_PAGE_ITEM,
    },
    notifyOnNetworkStatusChange: true,
    skip: skipCourses || skipDeadline,
    onCompleted: onCompletedDeadline,
    fetchPolicy: 'network-only',
  });

  const onLoadMoreWithDeadline = () =>
    coursesWithDeadlineData.fetchMore({
      variables: {
        after: coursesWithDeadlineData.data?.front_page_courses_deadline_within_fortnight.pageInfo.endCursor,
        first: LIMIT_PAGE_ITEM,
      },
    });

  return {
    coursesReady: readyAssignees && readyIssues && readyDeadline,
    coursesLoading: coursesNoAssigneesData.loading || coursesWithIssuesData.loading || coursesWithDeadlineData.loading,
    coursesWidgetData: coursesWidgetData.data?.front_page_courses,
    coursesNoAssigneesData: {
      data: coursesNoAssigneesData.data?.front_page_courses_no_assignees.edges.map((el) => el.node),
      hasNextPage: coursesNoAssigneesData.data?.front_page_courses_no_assignees.pageInfo.hasNextPage,
      fetchMore: onLoadMoreNoAssignees,
    },
    coursesWithIssuesData: {
      data: coursesWithIssuesData.data?.front_page_courses_with_issues.edges.map((el) => el.node),
      hasNextPage: coursesWithIssuesData.data?.front_page_courses_with_issues.pageInfo.hasNextPage,
      fetchMore: onLoadMoreWithIssues,
    },
    coursesWithDeadlineData: {
      data: coursesWithDeadlineData.data?.front_page_courses_deadline_within_fortnight.edges.map((el) => el.node),
      hasNextPage: coursesWithDeadlineData.data?.front_page_courses_deadline_within_fortnight.pageInfo.hasNextPage,
      fetchMore: onLoadMoreWithDeadline,
    },
  };
}

export default useCoursesData;
