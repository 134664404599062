import React from 'react';

import { Progress } from 'antd';
import { ProgressProps } from 'antd/lib/progress/progress';

import withCommonProps from '../../hocs/withCommonProps';
import { TemplateCommonProps } from '../../common/commonPropsProvider';
import { cx } from '../../utils';

import styles from './ProgressLine.module.scss';

type ProgressLoadingProps = {
  className?: string;
  progress?: number | null;
};

function ProgressLine({
  className,
  type,
  percent,
  progress,
  showInfo = true,
  ...props
}: TemplateCommonProps & ProgressProps & ProgressLoadingProps) {
  if (!percent) {
    return null;
  }

  const classNames = cx(styles.container, className);

  return (
    <div className={classNames}>
      <Progress
        percent={percent || progress || 0}
        showInfo={showInfo}
        style={{ width: '100px' }}
        trailColor="#E9E9E9"
        type={type}
        {...props}
      />
    </div>
  );
}

export default withCommonProps(ProgressLine);
