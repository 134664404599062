import API from 'api';
import { OutputData } from '@editorjs/editorjs/types/data-formats/output-data';

import { ApiModules, ENDPOINTS } from '../constants';

type AddCommentPayload = {
  message: string;
};

export const addCommentToDiscussion = (taskId: string, payload: AddCommentPayload) =>
  API(ApiModules.PM).post(ENDPOINTS.ADD_COMMENT_TO_DISCUSSION(taskId), payload);

export const changeRollbackPossibility = (taskId: string) => API(ApiModules.PM).put(ENDPOINTS.CHANGE_ROLLBACK(taskId));

type InitProjectTaskPayload = {
  project_id: number;
  name: string;
  deadline_production: string;
  deadline_first_iteration_review?: string;
  task_type: string;
  assignees: number[];
};

export type InitProjectTaskResponse = {
  id: number;
  project_id: number;
  name: string;
  deadline: string;
  assignees: number[];
};

export const initProjectTask = (payload: InitProjectTaskPayload): Promise<any | InitProjectTaskResponse> =>
  API(ApiModules.PM).post(ENDPOINTS.PROJECT_TASK_INIT, payload);

type DescriptionProjectTaskPayload = OutputData;
type DescriptionProjectTaskResponse = {
  artefact_id: number;
  document_file_version_id: number;
  version_id: number;
};

export const addDescriptionProjectTask = (
  projectTaskId: string,
  payload: DescriptionProjectTaskPayload
): Promise<any | DescriptionProjectTaskResponse> =>
  API(ApiModules.ARTEFACT_MANAGEMENT).post(ENDPOINTS.PROJECT_TASK_DESCRIPTION(projectTaskId), payload);

export type CreateSeparateTaskResponse = {
  id: number;
};

export const createSeparateTask = (projectTaskId: string): Promise<any | CreateSeparateTaskResponse> =>
  API(ApiModules.PM).post(ENDPOINTS.CREATE_SEPARATE_TASK(projectTaskId));

type UpdateProjectTaskAssigneePayload = {
  assignees: number[];
};

export const updateProjectTaskAssignee = (
  projectTaskId: string,
  payload: UpdateProjectTaskAssigneePayload
): Promise<any> => API(ApiModules.PM).put(ENDPOINTS.PROJECT_TASK_ASSIGNEE(projectTaskId), payload);

export const removeSeparateTask = (projectTaskId: string): Promise<any> =>
  API(ApiModules.PM).delete(ENDPOINTS.UPDATE_SEPARATE_TASK(projectTaskId));

type UpdateSeparateTaskStatePayload = {
  state_id: number;
};

export const updateSeparateTaskState = (projectTaskId: string, payload: UpdateSeparateTaskStatePayload): Promise<any> =>
  API(ApiModules.PM).put(ENDPOINTS.PROJECT_TASK_STATE_UPDATE(projectTaskId), payload);

export type UpdateSeparateTaskPayload = {
  deadline_production: string;
  deadline_first_iteration_review?: string;
};

export const updateSeparateTask = (projectTaskId: string, payload: UpdateSeparateTaskPayload): Promise<any> =>
  API(ApiModules.PM).put(ENDPOINTS.UPDATE_SEPARATE_TASK(projectTaskId), payload);

type CreateSeparateTaskMessagePayload = {
  message: string;
  parent_message_id?: string;
};

export const createSeparateTaskMessage = (
  projectTaskId: string,
  payload: CreateSeparateTaskMessagePayload
): Promise<any> => API(ApiModules.PM).post(ENDPOINTS.PROJECT_TASK_CREATE_MESSAGE(projectTaskId), payload);

type UpdateSeparateTaskMessagePayload = {
  message: string;
};

export const updateSeparateTaskMessage = (messageId: string, payload: UpdateSeparateTaskMessagePayload): Promise<any> =>
  API(ApiModules.PM).put(ENDPOINTS.PROJECT_TASK_UPDATE_MESSAGE(messageId), payload);

export const removeSeparateTaskMessage = (messageId: string): Promise<any> =>
  API(ApiModules.PM).delete(ENDPOINTS.PROJECT_TASK_UPDATE_MESSAGE(messageId));
