import React, { memo, RefObject } from 'react';

import moment from 'moment';

import './styles.scss';

type GanttHeadProps = {
  monthRange?: {
    monthData: string[];
    monthYearData: string[];
  } | null;
  headRef: RefObject<HTMLDivElement>;
};

function GanttHead({ headRef, monthRange }: GanttHeadProps) {
  return (
    <div className="gantt-head">
      <div ref={headRef} className="gantt-head__wrap">
        {monthRange?.monthData?.map((el, index) => {
          const daysInMount = moment(monthRange.monthYearData[index]).daysInMonth();
          const styles = {
            width: daysInMount * 4,
          };
          return (
            <div style={styles} className="gantt-head__item" key={index}>
              {el}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default memo(GanttHead);
