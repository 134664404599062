import React, { useState } from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { cx } from 'utils';
import { CompanyArtefactTypeWithId } from 'types/entities';

import HeaderModal from 'components/modal/components/header';
import Select from 'components/select/Select';
import Label from 'components/label/Label';

import './styles.scss';
import { ArtefactPipelineSequence } from '../../hooks/queries/getArtefactPipeline';
import Button from '../../components/button';
import { ButtonTypesEnum } from '../../components/button/types';
import { onAddSequence } from '../../api/requests/companyState';

type Props = {
  artFlowStepStateOptions: DefaultOptionType[];
  artefactType: CompanyArtefactTypeWithId;
  companyStatesForSelect: DefaultOptionType[];
  sequences: ArtefactPipelineSequence[];
  onCancel: () => void;
  onRefetch: () => Promise<void>;
};

const AddSequenceRule = ({
  artFlowStepStateOptions,
  sequences,
  artefactType,
  companyStatesForSelect,
  onCancel,
  onRefetch,
}: Props) => {
  const [state, setState] = useState<string | null>(null);
  const [nextArt, setNextArt] = useState<string | null>(null);
  const [nextState, setNextState] = useState<string | null>(null);

  const sequenceOptions = sequences
    .filter((el) => el.artefact_type.id !== artefactType.id)
    .map((el) => {
      return {
        id: el.id,
        label: el.artefact_type.name,
        value: el.id,
      };
    });

  const onChangeState = (value: string) => {
    setState(value);
  };
  const onChangeNextArt = (value: string) => {
    setNextArt(value);
  };
  const onChangeNextState = (value: string) => {
    setNextState(value);
  };

  const onSubmit = async () => {
    const payload = {
      artefact_flow_step_id: Number(state),
      artefact_pipeline_step_sequence_id: Number(nextArt),
      set_state_id: Number(nextState),
    };
    await onAddSequence(payload);
    await onRefetch().then(() => onCancel());
  };

  const classNames = cx('ccm-modal--wrapper');
  return (
    <div className={classNames}>
      <HeaderModal isSmallTitle title="Seuqence rule" />
      <div className="add-sequence-rule__form">
        <Label text={`If ${artefactType.name} state:`}>
          <Select
            className="mb_8 nodrag"
            noForm
            size="large"
            placeholder="Select status"
            value={state}
            options={artFlowStepStateOptions}
            onChange={onChangeState}
          />
        </Label>
        <ArrowRightOutlined style={{ fontSize: 20 }} />
        <Label text="Then set state for:">
          <Select
            className="mb_8 nodrag"
            noForm
            size="large"
            placeholder="Select status"
            disabled={!state}
            value={nextArt}
            options={sequenceOptions}
            onChange={onChangeNextArt}
          />
        </Label>
        <Label text="State">
          <Select
            className="mb_8 nodrag"
            noForm
            size="large"
            placeholder="Select status"
            disabled={!nextArt}
            value={nextState}
            options={companyStatesForSelect}
            onChange={onChangeNextState}
          />
        </Label>
      </div>
      <div className="add-sequence-rule__footer">
        <Button size="large" disabled={!nextState} type={ButtonTypesEnum.primary} onClick={onSubmit}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddSequenceRule;
