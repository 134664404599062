import React, { memo } from 'react';

import { cx } from 'utils';

import { IssuePointProps } from './types';

import './styles.scss';

function IssuePoint({ className, classNameInner, style, type, active, info, onClick }: IssuePointProps) {
  const classNames = cx('issue-point', className, {
    [`issue-point--${type}`]: type,
    'issue-point--active': active,
  });
  const classNamesInner = cx('issue-point__inner', classNameInner);

  const handleClick = (e: any) => {
    e.nativeEvent.stopImmediatePropagation();
    onClick?.(info);
  };

  return (
    <div onClick={handleClick} style={style} className={classNames}>
      <div className={classNamesInner}></div>
    </div>
  );
}

export default memo(IssuePoint);
