import React from 'react';

import { Form, message } from 'antd';
import { cx } from 'utils';
import withCommonProps from 'hocs/withCommonProps';
import { noop } from 'lodash';
import { TemplateCommonProps } from 'common/commonPropsProvider';

import HeaderModal from 'components/modal/components/header';
import FooterModal from 'components/modal/components/footer';
import TextField from 'components/textField';
import Select from 'components/select';

import { getSystemRoles } from 'store/users/constants';
import { IBusinessRole, InvitePayload, IUserTableInfo, UserPatch } from 'store/users/interfaces';

import styles from '../inviteUser/unvite.module.scss';
import './styles.scss';

type EditUserProps = {
  onCancel: () => void;
  business_roles: IBusinessRole[] | null;
  onInviteUserRequest: (payload: InvitePayload) => void;
  companySlugId: string;
  onGetUsersRequest: (id: string) => void;
  row: IUserTableInfo;
  onPatchUserRequest: (payload: UserPatch) => void;
};

const EditUser = ({
  onCancel,
  row,
  onPatchUserRequest,
  business_roles,
  companySlugId,
  onGetUsersRequest,
  common,
}: EditUserProps & TemplateCommonProps) => {
  const remappedBusinessRolesValue = row?.business_roles?.map((role: any) => ({
    ...role,
    label: role.name,
    value: role.id,
  }));

  const onSubmit = async (values: any) => {
    let businessRolesIds;
    if (values.business_roles?.[0]?.id) {
      businessRolesIds = values.business_roles?.map((role: any) => role.id);
    } else {
      businessRolesIds = values.business_roles;
    }
    if (companySlugId && row.user.id) {
      const payload: UserPatch = {
        slugId: companySlugId,
        userId: row.user.id.toString(),
        params: {
          email: values.email || row?.user.email,
          company_slug: companySlugId,
          technical_role: values.technical_role || row?.technical_role,
          business_roles: businessRolesIds,
          first_name: values.first_name || row.user.first_name || '',
          last_name: values.last_name || row.user.last_name || '',
        },
      };
      await onPatchUserRequest(payload);
      await onGetUsersRequest(companySlugId);
      message.success(common.t<string>('modals.changesSaved'));
    }
    await onCancel();
  };
  const classNames = cx('ccm-modal--wrapper', styles.container);
  return (
    <div className={classNames}>
      <HeaderModal title={common.t<string>('common.user')} onClose={onCancel} className={styles.header} />
      <div className={styles.content}>
        <Form
          className="flex-col"
          initialValues={{
            first_name: row?.user?.first_name,
            last_name: row?.user?.last_name,
            email: row?.user?.email,
            technical_role: row?.technical_role,
            business_roles: remappedBusinessRolesValue,
          }}
          onFinish={onSubmit}
        >
          <TextField
            name="first_name"
            label={common.t<string>('form.field.firstName.label')}
            placeholder={common.t<string>('form.field.firstName.placeholder')}
            rules={[{ required: true, message: common.t<string>('errors.required.firstName') }]}
          />
          <TextField
            name="last_name"
            label={common.t<string>('form.field.lastName.label')}
            placeholder={common.t<string>('form.field.lastName.placeholder')}
            rules={[{ required: true, message: common.t<string>('errors.required.lastName') }]}
          />
          <TextField
            disabled
            name="email"
            label={common.t<string>('form.field.email.label')}
            placeholder={common.t<string>('form.field.email.placeholder')}
          />
          <Select
            options={getSystemRoles(common.t)}
            placeholder={common.t<string>('common.systemRole')}
            name="technical_role"
            label={common.t<string>('common.systemRole')}
          />
          <Select
            options={business_roles || []}
            placeholder={common.t<string>('common.businessRole')}
            name="business_roles"
            label={common.t<string>('common.businessRole')}
            mode="multiple"
            rules={[{ required: true, message: common.t<string>('errors.required.systemRole') }]}
          />
          <FooterModal
            labelCancel={common.t<string>('modals.cancel')}
            labelSubmit={common.t<string>('modals.save')}
            onCancel={onCancel}
            onSubmit={noop}
          />
        </Form>
      </div>
    </div>
  );
};

export default withCommonProps(EditUser);
