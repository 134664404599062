import React from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { cx } from 'utils';

import Button from '../../../button';
import Title from '../../../title';
import { TitleVariantEnum } from '../../../title/types';
import { ButtonTypesEnum } from '../../../button/types';

interface IHeaderModalProps {
  isSmallTitle?: boolean;
  title: string;
  onClose?: () => void;
  className?: string;
}

const HeaderModal = ({ isSmallTitle, title, onClose, className }: IHeaderModalProps) => {
  const classNames = cx('ccm-modal__header', className);

  return (
    <div className={classNames}>
      <Title variant={isSmallTitle ? TitleVariantEnum.h5 : TitleVariantEnum.h3}>{title}</Title>
      {onClose ? (
        <Button
          className="ccm-modal--button-close"
          onClick={onClose}
          icon={<CloseOutlined style={{ fontSize: 17 }} />}
          type={ButtonTypesEnum.text}
          size="xl"
        />
      ) : null}
    </div>
  );
};

export default HeaderModal;
