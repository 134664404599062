import { useRef } from 'react';

import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';

import { GET_PROJECT } from '../../queries/getProject';
import { ProjectsDataItem, ProjectUserItem } from '../../pages/courses/types';

export interface ProjectData extends ProjectsDataItem {}

function useProject(
  projectId: number,
  fetchPolicy?: WatchQueryFetchPolicy,
  loadCallback?: (data: ProjectData) => void
) {
  const hasFirstLoad = useRef(false);

  const { data, loading, refetch } = useQuery(GET_PROJECT, {
    variables: {
      id: projectId,
    },
    skip: !projectId,
    fetchPolicy: fetchPolicy || 'network-only',
    onCompleted: (d) => {
      loadCallback?.(d?.project);
      if (!hasFirstLoad.current) {
        hasFirstLoad.current = true;
      }
    },
  });

  return {
    hasFirstLoad,
    projectData: data?.project as ProjectData,
    projectRefetch: refetch,
    projectUsers: data?.project?.active_users as ProjectUserItem[],
    projectLoading: loading,
    learningObjectsExist: data?.project?.learning_objects_exist,
    separateTaskProgress: data?.project?.separate_tasks_progress,
  };
}

export default useProject;
