import React, { memo } from 'react';

import { Timeline } from 'antd';
import { declOfNum, declTitleTask, getFormatDateByStr } from 'utils';
import { isEmpty } from 'lodash';

import Popover from 'components/popover/Popover';
import EmptyData from 'components/emptyData';

import { ArtefactTimelineItem } from 'hooks';

import useCommonContext from 'hooks/useCommonContext';
import useCompanyUsers from 'hooks/queries/useCompanyUsers';

import TaskInfoPopover from '../taskInfoPopover';

import './styles.scss';

type DeadlinesProps = {
  timelineData: ArtefactTimelineItem;
};

function Deadlines({ timelineData }: DeadlinesProps) {
  const common = useCommonContext();
  const { companyUsersData } = useCompanyUsers(Number(common?.company?.id), 'cache-and-network');

  if (isEmpty(timelineData)) {
    return <EmptyData title={common.t<string>('pages.courses.drawer.emptyDeadlines')} />;
  }

  return (
    <div className="info-drawer-deadlines">
      <Timeline>
        {Object.values(timelineData).map((elem, index) => {
          const user = companyUsersData?.active_users?.find((item) => item?.user.id === elem?.creator?.id);

          return (
            <Timeline.Item key={index} color="#848484" className="info-drawer-deadlines-item">
              <Popover content={<TaskInfoPopover list={elem.items} />} trigger="click" placement="leftBottom">
                <span className="info-drawer-deadlines__value">{`${elem?.items?.length} ${common.t<string>(
                  declOfNum(elem?.items?.length, declTitleTask)
                )}`}</span>
              </Popover>

              <span className="info-drawer-deadlines__info">
                {user
                  ? `${user?.business_roles?.[0]?.name} ${user.user.name}  ${getFormatDateByStr(
                      elem.items[0]?.created_at,
                      'D MMMM YYYY HH:mm'
                    )}`
                  : `- ${getFormatDateByStr(elem.items[0]?.created_at, 'D MMMM YYYY HH:mm')}`}
              </span>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </div>
  );
}

export default memo(Deadlines);
