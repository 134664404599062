import API from 'api/api';
import { ApiModules, ENDPOINTS } from 'api/constants';

import { InvitePayload, UserPatch } from 'store/users/interfaces';

export const onInvite = (payload: InvitePayload): Promise<any> =>
  API(ApiModules.ACCOUNT).post(ENDPOINTS.INVITE, payload);
export const onGetUsers = (slugId: string): Promise<any> => API(ApiModules.ACCOUNT).get(ENDPOINTS.USERS(slugId));
export const onGetSystemRoles = (payload: any): Promise<any> => API(ApiModules.ACCOUNT).post(ENDPOINTS.ROLES, payload);
export const onGetBusinessRoles = (slugId: string): Promise<any> =>
  API(ApiModules.ACCOUNT).get(ENDPOINTS.BUSINESS_ROLES(slugId));
export const onChangeUser = ({ slugId, userId, params }: UserPatch): Promise<any> =>
  API(ApiModules.ACCOUNT).patch(ENDPOINTS.UPDATE_USER({ slugId, userId }), params);

export const onUpdateUserState = (usersId: string, state: 'INVITED' | 'BLOCKED'): Promise<any> =>
  API(ApiModules.ACCOUNT).put(ENDPOINTS.UPDATE_USER_STATE(usersId, state));
