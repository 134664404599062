import React, { useState } from 'react';

import { cx } from 'utils';
import { Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { onCreatePipeline } from 'api/requests/companyState';

import Select from 'components/select';
import HeaderModal from 'components/modal/components/header';
import Label from 'components/label';
import Button from 'components/button';
import { ButtonTypesEnum } from 'components/button/types';

import { CompanyArtefactType } from '../../hooks';

import './styles.scss';
import useBoolean from '../../hooks/useBoolean';

type Props = {
  companyId?: string;
  companyArtefactTypes: CompanyArtefactType[];
  onCancel: () => void;
};

const CreateNewPipeline = ({ companyId, companyArtefactTypes, onCancel }: Props) => {
  const [loading, onLoading] = useBoolean(false);
  const navigate = useNavigate();
  const [selected, setSelected] = useState<CompanyArtefactType | null>(null);
  const options = companyArtefactTypes.map((el) => {
    return {
      label: el.name,
      value: el.pk,
    };
  });

  const onChange = (value: string) => {
    const temp = companyArtefactTypes.find((el) => el.pk === value)!;
    setSelected(temp);
  };

  const onSubmit = async () => {
    if (selected && companyId) {
      onLoading.on();
      const payload = {
        company_id: Number(companyId),
        company_artefact_type_id: Number(selected.pk),
        steps: [
          {
            order: 1,
            sequences: [
              {
                order: 1,
                artefact_type_id: Number(selected.pk),
                management_data: {
                  position: {
                    x: -400,
                    y: 950,
                  },
                },
              },
            ],
          },
        ],
      };
      await onCreatePipeline(payload)
        .then((response: any) => {
          if (response?.id) {
            onLoading.off();
            const path = `/internal-management-settings/pipeline/edit/${response?.id}`;
            navigate(path);
            onCancel();
          }
        })
        .then(() => {
          onLoading.off();
          setSelected(null);
        });
    }
  };

  const classNames = cx('ccm-modal--wrapper, pipeline');
  return (
    <div style={{ width: 520 }} className={classNames}>
      <HeaderModal isSmallTitle title="Add new pipeline" />
      <Form layout="vertical">
        <Select
          size="xl"
          label="Final artefact"
          value={selected?.pk}
          options={options}
          placeholder="Select final artefact"
          onChange={onChange}
        />
        <Label text="Learning object type">{selected?.learning_object_type.name}</Label>
      </Form>
      <div className="pipeline__footer">
        <Button loading={loading} disabled={!selected} type={ButtonTypesEnum.primary} size="xl" onClick={onSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default CreateNewPipeline;
