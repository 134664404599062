import { useEffect, useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import { DocumentDiscussion } from 'hooks';

import { CONTAINER_DISCUSSION_ID, CONTAINER_ID } from './useDiscussionControl';

type ScrollDiscussionProps = {
  discussions?: DocumentDiscussion[];
};

function useScrollDiscussion({ discussions }: ScrollDiscussionProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const issue = searchParams.get('issue');

  useEffect(() => {
    const editorContainer = document.getElementById(CONTAINER_ID);
    const discussionContainer = document.getElementById(CONTAINER_DISCUSSION_ID);

    if (editorContainer) {
      const comments = document.querySelectorAll('.mark-comment');

      if (comments.length) {
        comments.forEach((comment) => {
          comment.addEventListener('click', () => {
            const hasClass = comment.classList.contains('mark-comment--active');
            const commentId = comment.getAttribute('id');
            const discussionId = discussions?.find((d) => d.external_id === commentId)?.id;

            //Listens to clicks on the editor and toggles the active state of the comment
            if (!hasClass) {
              searchParams.delete('issue');
              setSearchParams(searchParams);
              if (commentId) {
                comments.forEach((el) => {
                  el.classList.remove('mark-comment--active');
                });
                setSearchParams({ issue: commentId });
                comment.classList.add('mark-comment--active');
              }
            }

            if (discussionId) {
              const discussion = document.getElementById(discussionId);

              if (discussion && discussionContainer) {
                discussion.scrollIntoView({ block: 'start', behavior: 'smooth' });
              }
            }
          });
        });
      }
    }
  }, [discussions, searchParams, setSearchParams]);
}

export default useScrollDiscussion;
