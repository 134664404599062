import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';

import { GET_PROJECT_USERS_WITH_TASKS_AMOUNT } from '../../queries/getProjectUsersWithTasksAmount';

export type ProjectUserWithTasksAmount = {
  id: string;
  tasks_amount: number;
  company_user: {
    id: string;
    user: {
      id: string;
      name: string;
    };
  };
};

type ProjectUsersWithTasksAmountData = {
  project_users_with_tasks_amount: ProjectUserWithTasksAmount[];
};

function useProjectUsersWithTasksAmount(projectId?: number, fetchPolicy?: WatchQueryFetchPolicy) {
  const { data } = useQuery<ProjectUsersWithTasksAmountData>(GET_PROJECT_USERS_WITH_TASKS_AMOUNT, {
    variables: {
      project_id: projectId,
    },
    skip: !projectId,
    fetchPolicy: fetchPolicy || 'network-only',
  });

  return useMemo(() => {
    return {
      projectUsersWithTasksAmount: data?.project_users_with_tasks_amount,
    };
  }, [data?.project_users_with_tasks_amount]);
}

export default useProjectUsersWithTasksAmount;
