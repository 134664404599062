import React, { memo } from 'react';

import './styles.scss';

type ProgressTooltipProps = {
  title?: string;
  style?: React.CSSProperties;
  hidden?: boolean;
};

function ProgressTooltip({ title, style, hidden }: ProgressTooltipProps) {
  if (hidden) {
    return null;
  }
  return (
    <div className="progress-tooltip" style={style}>
      <span className="progress-tooltip__content">{title}</span>
    </div>
  );
}

export default memo(ProgressTooltip);
