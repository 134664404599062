import React, { createContext, FC, useMemo } from 'react';

import { useSelector } from 'react-redux';
import i18n, { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NavigateFunction } from 'react-router';
import { IStore } from 'types/store';
import { BusinessRoleEnum, TechnicalRoleEnum, UserRoleBase } from 'types/entities/user';
import { CcmUserState } from 'schemas/types';

import { CompanyInfo, Me } from 'hooks/queries/useMeInfo';

type Context = {
  user: Me | null;
  t: TFunction;
  navigate: NavigateFunction;
  userRole?: BusinessRoleEnum;
  technicalRole: TechnicalRoleEnum | undefined;
  userId?: number;
  company?: CompanyInfo;
  businessRole?: UserRoleBase;
  businessRoles?: UserRoleBase[];
  isExecutor?: boolean;
  businessRoleIds?: number[];
  userStatus?: CcmUserState;
  isStaff?: boolean;
};

export type TemplateCommonProps = {
  common: Context;
};

const initContext: Context = {
  user: null,
  t: i18n.t,
  company: undefined,
  businessRole: undefined,
  businessRoleIds: [],
  navigate: () => {},
  userRole: undefined,
  isExecutor: undefined,
  technicalRole: undefined,
  userId: undefined,
  userStatus: undefined,
  isStaff: undefined,
};

const CommonPropsContext = createContext(initContext);

export const CommonPropsProvider: FC<any> = ({ children }: React.PropsWithChildren<any>) => {
  const user = useSelector((state: IStore) => state.auth.me);
  const userId = useSelector((state: IStore) => state.auth.authData?.user_id);
  const userStatus = useSelector((state: IStore) => state.auth.me?.state);
  const company = useSelector((state: IStore) => state.auth.me?.companies[0]?.company);
  const businessRole = useSelector((state: IStore) => state.auth.me?.companies[0]?.business_roles[0]);
  const businessRoles = useSelector((state: IStore) => state.auth.me?.companies[0]?.business_roles);
  const isStaff = useSelector((state: IStore) => state.auth.me?.is_staff);

  const technicalRole = useSelector((state: IStore) => state.auth.me?.companies[0]?.technical_role);
  const businessRoleIds = useSelector((state: IStore) =>
    state.auth.me?.companies[0]?.business_roles?.map((b) => Number(b.id))
  );

  const navigate = useNavigate();
  const { t } = useTranslation();

  const userRole = businessRole?.role;

  const isExecutor = userRole === BusinessRoleEnum.author || userRole === BusinessRoleEnum.productionExecutor;

  //TODO Fake user role
  //const userRole = 'user';

  const valueMemoized = useMemo(
    () => ({
      isStaff,
      user,
      userId,
      userRole,
      userStatus,
      company,
      businessRole,
      businessRoles,
      businessRoleIds,
      technicalRole,
      isExecutor,
      //Functions
      navigate,
      t,
    }),
    [
      isStaff,
      businessRole,
      businessRoleIds,
      businessRoles,
      company,
      isExecutor,
      navigate,
      t,
      technicalRole,
      user,
      userId,
      userRole,
      userStatus,
    ]
  );

  return <CommonPropsContext.Provider value={valueMemoized}>{children}</CommonPropsContext.Provider>;
};

export default CommonPropsContext;
