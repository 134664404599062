import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';

import Spin from '../../../components/spin';

const SpinStories = () => {
  return (
    <div>
      <div className="mb_8">
        <Spin className="mr_8" size="small" />
        <Spin className="mr_8" />
        <Spin className="mr_8" size="large" />
        <Spin className="mr_8" size="large" tip="Loading..." />
      </div>
      <div>
        <Spin className="mr_8" size="small" indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
        <Spin className="mr_8" indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
        <Spin className="mr_8" size="large" indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        <Spin
          className="mr_8"
          size="large"
          tip="Loading..."
          indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
        />
      </div>
    </div>
  );
};

export default SpinStories;
