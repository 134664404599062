import React, { useMemo } from 'react';

import { Form, InputNumber } from 'antd';
import { cx } from 'utils';
import { CompanyState } from 'types/models/CompanyState';
import { noop } from 'lodash';

import HeaderModal from 'components/modal/components/header';
import TextField from 'components/textField';
import Select from 'components/select';
import Checkbox from 'components/checkbox';
import FooterModal from 'components/modal/components/footer';

import { LastState } from '../../api/requests/companyState';

import './styles.scss';

type Props = {
  title: string;
  onCancel: () => void;
  onSave: (values: LastState) => void;
};

const EditStateItem = ({ title, onCancel, onSave }: Props) => {
  const classNames = cx('ccm-modal--wrapper edit-list');

  const onSubmit = (values: LastState) => {
    onSave(values);
    onCancel();
  };

  const typeOptions = useMemo(() => {
    const typeOptionsArr = Object.keys(CompanyState.state_type);
    return typeOptionsArr.map((el) => {
      return {
        label: el,
        value: el.toLowerCase(),
      };
    });
  }, []);

  return (
    <div className={classNames}>
      <HeaderModal isSmallTitle title={title} onClose={onCancel} />
      <Form className="edit-list-form" name="edit-list" onFinish={onSubmit}>
        <TextField
          name="name"
          size="xl"
          placeholder="State item name"
          rules={[{ required: true, message: 'required field' }]}
        />
        <TextField name="button_name" size="xl" placeholder="Button name" />
        <Select
          name="state_type"
          size="xl"
          options={typeOptions}
          placeholder="Select type"
          rules={[{ required: true, message: 'required field' }]}
        />
        {/*<Form.Item name="order" style={{ minHeight: 72 }} rules={[{ required: true, message: 'required field' }]}>*/}
        {/*  <InputNumber className="edit-list__num" name="order" size="xl" placeholder="Order" />*/}
        {/*</Form.Item>*/}

        <Form.Item name="is_new_iteration_initiation" valuePropName="checked">
          <Checkbox>Is new iteration initiation</Checkbox>
        </Form.Item>
        <Form.Item name="is_require_discuss_message" valuePropName="checked">
          <Checkbox>Is require discuss message</Checkbox>
        </Form.Item>
        <Form.Item name="is_could_close_without_complete_issues" valuePropName="checked">
          <Checkbox>Is could close without complete issues</Checkbox>
        </Form.Item>

        <FooterModal labelCancel="Cancel" labelSubmit="Save" onCancel={onCancel} onSubmit={noop} isForm />
      </Form>
    </div>
  );
};

export default EditStateItem;
