import React from 'react';

import { Modal as AntDModal, ModalProps } from 'antd';

import { cx } from '../../utils';

import './styles.scss';

interface IModalProps extends ModalProps {
  show: boolean;
  className: string;
  onCancel: () => void;
}

const maskStyle = {
  backgroundColor: 'rgb(30, 30, 30, 0.8)',
};

const DumbModal = ({ show, className, children, onCancel, ...props }: IModalProps) => {
  const classNames = cx('ccm-modal', className);
  return (
    <AntDModal
      closable={false}
      maskClosable
      centered
      className={classNames}
      open={show}
      onCancel={onCancel}
      footer={null}
      {...props}
      maskStyle={maskStyle}
    >
      {children}
    </AntDModal>
  );
};

export default DumbModal;
