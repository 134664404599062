import React from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { cx } from 'utils';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

type UserElementButtonProps = {
  size?: 'm' | 'l' | 'xl';
  onClick?: () => void;
  disabled?: boolean;
};

const getIconSize = (size?: 'm' | 'l' | 'xl') => {
  switch (size) {
    case 'm':
      return 10;
    case 'l':
      return 14;
    case 'xl':
      return 20;
    default:
      return 14;
  }
};

function UserElementButton({ size, disabled, onClick }: UserElementButtonProps) {
  const common = useCommonContext();
  const classNames = cx('user-elem-btn', {
    [`user-elem-btn--${size}`]: size,
  });

  return (
    <button type="button" disabled={disabled} className={classNames} onClick={onClick}>
      <div className="user-elem-btn__icon-wrap">
        <PlusOutlined style={{ color: '#1E1E1E', fontSize: getIconSize(size) }} />
      </div>
      {disabled ? common.t<string>('common.notSelected') : common.t<string>('common.select')}
    </button>
  );
}

export default UserElementButton;
