import { IAction } from 'types/store';

import IterationsTypes from './types';
import IIterationsState from './interfaces';

const initialState: IIterationsState = {
  iterations: null,
  activeIteration: null,
  iterationById: null,
};

export default function (state = initialState, action: IAction<IterationsTypes>) {
  switch (action.type) {
    case IterationsTypes.SET_ITERATIONS_DATA:
      return {
        ...state,
        iterations: action.payload,
      };
    case IterationsTypes.SET_ACTIVE_ITERATION_DATA:
      return {
        ...state,
        activeIteration: action.payload,
      };
    case IterationsTypes.SET_ITERATION_BY_ID_DATA:
      return {
        ...state,
        iterationById: action.payload,
      };
    default:
      return state;
  }
}
