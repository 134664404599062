import React, { useCallback, useState } from 'react';

import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { RadioChangeEvent } from 'antd/lib/radio/interface';

import HeaderModal from '../../components/modal/components/header';
import { RadioGroup } from '../../components/radio';
import { cx } from '../../utils';
import FooterModal from '../../components/modal/components/footer';

import { radioButtonMap } from './constants';
import { StepAddMaterial, StepVideo } from './Steps';

import styles from './course.module.scss';

type FormValue = {
  [key: string]: string;
};

type CourseCreateElementModalProps = {
  onCancel: () => void;
};

function CourseCreateElement({ onCancel }: CourseCreateElementModalProps) {
  const [values, setValues] = useState({});
  const [radio, setRadio] = useState('video');
  const { t } = useTranslation();
  const classNames = cx('ccm-modal--wrapper', styles.container);
  const onSubmit = () => {};

  const onChangeValues = (changedValues: FormValue) => {
    setValues({ ...values, ...changedValues });
  };

  const onChangeRadio = useCallback((e: RadioChangeEvent) => {
    setRadio(e.target.value);
  }, []);

  return (
    <div className={classNames}>
      <HeaderModal title={t<string>('common.systemRole')} onClose={onCancel} />
      <div className="ccm-modal__content flex-col">
        <Form onValuesChange={onChangeValues} name="create_element" className="flex-col">
          <RadioGroup
            defaultValue="video"
            name="radio"
            className="mb_24"
            optionType="button"
            options={radioButtonMap(t)}
            value={radio}
            onChange={onChangeRadio}
          />
          {radio === 'video' ? <StepVideo /> : null}
          {radio === 'addMaterial' ? <StepAddMaterial /> : null}
        </Form>
      </div>
      <FooterModal
        labelSubmit={t('modals.createElement')}
        onSubmit={onSubmit}
        labelCancel={t('modals.cancel')}
        onCancel={onCancel}
      />
    </div>
  );
}

export default CourseCreateElement;
