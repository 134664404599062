import React, { memo, useCallback } from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { UserExtended } from 'types/entities';
import { noop } from 'lodash';
import { cx } from 'utils';

import Tooltip from 'components/tooltip';
import Block from 'components/block';
import UserElement from 'components/userElement';

import { CompanyRole } from 'hooks';

import useCommonContext from 'hooks/useCommonContext';

import UserElementButton from '../../../components/userElementButton';

import './styles.scss';

type SelectUsersProps = {
  canEdit?: boolean;
  label?: string;
  hint?: string;
  onClick: (roleId: string) => void;
  roleItem?: CompanyRole;
  users?: UserExtended[] | null;
};

function SelectUsersBlock({ canEdit, label, hint, roleItem, users, onClick }: SelectUsersProps) {
  const common = useCommonContext();
  const handleClick = useCallback(() => {
    if (roleItem?.id) {
      onClick(roleItem?.id);
    }
  }, [onClick, roleItem]);

  return (
    <div className="select-users">
      <Block className="select-users__row" hidden={!label && !hint}>
        {label && <span className="select-users__label">{label}</span>}
        <Block empty hidden={!hint}>
          <Tooltip title={hint}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Block>
      </Block>
      <Block className={cx({ 'select-users select-users__canEdit': canEdit })} hidden={!users}>
        {users?.map((u, index) => (
          <UserElement size="xl" key={u.id} user={u} hasPopover onClick={canEdit ? handleClick : noop} />
        ))}
      </Block>

      <Block empty hidden={!!users || !canEdit}>
        <UserElementButton size="xl" onClick={canEdit ? handleClick : noop} />
      </Block>
      <Block empty hidden={!!users || canEdit}>
        <span className="select-users__empty">{common.t<string>('common.notChosen')}</span>
      </Block>
    </div>
  );
}

export default memo(SelectUsersBlock);
