import tasks from 'assets/icons/tasks.svg';
import tasksActive from 'assets/icons/tasks-selected.svg';
import users from 'assets/icons/users.svg';
import usersActive from 'assets/icons/users-selected.svg';
import courses from 'assets/icons/courses.svg';
import coursesActive from 'assets/icons/courses-selected.svg';
import home from 'assets/icons/home.svg';
import homeActive from 'assets/icons/home-selected.svg';
import { INavigationItem } from 'types/global';

export const navMap: INavigationItem[] = [
  {
    name: 'pages.dashboard.title',
    path: '/dashboard',
    id: '1',
    testClassName: 'testclass-dashboard-top-panel-tab-dashboard',
    icon: home,
    iconActive: homeActive,
    protected: true,
  },
  {
    name: 'pages.tasks.title',
    path: '/tasks',
    id: '2',
    testClassName: 'testclass-dashboard-top-panel-tab-tasks',
    icon: tasks,
    iconActive: tasksActive,
  },
  {
    name: 'pages.courses.title',
    path: '/courses',
    id: '3',
    testClassName: 'testclass-dashboard-top-panel-tab-courses',
    icon: courses,
    iconActive: coursesActive,
  },
  {
    name: 'pages.users.title',
    path: '/users',
    id: '4',
    testClassName: 'testclass-dashboard-top-panel-tab-users',
    icon: users,
    iconActive: usersActive,
  },
];
