import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { message, Progress } from 'antd';
import { checkMimeTypePresentation, cx } from 'utils';
import { onCopyFile, onDeleteSlide, onUpdateSlide } from 'api/requests/files';
import withCommonProps from 'hocs/withCommonProps';
import { TemplateCommonProps } from 'common/commonPropsProvider';
import modalService from 'services/modalService';
import { ConfirmModalIcon } from 'modals/confirm/ConfirmModal';

import { createArtefactVersion } from '../../task/subPages/finalPresentation/fetches';
import { PresentationFile } from '../../task/types';
import Block from '../../../components/block';

import SlidePreviewControls from './SlidePreviewControls';
import { SlidePreviewProps } from './types';

import './styles.scss';
import { messageDurability } from '../../../constants';

type InitState = {
  source?: string;
  percent: number | null;
  loading: boolean;
  upload: number | null;
};

const initState: InitState = {
  source: undefined,
  percent: 50,
  loading: false,
  upload: null,
};

function SlidePreview({
  className,
  slide,
  isShowControls,
  totalSlides,
  count,
  fileId,
  refetch,
  progress,
  upload,
  changeOrder,
  common,
  artefactFileVersion,
  currentArtefact,
  onClickPreviewSlide,
}: SlidePreviewProps & TemplateCommonProps) {
  const [state, setState] = useState<InitState>(initState);
  const [hover, setHover] = useState<boolean>(false);
  const [onInputFile, setOnInputFile] = useState<boolean>(false);

  const classNames = cx('slide-preview', className);

  const setProgress = (progressEvent: any) => {
    let percentComplete = progressEvent.loaded / progressEvent.total;
    percentComplete = Number(percentComplete * 100);
    setState({ ...state, upload: percentComplete });
  };

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);
  const handleSetOnInputFile = () => setOnInputFile(true);

  const onReloadSlide = async (event: any) => {
    const selectedFile = event?.target?.files[0];
    if (selectedFile) {
      const { isImages } = checkMimeTypePresentation(selectedFile);
      if (!isImages) {
        message.error(common.t<string>('errors.messages.upload.onlyImage'), messageDurability);
      } else {
        const formData = new FormData();
        formData.append('file', selectedFile);
        if (artefactFileVersion === null || artefactFileVersion?.is_active) {
          const newFile = await onCopyFile(Number(fileId));
          const newArtefactVersion = await createArtefactVersion({
            file_id: Number(newFile?.id),
            artefact_id: Number(currentArtefact?.id),
          });
          const newOrderedFile = newFile?.file_list.find(
            (fileItem: PresentationFile) => fileItem.order === slide?.order
          );
          await onUpdateSlide(
            Number(newArtefactVersion?.file_id),
            Number(newOrderedFile?.id),
            formData,
            setProgress
          ).then(() => {
            refetch?.().then(() => setState({ ...state, upload: null }));
          });
          setOnInputFile(false);
        } else {
          await onUpdateSlide(Number(fileId), Number(slide?.id), formData, setProgress).then(() => {
            refetch?.().then(() => setState({ ...state, upload: null }));
          });
          setOnInputFile(false);
        }
      }
    } else {
      setOnInputFile(false);
    }
  };

  const onConfirmDeleteSlide = async () => {
    if (artefactFileVersion === null || artefactFileVersion?.is_active) {
      const newFile = await onCopyFile(Number(fileId));
      const newArtefactVersion = await createArtefactVersion({
        file_id: Number(newFile?.id),
        artefact_id: Number(currentArtefact?.id),
      });
      const newOrderedFile = newFile?.file_list.find((fileItem: PresentationFile) => fileItem.order === slide?.order);
      if (newFile) {
        await onDeleteSlide(Number(newFile?.id), Number(newOrderedFile?.id));
        await refetch();
      }
    } else {
      onDeleteSlide(Number(fileId), Number(slide?.id)).then(() => refetch?.());
    }
  };

  const onClickDeleteSlide = () => {
    modalService.openConfirmModal({
      icon: ConfirmModalIcon.warning,
      title: common.t<string>('modals.deleteSlide'),
      text: common.t<string>('modals.deleteSlideText'),
      labelCancel: common.t<string>('modals.no'),
      labelConfirm: common.t<string>('modals.yesDelete'),
      onConfirm: onConfirmDeleteSlide,
    });
  };

  const onMoveLeft = async () => {
    if (slide?.order) {
      if (artefactFileVersion === null || artefactFileVersion?.is_active) {
        const newFile = await onCopyFile(Number(fileId));
        const newArtefactVersion = await createArtefactVersion({
          file_id: Number(newFile?.id),
          artefact_id: Number(currentArtefact?.id),
        });
        if (newFile && newArtefactVersion) {
          await changeOrder?.(slide.order, slide.order - 1, Number(newFile?.id), newFile?.file_list);
          await refetch();
        }
      } else {
        changeOrder?.(slide.order, slide.order - 1);
      }
    }
  };

  const onMoveRight = async () => {
    if (slide?.order) {
      if (artefactFileVersion === null || artefactFileVersion?.is_active) {
        const newFile = await onCopyFile(Number(fileId));
        const newArtefactVersion = await createArtefactVersion({
          file_id: Number(newFile?.id),
          artefact_id: Number(currentArtefact?.id),
        });
        if (newFile && newArtefactVersion) {
          await changeOrder?.(slide.order, slide.order + 1, Number(newFile?.id), newFile?.file_list);
          await refetch();
        }
      } else {
        changeOrder?.(slide.order, slide.order + 1);
      }
    }
  };

  if (upload) {
    return (
      <div className={classNames}>
        <div className="slide-preview__btn">
          {progress ? (
            <div className="slide-preview__load">
              <span className="slide-preview__text">{common.t<string>('common.loading')}</span>
              <Progress percent={progress} size="small" showInfo={false} strokeColor="#1E1E1E" strokeWidth={5} />
            </div>
          ) : (
            <>
              <PlusOutlined />
              {/*<span className="slide-preview__text">Loading...</span>*/}
            </>
          )}
        </div>
      </div>
    );
  }

  const onClickSlide = () => {
    if (slide) {
      onClickPreviewSlide?.(slide.order);
    }
  };

  return (
    <div onClick={onClickSlide} className={classNames} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="slide-preview__bg">
        <Block empty hidden={!isShowControls}>
          {hover || onInputFile ? (
            <SlidePreviewControls
              onClickDeleteSlide={onClickDeleteSlide}
              onReloadSlide={onReloadSlide}
              onMoveRight={onMoveRight}
              onMoveLeft={onMoveLeft}
              handleSetOnInputFile={handleSetOnInputFile}
              disabledRight={slide?.order === 1}
              disabledLeft={slide?.order === totalSlides}
            />
          ) : null}
        </Block>

        <img
          className="slide-preview__image"
          onLoad={(e) => setState({ ...state, loading: true })}
          src={slide?.source_url}
          alt="image"
        />
        {state.upload || !state.loading ? (
          <div className="slide-preview__load">
            <span className="slide-preview__text">Loading...</span>
            <Progress
              percent={state.upload || 25}
              size="small"
              showInfo={false}
              strokeColor="#1E1E1E"
              strokeWidth={5}
            />
          </div>
        ) : null}
      </div>
      <span className="slide-preview__count">{count}</span>
    </div>
  );
}

export default withCommonProps(SlidePreview);
