import { IAction } from 'types/store';

import IUsersState, { IBusinessRole } from './interfaces';
import UsersTypes from './types';

const initialState: IUsersState = {
  users: null,
  business_roles: null,
};

export default function (state = initialState, action: IAction<UsersTypes>) {
  switch (action.type) {
    case UsersTypes.SET_USERS_DATA:
      return {
        ...state,
        users: action.payload,
      };
    case UsersTypes.CLEAR_USERS_DATA:
      return {
        ...state,
        users: [],
      };
    case UsersTypes.SET_BUSINESS_ROLES_DATA:
      const rolesRemapped = action.payload?.map((el: IBusinessRole) => ({ ...el, label: el.name, value: el.id }));
      return {
        ...state,
        business_roles: rolesRemapped,
      };
    default:
      return state;
  }
}
