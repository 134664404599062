import React from 'react';

export enum IssuePointTypeEnum {
  new = 'new',
}

export type IssuePointProps = {
  className?: string;
  classNameInner?: string;
  style?: React.CSSProperties;
  type?: IssuePointTypeEnum;
  active?: boolean;
  info?: any;
  onClick?: (data: any) => void;
};
