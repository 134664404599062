import React, { useState } from 'react';

import Button from 'components/button/Button';
import Drawer from 'components/drawer/Drawer';
import DrawerHeader from 'components/drawer/DrawerHeader';
import DrawerFooter from 'components/drawer/DrawerFooter';

const DrawerStories = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className="mb_8">
      <Button onClick={showDrawer}>open</Button>
      <Drawer
        title={<DrawerHeader onClose={onClose} title="Drawer Title" />}
        footer={<DrawerFooter labelSubmit="Create" onSubmit={onClose} labelCancel="Cancel" onCancel={onClose} />}
        closable={false}
        placement="right"
        visible={open}
      >
        <p>Some contents...</p>
      </Drawer>
    </div>
  );
};

export default DrawerStories;
