import React from 'react';

import { notification } from 'antd';

import Button from '../../../components/button/Button';

const NotificationStories = () => {
  const key = `open${Date.now()}`;
  const closeNotification = () => {
    notification.destroy(key);
  };
  const showNotification = () => {
    notification.open({
      message: 'Notification Title',
      description:
        'Proactively incubate innovative processes for high-payoff architectures. Globally benchmark flexible.',
      btn: (
        <Button onClick={closeNotification} size="middle">
          Confirm
        </Button>
      ),
      type: 'info',
      key,
      onClose: close,
    });
  };
  return (
    <div className="mb_8">
      <Button onClick={showNotification}>open</Button>
    </div>
  );
};

export default NotificationStories;
