import { gql } from '@apollo/client';

export const LAST_STATE = gql`
  fragment LastState on CompanyStateType {
    id
    name
    state_type
    icon_image
  }
`;

export const USER_TYPE = gql`
  fragment UserData on UserType {
    id
    email
    name
    first_name
    last_name
    state
    timezone
    icon_image
  }
`;

export const USER_ROLE_TYPE = gql`
  fragment RoleData on UserRoleType {
    id
    name
    role
    pk
  }
`;

export const COMPANY_TYPE = gql`
  fragment CompanyData on CompanyType {
    id
    name
    slug
    company_type
  }
`;

export const COMPANY_STATE_TYPE = gql`
  fragment CompanyState on CompanyStateType {
    id
    name
    state_type
    icon_image
  }
`;

export const PAGE_INFO = gql`
  fragment PageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;

export const FRONT_PAGE_TASKS = gql`
  fragment FrontPageTasks on ProjectTaskFPNodeType {
    id
    pk
    name
    last_state_updated_at
    last_state_is_changed
    last_state {
      id
      name
      state_type
      icon_image
    }
    project {
      id
      name
      active_users {
        id
        company_user {
          id
          company {
            id
            name
          }
          user {
            id
            name
            state
            email
            icon_image
            timezone
          }
          business_roles {
            id
            role
            name
          }
        }
      }
    }
    cached_linked_artefact {
      pk
      first_iteration_deadline
      first_iteration_delay_duration_summary
      production_deadline
      delay_duration_summary
      artefact_iteration_count
      production_deadline_delay
      first_iteration_deadline_delay
      messages_amount
      artefact_type {
        name
        type
        icon_image
      }
      artefact_versions {
        file {
          file_type
        }
      }
    }
    artefact_production_sequence {
      project_task {
        id
        last_state_updated_at
        last_state_is_changed
        last_state {
          state_type
          name
        }
      }
      project_learning_object {
        id
        name
        learning_object_type {
          icon_image
        }
        parent {
          human_readable_number
        }
      }
    }
    current_assignees {
      project_task {
        id
      }
      is_current_executor
      project_user {
        company_user {
          company {
            id
            name
          }
          user {
            id
            name
            first_name
            last_name
            icon_image
            email
            state
          }
        }
        roles {
          role {
            id
            name
            role
          }
        }
      }
    }
    separate_task {
      id
      cached_artefact_iteration_count
      deadline_first_iteration_review
      deadline_production
      delay_duration_summary
      delay_duration_summary_days
      first_iteration_delay_duration_summary
      first_iteration_delay_duration_summary_days
      first_iteration_deadline_changed
      production_deadline_changed
      is_delayed
      project_task {
        id
      }
      task_type {
        id
        name
        icon_image
      }
    }
  }
`;

export const SEPARATE_TASK = gql`
  fragment SeparateTask on SeparateProjectTaskType {
    id
    cached_artefact_iteration_count
    deadline_first_iteration_review
    deadline_production
    delay_duration_summary
    delay_duration_summary_days
    first_iteration_delay_duration_summary
    first_iteration_delay_duration_summary_days
    first_iteration_deadline_changed
    production_deadline_changed
    is_delayed
    project_task {
      id
    }
    task_type {
      id
      name
      icon_image
    }
  }
`;

export const PIPELINE_VERSION = gql`
  fragment PipelineVersion on ArtefactPipelineVersionType {
    id
    steps {
      id
      order
      sequences {
        id
        order
        is_final
        management_data
        artefact_type {
          id
          name
          type
        }
        default_state {
          id
          name
          state_type
          button_name
          order
          company_state_list_version {
            id
          }
        }
        sequence_rules {
          id
          pk
          is_active_rule
          artefact_pipeline_step_sequence {
            artefact_type {
              id
              name
              type
            }
          }
          artefact_flow_step {
            id
            state_initiation {
              id
              name
              state_type
            }
          }
          set_state {
            id
            name
            icon_image
            state_type
          }
        }
      }
    }
  }
`;
