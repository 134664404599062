import { useState, useEffect, useRef } from 'react';

type Event = MouseEvent | TouchEvent;

export default function useVisible(initialIsVisible: boolean, callback?: () => void) {
  const [isVisible, setIsVisible] = useState<boolean>(initialIsVisible);
  const ref = useRef<any>(null);
  const buttonRef = useRef<any>(null);

  const handleClickOutside = (event: Event) => {
    if (
      ref.current &&
      ref.current?.contains &&
      !ref.current?.contains(event.target) &&
      ((buttonRef.current && !buttonRef.current?.contains(event.target)) || !buttonRef.current)
    ) {
      setIsVisible(false);
      callback?.();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isVisible, setIsVisible, buttonRef };
}
