import { useQuery } from '@apollo/client';

import { GET_ARTEFACT_FLOW_STEPS_ADMIN } from '../../queries/getArtefactFlowStepsAdmin';
import { convertToEdge, convertToNode } from '../../pages/managementSettings/components/artefactFlow/untils';

export type PositionManagement = {
  position: {
    x: number;
    y: number;
  };
};

export type HandleManagement = {
  sourceHandle: string;
  targetHandle: string;
};

type StateInitiation = {
  id: string;
  state_type: string;
  name: string;
  button_name: string;
  company_state_list_version: {
    id: string;
  };
};

export type FlowSteps = {
  pk: string;
  id: string;
  management_data: string;
  assignee_user_role: {
    id: string;
    role: string;
  };
  state_initiation: StateInitiation;
  possible_next_steps: {
    management_data: string;
    order: number;
    state_next: {
      pk: string;
      state_initiation: StateInitiation;
    };
  }[];
};

type FlowStepsData = {
  artefact_flow_steps_admin: {
    edges: {
      node: FlowSteps;
    }[];
  };
};

const useArtefactFlowStepsAdmin = (artTypeId: string | null, listVerId?: string) => {
  const { data, refetch } = useQuery<FlowStepsData>(GET_ARTEFACT_FLOW_STEPS_ADMIN, {
    variables: {
      company_artefact_type_id: Number(artTypeId),
      company_state_list_version_id: Number(listVerId),
    },
    skip: !artTypeId || !listVerId,
  });

  const flowSteps = data?.artefact_flow_steps_admin?.edges?.map((el) => el?.node);

  return {
    flowSteps: flowSteps,
    stepsNode: convertToNode(flowSteps),
    stepsEdge: convertToEdge(flowSteps),
    refetch,
  };
};

export default useArtefactFlowStepsAdmin;
