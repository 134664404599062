import React, { memo, useCallback, useMemo } from 'react';

import { getFormatDateByStr, getUrlToTask } from 'utils';
import { BusinessRoleEnum } from 'types/entities';
import { useNavigate } from 'react-router-dom';

import Tag from 'components/tag';
import { TagShapeEnum } from 'components/tag/types';
import DeadlineIndicator from 'components/deadlineIndicator';
import StatusLabelFlat from 'components/statusLabelFlat/StatusLabelFlat';
import { ProjectStatusChangedPopup } from 'components/historyPopup';

import { CurrentArtefactProductionSequence, TaskAssignee } from 'hooks';

import useGlobalActionsContext from 'hooks/useGlobalActionsContext';
import useCommonContext from 'hooks/useCommonContext';

import './tasks.scss';

type TaskItemProps = {
  learningObjectId?: string;
  artefact: CurrentArtefactProductionSequence;
  projectId: string;
  assignees?: TaskAssignee[];
  onClose?: () => void;
};

function TaskItem({ learningObjectId, projectId, artefact, assignees, onClose }: TaskItemProps) {
  const { data, setData } = useGlobalActionsContext();
  const common = useCommonContext();
  const navigate = useNavigate();

  //Iteration First Deadline
  const iterationDeadline = artefact.project_task.cached_linked_artefact?.first_iteration_deadline;
  const iterationDeadlineDelay = artefact.project_task.cached_linked_artefact?.first_iteration_deadline_delay;

  //Production Second Deadline
  const productionDeadline = artefact.project_task.cached_linked_artefact?.production_deadline;
  const productionDeadlineDelay = artefact.project_task.cached_linked_artefact?.production_deadline_delay;

  const viewAssignee = useMemo(() => {
    const filteredAssignees = assignees?.filter((a) => a.project_task.id === artefact.project_task.id && a.is_active);
    return filteredAssignees?.find(
      (r) =>
        r?.by_artefact_role_assignment?.user_role.role === BusinessRoleEnum.productionExecutor ||
        r?.by_artefact_role_assignment?.user_role.role === BusinessRoleEnum.author
    );
  }, [artefact.project_task.id, assignees]);

  const path = getUrlToTask(
    learningObjectId,
    artefact?.project_task?.id,
    artefact?.project_task?.cached_linked_artefact?.artefact_iteration_count,
    artefact?.project_task?.cached_linked_artefact?.id,
    artefact?.project_task?.cached_linked_artefact?.artefact_type?.type,
    artefact?.project_task?.cached_linked_artefact?.artefact_versions[0]?.file?.file_type
  );

  const onClickLink = useCallback(() => {
    const url = `${window.location.pathname}${window.location.search}`;
    setData({ ...data, prevUrl: url });
    onClose?.();
    navigate(path);
  }, [data, navigate, onClose, path, setData]);

  return (
    <div role="button" onClick={onClickLink} className="modal-preview-task">
      <div className="modal-preview-task__art-type">
        <Tag shape={TagShapeEnum.circle}>{artefact.project_task.cached_linked_artefact.artefact_type.name}</Tag>
      </div>
      <span className="modal-preview-task__user-name">
        {viewAssignee?.project_user?.company_user?.user.name || '-'}
      </span>
      <span className="modal-preview-task__iteration">
        {`№${artefact.project_task.cached_linked_artefact.artefact_iteration_count}`}
      </span>
      <div className="modal-preview-task__indicator">
        <DeadlineIndicator date={iterationDeadline} delay={iterationDeadlineDelay} type="warning" />
      </div>
      <div className="modal-preview-task__indicator">
        <DeadlineIndicator date={productionDeadline} delay={productionDeadlineDelay} type="warning" />
      </div>
      <div className="modal-preview-task__status">
        <StatusLabelFlat
          type={artefact?.project_task?.last_state?.state_type}
          name={artefact?.project_task?.last_state?.name}
        />
      </div>
      <div className="modal-preview-task__change-status">
        <span
          className={
            artefact.project_task?.last_state_is_changed
              ? 'modal-preview-task__change-status-value'
              : 'modal-preview-task__change-status-empty'
          }
        >
          {getFormatDateByStr(artefact?.project_task.last_state_updated_at)}
        </span>
        {artefact.project_task?.last_state_is_changed && (
          <ProjectStatusChangedPopup
            isSeparate={false}
            projectId={projectId}
            projectTaskId={artefact.project_task.id}
          />
        )}
      </div>
    </div>
  );
}

export default memo(TaskItem);
