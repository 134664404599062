import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';

import { GET_DOCUMENT_DISCUSSIONS } from '../../queries/getDocumentDiscussions';
import { CcmUserState } from '../../schemas/types';
import { CreatorBase, StateType, UserBase } from '../../types/entities';
import useCommonContext from '../useCommonContext';

type DocumentDiscussionsProps = {
  artefactId?: number;
  fetchPolicy?: WatchQueryFetchPolicy;
};

export type DiscussionComment = {
  id: string;
  message: string;
  project_user: {
    company_user: {
      user: UserBase;
    };
  };
  creator: UserBase;
  updated_at: string;
};

export interface DiscussionCommentEx extends DiscussionComment {
  isMyComment: boolean;
}

export type DocumentDiscussion = {
  pk: string;
  id: string;
  updated_at: string;
  created_at: string;
  external_id: string;
  creator: UserBase;
  artefact_version_creation: {
    id: string;
    number: number;
  };
  project_task: {
    id: string;
    description: string;
    last_state: {
      id: string;
      state_type: StateType;
    };
  };
  discussion_comments: DiscussionComment[];
  artefact: {
    id: string;
  };
  artefact_version_solution: {
    id: string;
  };
  changed_by: {
    id: string;
  };
  is_active: boolean;
};

export interface DocumentDiscussionEx extends DocumentDiscussion {
  isMyDiscussion: boolean;
}

type QueryData = {
  document_discussions: {
    totalCount: number;
    edges: {
      node: DocumentDiscussion;
    }[];
  };
};

function useDocumentDiscussions({ artefactId, fetchPolicy }: DocumentDiscussionsProps) {
  const common = useCommonContext();
  const { loading, data, refetch } = useQuery<QueryData>(GET_DOCUMENT_DISCUSSIONS, {
    variables: {
      artefactId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy,
    skip: !artefactId,
  });

  const discussions = data?.document_discussions.edges.map((el) => el.node);

  const discussionsExtended: DocumentDiscussionEx[] | undefined = discussions?.map((d) => {
    return {
      ...d,
      isMyDiscussion: Number(d.creator?.id) === common.userId,
      discussion_comments: d.discussion_comments.map((c) => {
        return {
          ...c,
          isMyComment: Number(c.creator?.id) === common.userId,
        };
      }),
    };
  });

  return {
    loading,
    discussions,
    discussionsExtended,
    discussionsActive: discussionsExtended?.filter((el) => el.project_task.last_state.state_type === StateType.NEW),
    discussionsClosed: discussionsExtended?.filter((el) => el.project_task.last_state.state_type === StateType.DONE),
    discussionsRefetch: refetch,
  };
}

export default useDocumentDiscussions;
