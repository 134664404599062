import React, { useCallback, useMemo, useState } from 'react';

import { cx } from 'utils';
import {
  createSeparateTaskMessage,
  removeSeparateTaskMessage,
  updateSeparateTaskMessage,
} from 'api/requests/projectTask';
import { useParams, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import modalService from 'services/modalService';
import message from 'antd/es/message';

import CommentPane from 'components/commentPane';
import { MenuInfo } from 'components/dropdownMenu/types';

import useCommonContext from 'hooks/useCommonContext';
import useProjectTaskDiscussionsQuery, {
  SeparateTaskDiscussionItem,
} from 'hooks/queries/useProjectTaskDiscussionsQuery';

import SeparateTaskDiscussionHead from './SeparateTaskDiscussionHead';

import './styles.scss';

type SeparateTaskDiscussionProps = {
  item: SeparateTaskDiscussionItem;
};

function SeparateTaskDiscussion({ item }: SeparateTaskDiscussionProps) {
  const [commentId, setCommentId] = useState<string | null>(null);
  const { taskId } = useParams();
  const common = useCommonContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const issue = searchParams.get('issue');
  const { discussionsRefetch } = useProjectTaskDiscussionsQuery(taskId);

  const onCancelEdit = useCallback(() => setCommentId(null), []);

  const isSelected = useMemo(() => {
    return issue === item.id;
  }, [issue, item.id]);

  const onClickCard = useCallback(() => {
    if (isSelected) {
      return;
    }
    setSearchParams({ issue: item.id });
  }, [isSelected, item.id, setSearchParams]);

  const onCreateMessage = useCallback(
    async (text: string) => {
      const payload = {
        message: text,
        parent_message_id: item.id || '',
      };
      await createSeparateTaskMessage(taskId || '', payload);
      await discussionsRefetch();
    },
    [discussionsRefetch, item.id, taskId]
  );

  const onSaveMessage = useCallback(
    async (text: string, messageId: string) => {
      const payload = {
        message: text,
      };
      await updateSeparateTaskMessage(messageId, payload);
      await discussionsRefetch().then(() => setCommentId(null));
    },
    [discussionsRefetch]
  );

  const onClickLink = useCallback(() => {
    navigator.clipboard.writeText(location.href);
    message.success(common.t<string>('comments.linkCopied'), 2.5);
  }, [common]);

  const onRemoveMessage = useCallback(
    async (commentID: string) => {
      await removeSeparateTaskMessage(commentID);
      await discussionsRefetch().then(() => {
        searchParams.delete('issue');
        setSearchParams(searchParams);
      });
    },
    [discussionsRefetch, searchParams, setSearchParams]
  );

  const onRemoveModal = useCallback(
    (commentID: string) => {
      modalService.openConfirmModal({
        title: common.t<string>('pages.task.modal.removeDis.title'),
        text: common.t<string>('pages.task.modal.removeDis.text'),
        labelCancel: common.t<string>('pages.task.modal.removeDis.cancel'),
        labelConfirm: common.t<string>('pages.task.modal.removeDis.confirm'),
        onConfirm: () => onRemoveMessage(commentID),
      });
    },
    [common, onRemoveMessage]
  );

  const onClickMoreItem = (info: MenuInfo, commentID: string) => {
    if (info.key === 'edit') {
      setCommentId(commentID);
    }
    if (info.key === 'link') {
      onClickLink();
    }
    if (info.key === 'remove') {
      onRemoveModal(commentID);
    }
  };

  const classNames = cx('separate-task-discussion', {
    'separate-task-discussion--selected': isSelected,
  });

  return (
    <div id={item.id} onClick={onClickCard} className={classNames}>
      <div className="separate-task-discussion__container">
        <SeparateTaskDiscussionHead
          canIEdit={item?.creator?.id === common?.userId?.toString()}
          user={item?.creator}
          createdAt={item?.created_at}
          commentId={item?.id}
          onClickMenu={onClickMoreItem}
        />

        {commentId === item.id ? (
          <CommentPane
            placeholder={common.t<string>('pages.task.freeTask.comments.newComment')}
            labelSubmit={common.t<string>('common.save')}
            initText={item.message}
            onCancel={onCancelEdit}
            onSubmit={(text: string) => onSaveMessage(text, item.id)}
          />
        ) : (
          <span className={cx('separate-task-discussion__comment', { mb_16: isSelected })}>{item.message}</span>
        )}
      </div>
      {!isEmpty(item.children)
        ? item.children.map((comment) => {
            return (
              <div key={comment.id} className="separate-task-discussion__container">
                <SeparateTaskDiscussionHead
                  canIEdit={comment?.creator?.id === common?.userId?.toString()}
                  hideMore
                  user={comment?.creator}
                  createdAt={comment.created_at}
                  commentId={comment.id}
                  onClickMenu={onClickMoreItem}
                />
                {commentId === comment?.id ? (
                  <CommentPane
                    placeholder={common.t<string>('pages.task.freeTask.comments.newComment')}
                    labelSubmit={common.t<string>('common.save')}
                    initText={comment.message}
                    onCancel={onCancelEdit}
                    onSubmit={(text: string) => onSaveMessage(text, comment.id)}
                  />
                ) : (
                  <span className="separate-task-discussion__comment">{comment.message}</span>
                )}
              </div>
            );
          })
        : null}
      {commentId === null && isSelected ? (
        <CommentPane
          placeholder={common.t<string>('pages.task.freeTask.comments.newComment')}
          onSubmit={onCreateMessage}
        />
      ) : null}
    </div>
  );
}

export default SeparateTaskDiscussion;
