import React from 'react';

import { getIconByUrl } from 'utils/icons';

import './styles.scss';

type SelectLabelProps = {
  icon?: string;
  text: string;
};

function SelectLabel({ text, icon }: SelectLabelProps) {
  return (
    <div className="ccm-select-label">
      <span className="ccm-select-label__icon">{getIconByUrl(icon)}</span>
      <span className="ccm-select-label__text">{text}</span>
    </div>
  );
}

export default SelectLabel;
