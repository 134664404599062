import { useRef, useState } from 'react';

import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { CompanyArtefactType } from 'types';
import { GET_LEARNING_OBJECTS } from 'queries/getLearningObjects';
import { GET_LEARNING_OBJECTS_PLAN } from 'queries/getLearningObjectsPlan';
import { ProjectTaskAssignees, StateType } from 'types/entities';
import { ProjectLearningObjectNodeTypeEdge } from 'schemas/types';

import { PlanFilters } from '../../pages/project/subPages/plan/types';

import { ProjectUserType } from './useProjectUsers';

type ProjectTaskAssigneesNode = {
  node: ProjectTaskAssignees;
};

export type CompanyArtTypeBySequenceMapType = {
  sequenceId: string;
  companyArtTypeId: string;
};

export type CurrentArtefactProductionSequence = {
  id: string;
  is_turn_off: boolean;
  project_learning_object: {
    id: string;
    name: string;
    icon_image: string;
  };
  current_assignees: ProjectTaskAssignees[];
  project_task: {
    id: string;
    name: string;
    last_state_updated_at: string;
    last_state_is_changed: boolean;
    last_state: {
      id: string;
      state_type: StateType;
      name: string;
      updated_at: string;
    };
    cached_linked_artefact: {
      id: string;
      artefact_iteration_count: number;
      first_iteration_deadline: string;
      first_iteration_deadline_delay: number;
      first_iteration_deadline_changed: boolean;
      production_deadline: string;
      production_deadline_delay: number;
      production_deadline_changed: boolean;
      artefact_type: {
        id: string;
        type: string;
        name: string;
        icon_image: string;
      };
      artefact_versions: {
        file: {
          file_type: string;
        };
      }[];
    };
  };
};

function useLearningObjectsForProject(
  projectId?: number,
  planFilters?: PlanFilters,
  hasSequence?: boolean,
  projectUsers?: ProjectUserType[]
) {
  const [learningObjects, setLearningObjects] = useState([]);
  const hasFirstLoad = useRef<boolean>(false);

  const onCompleted = (data: any) => {
    if (!hasFirstLoad.current) {
      hasFirstLoad.current = true;
    }
    const assignees = data?.project_task_assignees?.edges
      .map((a: ProjectTaskAssigneesNode) => a?.node)
      .map((el: ProjectTaskAssignees) => {
        const additional = projectUsers?.find((user) => user?.id === el?.project_user?.id);
        return {
          ...el,
          project_user: additional
            ? {
                ...el.project_user,
                company_user: {
                  ...additional?.company_user,
                },
                roles: additional?.roles ? [...additional?.roles] : [],
              }
            : null,
        };
      });

    const convertedData = () => {
      return (
        data?.learning_objects?.edges.map((el: ProjectLearningObjectNodeTypeEdge) => {
          const artefact = data.project_learning_object_final_artefacts.find(
            (art: any) => art?.project_learning_object?.id === el?.node?.pk
          );

          const artefactType = artefact?.final_artefact.artefact_type?.type;
          const lastArtefactIteration = artefact?.final_artefact?.last_artefact_iteration?.artefact_file_version;

          let artefactFile = undefined;
          let videoUrl = undefined;
          let externalUrl = undefined;
          let documentFile = undefined;

          if (lastArtefactIteration) {
            artefactFile = artefact?.final_artefact.artefact_versions.find(
              (ver: any) => ver?.id === lastArtefactIteration?.id
            );
          } else {
            artefactFile = artefact?.final_artefact.artefact_versions?.[0];
          }

          const fileType = artefactFile?.file?.file_type;

          if (
            artefactType !== CompanyArtefactType.type.ORIGINAL_VIDEO &&
            artefactType !== CompanyArtefactType.type.PRODUCTION_PRESENTATION
          ) {
            if (artefactFile?.file?.file_type === 'url') {
              externalUrl = artefactFile?.file?.related_files?.[0]?.url;
            } else {
              documentFile = artefactFile?.file?.related_files?.[0];
            }
          }

          if (artefactType === CompanyArtefactType.type.ORIGINAL_VIDEO) {
            if (artefactFile?.file?.file_type === 'video') {
              videoUrl = artefactFile?.file?.related_files?.[0]?.source_url;
            }
          }

          const sequence = data?.current_artefact_production_sequence
            ?.filter((s: CurrentArtefactProductionSequence) => s?.project_learning_object?.id === el?.node?.pk)
            .map((s: CurrentArtefactProductionSequence) => {
              const filteredAssignees = assignees?.filter(
                (a: ProjectTaskAssignees) => a?.project_task?.id === s?.project_task?.id && a?.is_active
              );
              return {
                ...s,
                current_assignees: filteredAssignees,
              };
            });

          return {
            id: Number(el?.node?.pk),
            parent: Number(el?.node?.parent?.project_learning_object_parent?.id) || 0,
            droppable: true,
            text: el?.node?.name || '',
            data: {
              saved: true,
              is_show: true,
              description: el?.node?.description,
              learningObjectType: el.node?.learning_object_type,
              learning_object_type_id: '',
              artefactTypeId: artefact?.final_artefact.artefact_type.id
                ? Number(el?.node?.learning_object_type?.id)
                : null,
              learningOutcomes: el?.node?.project_learning_outcome?.description,
              projectLearningOutcomeId: el?.node?.project_learning_outcome?.pk,
              learningStructureType: el?.node?.project_learning_structure_type?.level_type,
              final_artefacts: el?.node?.final_artefacts,
              depth: el?.node?.parent?.depth,
              progress: el?.node?.production_progress,
              objectState: el.node?.cached_object_state,
              videoUrl,
              externalUrl,
              documentFile,
              fileType,
              artefactSequence: sequence,
              humanReadableNumber: el.node?.parent?.human_readable_number,
            },
          };
        }) || []
      );
    };
    setLearningObjects(convertedData);
  };

  const { data, loading, refetch } = useQuery(hasSequence ? GET_LEARNING_OBJECTS_PLAN : GET_LEARNING_OBJECTS, {
    variables: {
      project_id: projectId,
      is_active: true,
      plan_filters: {
        search_text: planFilters?.searchText ? planFilters?.searchText : undefined,
        object_state_enums: hasSequence ? undefined : planFilters?.statusIds, //Content filter
        all_tasks_state_names: hasSequence ? planFilters?.statusName : undefined,
        artefact_type_names: hasSequence ? planFilters?.artefactTypeNames : undefined,
        assignee_ids: planFilters?.assigneeIds,
        deadline_violations_only: planFilters?.missed,
        company_learning_object_type_ids: hasSequence ? undefined : planFilters?.artefactTypeIds, //Content filter
      },
    },
    skip: !projectId,
    fetchPolicy: 'network-only',
    onCompleted: onCompleted,
  });

  const companyArtTypeBySequenceMap = data?.current_artefact_production_sequence?.map(
    (el: CurrentArtefactProductionSequence) => {
      return {
        sequenceId: el.id,
        companyArtTypeId: el?.project_task?.cached_linked_artefact?.artefact_type?.id,
      };
    }
  );

  return {
    learningObjects: learningObjects,
    learningObjectsLoading: loading,
    learningObjectsRefetch: refetch,
    assignees: data?.project_task_assignees?.edges.map(
      (a: ProjectTaskAssigneesNode) => a?.node
    ) as ProjectTaskAssignees[],
    sequences: data?.current_artefact_production_sequence as CurrentArtefactProductionSequence[],
    sequencesMap: data?.current_artefact_production_sequence.map((el: CurrentArtefactProductionSequence) => el.id),
    companyArtTypeBySequenceMap: companyArtTypeBySequenceMap as CompanyArtTypeBySequenceMapType[],
    hasFirstLoad: hasFirstLoad.current,
  };
}

export default useLearningObjectsForProject;
