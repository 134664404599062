import React, { memo, ReactNode } from 'react';

import './styles.scss';

type CollapseHeaderProps = {
  title: string;
  children?: ReactNode;
};

function CollapseHeader({ title, children }: CollapseHeaderProps) {
  return (
    <div className="collapse-header">
      <span className="collapse-header__text">{title}</span>
      <div className="collapse-header__ex">{children}</div>
    </div>
  );
}

export default memo(CollapseHeader);
