import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { ProjectLearningStructureEnum } from 'types/entities';
import { isEqual } from 'lodash';

import TextField from 'components/textField';
import Button from 'components/button';
import { TextFieldTypeEnum } from 'components/textField/types';
import { ButtonTypesEnum } from 'components/button/types';

import useCommonContext from 'hooks/useCommonContext';

import { EditSectionDraft, EditSectionProps } from './types';

import './styles.scss';

const initState: EditSectionDraft = {
  title: '',
  description: '',
  learningOutcomes: '',
  learningStructureType: undefined,
  learningObjectType: null,
};

function EditSection({ count, isElement, learningObjectType, parentId, data, onCancel, onSubmit }: EditSectionProps) {
  const common = useCommonContext();
  const [draft, setDraft] = useState<EditSectionDraft>({
    ...initState,
    learningObjectType,
    learningStructureType: isElement
      ? ProjectLearningStructureEnum.production_item
      : ProjectLearningStructureEnum.group,
  });

  const initData = useMemo(() => {
    return {
      title: data?.text || '',
      description: data?.data?.description,
      learningOutcomes: data?.data?.learningOutcomes,
      learningStructureType: data?.data?.learningStructureType,
      learningObjectType: data?.data?.learningObjectType,
    };
  }, [
    data?.data?.description,
    data?.data?.learningObjectType,
    data?.data?.learningOutcomes,
    data?.data?.learningStructureType,
    data?.text,
  ]);

  useEffect(() => {
    if (data) {
      setDraft(initData);
    }
  }, [data, initData]);

  const disableSaveButton = useMemo(() => {
    if (data) {
      return isEqual(draft, initData);
    }
    return !draft.title?.trim();
  }, [data, draft, initData]);

  const handleChangeTextField = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.target;
    setDraft({ ...draft, [name]: value });
  };

  const onClickCancel = useCallback(() => {
    setDraft(initState);
    onCancel();
  }, [onCancel]);

  const onClickSubmit = () => {
    onSubmit(draft, data?.id, parentId);
    onClickCancel();
  };

  return (
    <div className="tree-edit-section">
      {count ? <span className="tree-edit-section__count">{count}</span> : null}

      <div className="tree-edit-section__form">
        <TextField
          noForm
          size="xl"
          name="title"
          placeholder={common.t<string>(
            isElement ? 'form.field.titleElement.placeholder' : 'form.field.titleSection.placeholder'
          )}
          value={draft.title}
          onChange={handleChangeTextField}
          status={!draft.title ? 'error' : ''}
        />
        <TextField
          noForm
          autoSize
          size="small"
          name="description"
          placeholder={common.t<string>(
            isElement ? 'form.field.descriptionElement.placeholder' : 'form.field.descriptionSection.placeholder'
          )}
          type={TextFieldTypeEnum.textArea}
          value={draft.description}
          onChange={handleChangeTextField}
        />
        <TextField
          noForm
          size="small"
          autoSize
          name="learningOutcomes"
          placeholder={common.t<string>('form.field.resultSection.placeholder')}
          type={TextFieldTypeEnum.textArea}
          value={draft.learningOutcomes}
          onChange={handleChangeTextField}
        />
        <div className="tree-edit-section__buttons">
          <Button
            className="mr_16"
            size="large"
            disabled={disableSaveButton}
            onClick={onClickSubmit}
            type={ButtonTypesEnum.primary}
            hintText={common.t<string>('common.typeName')}
          >
            {common.t<string>('common.save')}
          </Button>
          <Button className="" size="large" type={ButtonTypesEnum.default} onClick={onClickCancel}>
            {common.t<string>('common.cancel')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EditSection;
