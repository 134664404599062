import React, { useRef } from 'react';

import { getMonthRange } from 'utils';
import moment from 'moment';

import GanttHead from 'components/gantt/ganttHead';
import GanttBlock from 'components/gantt/ganttBlock';
import GanttUser from 'components/gantt/ganttUser';

import useRefDimensions from 'hooks/useRefDimensions';

import { DashboardCollaboratorsGantt } from '../../types';

import './styles.scss';

type GanttProps = {
  data?: DashboardCollaboratorsGantt;
};

function Gantt({ data }: GanttProps) {
  const headRef = useRef(null);
  const dimensions = useRefDimensions(headRef);
  const monthRange = getMonthRange(data?.min_date, data?.max_date);

  const step = 4;
  const gap = 20; // left padding
  const today = moment();
  const startOfMonth = moment(data?.min_date).startOf('month').format('YYYY-MM-DD');
  const diff = today.diff(startOfMonth, 'days');

  const todayStyle = {
    left: diff * step + gap || 4,
  };

  return (
    <div className="dashboard-gantt">
      <div className="dashboard-gantt__wrap">
        <div className="dashboard-gantt-users">
          {data?.users.map((user) => {
            return <GanttUser key={user.id} user={user} />;
          })}
        </div>
        <div className="dashboard-gantt-chart">
          <div className="dashboard-gantt-chart__container">
            <GanttHead headRef={headRef} monthRange={monthRange} />
            <div className="dashboard-gantt-chart__wrap">
              {data?.users.map((user, index) => {
                return <GanttBlock key={index} minDate={data?.min_date} dimensions={dimensions} user={user} />;
              })}
            </div>
            <div style={todayStyle} className="dashboard-gantt-chart__today"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gantt;
