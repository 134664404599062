import { gql } from '@apollo/client';

export const GET_TASK_INFO = gql`
  query GetTaskInfo($learning_id: Int, $project_task_id: Int) {
    learning_object(id: $learning_id) {
      id
      name
      description
      parent {
        human_readable_number
      }
      learning_object_type {
        icon_image
        type
        name
      }
      project {
        id
        pk
        name
        company {
          id
        }
        project_task_state_list_version {
          id
        }
        artefact_issue_state_list_version {
          id
        }
        projectuser_set {
          id
          project {
            id
            name
          }
          company_user {
            id
            company {
              id
              slug
              name
              company_type
            }
            user {
              id
              first_name
              last_name
              email
              timezone
              icon_image
              name
            }
          }
          roles {
            role {
              id
              name
              role
              pk
            }
          }
        }
        active_users {
          id
          project {
            id
            name
          }
          company_user {
            id
            company {
              id
              slug
              name
              company_type
            }
            user {
              id
              first_name
              last_name
              email
              timezone
              icon_image
              name
            }
          }
          roles {
            role {
              id
              name
              role
              pk
            }
          }
        }
      }
      project_learning_outcome {
        description
      }
      final_artefacts {
        artefact_type {
          name
          id
        }
      }
    }
    artefact_production_sequence(project_task_id: $project_task_id) {
      id
      is_turn_off
      project_task {
        updated_at
        created_at
        last_state_updated_at
        project_task_state_history {
          id
          is_rollback_possible
          changed_by {
            id
          }
          state {
            id
            name
            state_type
            created_at
            updated_at
            creator {
              id
              first_name
              last_name
              email
            }
            company_state_list_version {
              id
              company_state_list {
                id
                created_at
                updated_at
                creator {
                  id
                  first_name
                  last_name
                  email
                }
                name
                actual_version {
                  id
                }
                last_version {
                  id
                }
              }
            }
            order
            is_new_iteration_initiation
          }
        }
        id
        description

        projecttaskassignee_set {
          is_current_executor
          is_active
          project_user {
            company_user {
              id
              user {
                id
                first_name
                last_name
              }
            }
            roles {
              role {
                id
                name
                role
              }
            }
          }
        }
        assignees {
          company_user {
            id
            user {
              id
              first_name
              last_name
            }
          }
          roles {
            role {
              id
              name
              role
            }
          }
        }
        last_state {
          id
          name
          state_type
        }
        artefact_list {
          id
          document_discussions_set {
            id
            external_id
            project_task {
              id
              description
              last_state {
                id
                state_type
              }
            }
          }
          first_iteration_deadline
          production_deadline
          artefact_versions {
            id
            created_at
            updated_at
            number
            is_active
            is_current
            file {
              id
              pk
              is_active
              file_type
              name
              state
              related_files {
                ... on PresentationFileSlideType {
                  id
                  source_url
                  is_active
                  order
                }
                ... on VideoFileType {
                  id
                  source_url
                  resolution
                  is_source
                }
                ... on ExternalUrlFileType {
                  id
                  url
                }
                ... on DocumentFileSectionType {
                  id
                  parent_section {
                    id
                  }
                  section_type
                  section_content
                  style
                  level
                  params
                  order
                }
                ... on DocumentFileVersionType {
                  id
                  original_data
                  sections {
                    id
                    parent_section {
                      id
                    }
                    section_type
                    section_content
                    style
                    level
                    params
                    order
                  }
                }
              }
            }
          }
          artefact_type {
            id
            name
            type
          }

          artefact_iteration_count
          last_artefact_iteration {
            artefact_file_version {
              id
              file {
                id
              }
              is_active
              is_current
            }
          }
        }
      }
      is_atrefact
      order
    }
  }
`;
