import { gql } from '@apollo/client';

export const GET_COLLABORATORS_BY_ROLE = gql`
  query CollaboratorsByRole($userRoleId: Int) {
    collaborators_by_role(user_role_id: $userRoleId) {
      pk
      id
      is_active
      technical_role
      user {
        id
        email
        first_name
        last_name
        name
        state
        icon_image
      }
      business_roles {
        id
        name
        role
        pk
      }
      company {
        id
        name
        slug
        company_type
      }
    }
  }
`;
