import React, { memo } from 'react';

import { Handle, NodeProps, Position, useReactFlow, useStoreApi } from 'reactflow';
import { CompanyArtefactTypeWithId } from 'types/entities';
import { CompanyState } from 'types';
import { cx } from 'utils';
import modalService from 'services/modalService';

import Select from 'components/select';
import Label from 'components/label';
import InputNum from 'components/inputNum';
import Button from 'components/button';
import { ButtonTypesEnum } from 'components/button/types';
import Table from 'components/table';
import RemoveButtonWithConfirm from 'components/removeButtonWithConfirm';

import useCommonContext from 'hooks/useCommonContext';
import useCompanyState from 'hooks/queries/useCompanyState';
import { useCompanyStateLists } from 'hooks/queries/useCompanyStateLists';
import { ArtefactPipelineSequence, SequenceRule } from 'hooks/queries/getArtefactPipeline';
import useArtefactFlowStepsAdmin from 'hooks/queries/useArtefactFlowStepsAdmin';

import './styles.scss';
import { onDeleteSequenceRule } from '../../../../../api/requests/companyState';

export type NodeData = {
  statusListVerId?: string;
  order: number;
  isFinal: boolean;
  artefactType: CompanyArtefactTypeWithId;
  defaultState: CompanyState;
  sequences: ArtefactPipelineSequence[];
  rules: SequenceRule[] | null;
  onRefetch?: () => Promise<void>;
};

const stopPropagation = (event: any) => event.stopPropagation();

const CustomNode = ({ data, ...props }: NodeProps<NodeData>) => {
  const { company } = useCommonContext();
  const { companyStateListForSelect } = useCompanyStateLists(company?.id);
  const { companyStatesForSelect } = useCompanyState(company?.id, 'network-only', null, Number(data?.statusListVerId));

  const { flowSteps } = useArtefactFlowStepsAdmin(data?.artefactType?.id, data?.statusListVerId);

  console.log(data.rules);

  const artFlowStepStateOptions = flowSteps?.map((el) => {
    return {
      id: el?.pk,
      label: el?.state_initiation.name,
      value: el?.pk,
    };
  });

  const { setNodes } = useReactFlow();
  const store = useStoreApi();

  const onChangeDataField = (field: string, value: any) => {
    const { nodeInternals } = store.getState();
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === props.id) {
          node.data = {
            ...node.data,
            [field]: value,
          };
        }
        return node;
      })
    );
  };

  const onChangeStatusList = (value: string) => {
    onChangeDataField('statusListVerId', value);
    onChangeDataField('defaultState', null);
  };
  const onChangeDefaultState = (value: string) => {
    onChangeDataField(
      'defaultState',
      companyStatesForSelect.find((el: any) => el.id === value)
    );
  };

  const onChangeOrder = (value: string | number | null) => {
    onChangeDataField('order', value);
  };

  const classNames = cx('pipe-flow-custom-node', {
    'pipe-flow-custom-node--final': data.isFinal,
  });

  const onAddRule = () => {
    modalService.openAddSequenceRule({
      artFlowStepStateOptions,
      artefactType: data.artefactType,
      companyStatesForSelect,
      sequences: data.sequences,
      onRefetch: data.onRefetch,
    });
  };

  const onDeleteRule = async (item: string | number) => {
    await onDeleteSequenceRule(item.toString());
    await data.onRefetch?.();
  };

  const columns = [
    {
      key: 'current_state',
      title: `if ${data.artefactType.name}`,
      render: (record: SequenceRule) => record.artefact_flow_step.state_initiation.name,
    },
    {
      key: 'art',
      title: 'Artefact',
      render: (record: SequenceRule) => record.artefact_pipeline_step_sequence.artefact_type.name,
    },
    {
      key: 'set_state',
      title: 'Set state',
      render: (record: SequenceRule) => record.set_state.name,
    },
    {
      key: 'remove',
      title: '',
      render: (record: SequenceRule) => <RemoveButtonWithConfirm itemId={record.id} onClick={onDeleteRule} />,
    },
  ];

  return (
    <div className={classNames}>
      <Handle id={`target-top-${props?.id}`} className="customHandle" position={Position.Top} type="target" />
      <Handle id={`target-right-${props?.id}`} className="customHandle" position={Position.Right} type="target" />
      <Handle id={`target-bottom-${props?.id}`} className="customHandle" position={Position.Bottom} type="target" />
      <Handle id={`target-left-${props?.id}`} className="customHandle" position={Position.Left} type="target" />

      <Handle id={`source-top-${props?.id}`} className="customHandle" position={Position.Top} type="source" />
      <Handle id={`source-right-${props?.id}`} className="customHandle" position={Position.Right} type="source" />
      <Handle id={`source-bottom-${props?.id}`} className="customHandle" position={Position.Bottom} type="source" />
      <Handle id={`source-left-${props?.id}`} className="customHandle" position={Position.Left} type="source" />

      <div className="pipe-flow-custom-node__top">
        <span className="pipe-flow-custom-node__label">{data?.artefactType?.name}</span>
        {data.isFinal ? <span className="pipe-flow-custom-node__label--final">Final artefact</span> : null}
      </div>
      <div className="pipe-flow-custom-node__inner">
        <Label text="Status list">
          <Select
            className="mb_8 nodrag"
            noForm
            size="large"
            placeholder="Select status list"
            value={data?.statusListVerId}
            options={companyStateListForSelect}
            onClick={stopPropagation}
            onChange={onChangeStatusList}
          />
        </Label>
        <Label text="Default state">
          <Select
            className="mb_8 nodrag"
            noForm
            size="large"
            placeholder="Select state"
            disabled={!data?.statusListVerId}
            value={data?.defaultState?.id}
            options={companyStatesForSelect}
            onClick={stopPropagation}
            onChange={onChangeDefaultState}
          />
        </Label>
        <Label text="Order">
          <InputNum
            disabled={data.isFinal}
            className="nodrag"
            size="large"
            value={data?.order}
            onChange={onChangeOrder}
          />
        </Label>
        <div className="pipe-flow-custom-node__rules">
          <div className="pipe-flow-custom-node__rules-top">
            <span>Sequence rule</span>
            <Button type={ButtonTypesEnum.primary} onClick={onAddRule}>
              New
            </Button>
          </div>
          <Table columns={columns} dataSource={data?.rules || []} pagination={false} />
        </div>
      </div>
    </div>
  );
};

export default memo(CustomNode);
