import React, { memo, useCallback, useEffect, useMemo } from 'react';

import { LinkOutlined, LoadingOutlined } from '@ant-design/icons';
import { NodeModel, RenderParams } from '@minoru/react-dnd-treeview/dist/types';
import { cx, getDataToWidget } from 'utils';
import { noop } from 'lodash';
import { ProjectLearningStructureEnum, StateType } from 'types/entities';
import { useParams } from 'react-router-dom';
import TruncateMarkup from 'react-truncate-markup';

import Block from 'components/block';
import Button from 'components/button';
import Text from 'components/text';
import { TextVariantEnum } from 'components/text/types';
import Tooltip from 'components/tooltip';
import { ButtonTypesEnum } from 'components/button/types';
import ElementType from 'components/elementType/ElementType';
import Indicator from 'components/indicator';

import useCommonContext from 'hooks/useCommonContext';

import ExtraButtons from '../extraButtons/ExtraButtons';
import { CustomData, TreeSettings } from '../../types';
import EditSection from '../editSection/EditSection';
import { EditSectionDraft } from '../editSection/types';
import ExportElementPopup from '../exportElementPopup/ExportElementPopup';
import AddContent from '../addContent';
import useSingleExport from '../../../../subPages/content/hooks/useSingleExport';

type MainContentProps = {
  elementHovered: boolean;
  viewExport: any;
  openTrigger: any;
  count: string;
  show: boolean;
  showExtraButtons: boolean;
  settings: TreeSettings;
  isEdit: boolean;
  hasChild?: boolean;
  node: NodeModel<CustomData>;
  params: RenderParams;
  descendants: NodeModel<CustomData>[];
  parents: NodeModel<CustomData>[];
  onPreview: () => void;
  onDelete: () => void;
  onSubmit: (values: EditSectionDraft, id?: number | string, parentId?: number | string) => void;
  onCopy: () => void;
  onShowEdit: () => void;
  onCloseEdit: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

function MainContent({
  elementHovered,
  viewExport,
  openTrigger,
  count,
  show,
  showExtraButtons,
  settings,
  isEdit,
  hasChild,
  node,
  params,
  descendants,
  parents,
  onPreview,
  onDelete,
  onSubmit,
  onCopy,
  onShowEdit,
  onCloseEdit,
  onMouseEnter,
  onMouseLeave,
}: MainContentProps) {
  const [openedPopup, onOpenedPopup] = openTrigger;
  const { id } = useParams();

  const onShowExport = useCallback(() => {
    viewExport.setIsVisible(true);
    onOpenedPopup.on();
  }, [onOpenedPopup, viewExport]);

  const onCloseExport = useCallback(() => {
    viewExport.setIsVisible(false);
    onOpenedPopup.off();
  }, [onOpenedPopup, viewExport]);

  const singleExportInst = useSingleExport({
    projectId: id,
    node,
    onCloseExport,
  });

  useEffect(() => {
    if (!isEdit && show) {
      onCloseEdit();
    }
  }, [isEdit, onCloseEdit, show]);

  const common = useCommonContext();

  const onClickLink = useCallback(() => {
    const link = `${document.URL}?elementId=${node.id}`;
    navigator.clipboard.writeText(link);
  }, [node.id]);

  const widgets = useMemo(() => {
    return getDataToWidget(descendants);
  }, [descendants]);

  const classNamesButtons = cx('course-tree-item__buttons', {
    'course-tree-item__buttons--showed': elementHovered || viewExport.isVisible,
  });

  return show ? (
    <EditSection
      isElement={node.data?.learningStructureType === ProjectLearningStructureEnum.production_item}
      learningObjectType={node?.data?.learningObjectType || null}
      parentId={node.parent}
      data={node}
      onCancel={onCloseEdit}
      onSubmit={onSubmit}
    />
  ) : (
    <div className="course-tree-item__main-content">
      <div className="course-tree-item__main-content-inner" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <span className="course-tree-item__count">{count}</span>
        <Block empty hidden={node.data?.learningStructureType !== ProjectLearningStructureEnum.production_item}>
          <ElementType
            hasTooltip
            objectState={node?.data?.objectState}
            url={node?.data?.learningObjectType?.icon_image}
            name={node?.data?.learningObjectType?.name}
            progress={node?.data?.progress}
            sequences={node?.data?.artefactSequence}
          />
        </Block>
        <TruncateMarkup lines={1}>
          <div
            title={node?.text}
            className="course-tree-item__title"
            role="button"
            onClick={
              node.data?.learningStructureType === ProjectLearningStructureEnum.production_item ? onPreview : noop
            }
          >
            {node?.text}
          </div>
        </TruncateMarkup>

        <Block className="course-tree-item__indicators" hidden={!params.hasChild || isEdit}>
          {widgets.map((el, index) => {
            return <Indicator key={index} element={el} hasTooltip />;
          })}
        </Block>

        <Block
          className={cx('course-tree-item__progress', { ml_auto: !widgets?.length })}
          hidden={node.data?.learningStructureType === ProjectLearningStructureEnum.production_item || isEdit}
        >
          <Text variant={TextVariantEnum.h5}>{Math.round(node?.data?.progress || 0)}</Text>
          <Text className="course-tree-item__progress-percent" variant={TextVariantEnum.description}>
            %
          </Text>
        </Block>

        <Block
          className={classNamesButtons}
          hidden={node.data?.learningStructureType !== ProjectLearningStructureEnum.production_item || isEdit}
        >
          <Block hidden={node?.data?.objectState !== StateType.DONE}>
            {singleExportInst.loading ? (
              <LoadingOutlined />
            ) : (
              <ExportElementPopup
                anchor={viewExport.ref}
                visible={viewExport.isVisible}
                node={node}
                singleExport={singleExportInst}
                onShow={onShowExport}
                onClose={onCloseExport}
              />
            )}
          </Block>
          <Tooltip title={common?.t<string>('common.linkElem')}>
            <Button
              className="ml_8"
              size="large"
              onClick={onClickLink}
              type={ButtonTypesEnum.text}
              icon={<LinkOutlined />}
            />
          </Tooltip>
        </Block>
        <ExtraButtons
          isElement={node.data?.learningStructureType === ProjectLearningStructureEnum.production_item}
          canDelete={!node?.data?.progress}
          show={showExtraButtons && isEdit}
          learningStructureType={node.data?.learningStructureType}
          onDelete={onDelete}
          onCopy={onCopy}
          onEdit={onShowEdit}
          hasChild={hasChild}
        />
      </div>

      <AddContent node={node} settings={settings} isEdit={isEdit} elementHovered={elementHovered} />
    </div>
  );
}

export default memo(MainContent);
