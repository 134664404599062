import React from 'react';

import { cx } from 'utils';
import { ProjectSeparateTaskItem } from 'types/entities/separate';

import GlobalTasksCollapseHeader from './SeparateTasksCollapseHeader';
import GlobalTasksItem from './SeparateTasksItem';

import './styles.scss';

type GlobalTasksCollapseProps = {
  data: any;
  open: boolean;
  progress?: number;
  onToggle: () => void;
};

function SeparateTasksCollapse({ data, open, progress, onToggle }: GlobalTasksCollapseProps) {
  const classNames = cx('global-tasks-collapse-content', {
    'global-tasks-collapse-content--opened': open,
  });

  return (
    <div className="global-tasks-collapse">
      <GlobalTasksCollapseHeader open={open} onToggle={onToggle} progress={progress} />
      <div className={classNames}>
        {data?.map((item: ProjectSeparateTaskItem) => {
          return <GlobalTasksItem key={item.id} item={item} />;
        })}
      </div>
    </div>
  );
}

export default SeparateTasksCollapse;
