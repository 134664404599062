import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { isEmpty } from 'lodash';
import { GET_COMPANY_ARTEFACT_ROLE_ASSIGNMENTS } from 'queries/getCompanyArtefactRoleAssigments';
import { CompanyArtefactTypeId, UserRoleBase } from 'types/entities';

type Props = {
  artefactIds?: number[];
  fetchPolicy?: WatchQueryFetchPolicy;
};

export type CompanyArtefactRoleAssignments = {
  id: string;
  pk: string;
  company_artefact_type: CompanyArtefactTypeId;
  user_roles: UserRoleBase[];
};

type Data = {
  company_artefact_role_assignments: CompanyArtefactRoleAssignments[];
};

function useCompanyArtefactRoleAssignments({ artefactIds, fetchPolicy }: Props) {
  const { data, loading } = useQuery<Data>(GET_COMPANY_ARTEFACT_ROLE_ASSIGNMENTS, {
    variables: {
      artefactIds: artefactIds,
    },
    fetchPolicy: fetchPolicy || 'cache-and-network',
    skip: isEmpty(artefactIds),
  });

  return {
    loading,
    artefactRoleAssignments: data?.company_artefact_role_assignments,
  };
}
export default useCompanyArtefactRoleAssignments;
