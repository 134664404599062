export const structureCourseOptions = [
  {
    label: 'XLS',
    value: 'xls',
  },
  {
    label: 'CSV',
    value: 'csv',
  },
];

export const textCourseOptions = [
  {
    label: 'PDF',
    value: 'pdf',
  },
  {
    label: 'DOCX',
    value: 'docx',
  },
];

export const videoResolutionOptions = [
  {
    id: '1',
    value: '360',
    label: '360',
  },
  {
    id: '2',
    value: '480',
    label: '480',
  },
  {
    id: '3',
    value: '720',
    label: '720',
  },
  {
    id: '4',
    value: '1080',
    label: '1080',
  },
  // {
  //   id: '5',
  //   value: 'original',
  //   label: 'original',
  // },
];
