/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ArtefactAssignee } from './ArtefactAssignee';
import type { CompanyState } from './CompanyState';

export type ArtefactTask = {
    readonly id?: number;
    description: string;
    state_list_version_id: number;
    task_type: ArtefactTask.task_type;
    assignees: Array<ArtefactAssignee>;
    last_state: CompanyState;
};

export namespace ArtefactTask {

    export enum task_type {
        VIDEO_SHOOTING = 'video_shooting',
        ARTEFACT_PRODUCTION = 'artefact_production',
        ARTEFACT_ISSUE = 'artefact_issue',
        ARTEFACT_DISCUSSION = 'artefact_discussion',
        SEPARATE_TASK = 'separate_task',
    }


}
