import React from 'react';

import { cx } from 'utils';
import { isEmpty } from 'lodash';
import withCommonProps from 'hocs/withCommonProps';
import { TemplateCommonProps } from 'common/commonPropsProvider';
import { BusinessRoleEnum } from 'types/entities/user';
import { StateType } from 'types/entities';

import Block from 'components/block';

import { CompanyIssueItem } from '../../task/types';
import { ProjectStateItem } from '../../../hooks';
import Empty from '../../task/components/empty';

import ErrorCard from './components/errorCard';
import TaskErrorsSkeleton from './TaskErrorsSkeleton';
import ErrorsFilter from './components/errorsFilter';

import './styles.scss';

type TaskSidebarProps = {
  loading?: boolean;
  isVideo?: boolean;
  className?: string;
  userRoleId: string | null;
  dataList?: any[];
  selectedIssue?: any;
  isProductExecutor?: boolean | undefined;
  isMethodist?: boolean | undefined;
  onSelectedIssue: (value: any) => void; //TODO добавить типы сущностей как будут ))
  changeOrder: (value: string) => void;
  changeFilter: (value: string) => void;
  companyStates: ProjectStateItem[] | null;
  companyIssuesStates: ProjectStateItem[] | null;
  issuesRefetch: any;
  issuesTypesList: CompanyIssueItem[] | null;
  isCurrentExecutor: boolean | null;
  userRole: BusinessRoleEnum | null;
  currentTaskState: StateType | null;
  filter: string;
  ordering: string;
  issuesFlowSteps: any[] | undefined;
};

function TaskErrors({
  className,
  loading,
  isVideo,
  dataList,
  selectedIssue,
  onSelectedIssue,
  isProductExecutor,
  isMethodist,
  companyStates,
  issuesRefetch,
  issuesTypesList,
  isCurrentExecutor,
  userRole,
  companyIssuesStates,
  currentTaskState,
  common,
  changeFilter,
  changeOrder,
  filter,
  ordering,
  issuesFlowSteps,
  userRoleId,
}: TaskSidebarProps & TemplateCommonProps) {
  const classNames = cx('task-errors', className);
  return (
    <div className={classNames}>
      {loading ? <TaskErrorsSkeleton /> : null}
      {/*{isEdit ? <ErrorEdit onBack={onToggleEdit} item={testItem2} /> : null}*/}
      <Block
        hidden={loading}
        className={cx('task-errors__content', { 'task-errors__content-empty': isEmpty(dataList) })}
      >
        <ErrorsFilter
          isVideo={isVideo}
          changeOrder={changeOrder}
          changeFilter={changeFilter}
          filter={filter}
          ordering={ordering}
        />
        {dataList?.map((elem) => {
          return (
            <ErrorCard
              key={elem.id}
              isVideo={isVideo}
              item={elem}
              isSelectedIssue={selectedIssue?.id === elem?.id}
              onSelectedIssue={onSelectedIssue}
              isMethodist={isMethodist}
              isProductExecutor={isProductExecutor}
              companyStates={companyStates}
              issuesRefetch={issuesRefetch}
              issuesTypesList={issuesTypesList}
              isCurrentExecutor={isCurrentExecutor}
              userRole={userRole}
              companyIssuesStates={companyIssuesStates}
              currentTaskState={currentTaskState}
              issuesFlowSteps={issuesFlowSteps}
              userRoleId={userRoleId}
            />
          );
        })}
      </Block>
      <Block empty hidden={!isEmpty(dataList) || loading}>
        <Empty text={common.t<string>('common.noCorrections')} />
      </Block>
    </div>
  );
}

export default withCommonProps(TaskErrors);
