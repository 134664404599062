import React, { memo } from 'react';

import { cx } from 'utils';

import './styles.scss';

type AddMaterialBlockProps = {
  url?: string;
  text?: string | null;
  className?: string;
};

function AddMaterialBlock({ url, text, className }: AddMaterialBlockProps) {
  const classNames = cx('add-material-block', className);
  return (
    <div className={classNames}>
      <a href={url} target="_blank">
        {text}
      </a>
    </div>
  );
}

export default memo(AddMaterialBlock);
