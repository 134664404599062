import React, { memo } from 'react';

import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { cx } from 'utils';

import Button from 'components/button/Button';

import { ButtonTypesEnum } from '../button/types';

import './styles.scss';

interface IHeaderDrawer {
  className?: string;
  onClose?: () => void;
  title?: string;
  children?: React.ReactNode;
  goBack?: () => void;
}

function DrawerHeader({ className, onClose, title, goBack, children }: IHeaderDrawer) {
  const classNames = cx('header-drawer', className);
  return (
    <div className={classNames}>
      <div className="header-drawer__wrap">
        {goBack ? <Button shape="circle" icon={<ArrowLeftOutlined />} onClick={goBack} /> : null}
        <span className="header-drawer__title">{title}</span>
      </div>
      {children ? <div className="header-drawer__extra">{children}</div> : null}
      <Button
        onClick={onClose}
        type={ButtonTypesEnum.text}
        icon={<CloseOutlined />}
        className="close-button testclass-historyofchanges-field-close"
      />
    </div>
  );
}

export default memo(DrawerHeader);
