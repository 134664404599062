import React, { memo } from 'react';

import { Radio } from 'antd';
import { cx } from 'utils';

import { IRadioGroup } from './types';

function RadioGroup({ className, size, isFull, options, variant = 'button', ...props }: IRadioGroup) {
  const classNames = cx('ccm-radio', className, {
    [`ccm-radio--${size}`]: size,
    [`ccm-radio--full`]: isFull,
  });
  return (
    <Radio.Group className={classNames} size={size} {...props}>
      {options?.map((option, index) => {
        if (variant === 'default') {
          return (
            <Radio
              value={option?.value || option}
              className={option?.className}
              key={index}
              disabled={option?.disabled}
            >
              {option.label}
            </Radio>
          );
        }

        return (
          <Radio.Button
            value={option?.value || option}
            className={option?.className}
            key={index}
            disabled={option?.disabled}
          >
            {option.label}
          </Radio.Button>
        );
      })}
    </Radio.Group>
  );
}

export default memo(RadioGroup);
