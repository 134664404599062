import { gql } from '@apollo/client';

import { USER_TYPE } from './fragments';

export const GET_PROJECT_TASK_DISCUSSIONS = gql`
  query GetProjectTaskDiscussions($taskId: Int) {
    project_task_discuss_messages(task_id: $taskId) {
      id
      message
      created_at
      is_active
      parent_message {
        id
      }
      creator {
        ...UserData
      }
      children {
        id
        message
        created_at
        updated_at
        is_active
        creator {
          ...UserData
        }
      }
    }
  }
  ${USER_TYPE}
`;
