import React, { memo } from 'react';

import { Drawer as ADrawer } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import { cx } from 'utils';

const Drawer = ({ className, children, ...props }: DrawerProps) => {
  const classNames = cx('ccm-drawer', className);
  return (
    <ADrawer rootClassName={classNames} {...props}>
      {children}
    </ADrawer>
  );
};

export default memo(Drawer);
