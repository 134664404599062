import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { GET_COMPANIES_FOR_INVITE } from 'queries/getCompaniesForInvite';
import { Company, CompanyEx } from 'types/entities';
import { arrToSelect } from 'utils';

type CompaniesForInvite = {
  companies_for_invite: Company[];
};

function useCompaniesForInvite(fetchPolicy?: WatchQueryFetchPolicy) {
  const { data } = useQuery<CompaniesForInvite>(GET_COMPANIES_FOR_INVITE, {
    fetchPolicy: fetchPolicy || 'network-only',
  });

  return useMemo(() => {
    return {
      companies: data?.companies_for_invite,
      companiesForSelect: arrToSelect<Company, CompanyEx>(data?.companies_for_invite),
    };
  }, [data?.companies_for_invite]);
}

export default useCompaniesForInvite;
