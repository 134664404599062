import { gql } from '@apollo/client';

export const GET_NEXT_TASK_INFO = gql`
  query GetNextTaskInfo {
    next_task {
      pk
      learning_object {
        id
      }
      cached_linked_artefact {
        cached_artefact_iteration_count
      }
      artefacts {
        id
        artefact_type {
          type
        }
        artefact_versions {
          file {
            file_type
          }
        }
      }
    }
  }
`;
