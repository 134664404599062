import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { GET_COMPANY_USERS } from 'queries/getCompanyUsers';
import { BusinessRoleEnum, UserExtended, UserExtendedSelect, UserRoleBase } from 'types/entities/user';

import { CcmUserState } from '../../schemas/types';
import useCommonContext from '../useCommonContext';

export type CompanyUsersType = {
  pk: string;
  id: string;
  name: string;
  company_type: string;
  userrole_set: UserRoleBase[];
  companyuser_set: UserExtended[];
  active_users: UserExtended[];
};

function useCompanyUsers(companyId: number, fetchPolicy?: WatchQueryFetchPolicy) {
  const common = useCommonContext();
  const { data, loading } = useQuery(GET_COMPANY_USERS, {
    variables: {
      company_id: companyId,
    },
    fetchPolicy: fetchPolicy || 'network-only',
    skip: !companyId,
  });

  const producersMemoized = useMemo(() => {
    return data?.company_users?.active_users?.filter(
      (user: UserExtended) =>
        user.user.state === CcmUserState.Registered &&
        user.business_roles.find((e) => e.role === BusinessRoleEnum.manager)
    );
  }, [data?.company_users?.active_users]);

  const authorsMemoized = useMemo(() => {
    return data?.company_users?.active_users?.filter(
      (user: UserExtended) =>
        user.user.state === CcmUserState.Registered &&
        user.business_roles.find((e) => e.role === BusinessRoleEnum.author)
    );
  }, [data?.company_users?.active_users]);

  const executorsMemoized = useMemo(() => {
    return data?.company_users?.active_users?.filter(
      (user: UserExtended) =>
        user.user.state === CcmUserState.Registered &&
        user.business_roles.find((e) => e.role === BusinessRoleEnum.productionExecutor)
    );
  }, [data?.company_users?.active_users]);

  const executorsToSelect = useMemo(() => {
    return executorsMemoized?.map((e: UserExtended) => {
      return {
        ...e,
        label: `${e.user.first_name} ${e.user.last_name}`,
        value: e.user.id,
      };
    });
  }, [executorsMemoized]);

  const authorsToSelect = useMemo(() => {
    return authorsMemoized?.map((e: UserExtended) => {
      return {
        ...e,
        label: `${e.user.first_name} ${e.user.last_name}`,
        value: e.user.id,
      };
    });
  }, [authorsMemoized]);

  const activeCompanyUsersMemoized = useMemo(() => {
    return data?.company_users?.active_users;
  }, [data?.company_users?.active_users]);

  const currentCompanyUserMemoized: UserExtended | undefined = useMemo(() => {
    return data?.company_users?.active_users?.find((user: UserExtended) => Number(user.user.id) === common.userId);
  }, [common.userId, data?.company_users?.active_users]);

  const producersDropdownCheckbox = producersMemoized?.map((u: UserExtended) => {
    return {
      id: u?.user.id,
      label: `${u.user.first_name} ${u.user.last_name}`,
      value: u?.user.id,
    };
  });

  return useMemo(() => {
    return {
      companyUsersData: data?.company_users as CompanyUsersType,
      companyProducers: producersMemoized as UserExtended[],
      executorsToSelect: executorsToSelect as UserExtendedSelect[],
      authorsToSelect: authorsToSelect as UserExtendedSelect[],
      currentCompanyUser: currentCompanyUserMemoized,
      activeCompanyUsers: activeCompanyUsersMemoized as UserExtended[],
      producersDropdownCheckbox,
      companyUsersLoading: loading,
    };
  }, [
    activeCompanyUsersMemoized,
    authorsToSelect,
    currentCompanyUserMemoized,
    data?.company_users,
    executorsToSelect,
    loading,
    producersDropdownCheckbox,
    producersMemoized,
  ]);
}

export default useCompanyUsers;
