import React, { useMemo, useState } from 'react';

import { cx } from 'utils';
import { Checkbox, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import useBoolean from 'hooks/useBoolean';

import Button from '../button';
import { ButtonTypesEnum } from '../button/types';

import { DropdownCheckboxListProps } from './types';

import '../checkbox/styles.scss';
import './styles.scss';

function DropdownCheckboxList({
  className,
  name,
  placeholder,
  list,
  value,
  onChange,
  testClassName,
  size,
  disabled,
}: DropdownCheckboxListProps) {
  const [open, onOpen] = useBoolean(false);
  const classNames = cx('dropdown-checkbox', className, {
    'dropdown-checkbox--opened': open,
  });

  const menu = useMemo(() => {
    return (
      <div className="dropdown-checkbox-menu">
        <Checkbox.Group
          className="dropdown-checkbox-menu-list"
          options={list}
          name={name}
          value={value}
          onChange={onChange}
        />
      </div>
    );
  }, [list, name, onChange, value]);

  const onVisibleChange = (visible: boolean) => onOpen.setValue(visible);

  return (
    <div className={classNames}>
      <Dropdown
        disabled={disabled}
        overlay={menu}
        trigger={['click']}
        arrow
        onVisibleChange={onVisibleChange}
        getPopupContainer={(triggerNode) => triggerNode}
      >
        <Button disabled={disabled} type={ButtonTypesEnum.default} className={testClassName} size={size}>
          <span className="dropdown-checkbox__value">{placeholder}</span>
          <DownOutlined style={{ fontSize: '10px' }} />
        </Button>
      </Dropdown>
    </div>
  );
}

export default DropdownCheckboxList;
