import React, { memo } from 'react';

import { Skeleton } from 'antd';
import { cx } from 'utils';

type TabPaneSkeletonProps = {
  loading?: boolean;
  children: React.ReactNode;
  className?: string;
};

const styles = {
  width: '94px',
};

function TabPaneSkeleton({ className, loading, children }: TabPaneSkeletonProps) {
  if (loading) {
    return <Skeleton.Button size="small" style={styles} />;
  }

  return <span className={cx('ccn-tab-pane', className)}>{children}</span>;
}

export default memo(TabPaneSkeleton);
