import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, IStore } from 'types/store';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PATH_NAMES } from 'router/constants';
import i18n from 'i18next';
import { BusinessRoleEnum } from 'types/entities/user';
import { isEmpty } from 'lodash';
import jwtDecode from 'jwt-decode';

import { checkToken, setAuthDataAction, setMeDataAction } from '../store/auth/actions';
import { localStorageService } from '../services/localStorageService';
import { IAuthData } from '../store/auth/interfaces';

import useMeInfo from './queries/useMeInfo';
import useCommonContext from './useCommonContext';
import useCompanyRoles from './queries/useCompanyRoles';

const UseInitApp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const common = useCommonContext();
  const { isAuthenticated } = useSelector((state: IStore) => state.auth);
  const { me } = useSelector((state: IStore) => state.auth);
  const dispatch: AppDispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getMe } = useMeInfo();
  useCompanyRoles();
  useEffect(() => {}, []);

  const access = searchParams.get('a');
  const refresh = searchParams.get('r');

  useEffect(() => {
    if (access && refresh) {
      const authData = {
        access: access,
        refresh: refresh,
      };
      localStorageService.setAuthData(authData);
      const data = jwtDecode<IAuthData>(access);
      dispatch(setAuthDataAction(data));
      searchParams.delete('a');
      searchParams.delete('r');
      setSearchParams(searchParams);
    }
  }, [access, dispatch, refresh, searchParams, setSearchParams]);

  /**
   * Change lang according language env variable
   */
  useEffect(() => {
    if (process.env.REACT_APP_CCM_LANG) {
      i18n.changeLanguage(process.env.REACT_APP_CCM_LANG);
    }
  }, []);
  /**
   * Change page title
   */
  const appCompany = process.env.REACT_APP_CCM_COMPANY;
  useEffect(() => {
    if (appCompany === 'constr') {
      document.title = 'Constructor';
    } else {
      document.title = common.t<string>('common.appName');
    }
  }, [appCompany, common]);

  const checkPath = useCallback(() => {
    const authData = localStorageService.getAuthData();

    if (authData) {
      if (location.pathname === PATH_NAMES.auth.login || location.pathname === PATH_NAMES.auth.signup) {
        const userRoles = me?.companies?.[0]?.business_roles;

        if (isEmpty(userRoles) || !userRoles) {
          navigate(PATH_NAMES.tasks.base);
        } else {
          if (
            userRoles?.some((item) => item.role === BusinessRoleEnum.support) ||
            userRoles?.some((item) => item.role === BusinessRoleEnum.support_manager) ||
            userRoles?.some((item) => item.role === BusinessRoleEnum.authorManager)
          ) {
            navigate(PATH_NAMES.tasks.base);
          } else {
            navigate(PATH_NAMES.dashboard.base);
          }
        }
      }
    } else {
      navigate(PATH_NAMES.auth.login);
    }
  }, [location.pathname, me, navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      if (!me) {
        getMe().then((response) => {
          dispatch(setMeDataAction(response?.data?.me));
          checkPath();
        });
      } else {
        checkPath();
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(checkToken());
    }
  }, [dispatch, isAuthenticated, checkPath, getMe, me]);
};

export default UseInitApp;
