import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { GET_ARTEFACT_ISSUES_VIDEO_LIST } from '../../queries/getArtefactIssuesVideoList';

type PresentationFilter = {
  artefactId: number;
  ordering?: string;
  filter?: string;
};
function useArtefactVideoIssues({ artefactId, filter, ordering }: PresentationFilter) {
  const { data, refetch, loading } = useQuery(GET_ARTEFACT_ISSUES_VIDEO_LIST, {
    variables: {
      artefactId: artefactId,
      ordering: ordering,
      tasks_state: filter,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !artefactId,
  });

  const errorsRemapped = useMemo(() => {
    return data?.artefact_content_video_found_issues?.edges?.map((issue: any) => issue?.node);
  }, [data?.artefact_content_video_found_issues?.edges]);

  return {
    issuesData: data,
    issuesList: errorsRemapped,
    issuesRefetch: refetch,
    issuesLoading: loading,
  };
}

export default useArtefactVideoIssues;
