/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ExportContentParams = {
    file_extension?: ExportContentParams.file_extension;
    export_project_content: boolean;
};

export namespace ExportContentParams {

    export enum file_extension {
        XLS = 'xls',
        CSV = 'csv',
    }


}
