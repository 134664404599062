import React, { memo, RefObject } from 'react';

import { cx } from 'utils';

import './styles.scss';

type PopupCardProps = {
  className?: string;
  containerRef?: RefObject<any>;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

function PopupCard({ className, containerRef, children, style }: PopupCardProps) {
  const classNames = cx('popup-card', className);
  return (
    <div ref={containerRef} style={style} className={classNames}>
      {children}
    </div>
  );
}

export default memo(PopupCard);
