import { TFunction } from 'i18next';

import { SystemRolesType } from '../../types/entities';

export const getSystemRoles = (t: TFunction) => [
  { id: 1, value: SystemRolesType.ADMIN, label: t('systemRoles.admin') },
  { id: 2, value: SystemRolesType.OWNER, label: t('systemRoles.owner') },
  { id: 3, value: SystemRolesType.MEMBER, label: t('systemRoles.member') },
  { id: 4, value: SystemRolesType.GUEST, label: t('systemRoles.guest') },
];
