import React, { memo } from 'react';

import { DndProvider } from 'react-dnd';
import {
  Tree,
  NodeModel,
  MultiBackend,
  getBackendOptions,
  getDescendants,
  getParents,
} from '@minoru/react-dnd-treeview';
import { getIndex } from 'utils';

import useBoolean from 'hooks/useBoolean';

import { CustomData as CustomDataPlan } from '../projectPlanTree/types';

import { CustomData, ProjectTreeProps } from './types';
import CustomPlaceholder from './components/placeholder';
import NodeWrapper from './components/nodeWrapper';

import './tree.scss';

function ProjectContentTree({
  treeData,
  settings,
  selected,
  isEdit,
  onHandleDrop,
  onCanDrop,
  onCopy,
  onSubmitEditSection,
  onDelete,
  anchorTree,
  setSearchParams,
  onToggleCheckbox,
}: ProjectTreeProps) {
  const openTrigger = useBoolean(false);

  return (
    <div className="ccm-tree-container">
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <Tree
          ref={anchorTree}
          tree={treeData}
          rootId={0}
          render={(node: NodeModel<CustomData>, params) => {
            const descendants: NodeModel<CustomDataPlan>[] = getDescendants(treeData, Number(node.id));

            const count = isEdit
              ? getIndex(treeData, node.id, node.parent, '', false)
              : node?.data?.humanReadableNumber;

            return (
              <NodeWrapper
                node={node}
                params={params}
                settings={settings}
                selected={selected}
                isEdit={isEdit}
                parents={getParents(treeData, node.id)}
                descendants={getDescendants(treeData, node.id)}
                count={count}
                onSubmit={onSubmitEditSection}
                onDelete={onDelete}
                onCopy={onCopy}
                onToggleCheckbox={onToggleCheckbox}
                setSearchParams={setSearchParams}
                hasChild={!!descendants.find((n) => !n?.data?.is_extra)}
                openTrigger={openTrigger}
              />
            );
          }}
          onDrop={onHandleDrop}
          canDrop={onCanDrop}
          canDrag={() => isEdit}
          dropTargetOffset={50}
          sort={false}
          placeholderRender={() => <CustomPlaceholder />}
          classes={{ root: 'ccm-tree-list', listItem: 'ccm-tree-list-item' }}
        />
      </DndProvider>
    </div>
  );
}
export default memo(ProjectContentTree);
