import React, { memo, useCallback } from 'react';

import { CloseOutlined, LinkOutlined, LoadingOutlined } from '@ant-design/icons';

import Text from 'components/text';
import { TextVariantEnum } from 'components/text/types';
import ElementType from 'components/elementType/ElementType';
import Button from 'components/button/Button';

import { ButtonTypesEnum } from '../../components/button/types';
import { StateType } from '../../types/entities';
import ExportElementPopup from '../../pages/project/components/projectContentTree/components/exportElementPopup';
import Block from '../../components/block/Block';
import useVisible from '../../hooks/useVisible';
import useSingleExport from '../../pages/project/subPages/content/hooks/useSingleExport';

import { CoursePreviewHeaderProps } from './types';

import styles from './course.module.scss';

function CoursePreviewHeader({ count, node, projectId, onClickLink, onCancel }: CoursePreviewHeaderProps) {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  const onShowExport = useCallback(() => setIsVisible(true), [setIsVisible]);
  const onCloseExport = useCallback(() => setIsVisible(false), [setIsVisible]);

  const singleExportInst = useSingleExport({
    projectId,
    node,
    onCloseExport,
  });
  return (
    <div className={styles.header}>
      <div className={styles.header__wrapper}>
        <Text variant={TextVariantEnum.description}>{count}</Text>
        <ElementType
          className="ml_12"
          hasTooltip
          objectState={node?.data?.objectState}
          url={node?.data?.learningObjectType?.icon_image}
          name={node?.data?.learningObjectType?.name}
          progress={node?.data?.progress}
          sequences={node?.data?.artefactSequence}
        />
        <div className={styles.header__buttons}>
          <Block
            hidden={node?.data?.objectState !== StateType.DONE}
            className="flex-align-items--center flex-justify-content--center"
          >
            {singleExportInst.loading ? (
              <Block className="mr_16">
                <LoadingOutlined style={{ fontSize: '16px' }} />
              </Block>
            ) : (
              <ExportElementPopup
                anchor={ref}
                visible={isVisible}
                node={node}
                singleExport={singleExportInst}
                onShow={onShowExport}
                onClose={onCloseExport}
              />
            )}
          </Block>
          <Button type={ButtonTypesEnum.link} icon={<LinkOutlined />} onClick={onClickLink} />
          <Button
            className="ccm-modal--button-close"
            onClick={onCancel}
            icon={<CloseOutlined style={{ fontSize: 17 }} />}
            type={ButtonTypesEnum.primary}
            size="xl"
          />
        </div>
      </div>
    </div>
  );
}

export default memo(CoursePreviewHeader);
