import { BusinessRoleEnum, UserRoleBase, UserRoles } from '../types/entities/user';
import { CompanyRole } from '../hooks';

export const checkUserRole = (roles?: UserRoles[] | null, role?: BusinessRoleEnum): boolean =>
  !!roles?.some((r) => r?.role?.role === role);

export const checkUserBusinessRole = (roles?: UserRoleBase[], role?: BusinessRoleEnum) =>
  roles?.some((r) => r?.role === role);

export const getRoleNameByType = (roles?: CompanyRole[], type?: BusinessRoleEnum): string => {
  return roles?.find((role) => role.role === type)?.name || '';
};
