import React, { memo } from 'react';

import { Skeleton } from 'antd';

const arr = Array.from(Array(5).keys());

function SkeletonPlan() {
  return (
    <div className="skeleton-project-content">
      {arr.map((e) => {
        return (
          <div key={e} className="skeleton-project-content__row">
            <Skeleton paragraph={false} avatar={false} active />
            <Skeleton paragraph={false} avatar={false} active />
            <Skeleton paragraph={false} avatar={false} active />
            <Skeleton paragraph={false} avatar={false} active />
            <Skeleton paragraph={false} avatar={false} active />
          </div>
        );
      })}
    </div>
  );
}

export default memo(SkeletonPlan);
