import { useMemo } from 'react';

import { BusinessRoleEnum } from 'types/entities';

import { checkUserBusinessRole } from 'utils/roles';

import useCommonContext from 'hooks/useCommonContext';
import useSeparateTaskQuery from 'hooks/queries/useSeparateTaskQuery';

function useSeparateTaskAbility(taskId?: string) {
  const common = useCommonContext();
  const { separateTask } = useSeparateTaskQuery(taskId, 'cache-and-network');

  const isManager = useMemo(() => {
    return (
      (checkUserBusinessRole(common.businessRoles, BusinessRoleEnum.executiveManager) &&
        common.company?.id === separateTask?.project.company.id) ||
      (checkUserBusinessRole(common.businessRoles, BusinessRoleEnum.manager) &&
        common.company?.id === separateTask?.project.company.id)
    );
  }, [common.businessRoles, common.company?.id, separateTask?.project?.company.id]);

  const isCreator = useMemo(() => {
    return separateTask?.creator?.id === common?.userId?.toString();
  }, [common.userId, separateTask?.creator?.id]);

  const isAssignee = useMemo(() => {
    return !!separateTask?.current_assignees?.find(
      (item) =>
        item?.project_user?.company_user.user?.id === common?.userId?.toString() &&
        item.is_active &&
        item.is_current_executor
    );
  }, [common?.userId, separateTask?.current_assignees]);

  const canIEdit = useMemo(() => {
    return isManager || isCreator || isAssignee;
  }, [isAssignee, isCreator, isManager]);

  const canIEditDeadlines = useMemo(() => {
    return isManager || isCreator;
  }, [isCreator, isManager]);

  const canIRemoveTask = useMemo(() => {
    return isManager || isCreator;
  }, [isCreator, isManager]);

  return {
    canIEdit,
    canIEditDeadlines,
    canIRemoveTask,
  };
}

export default useSeparateTaskAbility;
