import React, { memo } from 'react';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

function CoursesTableHeader() {
  const common = useCommonContext();
  return (
    <div className="course-header">
      <div className="course-header__stage mr_12"></div>
      <div className="course-header__name mr_8">{common.t<string>('common.name')}</div>
      <div className="course-header__avatars mr_32">
        {common.t<string>('pages.addCourse.participants.participants')}
      </div>
      <div className="course-header__period flex-row flex-align-items--center">
        {common.t<string>('common.productionsDates')}
      </div>
      <div className="course-header__tasks"> {common.t<string>('pages.tasks.title')}</div>
      <div className="course-header__deadline">{common.t<string>('common.deadlinesBroken')}</div>
      <div className="course-header__course-date flex-row flex-align-items--center">
        {common.t<string>('common.history')}
      </div>
      <div className="course-header__progress flex-row flex-align-items--center">
        {common.t<string>('pages.courses.table.progress')}
      </div>
      <div className="course-header__state"></div>
      <div className="course-header__health"></div>
    </div>
  );
}

export default memo(CoursesTableHeader);
