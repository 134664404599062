import React, { memo, ReactNode } from 'react';

import { UserBase } from 'types/entities';
import { getFormatDateByStr } from 'utils';

import Avatar from '../avatar/Avatar';
import Block from '../block';
import Tag from '../tag';

import './styles.scss';

type AvatarWithInfoProps = {
  user: UserBase | any;
  role?: ReactNode;
  createdAt?: string;
};

function AvatarWithInfo({ user, role, createdAt }: AvatarWithInfoProps) {
  return (
    <div className="avatar-info">
      <Avatar src={user?.icon_image} size={40}>
        {user?.name?.[0]}
      </Avatar>
      <div className="avatar-info__wrap">
        <span className="avatar-info__name">{user?.name}</span>

        <Block empty hidden={!createdAt}>
          <span className="avatar-info__date">{getFormatDateByStr(createdAt, 'DD MMM HH:mm')}</span>
        </Block>

        <Block empty hidden={!role}>
          <Tag>{role}</Tag>
        </Block>
      </div>
    </div>
  );
}

export default memo(AvatarWithInfo);
