import React, { useMemo, useState } from 'react';

import { Form, Upload } from 'antd';
import { cx } from 'utils';
import { isEmpty, noop } from 'lodash';
import { UploadOutlined } from '@ant-design/icons';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { onCreateArtefactType } from 'api/requests/companyState';

import HeaderModal from 'components/modal/components/header';
import TextField from 'components/textField';
import Select from 'components/select/Select';
import Checkbox from 'components/checkbox/Checkbox';
import InputNum from 'components/inputNum';
import FooterModal from 'components/modal/components/footer';
import Button from 'components/button';

import useBoolean from 'hooks/useBoolean';

import { AdminCompanyArtefactType } from '../../types';

import './styles.scss';

type Props = {
  optionsLO: {
    id: string;
    label: string;
    value: string;
  }[];
  companyId: string;
  refetch: () => Promise<any>;
  onCancel: () => void;
};

type FormData = {
  name: string;
  type: string;
  is_final: boolean;
  learning_object_type_id: string;
  order: number;
  cob_artefact_type: string;
};

const CreateNewArtefactType = ({ companyId, optionsLO, refetch, onCancel }: Props) => {
  const [loading, onLoading] = useBoolean(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const classNames = cx('ccm-modal--wrapper create-art-type');

  const typeOptions = useMemo(() => {
    const typeOptionsArr = Object.keys(AdminCompanyArtefactType.type);
    return typeOptionsArr.map((el) => {
      return {
        label: el,
        value: el.toLowerCase(),
      };
    });
  }, []);

  const cobTypeOptions = useMemo(() => {
    const typeOptionsArr = Object.keys(AdminCompanyArtefactType.cob_artefact_type);
    return typeOptionsArr.map((el) => {
      return {
        label: el,
        value: el.toLowerCase(),
      };
    });
  }, []);

  const onSubmit = async (values: FormData) => {
    onLoading.on();
    if (companyId) {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('type', values.type);
      formData.append('is_final', values.is_final ? 'true' : 'false');
      if (values.learning_object_type_id) {
        formData.append('learning_object_type_id', values.learning_object_type_id);
      }
      if (values.cob_artefact_type) {
        formData.append('cob_artefact_type', values.cob_artefact_type);
      }
      if (values.order) {
        formData.append('order', `${values.order}`);
      }
      if (!isEmpty(fileList)) {
        const file = fileList[0];
        formData.append('icon_image', file.originFileObj as Blob);
      }

      await onCreateArtefactType(companyId, formData);
      await refetch().then(() => {
        onCancel();
        onLoading.off();
      });
    }
  };
  const customRequest = () => null;

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    onChange: (info) => {
      setFileList(info.fileList);
    },
    beforeUpload: () => {
      return false;
    },
    fileList,
  };

  return (
    <div className={classNames}>
      <HeaderModal isSmallTitle title="Add new artefact type" />
      <Form
        disabled={loading}
        className="create-art-type__form"
        name="create-art-type"
        layout="vertical"
        requiredMark={false}
        onFinish={onSubmit}
      >
        <TextField
          label="Name"
          name="name"
          size="xl"
          placeholder="Artefact type name"
          rules={[{ required: true, message: 'required field' }]}
        />
        <Select
          label="Type"
          name="type"
          size="xl"
          options={typeOptions}
          placeholder="Select type"
          rules={[{ required: true, message: 'required field' }]}
        />
        <Select
          label="COB artefact type"
          name="cob_artefact_type"
          size="xl"
          options={cobTypeOptions}
          placeholder="Select COB type"
        />
        <Select
          label="Learning object type"
          name="learning_object_type_id"
          size="xl"
          options={optionsLO}
          placeholder="Learning object type"
        />
        <Form.Item label="Order" name="order" style={{ minHeight: 98 }}>
          <InputNum size="xl" placeholder="Order" />
        </Form.Item>

        <Form.Item name="is_final" valuePropName="checked" className="mb_24">
          <Checkbox>Is final</Checkbox>
        </Form.Item>
        <div className="mb_24">
          <Upload {...props} name="logo" action="" listType="text" customRequest={customRequest}>
            <Button icon={<UploadOutlined />}>Upload icon</Button>
          </Upload>
        </div>
        <FooterModal
          loading={loading}
          labelCancel="Cancel"
          labelSubmit="Save"
          onCancel={onCancel}
          onSubmit={noop}
          isForm
        />
      </Form>
    </div>
  );
};

export default CreateNewArtefactType;
