import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { fileToDataUri } from 'utils';

import './styles.scss';
import { FileTextOutlined } from '@ant-design/icons';
import TruncateMarkup from 'react-truncate-markup';

import useHover from '../../../hooks/useHover';

import ImageCardMask from './ImageCardMask';
import ImageCardFileName from './ImageCardFileName';

type ImageCardProps = {
  item: UploadFile;
  onRemove?: (fileId: string) => void;
};

function ImageCard({ item, onRemove }: ImageCardProps) {
  const [url, setUrl] = useState<string>('');
  const anchor = useRef<HTMLDivElement>(null);
  const hovered = useHover(anchor);
  const isImg =
    item?.type === 'image/jpeg' ||
    item?.type === 'image/jpg' ||
    item?.type === 'image/png' ||
    item?.type === 'image/gif';

  const onPrepareImage = useCallback(
    async (file: RcFile) => {
      if (isImg) {
        await fileToDataUri(file).then((data: any) => {
          setUrl(data);
        });
      }
    },
    [isImg]
  );

  useEffect(() => {
    if (isImg && item.originFileObj) {
      onPrepareImage(item.originFileObj);
    }
  }, [isImg, item?.originFileObj, onPrepareImage]);

  const onClickRemove = useCallback(() => {
    onRemove?.(item?.uid);
  }, [item?.uid, onRemove]);

  return (
    <div ref={anchor} className="upload-wall-preview">
      {hovered ? <ImageCardMask onRemove={onClickRemove} /> : null}
      {isImg ? (
        <img src={url} alt="image" />
      ) : (
        <>
          <FileTextOutlined style={{ fontSize: 32 }} />
          <ImageCardFileName name={item.name} />
        </>
      )}
    </div>
  );
}

export default ImageCard;
