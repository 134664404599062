import { gql } from '@apollo/client';

import { COMPANY_TYPE, LAST_STATE, SEPARATE_TASK, USER_ROLE_TYPE, USER_TYPE } from './fragments';

export const GET_PROJECT_SEPARATE_TASKS = gql`
  query ProjectPlanSeparateTasks($project_id: Int!, $plan_filters: PlanFilter) {
    project_plan_separate_tasks(project_id: $project_id, plan_filters: $plan_filters) {
      id
      name
      task_type
      last_state_updated_at
      last_state_is_changed
      project {
        id
      }
      cached_linked_artefact {
        id
      }
      last_state {
        ...LastState
      }
      current_assignees {
        id
        is_current_executor
        is_active
        project_user {
          id
          company_user {
            company {
              ...CompanyData
            }
            user {
              ...UserData
            }
          }
          roles {
            id
            role {
              ...RoleData
            }
          }
        }
      }
      separate_task {
        ...SeparateTask
      }
    }
  }
  ${USER_TYPE}
  ${USER_ROLE_TYPE}
  ${LAST_STATE}
  ${SEPARATE_TASK}
  ${COMPANY_TYPE}
`;
