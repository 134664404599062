import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { BusinessRoleEnum } from 'types/entities/user';
import { splitActiveUsersByRole, userMapToSelect } from 'utils';

import { GET_COMPANY_USERS } from '../../queries/getCompanyUsers';

export type UserRoleSet = {
  id: string;
  name: string;
  role: BusinessRoleEnum;
};

function useCompanyUsersByRole(
  companyId: string | undefined,
  fetchPolicy?: WatchQueryFetchPolicy,
  roleId?: string | null
) {
  const companyUsers = useQuery(GET_COMPANY_USERS, {
    variables: {
      company_id: companyId,
    },
    skip: !companyId,
    fetchPolicy: fetchPolicy || 'network-only',
  });
  const companyUserRoles: UserRoleSet[] = companyUsers?.data?.company_users?.userrole_set;

  const { stakeholders, producers, methodists, authors, videoProductions, executors, usersByRoleId } =
    splitActiveUsersByRole(companyUsers?.data?.company_users?.active_users, roleId);

  return useMemo(() => {
    return {
      authors: userMapToSelect(authors),
      methodists: userMapToSelect(methodists),
      producers: userMapToSelect(producers),
      stakeholders: userMapToSelect(stakeholders),
      productionExecutors: userMapToSelect(executors),
      videoProductions: userMapToSelect(videoProductions),
      allCompanyUsers: companyUsers?.data?.company_users?.active_users,
      companyUserRoles,
      usersByRoleId,
      onCompanyUsersRefetch: companyUsers.refetch,
    };
  }, [
    authors,
    companyUserRoles,
    companyUsers?.data?.company_users?.active_users,
    executors,
    methodists,
    producers,
    stakeholders,
    videoProductions,
    usersByRoleId,
    companyUsers.refetch,
  ]);
}

export default useCompanyUsersByRole;
