import React, { memo } from 'react';

import SvgInline from 'components/svgInline';

type TaskSidebarHeaderContentProps = {
  icon?: string;
  humNum?: number;
};

function TaskSidebarHeaderContent({ icon, humNum }: TaskSidebarHeaderContentProps) {
  return (
    <div className="sidebar__header-info">
      <div>
        <span className="sidebar__header-human-number">{humNum}</span>
      </div>
      <SvgInline url={icon} />
    </div>
  );
}

export default memo(TaskSidebarHeaderContent);
