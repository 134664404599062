import React, { memo } from 'react';

import { cx } from 'utils';

import { StatusRowBackendEnum } from './types';

import './row.scss';

type StatusRowProps = {
  type?: StatusRowBackendEnum;
  children?: string;
  className?: string;
};

function StatusRow({ children, className, type }: StatusRowProps) {
  const classNames = cx('status-row', className, {
    [`status-row--${type}`]: type,
  });
  return (
    <div className={classNames}>
      <span className="status-row__text">{children}</span>
    </div>
  );
}

export default memo(StatusRow);
