import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { isEmpty, noop } from 'lodash';
import { CaretDownOutlined, CaretLeftOutlined } from '@ant-design/icons';
import { cx } from 'utils';
import { ProjectLearningStructureEnum, StateType } from 'types/entities';
import Can from 'config/Can';

import Block from 'components/block';
import Button from 'components/button';
import { ButtonTypesEnum } from 'components/button/types';
import Checkbox from 'components/checkbox';

import useHover from 'hooks/useHover';
import useVisible from 'hooks/useVisible';

import { ProjectTreeItemProps } from '../../types';
import MainContent from '../mainContent';

import './styles.scss';

function ProjectTreeItem({
  node,
  params,
  openTrigger,
  settings,
  count,
  selected,
  parents,
  descendants,
  isEdit,
  hasChild,
  onSubmit,
  onDelete,
  onCopy,
  setSearchParams,
  onToggleCheckbox,
}: ProjectTreeItemProps) {
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const [showExtraButtons, setShowExtraButtons] = useState<boolean>(false);
  const anchor = useRef<HTMLDivElement | null>(null);
  const anchorCheckbox = useRef<HTMLDivElement | null>(null);
  const elementHovered = useHover(anchor);

  const [openedPopup, onOpenedPopup] = openTrigger;
  const viewExport = useVisible(openedPopup);

  useEffect(() => {
    if (!viewExport.isVisible) {
      onOpenedPopup.off();
    }
  }, [onOpenedPopup, viewExport.isVisible]);

  const onMouseEnter = useCallback(() => {
    if (isEdit) {
      setShowExtraButtons(true);
    }
  }, [isEdit]);
  const onMouseLeave = useCallback(() => {
    if (isEdit) {
      setShowExtraButtons(false);
    }
  }, [isEdit]);

  const onShowEdit = useCallback(() => {
    setShowEdit(true);
  }, []);

  const onCloseEdit = useCallback(() => {
    setShowEdit(false);
  }, []);

  const onPreview = () => {
    setSearchParams?.({ elementId: node.id });
  };

  const onClickDelete = useCallback(() => {
    onDelete(node.id);
  }, [node.id, onDelete]);

  const onClickCopy = useCallback(() => {
    onCopy(node.id);
  }, [node.id, onCopy]);

  const onToggle = useCallback(() => params.onToggle(), [params]);

  const classNames = cx('course-tree-item', {
    [`course-tree-item--level--opened`]: params.isOpen,
    [`course-tree-item--level-${params.depth}`]: params.depth === 0 || params.depth,
    'course-tree-item--edit': showEdit,
    'course-tree-item--focused': viewExport.isVisible,
    'course-tree-item--hovered': !openedPopup && elementHovered,
  });

  const onChangeCheckbox = useCallback(() => {
    onToggleCheckbox?.(Number(node.id));
  }, [node.id, onToggleCheckbox]);

  const elemDescendants = descendants.filter(
    (d) => d?.data?.learningStructureType === ProjectLearningStructureEnum.production_item
  );

  const isSelected = !!selected.find((s) => Number(s) === Number(node.id));

  const isIndeterminate = elemDescendants.some((d) => selected.includes(d.id.toString()));

  const allDescendantsSelected = useMemo(() => {
    if (isEmpty(elemDescendants)) {
      return false;
    }
    return elemDescendants.every((d) => selected.includes(d.id.toString())) && !isEmpty(descendants);
  }, [descendants, elemDescendants, selected]);

  const hasDescendantsDone = elemDescendants.some((d) => d?.data?.objectState === StateType.DONE);

  const showCheckbox = () => {
    if (isSelected || isIndeterminate || allDescendantsSelected) {
      return true;
    }
    if (hasDescendantsDone && elementHovered) {
      return true;
    }
    return node?.data?.objectState === StateType.DONE && elementHovered;
  };

  return (
    <div ref={anchor} className={classNames}>
      <Can I="manage" a="Project.content.download.button">
        <Block
          hidden={!showCheckbox() || isEdit}
          blockRef={anchorCheckbox}
          className="course-tree-item__checkbox-download"
        >
          <Checkbox
            checked={allDescendantsSelected || isSelected}
            indeterminate={isIndeterminate && !isSelected && !allDescendantsSelected}
            onClick={onChangeCheckbox}
          />
        </Block>
      </Can>
      <div role="button" className="course-tree-item__wrapper" onClick={showEdit || showExtraButtons ? noop : onToggle}>
        <div className="course-tree-item__inner">
          <MainContent
            elementHovered={elementHovered}
            viewExport={viewExport}
            openTrigger={openTrigger}
            count={count}
            show={showEdit}
            showExtraButtons={showExtraButtons}
            parents={parents}
            descendants={descendants}
            settings={settings}
            isEdit={isEdit}
            hasChild={hasChild}
            node={node}
            params={params}
            onPreview={onPreview}
            onDelete={onClickDelete}
            onSubmit={onSubmit}
            onCopy={onClickCopy}
            onShowEdit={onShowEdit}
            onCloseEdit={onCloseEdit}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />

          <Block
            hidden={node.data?.learningStructureType === ProjectLearningStructureEnum.production_item}
            className={cx('course-tree-item__toggle', {
              ml_auto: isEdit,
            })}
          >
            {params.isOpen ? (
              <Button
                type={ButtonTypesEnum.text}
                size="large"
                icon={<CaretDownOutlined />}
                onClick={isEdit ? onToggle : noop}
              />
            ) : (
              <Button
                type={ButtonTypesEnum.text}
                size="large"
                icon={<CaretLeftOutlined />}
                onClick={isEdit ? onToggle : noop}
              />
            )}
          </Block>
        </div>
      </div>
    </div>
  );
}

export default ProjectTreeItem;
