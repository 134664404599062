import React from 'react';

import Dropdown from '../../../components/dropdown';
import DropdownMenu from '../../../components/dropdownMenu';

const data = [
  {
    id: '1',
    label: 'menu item 1',
    value: '1',
  },
  {
    id: '2',
    label: 'menu item 2',
    value: '2',
  },
  {
    id: '3',
    label: 'menu item 3',
    value: '3',
  },
  {
    id: '4',
    label: 'menu item 4',
    value: '4',
  },
  {
    id: '5',
    label: 'menu item 5',
    value: '5',
  },
];

const DropdownStories = () => {
  return (
    <div>
      <Dropdown className="mr_16" overlay={<DropdownMenu list={data} />}>
        Dropdown
      </Dropdown>
      <Dropdown disabled className="mr_16" overlay={<DropdownMenu list={data} />}>
        Dropdown
      </Dropdown>
      <Dropdown className="mr_16" variant="button" size="large" overlay={<DropdownMenu list={data} />}>
        Dropdown
      </Dropdown>
      <Dropdown className="mr_16" variant="button" size="middle" overlay={<DropdownMenu list={data} />}>
        Dropdown
      </Dropdown>
      <Dropdown className="mr_16" variant="button" size="small" overlay={<DropdownMenu list={data} />}>
        Dropdown
      </Dropdown>
      <Dropdown disabled className="mr_16" variant="button" size="small" overlay={<DropdownMenu list={data} />}>
        Dropdown
      </Dropdown>
    </div>
  );
};

export default DropdownStories;
