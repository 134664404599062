import React, { memo } from 'react';

import GanttItem from '../ganttItem';

import './styles.scss';

type GanttBlockProps = {
  user: any;
  minDate?: string;
  dimensions: {
    width: number;
    height: number;
  };
};

function GanttBlock({ dimensions, minDate, user }: GanttBlockProps) {
  const projectHeight = user.projects.length * 24;
  const gap = (user.projects.length - 1) * 4;

  const containerStyles = {
    width: dimensions.width,
    height: projectHeight < 64 ? `64px` : `${projectHeight + gap}px`,
  };

  return (
    <div style={containerStyles} className="gantt-block">
      {user?.projects.map((p: any, index: number) => {
        return (
          <div key={index} style={containerStyles} className="gantt-block__row">
            <GanttItem minDate={minDate} item={p} />
          </div>
        );
      })}
    </div>
  );
}

export default memo(GanttBlock);
