import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_TASK_ARTEFACTS_SIDEBAR } from 'queries/getTaskInfoSidebar';
import { CompanyArtefactTypeWithId, FileType, UserBase, UserRoles } from 'types/entities';

import { CompanyState } from '../../../types';

export type ArtefactSidebar = {
  id: string;
  is_turn_off: boolean;
  project_task: {
    id: string;
    current_assignees: {
      is_current_executor: boolean;
      is_active: boolean;
      by_artefact_role_assignment: {
        user_role: {
          name: string;
        };
      };
      project_user: {
        company_user: {
          id: string;
          user: UserBase;
        };
        roles: UserRoles[];
      };
    }[];
    last_state: {
      state_type: CompanyState.state_type;
    };
    cached_linked_artefact: {
      id: string;
      first_iteration_deadline: string;
      production_deadline: string;
      artefact_type: CompanyArtefactTypeWithId;
      current_file: {
        file_type: FileType;
      };
    };
  };
};

type ArtefactSidebarData = {
  artefact_production_sequence: ArtefactSidebar[];
};

const useArtefactSidebar = () => {
  const { learningObjectId } = useParams();

  const { data, loading, refetch } = useQuery<ArtefactSidebarData>(GET_TASK_ARTEFACTS_SIDEBAR, {
    variables: {
      learning_id: learningObjectId,
    },
    fetchPolicy: 'no-cache',
  });

  return {
    data: data?.artefact_production_sequence,
    loading,
    refetch,
  };
};

export default useArtefactSidebar;
