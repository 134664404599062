export enum StatusLabelEnum {
  blocked = 'blocked',
  block = 'block',
  done = 'done',
  edits = 'edits',
  approved = 'approved',
  registered = 'registered',
  new = 'new',
  in_progress = 'in_progress',
  reopen = 'reopen',
  review = 'review',
  cancelled = 'cancelled',
}

export enum StatusRowEnum {
  default = 'default',
  error = 'error',
  processing = 'processing',
  warning = 'warning',
  success = 'success',
}

export enum StatusRowBackendEnum {
  BLOCKED = 'blocked',
  DONE = 'done',
  REVIEW = 'review',
  READY = 'ready',
  REOPEN = 'reopen',
  CANCELLED = 'cancelled',
  REJECTED = 'rejected',
  NEW = 'new',
  IN_PROGRESS = 'on_progress',
}
