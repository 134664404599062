export type Notification = {
  timeout?: number;
  title?: string;
  description?: string;
  type: NotificationType;
  errorCode?: string;
  values?: any;
};

export enum NotificationType {
  error = 'error',
  warning = 'warning',
  success = 'success',
  info = 'info',
}

export enum SystemRolesType {
  ADMIN = 'ADMIN',
  GUEST = 'GUEST',
  OWNER = 'OWNER',
  MEMBER = 'MEMBER',
}
