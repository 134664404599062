import React from 'react';

import logoCF from 'assets/image/logoCF-black.svg';
import logoCCM from 'assets/image/ccm-black-logo.svg';
import constructor from 'assets/image/all_constructor_logo.svg';
import constructorSmall from 'assets/image/all_constructor_logo-s.svg';

import useCommonContext from '../../hooks/useCommonContext';

const logo = process.env.REACT_APP_CCM_LANG === 'en' ? logoCF : logoCCM;
const appCompany = process.env.REACT_APP_CCM_COMPANY;

type Props = {
  isOpen: boolean;
};

function MainLayoutHeader({ isOpen }: Props) {
  const common = useCommonContext();
  return (
    <div className="main-sidebar-header">
      {appCompany === 'constr' ? (
        <div className="main-sidebar-header__logo-wrap">
          <img
            className="main-sidebar-header__logo--external"
            src={isOpen ? constructor : constructorSmall}
            alt="logo"
          />
        </div>
      ) : (
        <>
          <img className="main-sidebar-header__logo" src={logo} alt="logo" />
          <span className="main-sidebar-header__title">{common.t<string>('common.appName')}</span>
        </>
      )}
    </div>
  );
}

export default MainLayoutHeader;
