import { message } from 'antd';
import { exportCancelProjectAll, exportCheckStatus, exportOneProject, exportProjectAll } from 'api/requests/export';
import { ExportCourseResponse } from 'types/models/ExportCourseResponse';

import { downloadByLink } from '../../../../../components/taskErrors/components/errorCard/utils';
import { ExportCourseElements, ExportVideoParams } from '../../types';

export async function getExportFile(
  projectData: string,
  payload: ExportCourseElements,
  closeNotification: () => void
): Promise<void> {
  const response = await exportProjectAll(projectData, payload).catch(() => {
    closeNotification();
  });
  while (true) {
    try {
      if (response?.export_id) {
        const exportStatus = await exportCheckStatus(response?.export_id.toString());
        if (exportStatus.state === ExportCourseResponse.state.DONE) {
          downloadByLink(exportStatus?.final_path);
          closeNotification();
          break;
        }
        if (
          exportStatus.state === ExportCourseResponse.state.CANCELLED ||
          exportStatus.state === ExportCourseResponse.state.FAILED
        ) {
          closeNotification();
          break;
        }
      }
    } catch (error) {
      closeNotification();
      break;
    }
    // wait for 10 seconds before making the next request
    // eslint-disable-next-line @typescript-eslint/no-loop-func
    await new Promise((resolve) => setTimeout(resolve, 10000));
  }
}

export async function cancelExportFile(
  projectData: string,
  payload: ExportCourseElements,
  closeNotification: () => void
): Promise<void> {
  try {
    await exportCancelProjectAll(projectData, payload);
    closeNotification();
  } catch (error) {
    closeNotification();
  }
}

export async function getExportOneFile(
  projectId: string,
  projectLearningObjectId: string,
  payload: ExportVideoParams,
  closeNotification: () => void,
  loadingOff: () => void,
  common: any,
  isCopyLink?: boolean
): Promise<void> {
  const response = await exportOneProject(projectId, projectLearningObjectId, payload);
  while (true) {
    try {
      if (response.export_id) {
        const exportStatus = await exportCheckStatus(response?.export_id.toString());
        if (exportStatus.state === ExportCourseResponse.state.DONE) {
          if (isCopyLink) {
            await navigator.clipboard.writeText(exportStatus?.final_path);
            // await closeNotification();
            await loadingOff();
            message.info({
              content: common.t('common.linkCopied'),
              icon: null,
              duration: 3,
            });
            break;
          } else {
            downloadByLink(exportStatus?.final_path);
            closeNotification();
            loadingOff();
            break;
          }
        }
        if (
          exportStatus.state === ExportCourseResponse.state.CANCELLED ||
          exportStatus.state === ExportCourseResponse.state.FAILED
        ) {
          message.destroy();
          loadingOff();
          break;
        }
      }
    } catch (error) {
      message.destroy();
      loadingOff();
      break;
    }
    // wait for 10 seconds before making the next request
    // eslint-disable-next-line @typescript-eslint/no-loop-func
    await new Promise((resolve) => setTimeout(resolve, 10000));
  }
}
