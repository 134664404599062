import React, { ChangeEvent, useState } from 'react';

import { cx } from 'utils';
import withCommonProps from 'hocs/withCommonProps';
import { TemplateCommonProps } from 'common/commonPropsProvider';

import TextField from 'components/textField';
import Select from 'components/select';
import Button from 'components/button/Button';
import { ButtonTypesEnum } from 'components/button/types';
import { TextFieldTypeEnum } from 'components/textField/types';

import { PresentationUpdateIssueRequestPayloadType } from '../../store/issues/types';

import './styles.scss';

export type EditIssueProps = {
  projectTaskIssueId: number;
  projectTaskIssueDescription: string;
  issueId: number;
  issuesTypesList: any[];
  issuesRefetch: any;
  onEditIssue: (issueId: number, payload: PresentationUpdateIssueRequestPayloadType, isVideo?: boolean) => void;
  onCancel: () => void;
  isVideo?: boolean;
};

const EditIssue = ({
  onCancel,
  common,
  projectTaskIssueId,
  projectTaskIssueDescription,
  onEditIssue,
  issueId,
  issuesRefetch,
  issuesTypesList,
  isVideo,
}: EditIssueProps & TemplateCommonProps) => {
  const [currentIssueId, setCurrentIssueId] = useState(projectTaskIssueId);
  const [description, setDescription] = useState(projectTaskIssueDescription);
  const onSubmit = async () => {
    const payload: PresentationUpdateIssueRequestPayloadType = {
      description: description,
      artefact_content_issue_id: currentIssueId,
    };
    await onEditIssue(issueId, payload, isVideo);
    await issuesRefetch();
    await onCancel();
  };
  const handleChangeIssue = (value: string, option: any) => {
    setCurrentIssueId(Number(option?.pk));
  };
  const handleChangeDescription = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };
  const classNames = cx('popup-edit-error-info');
  const defaultIssueType = issuesTypesList?.filter((issueEntity) => Number(issueEntity?.pk) === projectTaskIssueId);
  return (
    <div className={classNames}>
      <div className="content">
        <Select
          noForm
          fullWidth
          options={issuesTypesList}
          className="mb_14"
          placeholder={common.t<string>('form.field.errorType.placeholder')}
          defaultValue={defaultIssueType}
          onChange={handleChangeIssue}
        />
        <TextField
          noForm
          className="mb_14"
          placeholder={common.t<string>('form.field.description.placeholder')}
          autoSize={{ minRows: 2, maxRows: 6 }}
          type={TextFieldTypeEnum.textArea}
          value={description}
          onChange={handleChangeDescription}
        />
      </div>
      <div className="popup-set-error-info__footer">
        <Button onClick={onCancel} className="mr_8" type={ButtonTypesEnum.default}>
          {common.t<string>('common.cancel')}
        </Button>
        <Button
          onClick={onSubmit}
          type={ButtonTypesEnum.primary}
          disabled={description === projectTaskIssueDescription && projectTaskIssueId === currentIssueId}
        >
          {common.t<string>('common.saveIssue')}
        </Button>
      </div>
    </div>
  );
};

export default withCommonProps(EditIssue);
