import React, { useCallback, useEffect, useRef } from 'react';

import ReactFlow, {
  addEdge,
  Background,
  Controls,
  Edge,
  MarkerType,
  MiniMap,
  Node,
  Panel,
  updateEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import { Connection } from '@reactflow/core/dist/esm/types';

import Button from 'components/button/Button';
import { ButtonTypesEnum } from 'components/button/types';

import CustomNode from './customNode';
import { NodeData } from './customNode/CustomNode';

import './styles.scss';

const nodeTypes = {
  custom: CustomNode,
};

type Props = {
  dataNodes: Node<any>[];
  dataEdges: Edge<any>[];
  onSaveDraft: (nodes: Node<NodeData>[], edges: Edge<any>[]) => void;
};

const connectionLineStyle = { stroke: '#413aa9' };

const minimapStyle = {
  height: 120,
};

const PipelineFlow = ({ dataNodes, dataEdges, onSaveDraft }: Props) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const edgeUpdateSuccessful = useRef(true);

  useEffect(() => {
    setNodes(dataNodes);
    setEdges(dataEdges);
  }, [dataNodes, dataEdges]);

  const onConnect = useCallback(
    (params: Connection) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: 'smoothstep',
            markerEnd: { type: MarkerType.ArrowClosed },
          },
          eds
        )
      ),
    []
  );

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge: Edge<any>, newConnection: Connection) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  const onEdgeUpdateEnd = useCallback((_: any, edge: Edge<any>) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeUpdateSuccessful.current = true;
  }, []);

  const onClickSaveDraft = () => onSaveDraft(nodes, edges);

  return (
    <div className="ms-pipeline-flow">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onEdgeUpdate={onEdgeUpdate}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onEdgeUpdateEnd={onEdgeUpdateEnd}
        fitView
        connectionLineStyle={connectionLineStyle}
        nodeTypes={nodeTypes}
        //edgeTypes={edgeTypes}
      >
        <Controls />
        <Background color="#413aa9" gap={16} />
        <MiniMap style={minimapStyle} zoomable pannable />
        <Panel position="top-right">
          <Button type={ButtonTypesEnum.primary} onClick={onClickSaveDraft}>
            Save as a draft
          </Button>
        </Panel>
      </ReactFlow>
    </div>
  );
};

export default PipelineFlow;
