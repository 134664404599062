import React, { memo } from 'react';

import { SyncOutlined } from '@ant-design/icons';

import Button from 'components/button';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

type LoadMoreProps = {
  loading?: boolean;
  hasNextPage?: boolean;
  onFetch?: () => void;
};

function LoadMore({ loading, hasNextPage, onFetch }: LoadMoreProps) {
  const common = useCommonContext();
  if (!hasNextPage) {
    return null;
  }

  return (
    <div className="load-more">
      <Button size="large" disabled={loading} onClick={onFetch}>
        {common.t<string>('common.loadMore')}
        {loading ? <SyncOutlined spin /> : null}
      </Button>
    </div>
  );
}

export default memo(LoadMore);
