import React, { memo, useCallback } from 'react';

import { CopyOutlined } from '@ant-design/icons';
import { message } from 'antd';

import Tooltip from 'components/tooltip';
import Button from 'components/button';
import { ButtonTypesEnum } from 'components/button/types';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

type LearningOutcomesProps = {
  text?: string;
  title?: string;
  showButton?: boolean;
};

function LearningOutcomes({ text, showButton, title }: LearningOutcomesProps) {
  const common = useCommonContext();

  const onClipboard = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      navigator.clipboard.writeText(text || '');
      message.info({
        content: common.t<string>('common.textCopied'),
        icon: null,
      });
    },
    [common, text]
  );

  return (
    <div className="learning-outcomes">
      <div className="learning-outcomes__wrap">
        <span className="course-tree-item__content-title mr_4">{title}</span>
        {showButton && text ? (
          <Tooltip title={common.t<string>('common.copyText')}>
            <Button
              style={{ zIndex: 99 }}
              type={ButtonTypesEnum.text}
              icon={<CopyOutlined />}
              onClick={(event) => onClipboard(event)}
            />
          </Tooltip>
        ) : null}
      </div>

      <span className="course-tree-item__content-value">{text}</span>
    </div>
  );
}

export default memo(LearningOutcomes);
