import { UserPatch } from '../store/users/interfaces';
import { CompanyArtefactType } from '../types';

export const ENDPOINTS = {
  //AUTH
  LOGIN: 'token/',
  REFRESH_TOKEN: 'token/refresh/',
  SIGNUP: 'register/',
  INVITE: 'invite/',
  RESET_PASSWORD: 'password-reset/',
  USERS: (slugId: string) => `companies/${slugId}/users`,
  BUSINESS_ROLES: (slugId: string) => `companies/${slugId}/roles`,
  UPDATE_USER: ({ slugId, userId }: UserPatch) => `companies/${slugId}/${userId}/update`,
  ROLES: 'roles/',
  ME: 'me/',
  //PROJECTS
  PROJECT: 'project/',
  COMPANY_STATUSES: (companyId: number) => `/company_state/${companyId}/`,
  PROJECT_STATUS: (projectTaskId: number) => `/project_task/${projectTaskId}/state/`,
  ROLLBACK_STATUS: (projectTaskId: number) => `/project_task/${projectTaskId}/state/rollback/`,
  INSTRUCTIONAL_DESIGNER: (project_id: number) => `/dashboard/${project_id}/instructional_designer/`,
  COMPANY_USER_LIST: (project_id: number) => `/project/${project_id}/member/list/`,
  CHANGE_PROJECT: (project_id: number) => `/project/${project_id}/`,
  IMPORT_COURSE: `/learning_object/all/import/`,
  //ARTEFACT
  ARTEFACT: (id: string) => `artefact/${id}/`,
  LEARNING_OBJECT: (id: string) => `learning_object/${id}/`,
  FINAL_ARTEFACT_PIPELINE: (finalArtefactId: string) => `/final_artefact/${finalArtefactId}/pipeline/`,
  ITERATIONS: (learningObjectId: string | number, artefactId: string | number) =>
    `/learning_object/${learningObjectId}/artefact/${artefactId}/iteration`,
  ITERATIONS_ACTIVE: (learningObjectId: string | number, artefactId: string | number) =>
    `/learning_object/${learningObjectId}/artefact/${artefactId}/iteration/active`,
  ITERATION_BY_ID: (learningObjectId: string | number, artefactId: string | number, iterationId: string | number) =>
    `/learning_object/${learningObjectId}/artefact/${artefactId}/iteration/${iterationId}`,
  //FILES
  UPLOAD_IMAGE: `editor/image-upload/`,

  // CREATE_FILE: 'file/',
  CREATE_FILE: (fileType: CompanyArtefactType.type | string) => `file/${fileType}/`,
  UPLOAD_FILE: (fileId: number) => `file/${fileId}/part/`,
  DELETE_FILE: (fileType: string, fileId: number) => `file/${fileType}/${fileId}/`,
  UPDATE_FILE: (fileType: string, fileId: number) => `file/${fileType}/${fileId}/`,
  CREATE_ARTEFACT_VERSION: `artefact_version/`,
  UPDATE_ARTEFACT_VERSION: (artVerId: string) => `artefact_version/${artVerId}/`,
  UPLOAD_PRESENTATION_SLIDE: (fileId: number) => `file/${fileId}/presentation/slide/`,
  DELETE_PRESENTATION_SLIDE: (fileId: number, slideId: number) => `file/${fileId}/presentation/slide/${slideId}/`,
  UPDATE_PRESENTATION_SLIDE: (fileId: number, slideId: number) => `file/${fileId}/presentation/slide/${slideId}/`,
  UPDATE_PRESENTATION_SEQUENCE: (fileId: number) => `file/${fileId}/presentation/slide/sequence/`,
  FILE_STATE: (fileId: number) => `file/${fileId}/state/`,
  COPY_FILE: (fileId: number) => `file/${fileId}/duplicate/`,
  GET_FILE: (fileId: number) => `file/${fileId}/`,
  PUT_FILE_URL: (fileId: number) => `file/${fileId}/url/`,
  PUT_FILE_URL_NEW: (fileId: number) => `file/url/${fileId}/`,
  GET_ASYNC_FILE_STATUS: (fileId: number) => `async_task/state/${fileId}/`,
  PUT_CHECK_LIST_URL: (artefactFileVersionId: number, documentFileId: number) =>
    `/artefact_version/${artefactFileVersionId}/file/${documentFileId}/checklist/`,

  //FILE DOCUMENT
  UPDATE_DOCUMENT: (fileId: string) => `file/${fileId}/document/`,
  START_UPLOAD_FILE: (fileType: string) => `file/${fileType}/`,

  //ISSUES
  ADD_ISSUE: '/issue/presentation/',
  ADD_ISSUE_VIDEO: '/issue/video/',
  MODIFY_ISSUE: (issueId: number) => `/issue/${issueId}/presentation/`,
  MODIFY_ISSUE_VIDEO: (issueId: number) => `/issue/${issueId}/video/`,
  ADD_COMMENT: (stateId: number) => `/project_task/${stateId}/state/message/`,

  ADD_ISSUE_DOCUMENT: '/issue/document/',
  UPDATE_ISSUE_DOCUMENT: (issueId: string) => `/issue/${issueId}/document/`,

  //PROJECT
  PROJECT_LO_UPDATE_ALL: (projectId: number) => `/project/${projectId}/learning_object/all/`,
  PROJECT_LO_UPDATE_ART_PROD_SEQUENCE: (projectId: number) =>
    `/project/${projectId}/learning_object/artefact_production_sequence/`,

  //COMPANY
  CREATE_COMPANY: '/company/',
  ADD_USER_TO_COMPANY: (companyId: string) => `/company/${companyId}/user/`,
  UPDATE_USER_TO_COMPANY: (companyId: string, userId: string) => `/company/${companyId}/user/${userId}/`,

  //USER
  UPDATE_USER_STATE: (usersId: string, state: 'INVITED' | 'BLOCKED') => `/user/${usersId}/state/${state}/`,

  //PROJECT TASK
  PROJECT_TASK_INIT: '/project_task/init/',
  PROJECT_TASK_DESCRIPTION: (projectTaskId: string) => `/separate_task/${projectTaskId}/description/`,
  ADD_COMMENT_TO_DISCUSSION: (taskId: string) => `/project_task/${taskId}/state/message/`,
  UPDATE_COMMENT_TO_DISCUSSION: (messageId: string) => `/project_task/state/message/${messageId}/`,
  CREATE_GLOBAL_TASK: `/project_task/`,
  PROJECT_TASK_ASSIGNEE: (projectTaskId: string) => `/project_task/${projectTaskId}/assignee/`,
  CREATE_SEPARATE_TASK: (projectTaskId: string) => `/project_task/${projectTaskId}/artefact/separate_task/`,
  UPDATE_SEPARATE_TASK: (projectTaskId: string) => `/project_task/${projectTaskId}/`,
  PROJECT_TASK_STATE_UPDATE: (projectTaskId: string) => `/project_task/${projectTaskId}/state/`,
  PROJECT_TASK_CREATE_MESSAGE: (projectTaskId: string) => `/project_task/${projectTaskId}/state/message/`,
  PROJECT_TASK_UPDATE_MESSAGE: (messageId: string) => `/project_task/state/message/${messageId}/`,

  //EXPORT ALL
  EXPORT_ALL_PROJECT_LEARNING_OBJECT: (projectId: string) => `/project/${projectId}/export/project_learning_object/`,
  EXPORT_ONE_PROJECT_LEARNING_OBJECT: (projectId: string, projectLearningObjectId: string) =>
    `/project/${projectId}/export/project_learning_object/${projectLearningObjectId}/`,
  CHANGE_ROLLBACK: (taskId: string) => `/project_task/${taskId}/opened/`,
  CHECK_EXPORT_PREPARING_STATUS: (exportId: string) => `project/export-state/${exportId}/`,

  //PM
  COMPANY_STATE: `/company_state/`,
  COMPANY_STATE_VER: (listId: string) => `/company_state_list/${listId}/version/`,
  COMPANY_STATE_VER_STATE: (verId: string) => `/company_state_list_version/${verId}/state/`,
  COMPANY_STATE_BY_ID: (id: string) => `/company_state/${id}/`,
  COMPANY_STATE_PUBLISH: `/company_state/publish/`,
  ART_FLOW_STEP: `/artefact_flow_step/`,
  ART_TYPE_FLOW_STEP: (artTypeId: string) => `/artefact_type/${artTypeId}/artefact_flow_step/steps/`,

  COMPANY_ARTEFACT_TYPE: (companyId: string) => `/company/${companyId}/artefact_type/`,
  COMPANY_LO_TYPE: (companyId: string) => `/company/${companyId}/learning_object_type/`,
  ARTEFACT_PIPELINE: `/artefact/pipeline/`,
  ARTEFACT_PIPELINE_UPDATE: (id: string) => `/artefact/pipeline/${id}/`,
  ADD_ARTEFACT_PIPELINE_VER: (id: string) => `/artefact_type/${id}/pipeline/version/`,
  ARTEFACT_PIPELINE_VER_PUBLISH: (verId: string) => `/artefact_pipeline_version/${verId}/publish/`,

  ARTEFACT_FLOW_STEP_SEQUENCE_RULE: `/artefact_flow_step_sequence_rule/`,
  ARTEFACT_FLOW_STEP_SEQUENCE_RULE_ID: (id: string) => `/artefact_flow_step_sequence_rule/${id}/`,
};

export enum ApiModules {
  ACCOUNT = 'account',
  PM = 'pm',
  ARTEFACT_MANAGEMENT = 'artefact-management',
  ARTEFACT_AUDIT = 'artefact-audit',
}
