import { gql } from '@apollo/client';

export const GET_PROJECT_STATES_LIST = gql`
  query Project_states($projectId: Int) {
    project_states(project_id: $projectId) {
      id
      name
      state_type
      created_at
      updated_at
      icon_image
      order
      is_require_discuss_message
    }
    project_issues_states(project_id: $projectId) {
      id
      name
      state_type
      created_at
      updated_at
      icon_image
      order
      is_require_discuss_message
    }
    project_discussion_states(project_id: $projectId) {
      id
      name
      state_type
      created_at
      updated_at
      icon_image
      order
      is_require_discuss_message
    }
    object_states {
      id
      state_type
      name
    }
    project_separate_task_states(project_id: $projectId) {
      id
      name
      state_type
    }
  }
`;
