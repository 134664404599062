import React, { memo } from 'react';

import { DefaultOptionType } from 'rc-select/lib/Select';

import Select from 'components/select/Select';

import './styles.scss';

type ChooseExportVideoProps = {
  disabled?: boolean;
  options?: DefaultOptionType[];
  value?: DefaultOptionType;
  onChange?: (value: string, option: any) => void;
};

function ChooseExportVideo({ disabled, options, value, onChange }: ChooseExportVideoProps) {
  return (
    <div className="choose-export-video">
      <span className="choose-export-video__format">MP4</span>
      <Select
        disabled={disabled}
        noForm
        size="large"
        options={options}
        value={value}
        onChange={onChange}
        getPopupContainer={(triggerNode) => triggerNode}
      />
    </div>
  );
}

export default memo(ChooseExportVideo);
