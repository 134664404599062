import React from 'react';

import Checkbox from '../../../components/checkbox';

const CheckboxStories = () => {
  return (
    <div className="mb_8">
      <Checkbox />
      <Checkbox checked />
      <Checkbox disabled />
      <Checkbox checked disabled />
      <Checkbox indeterminate />
    </div>
  );
};

export default CheckboxStories;
