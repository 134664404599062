import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { GET_PROJECT_USERS } from 'queries/getProjectUsers';
import { UserBase, UserRoles } from 'types/entities/user';
import { projectUsersToSelect, splitProjectUsersByRole } from 'utils';

import { Company } from '../../types/entities';

export type ProjectUserType = {
  id: string;
  project: {
    id: string;
    name: string;
  };
  company_user: {
    id: string;
    company: Company;
    user: UserBase;
  };
  roles: UserRoles[];
  is_external_company: boolean;
  is_active: boolean;
  tasks_amount: number;
  is_separate_project_task_assignee: boolean;
};

export type ProjectUsersData = {
  project_users?: ProjectUserType[];
};

export interface ProjectUserSelect extends ProjectUserType {
  label: string;
  value: string;
}

function useProjectUsers(
  projectId?: string,
  fetchPolicy?: WatchQueryFetchPolicy,
  loadCallback?: (data: ProjectUsersData) => void
) {
  const { data, loading, refetch } = useQuery<ProjectUsersData>(GET_PROJECT_USERS, {
    variables: {
      project_id: Number(projectId),
    },
    skip: !projectId,
    fetchPolicy: fetchPolicy || 'network-only',
    onCompleted: loadCallback,
  });

  const { authors, executors, managers, instructionalDesigners } = splitProjectUsersByRole(data?.project_users);

  return useMemo(() => {
    return {
      projectUsers: data?.project_users,
      projectUsersForSelect: projectUsersToSelect(data?.project_users),
      projectAuthorsToSelect: projectUsersToSelect(authors),
      projectExecutorsToSelect: projectUsersToSelect(executors),
      projectManagersToSelect: projectUsersToSelect(managers),
      projectInstructionalDesignersToSelect: projectUsersToSelect(instructionalDesigners),
      projectUserRefetch: refetch,
      projectUserLoading: loading,
    };
  }, [authors, data?.project_users, executors, instructionalDesigners, loading, managers, refetch]);
}

export default useProjectUsers;
