import React, { memo } from 'react';

import { Result as AResult } from 'antd';
import { ResultProps } from 'antd/lib/result';

function Result({ status, title, subTitle, extra, children }: ResultProps) {
  return (
    <AResult className="ccm-result" status={status} title={title} subTitle={subTitle} extra={extra}>
      {children}
    </AResult>
  );
}

export default memo(Result);
