import { gql } from '@apollo/client';

export const GET_DOCUMENT_DISCUSSIONS = gql`
  query DocumentDiscussions($artefactId: Int) {
    document_discussions(artefact_id: $artefactId) {
      totalCount
      edges {
        node {
          pk
          id
          creator {
            id
            name
            email
            state
          }
          created_at
          updated_at
          artefact_version_creation {
            id
            number
          }
          project_task {
            id
            description
            last_state {
              id
              state_type
            }
          }
          external_id
          document_file_section {
            id
            external_id
            document_file_version {
              id
            }
          }
          discussion_comments {
            id
            message
            project_user {
              company_user {
                user {
                  id
                  name
                  email
                  state
                }
              }
            }
            creator {
              id
              name
            }
            updated_at
          }
          artefact {
            id
          }
          artefact_version_solution {
            id
          }
          changed_by {
            id
          }
          is_active
        }
      }
    }
  }
`;
