import React, { ChangeEvent, RefObject } from 'react';

import { ApolloQueryResult } from '@apollo/client/core/types';

import { ArtefactFile, ArtefactFileIteration, ArtefactListItem, TaskInfoQueryData } from '../../task/types';
import { PresentationIssueRequestPayloadType } from '../../../store/issues/types';
import { BusinessRoleEnum } from '../../../types/entities/user';
import { StateType } from '../../../types/entities';

export type Coordinates = {
  x: number;
  y: number;
};

export type ControlStateType = {
  current: number;
  display: CarouselDisplayEnum;
  coordinates: Coordinates | null;
  isOpen: boolean;
  currentSlideId: number;
};

export type ErrorStateType = {
  issue: any;
  description: string;
};

export enum CarouselDisplayEnum {
  single = 'single',
  grid = 'grid',
}

export type CarouselMainBlockProps = {
  className?: string;
  userRole: BusinessRoleEnum | null;
  onSelectedIssue: (issue: any) => void;
  onAddIssue: (payload: PresentationIssueRequestPayloadType) => void;
  selectedIssue: any;
  // data: any;
  issuesRefetch: any;
  issuesTypesList: any;
  files: ArtefactFile | null;
  currentArtefact: ArtefactListItem | undefined;
  refetch(): Promise<ApolloQueryResult<TaskInfoQueryData>>;
  dataList?: any[];
  carouselRef: any;
  artefactFileVersion: ArtefactFileIteration | null;
  currentTaskState: StateType | null;
  carouselState: ControlStateType;
  setCarouselState: any;
  isCurrentExecutor: boolean;
};

export type CarouselControlProps = {
  className?: string;
  userRole: BusinessRoleEnum | null;
  controlState: ControlStateType;
  dataLength: number;
  onClickDisplay: (v: CarouselDisplayEnum) => void;
  onChangeCurrent: (value: any) => void;
  onClickPrev: () => void;
  onClickNext: () => void;
  onDeleteAllSlides: () => void;
};

export type PopupSetErrorInfoProps = {
  containerRef: RefObject<any>;
  isVisible: boolean;
  style?: React.CSSProperties;
  values: {
    issue: any;
    description: string;
  };
  onClose: () => void;
  onChangeSelect: (value: string, option: any) => void;
  onChangeTextField: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onSubmit: () => void;
  issuesTypesList: any;
};
