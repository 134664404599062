import i18n from 'i18next';
import moment from 'moment';
import momentjs from 'moment-timezone';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import jwtDecode from 'jwt-decode';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { localStorageService } from '../services/localStorageService';
import { IAuthData } from '../store/auth/interfaces';

import { declOfNum, declTitleDay, declTitleMonth, declTitleTask, declTitleWeek, declTitleYear } from './declination';
dayjs.extend(duration);

export const isExpiredToken = (expired?: number): boolean => {
  if (expired) {
    const now = Math.round(Date.now() / 1000);
    const delayMs = expired - now;
    return !Boolean(delayMs <= 0);
  }
  return false;
};
export const getAccessExpToken = () => {
  const authData = localStorageService.getAuthData();
  if (authData?.access) {
    const decode = jwtDecode<IAuthData>(authData?.access);
    return isExpiredToken(decode?.exp);
  } else return false;
};

function pad(value: number) {
  return ('0' + value).slice(-2);
}

export const getFormatDateByStr = (date?: string, format = 'DD MMM', empty?: string) => {
  if (!date) {
    return empty !== undefined ? empty : '';
  }

  return moment(date).locale(i18n.language).format(format).replace('.', '');
};

export const getFormattedStartEndByStr = (start?: string, end?: string, format = 'D MMM YYYY') => {
  const startDate = moment(start).locale(i18n.language).format(format).replace('.', '');
  const endDate = moment(end).locale(i18n.language).format(format).replace('.', '');

  if (!start && !end) {
    return '-';
  }

  if (start && !end) {
    return startDate;
  }
  return `${startDate} - ${endDate}`;
};

export const getFormattedEndByStr = (end?: string, format = 'D MMM YYYY') => {
  if (!end) {
    return '-';
  }
  const endDate = moment(end).locale(i18n.language).format(format).replace('.', '');
  return `${endDate}`;
};

export const durationToHoursMinSeconds = (seconds: number) => {
  const date: Date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds();
  return { hh, mm, ss };
};

export function formatDuration(seconds: number) {
  const { hh, mm, ss } = durationToHoursMinSeconds(seconds);
  if (hh) {
    return `${hh}:${pad(mm)}:${pad(ss)}`;
  }
  return `${mm}:${pad(ss)}`;
}

export const dayjsToDuration = (value: Dayjs | undefined | null): number | null => {
  if (!value) {
    return null;
  }
  const dateString = dayjs(value).format('HH:mm:ss');
  console.log(dayjs(value).second());
  // TODO dayjs convert to second
  return moment.duration(dateString).asSeconds();
};

export const durationToString = (d: number | null) => {
  if (!d) {
    return '';
  }

  return moment.utc(d * 1000).format('HH:mm:ss');
};

type MonthRangeOutput = {
  monthData: string[];
  monthYearData: string[];
};

export const getMonthRange = (start?: string, end?: string): MonthRangeOutput | null => {
  if (!start || !end) {
    return null;
  }

  const fromDate = moment(start, 'YYYY-MM-DD').locale(i18n.language);
  const toDate = moment(end, 'YYYY-MM-DD').locale(i18n.language);
  const monthData = [];
  const monthYearData = [];
  while (toDate > fromDate || fromDate.format('M') === toDate.format('M')) {
    monthData.push(fromDate.format('MMMM'));
    monthYearData.push(fromDate.format('YYYY-MM'));
    fromDate.add(1, 'month');
  }
  return {
    monthData,
    monthYearData,
  };
};

export function getCurrentTime(timezone: string) {
  const date = momentjs().tz(timezone);
  return date.format('HH:mm');
}

export function getCurrentTimeOffset(timezone: string) {
  return momentjs().tz(timezone).format('Z');
}

export function deleteCommaOnTheEnd(string: string) {
  if (string.slice(-1) === '.') {
    return string.slice(0, -1);
  }
}

export const getLeftTime = (dateEnd?: string) => {
  const today = moment();
  const diff = moment(dateEnd).diff(today, 'days');

  if (diff === 0) {
    return `${i18n.t('common.today')}`;
  }

  if (diff <= 7 && diff >= 0) {
    return `${i18n.t('pages.courses.drawer.finalDeadline')} ${diff} ${i18n.t(declOfNum(diff, declTitleDay))} ${i18n.t(
      'common.away'
    )}`;
  }
  if (diff > 7 && diff < 31) {
    const value = Math.round(diff / 7);
    return `${i18n.t('pages.courses.drawer.finalDeadline')} ${value} ${i18n.t(
      declOfNum(value, declTitleWeek)
    )} ${i18n.t('common.away')}`;
  }
  if (diff > 30 && diff < 365) {
    const value = Math.round(diff / 30);
    return `${i18n.t('pages.courses.drawer.finalDeadline')} ${value}  ${i18n.t(
      declOfNum(value, declTitleMonth)
    )} ${i18n.t('common.away')}`;
  }

  if (diff > 365) {
    const value = Math.round(diff / 365);
    return `${i18n.t('pages.courses.drawer.finalDeadline')} ${value} ${i18n.t(
      declOfNum(value, declTitleYear)
    )} ${i18n.t('common.away')}`;
  }
  return `${Math.abs(diff)} ${i18n.t(declOfNum(diff, declTitleDay))} ${i18n.t('common.ofDelay')}`;
};
