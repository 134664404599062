import React, { FunctionComponent } from 'react';

import Button from '../../../components/button/Button';
import { ButtonTypesEnum } from '../../../components/button/types';

interface OwnProps {}

type Props = OwnProps;

const ButtonsStories: FunctionComponent<Props> = (props) => {
  return (
    <div>
      <div className="mb_8">
        <Button size="xl" className="mr_8" type={ButtonTypesEnum.primary}>
          Button title
        </Button>
        <Button size="xl" className="mr_8" type={ButtonTypesEnum.default}>
          Button title
        </Button>
        <Button size="xl" className="mr_8" type={ButtonTypesEnum.text}>
          Button title
        </Button>
        <Button size="xl" className="mr_8" type={ButtonTypesEnum.link}>
          Button title
        </Button>
      </div>
      <div className="mb_8">
        <Button hintText="Button title" size="xl" disabled className="mr_8" type={ButtonTypesEnum.primary}>
          Button title
        </Button>
        <Button hintText="Button title" size="xl" disabled className="mr_8" type={ButtonTypesEnum.default}>
          Button title
        </Button>
        <Button hintText="Button title" size="xl" disabled className="mr_8" type={ButtonTypesEnum.text}>
          Button title
        </Button>
        <Button hintText="Button title" size="xl" disabled className="mr_8" type={ButtonTypesEnum.link}>
          Button title
        </Button>
      </div>
      <div className="mb_8">
        <Button size="large" className="mr_8" type={ButtonTypesEnum.primary}>
          Button title
        </Button>
        <Button size="large" className="mr_8" type={ButtonTypesEnum.default}>
          Button title
        </Button>
        <Button size="large" className="mr_8" type={ButtonTypesEnum.text}>
          Button title
        </Button>
        <Button size="large" className="mr_8" type={ButtonTypesEnum.link}>
          Button title
        </Button>
      </div>
      <div className="mb_8">
        <Button size="large" disabled className="mr_8" type={ButtonTypesEnum.primary}>
          Button title
        </Button>
        <Button size="large" disabled className="mr_8" type={ButtonTypesEnum.default}>
          Button title
        </Button>
        <Button size="large" disabled className="mr_8" type={ButtonTypesEnum.text}>
          Button title
        </Button>
        <Button size="large" disabled className="mr_8" type={ButtonTypesEnum.link}>
          Button title
        </Button>
      </div>
      <div className="mb_8">
        <Button size="middle" className="mr_8" type={ButtonTypesEnum.primary}>
          Button title
        </Button>
        <Button size="middle" className="mr_8" type={ButtonTypesEnum.default}>
          Button title
        </Button>
        <Button size="middle" className="mr_8" type={ButtonTypesEnum.text}>
          Button title
        </Button>
        <Button size="middle" className="mr_8" type={ButtonTypesEnum.link}>
          Button title
        </Button>
      </div>
      <div className="mb_8">
        <Button size="middle" disabled className="mr_8" type={ButtonTypesEnum.primary}>
          Button title
        </Button>
        <Button size="middle" disabled className="mr_8" type={ButtonTypesEnum.default}>
          Button title
        </Button>
        <Button size="middle" disabled className="mr_8" type={ButtonTypesEnum.text}>
          Button title
        </Button>
        <Button size="middle" disabled className="mr_8" type={ButtonTypesEnum.link}>
          Button title
        </Button>
      </div>
      <div className="mb_8">
        <Button size="small" className="mr_8" type={ButtonTypesEnum.primary}>
          Button title
        </Button>
        <Button size="small" className="mr_8" type={ButtonTypesEnum.default}>
          Button title
        </Button>
        <Button size="small" className="mr_8" type={ButtonTypesEnum.text}>
          Button title
        </Button>
        <Button size="small" className="mr_8" type={ButtonTypesEnum.link}>
          Button title
        </Button>
      </div>
      <div className="mb_16">
        <Button size="small" disabled className="mr_8" type={ButtonTypesEnum.primary}>
          Button title
        </Button>
        <Button size="small" disabled className="mr_8" type={ButtonTypesEnum.default}>
          Button title
        </Button>
        <Button size="small" disabled className="mr_8" type={ButtonTypesEnum.text}>
          Button title
        </Button>
        <Button size="small" disabled className="mr_8" type={ButtonTypesEnum.link}>
          Button title
        </Button>
      </div>
      <div className="mb_8">
        <Button danger size="xl" className="mr_8" type={ButtonTypesEnum.primary}>
          Button title
        </Button>
        <Button danger size="xl" className="mr_8" type={ButtonTypesEnum.default}>
          Button title
        </Button>
        <Button danger size="xl" className="mr_8" type={ButtonTypesEnum.text}>
          Button title
        </Button>
        <Button danger size="xl" className="mr_8" type={ButtonTypesEnum.link}>
          Button title
        </Button>
      </div>
      <div className="mb_8">
        <Button danger size="xl" disabled className="mr_8" type={ButtonTypesEnum.primary}>
          Button title
        </Button>
        <Button danger size="xl" disabled className="mr_8" type={ButtonTypesEnum.default}>
          Button title
        </Button>
        <Button danger size="xl" disabled className="mr_8" type={ButtonTypesEnum.text}>
          Button title
        </Button>
        <Button danger size="xl" disabled className="mr_8" type={ButtonTypesEnum.link}>
          Button title
        </Button>
      </div>
      <div className="mb_8">
        <Button danger size="large" className="mr_8" type={ButtonTypesEnum.primary}>
          Button title
        </Button>
        <Button danger size="large" className="mr_8" type={ButtonTypesEnum.default}>
          Button title
        </Button>
        <Button danger size="large" className="mr_8" type={ButtonTypesEnum.text}>
          Button title
        </Button>
        <Button danger size="large" className="mr_8" type={ButtonTypesEnum.link}>
          Button title
        </Button>
      </div>
      <div className="mb_8">
        <Button danger size="large" disabled className="mr_8" type={ButtonTypesEnum.primary}>
          Button title
        </Button>
        <Button danger size="large" disabled className="mr_8" type={ButtonTypesEnum.default}>
          Button title
        </Button>
        <Button danger size="large" disabled className="mr_8" type={ButtonTypesEnum.text}>
          Button title
        </Button>
        <Button danger size="large" disabled className="mr_8" type={ButtonTypesEnum.link}>
          Button title
        </Button>
      </div>
      <div className="mb_8">
        <Button danger size="middle" className="mr_8" type={ButtonTypesEnum.primary}>
          Button title
        </Button>
        <Button danger size="middle" className="mr_8" type={ButtonTypesEnum.default}>
          Button title
        </Button>
        <Button danger size="middle" className="mr_8" type={ButtonTypesEnum.text}>
          Button title
        </Button>
        <Button danger size="middle" className="mr_8" type={ButtonTypesEnum.link}>
          Button title
        </Button>
      </div>
      <div className="mb_8">
        <Button danger size="middle" disabled className="mr_8" type={ButtonTypesEnum.primary}>
          Button title
        </Button>
        <Button danger size="middle" disabled className="mr_8" type={ButtonTypesEnum.default}>
          Button title
        </Button>
        <Button danger size="middle" disabled className="mr_8" type={ButtonTypesEnum.text}>
          Button title
        </Button>
        <Button danger size="middle" disabled className="mr_8" type={ButtonTypesEnum.link}>
          Button title
        </Button>
      </div>
      <div className="mb_8">
        <Button danger size="small" className="mr_8" type={ButtonTypesEnum.primary}>
          Button title
        </Button>
        <Button danger size="small" className="mr_8" type={ButtonTypesEnum.default}>
          Button title
        </Button>
        <Button danger size="small" className="mr_8" type={ButtonTypesEnum.text}>
          Button title
        </Button>
        <Button danger size="small" className="mr_8" type={ButtonTypesEnum.link}>
          Button title
        </Button>
      </div>
      <div className="mb_8">
        <Button danger size="small" disabled className="mr_8" type={ButtonTypesEnum.primary}>
          Button title
        </Button>
        <Button danger size="small" disabled className="mr_8" type={ButtonTypesEnum.default}>
          Button title
        </Button>
        <Button danger size="small" disabled className="mr_8" type={ButtonTypesEnum.text}>
          Button title
        </Button>
        <Button danger size="small" disabled className="mr_8" type={ButtonTypesEnum.link}>
          Button title
        </Button>
      </div>
    </div>
  );
};

export default ButtonsStories;
