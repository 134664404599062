/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CompanyArtefactType = {
    name: string;
    type: CompanyArtefactType.type;
    readonly icon_image?: string;
};

export namespace CompanyArtefactType {

    export enum type {
        DRAFT_PRESENTATION = 'draft_presentation',
        PRODUCTION_PRESENTATION = 'production_presentation',
        TEXT = 'text',
        GRADER = 'grader',
        PEER_REVIEW = 'peer_review',
        QUIZ = 'quiz',
        SCRIPT = 'script',
        ORIGINAL_VIDEO = 'original_video',
        DRAFT_VIDEO = 'draft_video',
        SEPARATE_TASK = 'separate_task',
    }


}
