import React, { memo } from 'react';

import { cx } from 'utils';
import logoCF from 'assets/image/logoCF-black.svg';
import logoCCM from 'assets/image/ccm-black-logo.svg';
import constructor from 'assets/image/all_constructor_logo.svg';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

const logo = process.env.REACT_APP_CCM_LANG === 'en' ? logoCF : logoCCM;
const appCompany = process.env.REACT_APP_CCM_COMPANY;

type IProps = {
  className?: string;
  children: JSX.Element;
};

function AuthCard({ className, children }: IProps) {
  const common = useCommonContext();
  const classNames = cx('card-auth', className);
  return (
    <div className={classNames}>
      <div className="card-auth__header">
        {appCompany === 'constr' ? (
          <div className="card-auth__logo-wrap mr_10">
            <img src={constructor} alt="logo" />
          </div>
        ) : (
          <>
            <img className="header-layout__logo mr_10" src={logo} alt="logo" />
            <span className="card-auth__header-text">{common.t<string>('common.appName')}</span>
          </>
        )}
      </div>
      <div className="card-auth__content">{children}</div>
    </div>
  );
}

export default memo(AuthCard);
