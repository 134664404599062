import { useCallback, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { PageInfo } from 'schemas/types';
import {
  GET_DASHBOARD_TASKS,
  GET_DASHBOARD_TASKS_DEADLINES_IN_TWO_DAYS,
  GET_DASHBOARD_TASKS_DEADLINES_MISSING,
  GET_DASHBOARD_TASKS_FOR_TODAY,
  GET_DASHBOARD_TASKS_MORE_THAN_THREE_ITERATION,
  GET_DASHBOARD_TASKS_WITH_NEW_COMMENTS,
} from 'queries/getDashboardTasks';

import { DashboardTaskItem, DashboardTasksWidget } from '../types';
import useCommonContext from '../../../hooks/useCommonContext';
import { buildAbilityFor } from '../../../config/ability';
import { convertRoles } from '../../../utils';
import useBoolean from '../../../hooks/useBoolean';

type TasksWidgetData = {
  front_page_tasks: DashboardTasksWidget;
};

type TaskDeadlinesMissingData = {
  front_page_tasks_deadlines_missing: {
    totalCount: number;
    pageInfo: PageInfo;
    edges: {
      cursor: string;
      node: DashboardTaskItem;
    }[];
  };
};

type TaskMoreThanThreeIterationData = {
  front_page_tasks_more_than_three_iterations: {
    totalCount: number;
    pageInfo: PageInfo;
    edges: {
      cursor: string;
      node: DashboardTaskItem;
    }[];
  };
};

type TaskMoreDeadlinesInTwoDaysData = {
  front_page_tasks_deadlines_in_two_days: {
    totalCount: number;
    pageInfo: PageInfo;
    edges: {
      cursor: string;
      node: DashboardTaskItem;
    }[];
  };
};

type TaskMoreForTodayData = {
  front_page_tasks_for_today: {
    totalCount: number;
    pageInfo: PageInfo;
    edges: {
      cursor: string;
      node: DashboardTaskItem;
    }[];
  };
};

type TaskMoreWithNewCommentsData = {
  front_page_tasks_with_new_comments: {
    totalCount: number;
    pageInfo: PageInfo;
    edges: {
      cursor: string;
      node: DashboardTaskItem;
    }[];
  };
};

const LIMIT_PAGE_ITEM = 10;

function useTasksData() {
  const [readyDeadlines, onReadyDeadlines] = useBoolean(false);
  const [readyIteration, onReadyIteration] = useBoolean(false);
  const [readyTwoDays, onReadyTwoDays] = useBoolean(false);
  const [readyComments, onReadyComments] = useBoolean(false);
  const [readyToday, onReadyToday] = useBoolean(false);
  const common = useCommonContext();

  const ability = buildAbilityFor(convertRoles(common.businessRoles));

  const skipTasks = !ability.can('view', 'Dashboard.tasks');
  const skipDeadlines = !ability.can('view', 'Dashboard.tasks.deadlines');
  const skipIteration = !ability.can('view', 'Dashboard.tasks.iteration');
  const skipTwoDays = !ability.can('view', 'Dashboard.tasks.twoDays');
  const skipComments = !ability.can('view', 'Dashboard.tasks.comments');
  const skipToday = !ability.can('view', 'Dashboard.tasks.today');

  const onCompletedDeadlines = useCallback(() => onReadyDeadlines.on(), [onReadyDeadlines]);
  const onCompletedIteration = useCallback(() => onReadyIteration.on(), [onReadyIteration]);
  const onCompletedTwoDays = useCallback(() => onReadyTwoDays.on(), [onReadyTwoDays]);
  const onCompletedComments = useCallback(() => onReadyComments.on(), [onReadyComments]);
  const onCompletedToday = useCallback(() => onReadyToday.on(), [onReadyToday]);

  useEffect(() => {
    if (skipTasks && common.businessRoles) {
      onCompletedDeadlines();
      onCompletedIteration();
      onCompletedTwoDays();
      onCompletedComments();
      onCompletedToday();
    }
    if (skipDeadlines && common.businessRoles) {
      onCompletedDeadlines();
    }
    if (skipIteration && common.businessRoles) {
      onCompletedIteration();
    }
    if (skipTwoDays && common.businessRoles) {
      onCompletedTwoDays();
    }
    if (skipComments && common.businessRoles) {
      onCompletedComments();
    }
    if (skipToday && common.businessRoles) {
      onCompletedToday();
    }
  }, [
    common.businessRoles,
    onCompletedComments,
    onCompletedDeadlines,
    onCompletedIteration,
    onCompletedToday,
    onCompletedTwoDays,
    skipComments,
    skipDeadlines,
    skipIteration,
    skipTasks,
    skipToday,
    skipTwoDays,
  ]);

  //Tasks widget
  const tasksWidgetData = useQuery<TasksWidgetData>(GET_DASHBOARD_TASKS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: skipTasks,
  });

  //Tasks deadlines missing
  const tasksDeadlinesMissingData = useQuery<TaskDeadlinesMissingData>(GET_DASHBOARD_TASKS_DEADLINES_MISSING, {
    variables: {
      offset: 0,
      first: LIMIT_PAGE_ITEM,
    },
    notifyOnNetworkStatusChange: true,
    skip: skipTasks || skipDeadlines,
    onCompleted: onCompletedDeadlines,
    fetchPolicy: 'network-only',
  });

  const onLoadMoreTaskDeadlinesMissing = () =>
    tasksDeadlinesMissingData.fetchMore({
      variables: {
        after: tasksDeadlinesMissingData.data?.front_page_tasks_deadlines_missing.pageInfo.endCursor,
        first: LIMIT_PAGE_ITEM,
      },
    });

  //Tasks more than three iteration
  const tasksMoreThanThreeIterationData = useQuery<TaskMoreThanThreeIterationData>(
    GET_DASHBOARD_TASKS_MORE_THAN_THREE_ITERATION,
    {
      variables: {
        offset: 0,
        first: LIMIT_PAGE_ITEM,
      },
      notifyOnNetworkStatusChange: true,
      skip: skipTasks || skipIteration,
      onCompleted: onCompletedIteration,
      fetchPolicy: 'network-only',
    }
  );

  const onLoadMoreMoreThanThreeIteration = () =>
    tasksMoreThanThreeIterationData.fetchMore({
      variables: {
        after: tasksMoreThanThreeIterationData.data?.front_page_tasks_more_than_three_iterations.pageInfo.endCursor,
        first: LIMIT_PAGE_ITEM,
      },
    });

  //Tasks deadlines in two days
  const tasksDeadlinesInTwoDaysData = useQuery<TaskMoreDeadlinesInTwoDaysData>(
    GET_DASHBOARD_TASKS_DEADLINES_IN_TWO_DAYS,
    {
      variables: {
        offset: 0,
        first: LIMIT_PAGE_ITEM,
      },
      notifyOnNetworkStatusChange: true,
      skip: skipTasks || skipTwoDays,
      onCompleted: onCompletedTwoDays,
      fetchPolicy: 'network-only',
    }
  );

  const onLoadMoreDeadlinesInTwoDays = () =>
    tasksDeadlinesInTwoDaysData.fetchMore({
      variables: {
        after: tasksDeadlinesInTwoDaysData.data?.front_page_tasks_deadlines_in_two_days.pageInfo.endCursor,
        first: LIMIT_PAGE_ITEM,
      },
    });

  //Tasks for today
  const tasksForTodayData = useQuery<TaskMoreForTodayData>(GET_DASHBOARD_TASKS_FOR_TODAY, {
    variables: {
      offset: 0,
      first: LIMIT_PAGE_ITEM,
    },
    notifyOnNetworkStatusChange: true,
    skip: skipTasks || skipToday,
    onCompleted: onCompletedToday,
    fetchPolicy: 'network-only',
  });

  const onLoadMoreForTodayDays = () =>
    tasksForTodayData.fetchMore({
      variables: {
        after: tasksForTodayData.data?.front_page_tasks_for_today.pageInfo.endCursor,
        first: LIMIT_PAGE_ITEM,
      },
    });

  //Tasks with new comments
  const tasksWithNewCommentsData = useQuery<TaskMoreWithNewCommentsData>(GET_DASHBOARD_TASKS_WITH_NEW_COMMENTS, {
    variables: {
      offset: 0,
      first: LIMIT_PAGE_ITEM,
    },
    notifyOnNetworkStatusChange: true,
    skip: skipTasks || skipComments,
    onCompleted: onCompletedComments,
    fetchPolicy: 'network-only',
  });

  const onLoadMoreWithNewComments = () =>
    tasksForTodayData.fetchMore({
      variables: {
        after: tasksWithNewCommentsData.data?.front_page_tasks_with_new_comments.pageInfo.endCursor,
        first: LIMIT_PAGE_ITEM,
      },
    });

  return {
    tasksReady: readyDeadlines && readyIteration && readyTwoDays && readyComments && readyToday,
    tasksLoading:
      tasksDeadlinesMissingData.loading ||
      tasksMoreThanThreeIterationData.loading ||
      tasksDeadlinesInTwoDaysData.loading ||
      tasksForTodayData.loading ||
      tasksWithNewCommentsData.loading,
    tasksWidgetData: tasksWidgetData?.data?.front_page_tasks,
    tasksDeadlinesMissingData: {
      data: tasksDeadlinesMissingData.data?.front_page_tasks_deadlines_missing.edges.map((el) => el.node),
      hasNextPage: tasksDeadlinesMissingData.data?.front_page_tasks_deadlines_missing.pageInfo.hasNextPage,
      fetchMore: onLoadMoreTaskDeadlinesMissing,
    },
    tasksMoreThanThreeIterationData: {
      data: tasksMoreThanThreeIterationData.data?.front_page_tasks_more_than_three_iterations.edges.map(
        (el) => el.node
      ),
      hasNextPage:
        tasksMoreThanThreeIterationData.data?.front_page_tasks_more_than_three_iterations.pageInfo.hasNextPage,
      fetchMore: onLoadMoreMoreThanThreeIteration,
    },
    tasksDeadlinesInTwoDaysData: {
      data: tasksDeadlinesInTwoDaysData.data?.front_page_tasks_deadlines_in_two_days.edges.map((el) => el.node),
      hasNextPage: tasksDeadlinesInTwoDaysData.data?.front_page_tasks_deadlines_in_two_days.pageInfo.hasNextPage,
      fetchMore: onLoadMoreDeadlinesInTwoDays,
    },
    tasksForTodayData: {
      data: tasksForTodayData.data?.front_page_tasks_for_today.edges.map((el) => el.node),
      hasNextPage: tasksForTodayData.data?.front_page_tasks_for_today.pageInfo.hasNextPage,
      fetchMore: onLoadMoreForTodayDays,
    },
    tasksWithNewCommentsData: {
      data: tasksWithNewCommentsData.data?.front_page_tasks_with_new_comments.edges.map((el) => el.node),
      hasNextPage: tasksWithNewCommentsData.data?.front_page_tasks_with_new_comments.pageInfo.hasNextPage,
      fetchMore: onLoadMoreWithNewComments,
    },
  };
}

export default useTasksData;
