import React, { memo } from 'react';

import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { UserExtended } from 'types/entities';

import { RadioGroup } from 'components/radio';
import UserItemInfo from 'components/userItemInfo';

type SingleChoiceProps = {
  data: UserExtended[] | null;
  userList?: UserExtended[];
  onChangeSingle: (user: UserExtended) => void;
};

function SingleChoice({ data, userList, onChangeSingle }: SingleChoiceProps) {
  const options = userList?.map((user) => {
    return {
      label: <UserItemInfo user={user} />,
      value: user.pk,
    };
  });

  const handleChange = (e: RadioChangeEvent) => {
    const { value } = e.target;
    const user = userList?.find((el) => el.pk === value);
    if (user) {
      onChangeSingle(user);
    }
  };

  return (
    <div className="select-user-single">
      <RadioGroup
        defaultValue={data?.[0]?.pk}
        name="single"
        variant="default"
        options={options}
        onChange={handleChange}
      />
    </div>
  );
}

export default memo(SingleChoice);
