import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';

import { GET_ARTEFACT_FLOW_STEPS } from '../../queries/getArtefactFlowSteps';

type FlowStepsType = {
  companyArtefactTypeId: number | undefined;
  initialStateId?: number | undefined;
  fetchPolicy?: WatchQueryFetchPolicy;
  companyStateListVersionId: number | undefined;
  requiredUserRoleIds: number[] | null;
};
function useIssuesFlowSteps({
  companyArtefactTypeId,
  companyStateListVersionId,
  requiredUserRoleIds,
  initialStateId,
  fetchPolicy,
}: FlowStepsType) {
  const { data, loading, refetch } = useQuery(GET_ARTEFACT_FLOW_STEPS, {
    variables: {
      company_artefact_type_id: companyArtefactTypeId,
      initiation_state_id: initialStateId,
      required_user_role_ids: requiredUserRoleIds,
      company_state_list_version_id: companyStateListVersionId,
    },
    skip: !companyStateListVersionId || !companyArtefactTypeId || !requiredUserRoleIds,
    fetchPolicy: fetchPolicy || 'network-only',
  });
  const issuesFlowSteps = data?.artefact_flow_steps?.edges?.map((flowStep: any) => flowStep?.node);
  return {
    issuesFlowSteps,
    issuesFlowStepsLoading: loading,
    issuesFlowStepsRefetch: refetch,
  };
}

export default useIssuesFlowSteps;
