import React from 'react';

import { Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import Result from '../../../components/result';
import Button from '../../../components/button';
import { ButtonTypesEnum } from '../../../components/button/types';
const { Paragraph, Text } = Typography;

const ResultStories = () => {
  return (
    <div>
      <div className="mb_8">
        <Result
          title="Your operation has been executed"
          extra={
            <Button type={ButtonTypesEnum.primary} key="console" size="large">
              Button title
            </Button>
          }
        />
        <Result
          status="success"
          title="Successfully Purchased Cloud Server ECS!"
          subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
          extra={[
            <Button type={ButtonTypesEnum.primary} key="console" size="large">
              Button title
            </Button>,
            <Button key="buy" size="large">
              Button title
            </Button>,
          ]}
        />
        <Result
          status="warning"
          title="There are some problems with your operation."
          extra={
            <Button type={ButtonTypesEnum.primary} key="console" size="large">
              Button title
            </Button>
          }
        />
      </div>
      <div className="mb_8">
        <Result
          status="error"
          title="Submission Failed"
          subTitle="Please check and modify the following information before resubmitting."
          extra={[
            <Button type={ButtonTypesEnum.primary} key="console" size="large">
              Button title
            </Button>,
            <Button key="buy" size="large">
              Button title
            </Button>,
          ]}
        >
          <div className="desc" style={{ backgroundColor: '#FFFFFF' }}>
            <Paragraph style={{ backgroundColor: '#FFFFFF' }}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: 400,
                  color: '#848484',
                }}
              >
                The content you submitted has the following error:
              </Text>
            </Paragraph>
            <Paragraph>
              <CloseCircleOutlined className="site-result-demo-error-icon" /> Your account has been frozen.
              <a>Thaw immediately &#8594;</a>
            </Paragraph>
            <Paragraph>
              <CloseCircleOutlined className="site-result-demo-error-icon" /> Your account is not yet eligible to apply.
              <a>Apply Unlock &#8594;</a>
            </Paragraph>
          </div>
        </Result>
      </div>
    </div>
  );
};

export default ResultStories;
