import React, { useCallback } from 'react';

import { noop } from 'lodash';
import { message } from 'antd';

import { Drawer, DrawerHeader, DrawerFooter } from 'components/drawer';
import LoadingOverlay from 'components/loadingOverlay/LoadingOverlay';

import useCommonContext from 'hooks/useCommonContext';

import useAddForm from '../hooks/useAddForm';
import AddParticipantsForm from '../../components/addParticipantsForm';

import './styles.scss';

type AddEmployeeDrawerProps = {
  visible: boolean;
  onClose: () => void;
  companyId?: string;
  refetchUsers?: () => Promise<any>;
};

function AddEmployeeDrawer({ visible, companyId, onClose, refetchUsers }: AddEmployeeDrawerProps) {
  const addForm = useAddForm(null, undefined, noop, visible, false);
  const common = useCommonContext();

  const onClickClose = useCallback(() => {
    onClose();
    addForm.onResetFields();
  }, [addForm, onClose]);

  const onClickSubmit = async () => {
    await addForm.onSubmit();
    await refetchUsers?.().then(() => {
      onClickClose();
      message.success(common.t<string>('common.employeeAdd'));
    });
  };

  return (
    <Drawer
      visible={visible}
      closable={false}
      onClose={onClickClose}
      maskClosable={true}
      destroyOnClose
      placement="right"
      width={560}
      title={<DrawerHeader onClose={onClickClose} title={common.t<string>('pages.users.addingEmployee')} />}
      footer={
        <DrawerFooter
          labelSubmit={common.t<string>('common.addEmployee')}
          disabled={addForm.disableSubmit}
          onSubmit={onClickSubmit}
          labelCancel={common.t<string>('common.canceling')}
          onCancel={onClickClose}
          formId="add-employee"
        />
      }
    >
      <LoadingOverlay show={addForm.loading} text={common.t<string>('pages.users.sendInvitation')} />
      <AddParticipantsForm companyId={companyId} addForm={addForm} />
    </Drawer>
  );
}

export default AddEmployeeDrawer;
