import React, { memo } from 'react';

import { Avatar as AAvatar } from 'antd';
import { cx } from 'utils';
import { AvatarProps } from 'antd/lib/avatar/avatar';

type CCMAvatarProps = {
  className?: string;
};

function Avatar({ className, children, ...props }: CCMAvatarProps & AvatarProps) {
  const classNames = cx('ccm-avatar', className);
  return (
    <AAvatar className={classNames} style={{ backgroundColor: '#E9E9E9', color: '#1E1E1E' }} {...props}>
      {children}
    </AAvatar>
  );
}

export default memo(Avatar);
