import { arrStrToArrNum } from 'utils';
import usePageFilters from 'pages/hooks/usePageFilters';

function usePlanFilter() {
  const filter = usePageFilters();
  return {
    filterParams: {
      searchText: filter.text,
      statusName: filter.statuses,
      artefactTypeNames: filter.types,
      assigneeIds: arrStrToArrNum(filter.assignees),
      missed: filter.missed,
    },
  };
}

export default usePlanFilter;
