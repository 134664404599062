import { useCallback, useRef, useState } from 'react';

import { arrStrToArrNum } from 'utils';
import { UserExtended } from 'types/entities';

import useLazyCollaborator, { Collaborator } from 'hooks/queries/useLazyCollaborator';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import useCollaborators, { Collaborators } from 'hooks/queries/useCollaborators';
import useDebounce from 'hooks/useDebounce';

import { UsersData, UsersDataOutput } from '../types';

const LIMIT_PAGE_ITEM = 50;

function useUsersData({ filter, user, setUser }: UsersData): UsersDataOutput {
  const [users, setUsers] = useState<UserExtended[]>([]);

  //https://github.com/apollographql/react-apollo/issues/3505
  const needUpdate = useRef(true);
  const hasFirstLoad = useRef(false);

  const onCompleted = useCallback((data: Collaborators) => {
    const temp = data.collaborators.edges.map((el) => el.node);

    if (needUpdate.current) {
      setUsers([...temp]);
    }

    if (!hasFirstLoad.current) {
      hasFirstLoad.current = true;
    }
  }, []);

  const debounceFilter = useDebounce(filter, 500);

  const { data, hasNextPage, loading, fetchMore, refetch } = useCollaborators({
    filter: {
      company_ids: arrStrToArrNum(debounceFilter.companyIds),
      role_ids: arrStrToArrNum(debounceFilter.roleIds),
      search_text: filter.search,
    },
    offset: 0,
    first: LIMIT_PAGE_ITEM,
    onCompleted,
    skip: !needUpdate.current,
  });

  useLockBodyScroll(loading);

  const onCompletedUser = useCallback(
    (d: Collaborator) => {
      needUpdate.current = false;
      const temp = users.map((el) => {
        if (el.pk === d.collaborator.pk) {
          return {
            ...el,
            ...d.collaborator,
          };
        } else {
          return el;
        }
      });
      setUsers([...temp]);
      setUser(d.collaborator);
      setTimeout(() => (needUpdate.current = true), 1000);
    },
    [setUser, users]
  );

  const { onFetchUser } = useLazyCollaborator({
    companyUserId: user?.pk,
    onCompleted: onCompletedUser,
    fetchPolicy: 'network-only',
  });

  const onLoadMore = () =>
    fetchMore({
      variables: {
        filter: {
          company_ids: arrStrToArrNum(debounceFilter.companyIds),
          role_ids: arrStrToArrNum(debounceFilter.roleIds),
          search_text: debounceFilter.search,
        },
        after: data?.collaborators?.pageInfo?.endCursor,
        first: LIMIT_PAGE_ITEM,
        onCompleted,
      },
    });

  return {
    hasNextPage,
    hasFirstLoad: hasFirstLoad.current,
    loading,
    users,
    onLoadMore,
    onFetchUser,
    refetchUsers: refetch,
  };
}

export default useUsersData;
