import React, { useRef } from 'react';

import { Layout } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Outlet } from 'react-router-dom';
import { cx } from 'utils';

import useHover from 'hooks/useHover';

import MainLayoutHeader from './MainLayoutHeader';
import MainLayoutMenu from './MainLayoutMenu';
import MainLayoutFooter from './MainLayoutFooter';

import './styles.scss';

function MainLayout() {
  const anchor = useRef(null);
  const hovered = useHover(anchor);
  const sidebarClassNames = cx('main-sidebar', {
    'main-sidebar--opened': hovered,
  });

  return (
    <Layout hasSider className="main-layout">
      <Sider
        className={sidebarClassNames}
        collapsible
        theme="light"
        width={240}
        collapsedWidth={64}
        trigger={null}
        collapsed={!hovered}
        ref={anchor}
      >
        <MainLayoutHeader isOpen={hovered} />
        <MainLayoutMenu />
        <MainLayoutFooter closed={hovered} />
      </Sider>
      <div className="main-content">
        <Outlet />
      </div>
    </Layout>
  );
}

export default MainLayout;
