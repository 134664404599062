import React, { useRef, useState } from 'react';

import { Slider } from 'antd';
import { ArtefactContentVideoFoundIssue } from 'types/entities/artefact';
import { formatDuration } from 'utils';
import { useResizeDetector } from 'react-resize-detector';

import ProgressTooltip from '../tooltip';
import ErrorMark from '../errorMark';

import './styles.scss';

type VideoProgressBarProps = {
  fullScreen: boolean;
  value?: number;
  duration?: number;
  selectedIssue: ArtefactContentVideoFoundIssue | null;
  onSeekChange: (value: number) => void;
  errors?: ArtefactContentVideoFoundIssue[];
  onSelectedIssue: (value: any) => void;
};

function VideoProgressBar({
  value = 0,
  duration = 0,
  errors,
  selectedIssue,
  onSeekChange,
  onSelectedIssue,
}: VideoProgressBarProps) {
  const [position, setPosition] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const containerRef = useRef<any>();
  const { width: containerWidth, height } = useResizeDetector({ targetRef: containerRef });

  const onShowTooltip = () => {
    if (!show) {
      setShow(true);
    }
  };
  const onHiddeTooltip = () => {
    if (show) {
      setShow(false);
    }
  };

  const calcMousePosition = (e: any) => {
    if (containerRef.current) {
      const { width, left } = containerRef?.current?.getBoundingClientRect();
      const { clientX } = e;
      const percent = (clientX - left) / width;
      setPosition(percent);
    }
  };

  return (
    <div className="video-progress__wrapper">
      <div
        id="video-progress"
        onMouseEnter={onShowTooltip}
        onMouseLeave={onHiddeTooltip}
        onMouseMove={calcMousePosition}
        ref={containerRef}
        className="video-progress testclass-video-timeline"
      >
        <ProgressTooltip
          hidden={!show}
          title={formatDuration(duration * position)}
          style={{
            left: containerRef?.current?.offsetWidth * position,
            transform: `translateX(calc(-50%))`,
            top: '-50px',
          }}
        />
        {!!duration &&
          errors?.map((elem) => {
            return (
              <ErrorMark
                key={elem.id}
                progressWidth={Math.round(containerWidth || 0)}
                duration={duration}
                item={elem}
                start={elem.timestamp_start}
                end={elem.timestamp_end}
                isActive={selectedIssue?.id === elem.id}
                onSelectedIssue={onSelectedIssue}
                onSeekChange={onSeekChange}
              />
            );
          })}
        <Slider className="ccm-slider" step={0.0001} onChange={onSeekChange} min={0} max={1} value={value} />
      </div>
    </div>
  );
}

export default VideoProgressBar;
