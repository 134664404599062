import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  arrStrToArrNum,
  cx,
  executeScrollWithDelay,
  filteredNodeTree,
  getDescendantsWithSequence,
  getOnlySequencesMap,
  getPayloadBySavePlanTree,
  toggleTaskTurnOff,
  updateSequences,
} from 'utils';
import { NodeModel, TreeMethods } from '@minoru/react-dnd-treeview';
import { useParams, useSearchParams } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';
import { message } from 'antd';
import { ArtefactProductionSequencePayload, updateProjectArtefactProductionSequence } from 'api/requests/project';
import PlanEmpty from 'pages/project/components/planEmpty';
import { CustomData as CustomDataPlan } from 'pages/project/components/projectPlanTree/types';
import ProjectPlanTree from 'pages/project/components/projectPlanTree';
import SkeletonPlan from 'pages/project/components/skeletons/SkeletonPlan';
import SkeletonToolbar from 'pages/project/components/skeletons/SkeletonToolbar';

import Block from 'components/block';
import LoadingOverlay from 'components/loadingOverlay';
import EmptyData from 'components/emptyData';

import useProjectUsers from 'hooks/queries/useProjectUsers';
import useLearningObjectsForProject from 'hooks/queries/useLearningObjectsForProject';
import useCommonContext from 'hooks/useCommonContext';
import { usePrompt } from 'hooks/useBlocker';
import useProject from 'hooks/queries/useProject';
import useProjectTaskMetrics from 'hooks/queries/useProjectTaskMetrics';
import useProjectSeparateTasks from 'hooks/queries/useProjectSeparateTasks';
import useCompanyArtefactRoleAssignments from 'hooks/queries/useCompanyArtefactRoleAssignments';

import { messageDurability } from '../../../../constants';

import PlanToolbar from './components/toolbar';
import SeparateTasksCollapse from './components/separateTasks';
import usePlanFilter from './usePlanFilter';
import AssigneesDrawer from './components/assigneesDrawer';
import { FormData } from './components/assigneesDrawer/AssigneesDrawer';

import './styles.scss';

function CoursePlanPage() {
  const [searchParams] = useSearchParams();

  const openTaskId = searchParams.get('open');

  const [openGlobal, setOpenGlobal] = useState(true);
  const [treeData, setTreeData] = useState<NodeModel<CustomDataPlan>[]>([]);
  const [snapshot, setSnapshot] = useState<NodeModel<CustomDataPlan>[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedSequence, setSelectedSequence] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);

  const { id } = useParams();
  const ref = useRef<TreeMethods>(null);
  const common = useCommonContext();

  const hasChangedTree = useMemo(() => !isEqual(treeData, snapshot), [snapshot, treeData]);
  const { projectUsers } = useProjectUsers(id);
  const { filterParams } = usePlanFilter();

  // Queries
  const { learningObjectsExist, separateTaskProgress } = useProject(Number(id), 'cache-first');

  const {
    learningObjects,
    learningObjectsLoading,
    sequencesMap,
    companyArtTypeBySequenceMap,
    learningObjectsRefetch,
    hasFirstLoad,
  } = useLearningObjectsForProject(Number(id), filterParams, true, projectUsers);

  const { projectTaskMetricsRefetch } = useProjectTaskMetrics(id, filterParams, true);
  const { separateTasks } = useProjectSeparateTasks(id, filterParams);

  const sequencesSelected = useMemo(() => {
    return getOnlySequencesMap(selected, sequencesMap);
  }, [selected, sequencesMap]);

  const artefactIds = sequencesSelected?.map((el) => {
    return companyArtTypeBySequenceMap?.find((c) => c.sequenceId === el)?.companyArtTypeId as string;
  });

  const { artefactRoleAssignments } = useCompanyArtefactRoleAssignments({
    artefactIds: arrStrToArrNum(artefactIds),
  });

  const openBlock = useCallback(() => {
    setOpenGlobal(true);
    if (openTaskId) {
      executeScrollWithDelay(openTaskId, undefined, -200);
    }
  }, [openTaskId]);

  // Effects

  useEffect(() => {
    if (!isEmpty(learningObjects)) {
      if (hasFirstLoad && openTaskId) {
        openBlock();
      }
    }
    setTreeData(learningObjects);
    setSnapshot(learningObjects);
  }, [hasFirstLoad, learningObjects, openBlock, openTaskId]);

  // Actions

  const toggleOpenGlobal = useCallback(() => setOpenGlobal(!openGlobal), [openGlobal]);

  const onCloseAll = () => {
    ref?.current?.closeAll();
    setOpenGlobal(false);
  };
  const onOpenAll = () => {
    ref?.current?.openAll();
    setOpenGlobal(true);
  };

  const onStartEdit = () => {
    setEdit(true);
  };

  const onUndoEdit = () => {
    setTreeData(snapshot);
    setSelected([]);
    setSelectedSequence([]);
    setEdit(false);
  };

  const onHandleDrop = () => {};

  const onToggleTask = useCallback(
    (sequenceId: string) => {
      const updatedTree = toggleTaskTurnOff(treeData, sequenceId);
      setTreeData(updatedTree);
    },
    [treeData]
  );

  const onToggleCheckbox = useCallback(
    (artId: number) => {
      const hasId = !!selected.find((t) => Number(t) === artId);
      const { allIds, onlySequenceIds } = getDescendantsWithSequence(treeData, artId);

      if (!hasId) {
        setSelected([...selected, artId.toString(), ...allIds]);
        const p = isEmpty(onlySequenceIds) ? [artId.toString()] : [...onlySequenceIds];
        setSelectedSequence([...selectedSequence, ...p]);
      } else {
        const arr = [artId.toString(), ...allIds];
        const temp = selected.filter((item) => !arr.includes(item));
        const arrSequence = [artId.toString(), ...onlySequenceIds];
        const tempSequence = selectedSequence.filter((item) => !arrSequence.includes(item));
        setSelected(isEmpty(tempSequence) ? [] : temp);
        setSelectedSequence(tempSequence);
      }
    },
    [selected, selectedSequence, treeData]
  );

  const onCleanSelected = () => {
    setSelected([]);
    setSelectedSequence([]);
  };

  const onApply = useCallback(
    (values: FormData) => {
      const updatedTree = updateSequences(
        treeData,
        sequencesSelected,
        values,
        projectUsers,
        artefactRoleAssignments
      ) as NodeModel<CustomDataPlan>[];
      setTreeData(updatedTree);
      onCleanSelected();
    },
    [artefactRoleAssignments, projectUsers, sequencesSelected, treeData]
  );

  const callBackAfterSave = useCallback(
    (cb: () => void, result: boolean, navRetry?: () => void) => {
      learningObjectsRefetch().then(() => {
        setEdit(false);
        cb();
        if (result) {
          message.info(common?.t<string>('modals.changesSaved'), 2.5);
          if (typeof navRetry === 'function') {
            navRetry?.();
          }
        } else {
          message.error(common?.t<string>('modals.changesNotSaved'), messageDurability);
        }
      });
    },
    [common, learningObjectsRefetch]
  );

  const saveTree = useCallback(
    async (callback?: () => void) => {
      setLoading(true);
      const hide = message.loading(common?.t<string>('common.savingChanges'), 0);

      const payload = getPayloadBySavePlanTree(treeData);

      await updateProjectArtefactProductionSequence(Number(id), payload)
        .then(() => {
          projectTaskMetricsRefetch();
          callBackAfterSave(hide, true, callback);
        })
        .catch(() => {
          callBackAfterSave(hide, false);
        });
    },
    [callBackAfterSave, common, id, projectTaskMetricsRefetch, treeData]
  );

  usePrompt(common.t<string>('pages.project.modal.saveChanges'), hasChangedTree, saveTree);

  const classNames = cx('project-page-content project-page-plan', {
    'project-page-content__plan': loading,
    'project-page-plan--footer': !isEmpty(selected),
  });

  return (
    <>
      <Block empty hidden={true}>
        <SkeletonToolbar />
      </Block>
      <Block empty hidden={!true}>
        <PlanToolbar
          isTree
          isEdit={edit}
          hasChangedTree={hasChangedTree}
          onOpenAll={onOpenAll}
          onCloseAll={onCloseAll}
          onStartEdit={onStartEdit}
          onUndoEdit={onUndoEdit}
          onSave={saveTree}
        />
      </Block>

      <Block className={classNames}>
        {!hasFirstLoad ? <SkeletonPlan /> : null}
        {!learningObjectsExist && hasFirstLoad && isEmpty(learningObjects) && isEmpty(separateTasks) ? (
          <PlanEmpty />
        ) : null}
        {learningObjectsExist && hasFirstLoad && isEmpty(learningObjects) && isEmpty(separateTasks) ? (
          <EmptyData />
        ) : null}
        {hasFirstLoad && learningObjectsExist && (!isEmpty(learningObjects) || !isEmpty(separateTasks)) ? (
          <div className="project-page-content__content">
            <Block empty hidden={isEmpty(separateTasks)}>
              <SeparateTasksCollapse
                data={separateTasks}
                open={openGlobal}
                onToggle={toggleOpenGlobal}
                progress={separateTaskProgress}
              />
            </Block>
            <Block empty hidden={isEmpty(treeData)}>
              <ProjectPlanTree
                anchorTree={ref}
                isEdit={edit}
                treeData={filteredNodeTree(treeData)}
                onHandleDrop={onHandleDrop}
                onToggleTask={onToggleTask}
                selected={selected}
                onToggleCheckbox={onToggleCheckbox}
              />
            </Block>
          </div>
        ) : null}
      </Block>

      <AssigneesDrawer
        open={!isEmpty(selected)}
        artefactIds={artefactIds}
        onApply={onApply}
        onCancel={onCleanSelected}
      />

      <LoadingOverlay
        className="plan-loading-overlay"
        show={hasFirstLoad && learningObjectsLoading}
        text={common.t<string>('common.loadingData')}
      />
    </>
  );
}

export default CoursePlanPage;
