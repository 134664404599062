import { useCallback, useState } from 'react';

type UseTabsControlProps = {
  defaultActiveKey?: string;
};

export default function useTabsControl({
  defaultActiveKey,
}: UseTabsControlProps): [view?: string, onChangeTab?: (value: string) => void] {
  const [tab, setTab] = useState<string | undefined>(defaultActiveKey);

  const onChangeTab = useCallback((activeKey: string) => setTab(activeKey), []);

  return [tab, onChangeTab];
}
