import { useQuery } from '@apollo/client';
import { GET_PROJECT_TASK_DISCUSSIONS } from 'queries/getProjectTaskDiscussMessages';
import { Company, UserBase } from 'types/entities';

export type SeparateTaskDiscussionItem = {
  id: string;
  message: string;
  created_at: string;
  is_active: boolean;
  parent_message: {
    id: string;
  } | null;
  creator: UserBase;
  children: {
    id: string;
    message: string;
    created_at: string;
    updated_at: string;
    is_active: boolean;
    creator: UserBase;
  }[];
};

type ProjectTaskDiscussionsData = {
  project_task_discuss_messages: SeparateTaskDiscussionItem[];
};

function useProjectTaskDiscussionsQuery(taskId?: string) {
  const { data, refetch } = useQuery<ProjectTaskDiscussionsData>(GET_PROJECT_TASK_DISCUSSIONS, {
    variables: {
      taskId,
    },
    skip: !taskId,
  });

  return {
    discussions: data?.project_task_discuss_messages,
    discussionsRefetch: refetch,
  };
}

export default useProjectTaskDiscussionsQuery;
