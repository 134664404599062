import { GET_PIPELINE } from '../../queries/getArtefactPipeline';
import { CompanyArtefactTypeWithId } from '../../types/entities';
import { CompanyState } from '../../types';
import { client } from '../../schemas';

export type SequenceRule = {
  id: string;
  pk: string;
  is_active_rule: string;
  artefact_pipeline_step_sequence: {
    artefact_type: {
      id: string;
      name: string;
      type: string;
    };
  };
  artefact_flow_step: {
    id: string;
    state_initiation: {
      id: string;
      name: string;
      state_type: string;
    };
  };
  set_state: {
    id: string;
    name: string;
    icon_image: string;
    state_type: string;
  };
};

export type Management = {
  statusListVerId?: string;
  links: {
    id: string;
    source: string;
    sourceHandle: string;
    target: string;
    targetHandle: string;
    type: string;
  }[];
  position: {
    x: number;
    y: number;
  };
};

interface ExtendedCompanyState extends CompanyState {
  company_state_list_version: {
    id: string;
  };
}

export type ArtefactPipelineSequence = {
  id: string;
  order: number;
  is_final: boolean;
  management_data?: string;
  artefact_type: CompanyArtefactTypeWithId;
  default_state: ExtendedCompanyState;
  sequence_rules: SequenceRule[] | null;
};

type ArtefactPipelineStep = {
  id: string;
  order: number;
  sequences: ArtefactPipelineSequence[];
};

export type ArtefactPipelineItem = {
  id: string;
  last_version: {
    id: string;
    steps: ArtefactPipelineStep[];
  };
  actual_version: {
    id: string;
    steps: ArtefactPipelineStep[];
  };
};

type Data = {
  artefact_pipeline: ArtefactPipelineItem;
};

export const pipelineQuery = (id: string) => {
  return client.query<Data>({
    query: GET_PIPELINE,
    fetchPolicy: 'network-only',
    variables: {
      id: Number(id),
    },
  });
};
