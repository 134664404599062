import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';

import { noop } from 'lodash';
import { EyeFilled, EyeInvisibleFilled, FieldNumberOutlined } from '@ant-design/icons';
import { cx, getFormatDateByStr, getUrlToTask, projectTaskAssigneesToCompanyUserItem } from 'utils';
import { useParams } from 'react-router-dom';
import TruncateMarkup from 'react-truncate-markup';

import Block from 'components/block/Block';
import Checkbox from 'components/checkbox/Checkbox';
import Button from 'components/button/Button';
import StatusLabelFlat from 'components/statusLabelFlat';
import { ProjectStatusChangedPopup } from 'components/historyPopup';
import ArtefactDeadlinesChanged from 'components/historyPopup/artefactDeadlinesChanged';
import { ButtonTypesEnum } from 'components/button/types';
import AvatarGroup from 'components/avatarGroup';
import TagDelayWithFlag from 'components/tagDelayWithFlag';
import Tooltip from 'components/tooltip';

import { getStageImageByType } from 'utils/icons';

import useCommonContext from 'hooks/useCommonContext';

import { ExtraContentItemProps } from '../../types';

import './styles.scss';

function ExtraContentItem({
  depth,
  item,
  isEdit,
  selected,
  learningObjectId,
  onToggleCheckbox,
  onToggleTask,
}: ExtraContentItemProps) {
  const common = useCommonContext();
  const [showButton, setShowButton] = useState<boolean>(false);
  const { id } = useParams();

  const onMouseEnter = () => setShowButton(true);
  const onMouseLeave = () => setShowButton(false);

  const iterationCount = item.project_task.cached_linked_artefact?.artefact_iteration_count;

  //Iteration First Deadline
  const iterationDeadline = item.project_task.cached_linked_artefact?.first_iteration_deadline;
  const iterationDeadlineDelay = item.project_task.cached_linked_artefact?.first_iteration_deadline_delay;
  const iterationDeadlineChanged = item.project_task.cached_linked_artefact?.first_iteration_deadline_changed;
  // const isShowIterationDelay =
  //   moment(iterationDeadline).isBefore(today) && item?.project_task?.last_state?.state_type === StateType.NEW;

  //Production Second Deadline
  const productionDeadline = item.project_task.cached_linked_artefact?.production_deadline;
  const productionDeadlineDelay = item.project_task.cached_linked_artefact?.production_deadline_delay;
  const productionDeadlineChanged = item.project_task.cached_linked_artefact?.production_deadline_changed;

  // const isShowProductionDelay =
  //   moment(productionDeadline).isBefore(today) && item?.project_task?.last_state?.state_type !== StateType.DONE;

  const assignees = useMemo(() => {
    return item.current_assignees?.filter((r) => r.project_user);
  }, [item.current_assignees]);

  const convertAssignees = assignees?.map((a) => projectTaskAssigneesToCompanyUserItem(a));

  const isSelect = useMemo(() => {
    return !!selected.find((s) => s === item?.id);
  }, [item?.id, selected]);

  const onClickEye = useCallback(() => {
    if (item?.id) {
      onToggleTask(item?.id);
    }
  }, [item, onToggleTask]);

  const onChangeCheckbox = useCallback(() => {
    onToggleCheckbox(Number(item?.id));
  }, [item?.id, onToggleCheckbox]);

  const path = getUrlToTask(
    learningObjectId,
    item.project_task.id,
    iterationCount,
    item.project_task.cached_linked_artefact?.id,
    item.project_task.cached_linked_artefact?.artefact_type.type,
    item.project_task.cached_linked_artefact?.artefact_versions[0]?.file.file_type
  );

  const onClickLink = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      window.open(path, '_blank');
    },
    [path]
  );

  const classNames = cx('ex-item', {
    [`ex-item--${depth}`]: depth,
    [`ex-item--edit`]: isEdit,
    [`ex-item--turn-off`]: item.is_turn_off,
  });

  if (item.is_turn_off && !isEdit) {
    return null;
  }

  return (
    <div
      role={isEdit ? 'link' : 'block'}
      className={classNames}
      onClick={isEdit ? noop : onClickLink}
      onMouseEnter={isEdit ? onMouseEnter : noop}
      onMouseLeave={isEdit ? onMouseLeave : noop}
    >
      <Block empty hidden={!isEdit}>
        <Checkbox
          className="course-tree-plan-item__checkbox"
          checked={isSelect}
          disabled={item.is_turn_off}
          onChange={onChangeCheckbox}
        />
      </Block>
      <Block empty hidden={isEdit}>
        <div className="course-tree-plan-item__checkbox course-tree-plan-item__checkbox-empty" />
      </Block>
      <div className="ex-item__inner">
        <div className="ex-item__icon-wrap">
          {getStageImageByType(item.project_task?.cached_linked_artefact?.artefact_type?.icon_image || '')}
        </div>
        <TruncateMarkup lines={1}>
          <span className="ex-item__name">{item.project_task?.cached_linked_artefact?.artefact_type?.name}</span>
        </TruncateMarkup>
        <span className="ex-item__iteration">
          <Tooltip
            className="flex-row flex-align-items--center"
            title={common.t<string>('pages.dashboard.tooltips.iteration')}
          >
            <FieldNumberOutlined style={{ fontSize: 16, color: '#848484' }} />
            <span className="ex-item__iteration-value">{iterationCount}</span>
          </Tooltip>
        </span>
        <span className="ex-item__user-name">
          {<AvatarGroup users={convertAssignees} hasPopover maxPopoverTrigger="hover" />}
        </span>

        <div className="ex-item__deadline">
          {iterationDeadline ? (
            <TagDelayWithFlag
              filled={false}
              text={iterationDeadline}
              delay={iterationDeadlineDelay}
              tooltip={`${common.t<string>('pages.dashboard.tooltips.iterationDeadline')} ${iterationDeadlineDelay}`}
              extraText={
                iterationDeadlineDelay > 0 ? `${iterationDeadlineDelay}${common.t<string>('common.shortDay')}` : null
              }
            />
          ) : null}

          {iterationDeadlineChanged && !isEdit ? (
            <ArtefactDeadlinesChanged
              projectId={id}
              isSeparate={false}
              artefactId={item.project_task.cached_linked_artefact?.id}
              deadlineType="first_iteration_deadline"
            />
          ) : null}
        </div>
        <div className="ex-item__deadline ex-item__deadline-second">
          {productionDeadline ? (
            <TagDelayWithFlag
              filled={false}
              flagFilled
              text={productionDeadline}
              delay={productionDeadlineDelay}
              tooltip={`${common.t<string>('pages.dashboard.tooltips.finalDeadline')} ${productionDeadlineDelay}`}
              extraText={
                productionDeadlineDelay > 0 ? `${productionDeadlineDelay}${common.t<string>('common.shortDay')}` : null
              }
            />
          ) : null}

          {productionDeadlineChanged && !isEdit ? (
            <ArtefactDeadlinesChanged
              isSeparate={false}
              projectId={id}
              artefactId={item.project_task.cached_linked_artefact?.id}
              deadlineType="artefact_production_deadline"
            />
          ) : null}
        </div>

        <div className="ex-item__status-change">
          <Tooltip
            className="flex-row flex-align-items--center"
            title={common.t<string>('pages.dashboard.tooltips.lastChanged')}
          >
            <span
              className={
                item.project_task?.last_state_is_changed
                  ? 'ex-item__status-change-value'
                  : 'ex-item__status-change-empty'
              }
            >
              {getFormatDateByStr(item?.project_task.last_state_updated_at)}
            </span>
            {item.project_task?.last_state_is_changed && (
              <ProjectStatusChangedPopup isSeparate={false} projectId={id} projectTaskId={item.project_task.id} />
            )}
          </Tooltip>
        </div>

        <div className="ex-item__status">
          <StatusLabelFlat
            type={item?.project_task?.last_state?.state_type}
            name={item?.project_task?.last_state?.name}
          />
        </div>

        <Block className="ex-item__task" hidden={!isEdit}>
          <Block empty>
            <Button
              size="large"
              onClick={onClickEye}
              type={ButtonTypesEnum.text}
              icon={item.is_turn_off ? <EyeInvisibleFilled /> : <EyeFilled />}
            />
          </Block>
        </Block>
      </div>
    </div>
  );
}

export default ExtraContentItem;
