import React from 'react';

import { Form } from 'antd';

import Title from 'components/title/Title';
import { TitleVariantEnum } from 'components/title/types';
import Select from 'components/select/Select';
import Button from 'components/button';
import { ButtonTypesEnum } from 'components/button/types';

import './styles.scss';

const GlobalSettingsPage = () => {
  const onSubmit = () => {};

  return (
    <div className="global-sett-page">
      <div className="global-sett__top">
        <Title variant={TitleVariantEnum.h4}>Company setting:</Title>
      </div>
      <Form className="global-sett-form" name="global-sett" layout="vertical" requiredMark={false} onFinish={onSubmit}>
        <Select
          label="Default project task state list"
          name="state_type"
          size="xl"
          //options={typeOptions}
          placeholder="Select type"
          rules={[{ required: true, message: 'required field' }]}
        />
        <Select
          label="Artefact isuue state list"
          name="state_type"
          size="xl"
          //options={typeOptions}
          placeholder="Select type"
          rules={[{ required: true, message: 'required field' }]}
        />
        <Select
          label="Artefact discussion state list"
          name="state_type"
          size="xl"
          //options={typeOptions}
          placeholder="Select type"
          rules={[{ required: true, message: 'required field' }]}
        />
        <Select
          label="Artefact state list"
          name="state_type"
          size="xl"
          //options={typeOptions}
          placeholder="Select type"
          rules={[{ required: true, message: 'required field' }]}
        />
        <div className="global-sett-form__footer">
          <Button size="xl" type={ButtonTypesEnum.primary}>
            Update
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default GlobalSettingsPage;
