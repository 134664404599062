import React, { useState } from 'react';

import { InfoCircleOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';

import Button from 'components/button';
import { ButtonTypesEnum } from 'components/button/types';
import Block from 'components/block';

import useCommonContext from 'hooks/useCommonContext';
import usePermissionsProject, { ProjectPageEnum } from 'hooks/permissions/usePermissionsProject';

import ImportInstructionsDrawer from '../importInstructionsDrawer';
import { onImportProgramFile } from '../../fetches';
import './styles.scss';

type CourseImportProps = {
  projectId: number;
  learningObjectsRefetch: () => Promise<any>;
};

function CourseImport({ projectId, learningObjectsRefetch }: CourseImportProps) {
  const [visible, setVisible] = useState(false);
  const uploaderRef = React.useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const common = useCommonContext();

  const { canIEdit } = usePermissionsProject({ page: ProjectPageEnum.content });

  const onClose = () => {
    setVisible(false);
  };
  const showDrawer = () => {
    setVisible(true);
  };
  const onImportFileHandler = async (event: any) => {
    await onImportProgramFile(common?.t, projectId, event, learningObjectsRefetch, setLoading, showDrawer);
    await learningObjectsRefetch();
  };
  const onImportFileClickHandler = () => {
    uploaderRef?.current?.click();
  };
  return (
    <div className="course-import">
      {loading ? (
        <LoadingOutlined style={{ fontSize: 80, color: '#1E1E1E' }} spin />
      ) : (
        <>
          <div className="course-import__title">
            <span>{common.t<string>('pages.project.emptyCourse')}</span>
          </div>
          <div className="course-import__description">
            <span>
              {common.t<string>(canIEdit ? 'pages.project.importFormats' : 'pages.project.contactManagerToImport')}
            </span>
          </div>
          <div className="course-import__buttons">
            <Button type={ButtonTypesEnum.default} onClick={showDrawer}>
              <InfoCircleOutlined />
              {common.t<string>('pages.project.seeGuide')}
            </Button>

            <Block empty hidden={!canIEdit}>
              <Button onClick={onImportFileClickHandler}>
                <UploadOutlined />
                {common.t<string>('pages.project.uploadProgram')}
              </Button>
              <input ref={uploaderRef} type="file" style={{ display: 'none' }} onChange={onImportFileHandler} />
            </Block>
          </div>
          <ImportInstructionsDrawer visible={visible} onClose={onClose} />
        </>
      )}
    </div>
  );
}

export default CourseImport;
