import { useLazyQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';

import { GET_COLLABORATOR } from '../../queries/getCollaborator';
import { UserExtended } from '../../types/entities';

export type Collaborator = {
  collaborator: UserExtended;
};

type CollaboratorProps = {
  companyUserId?: string;
  onCompleted: (data: Collaborator) => void;
  fetchPolicy?: WatchQueryFetchPolicy;
};

function useLazyCollaborator({ companyUserId, onCompleted, fetchPolicy }: CollaboratorProps) {
  const [onFetchUser, {}] = useLazyQuery(GET_COLLABORATOR, {
    variables: {
      companyUserId,
    },
    onCompleted,
    fetchPolicy,
  });

  return {
    onFetchUser,
  };
}

export default useLazyCollaborator;
