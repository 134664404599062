import { gql } from '@apollo/client';

export const GET_PROJECT_DEADLINES_CHANGED = gql`
  query GetProjectDeadlinesChanged($project_id: Int) {
    project_deadlines_changed(project_id: $project_id) {
      edges {
        node {
          pk
          created_at
          creator {
            id
            first_name
            last_name
          }
          deadline_new
          deadline_old
          production_start_date
          production_finish_date
          relation_changed {
            id
          }
        }
      }
    }
  }
`;
