import React from 'react';

type ProgressUploadProps = {
  fileSize?: number | null;
};

function useProgressUpload({ fileSize }: ProgressUploadProps) {
  const [value, setValue] = React.useState<number>(0);

  const resetProgress = () => setValue(0);
  const updateProgress = (v: number) => setValue(v);

  const percent = value && fileSize ? (value * 100) / fileSize : undefined;

  return {
    progress: percent || 0,
    resetProgress,
    updateProgress,
  };
}

export default useProgressUpload;
