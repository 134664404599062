import React, { memo } from 'react';

import { ProjectPhaseState, ProjectState } from 'types/entities';

import Tag from 'components/tag/Tag';
import { TagSizeEnum } from 'components/tag/types';
import StatusLabelFlat from 'components/statusLabelFlat/StatusLabelFlat';
import Block from 'components/block';

import { getStageImageByType } from 'utils/icons';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

type ContentStateProps = {
  phaseState?: ProjectPhaseState;
  projectState?: ProjectState;
};

function ContentState({ phaseState, projectState }: ContentStateProps) {
  const common = useCommonContext();
  return (
    <div className="info-drawer-content-state">
      <div className="info-drawer-content-state__block">
        <span className="info-drawer-content-state__title">{common.t<string>('common.stage')}</span>
        <Block hidden={!phaseState?.name || !phaseState?.state_type} className="info-drawer-content-state__value">
          <Tag icon={getStageImageByType(phaseState?.icon_image)} size={TagSizeEnum.medium}>
            {phaseState?.name}{' '}
          </Tag>
        </Block>
      </div>
      <div className="info-drawer-content-state__block">
        <span className="info-drawer-content-state__title">{common.t<string>('common.Status')}</span>
        <Block hidden={!projectState?.name || !projectState?.state_type} className="info-drawer-content-state__value">
          <StatusLabelFlat type={projectState?.state_type} name={projectState?.name} />
        </Block>
      </div>
    </div>
  );
}

export default memo(ContentState);
