import React, { ChangeEvent, memo } from 'react';

import TextField from 'components/textField';

import useCommonContext from '../../../../hooks/useCommonContext';
import './styles.scss';
import Block from '../../../../components/block';
import Button from '../../../../components/button/Button';
import { ButtonTypesEnum } from '../../../../components/button/types';

import { CloseOutlined } from '@ant-design/icons';

import { StateType } from '../../../../types/entities';

type ResourceLinkInputTypeProps = {
  onChangeLink: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  disabled: boolean;
  isError: boolean;
  closeInputButtonHandler: () => void;
  link: string | undefined;
  currentTaskStatus: StateType | undefined;
};

function ResourceLinkInput({
  onChangeLink,
  disabled,
  link,
  isError,
  closeInputButtonHandler,
  currentTaskStatus,
}: ResourceLinkInputTypeProps) {
  const common = useCommonContext();
  return (
    <div className="resource-link mr_16">
      <span className="resource-link__title">{common?.t<string>('common.linkToFile')}</span>
      <div className="flex-row resource-link__input">
        <TextField
          noForm
          placeholder={common?.t<string>('common.link')}
          size="large"
          onChange={onChangeLink}
          disabled={disabled}
          defaultValue={link}
          value={link}
          className="mr_16"
        />
        <Block hidden={currentTaskStatus !== StateType.REOPEN}>
          <Button
            onClick={closeInputButtonHandler}
            type={ButtonTypesEnum.default}
            size="large"
            className="resource-link__cancel-button"
          >
            <CloseOutlined style={{ color: '#E5263E' }} />
          </Button>
        </Block>
      </div>
      <Block hidden={!isError} className="resource-link__validation">
        <span className="resource-link__error">{common?.t<string>('common.linkValidation')}</span>
      </Block>
    </div>
  );
}

export default memo(ResourceLinkInput);
