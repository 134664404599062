import React from 'react';

import { arrStrToArrNum } from 'utils';
import { Form } from 'antd';
import { flatten, uniqBy } from 'lodash';
import { useParams } from 'react-router-dom';
import { Dayjs } from 'dayjs';

import { Drawer, DrawerFooter, DrawerHeader } from 'components/drawer';
import Select from 'components/select';
import DatePicker from 'components/datePicker/DatePicker';

import useCompanyArtefactRoleAssignments from 'hooks/queries/useCompanyArtefactRoleAssignments';
import useCommonContext from 'hooks/useCommonContext';
import useProjectUsers from 'hooks/queries/useProjectUsers';

import AssigneesDrawerSkeleton from './Skeleton';

export type FormData = {
  artefact_iteration_deadline?: Dayjs;
  artefact_production_deadline?: Dayjs;
  [name: string]: any;
};

type Props = {
  open: boolean;
  artefactIds: string[];
  onApply: (values: FormData) => void;
  onCancel: () => void;
};

const AssigneesDrawer = ({ open, artefactIds, onApply, onCancel }: Props) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { projectUsers } = useProjectUsers(id);
  const common = useCommonContext();

  const { loading, artefactRoleAssignments } = useCompanyArtefactRoleAssignments({
    artefactIds: arrStrToArrNum(artefactIds),
  });

  const userRoles = flatten(
    artefactRoleAssignments?.map((a) => {
      return a.user_roles.map((r) => r);
    })
  );

  const uniqUserRoles = uniqBy(userRoles, 'id');

  const onClose = () => {
    onCancel();
    form.resetFields();
  };

  const onSubmit = () => {
    const values: FormData = form.getFieldsValue();
    onApply(values);
    onClose();
  };

  return (
    <Drawer
      className="drawer-assignees"
      closable={false}
      maskClosable={false}
      mask={false}
      destroyOnClose
      placement="right"
      width={560}
      open={open}
      title={<DrawerHeader onClose={onClose} title={common.t<string>('pages.project.selectAssignees')}></DrawerHeader>}
      footer={<DrawerFooter labelSubmit={common.t<string>('common.apply')} onSubmit={onSubmit} disabled={loading} />}
    >
      {loading ? (
        <AssigneesDrawerSkeleton />
      ) : (
        <Form form={form} layout="vertical">
          {uniqUserRoles.map((el) => {
            const options = projectUsers
              ?.filter((u) => u.roles.some((r) => r.role.id === el.id))
              ?.map((o) => {
                return {
                  id: o.company_user.id,
                  label: o.company_user.user.name,
                  value: o.company_user.id,
                };
              });

            return (
              <Form.Item key={el.id} label={el.name} name={el.id}>
                <Select size="xl" allowClear options={options} placeholder={common.t<string>('common.select')} />
              </Form.Item>
            );
          })}
          <Form.Item
            label={common.t<string>('pages.project.deadlineFirstIteration')}
            name="artefact_iteration_deadline"
          >
            <DatePicker format="DD.MM.YY" size="xl" getPopupContainer={(triggerNode) => triggerNode} />
          </Form.Item>
          <Form.Item label={common.t<string>('pages.project.finalDeadline')} name="artefact_production_deadline">
            <DatePicker size="xl" format="DD.MM.YY" getPopupContainer={(triggerNode) => triggerNode} />
          </Form.Item>
        </Form>
      )}
    </Drawer>
  );
};

export default AssigneesDrawer;
