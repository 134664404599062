import React, { memo } from 'react';

import { Skeleton } from 'antd';

const arr = Array.from(Array(8).keys());

function SelectUserSkeleton() {
  return (
    <div className="skeleton-select-user">
      {arr.map((el) => {
        return (
          <div key={el} className="skeleton-select-user-item">
            <Skeleton active avatar paragraph={{ rows: 1 }} />
          </div>
        );
      })}
    </div>
  );
}

export default memo(SelectUserSkeleton);
