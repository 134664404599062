import { createAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import i18n, { TFunction } from 'i18next';

import { IThunkResult } from '../../types/store';
import {
  onChangeProjectStatus,
  onChangeProjectUsersList,
  onCreateProject,
  onGetProjectById,
  onGetProjects,
  onGetStatuses,
  onRollbackProjectStatus,
} from '../../api/requests/projects';
import { ArtefactStateRequest, CreateProjectRequest, TaskStateRollbackRequest } from '../../pages/task/types';

import ProjectsTypes from './types';
import { IArtefactResponse, Project } from './interfaces';

export const setProjectsDataAction = createAction<any>(ProjectsTypes.SET_PROJECTS_DATA);
export const setCompanyStatesDataAction = createAction<any>(ProjectsTypes.SET_COMPANY_STATES_DATA);

export async function asyncForEach<T>(array: Array<T>, callback: (item: T, index: number) => Promise<void>) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index);
  }
}
export const getProjectByIdRequest = (projectId: number): IThunkResult<void> => {
  return async (dispatch) => {
    const result = await onGetProjectById(projectId).then((response: IArtefactResponse) => {});
    return result;
  };
};
export const changeTaskStatusRequest = (payload: ArtefactStateRequest): IThunkResult<void> => {
  return async (dispatch) => {
    await onChangeProjectStatus(payload.projectTaskId, {
      state_id: payload.state_id,
      message: payload?.message,
    });
  };
};
export const rollbackTaskStatusRequest = (payload: TaskStateRollbackRequest): IThunkResult<void> => {
  return async (dispatch) => {
    await onRollbackProjectStatus(payload.projectTaskId, {
      history_id: payload?.history_id,
    });
  };
};
export const getStatusesRequest = (companyId: number): IThunkResult<void> => {
  return async (dispatch) => {
    await onGetStatuses(companyId).then((response) => {
      dispatch(setCompanyStatesDataAction(response?.actual_states));
    });
  };
};
// export const updateProjectUsersRequest = (projectId: number, payload: any): IThunkResult<void> => {
//   return async (dispatch) => {
//     const resp = await onChangeProjectUsersList(projectId, payload);
//   };
// };
//
// export const createProjectRequest = (payload: CreateProjectRequest, t: TFunction): IThunkResult<void> => {
//   return async (dispatch) => {
//     const resp = await onCreateProject(payload);
//     if (resp) {
//       message.success(t<string>('pages.courses.createdSuccessful'), 2.5);
//     } else {
//       message.error(t<string>('pages.courses.createdFail'), 2.5);
//     }
//   };
// };

export const getProjectsRequest = (): IThunkResult<void> => {
  const allProjectsData: any = [];
  return async (dispatch) => {
    const projectsResponse = await onGetProjects();
    const projects = projectsResponse?.results;
    await asyncForEach(projects, async (eachItem: Project) => {
      const fullProject = await onGetProjectById(eachItem?.id).then((result: IArtefactResponse) => {
        return { ...eachItem, artefacts: result?.results };
      });
      allProjectsData.push(fullProject);
    });
    dispatch(setProjectsDataAction(allProjectsData));
  };
};
