import React from 'react';

import { ProjectSeparateTaskItem } from 'types/entities/separate';

import SeparateTaskProductionDate from '../../viewSeparateTask/modules/separateProductionDate';
import SeparateTaskComments from '../../viewSeparateTask/modules/separateTaskComments';

import SeparateTaskPanelUsers from './components/panelUsers';

import './styles.scss';

type SeparateTaskPanelProps = {
  task?: ProjectSeparateTaskItem;
  openDrawer: () => void;
};

function SeparateTaskPanel({ task, openDrawer }: SeparateTaskPanelProps) {
  return (
    <div className="free-task-panel">
      <SeparateTaskPanelUsers task={task} openDrawer={openDrawer} />
      <SeparateTaskProductionDate />
      <SeparateTaskComments />
      <div></div>
    </div>
  );
}

export default SeparateTaskPanel;
