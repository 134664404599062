import React from 'react';

import constructor from 'assets/image/all_constructor_logo.svg';
import constructorSmall from 'assets/image/all_constructor_logo-s.svg';
import {
  AlertFilled,
  AlertOutlined,
  ClockCircleOutlined,
  EyeOutlined,
  FileUnknownOutlined,
  LockOutlined,
  SafetyCertificateOutlined,
  SettingOutlined,
  StopFilled,
  WarningFilled,
} from '@ant-design/icons';

import { StateType } from '../types/entities';
import { StatusLabelEnum } from '../components/statusLabel/types';
import SvgInline from '../components/svgInline';
import logoCF from '../assets/image/logoCF-black.svg';
import logoCCM from '../assets/image/ccm-black-logo.svg';

export const getIconByStatus = (iconType?: StateType | StatusLabelEnum | string, color?: string) => {
  switch (iconType) {
    case StatusLabelEnum.review:
      // case StatusLabelEnum.reopen:
      return <EyeOutlined style={{ fontSize: '12px', color: color || '#5149D3' }} />;
    case StatusLabelEnum.done:
    case StateType.DONE:
    case StatusLabelEnum.approved:
      return <SafetyCertificateOutlined style={{ fontSize: '12px', color: color || '#52AD3D' }} />;
    case StatusLabelEnum.block:
    case StatusLabelEnum.blocked:
    case StateType.BLOCKED:
      return <LockOutlined style={{ fontSize: '12px', color: color || '#44434D' }} />;
    case StatusLabelEnum.in_progress:
    case StateType.IN_PROGRESS:
    case StateType.NEW:
      return <ClockCircleOutlined style={{ fontSize: '12px', color: color || '#44434D' }} />;
    case StatusLabelEnum.edits:
    // case StatusLabelEnum.review:
    case StatusLabelEnum.reopen:
      return <AlertOutlined style={{ fontSize: '12px', color: color || '#E98529' }} />;
    default:
      return null;
  }
};

export const getHealthIcon = (health: number, fontSize?: number) => {
  if (health === 0) {
    return null;
  }
  //temp range solution according Ilga's task
  if (0 < health && health < 7) {
    return <WarningFilled style={{ color: '#E29416', fontSize }} />;
  }

  if (health >= 7 && health < 30) {
    return <AlertFilled style={{ color: '#D73C1D', fontSize }} />;
  }

  if (health >= 30) {
    return <StopFilled style={{ color: '#1E1E1E', fontSize }} />;
  }

  return null;
};

export const getStageImageByType = (url?: string) => {
  const extension = url?.split('.').pop();
  switch (extension) {
    case 'png':
      return <img src={url} alt="icon" />;
    case 'svg':
      return <SvgInline url={url} size={14} />;
    default:
      return <FileUnknownOutlined />;
  }
};

export const getIconByUrl = (url?: string | null) => {
  if (!url) {
    return <FileUnknownOutlined />;
  }

  const extension = url?.split('.').pop();
  switch (extension) {
    case 'png':
      return <img className="img-icon" src={url} alt="icon" />;
    case 'svg':
      return <SvgInline url={url} size={14} />;
    default:
      return <FileUnknownOutlined />;
  }
};

export const getAppIcon = (isSmall?: boolean) => {
  const logo = process.env.REACT_APP_CCM_LANG === 'en' ? logoCF : logoCCM;
  const appCompany = process.env.REACT_APP_CCM_COMPANY;

  switch (appCompany) {
    case 'constructor' && isSmall:
      return constructorSmall;
    case 'constructor':
      return constructor;
    default:
      return logo;
  }
};
