import React, { memo } from 'react';

import Badge from '../badge';
import { StatusRowEnum } from '../statusLabel/types';
import Block from '../block/Block';
import { CCMBadgeVariant } from '../badge/types';

type TabTitleWithBadgeProps = {
  title: string;
  counter?: React.ReactNode;
  type?: StatusRowEnum;
  showZero?: boolean;
};

import './styles.scss';

function TabTitleWithBadge({ title, counter, type, showZero = true }: TabTitleWithBadgeProps) {
  return (
    <div className="ccm-tab-pane-title-badge testclass-errors-tab">
      <span className="ccm-tab-pane-title">{title}</span>
      <Block hidden={!counter}>
        <Badge count={counter} status={type} showZero={showZero} variant={CCMBadgeVariant.light} />
      </Block>
    </div>
  );
}

export default memo(TabTitleWithBadge);
