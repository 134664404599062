import React from 'react';

import Badge from '../../../components/badge';

const BadgeStories = () => {
  return (
    <div>
      <div className="mb_8">
        <Badge count={99} className="mr_8" style={{ backgroundColor: '#1E1E1E' }} />
        <Badge count={99} className="mr_8" style={{ backgroundColor: '#FFFFFF', color: '#000000' }} />
        <Badge count={99} className="mr_8" style={{ backgroundColor: '#65B129' }} />
        <Badge count={99} className="mr_8" style={{ backgroundColor: '#D73C1D' }} />
        <Badge count={99} className="mr_8" style={{ backgroundColor: '#5149D3' }} />
      </div>
      <div className="mb_8">
        <Badge count={9} className="mr_8" style={{ backgroundColor: '#1E1E1E' }} />
        <Badge count={9} className="mr_8" style={{ backgroundColor: '#FFFFFF', color: '#000000' }} />
        <Badge count={9} className="mr_8" style={{ backgroundColor: '#65B129' }} />
        <Badge count={9} className="mr_8" style={{ backgroundColor: '#D73C1D' }} />
        <Badge count={9} className="mr_8" style={{ backgroundColor: '#5149D3' }} />
      </div>
    </div>
  );
};

export default BadgeStories;
