import React from 'react';

import { Form } from 'antd';
import RoleProductionView from 'pages/users/viewEmployeeDrawer/RoleProductionView';
import { DefaultOptionType } from 'rc-select/lib/Select';

import ContentBlock from 'components/contentBlock/ContentBlock';
import TextField from 'components/textField';
import Button from 'components/button/Button';
import { ButtonTypesEnum } from 'components/button/types';
import Select from 'components/select';

import useCommonContext from 'hooks/useCommonContext';

type AccountPageType = {
  form: any;
  handleFormChange: any;
  resetToDefault: any;
  loading: boolean;
  disabled: boolean;
  timezones?: DefaultOptionType[];
  onUpdate: () => void;
};

function AccountForm({
  form,
  loading,
  onUpdate,
  timezones,
  handleFormChange,
  resetToDefault,
  disabled,
}: AccountPageType) {
  const common = useCommonContext();
  const nameValidationRegex = /^\S+(\s+\S+)*/;
  const validateName = (rule: any, value: any, callback: any) => {
    if (!value) {
      callback(common.t<string>('common.requiredFields'));
    } else if (!nameValidationRegex.test(value.trim())) {
      callback(common.t<string>('common.nameShouldBe'));
    } else {
      callback();
    }
  };
  return (
    <Form
      form={form}
      id="account-form"
      className="account-form"
      layout="vertical"
      requiredMark={false}
      onFieldsChange={handleFormChange}
    >
      <ContentBlock
        className="organisation-block mb_24"
        label={common.t<string>('pages.users.organisation')}
        text={common.company?.name}
      />
      <TextField
        name="name"
        size="xl"
        label={common.t<string>('form.field.fio.label')}
        placeholder={common.t<string>('form.field.fio.placeholder')}
        rules={[
          // { required: true },
          {
            validator: validateName,
          },
        ]}
        // normalize={(value: string) => value.trim()}
      />
      <ContentBlock
        small
        hasCopy
        className="mb_24"
        label={common.t<string>('form.field.email.label')}
        text={common.user?.email}
      />
      <ContentBlock
        className="mb_24"
        label={common.t<string>('pages.users.roleProduction')}
        // hint={<RoleProductionPopover />}
        content={<RoleProductionView data={common?.businessRoles || []} />}
      />
      <Select
        size="xl"
        name="timezone"
        label={common.t<string>('common.timezone')}
        placeholder={common.t<string>('common.selectTimezone')}
        options={timezones}
        showSearch
      />
      <div className="account-form__actions">
        <Button
          className="mr_16"
          size="xl"
          onClick={onUpdate}
          htmlType="submit"
          disabled={disabled}
          type={ButtonTypesEnum.primary}
          loading={loading}
          //hintText={disabledHint}
        >
          {common.t<string>('common.save')}
        </Button>
        <Button size="xl" onClick={resetToDefault} disabled={loading}>
          {common.t<string>('common.canceling')}
        </Button>
      </div>
    </Form>
  );
}

export default AccountForm;
