import { TFunction } from 'i18next';

import { StatusRowBackendEnum } from '../../../../../components/statusLabel/types';
import { BusinessRoleEnum } from '../../../../../types/entities/user';

export const getActionStatusToIssues = (
  t: TFunction,
  issueStatus: StatusRowBackendEnum,
  userRole: BusinessRoleEnum | null
) => {
  if (userRole === BusinessRoleEnum.instructionalDesigner) {
    switch (issueStatus) {
      case StatusRowBackendEnum.READY:
      case StatusRowBackendEnum.CANCELLED:
      case StatusRowBackendEnum.REVIEW:
      case StatusRowBackendEnum.REJECTED:
        return [
          {
            label: t('common.neededChanges'),
            value: 'reopen',
          },
          {
            label: t('statuses.approved'),
            value: 'done',
          },
        ];
      case StatusRowBackendEnum.REOPEN:
        return [
          {
            label: t('common.neededChanges'),
            value: 'reopen',
          },
          {
            label: t('statuses.approved'),
            value: 'done',
          },
        ];
      case StatusRowBackendEnum.DONE:
        return [
          {
            label: t('common.neededChanges'),
            value: 'reopen',
          },
          {
            label: t('statuses.approved'),
            value: 'done',
          },
        ];
      default:
        return undefined;
    }
  } else {
    if (userRole === BusinessRoleEnum.productionExecutor) {
      switch (issueStatus) {
        case StatusRowBackendEnum.NEW:
        case StatusRowBackendEnum.REOPEN:
          return [
            {
              label: t('common.rejected'),
              value: 'rejected',
            },
            {
              label: t('common.ready'),
              value: 'ready',
            },
          ];
        case StatusRowBackendEnum.READY:
          return [
            {
              label: t('common.rejected'),
              value: 'rejected',
            },
            {
              label: t('common.ready'),
              value: 'ready',
            },
          ];
        case StatusRowBackendEnum.REJECTED:
          return [
            {
              label: t('common.rejected'),
              value: 'rejected',
            },
            {
              label: t('common.ready'),
              value: 'ready',
            },
          ];
        case StatusRowBackendEnum.DONE:
          return undefined;
        default:
          return undefined;
      }
    }
  }
};

export function downloadByLink(dataUrl: string | null | undefined) {
  if (dataUrl) {
    const link = document.createElement('a');
    link.href = dataUrl;
    link.click();
  }
}
