import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';

import { GET_PROJECT_METRICS } from '../../queries/getMetrics';

export type ProjectMetricsDelayItem = {
  id: string;
  assignee: {
    id: string;
  } | null;
  tasks_amount: number;
  delay_duration_max: number;
  calculation_date: number;
};

type ProjectMetricsDelayItemNode = {
  node?: ProjectMetricsDelayItem;
};

export type ProjectMetricsIterationItem = {
  id: string;
  assignee: {
    id: string;
  } | null;
  reviewer: {
    id: string;
  };
  total_tasks_amount: number;
  calculation_date: number;
};

type ProjectMetricsIterationItemNode = {
  node?: ProjectMetricsIterationItem;
};

function useProjectMetrics(projectId: number, fetchPolicy?: WatchQueryFetchPolicy) {
  const { data } = useQuery(GET_PROJECT_METRICS, {
    variables: {
      project_id: projectId,
    },
    skip: !projectId,
    fetchPolicy: fetchPolicy || 'network-only',
  });

  return useMemo(() => {
    return {
      currentMetricsDeadlineProduction: data?.metrics_current_assignees_missed_deadline_production?.edges?.map(
        (el: ProjectMetricsDelayItemNode) => el?.node
      ),
      currentMetricsDeadlineFirstIteration: data?.metrics_current_assignees_missed_deadline_first_iteration?.edges?.map(
        (el: ProjectMetricsDelayItemNode) => el?.node
      ),
      currentMetricsTaskFourIteration: data?.metrics_current_assignees_tasks_with_four_iterations_annotated?.edges?.map(
        (el: ProjectMetricsDelayItemNode) => el?.node
      ),

      metricsDeadlineFirstIteration: data?.metrics_assignees_missed_deadline_first_iteration?.edges?.map(
        (el: ProjectMetricsDelayItemNode) => el?.node
      ),
      metricsDeadlineProduction: data?.metrics_assignees_missed_deadline_production?.edges?.map(
        (el: ProjectMetricsDelayItemNode) => el?.node
      ),
      metricsTaskFourIteration: data?.metrics_assignees_tasks_with_four_iterations_annotated?.edges?.map(
        (el: ProjectMetricsIterationItemNode) => el?.node
      ),
    };
  }, [data]);
}

export default useProjectMetrics;
