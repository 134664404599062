import React, { memo, useCallback } from 'react';

import { DefaultOptionType } from 'rc-select/lib/Select';
import Can from 'config/Can';
import { uniqBy } from 'lodash';
import usePageFilters from 'pages/hooks/usePageFilters';

import Search from 'components/search';
import Select from 'components/select';

import useCommonContext from 'hooks/useCommonContext';
import useDashboardFilters from 'hooks/queries/useDashboardFilters';
import useCompanyState from 'hooks/queries/useCompanyState';

import './styles.scss';

function TasksFilter() {
  const common = useCommonContext();
  const filters = usePageFilters();

  const {
    companyProjectsSelect,
    companyAssigneesForSelect,
    companyMergedTypesForSelect,
    companyStatesForSelect,
    companyLearningObjectTypeForSelect,
  } = useDashboardFilters(common.company?.id, undefined, common.businessRoleIds, common.userId, 'cache-only');

  const { companySeparateStatesForSelect } = useCompanyState(
    common.company?.id,
    'network-only',
    null,
    Number(common?.company?.default_separate_task_state_list_version?.id)
  );

  const mergedStates = uniqBy([...companyStatesForSelect, ...companySeparateStatesForSelect], 'value');

  const filterOption = useCallback((inputValue: string, option?: DefaultOptionType) => {
    const label = option?.label || '';
    return label?.toString()?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  }, []);

  return (
    <div className="tasks-page-filter">
      <Select
        noForm
        name="projectName"
        placeholder={common.t<string>('pages.tasks.filter.course')}
        showSearch
        allowClear
        value={filters.projectName}
        dropdownMatchSelectWidth={200}
        options={companyProjectsSelect}
        filterOption={filterOption}
        onChange={filters.onChangeProjectName}
      />

      <Can I="manage" a="Tasks.filter.assignees">
        <Select
          noForm
          name="assignee"
          placeholder={common.t<string>('pages.tasks.filter.executor')}
          showSearch
          allowClear
          value={filters.assignee}
          dropdownMatchSelectWidth={350}
          options={companyAssigneesForSelect}
          filterOption={filterOption}
          onChange={filters.onChangeAssignee}
        />
      </Can>

      <Can I="manage" a="Tasks.filter.element">
        <Select
          noForm
          name="elements"
          mode="multiple"
          maxTagCount="responsive"
          placeholder={common.t<string>('pages.tasks.filter.element')}
          options={companyLearningObjectTypeForSelect}
          value={filters.elements}
          filterOption={filterOption}
          onChange={filters.onChangeElements}
        />
      </Can>

      <Select
        noForm
        name="types"
        mode="multiple"
        maxTagCount="responsive"
        placeholder={common.t<string>('pages.project.filter.artefact')}
        options={companyMergedTypesForSelect}
        value={filters.types}
        onChange={filters.onChangeTypes}
        testClassName="testclass-dashboard-tasktable-filter-artifact"
      />

      <Select
        noForm
        name="statuses"
        mode="multiple"
        maxTagCount="responsive"
        placeholder={common.t<string>('common.statuses')}
        options={mergedStates}
        value={filters.statuses}
        onChange={filters.onChangeStatuses}
        testClassName="testclass-dashboard-filter-statuses"
      />

      <Search
        placeholder={common.t<string>('form.field.search.placeholder')}
        className="testclass-dashboard-filter-search-button"
        testClassName="testclass-dashboard-filter-search-input"
        value={filters.textSearch}
        onChange={filters.onChangeText}
        onSearch={filters.onSearch}
      />
    </div>
  );
}

export default memo(TasksFilter);
