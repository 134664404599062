import React from 'react';

import Avatar from '../../../components/avatar';

import ava from './avatar.png';
const AvatarStories = () => {
  return (
    <div className="mb_8">
      <Avatar size="large" src={ava} className="mr_8">
        N
      </Avatar>
      <Avatar size="default" className="mr_8">
        C
      </Avatar>
      <Avatar size="small" className="mr_8">
        A
      </Avatar>
    </div>
  );
};

export default AvatarStories;
