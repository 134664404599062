import React, { memo, SyntheticEvent } from 'react';

import { LoadingOutlined } from '@ant-design/icons';

import { AllProjectTaskStateHistory, ProjectUserType } from 'hooks';

import TimelineFinalArtefactPipeline from '../../timeline/TimelineFinalArtefactPipeline';

import '../styles.scss';

type HistoryPopupContentProps = {
  data?: AllProjectTaskStateHistory[];
  projectUsers?: ProjectUserType[];
  loading: boolean;
  isSeparate: boolean;
};

function ProjectStatusChangedPopupContent({ data, projectUsers, loading, isSeparate }: HistoryPopupContentProps) {
  const onHandelClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };
  return (
    <div className="ccm-popover-history__wrapper" onClick={onHandelClick}>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <TimelineFinalArtefactPipeline data={data} projectUsers={projectUsers} isSeparate={isSeparate} />
      )}
    </div>
  );
}

export default memo(ProjectStatusChangedPopupContent);
