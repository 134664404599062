import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { message } from 'antd';
import { isEmpty } from 'lodash';
import { useParams, useSearchParams } from 'react-router-dom';
import { getDescendants, getParents, NodeModel, TreeMethods } from '@minoru/react-dnd-treeview';
import {
  addExtraData,
  arrStrToArrNum,
  cx,
  filteredNodeTree,
  getDepth,
  getIndex,
  getLastId,
  getPayloadBySaveContentTree,
} from 'utils';
import { updateProjectLOAll } from 'api/requests/project';
import modalService from 'services/modalService';
import { CustomData } from 'pages/project/components/projectContentTree/types';
import ProjectContentTree from 'pages/project/components/projectContentTree';
import { EditSectionDraft } from 'pages/project/components/projectContentTree/components/editSection/types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { StateType } from 'types/entities';
import usePageFilters from 'pages/hooks/usePageFilters';

import Block from 'components/block';

import useLearningObjectsForProject from 'hooks/queries/useLearningObjectsForProject';
import useCompanyLearningObjectTypes from 'hooks/queries/useCompanyLearningObjectTypes';
import useCommonContext from 'hooks/useCommonContext';
import useDebounce from 'hooks/useDebounce';
import useProject from 'hooks/queries/useProject';
import useBoolean from 'hooks/useBoolean';

import { initSettings } from '../../constants';
import CourseImport from '../../components/courseImport';
import { messageDurability } from '../../../../constants';
import EmptyData from '../../components/emptyData';
import SkeletonPlan from '../../components/skeletons/SkeletonPlan';
import LoadingOverlay from '../../../../components/loadingOverlay';
import SkeletonToolbar from '../../components/skeletons/SkeletonToolbar';
import { PlanFilters } from '../plan/types';

import ContentToolbar from './components/toolbar';
import { SettingsData } from './types';
import DownloadDrawer from './components/downloadDrawer';
import useExport from './hooks/useExport';

import './styles.scss';

function ProjectContentPage() {
  const [treeData, setTreeData] = useState<NodeModel<CustomData>[]>([]);
  const [snapshot, setSnapshot] = useState<NodeModel<CustomData>[]>([]);
  const [settings, setSettings] = useState<SettingsData>(initSettings);

  const [loading, setLoading] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [download, setDownload] = useBoolean(false);
  const filters = usePageFilters();
  const { id } = useParams();

  const ref = useRef<TreeMethods>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const common = useCommonContext();
  const elementId = searchParams.get('elementId');

  const exportInstance = useExport({ treeData });

  const onOpenParentElements = useCallback(
    (childId: string) => {
      const parents = getParents(treeData, Number(childId));
      if (parents) {
        parents.forEach((p, index) => {
          setTimeout(() => ref.current?.open(Number(p.id)), 100 + index * 50);
        });
      }
    },
    [treeData]
  );
  const callbackUnmountModal = useCallback(() => {
    if (elementId) {
      onOpenParentElements(elementId);
    }

    searchParams.delete('elementId');
    setSearchParams(searchParams);
  }, [elementId, onOpenParentElements, searchParams, setSearchParams]);

  const onPreview = useCallback(() => {
    const node = treeData.find((t) => Number(t.id) === Number(elementId));

    if (node) {
      modalService.openCoursePreview({
        node,
        count: getIndex(treeData, node?.id, node?.parent, '', false),
        projectId: id || '',
        onCloseCallback: callbackUnmountModal,
      });
    }
  }, [callbackUnmountModal, elementId, id, treeData]);

  const filterParams: PlanFilters = {
    searchText: filters.text,
    statusIds: filters.statuses,
    artefactTypeIds: arrStrToArrNum(filters.types),
  };
  // Queries
  const { learningObjectsExist } = useProject(Number(id), 'cache-first');
  const { learningObjects, learningObjectsLoading, learningObjectsRefetch, hasFirstLoad } =
    useLearningObjectsForProject(Number(id), filterParams);
  useCompanyLearningObjectTypes('cache-and-network');

  // Effects
  useEffect(() => {
    setTreeData(addExtraData(learningObjects));
    setSnapshot(learningObjects);
  }, [learningObjects]);

  useEffect(() => {
    if (elementId && !isEmpty(treeData)) {
      onPreview();
    }
  }, [elementId, onPreview, treeData]);

  // Actions
  const onCloseExportDrawer = useCallback(() => {
    exportInstance.setSelected([]);
    setDownload.off();
  }, [exportInstance, setDownload]);

  const onClickDownload = useCallback(() => {
    if (isEmpty(exportInstance.selected)) {
      const temp = treeData
        ?.filter((node) => node?.data?.learningObjectType && node.data.objectState === StateType.DONE)
        .map((node) => node.id.toString());
      exportInstance.setSelected(temp);
      setDownload.on();
    } else {
      setDownload.on();
      exportInstance.setElements({
        ...exportInstance.elements,
        structure: {
          ...exportInstance.elements.structure,
          selected: false,
        },
      });
    }
  }, [exportInstance, setDownload, treeData]);

  const onCloseAll = () => ref?.current?.closeAll();
  const onOpenAll = () => ref?.current?.openAll();

  const onHandleDrop = (newTree: NodeModel<CustomData>[], { dragSourceId }: any) => {
    const updateTree = newTree.map((el) => {
      if (el.id === dragSourceId) {
        return {
          ...el,
          data: {
            ...el.data,
            is_moved: true,
          },
        };
      }
      return el;
    });
    setTreeData(addExtraData(updateTree, edit));
  };

  const onCanDrop = (tree: NodeModel<CustomData>[], { dragSourceId, dropTargetId, dragSource, dropTarget }: any) => {
    const depthCurrent = getDepth(tree, dragSourceId);
    const depthTarget = getDepth(tree, dropTargetId);

    if (depthCurrent === 3 && depthTarget == 2) {
      return true;
    }

    if (depthCurrent === 2 && depthTarget == 1) {
      return true;
    }

    if (depthCurrent === 1 && depthTarget === 1) {
      return false;
    }

    if (dragSource?.parent === dropTargetId) {
      return true;
    }

    if (depthCurrent === depthTarget) {
      return false;
    }

    return depthCurrent === depthTarget;
  };

  const onCopy = (nodeId: NodeModel['id']) => {
    const lastId = getLastId(treeData);
    const targetNode = treeData.find((n) => n.id === nodeId);

    if (!targetNode) {
      return;
    }

    const descendants = getDescendants(treeData, id || '');
    const partialTree = descendants.map((node: NodeModel<CustomData>) => ({
      ...node,
      id: Number(node.id) + Number(lastId),
      parent: Number(node.parent) + Number(lastId),
    }));

    const arr = [
      ...treeData,
      { ...targetNode, id: Number(targetNode.id) + Number(lastId), data: { ...targetNode.data, is_added: true } },
      ...partialTree,
    ];

    setTreeData(addExtraData(arr, edit));
  };

  const onSubmitEditSection = (values: EditSectionDraft, nodeId?: number | string, parentId?: number | string) => {
    const lastId = Number(getLastId(treeData)) + 1;
    const payload = {
      id: nodeId || lastId,
      parent: parentId || 0,
      droppable: true,
      text: values.title,
      data: {
        description: values.description || '',
        learningOutcomes: values.learningOutcomes || '',
        is_show: true,
        is_added: !nodeId,
        is_changed: !!nodeId,
        learningStructureType: values.learningStructureType,
        learningObjectType: {
          ...values.learningObjectType,
        },
      },
    };

    const newTree = () => {
      if (nodeId) {
        return treeData.map((el) => {
          if (el.id === nodeId) {
            return payload;
          }
          return el;
        });
      } else {
        return [...treeData, payload];
      }
    };

    const temp = newTree();

    setTreeData(addExtraData(temp, edit));
  };

  const onDelete = useCallback(
    (nodeId: NodeModel['id']) => {
      const deleteIds = [nodeId, ...getDescendants(treeData, nodeId).map((node) => node.id)];
      const newTree = treeData.map((node) => {
        if (deleteIds.includes(node.id)) {
          return {
            ...node,
            data: {
              ...node.data,
              is_deleted: true,
            },
          };
        }
        return node;
      });
      setTreeData(addExtraData(newTree, edit));
    },
    [edit, treeData]
  );

  const onStartEdit = () => {
    setEdit(true);
    if (isEmpty(treeData)) {
      setTreeData([
        {
          id: 100500700,
          parent: 0,
          droppable: false,
          text: '',
          data: {
            is_extra: true,
            artefactTypeId: null,
          },
        },
      ]);
    } else {
      setTreeData(addExtraData(treeData, true));
    }
  };

  const onUndoEdit = () => {
    setTreeData(snapshot);
    setEdit(false);
  };

  const onChangeSettings = (e: CheckboxChangeEvent) => {
    const { name = '', checked } = e.target;
    setSettings({ ...settings, [name]: checked });
  };

  const callBackAfterSave = useCallback(
    (cb: () => void, result: boolean) => {
      learningObjectsRefetch().then(() => {
        setLoading(false);
        setEdit(false);
        cb();
        if (result) {
          message.info(common?.t<string>('modals.changesSaved'), 2.5);
        } else {
          message.error(common?.t<string>('modals.changesNotSaved'), messageDurability);
        }
      });
    },
    [common, learningObjectsRefetch]
  );

  const saveTree = async () => {
    setLoading(true);
    const hide = message.loading(common?.t<string>('common.savingChanges'), 0);

    const payload = getPayloadBySaveContentTree(treeData);
    await updateProjectLOAll(Number(id), payload)
      .then(() => {
        callBackAfterSave(hide, true);
      })
      .catch(() => {
        callBackAfterSave(hide, false);
      });
  };

  const renderContent = useMemo(() => {
    if (!hasFirstLoad) {
      return <SkeletonPlan />;
    }
    if (!learningObjectsExist && hasFirstLoad && !learningObjects?.length && !edit) {
      return <CourseImport projectId={Number(id)} learningObjectsRefetch={learningObjectsRefetch} />;
    }
    if (learningObjectsExist && hasFirstLoad && !learningObjects?.length && !edit) {
      return <EmptyData />;
    }
    return (
      <ProjectContentTree
        anchorTree={ref}
        treeData={filteredNodeTree(treeData)}
        selected={exportInstance.selected}
        settings={settings}
        isEdit={edit}
        onHandleDrop={onHandleDrop}
        onCanDrop={onCanDrop}
        onCopy={onCopy}
        onSubmitEditSection={onSubmitEditSection}
        onDelete={onDelete}
        setSearchParams={setSearchParams}
        onToggleCheckbox={exportInstance.onToggleCheckbox}
      />
    );
  }, [
    edit,
    exportInstance.onToggleCheckbox,
    exportInstance.selected,
    hasFirstLoad,
    id,
    learningObjects?.length,
    learningObjectsExist,
    learningObjectsRefetch,
    onCopy,
    onDelete,
    onHandleDrop,
    onSubmitEditSection,
    setSearchParams,
    settings,
    treeData,
  ]);

  return (
    <>
      <Block empty hidden={hasFirstLoad}>
        <SkeletonToolbar />
      </Block>
      <Block empty hidden={!hasFirstLoad}>
        <ContentToolbar
          courseEmpty={!learningObjectsExist}
          settings={settings}
          isEdit={edit}
          onChangeSettings={onChangeSettings}
          onCloseAll={onCloseAll}
          onOpenAll={onOpenAll}
          onStartEdit={onStartEdit}
          onUndoEdit={onUndoEdit}
          onSave={saveTree}
          onDownload={onClickDownload}
        />
      </Block>

      <div className="project-page-content__content project-page-content">{renderContent}</div>
      <DownloadDrawer visible={download} exportInstance={exportInstance} onClose={onCloseExportDrawer} />
      <LoadingOverlay
        className="plan-loading-overlay"
        show={hasFirstLoad && learningObjectsLoading}
        text={common.t<string>('common.loadingData')}
      />
    </>
  );
}

export default ProjectContentPage;
