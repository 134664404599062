import React, { memo } from 'react';

import LoadMore from 'components/loadMore/LoadMore';

import { ProjectTaskItem } from 'hooks';

import ProjectTableHead from './ProjectTableHead';
import ProjectTableRow from './ProjectTableRow';

import './styles.scss';

type ProjectTableProps = {
  hasNextPage?: boolean;
  data: ProjectTaskItem[] | null;
  loading?: boolean;
  onLoadMore: () => void;
};

function ProjectTable({ data, hasNextPage, loading, onLoadMore }: ProjectTableProps) {
  return (
    <div className="project-table">
      <ProjectTableHead />
      {data?.map((elem) => {
        return <ProjectTableRow key={elem.id} item={elem} />;
      })}
      <LoadMore hasNextPage={hasNextPage} loading={loading} onFetch={onLoadMore} />
    </div>
  );
}

export default memo(ProjectTable);
