import React from 'react';

import ProjectTable from 'pages/components/projectTable';
import { useParams } from 'react-router-dom';

import Block from 'components/block/Block';
import LoadingOverlay from 'components/loadingOverlay';

import useDashboard from 'hooks/queries/useDashboard';
import useCommonContext from 'hooks/useCommonContext';

import SkeletonToolbar from '../../components/skeletons/SkeletonToolbar';
import SkeletonPlan from '../../components/skeletons/SkeletonPlan';

import PlanToolbar from './components/toolbar';
import usePlanFilter from './usePlanFilter';

function PlanTable() {
  const { filterParams } = usePlanFilter();

  const { id } = useParams();
  const common = useCommonContext();

  const { tasks, tasksLoading, tasksHasNextPage, tasksLoadMore, hasFirstLoad } = useDashboard({
    projectIds: [Number(id)],
    filter: filterParams,
  });

  console.log(tasksLoading);

  return (
    <>
      <Block empty hidden={hasFirstLoad}>
        <SkeletonToolbar />
      </Block>
      <Block empty hidden={!hasFirstLoad}>
        <PlanToolbar />
      </Block>
      <div className="project-page-content project-page-plan">
        {!hasFirstLoad ? (
          <SkeletonPlan />
        ) : (
          <ProjectTable loading={tasksLoading} hasNextPage={tasksHasNextPage} data={tasks} onLoadMore={tasksLoadMore} />
        )}
      </div>
      <LoadingOverlay
        className="plan-loading-overlay"
        show={hasFirstLoad && tasksLoading}
        text={common.t<string>('common.loadingData')}
      />
    </>
  );
}

export default PlanTable;
