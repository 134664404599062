import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { DefaultOptionType } from 'rc-select/lib/Select';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { getDescendants, NodeModel } from '@minoru/react-dnd-treeview';
import { isEmpty, isEqual } from 'lodash';
import { onlyUnique } from 'utils';
import { StateType } from 'types/entities';
import { AdminCompanyLearningObjectType } from 'types';

import {
  structureCourseOptions,
  textCourseOptions,
  videoResolutionOptions,
} from '../components/downloadDrawer/constants';
import { CustomData } from '../../../components/projectContentTree/types';

export type ExportElement = {
  selected: boolean;
  radio?: string;
  value?: DefaultOptionType;
};

export type ExportElements = {
  structure: ExportElement;
  video: ExportElement;
  addMaterials: ExportElement;
  tasks: ExportElement;
};

const defaultValue = {
  structure: {
    selected: true,
    radio: structureCourseOptions[0].value,
  },
  video: {
    selected: false,
    value: videoResolutionOptions[2],
  },
  addMaterials: {
    selected: false,
    radio: textCourseOptions[0].value,
  },
  tasks: {
    selected: false,
    radio: textCourseOptions[0].value,
  },
};

export type UseExportOutput = {
  treeData: NodeModel<CustomData>[];
  elements: ExportElements;
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  setElements: Dispatch<SetStateAction<ExportElements>>;
  learningObjectSelected: {
    video: NodeModel<CustomData>[];
    text: NodeModel<CustomData>[];
    task: NodeModel<CustomData>[];
  };
  learningObjectDone: {
    video: NodeModel<CustomData>[];
    text: NodeModel<CustomData>[];
    task: NodeModel<CustomData>[];
  };
  onToggleCheckbox: (elemId: number) => void;
  onChangeSelectItem: (name: string, e: CheckboxChangeEvent) => void;
  onChangeTextType: (name: string, value: string) => void;
  onChangeVideoResolution: (value: string, option: any) => void;
};

type UseExportProps = {
  treeData: NodeModel<CustomData>[];
};

const getSelectedElementsByType = (
  tree: NodeModel<CustomData>[],
  selected: string[],
  type: AdminCompanyLearningObjectType.type
) => {
  return tree
    .filter((node) => node?.data?.learningObjectType?.type === type)
    .filter((node) => selected.some((s) => Number(node.id) === Number(s)));
};

const getElementsDoneByType = (tree: NodeModel<CustomData>[], type: AdminCompanyLearningObjectType.type) => {
  return tree
    .filter((node) => node?.data?.learningObjectType && node?.data?.objectState === StateType.DONE)
    .filter((node) => node?.data?.learningObjectType?.type === type);
};

const nameToType = (name: string) => {
  if (name === 'video') {
    return AdminCompanyLearningObjectType.type.VIDEO;
  }
  if (name === 'addMaterials') {
    return AdminCompanyLearningObjectType.type.TEXT;
  }
  return AdminCompanyLearningObjectType.type.TASK;
};

function useExport({ treeData }: UseExportProps) {
  const [elements, setElements] = useState<ExportElements>(defaultValue);
  const [selected, setSelected] = useState<string[]>([]);

  //total items with status DONE
  const learningObjectDone = useMemo(() => {
    return {
      video: getElementsDoneByType(treeData, AdminCompanyLearningObjectType.type.VIDEO),
      text: getElementsDoneByType(treeData, AdminCompanyLearningObjectType.type.TEXT),
      task: getElementsDoneByType(treeData, AdminCompanyLearningObjectType.type.TASK),
    };
  }, [treeData]);

  //total items selected
  const learningObjectSelected = useMemo(() => {
    return {
      video: getSelectedElementsByType(treeData, selected, AdminCompanyLearningObjectType.type.VIDEO),
      text: getSelectedElementsByType(treeData, selected, AdminCompanyLearningObjectType.type.TEXT),
      task: getSelectedElementsByType(treeData, selected, AdminCompanyLearningObjectType.type.TASK),
    };
  }, [selected, treeData]);

  useEffect(() => {
    setElements({
      structure: {
        selected: elements.structure.selected,
        // !!learningObjectSelected.video.length ||
        // !!learningObjectSelected.text.length ||
        // !!learningObjectSelected.task.length,
        radio: elements.structure.radio,
      },
      video: {
        selected: !!learningObjectSelected.video.length,
        value: elements.video.value,
      },
      addMaterials: {
        selected: !!learningObjectSelected.text.length,
        radio: elements.addMaterials.radio,
      },
      tasks: {
        selected: !!learningObjectSelected.task.length,
        radio: elements.tasks.radio,
      },
    });
  }, [
    elements.addMaterials.radio,
    elements.structure.radio,
    elements.structure.selected,
    elements.tasks.radio,
    elements.video.value,
    learningObjectSelected.task.length,
    learningObjectSelected.text.length,
    learningObjectSelected.video.length,
  ]);

  const onToggleCheckbox = useCallback(
    (elemId: number) => {
      const node = treeData.find((t) => Number(t.id) === elemId);

      if (node?.data?.learningStructureType === 'production_item') {
        const hasId = !!selected.find((t) => Number(t) === elemId);
        if (hasId) {
          const temp = selected.filter((s) => Number(s) !== elemId);
          setSelected(temp);
        } else {
          setSelected([...selected, elemId.toString()]);
        }
      } else {
        const descendants: NodeModel<CustomData>[] = getDescendants(treeData, elemId);
        const elemDescendants = descendants.filter((d) => d?.data?.learningStructureType === 'production_item');
        const allDescendantsSelected =
          elemDescendants.every((d) => selected.includes(d.id.toString())) && !isEmpty(descendants);

        if (allDescendantsSelected) {
          const temp = selected.filter((s) => !elemDescendants.some((d) => Number(d.id) === Number(s)));
          setSelected(temp);
        } else {
          const descendantNotSelected = elemDescendants.filter((d) => !selected.some((s) => d.id.toString() === s));
          if (isEqual(elemDescendants, descendantNotSelected)) {
            const descendantNotSelectedDone = descendantNotSelected.filter(
              (d) => d.data?.objectState === StateType.DONE
            );
            const temp = descendantNotSelectedDone.map((n) => n.id.toString());
            setSelected([...selected, ...temp]);
          } else {
            const temp = selected.filter((s) => !elemDescendants.some((d) => Number(d.id) === Number(s)));
            setSelected(temp);
          }
        }
      }
    },
    [selected, treeData]
  );

  const onToggleElementsByName = (value: boolean, name: string) => {
    const type = nameToType(name);
    const temp = treeData
      .filter((node) => node?.data?.learningObjectType && node.data.objectState === StateType.DONE)
      .filter((node) => node.data?.learningObjectType?.type === type)
      .map((node) => node.id.toString());

    if (value) {
      const merge = [...selected, ...temp];
      setSelected(merge.filter(onlyUnique));
    } else {
      const filtered = selected.filter((s) => !temp.some((t) => t === s));
      setSelected(filtered);
    }
  };

  const onChangeSelectItem = (name: string, e: CheckboxChangeEvent) => {
    if (name !== 'structure') {
      onToggleElementsByName(e.target.checked, name);
    }

    setElements({
      ...elements,
      [name]: {
        ...elements[name as keyof ExportElements],
        selected: e.target.checked,
      },
    });
  };

  const onChangeTextType = (name: string, value: string) => {
    setElements({
      ...elements,
      [name]: {
        ...elements[name as keyof ExportElements],
        radio: value,
      },
    });
  };

  const onChangeVideoResolution = (value: string, option: any) => {
    setElements({
      ...elements,
      video: {
        ...elements.video,
        value: option,
      },
    });
  };

  return {
    treeData,
    elements,
    selected,
    setElements,
    setSelected,
    learningObjectDone,
    learningObjectSelected,
    onToggleCheckbox,
    onChangeSelectItem,
    onChangeTextType,
    onChangeVideoResolution,
  };
}

export default useExport;
