import React, { useCallback, useState } from 'react';

import { cx } from 'utils';
import { PlusOutlined } from '@ant-design/icons';
import { ProjectLearningStructureEnum } from 'types/entities';

import Block from 'components/block/Block';
import Button from 'components/button/Button';
import { ButtonTypesEnum } from 'components/button/types';

import useCommonContext from 'hooks/useCommonContext';
import useCompanyLearningObjectTypes from 'hooks/queries/useCompanyLearningObjectTypes';

import EditSection from '../editSection/EditSection';
import ElementButton from '../elementButton';
import ElementsDropdown from '../elementsDropdown';
import { LearningObjectType } from '../../types';

import { AddSectionProps } from './types';

import './styles.scss';
import { AdminCompanyLearningObjectType } from '../../../../../../types';

function AddSection({ className, counter, isEdit, parentId, depth, learningStructureType, onSubmit }: AddSectionProps) {
  const [show, setShow] = useState<boolean>(false);
  const [loType, setLOType] = useState<LearningObjectType | null>(null);
  const [isElem, setIsElem] = useState<boolean>(false);

  const { companyLearningObjectTypes } = useCompanyLearningObjectTypes('cache-only');

  const common = useCommonContext();

  const classNames = cx('ccm-tree-add-section', className, {
    [`ccm-tree-add-section--${depth}`]: depth,
  });

  const onShowForm = () => {
    setIsElem(false);
    setShow(true);
  };

  const onCloseForm = () => {
    setIsElem(false);
    setShow(false);
  };

  const handleClickElement = useCallback(
    (artefactId: number, artefactType: AdminCompanyLearningObjectType.type, name: string, iconImage: string | null) => {
      setLOType({
        id: artefactId,
        name,
        icon_image: iconImage,
        type: artefactType,
      });
      setIsElem(true);
      setShow(true);
    },
    []
  );

  if (!isEdit) {
    return null;
  }

  const firstElements = companyLearningObjectTypes?.slice(0, 3);
  const secondElements = companyLearningObjectTypes?.slice(3);

  const groupContent = () => {
    switch (learningStructureType) {
      case ProjectLearningStructureEnum.group:
        return (
          <Block hidden={show} className="ccm-tree-add-section--main">
            <Button size="large" type={ButtonTypesEnum.default} icon={<PlusOutlined />} onClick={onShowForm}>
              {common.t<string>('common.addSection')}
            </Button>
          </Block>
        );
      case ProjectLearningStructureEnum.production_item:
        return (
          <Block
            hidden={show}
            className="ccm-tree-add-section--main ccm-tree-add-section--element flex-justify-content--center"
          >
            <span className="ccm-tree-add-section--main-text">{common.t<string>('common.add')}:</span>
            {firstElements?.map((art) => {
              return <ElementButton key={art.id} item={art} callback={handleClickElement} />;
            })}
            <ElementsDropdown data={secondElements} action={handleClickElement} />
          </Block>
        );
      default:
        return (
          <>
            <Block hidden={show} className="ccm-tree-add-section--main">
              <Button size="large" type={ButtonTypesEnum.default} icon={<PlusOutlined />} onClick={onShowForm}>
                {common.t<string>('common.addSection')}
              </Button>
            </Block>
            <Block
              hidden={show}
              className="ccm-tree-add-section--main ccm-tree-add-section--element flex-justify-content--center"
            >
              <span className="ccm-tree-add-section--main-text">{common.t<string>('common.add')}:</span>
              {firstElements?.map((art) => {
                return <ElementButton key={art.id} item={art} callback={handleClickElement} />;
              })}
              <ElementsDropdown data={secondElements} action={handleClickElement} />
            </Block>
          </>
        );
    }
  };

  return (
    <div className={classNames}>
      {groupContent()}
      <Block empty hidden={!show}>
        <EditSection
          isElement={isElem}
          count={counter}
          learningObjectType={loType}
          parentId={parentId}
          onCancel={onCloseForm}
          onSubmit={onSubmit}
        />
      </Block>
    </div>
  );
}

export default AddSection;
