import React from 'react';

import { Form, message } from 'antd';
import { cx } from 'utils';
import withCommonProps from 'hocs/withCommonProps';
import { noop } from 'lodash';
import { TemplateCommonProps } from 'common/commonPropsProvider';

import HeaderModal from 'components/modal/components/header';
import FooterModal from 'components/modal/components/footer';
import TextField from 'components/textField';
import Select from 'components/select';

import { IBusinessRole, InvitePayload } from 'store/users/interfaces';
import { getSystemRoles } from 'store/users/constants';

import styles from './unvite.module.scss';

type InviteUserProps = {
  onCancel: () => void;
  business_roles: IBusinessRole[] | null;
  onInviteUserRequest: (payload: InvitePayload) => void;
  companySlugId: string;
  onGetUsersRequest: (id: string) => void;
};

const InviteUser = ({
  onCancel,
  business_roles,
  onInviteUserRequest,
  companySlugId,
  onGetUsersRequest,
  common,
}: InviteUserProps & TemplateCommonProps) => {
  const onSubmit = async (values: InvitePayload) => {
    const payload: InvitePayload = {
      email: values?.email,
      company_slug: companySlugId,
      technical_role: values?.technical_role,
      business_roles: values?.business_roles,
    };
    await onInviteUserRequest(payload);
    await onGetUsersRequest(companySlugId);
    await onCancel();
    message.success(`${common.t<string>('modals.successInvited')} ${values?.email}`);
  };
  const businessRolesRemapped = business_roles?.map((role: IBusinessRole) => {
    return { ...role, value: role.id };
  });

  const classNames = cx('ccm-modal--wrapper', styles.container);

  return (
    <div className={classNames}>
      <HeaderModal title={common.t<string>('common.user')} onClose={onCancel} className={styles.header} />
      <div className={styles.content}>
        <Form className="flex-col" onFinish={onSubmit}>
          <TextField
            name="email"
            label={common.t<string>('form.field.email.label')}
            placeholder={common.t<string>('form.field.email.placeholder')}
            rules={[{ required: true, type: 'email', message: common.t<string>('errors.required.email') }]}
          />
          <Select
            options={getSystemRoles(common.t)}
            placeholder={common.t<string>('common.systemRole')}
            name="technical_role"
            label={common.t<string>('common.systemRole')}
            rules={[{ required: true, message: common.t<string>('errors.required.systemRole') }]}
          />
          <Select
            options={businessRolesRemapped}
            placeholder={common.t<string>('common.businessRole')}
            name="business_roles"
            label={common.t<string>('common.businessRole')}
            mode="multiple"
            rules={[{ required: true, message: common.t<string>('errors.required.systemRole') }]}
          />
          <FooterModal
            labelCancel={common.t<string>('modals.cancel')}
            labelSubmit={common.t<string>('modals.sendInvite')}
            onCancel={onCancel}
            onSubmit={noop}
            isForm
          />
        </Form>
      </div>
    </div>
  );
};

export default withCommonProps(InviteUser);
