import React, { memo, SyntheticEvent } from 'react';

import { cx } from 'utils';
import { ArtefactContentVideoFoundIssue } from 'types/entities/artefact';

import './styles.scss';

type ErrorMarkProps = {
  item: ArtefactContentVideoFoundIssue;
  progressWidth: number;
  duration: number;
  start?: number | null;
  end?: number | null;
  isActive: boolean;
  onSelectedIssue: (value: any) => void;
  onSeekChange: (value: number) => void;
};

const getStyles = (duration: number, progressWidth: number, start?: number | null, end?: number | null) => {
  const positionStart = ((start || 0) * 100) / duration;
  const positionEnd = ((end || 0) * 100) / duration;

  if (start && end && start !== end) {
    const widthPercent = positionEnd - positionStart;
    return {
      left: (progressWidth * positionStart) / 100,
      width: `${widthPercent}%`,
    };
  }
  return {
    left: (progressWidth * positionStart) / 100,
  };
};

function ErrorMark({
  item,
  isActive,
  progressWidth,
  duration,
  start = 0,
  end = 0,
  onSelectedIssue,
  onSeekChange,
}: ErrorMarkProps) {
  const classNames = cx('error-mark', {
    'error-mark__long': end && start !== end,
    'error-mark--active': isActive,
    'testclass-video-timeline-error': !isActive,
    'testclass-video-timeline-error-active': isActive,
  });

  if (!start) {
    return null;
  }

  const onMouseDown = (e: SyntheticEvent<HTMLSpanElement>) => e.stopPropagation();

  const onHandleClick = () => {
    const value = (start * 100) / duration / 100;
    onSeekChange(value);
    onSelectedIssue(item);
  };

  return (
    <span
      style={getStyles(duration, progressWidth, start, end)}
      className={classNames}
      onMouseDown={onMouseDown}
      onClick={onHandleClick}
    />
  );
}

export default memo(ErrorMark);
