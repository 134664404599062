import React, { memo, useCallback } from 'react';

import { cx } from 'utils';
import { UserBase } from 'types/entities';
import { DeleteFilled, EditFilled, LinkOutlined, MoreOutlined } from '@ant-design/icons';

import AvatarWithInfo from 'components/avatarWithInfo/AvatarWithInfo';
import { ButtonTypesEnum } from 'components/button/types';
import Button from 'components/button/Button';
import Dropdown from 'components/dropdown';
import { MenuInfo } from 'components/dropdownMenu/types';
import Block from 'components/block';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

type FreeTaskDiscussionHeadProps = {
  canIEdit: boolean;
  hideMore?: boolean;
  user?: UserBase;
  createdAt?: string;
  commentId: string;
  onClickMenu?: (info: MenuInfo, commentId: string) => void;
};

function SeparateTaskDiscussionHead({
  canIEdit,
  hideMore,
  user,
  createdAt,
  commentId,
  onClickMenu,
}: FreeTaskDiscussionHeadProps) {
  const common = useCommonContext();
  const classNames = cx('ft-discussion-head');

  const itemsFull = [
    {
      key: 'edit',
      label: common.t<string>('comments.edit'),
      icon: <EditFilled />,
      type: 'EDIT',
    },
    {
      key: 'link',
      label: common.t<string>('comments.copyLink'),
      icon: <LinkOutlined />,
      type: 'LINK',
    },
    {
      key: 'remove',
      label: common.t<string>('comments.delete'),
      icon: <DeleteFilled />,
      className: 'danger',
    },
  ];

  const itemsLink = [
    {
      key: 'link',
      label: common.t<string>('comments.copyLink'),
      icon: <LinkOutlined />,
      type: 'LINK',
    },
  ];

  const onClick = useCallback(
    (info: MenuInfo) => {
      onClickMenu?.(info, commentId);
    },
    [commentId, onClickMenu]
  );

  return (
    <div className={classNames}>
      <AvatarWithInfo user={user} createdAt={createdAt} />
      <Block hidden={hideMore && !canIEdit} className="ft-discussion-head__actions">
        <Dropdown hasArrow={false} menu={{ items: canIEdit ? itemsFull : itemsLink, onClick: onClick }}>
          <Button className="btn-more" size="large" type={ButtonTypesEnum.text} icon={<MoreOutlined />} />
        </Dropdown>
      </Block>
    </div>
  );
}

export default memo(SeparateTaskDiscussionHead);
