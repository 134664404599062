import React, { memo } from 'react';

import { FileZipOutlined } from '@ant-design/icons';

import './styles.scss';

function FormatView() {
  return (
    <div className="download-drawer-format-view">
      <FileZipOutlined />
      <span className="download-drawer-format-view__text">zip</span>
    </div>
  );
}
export default memo(FormatView);
