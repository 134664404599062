import { gql } from '@apollo/client';

export const GET_PROJECT_USER_INFO = gql`
  query User_role($projectId: Int, $userId: Int) {
    project_user(project_id: $projectId, user_id: $userId) {
      id
      roles {
        role {
          id
          name
          role
        }
      }
      company_user {
        id
        user {
          id
          first_name
          last_name
          email
        }
      }
    }
  }
`;
