/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ExportTaskParams = {
    file_extension?: ExportTaskParams.file_extension;
    export_task: boolean;
};

export namespace ExportTaskParams {

    export enum file_extension {
        PDF = 'pdf',
        DOCX = 'docx',
    }


}
