/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ProjectAsyncTask = {
    readonly id?: number;
    readonly project_id?: string;
    task_type: ProjectAsyncTask.task_type;
    state?: ProjectAsyncTask.state;
    state_message?: string;
    data?: any;
};

export namespace ProjectAsyncTask {

    export enum task_type {
        COURSE_LEARNING_OBJECT_IMPORT = 'course_learning_object_import',
        COURSE_LEARNING_OBJECT_CLEAN = 'course_learning_object_clean',
    }

    export enum state {
        NEW = 'new',
        IN_PROGRESS = 'in_progress',
        DONE = 'done',
        CANCELLED = 'cancelled',
        REJECTED = 'rejected',
    }


}
