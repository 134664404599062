import { CcmUserState } from '../../schemas/types';

import { CCMUser, UserRole } from './user';
import { Company, CompanyStateListVersion } from './company';

export enum ProjectTypeEnum {
  contentCreation = 'CONTENT_CREATION',
  translate = 'TRANSLATE ',
}

export interface Project {
  id: string;
  name: string;
  company: Company;
  project_type: ProjectTypeEnum;
  project_task_state_list_version: CompanyStateListVersion;
}

export interface ProjectUser {
  id: string;
  project: Project;
  user: CCMUser;
  role: UserRole;
  is_external_company: boolean;
  company: Company;
  is_active: boolean;
}

export interface ProjectState {
  id: string;
  name: string;
  name_after_submit: string;
  state_type: CcmUserState;
  order: number;
}

export interface ProjectPhaseState {
  id: string;
  name: string;
  name_after_submit: string;
  state_type: CcmUserState;
  order: number;
  icon_image: string;
}
