import React, { memo, SyntheticEvent, useCallback, useState } from 'react';

import { HistoryOutlined } from '@ant-design/icons';

import Button from 'components/button';
import Popover from 'components/popover';
import { ButtonTypesEnum } from 'components/button/types';

import useCommonContext from 'hooks/useCommonContext';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import useLazyFinalArtefactPipeline from 'hooks/queries/useLazyFinalArtefactPipeline';
import useProjectUsers from 'hooks/queries/useProjectUsers';

import ProjectStatusChangedPopupContent from './ProjectStatusChangedPopupContent';

import '../styles.scss';

type HistoryPopupProps = {
  projectId?: string;
  projectTaskId?: string;
  text?: string;
  isSeparate: boolean;
};

function ProjectStatusChangedPopup({ isSeparate, projectId, projectTaskId, text }: HistoryPopupProps) {
  const [opened, setOpened] = useState<boolean>(false);
  useLockBodyScroll(opened);
  const common = useCommonContext();

  const { projectUsers } = useProjectUsers(projectId);
  const { getFinalArtefactPipeline, allProjectTaskStateHistory, allProjectTaskStateHistoryLoading } =
    useLazyFinalArtefactPipeline({
      projectTaskId: Number(projectTaskId),
    });

  const handleOpened = useCallback((open: boolean) => setOpened(open), []);

  const onHandleClick = (e: SyntheticEvent) => {
    getFinalArtefactPipeline();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  return (
    <div className="ccm-popover-history" onClick={onHandleClick}>
      <Popover
        trigger="click"
        placement="left"
        title={common.t<string>('common.statusHistoryChanges')}
        onOpenChange={handleOpened}
        content={
          <ProjectStatusChangedPopupContent
            data={allProjectTaskStateHistory}
            projectUsers={projectUsers}
            loading={allProjectTaskStateHistoryLoading}
            isSeparate={isSeparate}
          />
        }
      >
        {text && <span>{text}</span>}
        <Button size="small" type={ButtonTypesEnum.link} icon={<HistoryOutlined />} />
      </Popover>
    </div>
  );
}

export default memo(ProjectStatusChangedPopup);
