import { gql } from '@apollo/client';

import { COMPANY_TYPE, LAST_STATE, PAGE_INFO, SEPARATE_TASK, USER_ROLE_TYPE, USER_TYPE } from './fragments';

export const GET_DASHBOARD = gql`
  query Dashboard(
    $project_id: Int
    $project_ids: [Int]
    $user_id: Int
    $last_state_ids: [Int]
    $artefact_types: [Int]
    $task_types: [String]
    $learning_object_name: String #    $company_id: Int
    $artefact_type_names: [String]
    $last_state_names: [String]
    $offset: Int
    $first: Int
    $artefact_production_deadline_filter: DateFilter
    $artefact_last_iteration_deadline_filter: DateFilter
    $is_current_executor: Boolean
    $is_supervisor: Boolean
    $is_course_plan: Boolean
    $element_type_ids: [Int]
    $user_ids: [Int]
    $after: String
  ) {
    project_tasks(
      project_id: $project_id
      project_ids: $project_ids
      user_id: $user_id
      last_state_ids: $last_state_ids
      artefact_type_ids: $artefact_types
      learning_object_name: $learning_object_name
      task_types: $task_types
      last_state_names: $last_state_names
      artefact_type_names: $artefact_type_names
      offset: $offset
      first: $first
      artefact_production_deadline_filter: $artefact_production_deadline_filter
      artefact_last_iteration_deadline_filter: $artefact_last_iteration_deadline_filter
      is_current_executor: $is_current_executor
      is_supervisor: $is_supervisor
      element_type_ids: $element_type_ids
      user_ids: $user_ids
      is_course_plan: $is_course_plan
      after: $after
    ) {
      pageInfo {
        ...PageInfo
      }
      totalCount
      edges {
        cursor
        node {
          pk
          id
          name
          last_state_is_changed
          last_state_updated_at
          last_state {
            ...LastState
          }
          artefacts {
            id
            artefact_versions {
              file {
                file_type
              }
            }
            artefact_type {
              id
              name
              type
              icon_image
            }
          }
          project {
            pk
            id
          }
          last_state {
            id
            name
            state_type
            created_at
            updated_at
          }
          learning_object {
            id
            name
            learning_object_type {
              id
              name
              icon_image
            }
            parent {
              human_readable_number
            }
          }
          cached_linked_artefact {
            id
            artefact_type {
              id
              name
              type
              icon_image
            }
            cached_artefact_iteration_count
            first_iteration_deadline
            first_iteration_deadline_delay
            first_iteration_deadline_changed
            production_deadline
            production_deadline_delay
            production_deadline_changed
          }
          current_assignees {
            project_task {
              id
            }
            is_current_executor
            project_user {
              id
              company_user {
                company {
                  ...CompanyData
                }
                user {
                  ...UserData
                }
              }
              roles {
                id
                role {
                  ...RoleData
                }
              }
            }
          }
          active_artefacts {
            artefact_iteration_count
          }
          separate_task {
            ...SeparateTask
          }
        }
      }
    }
  }
  ${PAGE_INFO}
  ${USER_TYPE}
  ${USER_ROLE_TYPE}
  ${LAST_STATE}
  ${SEPARATE_TASK}
  ${COMPANY_TYPE}
`;
