import React, { useState } from 'react';

import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { RcFile } from 'antd/es/upload';

import AuthCard from '../../../components/authCard';
import TextField from '../../../../components/textField';
import { TextFieldTypeEnum } from '../../../../components/textField/types';
import Button from '../../../../components/button';
import { signupRequest } from '../../../../store/auth/actions';
import { SignupPayload } from '../../../../store/auth/interfaces';
import { PATH_NAMES } from '../../../../router/constants';
import history from '../../../../router/history/history';
import useTimezones from '../../../../hooks/queries/useTimezones';
import Select from '../../../../components/select/Select';
import { fileToDataUri } from '../../../../utils';
import useBoolean from '../../../../hooks/useBoolean';
import AvatarBlock from '../../../account/components/avatarBlock';
import '../../styles.scss';
import './styles.scss';

function SignupScreen({ onSignupRequest }: PropsFromRedux) {
  const [file, setFile] = useState<RcFile | undefined>(undefined);
  const [dataUri, setDataUri] = useState<any>(null);
  const [disabled, onDisabled] = useBoolean(true);
  const [loading, onLoading] = useBoolean(false);
  const { t } = useTranslation();
  const { timezones } = useTimezones();
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get('invite_code');
  const emailCode = searchParams.get('email');
  const companyType = searchParams.get('company_type');

  const onSubmit = async (values: SignupPayload) => {
    onLoading.on();
    const formData = new FormData();
    if (inviteCode) {
      if (file) {
        formData.append('icon_image', file as Blob);
      }
      if (values.name) {
        formData.append('name', values.name);
      }
      if (values.password) {
        formData.append('password', values.password);
      }
      if (emailCode) {
        formData.append('email', emailCode);
      }
      if (inviteCode) {
        formData.append('invite_code', inviteCode);
      }
      formData.append('timezone', 'US/Pacific');
      // if (values.timezone) {
      //
      // }
      const payload = {
        email: emailCode || '',
        password: values.password,
        first_name: values.first_name,
        last_name: values.last_name,
        name: values.name,
        timezone: 'US/Pacific',
        invite_code: inviteCode,
      };
      await onSignupRequest(payload, formData, companyType);
    }
    onLoading.off();
  };
  const onChangeUpload = (info: UploadChangeParam<UploadFile>) => {
    const f = info.file.originFileObj;
    setFile(f);
    if (f) {
      fileToDataUri(f).then((dataUrl) => {
        setDataUri(dataUrl);
        onDisabled.off();
      });
    }
  };
  const goToLogin = () => history.push(PATH_NAMES.auth.login);
  return (
    <div className="signup-layout flex fullSize flex-row">
      <div className="signup-layout__items">
        <AuthCard className="signup-page">
          <Form className="signup-page__form" onFinish={onSubmit} layout="vertical">
            <span className="signup-page__title mb_16">{t('pages.signup.title')}</span>
            <AvatarBlock onChangeUpload={onChangeUpload} loading={loading} file={dataUri} />
            <TextField
              name="name"
              size="xl"
              label={t('form.field.nameSurname.label')}
              placeholder={t('form.field.nameSurname.placeholder')}
              rules={[{ required: true, message: t('errors.required.firstName') }]}
            />
            {/*<Select*/}
            {/*  size="xl"*/}
            {/*  name="timezone"*/}
            {/*  label={t<string>('common.timezone')}*/}
            {/*  placeholder={t<string>('common.selectTimezone')}*/}
            {/*  options={timezones}*/}
            {/*/>*/}
            <TextField
              name="password"
              size="xl"
              label={t('form.field.password.label')}
              placeholder={t('form.field.password.placeholder')}
              type={TextFieldTypeEnum.password}
              rules={[
                { required: true, message: t('errors.required.password') },
                { min: 4, message: t('errors.required.passwordMinLength') },
              ]}
            />
            <TextField
              name="confirm_password"
              size="xl"
              label={t('form.field.confirmPassword.label')}
              placeholder={t('form.field.confirmPassword.placeholder')}
              type={TextFieldTypeEnum.password}
              rules={[
                {
                  required: true,
                  message: t('errors.required.passwordConfirm'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('errors.required.passwordNotMatch')));
                  },
                }),
              ]}
            />
            <Button className="button-login" htmlType="submit" size="xl">
              {t('pages.signup.button')}
            </Button>
          </Form>
        </AuthCard>
      </div>
      <div className="signup-login-page">
        <div className="flex flex-col">
          <span className="signup-login-page__title mb_24">{t('pages.signup.alreadyRegistered')}</span>
        </div>
        <div className="flex flex-col">
          <Button className="button-login" size="xl" onClick={goToLogin} loading={loading} disabled={disabled}>
            {t('pages.login.button')}
          </Button>
        </div>
      </div>
      <iframe id="msg-container" src="https://cob-dev.xpdev.net" style={{ display: 'none' }} />
    </div>
  );
}

const connector = connect(null, {
  onSignupRequest: signupRequest,
});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SignupScreen);
