import React, { useCallback, useEffect, useMemo } from 'react';

import { isEqual } from 'lodash';
import { useOutletContext, useParams } from 'react-router-dom';
import { Form, message } from 'antd';
import { onChangeProject } from 'api/requests/projects';
import { AbilityContext } from 'config/Can';
import AboutDescription from 'pages/project/components/aboutDescription';
import AboutSkeletons from 'pages/project/components/skeletons/AboutSkeletons';
import { useAbility } from '@casl/react';
import AddCourseForm from 'pages/addCourse/components/form';
import { getCurrentTimeOffset } from 'utils';
import dayjs from 'dayjs';

import Button from 'components/button/Button';
import { ButtonTypesEnum } from 'components/button/types';
import Block from 'components/block';
import StatusLabelFlat from 'components/statusLabelFlat';
import Tag from 'components/tag';
import { TagSizeEnum } from 'components/tag/types';

import { getStageImageByType } from 'utils/icons';

import { ProjectType } from 'store/projects/interfaces';

import useProject from 'hooks/queries/useProject';
import useCommonContext from 'hooks/useCommonContext';
import useCompanyUsersByRole from 'hooks/queries/useCompanyUsersByRole';
import useBoolean from 'hooks/useBoolean';

import { messageDurability } from '../../../../constants';

import './styles.scss';

function CourseAboutPage() {
  const { id } = useParams();
  const headerRefetch: () => Promise<any> = useOutletContext();
  const [form] = Form.useForm();

  const { projectData, projectRefetch, projectLoading } = useProject(Number(id), 'no-cache');
  const [loading, onLoading] = useBoolean(false);
  const [disabled, onDisabled] = useBoolean(true);

  const common = useCommonContext();

  const ability = useAbility(AbilityContext);
  const canEdit = ability.can('update', 'Project.about.form');

  const { allCompanyUsers } = useCompanyUsersByRole(common?.company?.id);

  const defaultValues = useMemo(() => {
    return {
      name: projectData?.name,
      specialization: projectData?.project_group?.id,
      production: [
        projectData?.production_start_date && dayjs(projectData?.production_start_date),
        projectData?.production_finish_date && dayjs(projectData?.production_finish_date),
      ],
      timeZone: projectData?.timezone,
      phase_state: projectData?.phase_state?.id,
      state: projectData?.state?.id,
      description: projectData?.description,
      timezone: projectData?.timezone,
    };
  }, [projectData]);

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [defaultValues, form]);

  const handleFormChange = useCallback(() => {
    const fields = form.getFieldsValue();
    onDisabled.setValue(isEqual(defaultValues, fields));
  }, [defaultValues, form, onDisabled]);

  const currentCompanyUser = useMemo(
    () => allCompanyUsers?.find((user: any) => Number(user?.user?.id) === common?.userId),
    [allCompanyUsers, common?.userId]
  );

  const resetToDefault = useCallback(() => {
    form.setFieldsValue(defaultValues);
    onDisabled.on();
  }, [defaultValues, form, onDisabled]);

  const onSaveProjectEditing = useCallback(async () => {
    const formData = form.getFieldsValue();

    onLoading.on();
    const payload = {
      name: formData.name,
      project_group_id: Number(formData.specialization),
      project_type: ProjectType?.content_creation,
      production_start_date: formData?.production?.[0] && formData?.production?.[0].format('YYYY-MM-DD'),
      production_finish_date: formData?.production?.[1] && formData?.production?.[1].format('YYYY-MM-DD'),
      company_id: common?.company?.id,
      phase_state_id: Number(formData.phase_state),
      state_id: Number(formData.state),
      description: formData.description,
      timezone: formData.timezone,
    };
    const projectInfoResponse = await onChangeProject(Number(id), payload);
    if (projectInfoResponse) {
      message.info(common.t<string>('modals.changesSaved'), 2.5);
      await headerRefetch();
      await projectRefetch().then(() => {
        onLoading.off();
        onDisabled.on();
      });
    } else {
      message.error(common.t<string>('common.saveError'), messageDurability);
      onLoading.off();
    }
  }, [common, form, headerRefetch, id, onDisabled, onLoading, projectRefetch]);

  const prodStart = projectData?.production_start_date
    ? dayjs(projectData?.production_start_date).format('DD MMM YYYY')
    : '';
  const prodEnd = projectData?.production_finish_date
    ? dayjs(projectData?.production_finish_date).format('DD MMM YYYY')
    : '';

  const productionRange = `${prodStart} - ${prodEnd}`;

  return (
    <div className="course-page-about">
      <Block empty hidden={projectData && currentCompanyUser}>
        <AboutSkeletons />
      </Block>

      <Block empty hidden={!projectData || !currentCompanyUser}>
        <Block empty hidden={!canEdit}>
          <AddCourseForm form={form} onFieldsChange={handleFormChange} />
          <div className="flex">
            <Button
              className="mr_8"
              size="xl"
              onClick={onSaveProjectEditing}
              loading={loading}
              type={ButtonTypesEnum.primary}
              disabled={disabled}
              htmlType="submit"
            >
              {common.t<string>('common.save')}
            </Button>
            <Button disabled={disabled || loading} onClick={resetToDefault} type={ButtonTypesEnum.default} size="xl">
              {common.t<string>('common.cancel')}
            </Button>
          </div>
        </Block>

        <Block empty hidden={canEdit}>
          <AboutDescription fontSize="large" title={common.t<string>('common.name')} value={projectData?.name} />
          <AboutDescription
            fontSize="large"
            title={common.t<string>('common.specialization')}
            value={projectData?.project_group?.name}
          />
          <div className="flex-row course-page-about__row">
            <AboutDescription title={common.t<string>('common.productionsDates')} value={productionRange} />
            <AboutDescription
              title={common.t<string>('common.timezone')}
              value={
                projectData?.timezone && `UTC ${getCurrentTimeOffset(projectData?.timezone)} (${projectData?.timezone})`
              }
            />
          </div>
          <div className="flex-row course-page-about__row">
            <AboutDescription
              title={common.t<string>('common.stage')}
              value={
                projectData?.phase_state?.name && (
                  <Tag icon={getStageImageByType(projectData?.phase_state?.icon_image)} size={TagSizeEnum.medium}>
                    {projectData?.phase_state?.name}
                  </Tag>
                )
              }
            />
            <AboutDescription
              title={common.t<string>('common.Status')}
              value={
                <StatusLabelFlat
                  type={projectData?.state?.state_type}
                  name={projectData?.state?.name}
                  // icon={projectData?.state?.icon_image}
                />
              }
            />
          </div>
          <div className="flex-row course-page-about__row">
            <AboutDescription
              title={common.t<string>('form.field.descriptionAbout.label')}
              value={projectData?.description}
            />
          </div>
        </Block>
      </Block>
    </div>
  );
}

export default CourseAboutPage;
