import React, { memo } from 'react';

import { cx } from 'utils';
import { UserExtended } from 'types/entities';

import Avatar from '../avatar';

import './styles.scss';

type UserItemInfoProps = {
  user?: UserExtended;
};

function UserItemInfo({ user }: UserItemInfoProps) {
  const classNames = cx('user-item-info');
  return (
    <div className={classNames}>
      <Avatar src={user?.user?.icon_image} size={48}>
        {user?.user.name[0]}
      </Avatar>
      <div className="user-item-info__inner">
        <span className="user-item-info__company">{user?.company?.name}</span>
        <span className="user-item-info__name">{user?.user?.name}</span>
      </div>
    </div>
  );
}

export default memo(UserItemInfo);
