import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';

import Spin from '../spin';

import './styles.scss';
import { cx } from '../../utils';

const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

type LoadingOverlayProps = {
  text?: string;
  show?: boolean;
  className?: string;
};

function LoadingOverlay({ text, show, className }: LoadingOverlayProps) {
  if (!show) {
    return null;
  }
  return (
    <div className={cx('loading-overlay', className)}>
      <Spin indicator={antIcon} />
      <span className="loading-overlay__text">{text}</span>
    </div>
  );
}

export default LoadingOverlay;
