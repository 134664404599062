import React, { memo } from 'react';

import { Spin as ASpin } from 'antd';
import { SpinProps } from 'antd/lib/spin';

function Spin({ ...props }: SpinProps) {
  return <ASpin className="ccm-spin" {...props} />;
}

export default memo(Spin);
