import React, { memo } from 'react';

import { PATH_NAMES } from 'router/constants';
import { isEmpty } from 'lodash';
import Empty from 'pages/task/components/empty';
import { BusinessRoleEnum } from 'types/entities';

import Block from 'components/block';

import useCommonContext from 'hooks/useCommonContext';
import useCompanyRoles from 'hooks/queries/useCompanyRoles';

import BlockHeader from '../blockHeader';
import DashboardWidget from '../widget';
import Gantt from '../gantt';
import { DashboardCollaborators, DashboardCollaboratorsGantt } from '../../types';

type DashboardUsersProps = {
  collaboratorsWidgetData?: DashboardCollaborators;
  collaboratorsGanttData: {
    data?: DashboardCollaboratorsGantt;
  };
};

function DashboardUsers({ collaboratorsWidgetData, collaboratorsGanttData }: DashboardUsersProps) {
  const common = useCommonContext();
  const { companyRoles } = useCompanyRoles(common.company?.id.toString());
  const showEmpty = isEmpty(collaboratorsGanttData?.data?.users);

  const manager = companyRoles?.find((el) => el.role === BusinessRoleEnum.manager);
  const instructionalDesigner = companyRoles?.find((el) => el.role === BusinessRoleEnum.instructionalDesigner);

  const managerTitle = manager
    ? `${manager.name}${common.t<string>('pages.dashboard.users.pluralEnding')}`
    : common.t<string>('pages.dashboard.users.projectManagers');

  const instructionalDesignerTitle = instructionalDesigner
    ? `${instructionalDesigner.name}${common.t<string>('pages.dashboard.users.pluralEnding')}`
    : common.t<string>('pages.dashboard.users.instructionalDesigners');

  return (
    <div className="dashboard-users mb_32">
      <BlockHeader
        className="mb_16"
        title={common.t<string>('pages.dashboard.users.title')}
        path={PATH_NAMES.users.base}
      />
      <div className="page-dashboard-widgets mb_16">
        <DashboardWidget label={managerTitle} value={collaboratorsWidgetData?.managers} />
        <DashboardWidget label={instructionalDesignerTitle} value={collaboratorsWidgetData?.instructional_designers} />
        <DashboardWidget
          label={common.t<string>('pages.dashboard.users.employeesOverworked')}
          value={collaboratorsWidgetData?.overcommited}
          type="danger"
        />
        <DashboardWidget
          label={common.t<string>('pages.dashboard.users.employeesUnderworked')}
          value={collaboratorsWidgetData?.underused}
          type="warning"
        />
      </div>

      <Block className="page-dashboard__empty-wrap" hidden={!showEmpty}>
        <Empty text={common.t<string>('pages.dashboard.users.empty')} />
      </Block>

      <Block empty hidden={showEmpty}>
        <Gantt data={collaboratorsGanttData.data} />
      </Block>
    </div>
  );
}

export default memo(DashboardUsers);
