/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AdminCompanyArtefactType = {
    readonly id?: number;
    name: string;
    type: AdminCompanyArtefactType.type;
    readonly icon_image?: string;
    is_final?: boolean | null;
    learning_object_type_id?: number | null;
    order?: number | null;
    cob_artefact_type?: AdminCompanyArtefactType.cob_artefact_type;
};

export namespace AdminCompanyArtefactType {

    export enum type {
        DRAFT_PRESENTATION = 'draft_presentation',
        PRODUCTION_PRESENTATION = 'production_presentation',
        TEXT = 'text',
        GRADER = 'grader',
        PEER_REVIEW = 'peer_review',
        QUIZ = 'quiz',
        SCRIPT = 'script',
        ORIGINAL_VIDEO = 'original_video',
        DRAFT_VIDEO = 'draft_video',
        SEPARATE_TASK = 'separate_task',
    }

    export enum cob_artefact_type {
        PROGRAMMING_ASSIGNMENT = 'programming_assignment',
        PEER_REVIEW = 'peer_review',
        QUIZ = 'quiz',
        DISCUSSION = 'discussion',
        ASSIGNMENT = 'assignment',
        VIDEO = 'video',
        READING = 'reading',
        LECTURE = 'lecture',
        SURVEY = 'survey',
        CHAT = 'chat',
        GROUP_WORK = 'group_work',
    }


}
