import colors from './config/colors';
import fontSize from './config/fontSize';
import borderRadius from './config/borderRadius';
import spaces from './config/spaces';

export const theme = {
  token: {
    fontFamily: "'PT Root UI', sans-serif",

    colorPrimary: colors.neutral5,
    colorPrimaryHover: colors.neutral3,
    colorPrimaryActive: colors.neutral3,

    colorPrimaryText: colors.neutral5,
    colorPrimaryTextHover: colors.neutral3,
    colorPrimaryTextActive: colors.neutral3,

    colorInfo: colors.blue3,
    colorInfoHover: colors.blue2,
    colorInfoActive: colors.blue3,

    colorSuccess: colors.green3,
    colorSuccessHover: colors.green2,
    colorSuccessActive: colors.green3,

    colorWarning: colors.orange3,
    colorWarningHover: colors.orange2,
    colorWarningActive: colors.orange2,

    colorError: colors.red3,
    colorErrorHover: colors.red2,
    colorErrorActive: colors.red3,

    colorHighlight: colors.red3,
    colorNormal: colors.neutral2,

    colorTextBase: colors.neutral5,
    colorTextSecondary: colors.neutral3,

    colorLink: colors.neutral5,
    colorLinkHover: colors.neutral3,
    colorLinkActive: colors.neutral2,

    fontSizeHeading1: fontSize.fontSize56,
    fontSizeHeading2: fontSize.fontSize40,
    fontSizeHeading3: fontSize.fontSize32,
    fontSizeHeading4: fontSize.fontSize24,
    fontSizeHeading5: fontSize.fontSize20,

    fontSize: fontSize.fontSize14,
    fontSizeSM: fontSize.fontSize12,
    fontSizeLG: fontSize.fontSize16,
    fontSizeXL: fontSize.fontSize20,

    lineHeight: 1.4285,

    borderRadius: borderRadius.borderRadius4,
    borderRadiusSM: borderRadius.borderRadius2,

    colorBorder: colors.neutral1,

    padding: spaces.space16,
    paddingLG: spaces.space24,
    paddingMD: spaces.space16,
    paddingSM: spaces.space12,
    paddingXS: spaces.space8,
    paddingXSS: spaces.space4,

    controlPaddingHorizontal: spaces.space12,
    controlPaddingHorizontalSM: spaces.space8,

    margin: spaces.space16,
    marginLG: spaces.space24,
    marginMD: spaces.space16,
    marginSM: spaces.space12,
    marginXS: spaces.space8,
    marginXSS: spaces.space4,

    controlItemBgActiveDisabled: colors.neutral0,
    colorTextDisabled: colors.neutral2,
    controlItemBgHover: colors.neutral0,
    controlItemBgActive: colors.neutral0,
  },
};
