import React from 'react';

import withCommonProps from 'hocs/withCommonProps';
import { TemplateCommonProps } from 'common/commonPropsProvider';
import { FileExcelOutlined } from '@ant-design/icons';

import { Drawer, DrawerHeader } from 'components/drawer';

import './styles.scss';

interface IImportInstructionsDrawer {
  visible: boolean;
  onClose: () => void;
}

const ImportInstructionsDrawer = ({ visible, onClose, common }: IImportInstructionsDrawer & TemplateCommonProps) => {
  const templateLink = common.t<string>('instructions.link');
  return (
    <Drawer
      className="import-instructions"
      visible={visible}
      onClose={onClose}
      width={440}
      maskClosable={false}
      closable={false}
      title={<DrawerHeader onClose={onClose} title={common.t<string>('pages.project.drawer.title')} />}
    >
      <div className="import-instructions__container">
        <a href={templateLink} target="_blank">
          <div className="import-instructions__download mb_24">
            <FileExcelOutlined style={{ fontSize: '24px', color: '#1E1E1E', marginRight: '8px' }} />
            <span className="import-instructions__download-text">{common.t<string>('instructions.st1')}</span>
          </div>
        </a>
        <div className="import-instructions__title mb_8">{common.t<string>('instructions.st2')}</div>
        {/*<div className="import-instructions__text">*/}
        <div className="import-instructions__text mb_8">{common.t<string>('instructions.st3')}</div>
        <div className="import-instructions__text">{common.t<string>('instructions.st4')}</div>
        <div className="import-instructions__text mb_24">
          <ul>
            <li className="ml_6">
              {common.t<string>('instructions.st5')}
              <ul>
                <li className="ml_16">{common.t<string>('instructions.st6')}</li>
                <ul>
                  <li className="ml_32">{common.t<string>('instructions.st7')}</li>
                </ul>
              </ul>
            </li>
          </ul>
        </div>
        <div className="import-instructions__text">{common.t<string>('instructions.st8')}</div>
        <div className="import-instructions__text mb_24">
          <ul className="ml_6">
            <li>learning_group: {common.t<string>('instructions.st9')},</li>
            <li>learning_outcome: {common.t<string>('instructions.st10')},</li>
            <li>learning_description: {common.t<string>('instructions.st11')},</li>
            <li>learning_group: {common.t<string>('instructions.st12')},</li>
            <li>learning_outcome: {common.t<string>('instructions.st13')},</li>
            <li>learning_description: {common.t<string>('instructions.st14')},</li>
            <li>learning_object: {common.t<string>('instructions.st15')},</li>
            <li>learning_outcome: {common.t<string>('instructions.st16')},</li>
            <li>learning_object_type: {common.t<string>('instructions.st17')},</li>
            <li>learning_object_description: {common.t<string>('instructions.st18')}.</li>
          </ul>
          <div />
        </div>
        <div className="import-instructions__title mb_8">{common.t<string>('instructions.st19')}</div>
        <div className="import-instructions__text mb_24">
          <ul className="ml_6">
            <li>{common.t<string>('instructions.st20')}</li>
            <li>{common.t<string>('instructions.st21')}</li>
            <li>{common.t<string>('instructions.st22')}</li>
          </ul>
        </div>
      </div>
    </Drawer>
  );
};
export default withCommonProps(ImportInstructionsDrawer);
