import API from '../api';
import { ApiModules, ENDPOINTS } from '../constants';

export type AddUserToCompanyPayload = {
  name: string;
  email: string;
  timezone?: string;
  file?: any;
  phone_number?: string;
  business_role_ids: number[];
};

export type AddUserToCompanyParams = {
  companyId: string;
  payload: AddUserToCompanyPayload;
};

export type NewCompanyPayload = {
  name: string;
};

export type NewCompanyResponse = {
  id: string;
  name: string;
};

export type UpdateUserToCompanyPayload = {
  companyId: string;
  userId: string;
  payload: AddUserToCompanyPayload;
};
export type UpdateUserToCompanyFormDataPayload = {
  companyId: string;
  userId: string;
};
export const onCreateCompany = (payload: NewCompanyPayload): Promise<any> =>
  API(ApiModules.ACCOUNT).post(ENDPOINTS.CREATE_COMPANY, payload);

export const onAddUserToCompany = ({ companyId, payload }: AddUserToCompanyParams): Promise<any> =>
  API(ApiModules.ACCOUNT).post(ENDPOINTS.ADD_USER_TO_COMPANY(companyId), payload);

export const onUpdateUserToCompany = ({ companyId, userId, payload }: UpdateUserToCompanyPayload): Promise<any> =>
  API(ApiModules.ACCOUNT).put(ENDPOINTS.UPDATE_USER_TO_COMPANY(companyId, userId), payload);

export const onUpdateUserToCompanyFormData = (
  { companyId, userId }: UpdateUserToCompanyFormDataPayload,
  payload: FormData
): Promise<any> => API(ApiModules.ACCOUNT).put(ENDPOINTS.UPDATE_USER_TO_COMPANY(companyId, userId), payload);
