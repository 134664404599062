import { useCallback, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { GET_DASHBOARD_COLLABORATORS, GET_DASHBOARD_COLLABORATORS_GANTT } from 'queries/getDashboardCollaborators';
import { convertRoles } from 'utils';
import { buildAbilityFor } from 'config/ability';

import useCommonContext from 'hooks/useCommonContext';
import useBoolean from 'hooks/useBoolean';

import { DashboardCollaborators, DashboardCollaboratorsGantt } from '../types';

type CollaboratorsData = {
  front_page_collaborators: DashboardCollaborators;
};

type CollaboratorsGanttData = {
  front_page_collaborators_gantt: DashboardCollaboratorsGantt;
};

function useCollaboratorsData() {
  const [ready, onReady] = useBoolean(false);
  const common = useCommonContext();

  const ability = buildAbilityFor(convertRoles(common.businessRoles));
  const skipCollaborators = !ability.can('view', 'Dashboard.collaborators');

  const onCompleted = useCallback(() => onReady.on(), [onReady]);

  useEffect(() => {
    if (skipCollaborators && common.businessRoles) {
      onReady.on();
    }
  }, [common.businessRoles, onReady, skipCollaborators]);

  //Collaborators widget
  const collaboratorsWidgetData = useQuery<CollaboratorsData>(GET_DASHBOARD_COLLABORATORS, {
    notifyOnNetworkStatusChange: true,
    skip: skipCollaborators,
  });

  //Collaborators gantt
  const collaboratorsGanttData = useQuery<CollaboratorsGanttData>(GET_DASHBOARD_COLLABORATORS_GANTT, {
    notifyOnNetworkStatusChange: true,
    skip: skipCollaborators,
    onCompleted: onCompleted,
  });

  return {
    collaboratorsReady: ready,
    collaboratorsLoading: collaboratorsWidgetData.loading,
    collaboratorsWidgetData: collaboratorsWidgetData.data?.front_page_collaborators,
    collaboratorsGanttData: {
      data: collaboratorsGanttData?.data?.front_page_collaborators_gantt,
    },
  };
}

export default useCollaboratorsData;
