import React from 'react';

import icon from 'assets/image/empty-img-gray.svg';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

type EmptyProps = {
  text?: string;
  image?: string;
};

function Empty({ text, image }: EmptyProps) {
  const common = useCommonContext();
  return (
    <div className="art-not-created">
      <img src={image || icon} alt="icon" />
      <span className="art-not-created__text">{text ? text : common.t<string>('pages.task.artifactNotCreated')}</span>
    </div>
  );
}

export default Empty;
