import React from 'react';

import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { UploadChangeParam } from 'antd/es/upload';

import useCommonContext from '../../hooks/useCommonContext';

import './styles.scss';

interface UploadAvatarProps extends UploadProps {
  loading?: boolean;
  onChangeUpload: (info: UploadChangeParam<UploadFile>) => void;
  file?: string | undefined;
}

function UploadAvatar({ loading, onChangeUpload, file, ...props }: UploadAvatarProps) {
  const common = useCommonContext();
  const uploadButton = <div className="upload-avatar__button">{loading ? <LoadingOutlined /> : <PlusOutlined />}</div>;
  const avatar = (
    <div className="upload-avatar__button">{loading ? <LoadingOutlined /> : <img src={file} alt="avatar" />}</div>
  );

  return (
    <div className="upload-avatar">
      <ImgCrop
        grid
        rotate
        modalTitle={common.t<string>('pages.account.cropTitle')}
        modalOk={common.t<string>('pages.account.cropOk')}
        modalCancel={common.t<string>('pages.account.cropCancel')}
      >
        <Upload action={''} showUploadList={false} onChange={onChangeUpload} {...props}>
          {file ? avatar : uploadButton}
        </Upload>
      </ImgCrop>
    </div>
  );
}

export default UploadAvatar;
