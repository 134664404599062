import React, { memo } from 'react';

import { cx } from '../../utils';

import { TextVariantEnum } from './types';

type TextProps = {
  variant: TextVariantEnum;
  children?: React.ReactNode;
  className?: string;
};

import './styles.scss';

function Text({ variant, children, className }: TextProps) {
  const classNames = cx('ccm-text', className, {
    [`ccm-text--${variant}`]: variant,
  });
  return <span className={classNames}>{children}</span>;
}

export default memo(Text);
