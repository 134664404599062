import { TFunction } from 'i18next';
import moment from 'moment';

export const radioFilterMap = (t: TFunction) => [
  {
    label: t('pages.tasks.filter.toDay'),
    value: moment.utc().format('YYYY-MM-DD'),
    className: `testclass-dashboard-filter-today`,
    key: 1,
  },
  {
    label: t('pages.tasks.filter.allTask'),
    value: 'all',
    className: `testclass-dashboard-filter-tasks-all`,
    key: 2,
  },
];
