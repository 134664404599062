import React from 'react';

import { HistoryOutlined } from '@ant-design/icons';

import Button from 'components/button';

import useCommonContext from 'hooks/useCommonContext';

import HeaderVersions from '../headerSubPages/HeaderVersions';
import {
  ArtefactFile,
  ArtefactProductionSequence,
  ArtefactStateRequest,
  ArtefactVersion,
  TaskStateRollbackRequest,
} from '../../types';
import HeaderButtons from '../headerButtons/HeaderButtons';
import { BusinessRoleEnum, UserRoles } from '../../../../types/entities/user';
import './styles.scss';
import { ButtonTypesEnum } from '../../../../components/button/types';
import SkeletonHeader from '../skeleton/SkeletonHeader';

type TaskHeaderProps = {
  loading?: boolean;
  iterations: ArtefactVersion[] | [] | undefined;
  setSearchParams: any;
  searchParams: any;
  taskTypeName?: string | undefined;
  hintText: string;
  onOpenHistory?: () => void;
  onRollbackTaskStatus?: (payload: TaskStateRollbackRequest) => void;
  refetchProjectTask: () => Promise<any>;
  isHaveFile?: boolean;
  isHaveText?: boolean;
  artefactFlowSteps?: any[] | undefined;
  userRole: BusinessRoleEnum | null;
  userRoles: UserRoles[] | null;
  issuesList?: any[] | undefined;
  artefactFile?: ArtefactFile | null;
  projectTask?: ArtefactProductionSequence | undefined;
  isHaveFileLink?: boolean;
  callback?: any;
  onTaskHistoryRefetch?: () => Promise<any>;
};

function TaskHeader({
  loading,
  onOpenHistory,
  iterations,
  setSearchParams,
  searchParams,
  hintText,
  onRollbackTaskStatus,
  refetchProjectTask,
  userRole,
  userRoles,
  issuesList,
  artefactFlowSteps,
  artefactFile,
  projectTask,
  isHaveFileLink,
  callback,
  onTaskHistoryRefetch,
  isHaveText,
}: TaskHeaderProps) {
  const common = useCommonContext();
  const isCurrentExecutorEntity = projectTask?.project_task?.projecttaskassignee_set?.find(
    (assigner) => assigner.is_current_executor === true
  );
  const isCurrentExecutor =
    Number(isCurrentExecutorEntity?.project_user?.company_user?.user?.id) === Number(common?.userId);

  return (
    <div className="task-page-header">
      {loading ? (
        <SkeletonHeader />
      ) : (
        <div className="task-page-header__inner">
          <div className="task-page-header__version">
            <HeaderVersions
              iterations={iterations}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
              className="mr_16"
            />
            <Button
              className="testclass-componentspanel-history"
              type={ButtonTypesEnum.link}
              icon={<HistoryOutlined />}
              onClick={onOpenHistory}
              disabled={!iterations?.length}
            />
          </div>
          <div className="task-page-header__buttons">
            <HeaderButtons
              isHaveFile={callback ? !!isHaveFileLink : isHaveFileLink || Boolean(artefactFile?.related_files?.length)}
              onRollbackTaskStatus={onRollbackTaskStatus}
              projectTask={projectTask}
              refetch={refetchProjectTask}
              userRole={userRole}
              userRoles={userRoles}
              issuesList={issuesList}
              artefactFlowSteps={artefactFlowSteps}
              callback={callback}
              onTaskHistoryRefetch={onTaskHistoryRefetch}
              isCurrentExecutor={isCurrentExecutor}
              hintText={hintText}
              isHaveText={isHaveText}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default TaskHeader;
