import React, { useCallback, useMemo, useState } from 'react';

import { message, Progress, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { cx } from 'utils';
import { isEmpty } from 'lodash';
import { RcFile } from 'antd/es/upload';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { FileType } from 'types/entities';
import { onDeleteFile, onRemoveArtefactVersion } from 'api/requests/files';
import { useParams } from 'react-router-dom';
import useSeparateTaskAbility from 'pages/hooks/useSeparateTaskAbility';
import ImageCard from 'pages/components/imageCard';
import ImageCardEx from 'pages/components/imageCard/ImageCardEx';
import { filesUpload } from 'pages/fetches/fileUpload';
import { GlobalTaskOutput } from 'pages/hooks/useSeparateTask';

import Block from 'components/block';

import useCommonContext from 'hooks/useCommonContext';
import useSeparateTaskQuery from 'hooks/queries/useSeparateTaskQuery';
import useProgressUpload from 'hooks/useProgressUpload';

import './styles.scss';

type UploadWallProps = {
  instance?: GlobalTaskOutput;
  altLabel?: boolean;
};

const FILE_SIZE_MAX = 20;

function SeparateTaskUploadWall({ instance, altLabel }: UploadWallProps) {
  const [fileSize, setFileSize] = useState<number | null | undefined>(null);
  const { taskId } = useParams();
  const common = useCommonContext();
  const { canIEdit } = useSeparateTaskAbility(taskId);
  const { commonFiles, separateTask, separateTaskRefetch } = useSeparateTaskQuery(taskId, 'cache-and-network');

  const classNames = cx('upload-wall-select', {
    'upload-wall-select--empty': isEmpty(instance?.fileList),
    'upload-wall-select--empty-short': !instance,
  });

  const { progress, updateProgress, resetProgress } = useProgressUpload({ fileSize });

  const beforeUpload = (file: RcFile) => {
    const fSize = file?.size || 0;
    return fSize / 1024 / 1024 < FILE_SIZE_MAX;
  };

  const handleChange = useCallback(
    async (info: UploadChangeParam<UploadFile<any>>) => {
      const fSize = info?.file?.size || 0;
      const allowedSize = fSize / 1024 / 1024 < FILE_SIZE_MAX;

      if (allowedSize) {
        if (instance) {
          instance?.setFileList([...instance?.fileList, info.file]);
        } else {
          setFileSize(info.file.size);
          await filesUpload(separateTask?.id, [info.file], updateProgress);
          await separateTaskRefetch().then(() => resetProgress());
        }
      } else {
        message.error(common.t<string>('pages.project.create.fileSizeError'));
      }
    },
    [common, instance, resetProgress, separateTask?.id, separateTaskRefetch, updateProgress]
  );

  const onRemoveLocalFile = useCallback(
    (fileId: string) => {
      if (instance) {
        const filtered = instance?.fileList.filter((f) => f.uid !== fileId);
        instance?.setFileList(filtered);
      }
    },
    [instance]
  );

  const onRemoveFile = useCallback(
    async (artVer: string, fileId: string) => {
      await onRemoveArtefactVersion(artVer);
      await onDeleteFile(FileType.FILE, Number(fileId));
      await separateTaskRefetch();
    },
    [separateTaskRefetch]
  );

  const uploadButton = useMemo(() => {
    return progress ? (
      <div className="upload-wall__button upload-wall__button-progress">
        <span className="upload-wall__text">{common.t<string>('pages.task.freeTask.loading')}</span>
        <div className="upload-wall__button-progress-wrap">
          <Progress percent={progress} showInfo={false} strokeColor="#1E1E1E" trailColor="#E9E9E9" />
        </div>
      </div>
    ) : (
      <div className="upload-wall__button">
        <PlusOutlined />
        <span className="upload-wall__text">{common.t<string>('pages.project.create.chooseFile')}</span>
      </div>
    );
  }, [common, progress]);

  const onCustomRequest = () => {};

  const createRender = useMemo(() => {
    return (
      <div className="upload-wall__inner">
        {!isEmpty(instance?.fileList)
          ? instance?.fileList?.map((item, index) => <ImageCard key={index} item={item} onRemove={onRemoveLocalFile} />)
          : null}
        <Upload
          className={classNames}
          customRequest={onCustomRequest}
          showUploadList={false}
          fileList={instance?.fileList}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {uploadButton}
        </Upload>
      </div>
    );
  }, [classNames, handleChange, instance?.fileList, onRemoveLocalFile, uploadButton]);

  const editRender = useMemo(() => {
    return (
      <div className="upload-wall__inner">
        {!isEmpty(commonFiles)
          ? commonFiles?.map((file, index) => <ImageCardEx key={index} item={file} onRemove={onRemoveFile} />)
          : null}
        {canIEdit ? (
          <Upload
            className={classNames}
            customRequest={onCustomRequest}
            showUploadList={false}
            fileList={instance?.fileList}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {uploadButton}
          </Upload>
        ) : null}
      </div>
    );
  }, [canIEdit, classNames, commonFiles, handleChange, instance?.fileList, onRemoveFile, uploadButton]);

  return (
    <div className="upload-wall">
      {altLabel ? (
        <span className="upload-wall__label-alt">{common.t<string>('pages.project.create.filesAlt')}</span>
      ) : (
        <span className="upload-wall__label">{common.t<string>('pages.project.create.files')}</span>
      )}
      <div className="upload-wall__wrapper">
        {instance ? createRender : editRender}
        <Block hidden={!instance} className="upload-wall__size">
          {common.t<string>('pages.project.create.fileSize')}
        </Block>
      </div>
    </div>
  );
}

export default SeparateTaskUploadWall;
