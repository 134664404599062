import API from 'api/api';
import { ApiModules, ENDPOINTS } from 'api/constants';

import { CreateProjectRequest } from '../../pages/task/types';

export const onGetProjects = (): Promise<any> => API(ApiModules.PM).get(ENDPOINTS.PROJECT);
export const onCreateProject = (payload: CreateProjectRequest): Promise<any> =>
  API(ApiModules.PM).post(ENDPOINTS.PROJECT, payload);
export const onChangeProjectStatus = (projectTaskId: number, payload: any): Promise<any> =>
  API(ApiModules.PM).put(ENDPOINTS.PROJECT_STATUS(projectTaskId), payload);
export const onRollbackProjectStatus = (taskId: number, payload: any): Promise<any> =>
  API(ApiModules.PM).put(ENDPOINTS.ROLLBACK_STATUS(taskId), payload);
export const onGetProjectById = (projectId: number): Promise<any> =>
  API(ApiModules.PM).get(ENDPOINTS.INSTRUCTIONAL_DESIGNER(projectId));
export const onGetStatuses = (companyId: number): Promise<any> =>
  API(ApiModules.PM).get(ENDPOINTS.COMPANY_STATUSES(companyId));
export const onChangeProjectUsersList = (projectId: number, payload: any): Promise<any> =>
  API(ApiModules.PM).put(ENDPOINTS.COMPANY_USER_LIST(projectId), payload);
export const onChangeProject = (projectId: number, payload: any): Promise<any> =>
  API(ApiModules.PM).put(ENDPOINTS.CHANGE_PROJECT(projectId), payload);
