import React, { memo } from 'react';

import { Tabs } from 'antd';

import ButtonsStories from './buttons/buttons';
import SelectStories from './select/select';
import AvatarStories from './avatar/avatar';
import BadgeStories from './badge/badge';
import './styles.scss';
import DropdownCheckboxStories from './dropdownCheckbox/dropdownCheckbox';
import MessagesStories from './message/messages';
import InputStories from './input/Input';
import DrawerStories from './drawer/drawers';
import NotificationStories from './notification/notification';
import PopconfirmStories from './popconfirm/popconfirm';
import PopoverStories from './popover/popover';
import ProgressStories from './progress/progress';
import SwitchStories from './switch/switch';
import RadioStories from './radio/radio';
import TagStories from './tag/tag';
import DropdownStories from './dropdown/dropdown';
import CheckboxStories from './checkbox/checkbox';
import TimelineStories from './timeline/timeline';
import PaginationStories from './pagination/pagination';
import ResultStories from './result/result';
import SpinStories from './spin/spin';
import DatePickerStories from './datePicker/DatePicker';

function UiPage() {
  return (
    <div className="ui-page">
      <Tabs>
        <Tabs.TabPane tab="Avatar" key="item-2">
          <AvatarStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Badge" key="item-4">
          <BadgeStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Buttons" key="item-1">
          <ButtonsStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Select" key="item-3">
          <SelectStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Dropdown" key="item-55">
          <DropdownStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Dropdown checkbox" key="item-5">
          <DropdownCheckboxStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Messages" key="item-6">
          <MessagesStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Drawer" key="item-7">
          <DrawerStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Notification" key="item-8">
          <NotificationStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Input" key="item-9">
          <InputStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Popconfirm" key="item-10">
          <PopconfirmStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Popover" key="item-11">
          <PopoverStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Progress" key="item-12">
          <ProgressStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Switch" key="item-13">
          <SwitchStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Radio" key="item-14">
          <RadioStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Tag" key="item-15">
          <TagStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Checkbox" key="item-16">
          <CheckboxStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Timeline" key="item-17">
          <TimelineStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Pagination" key="item-18">
          <PaginationStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Results" key="item-20">
          <ResultStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Spin" key="item-21">
          <SpinStories />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Date and time pickers" key="item-19">
          <DatePickerStories />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default memo(UiPage);
