import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { uniqBy } from 'lodash';
import { GET_PROJECT_STATES_LIST } from 'queries/getProjectStates';
import { StateType } from 'types/entities';

export type ProjectStateItem = {
  id: string;
  name: string;
  state_type: StateType;
  created_at: string;
  updated_at: string;
  order: number;
  is_require_discuss_message: string;
};

type ObjectState = {
  id: string;
  name: string;
  state_type: StateType;
};

type ProjectStatesData = {
  project_states: ProjectStateItem[];
  project_issues_states: ProjectStateItem[];
  project_discussion_states: ProjectStateItem[];
  project_separate_task_states: ProjectStateItem[];
  object_states: ObjectState[];
};

function useProjectStates(projectId?: string | null, fetchPolicy?: WatchQueryFetchPolicy) {
  const { data, loading, refetch } = useQuery<ProjectStatesData>(GET_PROJECT_STATES_LIST, {
    variables: {
      projectId: projectId,
    },
    skip: !projectId,
    fetchPolicy: fetchPolicy || 'cache-and-network',
  });

  const projectStatesForSelect = data?.project_states?.map((state) => {
    return {
      id: state.id,
      label: state.name,
      value: state.id,
    };
  });

  const objectStatesForSelect = data?.object_states.map((el) => {
    return {
      id: el.id,
      label: el.name,
      value: el.id,
    };
  });

  const projectMergedStatesForSelect = useMemo(() => {
    const baseStates =
      data?.project_states.map((el) => {
        return {
          id: el.id,
          label: el.name,
          value: el.name,
        };
      }) || [];
    const separateStates =
      data?.project_separate_task_states?.map((state) => {
        return {
          id: state.id,
          label: state.name,
          value: state.name,
        };
      }) || [];

    const temp = [...baseStates, ...separateStates];

    return uniqBy(temp, 'value');
  }, [data?.project_separate_task_states, data?.project_states]);

  return {
    companyStates: data?.project_states || null,
    companyIssuesStates: data?.project_issues_states || null,
    projectDiscussionStates: data?.project_discussion_states || null,
    companyStatesLoading: loading,
    companyStatesRefetch: refetch,
    projectStatesForSelect,
    projectMergedStatesForSelect,
    objectStatesForSelect,
  };
}

export default useProjectStates;
