import React, { useMemo } from 'react';

import useCommonContext from './useCommonContext';

function useTextEmpty() {
  const common = useCommonContext();
  const textEmptyTask = useMemo(() => {
    return (
      <div className="flex-col flex-align-items--center">
        <span>{common.t<string>('common.nothingFound')}</span>
        <span>{common.t<string>('pages.tasks.shouldChangeFilter')}</span>
      </div>
    );
  }, [common]);

  return {
    textEmptyTask,
  };
}

export default useTextEmpty;
