import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { Company, LearningObjectType } from 'types/entities';
import { uniqBy } from 'lodash';
import { GET_COMPANY_ARTEFACT_TYPES } from 'queries/getCompanyArtefactTypes';
import { CompanyArtefactType as ModelCompanyArtefactType } from 'types/models/CompanyArtefactType';

import useCommonContext from '../useCommonContext';

export type CompanyArtefactType = {
  id: string;
  pk: string;
  created_at: string;
  updated_at: string;
  company: Company;
  name: string;
  type: ModelCompanyArtefactType.type;
  icon_image: string;
  order: number;
  is_final: boolean;
  learning_object_type: LearningObjectType;
  cob_artefact_type: string;
};

type CompanySeparateTaskTypes = {
  id: string;
  name: string;
};

type CompanyTypesData = {
  company_artefact_types: CompanyArtefactType[];
  company_separate_task_types: CompanySeparateTaskTypes[];
};

function useCompanyArtefactTypes(fetchPolicy?: WatchQueryFetchPolicy) {
  const common = useCommonContext();
  const { data, loading, refetch } = useQuery<CompanyTypesData>(GET_COMPANY_ARTEFACT_TYPES, {
    variables: {
      companyId: common?.company?.id,
    },
    fetchPolicy: fetchPolicy || 'network-only',
    skip: !common?.company?.id,
  });

  const memoizedData: CompanyArtefactType[] | null = useMemo(() => {
    return data?.company_artefact_types || [];
  }, [data?.company_artefact_types]);

  const memoizedDataForSelect = useMemo(() => {
    return memoizedData?.map((art) => {
      return {
        id: art.id,
        label: art.name,
        value: art.id,
      };
    });
  }, [memoizedData]);

  const memoizedDataFinalForSelect = useMemo(() => {
    return memoizedData
      ?.filter((e) => e.is_final)
      .map((art) => {
        return {
          id: art.id,
          label: art.name,
          value: art.id,
        };
      });
  }, [memoizedData]);

  const companyMergedTypesForSelect = useMemo(() => {
    const baseTypes =
      data?.company_artefact_types.map((el) => {
        return {
          id: el.id,
          label: el.name,
          value: el.name,
        };
      }) || [];
    const separateTypes =
      data?.company_separate_task_types.map((el) => {
        return {
          id: el.id,
          label: el.name,
          value: el.name,
        };
      }) || [];
    const temp = [...baseTypes, ...separateTypes];

    return uniqBy(temp, 'value');
  }, [data?.company_artefact_types, data?.company_separate_task_types]);

  return useMemo(() => {
    return {
      loading,
      companyArtefactTypes: memoizedData,
      companyArtefactTypesForSelect: memoizedDataForSelect,
      companyFinalArtefactTypesForSelect: memoizedDataFinalForSelect,
      companyMergedTypesForSelect,
      refetch,
    };
  }, [companyMergedTypesForSelect, loading, memoizedData, memoizedDataFinalForSelect, memoizedDataForSelect, refetch]);
}

export default useCompanyArtefactTypes;
