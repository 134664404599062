import React, { memo } from 'react';

import { ProjectLearningStructureEnum } from 'types/entities';

import { WrapperTreeProps } from '../../types';
import AddSection from '../addSection';
import ProjectTreeItem from '../treeItem';

function NodeWrapper({
  node,
  params,
  settings,
  selected,
  hasChild,
  isEdit,
  count,
  parents,
  descendants,
  onSubmit,
  onDelete,
  onCopy,
  onToggleCheckbox,
  setSearchParams,
  openTrigger,
}: WrapperTreeProps) {
  if (node?.data?.is_extra) {
    return (
      <AddSection
        counter={count}
        isEdit={isEdit}
        learningStructureType={node.data.learningStructureType}
        parentId={node.parent}
        depth={params.depth}
        onSubmit={onSubmit}
      />
    );
  }

  return (
    <ProjectTreeItem
      node={node}
      params={params}
      openTrigger={openTrigger}
      selected={selected}
      parents={parents}
      descendants={descendants}
      settings={settings}
      count={count}
      hasChild={hasChild}
      isEdit={isEdit}
      onSubmit={onSubmit}
      onCopy={onCopy}
      onDelete={onDelete}
      setSearchParams={setSearchParams}
      onToggleCheckbox={onToggleCheckbox}
    />
  );
}

export default memo(NodeWrapper);
