import React from 'react';

import { Pagination as APagination } from 'antd';
import { cx } from 'utils';
import { PaginationProps } from 'antd/lib/pagination/Pagination';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { IPaginationProps } from './types';

function Pagination({ className, ...props }: IPaginationProps) {
  const classNames = cx('ccm-pagination', className);
  const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <div className="testclass-dashboard-page-arrowleft">
          <LeftOutlined />
        </div>
      );
    }
    if (type === 'next') {
      return (
        <div className="testclass-dashboard-page-arrowright">
          <RightOutlined />
        </div>
      );
    }
    return originalElement;
  };
  return <APagination className={classNames} itemRender={itemRender} {...props} />;
}

export default Pagination;
