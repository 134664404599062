import { useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';
import { GET_PROJECT_TASK_ASSIGNEES } from 'queries/getProjectTaskAssignees';
import { UserBase, UserRoleBase } from 'types/entities';

export type TaskAssignee = {
  project_task: {
    id: string;
  };
  is_active: boolean;
  is_current_executor: boolean;
  project_user: {
    company_user: {
      id: string;
      user: UserBase;
    };
  };
  by_artefact_role_assignment: {
    user_role: UserRoleBase;
  };
};

type ProjectTaskAssignees = {
  project_task_assignees: {
    edges: {
      node: TaskAssignee;
    }[];
  };
};

function useLazyProjectTaskAssignees(projectId?: number) {
  const [getProjectTaskAssignees, { data, loading }] = useLazyQuery<ProjectTaskAssignees>(GET_PROJECT_TASK_ASSIGNEES, {
    variables: {
      project_id: projectId,
    },
  });

  return useMemo(() => {
    return {
      loading,
      assignees: data?.project_task_assignees.edges.map((a) => a.node),
      getProjectTaskAssignees,
    };
  }, [data?.project_task_assignees.edges, getProjectTaskAssignees, loading]);
}

export default useLazyProjectTaskAssignees;
