import React, { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { cx } from 'utils';
import modalService from 'services/modalService';
import { LastState, onCompanyStatePublish, onEditCompanyState } from 'api/requests/companyState';
import { PATH_NAMES } from 'router/constants';

import Button from 'components/button';
import { ButtonTypesEnum } from 'components/button/types';
import Title from 'components/title';
import { TitleVariantEnum } from 'components/title/types';
import Table from 'components/table';

import { CompanyStateListItem, StateItem } from 'hooks/queries/useCompanyStateLists';
import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

type Props = {
  isSelected: boolean;
  item: CompanyStateListItem;
  onRefetch: () => Promise<any>;
};

const StateManagementBlock = ({ isSelected, item, onRefetch }: Props) => {
  const navigate = useNavigate();
  const { company } = useCommonContext();
  const columns = [
    {
      key: 'name',
      title: 'Name',
      render: (record: StateItem) => record.name,
    },
    {
      key: 'buttonName',
      title: 'Button name',
      render: (record: StateItem) => record.button_name,
    },
    {
      key: 'type',
      title: 'Type',
      render: (record: StateItem) => record.state_type,
    },
  ];

  const data = item?.actual_version?.states || [];

  const onSaveNew = useCallback(
    async (values: LastState, onLoading: any) => {
      onLoading.on();
      const payload = {
        name: values.name,
        button_name: values.button_name,
        state_type: values.state_type,
        is_new_iteration_initiation: Boolean(values.is_new_iteration_initiation),
        is_require_discuss_message: Boolean(values.is_require_discuss_message),
        is_could_close_without_complete_issues: Boolean(values.is_could_close_without_complete_issues),
      };

      if (company?.id) {
        const payloadPub = {
          id: item.pk,
        };
        await onEditCompanyState(item.actual_version.id, payload);
        await onCompanyStatePublish(payloadPub);
        await onRefetch().then(() => onLoading.off());
      }
    },
    [company?.id, item, onRefetch]
  );

  const onAddNew = useCallback(() => {
    modalService.openEditStateItem({ title: item.name, onSave: onSaveNew });
  }, [item.name, onSaveNew]);

  const onChangeFlow = useCallback(() => {
    const path = `/${PATH_NAMES.management.base}/${PATH_NAMES.management.status}/edit/${item.pk}`;
    navigate(path);
  }, [item.pk, navigate]);

  const classNames = cx('state-manage-block', {
    'state-manage-block--active': isSelected,
  });

  return (
    <div className={classNames}>
      <div className="state-manage-block__top">
        <Title variant={TitleVariantEnum.h6} className="state-manage-block__title">
          {item.name}
        </Title>
        <div className="state-manage-block__actions">
          <Button type={ButtonTypesEnum.primary} onClick={onChangeFlow}>
            Flow
          </Button>
          <Button type={ButtonTypesEnum.primary} onClick={onAddNew}>
            New state
          </Button>
        </div>
      </div>
      <div className="state-manage-block__content">
        <Table columns={columns} dataSource={data} pagination={false} hasFirstLoad emptyText={'No data'} />
      </div>
    </div>
  );
};

export default StateManagementBlock;
