import React from 'react';

import ProgressLine from '../../../components/progressLine';

const ProgressStories = () => {
  return (
    <div className="mb_8 flex-col flex-align-items--center flex-justify-content--center">
      <ProgressLine percent={55} strokeColor="#1E1E1E" showInfo={false} />
      <br />
      <ProgressLine percent={70} status="exception" showInfo={false} />
      <br />
      <ProgressLine percent={100} showInfo={false} />
      <br />
      <ProgressLine percent={55} strokeColor="#1E1E1E" />
      <br />
      <ProgressLine percent={70} status="exception" />
      <br />
      <ProgressLine percent={100} />
      <br />
      <ProgressLine strokeColor="#1E1E1E" percent={55} size="small" />
      <br />
      <ProgressLine percent={70} status="exception" size="small" />
      <br />
      <ProgressLine percent={100} size="small" />
      <br />
      <ProgressLine type="circle" percent={30} width={80} strokeColor="#1E1E1E" />
      <br />
      <ProgressLine type="circle" percent={70} width={80} status="exception" />
      <br />
      <ProgressLine type="circle" percent={100} width={80} />
      <br />
      <ProgressLine percent={30} steps={5} strokeColor="#1E1E1E" />
      <br />
      <ProgressLine percent={50} steps={3} strokeColor="#1E1E1E" />
      <br />
      <ProgressLine percent={100} steps={5} size="small" strokeColor="#1E1E1E" />
      <br />
    </div>
  );
};

export default ProgressStories;
