import React, { memo } from 'react';

import { cx, getFormattedStartEndByStr, getLeftTime } from 'utils';
import { InfoCircleOutlined } from '@ant-design/icons';

import { ProjectDeadlinesChangedPopup } from 'components/historyPopup';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

type ProductionDatesProps = {
  hasChanges?: boolean;
  projectId?: string;
  start?: string;
  end?: string;
};

function ProductionDates({ hasChanges, projectId, start, end }: ProductionDatesProps) {
  const common = useCommonContext();

  const classNamesLeft = cx('info-drawer-prod-dates__days-left', {
    ml_8: !hasChanges,
  });

  return (
    <div className="info-drawer-prod-dates">
      <span className="info-drawer-prod-dates__label">{common.t<string>('pages.courses.drawer.prodDates')}</span>
      <div className="info-drawer-prod-dates__block">
        <span className="info-drawer-prod-dates__value">{getFormattedStartEndByStr(start, end)}</span>
        {hasChanges ? (
          <ProjectDeadlinesChangedPopup
            projectId={projectId}
            icon={<InfoCircleOutlined style={{ color: '#E29416' }} />}
            placement={'bottom'}
          />
        ) : null}
        {end ? <span className={classNamesLeft}>{getLeftTime(end)}</span> : null}
      </div>
    </div>
  );
}

export default memo(ProductionDates);
