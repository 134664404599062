import { useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { GET_ARTEFACT_DELAYS_PRODUCTION_DEADLINE } from 'queries/getArtefactDelays';

import { CompanyArtefactType } from '../../types';

export type ArtefactDelaysProdDeadline = {
  id: string;
  artefact_type: {
    name: string;
    type: CompanyArtefactType.type;
    icon_image: string;
  };
  artefact_versions: {
    id: string;
    file: {
      file_type: string;
    };
  }[];
  artefact_iteration_count: string;
  cached_task: {
    id: string;
    artefact_production_sequence: {
      id: string;
      project_learning_object: {
        id: string;
        name: string;
        parent: {
          human_readable_number: string;
        };
      };
    };
  };
};

export type ComposeArtefactDelaysProdDeadline = {
  id: string;
  artefact?: ArtefactDelaysProdDeadline;
  separateTask: {
    id: string;
    project_task: {
      id: string;
      name: string;
    };
    task_type: {
      id: string;
      name: string;
      icon_image: string;
    };
  } | null;
};

export type ArtefactDelaysProdDeadlineDataItem = {
  id: string;
  delay: {
    project: {
      id: string;
    };
  };
  project_task_assignee: {
    project_task: {
      cached_linked_artefact: ArtefactDelaysProdDeadline;
      separate_task: {
        id: string;
        project_task: {
          id: string;
          name: string;
        };
        task_type: {
          id: string;
          name: string;
          icon_image: string;
        };
      } | null;
    };
  };
};

type ArtefactDelaysProdDeadlineDataItemNode = {
  node: ArtefactDelaysProdDeadlineDataItem;
};

function useLazyArtefactDelaysProductionDeadline(
  projectId: number,
  userId: number,
  isCurrent?: boolean,
  fetchPolicy?: WatchQueryFetchPolicy
) {
  const [getArtefactDelaysProdDeadline, { data, loading }] = useLazyQuery(GET_ARTEFACT_DELAYS_PRODUCTION_DEADLINE, {
    variables: {
      project_id: projectId,
      user_id: userId,
      is_current: isCurrent,
    },
    fetchPolicy: fetchPolicy || 'network-only',
  });

  const list = useMemo(() => {
    const temp = data?.artefact_delays_production_deadline?.edges?.map(
      (el: ArtefactDelaysProdDeadlineDataItemNode) => el?.node
    );
    return temp?.map((el: ArtefactDelaysProdDeadlineDataItem) => {
      return {
        id: el?.project_task_assignee?.project_task?.cached_linked_artefact?.id,
        artefact: {
          ...el?.project_task_assignee?.project_task?.cached_linked_artefact,
        },
        separateTask: el?.project_task_assignee?.project_task?.separate_task || null,
      };
    });
  }, [data]);

  return useMemo(() => {
    return {
      getArtefactDelaysProdDeadline,
      delayProdList: list as ComposeArtefactDelaysProdDeadline[],
      delayProdLoading: loading,
    };
  }, [getArtefactDelaysProdDeadline, list, loading]);
}

export default useLazyArtefactDelaysProductionDeadline;
