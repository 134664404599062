import React, { ChangeEvent, memo } from 'react';

import { SearchOutlined } from '@ant-design/icons';

import TextField from 'components/textField';

import useCommonContext from 'hooks/useCommonContext';

type UserAddBlockProps = {
  roleName?: string;
  search: string;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

function UserAddBlock({ roleName, search, onChange }: UserAddBlockProps) {
  const common = useCommonContext();
  return (
    <div className="drawer-select-user-add">
      <span className="drawer-select-user-add__label">{roleName}</span>
      <div className="flex-row flex-align-items--center">
        <TextField
          noForm
          size="large"
          value={search}
          prefix={<SearchOutlined />}
          onChange={onChange}
          placeholder={common.t<string>('pages.project.create.searchPlaceholder')}
        />
      </div>
    </div>
  );
}

export default memo(UserAddBlock);
