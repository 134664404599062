import React, { useState } from 'react';

import { message, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { onCopyFile, onUpdatePresentationSequence, onUploadSlide } from 'api/requests/files';
import withCommonProps from 'hocs/withCommonProps';
import { TemplateCommonProps } from 'common/commonPropsProvider';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { checkMimeTypePresentation } from 'utils';
import { BusinessRoleEnum } from 'types/entities/user';

import { changeOrderSequence } from 'utils/convert';

import SlidePreview from '../slidePreview';
import {
  ArtefactFile,
  ArtefactFileIteration,
  ArtefactListItem,
  PresentationFile,
  TaskInfoQueryData,
} from '../../task/types';
import { createArtefactVersion } from '../../task/subPages/finalPresentation/fetches';
import { messageDurability } from '../../../constants';

type GridPresentationProps = {
  files: ArtefactFile | null;
  refetch(): Promise<ApolloQueryResult<TaskInfoQueryData>>;
  userRole: BusinessRoleEnum | null;
  artefactFileVersion: ArtefactFileIteration | null;
  currentArtefact: ArtefactListItem | undefined;
  onClickPreviewSlide: (slideNum: number) => void;
};

function GridPresentation({
  files,
  refetch,
  userRole,
  common,
  artefactFileVersion,
  currentArtefact,
  onClickPreviewSlide,
}: GridPresentationProps & TemplateCommonProps) {
  const [progress, setProgress] = useState<number | null>(null);

  const callback = (progressEvent: any) => {
    let percentComplete = progressEvent.loaded / progressEvent.total;
    percentComplete = Number(percentComplete * 100);
    setProgress(percentComplete);
  };

  const onSaveImage = async (infoFile: UploadFile) => {
    const file = infoFile.originFileObj;
    if (file) {
      const { isImages } = checkMimeTypePresentation(file);

      if (!isImages) {
        message.error(common.t<string>('errors.messages.upload.onlyImage'), messageDurability);
      }

      if (file && files && isImages) {
        const formData = new FormData();
        formData.append('file', file);
        if (artefactFileVersion === null || artefactFileVersion?.is_active) {
          const newFile = await onCopyFile(Number(files?.pk)).then(async (resp: any) => {
            if (resp?.status === 401) {
              const newFileResp = await onCopyFile(Number(files?.pk)).then((response) => response);
              return newFileResp;
            } else {
              return resp;
            }
          });
          const newArtefactVersion = await createArtefactVersion({
            file_id: Number(newFile?.id),
            artefact_id: Number(currentArtefact?.id),
          });
          await onUploadSlide(Number(newArtefactVersion?.file_id), formData, callback).then(() => {
            refetch().then(() => setProgress(null));
          });
        } else {
          await onUploadSlide(Number(files.pk), formData, callback).then(() => {
            refetch().then(() => setProgress(null));
          });
        }
      }
    }
  };

  const onChangeUpload = async (info: UploadChangeParam<UploadFile>) => {
    await onSaveImage(info.file);
  };

  const customRequest = () => null;

  const changeOrder = async (
    currentOrder: number,
    newOrder: number,
    newFileId?: number,
    newFilesArr?: PresentationFile[]
  ) => {
    if (newFilesArr?.length && newFileId) {
      const updatedArr = changeOrderSequence(newFilesArr, currentOrder, newOrder);
      await onUpdatePresentationSequence(Number(newFileId), updatedArr).then(() => refetch());
    } else if (files?.related_files) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const arr: PresentationFile[] = [...files?.related_files];
      const updatedArr = changeOrderSequence(arr, currentOrder, newOrder);
      await onUpdatePresentationSequence(Number(files.pk), updatedArr).then(() => refetch());
    }
  };

  return (
    <div className="grid-presentation-wrap">
      <div className="grid-presentation">
        {files?.related_files?.map((s, index) => {
          const slide = s as PresentationFile;
          return (
            <SlidePreview
              key={slide.id}
              slide={slide}
              totalSlides={files?.related_files?.length}
              count={index + 1}
              fileId={files.pk}
              refetch={refetch}
              changeOrder={changeOrder}
              isShowControls={userRole === BusinessRoleEnum.productionExecutor}
              artefactFileVersion={artefactFileVersion}
              currentArtefact={currentArtefact}
              onClickPreviewSlide={onClickPreviewSlide}
            />
          );
        })}
        {userRole === BusinessRoleEnum.productionExecutor && (
          <Upload
            showUploadList={false}
            name="presentation"
            className="slide-preview-upload"
            onChange={onChangeUpload}
            customRequest={customRequest}
          >
            <SlidePreview
              upload
              progress={progress}
              refetch={refetch}
              artefactFileVersion={artefactFileVersion}
              currentArtefact={currentArtefact}
            />
          </Upload>
        )}
      </div>
    </div>
  );
}

export default withCommonProps(GridPresentation);
