import React, { useCallback, useEffect } from 'react';

import { AbilityContext } from 'config/Can';
import { buildAbilityFor } from 'config/ability';
import { noop } from 'lodash';
import { PATH_NAMES } from 'router/constants';

import useCommonContext from 'hooks/useCommonContext';
import useBoolean from 'hooks/useBoolean';

import SelectUserDrawer from '../components/selectUserDrawer';
import Participants from '../components/participants';
import { convertRoles } from '../../utils';
import useAddForm from '../users/hooks/useAddForm';
import useCompanyRoles from '../../hooks/queries/useCompanyRoles';
import Button from '../../components/button/Button';
import { ButtonTypesEnum } from '../../components/button/types';
import HeaderSmall from '../components/headerSmall/HeaderSmall';

import useAddCourseControl from './hooks/useAddCourseControl';
import AddCourseForm from './components/form';

import './styles.scss';

function AddCoursePage() {
  const [open, onOpen] = useBoolean(false);
  const common = useCommonContext();
  const { disabled, form, roleId, users, setRoleId, onSave, onSubmit } = useAddCourseControl({ needNavi: true });
  const userAddForm = useAddForm(null, undefined, noop, open);

  const ability = buildAbilityFor(convertRoles(common.businessRoles));
  const canICreate = ability.can('manage', 'Courses.addCourse');

  useEffect(() => {
    if (!canICreate) {
      common.navigate(PATH_NAMES.courses.base);
    }
  }, [canICreate, common]);

  const openDrawer = useCallback(
    (roleID: string) => {
      setRoleId(roleID);
      onOpen.on();
    },
    [onOpen, setRoleId]
  );

  const closeDrawer = useCallback(() => {
    setRoleId(null);
    onOpen.off();
  }, [onOpen, setRoleId]);
  const { companyRoles } = useCompanyRoles(common?.company?.id?.toString());
  const goBack = () => common.navigate(-1);

  return (
    <AbilityContext.Provider value={ability}>
      <div className="add-course">
        <HeaderSmall title={common.t<string>('pages.courses.createCourse')} onGoBack={goBack}>
          <Button size="large" className="ml_auto mr_16" onClick={goBack}>
            {common.t<string>('common.canceling')}
          </Button>
          <Button
            hintText={common.t<string>('common.requiredFields')}
            disabled={disabled}
            size="large"
            type={ButtonTypesEnum.primary}
            onClick={onSubmit}
          >
            {common.t<string>('common.create')}
          </Button>
        </HeaderSmall>
        <div className="add-course__wrapper">
          <AddCourseForm title={common.t<string>('pages.project.description')} form={form} />
          <Participants companyRoles={companyRoles} users={users} onOpenDrawer={openDrawer} />
        </div>
        <SelectUserDrawer
          closeAfterSave
          companyId={common?.company?.id?.toString()}
          roleId={roleId}
          visible={open}
          users={users}
          userAddForm={userAddForm}
          onClose={closeDrawer}
          onSave={onSave}
        />
      </div>
    </AbilityContext.Provider>
  );
}

export default AddCoursePage;
