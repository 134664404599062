import { UserExtended } from '../types/entities';

export const sortUserExtended = (users?: UserExtended[]): UserExtended[] | undefined => {
  if (!users) {
    return [];
  }
  const temp = [...users];
  return temp?.sort((a, b) => {
    if (a.user.first_name < b.user.first_name) {
      return -1;
    }
    if (a.user.first_name > b.user.first_name) {
      return 1;
    }
    return 0;
  });
};
