import { gql } from '@apollo/client';

export const GET_DASHBOARD_FILTERS = gql`
  query GetDashboardFilters(
    $companyId: Int
    $statesScope: String
    #    $userRoleIds: [Int]
    $userId: Int
    $isCurrentExecutor: Boolean
    $stateListVersionId: Int
  ) {
    company_states(company_id: $companyId, states_scope: $statesScope, state_list_version_id: $stateListVersionId) {
      id
      name
      state_type
    }
    company_learning_object_types(company_id: $companyId) {
      pk
      id
      name
      icon_image
      type
    }

    dashboard_filters {
      dashboard_filter_projects {
        id
        name
      }
      dashboard_filter_elements {
        id
        name
        type
      }
      dashboard_filter_artefact_types {
        id
        company_artefact_type {
          id
          name
        }
      }
      dashboard_filter_assignees(user_id: $userId, is_current_executor: $isCurrentExecutor) {
        is_current_executor
        project_user {
          company_user {
            id
            user {
              id
              email
              first_name
              last_name
            }
          }
        }
      }
      dashboard_filter_separate_task_types {
        id
        name
      }
      dashboard_filter_states {
        id
        name
        state_type
      }
    }
  }
`;
