import { Dispatch, SetStateAction, useState } from 'react';

import { Form, message } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { UserExtended } from 'types/entities';
import { onChangeProjectUsersList, onCreateProject } from 'api/requests/projects';
import { PATH_NAMES } from 'router/constants';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

import { ProjectType } from 'store/projects/interfaces';

import useCommonContext from 'hooks/useCommonContext';

import { NewProjectTypeResponse } from '../../courses/types';
import { messageDurability } from '../../../constants';

type Output = {
  disabled: boolean;
  form: FormInstance;
  roleId: string | null;
  users: UsersState;
  setRoleId: Dispatch<SetStateAction<string | null>>;
  onSave: (user: UserExtended | UserExtended[]) => void;
  onSubmit: () => void;
};

export type UsersState = {
  [name: string]: UserExtended[];
};

type FormProps = {
  needNavi?: boolean;
};

function useAddCourseControl({ needNavi }: FormProps): Output {
  const [roleId, setRoleId] = useState<string | null>(null);
  const [users, setUsers] = useState<UsersState>({});
  const common = useCommonContext();

  const [form] = Form.useForm();

  const name = Form.useWatch('name', form);
  const phase = Form.useWatch('phase_state', form);
  const state = Form.useWatch('state', form);
  const timezone = Form.useWatch('timezone', form);

  const disabled = !name || !phase || !state || !timezone;

  const onSave = (value: UserExtended | UserExtended[]) => {
    if (roleId) {
      if (Array.isArray(value)) {
        setUsers({
          ...users,
          [`${roleId}`]: [...value],
        });
      } else {
        setUsers({
          ...users,
          [`${roleId}`]: [value],
        });
      }
    }
  };

  const onSubmit = async () => {
    const formData = form.getFieldsValue();

    const payload = {
      name: formData.name,
      project_type: ProjectType?.content_creation,
      company_id: common?.company?.id,
      production_start_date: formData?.production?.[0] ? dayjs(formData?.production[0]).format('YYYY-MM-DD') : null,
      production_finish_date: formData?.production?.[1] ? dayjs(formData?.production[1]).format('YYYY-MM-DD') : null,
      phase_state_id: Number(formData.phase_state),
      state_id: Number(formData.state),
      project_group_id: Number(formData.specialization),
      description: formData.description,
      timezone: formData.timezone,
    };

    const response: NewProjectTypeResponse = await onCreateProject(payload);

    if (response.id && !isEmpty(users)) {
      const projectUsers = Object.entries(users).map((el) => {
        return {
          role_id: el[0],
          project_users: el[1].map((u) => u?.pk),
        };
      });

      const payloadUsers = {
        roles: projectUsers,
      };
      await onChangeProjectUsersList(response.id, payloadUsers)
        .then(() => {
          message.success(common.t<string>('pages.courses.createdSuccessful'), 2.5);
          if (needNavi) {
            common.navigate(PATH_NAMES.courses.base);
          }
        })
        .catch(() => {
          message.error(common.t<string>('pages.courses.createdFail'), messageDurability);
        });
    }

    if (response.id && isEmpty(users)) {
      message.success(common.t<string>('pages.courses.createdSuccessful'), 2.5);
      if (needNavi) {
        common.navigate(PATH_NAMES.courses.base);
      }
    }
  };

  return {
    disabled,
    form,
    roleId,
    setRoleId,
    users,
    onSave,
    onSubmit,
  };
}

export default useAddCourseControl;
