/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Company } from './Company';

export type ProjectRead = {
    readonly id?: number;
    name: string;
    project_type: ProjectRead.project_type;
    company: Company;
    readonly project_task_state_list_version_id?: string;
    project_state_list_name: string;
};

export namespace ProjectRead {

    export enum project_type {
        CONTENT_CREATION = 'content_creation',
        TRANSLATE = 'translate',
    }


}
