import React, { useEffect, useRef } from 'react';

import { ExpandAltOutlined, ShrinkOutlined } from '@ant-design/icons';
import EditorJS from '@editorjs/editorjs';
import { useParams } from 'react-router-dom';
import useSeparateTaskAbility from 'pages/hooks/useSeparateTaskAbility';
import { cx } from 'utils';

import Button from 'components/button/Button';
import { ButtonTypesEnum } from 'components/button/types';
import Editor from 'components/editor';

import useCommonContext from 'hooks/useCommonContext';
import useBoolean from 'hooks/useBoolean';

import { getGlobalTaskEditorTools } from '../../../createSeparateTask/tools';

import './styles.scss';
import { EDITOR_HOLDER_ID } from '../../../../components/editor/Editor';
import useHover from '../../../../hooks/useHover';

type EditorBlockProps = {
  initialize?: (core: EditorJS | null) => void;
  readonly?: boolean;
  data?: string | null;
  onReadyEditor?: () => void;
  onChangeEditor: () => void;
};

function EditorBlock({ initialize, readonly, data, onReadyEditor, onChangeEditor }: EditorBlockProps) {
  const [open, onOpen] = useBoolean(false);
  const common = useCommonContext();
  const { taskId } = useParams();
  const { canIEdit } = useSeparateTaskAbility(taskId);
  const value = data ? JSON.parse(data) : null;

  const classNames = cx('view-free-task-page__editor-wrap', {
    'view-free-task-page__editor-wrap--opened': open,
  });

  return (
    <div className={classNames}>
      {/*<Button*/}
      {/*  className="editor-expand"*/}
      {/*  size="large"*/}
      {/*  type={ButtonTypesEnum.text}*/}
      {/*  icon={open ? <ShrinkOutlined style={{ fontSize: 20 }} /> : <ExpandAltOutlined style={{ fontSize: 20 }} />}*/}
      {/*  onClick={onOpen.toggle}*/}
      {/*/>*/}
      <span className="view-free-task-page__label">{common.t<string>('pages.project.create.editor.shortLabel')}</span>
      <Editor
        placeholder={common.t<string>('pages.project.create.editor.placeholder')}
        initialize={initialize}
        defaultValue={value}
        onReadyEditor={onReadyEditor}
        onChange={onChangeEditor}
        readOnly={readonly || !canIEdit}
        tools={getGlobalTaskEditorTools()}
      />
      {/*{open || focus ? null : <div className="editor-overlay" />}*/}
    </div>
  );
}

export default EditorBlock;
