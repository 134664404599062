import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { cx } from 'utils';
import { CaretDownOutlined, CaretLeftOutlined } from '@ant-design/icons';
import { noop } from 'lodash';
import { ProjectLearningStructureEnum } from 'types/entities';

import Block from 'components/block/Block';
import Checkbox from 'components/checkbox';
import { ButtonTypesEnum } from 'components/button/types';
import Button from 'components/button';

import { getIconByUrl, getStageImageByType } from 'utils/icons';

import { PlanTreeItemProps } from '../../types';
import MainContent from '../mainContent';
import ExtraContent from '../extraContent/ExtraContent';

import './styles.scss';

function ProjectPlanTreeItem({ node, params, isEdit, selected, onToggleCheckbox, onToggleTask }: PlanTreeItemProps) {
  const [open, setOpen] = useState<boolean>(false); // It is necessary to show ExtraContent
  const classNames = cx('course-tree-plan-item', {
    [`course-tree-plan-item--edit`]: isEdit,
    [`course-tree-plan-item--opened`]: params.isOpen,
    [`course-tree-plan-item--level-${params.depth}`]: params.depth === 0 || params.depth,
  });

  useEffect(() => {
    if (params.isOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [params.isOpen]);

  const onToggleExtra = () => setOpen(!open);

  const onChange = useCallback(() => {
    onToggleCheckbox(Number(node.id));
  }, [node.id, onToggleCheckbox]);

  const isSelect = useMemo(() => {
    return !!selected.find((s) => s === node.id.toString());
  }, [node.id, selected]);

  return (
    <>
      <div
        className={classNames}
        onClick={
          isEdit
            ? noop
            : node.data?.learningStructureType === ProjectLearningStructureEnum.production_item
            ? onToggleExtra
            : params.onToggle
        }
      >
        <div className="course-tree-plan-item__inner">
          <Block empty hidden={!isEdit}>
            <Checkbox className="course-tree-plan-item__checkbox" onChange={onChange} checked={isSelect} />
          </Block>
          <Block empty hidden={isEdit}>
            <div className="course-tree-plan-item__checkbox course-tree-plan-item__checkbox-empty" />
          </Block>
          <Block
            hidden={node.data?.learningStructureType === ProjectLearningStructureEnum.production_item}
            className="course-tree-plan-item__count"
          >
            {node?.data?.humanReadableNumber}
          </Block>
          <Block
            className="course-tree-plan-item__count-wrap"
            hidden={node.data?.learningStructureType !== ProjectLearningStructureEnum.production_item}
          >
            <span className="course-tree-plan-item__count">{node?.data?.humanReadableNumber}</span>
          </Block>
          <Block
            className="course-tree-plan-item__icon-wrap"
            hidden={node.data?.learningStructureType !== ProjectLearningStructureEnum.production_item}
          >
            {getIconByUrl(node?.data?.learningObjectType?.icon_image || '')}
          </Block>
          <MainContent node={node} params={params} />
          <div className="course-tree-plan-item__right">
            <div className="course-tree-plan-item__progress">
              <span className="course-tree-plan-item__progress__value">{Math.round(node?.data?.progress || 0)}</span>
              <span className="course-tree-plan-item__progress__text">%</span>
            </div>
            <Block className={cx('course-tree-plan-item__toggle')}>
              <Button
                size="large"
                type={ButtonTypesEnum.text}
                icon={
                  params.isOpen || open ? (
                    <CaretDownOutlined style={{ color: '#848484' }} />
                  ) : (
                    <CaretLeftOutlined style={{ color: '#848484' }} />
                  )
                }
                onClick={
                  !isEdit
                    ? noop
                    : node.data?.learningStructureType === ProjectLearningStructureEnum.production_item
                    ? onToggleExtra
                    : params.onToggle
                }
              />
            </Block>
          </div>
        </div>
        <Block empty hidden={!open}>
          <ExtraContent
            depth={params.depth}
            learningObjectId={node?.id?.toString()}
            data={node?.data?.artefactSequence}
            isEdit={isEdit}
            selected={selected}
            onToggleTask={onToggleTask}
            onToggleCheckbox={onToggleCheckbox}
          />
        </Block>
      </div>
    </>
  );
}

export default ProjectPlanTreeItem;
