import React from 'react';

import TruncateMarkup from 'react-truncate-markup';
import { ProjectSeparateTaskItem } from 'types/entities/separate';
import { useParams } from 'react-router-dom';
import useSeparateTaskAbility from 'pages/hooks/useSeparateTaskAbility';
import { noop } from 'lodash';

import Avatar from 'components/avatar';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

type SeparateTaskPanelUsersProps = {
  task?: ProjectSeparateTaskItem;
  openDrawer: () => void;
};

function SeparateTaskPanelUsers({ task, openDrawer }: SeparateTaskPanelUsersProps) {
  const { taskId } = useParams();
  const common = useCommonContext();
  const { canIEdit } = useSeparateTaskAbility(taskId);

  return (
    <div className="ft-panel-users">
      <div className="ft-panel-users__block">
        <span className="ft-panel-users__label">{common.t<string>('pages.task.freeTask.panel.performer')}</span>
        {task?.current_assignees.map((user) => {
          return (
            <div role="button" key={user.id} className="ft-panel-users__info" onClick={canIEdit ? openDrawer : noop}>
              <Avatar size={40} src={user?.project_user?.company_user?.user?.icon_image}>
                {user?.project_user?.company_user?.user?.name?.[0]}
              </Avatar>
              <TruncateMarkup lines={1}>
                <span className="ft-panel-users__user-name">{user?.project_user?.company_user?.user?.name}</span>
              </TruncateMarkup>
            </div>
          );
        })}
      </div>
      <div className="ft-panel-users__block">
        <span className="ft-panel-users__label">{common.t<string>('pages.task.freeTask.panel.taskAuthor')}</span>
        <div className="ft-panel-users__info ft-panel-users__info--author">
          <Avatar size={40} src={task?.creator?.icon_image}>
            {task?.creator?.name?.[0]}
          </Avatar>
          <TruncateMarkup lines={1}>
            <span className="ft-panel-users__user-name">{task?.creator?.name}</span>
          </TruncateMarkup>
        </div>
      </div>
    </div>
  );
}

export default SeparateTaskPanelUsers;
