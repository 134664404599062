import React, { ChangeEvent, memo, useMemo } from 'react';

import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import MultipleChoice from 'pages/components/selectUserDrawer/components/MultipleChoice';
import { SearchOutlined } from '@ant-design/icons';
import { getFilteredUserExt } from 'utils';
import { UserExtended } from 'types/entities';

import { Drawer, DrawerFooter, DrawerHeader } from 'components/drawer';
import TextField from 'components/textField';
import Button from 'components/button';

import useCommonContext from 'hooks/useCommonContext';

import AddParticipantsForm from '../addParticipantsForm';
import { UseAddFormOutput } from '../../users/hooks/useAddForm';

import './styles.scss';

type SelectUserDrawerProps = {
  userAddInstance: UseAddFormOutput;
  companyId?: string;
  visible: boolean;
  disabled: boolean;
  view: 'select' | 'add';
  search: string;
  collaborators?: UserExtended[];
  selectedUsers: UserExtended[] | null;
  onChangeView: () => void;
  onChangeUsers: (checkedValue: Array<CheckboxValueType>) => void;
  onChangeSearch: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onClose: () => void;
  onSubmit: () => void;
};

function SeparateTaskUserDrawer({
  userAddInstance,
  visible,
  companyId,
  disabled,
  view,
  search,
  collaborators,
  selectedUsers,
  onChangeView,
  onChangeUsers,
  onChangeSearch,
  onClose,
  onSubmit,
}: SelectUserDrawerProps) {
  const common = useCommonContext();

  const header = useMemo(
    () => (
      <DrawerHeader title={common.t<string>('pages.project.create.choicePerformer')} onClose={onClose}>
        <Button size="middle" onClick={onChangeView}>
          {common.t<string>('common.add')}
        </Button>
      </DrawerHeader>
    ),
    [common, onChangeView, onClose]
  );

  const footer = useMemo(
    () => (
      <DrawerFooter
        labelSubmit={common.t<string>('common.save')}
        labelCancel={common.t<string>('common.canceling')}
        disabled={disabled}
        onSubmit={onSubmit}
        onCancel={onClose}
      />
    ),
    [common, disabled, onClose, onSubmit]
  );

  return (
    <Drawer
      className="create-task-select-user"
      open={visible}
      closable={false}
      destroyOnClose
      maskClosable={true}
      placement="right"
      width={560}
      title={header}
      footer={footer}
    >
      {view === 'add' ? (
        <div className="create-task-select-user__content">
          <AddParticipantsForm companyId={companyId} addForm={userAddInstance} />
        </div>
      ) : (
        <div className="create-task-select-user__content">
          <TextField
            noForm
            className="mb_24"
            size="large"
            prefix={<SearchOutlined style={{ fontSize: 14 }} />}
            placeholder={common.t<string>('pages.project.create.searchPlaceholder')}
            value={search}
            onChange={onChangeSearch}
          />
          <MultipleChoice
            data={selectedUsers}
            userList={getFilteredUserExt(search, collaborators)}
            onChangeMultiple={onChangeUsers}
          />
        </div>
      )}
    </Drawer>
  );
}

export default memo(SeparateTaskUserDrawer);
