enum IssuesTypes {
  SET_ISSUES_DATA = 'ISSUES/SET_ISSUES_DATA',
}
export type IssueType = {
  name: string;
  description: string;
};
export type PresentationIssueRequestPayloadType = {
  artefact_id: number;
  slide_number: number;
  artefact_content_issue_id: number;
  description: string;
  coordinate_x: number | undefined;
  coordinate_y: number | undefined;
};

export type VideoIssueRequestPayloadType = {
  artefact_id: number;
  artefact_content_issue_id: number;
  description?: string;
  timestamp_start: number | null;
  timestamp_end: number | null;
};

export type PresentationUpdateIssueRequestPayloadType = {
  artefact_content_issue_id: number;
  description: string;
};
export type StatusCommentStateRequestPayloadType = {
  message: string;
};
export default IssuesTypes;
