import { gql } from '@apollo/client';

export const GET_DASHBOARD_COLLABORATORS = gql`
  query DashboardCollaborators {
    front_page_collaborators {
      total_amount
      managers
      instructional_designers
      overcommited
      underused
    }
  }
`;

export const GET_DASHBOARD_COLLABORATORS_GANTT = gql`
  query DashboardCollaboratorsGantt {
    front_page_collaborators_gantt {
      min_date
      max_date
      users {
        id
        name
        icon_image
        roles {
          id
          role
          name
        }
        projects {
          id
          name
          production_finish_date
          production_start_date
          production_progress
        }
      }
    }
  }
`;
