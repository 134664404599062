/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CompanyAsyncTask = {
    readonly id?: number;
    readonly company_id?: string;
    task_type: CompanyAsyncTask.task_type;
    state?: CompanyAsyncTask.state;
    state_message?: string;
    data?: any;
};

export namespace CompanyAsyncTask {

    export enum task_type {
        COMPANY_IMPORT = 'company_import',
        COPY_COMPANY = 'copy_company',
        IMPORT_SETTINGS = 'import_settings',
        IMPORT_COB_PROJECT = 'import_cob_project',
    }

    export enum state {
        NEW = 'new',
        IN_PROGRESS = 'in_progress',
        DONE = 'done',
        CANCELLED = 'cancelled',
        REJECTED = 'rejected',
    }


}
