import React, { ReactNode, useCallback, useEffect } from 'react';

import { Form } from 'antd';
import { getCompanyRolesByUserRoles } from 'utils';
import Can from 'config/Can';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { DefaultOptionType } from 'rc-select/lib/Select';

import ContentBlock from 'components/contentBlock/ContentBlock';
import TextField from 'components/textField';
import Block from 'components/block';
import Select from 'components/select';

import useCommonContext from 'hooks/useCommonContext';
import useCompaniesForInvite from 'hooks/queries/useCompaniesForInvite';
import useCompanyRoles from 'hooks/queries/useCompanyRoles';

type AddEmployeeFormProps = {
  tab?: string;
  form?: FormInstance;
  isEdit?: boolean;
  companyId?: string;
  handleFormChange?: () => void;
};

function AddEmployeeForm({ companyId, isEdit, tab, form, handleFormChange }: AddEmployeeFormProps) {
  const common = useCommonContext();
  const { companiesForSelect } = useCompaniesForInvite();
  const { companyRolesForSelect } = useCompanyRoles(companyId);

  const user = form?.getFieldValue('user');

  useEffect(() => {
    if (!tab) {
      form?.setFieldValue('company', common.company?.id);
    }
  }, [common.company?.id, form, tab]);

  const filterOption = useCallback((inputValue: string, option?: DefaultOptionType) => {
    const label = option?.label || '';
    return label?.toString()?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  }, []);

  return (
    <Form
      id="add-employee"
      form={form}
      className="form-add_employee"
      layout="vertical"
      requiredMark={false}
      onFieldsChange={handleFormChange}
    >
      <Block empty hidden={!tab || tab === 'existing'}>
        <TextField
          name="new_company"
          size="xl"
          label={common.t<string>('form.field.newOrganization.label')}
          placeholder={common.t<string>('form.field.newOrganization.placeholder')}
          rules={[{ required: true, message: `${common.t<string>('common.requiredFields')}` }]}
        />
      </Block>
      <Block invisible={!tab || tab === 'new'}>
        <Select
          name="company"
          size="xl"
          label={common.t<string>('form.field.organization.label')}
          placeholder={common.t<string>('form.field.organization.placeholder')}
          options={companiesForSelect}
        />
      </Block>
      <Block empty hidden={!!tab}>
        <Can I="view" a="Users.info.company">
          <ContentBlock
            className="organisation-block"
            label={common.t<string>('pages.users.organisation')}
            text={user?.company?.name || common.company?.name}
          />
        </Can>
      </Block>

      <Block empty hidden={!!tab}>
        <Can I="update" a="Users.info.company">
          <Select
            name="company"
            size="xl"
            label={common.t<string>('form.field.organization.label')}
            placeholder={common.t<string>('form.field.organization.placeholder')}
            options={companiesForSelect}
          />
        </Can>
      </Block>

      <TextField
        name="name"
        size="xl"
        label={common.t<string>('form.field.fio.label')}
        placeholder={common.t<string>(isEdit ? 'form.field.fioEdit.placeholder' : 'form.field.fio.placeholder')}
        rules={[{ required: true, message: `${common.t<string>('common.requiredFields')}` }]}
      />
      <TextField
        size="xl"
        name="email"
        disabled={isEdit}
        label={common.t<string>('form.field.email.label')}
        placeholder={common.t<string>('form.field.email.placeholder')}
        rules={[{ required: true, type: 'email', message: common.t<string>('errors.required.email') }]}
      />

      <Block empty>
        <Select
          name="roles"
          size="xl"
          mode="multiple"
          label={common.t<string>('form.field.prodRole.label')}
          placeholder={common.t<string>('form.field.prodRole.placeholder')}
          options={getCompanyRolesByUserRoles(companyRolesForSelect, common.businessRoles)}
          rules={[{ required: true, message: `${common.t<string>('common.requiredFields')}` }]}
          filterOption={filterOption}
        />
      </Block>
    </Form>
  );
}

export default AddEmployeeForm;
