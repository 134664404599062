import React, { useCallback, useEffect } from 'react';

import { ExportOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { localStorageService } from 'services/localStorageService';
import history from 'router/history/history';
import { PATH_NAMES } from 'router/constants';
import { TFunction } from 'i18next';

import Avatar from 'components/avatar';
import DropdownMenu from 'components/dropdownMenu';
import Block from 'components/block';

import { logoutAction } from 'store/auth/actions';

import useCommonContext from 'hooks/useCommonContext';

import useVisible from '../../hooks/useVisible';
import { BusinessRoleEnum } from '../../types/entities';

type MainLayoutFooterProps = {
  closed: boolean;
};

function MainLayoutFooter({ closed }: MainLayoutFooterProps) {
  const { ref, isVisible, setIsVisible } = useVisible(false);
  const common = useCommonContext();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!closed && isVisible) {
      setIsVisible(false);
    }
  }, [closed, isVisible, setIsVisible]);

  const onLogout = useCallback(() => {
    localStorageService.removeAuthData();
    dispatch(logoutAction());
    history.push(PATH_NAMES.auth.login);
  }, [dispatch]);

  const getList = (t: TFunction) => {
    return [
      {
        id: '1',
        label: t('common.account'),
        icon: <UserOutlined />,
        action: () => {
          common?.navigate(PATH_NAMES.account.base);
        },
      },
      {
        id: '2',
        label: 'Management settings',
        icon: <SettingOutlined />,
        action: () => {
          common?.navigate(`${PATH_NAMES.management.base}/${PATH_NAMES.management.status}`);
        },
        hidden: !common.isStaff,
      },
      {
        id: '3',
        label: t('common.logout'),
        icon: <ExportOutlined />,
        action: onLogout,
      },
    ];
  };

  const onClick = useCallback(() => setIsVisible(true), []);

  return (
    <div ref={ref} role="button" className="main-sidebar-footer" onClick={onClick}>
      <Avatar size={40} src={common?.user?.icon_image}>
        {common.user?.first_name?.[0]}
      </Avatar>
      <div className="main-sidebar-footer__user">
        <span className="main-sidebar-footer__user-role">{common.businessRole?.name}</span>
        <span className="main-sidebar-footer__user-name">
          {common?.user?.name || `${common.user?.first_name} ${common.user?.last_name}`}
        </span>
      </div>

      <Block empty hidden={!isVisible}>
        <DropdownMenu list={getList(common.t)} />
      </Block>
    </div>
  );
}

export default MainLayoutFooter;
