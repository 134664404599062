import React, { memo, useEffect, useState } from 'react';

import moment from 'moment';

import Select from '../../../../components/select/Select';
import { ArtefactVersion } from '../../types';
import { cx } from '../../../../utils';
import './styles.scss';
import useCommonContext from '../../../../hooks/useCommonContext';

type TextProps = {
  iterations: ArtefactVersion[] | [] | undefined;
  setSearchParams: any;
  searchParams: any;
  taskTypeName?: string | undefined;
  className?: string;
};

function HeaderVersions({ iterations, setSearchParams, searchParams, className }: TextProps) {
  const versionParam = searchParams?.get('version');
  const common = useCommonContext();
  const classNames = cx('ccm-version', className);
  const [iterationRemapped, setIterationRemapped] = useState<Array<any>>();

  const getCurrentInitVersion = () => {
    if (versionParam) {
      return iterationRemapped?.find((iter: any) => iter?.number === Number(versionParam));
    } else {
      return iterationRemapped?.find((iter: any) => iter?.is_current === true);
    }
  };

  const [currentVersion, setCurrentVersion] = useState(getCurrentInitVersion());
  const handleChangeVersion = (value: string, option: any) => {
    const issueA = searchParams?.get('issue');
    setCurrentVersion(option);
    const versionNumberParam = option?.number?.toString();
    if (issueA) {
      setSearchParams({ version: versionNumberParam, issue: issueA });
    } else {
      setSearchParams({ version: versionNumberParam });
    }
  };
  useEffect(() => {
    if (iterations?.length) {
      const iterationsItems = iterations?.map((iteration) => {
        return {
          ...iteration,
          label: `v${iteration.number} ${common.t<string>('common.from')} ${moment(iteration.created_at).format(
            'Do MMMM YYYY, h:mm'
          )}`,
          value: iteration?.id,
          key: iteration?.id,
        };
      });
      setIterationRemapped(iterationsItems);
    } else {
      setIterationRemapped([{ label: 'v1', value: 'v1', key: 1 }]);
    }
  }, [iterations]);

  useEffect(() => {
    if (versionParam) {
      const isCurrentUserVersion = iterationRemapped?.find((iter) => iter?.number === Number(versionParam));
      setCurrentVersion(isCurrentUserVersion);
    } else {
      const isCurrentVersion = iterationRemapped?.find((iter) => iter?.is_current === true);
      setCurrentVersion(isCurrentVersion);
    }
  }, [iterationRemapped, versionParam]);

  return (
    <div className={classNames}>
      {/*<span className="ccm-version__name">{taskTypeName}</span>*/}
      <Select
        classNameDropdown="ccm-version-menu"
        options={iterationRemapped}
        noForm
        value={iterations?.length ? currentVersion : 'v1'}
        onChange={handleChangeVersion}
        className="testclass-artefact-version"
        disabled={!iterations?.length}
      />
    </div>
  );
}

export default memo(HeaderVersions);
