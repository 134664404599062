import { TagProps } from 'antd/lib/tag';

export enum TagShapeEnum {
  default = 'default',
  circle = 'circle',
}

export enum TagSizeEnum {
  small = 'small',
  medium = 'medium',
}

export interface ITagProps extends TagProps {
  className?: string;
  shape?: TagShapeEnum;
  size?: TagSizeEnum;
}
