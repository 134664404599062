import React, { useCallback, useMemo } from 'react';

import modalService from 'services/modalService';
import { UserExtended } from 'types/entities';
import { message } from 'antd';
import Can from 'config/Can';
import { onUpdateUserState } from 'api/requests/users';

import { Drawer, DrawerFooter, DrawerHeader } from 'components/drawer';
import LoadingOverlay from 'components/loadingOverlay';

import useCommonContext from 'hooks/useCommonContext';
import useBoolean from 'hooks/useBoolean';

import AddEmployeeForm from '../../components/addParticipantsForm/AddEmployeeForm';
import useAddForm from '../hooks/useAddForm';
import { messageDurability } from '../../../constants';

import ActionBar from './ActionBar';
import ViewContent from './ViewContent';
import './styles.scss';

type ViewEmployeeDrawerProps = {
  visible: boolean;
  user: UserExtended | null;
  onClose: () => void;
  onFetchUser: () => Promise<any>;
};

function ViewEmployeeDrawer({ visible, user, onClose, onFetchUser }: ViewEmployeeDrawerProps) {
  const [edit, onEdit] = useBoolean(false);

  const onClickClose = useCallback(() => {
    onEdit.off();
    onClose();
  }, [onClose, onEdit]);

  const { loading, form, onUpdate, disableSubmit, handleFormChange } = useAddForm(
    user,
    onFetchUser,
    onEdit.off,
    visible,
    !edit
  );
  const common = useCommonContext();

  const onBlockUser = useCallback(async () => {
    await onUpdateUserState(user?.user.id || '', 'BLOCKED');
    await onFetchUser();
    message.info(common.t<string>('messages.blocked'), messageDurability);
  }, [common, onFetchUser, user?.user.id]);

  const onInviteUser = useCallback(async () => {
    await onUpdateUserState(user?.user.id || '', 'INVITED');
    await onFetchUser();
  }, [onFetchUser, user?.user.id]);

  const blockUser = useCallback(
    () =>
      modalService.openConfirmModal({
        title: common.t<string>('pages.users.popover.blockUser.title'),
        text: common.t<string>('pages.users.popover.blockUser.text'),
        labelCancel: common.t<string>('pages.users.popover.blockUser.cancel'),
        labelConfirm: common.t<string>('pages.users.popover.blockUser.confirm'),
        isDanger: true,
        onConfirm: onBlockUser,
      }),
    [common, onBlockUser]
  );

  const inviteUser = useCallback(
    () =>
      modalService.openConfirmModal({
        title: common.t<string>('pages.users.popover.inviteUser.title'),
        text: common.t<string>('pages.users.popover.inviteUser.text'),
        labelCancel: common.t<string>('pages.users.popover.inviteUser.cancel'),
        labelConfirm: common.t<string>('pages.users.popover.inviteUser.confirm'),
        isDanger: false,
        onConfirm: onInviteUser,
      }),
    [common, onInviteUser]
  );

  const header = useMemo(() => {
    return (
      <DrawerHeader
        title={edit ? common.t<string>('pages.users.editEmployee') : common.t<string>('pages.users.employee')}
        goBack={edit ? onEdit.off : undefined}
        onClose={onClickClose}
      >
        <Can I="update" a="Users.info">
          {!edit ? (
            <ActionBar userState={user?.user.state} onBlock={blockUser} onInvite={inviteUser} onEdit={onEdit.on} />
          ) : null}
        </Can>
      </DrawerHeader>
    );
  }, [blockUser, common, edit, inviteUser, onClickClose, onEdit.off, onEdit.on, user?.user.state]);

  const footer = useMemo(() => {
    return (
      <DrawerFooter
        loading={loading}
        labelSubmit={common.t<string>('common.save')}
        disabled={disableSubmit}
        onSubmit={onUpdate}
        labelCancel={common.t<string>('common.canceling')}
        onCancel={onClickClose}
        formId="add-employee"
      />
    );
  }, [common, disableSubmit, loading, onClickClose, onUpdate]);

  return (
    <Drawer
      className="drawer-view-employee"
      open={visible}
      closable={false}
      onClose={onClickClose}
      maskClosable={true}
      destroyOnClose
      placement="right"
      width={560}
      title={header}
      footer={edit ? footer : null}
    >
      <LoadingOverlay show={loading} text={common.t<string>('pages.users.sendInvitation')} />
      {edit ? (
        <AddEmployeeForm
          companyId={common?.company?.id.toString()}
          isEdit
          form={form}
          handleFormChange={handleFormChange}
        />
      ) : (
        <ViewContent user={user} />
      )}
    </Drawer>
  );
}

export default ViewEmployeeDrawer;
