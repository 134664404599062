import React, { memo, useMemo } from 'react';

import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import ChooseExportVideo from 'pages/components/chooseExportVideo';
import {
  textCourseOptions,
  videoResolutionOptions,
} from 'pages/project/subPages/content/components/downloadDrawer/constants';
import { NodeModel } from '@minoru/react-dnd-treeview/dist/types';
import { AdminCompanyLearningObjectType } from 'types';

import Popover from 'components/popover';
import Button from 'components/button';
import { ButtonTypesEnum } from 'components/button/types';
import Tooltip from 'components/tooltip';
import { RadioGroup } from 'components/radio';

import useCommonContext from 'hooks/useCommonContext';

import { CustomData } from '../../types';

import './styles.scss';

type ExportElementPopupProps = {
  singleExport: any;
  anchor?: any;
  visible: boolean;
  node: NodeModel<CustomData>;
  onShow: () => void;
  onClose: () => void;
};

function ExportElementPopup({ singleExport, anchor, visible, node, onShow, onClose }: ExportElementPopupProps) {
  const common = useCommonContext();
  const content = useMemo(() => {
    return (
      <div className="export-element-popup__wrap">
        <div className="export-element-popup__content">
          {node?.data?.learningObjectType?.type === AdminCompanyLearningObjectType.type.VIDEO ? (
            <ChooseExportVideo
              options={videoResolutionOptions}
              value={singleExport.video}
              onChange={singleExport.onChangeVideo}
            />
          ) : (
            <RadioGroup
              name="doc"
              size="large"
              options={textCourseOptions}
              variant="button"
              value={singleExport.doc}
              onChange={singleExport.onChangeDocFormat}
            />
          )}
        </div>
        <div className="export-element-popup__footer">
          <Button className="mr_8" type={ButtonTypesEnum.default} icon={<CloseOutlined />} onClick={onClose} />
          <Button className="mr_8" onClick={singleExport.onCopyExportToClipboard}>
            {common.t<string>('pages.project.popover.export.copyLink')}
          </Button>
          <Button type={ButtonTypesEnum.primary} onClick={singleExport.onExport}>
            {common.t<string>('pages.project.popover.export.download')}
          </Button>
        </div>
      </div>
    );
  }, [
    common,
    node?.data?.learningObjectType?.type,
    onClose,
    singleExport.doc,
    singleExport.onChangeDocFormat,
    singleExport.onChangeVideo,
    singleExport.onCopyExportToClipboard,
    singleExport.onExport,
    singleExport.video,
  ]);
  const learningObjectType = node?.data?.learningObjectType?.type;
  return (
    <div ref={anchor}>
      <Popover
        visible={visible}
        showArrow={false}
        trigger="click"
        placement="bottomRight"
        overlayClassName="export-element-popup"
        destroyTooltipOnHide={true}
        content={content}
        getPopupContainer={(triggerNode) => triggerNode}
        onVisibleChange={singleExport.onOpenChange}
      >
        <Tooltip
          visible={singleExport.opened ? false : undefined}
          title={common?.t<string>('pages.project.tooltips.exportMaterials')}
        >
          <Button
            className="ml_8"
            size="large"
            type={ButtonTypesEnum.text}
            icon={<DownloadOutlined />}
            onClick={onShow}
          />
        </Tooltip>
      </Popover>
    </div>
  );
}

export default memo(ExportElementPopup);
