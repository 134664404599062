import React, { memo } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import Button from 'components/button';
import { ButtonTypesEnum } from 'components/button/types';

import useCommonContext from '../../hooks/useCommonContext';

import styles from './Confirm.module.scss';

export enum ConfirmModalIcon {
  warning = 'warning',
}

export type ConfirmModalProps = {
  title?: string;
  text?: string;
  icon?: ConfirmModalIcon;
  labelCancel?: string;
  labelConfirm?: string;
  onCancel?: () => void;
  cancelAction?: () => void;
  onConfirm?: () => void;
  isDanger?: boolean;
};

const getIcon = (type: ConfirmModalIcon) => {
  switch (type) {
    case ConfirmModalIcon.warning:
      return <ExclamationCircleOutlined className={styles.iconWarning} />;
    default:
      return <ExclamationCircleOutlined />;
  }
};

function ConfirmModal({
  title,
  text,
  icon,
  labelCancel,
  labelConfirm,
  isDanger,
  onCancel,
  cancelAction,
  onConfirm,
}: ConfirmModalProps) {
  const common = useCommonContext();
  const onClickConfirm = () => {
    onConfirm?.();
    onCancel?.();
  };

  const onClickCancel = () => {
    cancelAction?.();
    onCancel?.();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className="flex-row flex-align-items--center mb_8">
          {icon && getIcon(icon)}
          <span className={styles.title}>{title}</span>
        </div>
        <span className={styles.text}>{text}</span>
      </div>
      <div className={styles.footer}>
        <Button size="large" type={ButtonTypesEnum.default} onClick={onClickCancel}>
          {labelCancel || common.t<string>('common.cancel')}
        </Button>
        <Button size="large" danger={isDanger} type={ButtonTypesEnum.primary} className="ml_8" onClick={onClickConfirm}>
          {labelConfirm || common.t<string>('common.confirm')}
        </Button>
      </div>
    </div>
  );
}

export default memo(ConfirmModal);
