import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { chunkSize, createChunks } from 'utils';
import {
  CreateFileResponse,
  onCreateArtefactVersion,
  onCreateFile,
  onSetFileState,
  onUploadFile,
} from 'api/requests/files';
import { UploadStateEnum } from 'types/entities';
import { createSeparateTask, CreateSeparateTaskResponse } from 'api/requests/projectTask';

export const onUploadFileInPart = async (
  file: RcFile,
  fileId: number,
  updateUpload?: (loaded: number) => void,
  callback?: () => void,
  canceled?: any
) => {
  const chunks = createChunks(file, chunkSize);
  let iterator = 1;
  let loaded = 0;
  let stopLoop = false;

  const onUploadProgress = (progressEvent: any) => {
    if (chunks.length) {
      updateUpload?.(chunkSize * loaded + progressEvent.loaded);
    }
  };

  for await (const chunk of chunks) {
    const formData = new FormData();
    formData.append('part', iterator.toString());
    formData.append('file', chunk);
    const config = {
      timeout: 75000,
      onUploadProgress: (progressEvent: any) => onUploadProgress(progressEvent),
    };

    if (!stopLoop) {
      if (!canceled?.current) {
        await onUploadFile(fileId, formData, config)
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          .then(async (re) => {
            loaded = loaded + 1;
            iterator = iterator + 1;

            if (canceled?.current) {
              onSetFileState(fileId, { state: UploadStateEnum.CANCELLED })
                .then(() => callback?.())
                .catch(() => {
                  stopLoop = true;
                  callback?.();
                });
            }

            if (loaded === chunks.length) {
              console.log('Process is complete, counter', loaded);
              await onSetFileState(fileId, { state: UploadStateEnum.UPLOADED })
                .then(() => callback?.())
                .catch(() => {
                  stopLoop = true;
                  callback?.();
                });
            }
          })
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          .catch(async (error) => {
            stopLoop = true;
            console.log('Error Occurred:', error);
            await onSetFileState(fileId, { state: UploadStateEnum.CANCELLED })
              .then(() => callback?.())
              .catch(() => callback?.());
          });
      }
    } else {
      break;
    }
  }
};

export const filesUpload = async (
  projectTaskId: string,
  fileList: UploadFile[],
  updateUpload?: (loaded: number) => void
) => {
  for await (const file of fileList) {
    const artResponse: CreateSeparateTaskResponse = await createSeparateTask(projectTaskId);
    const payload = {
      file_name: file.name || '',
    };
    const fileResponse: CreateFileResponse = await onCreateFile('file', payload);
    const artVerPayload = {
      file_id: fileResponse.id,
      artefact_id: artResponse.id,
    };
    await onCreateArtefactVersion(artVerPayload);

    const originFile = file.originFileObj;
    if (originFile) {
      await onUploadFileInPart(originFile, Number(fileResponse.id), updateUpload);
    }
  }
};
