import React, { memo, ReactNode } from 'react';

import { BusinessRoleEnum, CompanyUserItem } from 'types/entities';
import { declOfNum, declTitleTask, getRoleItem } from 'utils';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

import Avatar from 'components/avatar';

import { CompanyRole, ProjectUserType, ProjectUserWithTasksAmount } from 'hooks';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';
import Block from '../../../../../../components/block';

type UserBlockProps = {
  projectId?: string;
  hasLink?: boolean;
  skipEmpty?: boolean;
  role?: CompanyRole;
  users?: ProjectUserType[];
  projectUsersWithTasksAmount?: ProjectUserWithTasksAmount[];
  subTitle?: string;
};
const UserBlock = ({
  projectId,
  skipEmpty,
  hasLink,
  role,
  users,
  projectUsersWithTasksAmount,
  subTitle,
}: UserBlockProps) => {
  const common = useCommonContext();
  const filteredUsers = users?.filter((u) => u.roles.some((r) => r.role.id === role?.id));

  if (isEmpty(filteredUsers) && skipEmpty) {
    return null;
  }
  if (isEmpty(filteredUsers)) {
    return (
      <div className="info-drawer-user-block">
        {subTitle ? <span className="info-drawer-user-block__sub-title">{subTitle}</span> : null}
        <span className="info-drawer-user-block__empty">{common.t<string>('common.notChosen')}</span>
      </div>
    );
  }

  return (
    <div className="info-drawer-user-block">
      {subTitle ? <span className="info-drawer-user-block__sub-title">{subTitle}</span> : null}
      {filteredUsers?.map((f) => {
        const tasksAmount = projectUsersWithTasksAmount?.find((u) => u.company_user.user.id === f.company_user.user.id);

        const path = `/project/${projectId}/plan/tree?assignees=${f.company_user.user.id}`;

        return (
          <div key={f.id} className="info-drawer-user-block__wrapper">
            <Avatar size={48} src={f?.company_user?.user?.icon_image}>
              {f?.company_user?.user?.name[0]}
            </Avatar>
            <div className="info-drawer-user-block__info">
              <span className="info-drawer-user-block__company">{f?.company_user?.company?.name}</span>
              <div className="info-drawer-user-block__info-wrap">
                <span className="info-drawer-user-block__name">{f?.company_user?.user?.name}</span>
                {hasLink ? (
                  <Link target="_blank" to={path} className="info-drawer-user-block__link">
                    <span className="info-drawer-user-block__link-text">
                      {`${tasksAmount?.tasks_amount} ${common.t<string>(
                        declOfNum(tasksAmount?.tasks_amount || 0, declTitleTask)
                      )}`}
                    </span>
                    <ArrowRightOutlined />
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
type UserGroupProps = {
  title?: string;
  children?: ReactNode;
};
const UserGroup = ({ title, children }: UserGroupProps) => {
  if (!title) {
    return null;
  }
  return (
    <div className="info-drawer-user-group">
      <span className="info-drawer-user-group__title">{title}</span>
      <div className="info-drawer-user-group__content">{children}</div>
    </div>
  );
};
type UserListProps = {
  projectId?: string;
  users?: ProjectUserType[];
  companyRoles?: CompanyRole[];
  projectUsersWithTasksAmount?: ProjectUserWithTasksAmount[];
};

function UserList({ projectId, users, companyRoles, projectUsersWithTasksAmount }: UserListProps) {
  const common = useCommonContext();
  return (
    <div className="info-drawer-user-list">
      <Block empty hidden={!getRoleItem(companyRoles, BusinessRoleEnum.stakeholder)?.name}>
        <UserGroup title={getRoleItem(companyRoles, BusinessRoleEnum.stakeholder)?.name}>
          <UserBlock role={getRoleItem(companyRoles, BusinessRoleEnum.stakeholder)} users={users} />
        </UserGroup>
      </Block>
      <UserGroup title={getRoleItem(companyRoles, BusinessRoleEnum.manager)?.name}>
        <UserBlock role={getRoleItem(companyRoles, BusinessRoleEnum.manager)} users={users} />
      </UserGroup>
      <UserGroup title={getRoleItem(companyRoles, BusinessRoleEnum.instructionalDesigner)?.name}>
        <UserBlock role={getRoleItem(companyRoles, BusinessRoleEnum.instructionalDesigner)} users={users} />
      </UserGroup>
      <UserGroup title={getRoleItem(companyRoles, BusinessRoleEnum.learningTechnologist)?.name}>
        <UserBlock role={getRoleItem(companyRoles, BusinessRoleEnum.learningTechnologist)} users={users} />
      </UserGroup>
      <UserGroup title={common.t<string>('pages.addCourse.participants.collectiveOfAuthors')}>
        <UserBlock
          skipEmpty
          role={getRoleItem(companyRoles, BusinessRoleEnum.authorManager)}
          users={users}
          subTitle={getRoleItem(companyRoles, BusinessRoleEnum.authorManager)?.name}
        />
        <UserBlock
          hasLink
          projectId={projectId}
          role={getRoleItem(companyRoles, BusinessRoleEnum.author)}
          users={users}
          projectUsersWithTasksAmount={projectUsersWithTasksAmount}
          subTitle={getRoleItem(companyRoles, BusinessRoleEnum.author)?.name}
        />
      </UserGroup>
      <UserGroup title={common.t<string>('pages.addCourse.participants.videoProduction')}>
        <UserBlock
          skipEmpty
          role={getRoleItem(companyRoles, BusinessRoleEnum.videoProductionManager)}
          users={users}
          subTitle={getRoleItem(companyRoles, BusinessRoleEnum.videoProductionManager)?.name}
        />
        {companyRoles
          ?.filter((cr) => cr.role === BusinessRoleEnum.productionExecutor)
          ?.map((cr, index) => {
            return (
              <UserBlock
                key={index}
                projectId={projectId}
                hasLink
                role={cr}
                users={users}
                projectUsersWithTasksAmount={projectUsersWithTasksAmount}
                subTitle={cr?.name}
              />
            );
          })}
      </UserGroup>
      <Block empty hidden={!getRoleItem(companyRoles, BusinessRoleEnum.support)}>
        <UserGroup title={common.t<string>('pages.addCourse.participants.support')}>
          <UserBlock role={getRoleItem(companyRoles, BusinessRoleEnum.support)} users={users} />
        </UserGroup>
      </Block>
    </div>
  );
}

export default memo(UserList);
