import React, { memo } from 'react';

import { TaskSiderInfoSkeleton } from 'layout/sidebar/TaskSiderSkeleton';
import { generatePath, Link } from 'react-router-dom';
import modalService from 'services/modalService';
import { PATH_NAMES } from 'router/constants';
import { ArrowRightOutlined } from '@ant-design/icons';

import Block from 'components/block';
import Button from 'components/button';
import { ButtonTypesEnum } from 'components/button/types';

import useCommonContext from 'hooks/useCommonContext';

import { LearningObjectSidebar } from '../../hooks/useLearningObjectSidebar';
import { ArtefactSidebar } from '../../hooks/useArtefactSidebar';

type TaskSidebarInfoProps = {
  loading: boolean;
  learningObject?: LearningObjectSidebar;
  currentArtefact?: ArtefactSidebar;
};

function TaskSidebarInfo({ loading, learningObject, currentArtefact }: TaskSidebarInfoProps) {
  const common = useCommonContext();

  const onOpenAssignersClick = () => {
    modalService.openAssignersUserModal({
      projectId: learningObject?.project.id,
      projectCompanyId: learningObject?.project?.company?.id,
    });
  };

  const currentExecutorEntity = currentArtefact?.project_task?.current_assignees?.find(
    (assigner) => assigner.is_current_executor && assigner.is_active
  );

  const path = generatePath(PATH_NAMES.project.baseExtended, {
    id: learningObject?.project.pk || '1',
    entity: PATH_NAMES.project.entity.plan.tree,
  });

  const elemPath = generatePath(PATH_NAMES.project.baseExtended, {
    id: learningObject?.project.pk || '1',
    entity: PATH_NAMES.project.entity.content,
  });

  return (
    <div className="sidebar__info">
      <Block empty hidden={!loading}>
        <TaskSiderInfoSkeleton />
      </Block>
      <Block empty hidden={loading}>
        <Link to={`${elemPath}?elementId=${learningObject?.id}`} className="sidebar__info__title mb_24">
          {learningObject?.name}
          <ArrowRightOutlined style={{ fontSize: 20 }} className="ml_4" />
        </Link>
        <span className="sidebar__info__description mb_4">{common?.t<string>('pages.task.course')}</span>
        <span className="sidebar__info__text mb_16">
          <Link className="sidebar__info-link testclass-componentspanel-coursename" to={path}>
            {learningObject?.project?.name}
            <ArrowRightOutlined className="ml_4" />
          </Link>
        </span>
        <span className="sidebar__info__description mb_4">{common?.t<string>('pages.task.trainingResults')}</span>
        <span className="sidebar__info__text mb_16 testclass-componentspanel-progress">
          {learningObject?.project_learning_outcome?.description}
        </span>
        <span className="sidebar__info__description mb_4">{common?.t<string>('pages.task.description')}</span>
        <span className="sidebar__info__text mb_16  testclass-componentspanel-description">
          {learningObject?.description}
        </span>
        <span className="sidebar__info__description mb_4">
          {currentExecutorEntity?.by_artefact_role_assignment.user_role.name}
        </span>
        <span className="sidebar__info__text mb_4 assigners-button">
          <span className="assigners-button__text testclass-componentspanel-methodist">
            {currentExecutorEntity
              ? currentExecutorEntity?.project_user?.company_user?.user?.name
              : common.t<string>('common.notAssigned')}
          </span>
          <Button
            onClick={onOpenAssignersClick}
            type={ButtonTypesEnum.text}
            className="all-participants testclass-componentspanel-members"
          >
            {common?.t<string>('common.allParticipants')}
          </Button>
        </span>
      </Block>
    </div>
  );
}

export default memo(TaskSidebarInfo);
