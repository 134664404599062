import React, { memo } from 'react';

import { Skeleton } from 'antd';

import './styles.scss';

const styles = {
  tab: { width: '17.5%' },
};

function SkeletonToolbar() {
  return (
    <div className="skeleton-project-toolbar">
      <Skeleton paragraph={false} avatar={false} active style={styles.tab} />
      <Skeleton paragraph={false} avatar={false} active style={styles.tab} />
      <Skeleton paragraph={false} avatar={false} active style={styles.tab} />
    </div>
  );
}

export default memo(SkeletonToolbar);
