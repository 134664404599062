import React, { ReactNode } from 'react';

import ServiceNotification from '../../pages/service/components/ServiceNotification';
import './styles.scss';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return { hasError: true, error };
  }

  render() {
    const { state, props } = this;
    if (state.hasError) {
      // Можно отрендерить запасной UI произвольного вида
      return (
        <div className="error-boundary-container">
          <ServiceNotification message={state?.error?.message} commonTitle />
        </div>
      );
    }

    return props.children;
  }
}

export default ErrorBoundary;
