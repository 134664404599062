import React from 'react';

import Skeleton from 'antd/es/skeleton';

import './styles.scss';

const AssigneesDrawerSkeleton = () => {
  return (
    <div className="drawer-assignees__skeletons">
      <Skeleton.Input active size="large" />
      <Skeleton.Input active size="large" />
      <Skeleton.Input active size="large" />
      <Skeleton.Input active size="large" />
      <Skeleton.Input active size="large" />
      <Skeleton.Input active size="large" />
      <Skeleton.Input active size="large" />
      <Skeleton.Input active size="large" />
      <Skeleton.Input active size="large" />
      <Skeleton.Input active size="large" />
    </div>
  );
};

export default AssigneesDrawerSkeleton;
