import { useRef, useState } from 'react';

import { isEmpty } from 'lodash';
import { arrStrToArrNum } from 'utils';

import useCommonContext from 'hooks/useCommonContext';
import useProjects from 'hooks/queries/useProjects';
import useDebounce from 'hooks/useDebounce';

import { FiltersState, ProjectsResponseData } from '../types';
import { DashboardCourseItem } from '../../dashboard/types';

type UseCoursesDataProps = {
  filter: FiltersState;
};

type UseCoursesDataOutput = {
  courses: DashboardCourseItem[];
  metrics?: number;
  hasFirstLoad: boolean;
  loading: boolean;
  hasNextPage?: boolean;
  onLoadMore: () => void;
};

const LIMIT_PAGE_ITEM = 50;

function useCoursesData({ filter }: UseCoursesDataProps): UseCoursesDataOutput {
  const [courses, setCourses] = useState<DashboardCourseItem[]>([]);
  const [metrics, setMetrics] = useState<number | undefined>();

  const hasFirstLoad = useRef(false);

  const common = useCommonContext();

  const onCompleted = (data: ProjectsResponseData) => {
    const temp = data.projects.edges.map((el) => el.node);
    setCourses([...temp]);
    setMetrics(data?.company_metrics.courses_with_missed_deadlines);
    if (!hasFirstLoad.current) {
      hasFirstLoad.current = true;
    }
  };

  const debounceFilter = useDebounce(filter, 500);

  const { data, hasNextPage, loading, fetchMore } = useProjects({
    textSearch: filter.searchText,
    delaysExist: debounceFilter.delaysExist,
    companyDelaysExist: true,
    delays: !isEmpty(debounceFilter.delays) ? arrStrToArrNum(debounceFilter.delays) : undefined,
    assigned: !isEmpty(debounceFilter.assignees) ? arrStrToArrNum(debounceFilter.assignees) : undefined,
    offset: 0,
    first: LIMIT_PAGE_ITEM,
    onCompleted,
    skip: !common?.userId || !common?.company?.id,
  });

  const onLoadMore = () =>
    fetchMore({
      variables: {
        textSearch: debounceFilter.searchText,
        delaysExist: debounceFilter.delaysExist,
        companyDelaysExist: true,
        delays: !isEmpty(debounceFilter.delays) ? arrStrToArrNum(debounceFilter.delays) : undefined,
        assigned: !isEmpty(debounceFilter.assignees) ? arrStrToArrNum(debounceFilter.assignees) : undefined,
        after: data?.projects?.pageInfo?.endCursor,
        first: LIMIT_PAGE_ITEM,
        onCompleted,
      },
    });

  return {
    courses,
    metrics,
    loading,
    hasFirstLoad: hasFirstLoad.current,
    hasNextPage,
    onLoadMore,
  };
}

export default useCoursesData;
