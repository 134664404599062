import React, { useCallback, useMemo } from 'react';

import {
  EditFilled,
  ExclamationCircleFilled,
  ExpandAltOutlined,
  PartitionOutlined,
  PlusOutlined,
  ShrinkOutlined,
  TableOutlined,
  WarningFilled,
} from '@ant-design/icons';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { noop } from 'lodash';
import Can from 'config/Can';
import { PATH_NAMES } from 'router/constants';
import { arrStrToArrNum } from 'utils';
import usePageFilters from 'pages/hooks/usePageFilters';

import Segmented from 'components/segmented/Segmented';
import Button from 'components/button/Button';
import { ButtonTypesEnum } from 'components/button/types';
import Switch from 'components/switch';
import Badge from 'components/badge/Badge';
import Select from 'components/select/Select';
import Search from 'components/search';
import Block from 'components/block/Block';
import Popconfirm from 'components/popconfirm/Popconfirm';
import { CCMBadgeVariant } from 'components/badge/types';
import Tooltip from 'components/tooltip';

import useCommonContext from 'hooks/useCommonContext';
import useProjectStates from 'hooks/queries/useProjectStates';
import useCompanyArtefactTypes from 'hooks/queries/useCompanyArtefactTypes';
import useProjectUsers from 'hooks/queries/useProjectUsers';
import useProjectTaskMetrics from 'hooks/queries/useProjectTaskMetrics';

import './styles.scss';

export const ViewTypeOptions = [
  {
    value: 'tree',
    icon: <PartitionOutlined />,
  },
  {
    value: 'list',
    icon: <TableOutlined />,
  },
];

type PlanToolbarProps = {
  isTree?: boolean;
  isEdit?: boolean;
  hasChangedTree?: boolean;
  onCloseAll?: () => void;
  onOpenAll?: () => void;
  onStartEdit?: () => void;
  onUndoEdit?: () => void;
  onSave?: () => void;
};

function PlanToolbar({
  isTree,
  isEdit,
  hasChangedTree,
  onOpenAll,
  onCloseAll,
  onStartEdit,
  onUndoEdit,
  onSave,
}: PlanToolbarProps) {
  const common = useCommonContext();
  const params = useParams();
  const location = useLocation();

  const view = useMemo(() => (location.pathname.indexOf('list') === -1 ? 'tree' : 'list'), [location.pathname]);
  const filters = usePageFilters();

  const { projectMergedStatesForSelect } = useProjectStates(params.id, 'cache-and-network');
  const { companyMergedTypesForSelect } = useCompanyArtefactTypes('cache-and-network');
  const { projectUsersForSelect } = useProjectUsers(params.id, 'cache-and-network');

  const { projectTaskMetrics } = useProjectTaskMetrics(
    params.id,
    {
      statusName: filters.statuses,
      artefactTypeNames: filters.types,
      assigneeIds: arrStrToArrNum(filters.assignees),
    },
    true
  );
  const filterOption = useCallback((inputValue: string, option?: DefaultOptionType) => {
    const label = option?.label || '';
    return label?.toString()?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  }, []);

  const goToCreateTask = useCallback(() => {
    const path = generatePath(PATH_NAMES.project.baseExtended, {
      id: params.id,
      entity: PATH_NAMES.project.entity.create,
    });
    common.navigate(path);
  }, [common, params.id]);

  const onChangeView = useCallback(
    (value: string | number) => {
      const entity = value as 'tree' | 'list';
      const path = generatePath(PATH_NAMES.project.baseExtended, {
        id: params.id,
        entity: PATH_NAMES.project.entity.plan[entity],
      });
      common.navigate(`${path}${location.search}`);
    },
    [common, location.search, params.id]
  );

  return (
    <div className="plan-toolbar">
      <div className="plan-toolbar__missed mr_16">
        <Switch
          size="small"
          name="delaysExist"
          disabled={!projectTaskMetrics?.tasks_with_missed_deadlines}
          checked={!!filters.missed}
          onChange={filters.onChangeMissed}
        />
        <span className="plan-toolbar__missed-label">{common.t<string>('common.violationDeadlines')}</span>
        <Badge count={projectTaskMetrics?.tasks_with_missed_deadlines} variant={CCMBadgeVariant.light} />
      </div>
      <div className="plan-toolbar__filters">
        <Select
          name="status"
          noForm
          placeholder={common.t<string>('pages.project.filter.status')}
          mode="multiple"
          maxTagCount="responsive"
          value={filters.statuses}
          options={projectMergedStatesForSelect}
          onChange={filters.onChangeStatuses}
          filterOption={filterOption}
        />
        <Select
          name="type"
          noForm
          placeholder={common.t<string>('pages.project.filter.artefact')}
          mode="multiple"
          maxTagCount="responsive"
          value={filters.types}
          options={companyMergedTypesForSelect}
          onChange={filters.onChangeTypes}
          filterOption={filterOption}
        />
        <Select
          name="assignees"
          noForm
          placeholder={common.t<string>('pages.project.filter.executor')}
          mode="multiple"
          maxTagCount="responsive"
          value={filters.assignees}
          options={projectUsersForSelect}
          onChange={filters.onChangeAssignees}
          filterOption={filterOption}
        />
        <Search
          name="search"
          placeholder={common.t<string>('pages.project.filter.itemName')}
          value={filters.textSearch}
          onChange={filters.onChangeText}
          onSearch={filters.onSearch}
        />
      </div>
      <div className="plan-toolbar__setting">
        <Block hidden={!isTree} className="plan-toolbar__expand">
          <Button className="mr_8" type={ButtonTypesEnum.link} icon={<ExpandAltOutlined />} onClick={onOpenAll} />
          <Button type={ButtonTypesEnum.link} icon={<ShrinkOutlined />} onClick={onCloseAll} />
        </Block>
        <Segmented
          className={!isTree ? 'mr_auto ml_auto' : 'ml_16'}
          options={ViewTypeOptions}
          value={view}
          onChange={onChangeView}
        />
      </div>
      <div className="plan-toolbar__actions">
        <Can I="update" a="Project.plan.edit">
          <Block hidden={view === 'list'} className="plan-toolbar__edit">
            <Block className="ml_auto plan-toolbar__buttons" hidden={!isEdit}>
              <Popconfirm
                open={hasChangedTree ? undefined : false}
                placement="bottom"
                title={common.t<string>('common.cancelEdit')}
                okText={common.t<string>('common.yes')}
                cancelText={common.t<string>('common.no')}
                onConfirm={onUndoEdit}
                icon={<WarningFilled style={{ color: '#e98529' }} />}
              >
                <Button type={ButtonTypesEnum.default} size="large" onClick={hasChangedTree ? noop : onUndoEdit}>
                  {common.t<string>('common.canceling')}
                </Button>
              </Popconfirm>

              <Popconfirm
                placement="bottom"
                title={common.t<string>('common.saveEdit')}
                okText={common.t<string>('common.yes')}
                cancelText={common.t<string>('common.no')}
                onConfirm={onSave}
                icon={<ExclamationCircleFilled style={{ color: '#1E1E1E' }} />}
              >
                <Button className="ml_6" size="large" type={ButtonTypesEnum.primary}>
                  {common.t<string>('common.save')}
                </Button>
              </Popconfirm>
            </Block>
            <Block empty hidden={isEdit}>
              <Button type={ButtonTypesEnum.default} size="middle" icon={<EditFilled />} onClick={onStartEdit} />
            </Block>
          </Block>
        </Can>
        <Can I="manage" a="Project.plan.create.separate">
          <Tooltip title={common.t<string>('pages.project.tooltips.createTask')} placement="topLeft">
            <Button
              className="ml_16"
              type={ButtonTypesEnum.primary}
              size="middle"
              icon={<PlusOutlined />}
              onClick={goToCreateTask}
            />
          </Tooltip>
        </Can>
      </div>
    </div>
  );
}

export default PlanToolbar;
