import React, { memo, ReactNode, SyntheticEvent } from 'react';

import { HistoryOutlined } from '@ant-design/icons';
import { TooltipPlacement } from 'antd/lib/tooltip';

import Button from 'components/button';
import Popover from 'components/popover';

import useLazyProjectDeadlinesChanged from 'hooks/queries/useLazyProjectDeadlinesChanged';

import { ButtonTypesEnum } from '../../button/types';
import useCommonContext from '../../../hooks/useCommonContext';

import ProjectDeadlinesChangedPopupContent from './ProjectDeadlinesChangedPopupContent';

import '../styles.scss';

type HistoryPopupProps = {
  projectId?: string;
  icon?: ReactNode;
  placement?: TooltipPlacement;
};

function ProjectDeadlinesChangedPopup({ projectId, icon, placement }: HistoryPopupProps) {
  const common = useCommonContext();
  const { getProjectDeadlines, projectDeadlines, projectDeadlinesLoading } = useLazyProjectDeadlinesChanged(
    Number(projectId)
  );

  const onHandleClick = (e: SyntheticEvent) => {
    getProjectDeadlines();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  return (
    <div className="ccm-popover-history" onClick={onHandleClick}>
      <Popover
        trigger="click"
        placement={placement}
        title={common.t<string>('common.historyTimingsChanges')}
        content={<ProjectDeadlinesChangedPopupContent data={projectDeadlines} loading={projectDeadlinesLoading} />}
      >
        <Button type={ButtonTypesEnum.link} icon={icon ? icon : <HistoryOutlined />} />
      </Popover>
    </div>
  );
}

export default memo(ProjectDeadlinesChangedPopup);
