import React from 'react';

import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

import ProjectHeader from '../project/components/header/ProjectHeader';
import { PATH_NAMES } from '../../router/constants';

import './styles.scss';

const navigationMap = [
  {
    id: '1',
    name: 'Learning object types',
    path: PATH_NAMES.management.loTypes,
  },
  {
    id: '2',
    name: 'Artefacts types',
    path: PATH_NAMES.management.artTypes,
  },
  {
    id: '3',
    name: 'Global settings',
    path: PATH_NAMES.management.globalSettings,
  },
  {
    id: '4',
    name: 'Status-list',
    path: PATH_NAMES.management.status,
  },
  {
    id: '5',
    name: 'Pipeline',
    path: PATH_NAMES.management.pipeline,
  },
];

const ManagementSettings = () => {
  return (
    <Layout className="management">
      <ProjectHeader title={'Internal management settings'} naviMap={navigationMap} />
      <div className="management__content">
        <Outlet />
      </div>
    </Layout>
  );
};

export default ManagementSettings;
