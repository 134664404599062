import React, { useEffect } from 'react';

import { convertRoles, cx } from 'utils';
import { AbilityContext } from 'config/Can';
import Layout from 'antd/lib/layout';
import Header from 'pages/components/header';
import { buildAbilityFor } from 'config/ability';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import usePageFilters from 'pages/hooks/usePageFilters';

import Block from 'components/block';
import LoadMore from 'components/loadMore/LoadMore';
import LoadingOverlay from 'components/loadingOverlay';
import { RadioGroup } from 'components/radio';
import EmptyData from 'components/emptyData';

import useCommonContext from 'hooks/useCommonContext';
import useCompanyUsers from 'hooks/queries/useCompanyUsers';
import useDashboardFilters from 'hooks/queries/useDashboardFilters';
import useTextEmpty from 'hooks/useTextEmpty';

import TaskItem from '../dashboard/components/taskItem';
import { DashboardTaskItem } from '../dashboard/types';

import useTasksData from './hooks/useTasksData';
import TasksFilterSkeleton from './components/skeletons/TasksFilterSkeleton';
import TasksPageSkeleton from './components/skeletons/Skeleton';
import { radioFilterMap } from './constants';
import TasksFilter from './components/tasksFilter/TasksFilter';

import './styles.scss';

function TasksPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const common = useCommonContext();
  const { textEmptyTask } = useTextEmpty();
  const ability = buildAbilityFor(convertRoles(common.businessRoles));
  useCompanyUsers(Number(common?.company?.id)); //needed for the timeline

  const filters = usePageFilters();

  useEffect(() => {
    if (!filters.taskFormToday) {
      // searchParams.set('taskFormToday', dayjs().format('YYYY-MM-DD'));
      searchParams.set('taskFormToday', 'all');
      setSearchParams(searchParams);
    }
  }, [filters.taskFormToday, searchParams, setSearchParams]);

  useDashboardFilters(common.company?.id, undefined, common.businessRoleIds, common.userId, 'network-only');

  const filter = {
    statuses: filters.statuses,
    artefact: filters.types,
    searchText: filters.text,
    taskFormToday: { to_date: filters.taskFormToday },
    projectName: filters.projectName,
    elements: filters.elements,
    assignee: filters.assignee,
  };

  const { loading, hasFirstLoad, hasNextPage, tasks, onLoadMore } = useTasksData({
    filter,
    skip: !common.company?.id || !common.userId,
  });

  const classNames = cx('page-tasks__wrapper', {
    mb_16: !hasNextPage,
  });

  return (
    <AbilityContext.Provider value={ability}>
      <Layout className="page-tasks">
        <Block empty hidden={hasFirstLoad}>
          <TasksFilterSkeleton />
        </Block>

        <Block empty hidden={!hasFirstLoad}>
          <Header className="page-tasks__header" title={common.t<string>('pages.tasks.title')}>
            <RadioGroup
              className="tasks-radio"
              size="large"
              buttonStyle="solid"
              options={radioFilterMap(t)}
              optionType="button"
              value={filters.taskFormToday}
              onChange={filters.onChangeTaskDay}
            />
          </Header>
          <TasksFilter />
        </Block>

        <Block empty hidden={hasFirstLoad}>
          <TasksPageSkeleton />
        </Block>

        <Block className={classNames} hidden={!hasFirstLoad}>
          {!isEmpty(tasks) ? (
            tasks?.map((item) => {
              const sequence = item?.artefact_production_sequence?.find(
                (s) => s.project_task?.id === item.cached_linked_artefact.project_task?.id
              );
              const convertedItem = {
                ...item,
                artefact_production_sequence: {
                  ...sequence,
                },
              } as DashboardTaskItem;
              return <TaskItem key={item.id} data={convertedItem} />;
            })
          ) : (
            <EmptyData title={textEmptyTask} />
          )}

          <LoadMore hasNextPage={hasNextPage} onFetch={onLoadMore} />
        </Block>
        <LoadingOverlay show={loading && hasFirstLoad} text={common.t<string>('common.loadingData')} />
      </Layout>
    </AbilityContext.Provider>
  );
}

export default TasksPage;
