import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

import { UploadFile } from 'antd/lib/upload/interface';
import { FileType, UserExtended } from 'types/entities';
import { Form, message } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { isEmpty } from 'lodash';
import { generatePath, useParams } from 'react-router-dom';
import {
  addDescriptionProjectTask,
  createSeparateTask,
  CreateSeparateTaskResponse,
  initProjectTask,
  InitProjectTaskResponse,
  removeSeparateTask,
} from 'api/requests/projectTask';
import { filesUpload } from 'pages/fetches/fileUpload';
import { PATH_NAMES } from 'router/constants';
import dayjs from 'dayjs';

import useBoolean from 'hooks/useBoolean';
import useCommonContext from 'hooks/useCommonContext';

import { ProjectSeparateTaskItem, SeparateTaskArtefactVersions } from '../../types/entities/separate';
import { separateTaskAssigneeToUserExtended } from '../../utils';
import { CreateFileResponse, onCreateArtefactVersion, onCreateFile, onUpdateDocument } from '../../api/requests/files';

export type GlobalTaskOutput = {
  loading: boolean;
  form: FormInstance;
  disableCreate: boolean;
  openDrawer: boolean;
  onOpenDrawer: any;
  fileList: UploadFile[];
  setFileList: Dispatch<SetStateAction<UploadFile[]>>;
  selectedUsers: UserExtended[] | null;
  setSelectedUsers: Dispatch<SetStateAction<UserExtended[] | null>>;
  onCreateTask: () => void;
  onReadyEditor: () => void;
  initializeEditor: any;
  view: 'select' | 'add';
  setView: Dispatch<SetStateAction<'select' | 'add'>>;
  onChangeView: () => void;
  onRemoveSeparateTask: () => void;
};

type SeparateTaskProps = {
  separateTask?: ProjectSeparateTaskItem;
  documentData?: SeparateTaskArtefactVersions | null;
};

function useSeparateTask({ separateTask, documentData }: SeparateTaskProps) {
  const [loading, onLoading] = useBoolean(false);
  const [view, setView] = useState<'select' | 'add'>('select');
  const [openDrawer, onOpenDrawer] = useBoolean(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserExtended[] | null>(null);
  const editorCore = useRef<any>(null);
  const [editorReady, onEditorReady] = useBoolean(false);
  const [form] = Form.useForm();
  const { id } = useParams();
  const common = useCommonContext();

  const users = separateTaskAssigneeToUserExtended(separateTask?.current_assignees || []);

  useEffect(() => {
    if (!isEmpty(users) && selectedUsers === null) {
      setSelectedUsers(users);
    }
  }, [selectedUsers, users]);

  const name = Form.useWatch('name', form);
  const deadline = Form.useWatch('deadline_production', form);
  const taskType = Form.useWatch('task_type', form);

  const disableCreate = !name || !deadline || !taskType || isEmpty(selectedUsers);

  const onChangeView = useCallback(() => {
    if (view === 'add') {
      setView('select');
    } else {
      setView('add');
    }
  }, [view]);

  const initializeEditor = useCallback((instance: any) => {
    editorCore.current = instance;
  }, []);

  const onReadyEditor = () => onEditorReady.on();

  const onSaveDocument = useCallback(
    async (savedData: any) => {
      if (documentData) {
        await onUpdateDocument(documentData.file.id, savedData);
      } else {
        const artResponse: CreateSeparateTaskResponse = await createSeparateTask(separateTask?.id || '');
        const fileResponse: CreateFileResponse = await onCreateFile(FileType.DOCUMENT);
        const artVerPayload = {
          file_id: fileResponse.id,
          artefact_id: artResponse.id,
        };
        await onCreateArtefactVersion(artVerPayload);
        await onUpdateDocument(fileResponse.id.toString(), savedData);
      }
    },
    [documentData, separateTask?.id]
  );

  const onChangeEditor = useCallback(async () => {
    const savedData = await editorCore?.current?.save();
    await onSaveDocument(savedData);
  }, [onSaveDocument]);

  const finallyCreateTask = (taskId: string) => {
    return new Promise(() => {
      onLoading.off();
      const path = `/project/${id}/plan/tree?open=${taskId}`;
      common.navigate(path);
      message.success(common.t<string>('pages.project.create.taskCreated'));
    });
  };

  const onCreateTask = async () => {
    onLoading.on();
    const formData = form.getFieldsValue();
    const editorData = await editorCore?.current?.save();

    const payload = {
      project_id: Number(id),
      task_type: formData.task_type,
      name: formData.name,
      deadline_production: formData.deadline_production ? dayjs(formData.deadline_production).format('YYYY-MM-DD') : '',
      deadline_first_iteration_review: formData.deadline_first_iteration_review
        ? dayjs(formData.deadline_first_iteration_review).format('YYYY-MM-DD')
        : undefined,
      assignees: selectedUsers?.map((s) => Number(s.id)) as number[],
    };

    try {
      const taskResponse: InitProjectTaskResponse = await initProjectTask(payload);

      if (!isEmpty(editorData.blocks)) {
        await addDescriptionProjectTask(taskResponse?.id.toString(), editorData);
      }

      if (taskResponse?.id && !isEmpty(fileList)) {
        await filesUpload(taskResponse?.id.toString(), fileList);
      }
      await finallyCreateTask(taskResponse?.id.toString());
    } catch (err) {
      message.error(common.t<string>('common.errorTryAgain'));
      onLoading.off();
    }
  };

  const onRemoveSeparateTask = async () => {
    await removeSeparateTask(separateTask?.id || '').then(() => {
      const path = generatePath(PATH_NAMES.project.baseExtended, {
        id: separateTask?.project.id,
        entity: PATH_NAMES.project.entity.plan.tree,
      });
      common.navigate(path);
    });
  };

  return {
    loading,
    form,
    disableCreate,
    openDrawer,
    onOpenDrawer,
    fileList,
    setFileList,
    selectedUsers,
    setSelectedUsers,
    initializeEditor,
    onReadyEditor,
    onCreateTask,
    view,
    setView,
    onChangeView,
    onRemoveSeparateTask,
    onChangeEditor,
  };
}

export default useSeparateTask;
