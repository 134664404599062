import { gql } from '@apollo/client';

import { USER_TYPE, USER_ROLE_TYPE } from './fragments';

export const GET_PROJECT_TASK_ASSIGNEES = gql`
  query ProjectTaskAssignees($project_id: Int) {
    project_task_assignees(project_id: $project_id) {
      edges {
        node {
          project_task {
            id
          }
          is_active
          is_current_executor
          project_user {
            company_user {
              id
              user {
                ...UserData
              }
            }
          }

          by_artefact_role_assignment {
            user_role {
              ...RoleData
            }
          }
        }
      }
    }
  }
  ${USER_TYPE}
  ${USER_ROLE_TYPE}
`;
