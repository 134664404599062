import { gql } from '@apollo/client';

export const GET_COLLABORATORS = gql`
  query Collaborators($filter: CollaboratorsFilter, $after: String, $offset: Int, $first: Int) {
    collaborators(collaborators_filter: $filter, after: $after, offset: $offset, first: $first) {
      edges {
        cursor
        node {
          id
          pk
          user {
            id
            name
            email
            state
            icon_image
            timezone
          }
          technical_role
          business_roles {
            id
            name
            role
          }
          is_active
          company {
            id
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
