import React, { useEffect } from 'react';

import ServiceNotification from './components/ServiceNotification';

import './styles.scss';
import { localStorageService } from '../../services/localStorageService';

type Props = {
  cleanAuth?: boolean;
};

function ServicePage({ cleanAuth }: Props) {
  useEffect(() => {
    if (cleanAuth) {
      localStorageService.removeAuthData();
    }
  }, [cleanAuth]);
  return (
    <div className="service-page">
      <ServiceNotification />
    </div>
  );
}

export default ServicePage;
