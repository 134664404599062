import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { CloseOutlined, DeleteOutlined, EditOutlined, LinkOutlined, MessageOutlined } from '@ant-design/icons';
import { StateType } from 'types/entities';
import modalService from 'services/modalService';
import { BusinessRoleEnum } from 'types/entities/user';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IStore } from 'types/store';

import { RadioGroup } from 'components/radio';
import Button from 'components/button';
import Block from 'components/block';
import TextField from 'components/textField';
import { TextFieldTypeEnum } from 'components/textField/types';
import { ButtonTypesEnum } from 'components/button/types';

import { addStatusCommentRequest, deleteIssueRequest, updateIssueRequest } from 'store/issues/actions';
import { changeTaskStatusRequest } from 'store/projects/actions';

import { ArtefactStateRequest, CompanyIssueItem } from '../../../../task/types';
import { ProjectStateItem } from '../../../../../hooks';

type ErrorCardControlsProps = {
  isVideo?: boolean;
  isProductExecutor: boolean | undefined;
  isMethodist: boolean | undefined;
  isCurrentExecutor: boolean | null;
  userRoleId: string | null;
  item: any;
  status: StateType;
  issuesFlowSteps: any[] | undefined;
  companyStates: ProjectStateItem[] | null;
  companyIssuesStates: ProjectStateItem[] | null;
  issuesRefetch: () => Promise<any>;
  issuesTypesList: CompanyIssueItem[] | null;
  userRole: BusinessRoleEnum | null;
};

function ErrorCardControls({
  isVideo,
  item,
  onDeleteIssue,
  onChangeStatus,
  issuesRefetch,
  onAddStatusComment,
  onEditIssue,
  issuesTypesList,
  userRole,
  userRoleId,
  issuesFlowSteps,
  isCurrentExecutor,
}: ErrorCardControlsProps & PropsFromRedux) {
  const [isFetch, setFetch] = useState<boolean>(false);
  const [openChangeStatus, setOpenChangeStatus] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [statusId, setStatusId] = useState<number>();
  const [text, setText] = useState<string>('');
  const [changeStatusText, setChangeStatusText] = useState<string>('');
  const { t } = useTranslation();
  const nextTaskStepChangeStatusStepEntity = issuesFlowSteps?.find(
    (step: any) => Number(step?.state_initiation?.id) === Number(item?.project_task?.last_state?.id)
  );
  const artefactIssuesFlowStepNextArr = nextTaskStepChangeStatusStepEntity?.possible_next_steps;
  artefactIssuesFlowStepNextArr?.sort(
    (a: any, b: any) =>
      parseInt(a?.state_next?.state_initiation?.order) - parseInt(b?.state_next?.state_initiation?.order)
  );
  const getRadioButtonOptions = () => {
    const withRoleIdSteps = artefactIssuesFlowStepNextArr?.filter(
      (step: any) => Number(step?.user_role?.id) === Number(userRoleId)
    );
    if (
      (withRoleIdSteps?.length && isCurrentExecutor) ||
      userRole === BusinessRoleEnum.manager ||
      userRole === BusinessRoleEnum.executiveManager
    ) {
      return withRoleIdSteps?.map((step: any) => {
        return {
          label: step?.state_next?.state_initiation?.button_name,
          value: Number(step?.state_next?.state_initiation?.id),
          className: `testclass-errors-error-card-item-${step?.state_next?.state_initiation?.state_type}-button`,
        };
      });
    } else {
      return artefactIssuesFlowStepNextArr?.map((step: any) => {
        return {
          label: step?.state_next?.state_initiation?.button_name,
          value: Number(step?.state_next?.state_initiation?.id),
          className: `testclass-errors-error-card-item-${step?.state_next?.state_initiation?.state_type}-button`,
        };
      });
    }
  };
  const projectTaskIssueDescription = item?.project_task?.description;
  const projectTaskIssueId = Number(item?.issue?.pk);
  const initStateValue = Number(item?.project_task?.last_state?.id);
  const currentStateValueType = item?.project_task?.last_state?.state_type;
  const issueId = Number(item?.pk);
  const isMethodist = userRole === BusinessRoleEnum.instructionalDesigner;

  const onToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    const inputId = document.getElementById('stateCommentInput');
    if (inputId) {
      inputId.focus();
    }
  }, [openChangeStatus]);

  const onToggleChangeStatus = useCallback(() => {
    setOpenChangeStatus(!openChangeStatus);
  }, [openChangeStatus]);

  const onChangeText = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setText(e.target.value);
  };
  const onChangeStatusText = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setChangeStatusText(e.target.value);
  };
  const onDeleteHandle = async () => {
    await onDeleteIssue(Number(item?.pk), isVideo);
    await issuesRefetch();
  };

  const onOpenEditIssueModal = () =>
    modalService.openEditIssueModal({
      onEditIssue,
      projectTaskIssueDescription,
      projectTaskIssueId,
      issueId,
      issuesRefetch,
      issuesTypesList,
      isVideo,
    });

  const onChangeStatusRadioButton = async (event: any) => {
    const value = event?.target?.value;
    const projectTaskId = Number(item?.project_task?.id);
    // const stateIdValue = companyIssuesStates?.filter((companyState) => companyState?.state_type === value)?.[0]?.id;
    const requireDiscussMessage = artefactIssuesFlowStepNextArr?.filter(
      (companyState: any) => Number(companyState?.state_next?.state_initiation?.id) === value
    )?.[0]?.state_next?.state_initiation?.is_require_discuss_message;

    if (projectTaskId && value && !requireDiscussMessage) {
      setOpenChangeStatus(false);
      setChangeStatusText('');
      const payload: ArtefactStateRequest = {
        projectTaskId: Number(projectTaskId),
        state_id: Number(value),
      };
      setFetch(true);
      await onChangeStatus(payload);
      await issuesRefetch().then(() => {
        setFetch(false);
      });
    } else {
      setStatusId(Number(value));
      setOpenChangeStatus(true);
    }
  };

  const onCopyUrl = () => {
    const urlForCopy = location.href;
    navigator.clipboard.writeText(urlForCopy);
  };

  const onAddComment = async () => {
    const stateId = item?.project_task?.id;
    setFetch(true);
    const payload = {
      message: text,
    };
    await onAddStatusComment(Number(stateId), payload);
    onToggle();
    setText('');
    await issuesRefetch().then(() => setFetch(false));
  };

  const onAddCommentAndChangeStatus = async () => {
    const projectTaskId = Number(item?.project_task?.id);
    if (statusId && projectTaskId) {
      setFetch(true);
      const payload: ArtefactStateRequest = {
        projectTaskId: Number(projectTaskId),
        state_id: statusId,
        message: changeStatusText,
      };
      await onChangeStatus(payload);
      await issuesRefetch().then(() => setFetch(false));
      onToggleChangeStatus();
    }
  };
  return (
    <div className="error-card__controls">
      <Block hidden={!isCurrentExecutor}>
        <RadioGroup
          disabled={isFetch}
          isFull
          options={getRadioButtonOptions()}
          optionType="button"
          buttonStyle="solid"
          className="mb_8"
          onChange={onChangeStatusRadioButton}
          defaultValue={initStateValue}
        />
      </Block>
      <Block hidden={!open} className="flex-col mb_8">
        <TextField
          placeholder={t('common.comment')}
          type={TextFieldTypeEnum.textArea}
          autoSize={{ minRows: 1, maxRows: 2 }}
          value={text}
          onChange={onChangeText}
          style={{ borderRadius: '8px', backgroundColor: '#FFFFFF' }}
          className="mb_8 testclass-errors-task-frozenframe-new-reply-inputreply"
        />
        <Button
          fullWidth
          type={ButtonTypesEnum.default}
          onClick={onAddComment}
          className="testclass-errors-task-frozenframe-new-reply-addreply"
          style={{ backgroundColor: 'transparent' }}
        >
          {t<string>('common.addComment')}
        </Button>
      </Block>
      <Block hidden={!openChangeStatus} className="flex-col mb_16">
        <TextField
          placeholder={t('common.comment')}
          type={TextFieldTypeEnum.textArea}
          autoSize={{ minRows: 1, maxRows: 2 }}
          value={changeStatusText}
          onChange={onChangeStatusText}
          className="mb_8"
          style={{ borderRadius: '8px', backgroundColor: '#FFFFFF' }}
          id="stateCommentInput"
        />
        <Button
          fullWidth
          type={ButtonTypesEnum.default}
          disabled={!changeStatusText}
          onClick={onAddCommentAndChangeStatus}
          style={{ backgroundColor: 'transparent' }}
        >
          {t<string>('common.addCommentAndChangeStatus')}
        </Button>
      </Block>
      <div className="flex-row flex-align-items--center">
        <Block>
          <Button
            onClick={onToggle}
            className={
              open
                ? `button-reply testclass-errors-task-frozenframe-new-reply-cancellation`
                : `button-reply testclass-errors-error-card-item-comment-button`
            }
            type={ButtonTypesEnum.link}
            icon={open ? <CloseOutlined /> : <MessageOutlined />}
          >
            {open ? t<string>('common.cancel') : t<string>('common.reply')}
          </Button>
        </Block>

        <Button
          icon={<LinkOutlined />}
          onClick={onCopyUrl}
          type={ButtonTypesEnum.link}
          className="ml_auto testclass-errors-error-card-item-link-button"
        />
        <Block hidden={!isMethodist || currentStateValueType === StateType.DONE} className="flex-row">
          <Button
            icon={<EditOutlined />}
            onClick={onOpenEditIssueModal}
            type={ButtonTypesEnum.link}
            className="ml_8 testclass-errors-error-card-item-edit-button"
          />
        </Block>
        <Block hidden={!isMethodist} className="flex-row">
          <Button
            onClick={onDeleteHandle}
            icon={<DeleteOutlined />}
            type={ButtonTypesEnum.link}
            className="ml_8 testclass-errors-error-card-item-delete-button"
          />
        </Block>
      </div>
    </div>
  );
}

const connector = connect((state: IStore) => ({}), {
  onChangeStatus: changeTaskStatusRequest,
  onDeleteIssue: deleteIssueRequest,
  onAddStatusComment: addStatusCommentRequest,
  onEditIssue: updateIssueRequest,
});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ErrorCardControls);
