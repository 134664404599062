import { gql } from '@apollo/client';

export const GET_PROJECT_USERS_WITH_TASKS_AMOUNT = gql`
  query GetProjectUsersWith($project_id: Int!) {
    project_users_with_tasks_amount(project_id: $project_id) {
      id
      tasks_amount
      company_user {
        id
        user {
          id
          name
        }
      }
    }
  }
`;
