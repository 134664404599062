import React, { memo, ReactNode, useMemo } from 'react';

import Popover from 'components/popover';
import Tag from 'components/tag';
import StatusLabelFlat from 'components/statusLabelFlat/StatusLabelFlat';

import { CurrentArtefactProductionSequence } from 'hooks';

import './styles.scss';

type CourseInfoPopoverProps = {
  children: ReactNode;
  title?: string | null;
  sequences?: CurrentArtefactProductionSequence[];
};

function CourseInfoPopover({ children, title, sequences }: CourseInfoPopoverProps) {
  const filtered = sequences?.filter((s) => !s.is_turn_off);

  const content = useMemo(() => {
    return (
      <div className="course-info-pop__content">
        {filtered?.map((el, index) => {
          return (
            <div className="course-info-pop__row" key={index}>
              <Tag>{el.project_task.cached_linked_artefact.artefact_type.name}</Tag>
              <StatusLabelFlat
                type={el?.project_task?.last_state?.state_type}
                name={el?.project_task?.last_state?.name}
              />
            </div>
          );
        })}
      </div>
    );
  }, [filtered]);

  return (
    <Popover overlayClassName="course-info-pop" title={title} placement="topLeft" content={content}>
      {children}
    </Popover>
  );
}

export default memo(CourseInfoPopover);
