import React, { useCallback } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { useParams } from 'react-router-dom';
import { updateSeparateTask, UpdateSeparateTaskPayload } from 'api/requests/projectTask';
import { message } from 'antd';
import useSeparateTaskAbility from 'pages/hooks/useSeparateTaskAbility';
import { getFormatDateByStr } from 'utils';

import DatePicker from 'components/datePicker';

import useCommonContext from 'hooks/useCommonContext';
import useSeparateTaskQuery from 'hooks/queries/useSeparateTaskQuery';

import './styles.scss';
import Block from '../../../../components/block';

function SeparateTaskProductionDate() {
  const { taskId } = useParams();
  const { separateTask, separateTaskRefetch } = useSeparateTaskQuery(taskId, 'cache-and-network');
  const common = useCommonContext();
  const { canIEditDeadlines } = useSeparateTaskAbility(taskId);

  const firstIteration = separateTask?.separate_task.deadline_first_iteration_review
    ? dayjs(separateTask?.separate_task.deadline_first_iteration_review)
    : null;
  const deadlineProduction = separateTask?.separate_task.deadline_production
    ? dayjs(separateTask?.separate_task.deadline_production)
    : null;

  const onFieldsChange = useCallback(
    async (name: string, time: Dayjs | null, timeString: string) => {
      const payload: UpdateSeparateTaskPayload = {
        deadline_production: separateTask?.separate_task.deadline_production,
        deadline_first_iteration_review: undefined,
      };

      if (name === 'deadline_first_iteration_review') {
        payload.deadline_first_iteration_review = time ? time.format('YYYY-MM-DD') : undefined;
      }
      if (name === 'deadline_production') {
        payload.deadline_production = time
          ? time.format('YYYY-MM-DD')
          : separateTask?.separate_task.deadline_production;
      }

      await updateSeparateTask(separateTask?.id, payload).then(() => {
        separateTaskRefetch();
        message.info(<>{common.t<string>('pages.task.freeTask.panel.deadlineChanged')}</>);
      });
    },
    [common, separateTask?.id, separateTask?.separate_task.deadline_production, separateTaskRefetch]
  );

  return (
    <div className="separate-task-prod-date mb_12">
      <Block
        hidden={!canIEditDeadlines && !separateTask?.separate_task.deadline_first_iteration_review}
        className="separate-task-prod-date__column"
      >
        <Block className="separate-task-prod-date__label">
          {common.t<string>('pages.task.freeTask.panel.iteration')}
        </Block>
        {canIEditDeadlines ? (
          <DatePicker
            name="deadline_first_iteration_review"
            className="separate-task-prod-date__picker"
            noForm
            size="large"
            format="DD-MM-YYYY"
            value={firstIteration}
            onChangeWithName={onFieldsChange}
          />
        ) : (
          <span className="ft-panel-date__value">
            {getFormatDateByStr(separateTask?.separate_task.deadline_first_iteration_review, 'D MMMM YYYY')}
          </span>
        )}
      </Block>
      <div className="separate-task-prod-date__column">
        <span className="separate-task-prod-date__label">{common.t<string>('pages.task.freeTask.panel.deadline')}</span>
        {canIEditDeadlines ? (
          <DatePicker
            name="deadline_production"
            className="separate-task-prod-date__picker"
            size="large"
            noForm
            format="DD-MM-YYYY"
            value={deadlineProduction}
            onChangeWithName={onFieldsChange}
          />
        ) : (
          <span className="ft-panel-date__value">
            {getFormatDateByStr(separateTask?.separate_task.deadline_production, 'D MMMM YYYY')}
          </span>
        )}
      </div>
    </div>
  );
}

export default SeparateTaskProductionDate;
