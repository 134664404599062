import React, { memo } from 'react';

import { Skeleton } from 'antd';

import './styles.scss';

const styles = {
  title: { width: '100%', height: '16px', maxWidth: '269px' },
  block: { width: '100%', height: '16px' },
};

function SkeletonTabs() {
  return (
    <div className="task-page-skeleton-tabs">
      <div className="task-page-skeleton-tabs__block">
        <Skeleton paragraph={false} avatar={false} active style={styles.title} />
        <Skeleton paragraph={false} avatar={false} active style={styles.block} />
        <Skeleton paragraph={false} avatar={false} active style={styles.block} />
        <Skeleton paragraph={false} avatar={false} active style={styles.block} />
      </div>
      <div className="task-page-skeleton-tabs__block">
        <Skeleton paragraph={false} avatar={false} active style={styles.title} />
        <Skeleton paragraph={false} avatar={false} active style={styles.block} />
        <Skeleton paragraph={false} avatar={false} active style={styles.block} />
        <Skeleton paragraph={false} avatar={false} active style={styles.block} />
      </div>
    </div>
  );
}

export default memo(SkeletonTabs);
