import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import HistoryDrawer from 'pages/components/historyDrawer';
import TaskHeader from 'pages/task/components/taskHeader';
import UniversalTaskInstruction from 'pages/task/components/instruction';
import CheckList from 'pages/task/components/checkList';
import { connect, ConnectedProps } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { cx, isValidURL } from 'utils';
import { onPutFileUrlNew } from 'api/requests/files';
import { BusinessRoleEnum, FileState, StateType } from 'types/entities';
import { changeRollbackPossibility } from 'api/requests/projectTask';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';

import { Tabs } from 'components/tabs';
import TabPaneSkeleton from 'components/tabs/TabPaneSkeleton';
import Block from 'components/block';
import { ButtonTypesEnum } from 'components/button/types';
import Button from 'components/button/Button';

import { checkUserRole } from 'utils/roles';

import { changeTaskStatusRequest, rollbackTaskStatusRequest } from 'store/projects/actions';

import useArtefact from 'hooks/queries/useArtefact';
import useProjectTask from 'hooks/queries/useProjectTask';
import useProjectUser from 'hooks/queries/useProjectUser';
import useCommonContext from 'hooks/useCommonContext';
import useTaskFlowSteps from 'hooks/queries/useTaskFlowSteps';
import useTaskChangeHistory from 'hooks/queries/useTaskChangeHistory';
import useProjectStates from 'hooks/queries/useProjectStates';
import useMaterials from 'hooks/queries/useMaterials';

import ResourceLinkInput from '../../components/resourceLinkInput';
import ResourceLink from '../../components/resourceLink';
import { PresentationFile } from '../../types';
import SkeletonTabs from '../../components/skeleton/SkeletonTabs';
import MaterialItem from '../../../../components/materialItem/Materials';

import { onUploadCheckListUrl, onUploadExternalUrl } from './fetches';

import './styles.scss';

function TaskUniversalPage({ onRollbackTaskStatus }: PropsFromRedux) {
  const [panel, setPanel] = useState<boolean>(true);
  const [selected, setSelected] = useState<string[]>([]);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [link, setLink] = useState<string>('');
  const [linkReserve, setLinkReserve] = useState<string>('');
  const { artefactId } = useParams();
  const { instruction, checkList } = useArtefact(Number(artefactId));

  const common = useCommonContext();

  const onChangeLink = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    setLink(value);
  };

  const onTogglePanel = useCallback(() => setPanel(!panel), [panel]);

  const onOpenHistory = useCallback(() => setShowHistory(true), [setShowHistory]);
  const onCloseHistory = useCallback(() => setShowHistory(false), [setShowHistory]);
  const [searchParams, setSearchParams] = useSearchParams();

  const version = searchParams.get('version');
  const {
    projectId,
    projectTask,
    currentArtefact,
    artefactFile,
    artefactFileVersion,
    refetchProjectTask,
    currentProject,
    loadingProjectTask,
  } = useProjectTask(version, 'network-only');
  const { materials } = useMaterials(Number(currentArtefact?.id));
  const relatedFiles = artefactFile?.related_files as PresentationFile[];

  const currentLink = relatedFiles?.[0]?.url;
  const isActiveCurrentLinkArtefactFile = artefactFileVersion?.is_current;
  const { userRole, userRoles, isAuthor, isMethodist, loadingProjectUser } = useProjectUser(common?.userId, projectId);
  const currentTaskStatusId = Number(projectTask?.project_task?.last_state?.id);

  const { artefactFlowSteps } = useTaskFlowSteps({
    companyArtefactTypeId: Number(currentArtefact?.artefact_type?.id),
    companyStateListVersionId: Number(currentProject?.project_task_state_list_version?.id),
    initialStateId: currentTaskStatusId,
  });

  const { taskChangeHistory, taskChangeHistoryRefetch } = useTaskChangeHistory(projectTask?.project_task?.id);

  const { companyStates } = useProjectStates(projectId);

  useEffect(() => {
    if (currentLink) {
      setLink(currentLink);
      setLinkReserve(currentLink);
    }
  }, [currentLink]);

  useEffect(() => {
    return () => {
      setLink('');
    };
  }, [currentArtefact?.artefact_type?.type]);

  const isCurrentExecutorEntity = projectTask?.project_task?.projecttaskassignee_set?.find(
    (assigner) => assigner.is_current_executor && assigner.is_active
  );

  const isCurrentExecutor =
    Number(isCurrentExecutorEntity?.project_user?.company_user?.user?.id) === Number(common?.userId);
  const isCanRollback = projectTask?.project_task?.project_task_state_history?.is_rollback_possible;

  useEffect(() => {
    if (isCanRollback && isCurrentExecutor) {
      if (projectTask?.id) {
        changeRollbackPossibility(projectTask?.project_task?.id).then(() => refetchProjectTask());
      }
    }
  }, [isCanRollback, isCurrentExecutor, projectTask?.id, projectTask?.project_task?.id, refetchProjectTask]);
  const currentTaskStatus = projectTask?.project_task?.last_state?.state_type;

  const onToggleCheckbox = useCallback(
    (event: CheckboxChangeEvent) => {
      const itemId = event?.target?.name || '';
      const hasId = !!selected.find((s) => s === itemId);
      const item = checkList?.related_files?.find((el) => el.id === itemId);
      const allChildrenParent = checkList?.related_files
        ?.filter((el) => el?.parent_section?.id === item?.parent_section?.id)
        .map((el) => el.id);

      const isAllChildrenSelected = allChildrenParent?.every((el) => [...selected, itemId]?.includes(el));

      const descendants: string[] =
        checkList?.related_files?.filter((el) => el?.parent_section?.id === itemId).map((el) => el.id) || [];

      if (!hasId) {
        if (item?.parent_section) {
          const p = isAllChildrenSelected ? [item?.parent_section.id, itemId] : [itemId];
          setSelected([...selected, ...p]);
        } else {
          setSelected([...selected, ...descendants, itemId]);
        }
      } else {
        const arr = [...descendants, itemId];
        const temp = selected.filter((el) => !arr.includes(el));

        if (item?.parent_section) {
          const p = temp.filter((t) => t !== item?.parent_section?.id);
          setSelected([...p]);
        } else {
          setSelected([...temp]);
        }
      }
    },
    [checkList?.related_files, selected]
  );

  const onSendExternalUrlCallback = async () => {
    if (artefactFile?.state === FileState.uploaded) {
      await onPutFileUrlNew(Number(artefactFile?.pk));
    }
    await onUploadExternalUrl(
      link?.length ? link : linkReserve,
      currentArtefact?.id,
      artefactFileVersion,
      artefactFile
    ).then(() => setShowInput(false));
  };
  //Checklist upload
  const onSendUniversalTaskCheckListCallback = async () => {
    const numberedSelectedArr = selected?.map((item) => {
      return {
        file_document_section_id: Number(item),
      };
    });
    await onUploadCheckListUrl(numberedSelectedArr, artefactFileVersion?.id, Number(checkList?.id));
  };
  const disabledLink = useMemo(() => {
    if (isActiveCurrentLinkArtefactFile === undefined) {
      return false;
    }
    if (!!currentLink && !isCurrentExecutor) {
      return true;
    }
    return !isActiveCurrentLinkArtefactFile;
  }, [currentLink, isActiveCurrentLinkArtefactFile, isCurrentExecutor]);

  const getCallback = () => {
    if (isCurrentExecutor) {
      return onSendExternalUrlCallback;
    }
    if (
      (checkUserRole(userRoles, BusinessRoleEnum.instructionalDesigner) && currentTaskStatus === StateType.REVIEW) ||
      checkUserRole(userRoles, BusinessRoleEnum.manager) ||
      checkUserRole(userRoles, BusinessRoleEnum.executiveManager)
    ) {
      return onSendUniversalTaskCheckListCallback;
    } else {
      return null;
    }
  };
  const showInputHandler = () => {
    setShowInput(true);
    setLink('');
  };
  const closeInputButtonHandler = () => {
    setShowInput(false);
  };

  const tabItems = [
    {
      key: '1',
      label: (
        <TabPaneSkeleton className="testclass-instruction-tab" loading={loadingProjectTask}>
          {common.t<string>('common.instruction')}
        </TabPaneSkeleton>
      ),
      children: loadingProjectTask ? <SkeletonTabs /> : <UniversalTaskInstruction instruction={instruction} />,
    },
    {
      key: '2',
      label: (
        <TabPaneSkeleton className="testclass-check-list-tab" loading={loadingProjectTask}>
          {common.t<string>('common.checkList')}
        </TabPaneSkeleton>
      ),
      children: (
        <CheckList
          checkList={checkList}
          selected={selected}
          onChangeCheckbox={onToggleCheckbox}
          currentTaskStatus={currentTaskStatus}
        />
      ),
    },
    {
      key: '3',
      label: (
        <TabPaneSkeleton className="testclass-materials-tab" loading={loadingProjectTask}>
          {common.t<string>('common.materials')}
        </TabPaneSkeleton>
      ),
      children: (
        <div className="task-final-presentation__materials">
          <MaterialItem materials={materials} />
        </div>
      ),
    },
  ];

  const classNames = cx('task-page task-universal', {
    'task-universal__panel--closed': !panel,
  });

  return (
    <div className={classNames}>
      <TaskHeader
        loading={loadingProjectTask}
        onOpenHistory={onOpenHistory}
        iterations={currentArtefact?.artefact_versions}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
        hintText="pages.task.provideLink"
        isHaveFileLink={isAuthor ? isValidURL(link) : true}
        onRollbackTaskStatus={onRollbackTaskStatus}
        projectTask={projectTask}
        refetchProjectTask={refetchProjectTask}
        userRole={userRole}
        userRoles={userRoles}
        artefactFlowSteps={artefactFlowSteps}
        artefactFile={artefactFile}
        callback={getCallback()}
        onTaskHistoryRefetch={taskChangeHistoryRefetch}
      />
      <Block
        className="task-universal__view"
        hidden={loadingProjectTask || !projectTask || loadingProjectUser || !currentTaskStatus}
      >
        {(isCurrentExecutor && currentTaskStatus === StateType.NEW) ||
        (showInput && isCurrentExecutor && currentTaskStatus === StateType.REOPEN) ? (
          <ResourceLinkInput
            onChangeLink={onChangeLink}
            disabled={disabledLink}
            link={link}
            isError={!!link?.length && !isValidURL(link)}
            closeInputButtonHandler={closeInputButtonHandler}
            currentTaskStatus={currentTaskStatus}
          />
        ) : (
          <ResourceLink
            link={currentLink}
            type={currentArtefact?.artefact_type?.name}
            showInputHandler={showInputHandler}
            currentTaskStatus={currentTaskStatus}
            isCurrentExecutor={isCurrentExecutor}
          />
        )}
      </Block>

      <Tabs
        className="task-universal__tabs"
        defaultActiveKey="1"
        items={!panel ? [] : tabItems}
        tabBarExtraContent={
          <Button
            size="large"
            className="task-sider__button"
            type={ButtonTypesEnum.text}
            icon={
              panel ? <DoubleRightOutlined style={{ fontSize: 20 }} /> : <DoubleLeftOutlined style={{ fontSize: 20 }} />
            }
            onClick={onTogglePanel}
          />
        }
      />

      <HistoryDrawer
        open={showHistory}
        onClose={onCloseHistory}
        data={taskChangeHistory}
        companyStates={companyStates}
      />
    </div>
  );
}

const connector = connect(() => ({}), {
  onRollbackTaskStatus: rollbackTaskStatusRequest,
});
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(TaskUniversalPage);
