import React, { useState } from 'react';

import { Form } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import AuthCard from '../../../components/authCard';
import Button from '../../../../components/button';
import TextField from '../../../../components/textField';
import Text from '../../../../components/text';
import { TextVariantEnum } from '../../../../components/text/types';
import { resetPasswordRequest } from '../../../../store/auth/actions';
import { IResetPasswordPayload } from '../../../../store/auth/interfaces';
import { ButtonTypesEnum } from '../../../../components/button/types';
import './styles.scss';

function ResetPasswordScreen({ onResetPasswordRequest }: PropsFromRedux) {
  const [isSent, setIsSent] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onBack = () => navigate(-1);

  const companyType = searchParams.get('company_type');

  const onClickSubmit = async (values: IResetPasswordPayload) => {
    const payload: IResetPasswordPayload = {
      ...values,
    };

    if (companyType) {
      payload.company_type = companyType;
    }

    await onResetPasswordRequest(payload);
    setIsSent(true);
  };

  return (
    <AuthCard className="auth-page reset-password-page">
      <div className="reset-password-page__items">
        <div className="reset-password-page__title-items mb_32">
          <Button
            onClick={onBack}
            type={ButtonTypesEnum.default}
            size="large"
            icon={<LeftOutlined />}
            className="mr_16"
          />
          <span className="reset-password-page__title">{t('pages.resetPassword.title')}</span>
        </div>
        <Form className="reset-password-page__form" onFinish={onClickSubmit} layout="vertical">
          {isSent ? (
            <Text variant={TextVariantEnum.h5}>{t('pages.resetPassword.text')}</Text>
          ) : (
            <>
              <TextField
                name="email"
                size="xl"
                label={t('form.field.email.label')}
                placeholder={t('form.field.email.placeholder')}
                rules={[{ required: true, type: 'email', message: t('errors.required.email') }]}
              />
              <Button className="button-login" type={ButtonTypesEnum.default} htmlType="submit" size="xl">
                {t('pages.resetPassword.button')}
              </Button>
            </>
          )}
        </Form>
      </div>
    </AuthCard>
  );
}

const connector = connect(null, {
  onResetPasswordRequest: resetPasswordRequest,
});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ResetPasswordScreen);
