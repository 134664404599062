import React from 'react';

import { cx, getCurrentTime, getCurrentTimeOffset } from 'utils';
import RoleProductionView from 'pages/users/viewEmployeeDrawer/RoleProductionView';
import { isEmpty } from 'lodash';

import useCommonContext from 'hooks/useCommonContext';

import Avatar from '../avatar/Avatar';
import StatusLabelFlat from '../statusLabelFlat/StatusLabelFlat';
import ContentBlock from '../contentBlock/ContentBlock';

import './styles.scss';
import { Company, UserBase, UserRoles } from '../../types/entities';
import { ProjectUserItem } from '../../pages/courses/types';

type PopupContentProps = {
  user: ProjectUserItem;
  noPadding?: boolean;
};

function UserDetailPopupContent({ user, noPadding }: PopupContentProps) {
  const common = useCommonContext();
  const roles = user?.roles?.map((u: any) => u.role) || [];

  const classNamesTop = cx('user-detail-content__top', {
    'user-detail-content__top--no-padding': noPadding,
  });
  const classNamesBottom = cx('user-detail-content__bottom', {
    'user-detail-content__bottom--no-padding': noPadding,
  });
  return (
    <div className="user-detail-content">
      <div className={classNamesTop}>
        <div className="user-detail-content__user">
          <Avatar src={user?.company_user?.user?.icon_image} size={48}>
            {user?.company_user?.user?.name?.[0]}
          </Avatar>
          <span className="user-detail-content__user-name">{user?.company_user?.user?.name}</span>
        </div>
        <div className="user-detail-content__status">
          <StatusLabelFlat
            type={user?.company_user?.user?.state}
            name={common.t<string>(`statuses.${user?.company_user?.user?.state?.toLowerCase()}`)}
          />
          <span className="user-detail-content__status-time">
            {user?.company_user?.user?.timezone &&
              `${getCurrentTime(user?.company_user?.user?.timezone)} (UTC ${getCurrentTimeOffset(
                user?.company_user?.user?.timezone
              )})`}
          </span>
        </div>
      </div>
      <div className={classNamesBottom}>
        <ContentBlock
          small
          className="mb_16"
          label={common.t<string>('pages.users.organisation')}
          text={user?.company_user?.company?.name}
        />
        <ContentBlock
          small
          hasCopy
          className="mb_16"
          label={common.t<string>('form.field.email.label')}
          text={user?.company_user?.user?.email}
        />
        <ContentBlock
          label={common.t<string>('pages.users.roleProduction')}
          content={<RoleProductionView className="mt_4" data={roles || []} />}
        />
      </div>
    </div>
  );
}

export default UserDetailPopupContent;
