import React, { memo } from 'react';

import usePageFilters from 'pages/hooks/usePageFilters';

import Switch from 'components/switch';
import Badge from 'components/badge/Badge';
import Select from 'components/select/Select';
import Search from 'components/search';
import { CCMBadgeVariant } from 'components/badge/types';

import useCommonContext from 'hooks/useCommonContext';
import useCompanyUsers from 'hooks/queries/useCompanyUsers';

import { TaskFilter } from '../../types';

import { delaySelectData } from './constants';

import './styles.scss';

function CoursesFilterContainer({ companyMetricsData }: TaskFilter) {
  const common = useCommonContext();
  const { producersDropdownCheckbox } = useCompanyUsers(Number(common?.company?.id));

  const filters = usePageFilters();

  return (
    <div className="courses-filter__container">
      <Switch
        name="missed"
        disabled={!companyMetricsData}
        checked={!!filters.missed}
        onChange={filters.onChangeMissed}
        size="small"
      />
      <span className="courses-filter__label">{common.t<string>('common.deadlinesBroken')}</span>
      <Badge variant={CCMBadgeVariant.light} count={companyMetricsData || 0} />
      <Select
        name="delays"
        mode="multiple"
        maxTagCount="responsive"
        noForm
        placeholder={common.t<string>('common.delayTime')}
        value={filters.delays}
        options={delaySelectData(common.t)}
        onChange={filters.onChangeDelays}
      />
      <Select
        name="assignees"
        mode="multiple"
        maxTagCount="responsive"
        noForm
        placeholder={common.t<string>('common.producer')}
        value={filters.assignees}
        options={producersDropdownCheckbox}
        onChange={filters.onChangeAssignees}
      />
      <Search
        placeholder={common.t<string>('pages.courses.filter.courseName')}
        value={filters.textSearch}
        onChange={filters.onChangeText}
        onSearch={filters.onSearch}
        style={{ width: 240 }}
      />
    </div>
  );
}

export default memo(CoursesFilterContainer);
