import React, { useEffect, useState } from 'react';

import { Maybe } from 'graphql/jsutils/Maybe';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { cx } from '../../utils';
import './styles.scss';

type SvgInlineProps = {
  url?: Maybe<string> | string;
  className?: string;
  size?: number;
};

const request = async (url: string) => {
  return fetch(url, {
    headers: { 'Access-Control-Allow-Origin': '*' },
  });
};

function SvgInline({ url, className, size }: SvgInlineProps) {
  const [svg, setSvg] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);

  const styles = {
    width: size || '24px',
    height: size || '24px',
  };

  useEffect(() => {
    if (url) {
      request(url)
        .then((response: any) => {
          if (response.ok) {
            return response.text();
          } else {
            setError(true);
          }
        })
        .then((json) => {
          setSvg(json);
        })
        .catch((e) => {
          setError(true);
        });
    }
  }, [url]);
  const classNames = cx('ccm-svg-inline', className);
  return error ? (
    <div className={classNames}>
      <QuestionCircleOutlined style={{ fontSize: 12 }} />
    </div>
  ) : (
    <div style={styles} className={classNames} dangerouslySetInnerHTML={{ __html: svg }} />
  );
}

export default SvgInline;
