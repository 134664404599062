import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import HeaderSmall from 'pages/components/headerSmall';
import { isEmpty, noop } from 'lodash';
import { AbilityContext } from 'config/Can';
import useSeparateTask from 'pages/hooks/useSeparateTask';
import { UserExtended } from 'types/entities';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import Button from 'components/button/Button';
import { ButtonTypesEnum } from 'components/button/types';
import LoadingOverlay from 'components/loadingOverlay';

import useCommonContext from 'hooks/useCommonContext';
import useCollaboratorsByRoleQuery from 'hooks/queries/useCollaboratorsByRoleQuery';

import SeparateTaskUploadWall from '../viewSeparateTask/modules/separateTaskUploadWall';
import SeparateTaskUserDrawer from '../components/separateTaskUserDrawer';
import GlobalTaskForm from '../components/globalTaskForm';
import useAddForm from '../users/hooks/useAddForm';
import useProjectAbility from '../project/hooks/useProjectAbility';

import './styles.scss';

function CreateSeparateTaskPage() {
  const [selected, setSelected] = useState<UserExtended[] | null>(null);
  const [search, setSearch] = useState('');
  const common = useCommonContext();
  const { ability, projectData, checkPermissions } = useProjectAbility();

  useEffect(() => {
    if (ability) {
      checkPermissions();
    }
  }, [ability, checkPermissions]);

  const separateTaskInstance = useSeparateTask({});
  const userAddInstance = useAddForm(null, undefined, noop, separateTaskInstance.openDrawer);
  const { collaborators, collaboratorsRefetch } = useCollaboratorsByRoleQuery({ userRoleId: null });

  const openDrawer = () => separateTaskInstance.onOpenDrawer.on();
  const closeDrawer = useCallback(() => {
    separateTaskInstance.onOpenDrawer.off();
    separateTaskInstance.setView('select');
  }, [separateTaskInstance]);

  const goBack = () => common.navigate(-1);

  const onChangeUsers = useCallback(
    (checkedValue: Array<CheckboxValueType>) => {
      const temp = collaborators?.filter((user) => checkedValue.some((v) => user.pk === v));
      if (temp) {
        setSelected(temp);
      }
    },
    [collaborators]
  );

  const onChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearch(e.target.value);
  }, []);

  const onSubmitSelectUsers = useCallback(() => {
    separateTaskInstance.setSelectedUsers(selected);
    setSelected(null);
    closeDrawer();
  }, [closeDrawer, selected, separateTaskInstance]);

  const onSubmitNewUser = async () => {
    await userAddInstance?.onSubmit();
    await collaboratorsRefetch().then(() => {
      separateTaskInstance.setView('select');
      userAddInstance?.form?.resetFields();
    });
  };

  return (
    <AbilityContext.Provider value={ability}>
      <div className="create-free-task-page">
        <LoadingOverlay show={separateTaskInstance.loading} text={common.t<string>('common.saveData')} />
        <HeaderSmall title={common.t<string>('pages.project.create.title')} onGoBack={goBack}>
          <Button size="large" className="ml_auto mr_16" onClick={goBack}>
            {common.t<string>('common.canceling')}
          </Button>
          <Button
            hintText={common.t<string>('pages.project.create.tooltip.createRequired')}
            disabled={separateTaskInstance.disableCreate}
            size="large"
            type={ButtonTypesEnum.primary}
            onClick={separateTaskInstance.onCreateTask}
          >
            {common.t<string>('common.create')}
          </Button>
        </HeaderSmall>
        <div className="create-free-task-page__content">
          <GlobalTaskForm instance={separateTaskInstance} onOpenDrawer={openDrawer} />
          <SeparateTaskUploadWall instance={separateTaskInstance} />
        </div>
        <SeparateTaskUserDrawer
          userAddInstance={userAddInstance}
          visible={separateTaskInstance.openDrawer}
          companyId={projectData?.company?.id}
          disabled={separateTaskInstance.view === 'add' ? userAddInstance?.disableSubmit : isEmpty(selected)}
          view={separateTaskInstance.view}
          onChangeView={separateTaskInstance.onChangeView}
          search={search}
          collaborators={collaborators}
          selectedUsers={separateTaskInstance.selectedUsers}
          onChangeUsers={onChangeUsers}
          onChangeSearch={onChangeSearch}
          onClose={closeDrawer}
          onSubmit={separateTaskInstance.view === 'add' ? onSubmitNewUser : onSubmitSelectUsers}
        />
      </div>
    </AbilityContext.Provider>
  );
}

export default CreateSeparateTaskPage;
