import React, { ChangeEvent, useMemo, useState } from 'react';

import dayjs, { isDuration } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import { cx, dayjsToDuration, durationToHoursMinSeconds } from 'utils';
import { LockOutlined, SwapRightOutlined, UnlockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { onAddIssueVideo } from 'api/requests/issues';
import { useResizeDetector } from 'react-resize-detector';

import Select from 'components/select';
import TimePicker from 'components/timePicker';
import Checkbox from 'components/checkbox';
import TextField from 'components/textField';
import { TextFieldTypeEnum } from 'components/textField/types';
import Block from 'components/block';
import Tooltip from 'components/tooltip';

import Button from '../button';
import { ButtonTypesEnum } from '../button/types';

import { ErrorConstructorProps, ErrorConstructorState } from './types';

import './styles.scss';

dayjs.extend(utc);
dayjs.extend(duration);

const initState: ErrorConstructorState = {
  issue: null,
  timestamp_start: undefined,
  timestamp_end: undefined,
  description: '',
  closedStart: false,
  closedEnd: false,
};

function range(start: number, end: number) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function ErrorConstructor({
  className,
  duration: videoDuration,
  played,
  currentArtefact,
  errorTypes,
  refetch,
}: ErrorConstructorProps) {
  const [values, setValues] = useState(initState);
  const { t } = useTranslation();
  const { width = 1, ref } = useResizeDetector();

  const start = values.timestamp_start && dayjsToDuration(values?.timestamp_start);
  const end = values.timestamp_end && dayjsToDuration(values?.timestamp_end);

  const onOpenTimeStart = (open: boolean) => {
    if (open) {
      const formatted = dayjs.utc(videoDuration * played * 1000);
      setValues({ ...values, timestamp_start: formatted });
    }
  };

  const onOpenTimeEnd = (open: boolean) => {
    if (open) {
      const formatted = dayjs.utc(videoDuration * played * 1000);
      setValues({ ...values, timestamp_end: formatted });
    }
  };

  const handleChangeIssue = (value: string, option: any) => {
    setValues({ ...values, issue: option });
  };

  const handleChangeTimepicker = (name: string, time: any) => {
    const selected = dayjsToDuration(time);

    if (selected && selected > videoDuration) {
      const formatted = moment.utc(videoDuration * 1000);

      setValues({ ...values, [name]: formatted });
    } else if (name === 'timestamp_end' && selected) {
      if (start && start > selected) {
        setValues({ ...values, [name]: values?.timestamp_start });
      } else {
        setValues({ ...values, [name]: time });
      }
    } else {
      if (selected && end && selected > end) {
        setValues({ ...values, timestamp_start: time, timestamp_end: time });
      } else {
        setValues({ ...values, [name]: time });
      }
    }
  };

  const handleChangeDescription = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues({ ...values, description: e.target.value });
  };

  const handleChangeCheckbox = (e: CheckboxChangeEvent) => {
    const { name = '', checked } = e.target;
    setValues({ ...values, [name]: checked });
  };

  const disabledDateTime = () => {
    const { hh, mm, ss } = durationToHoursMinSeconds(videoDuration);

    return {
      disabledHours: () => range(0, 24).splice(hh === 0 ? hh : hh + 1, 24),
      disabledMinutes: () => range(0, 60).splice(hh === 0 ? mm + 1 : 60, 60),
      disabledSeconds: () => range(0, 60).splice(mm === 0 ? ss + 1 : 60, 60),
    };
  };

  const disabledSubmit = useMemo(() => {
    if (!values.issue) {
      return true;
    }

    if (!dayjsToDuration(values.timestamp_start)) {
      return true;
    }

    if (start && end) {
      return start > end;
    }
    return false;
  }, [end, start, values.issue, values.timestamp_start]);

  const tooltipText = useMemo(() => {
    if (!values.issue) {
      return t<string>('pages.task.tooltip.selectError');
    }
    if (!dayjsToDuration(values.timestamp_start)) {
      return t<string>('pages.task.tooltip.selectTimeErrorStart');
    }
    if (start && end) {
      return t<string>('pages.task.tooltip.timeErrorLess');
    }
  }, [end, start, t, values.issue, values.timestamp_start]);

  const onSubmit = async () => {
    const payload = {
      artefact_id: Number(currentArtefact?.id),
      artefact_content_issue_id: Number(values?.issue?.pk),
      description: values.description,
      timestamp_start: dayjsToDuration(values.timestamp_start),
      timestamp_end: dayjsToDuration(values.timestamp_end),
    };

    await onAddIssueVideo(payload).then(() => {
      refetch();
      setValues(initState);
    });
  };

  const classNames = cx('error-constructor', className, {
    'error-constructor--small': width < 600,
  });

  return (
    <div ref={ref} className={classNames}>
      <div className="error-constructor__row">
        <Select
          noForm
          fullWidth
          name="issue"
          options={errorTypes || []}
          placeholder={t('form.field.errorType.placeholder')}
          className="mr_16 testclass-errorconstructor-filter-errortype"
          value={values.issue}
          onChange={handleChangeIssue}
        />
        <TimePicker
          noForm
          name="timestamp_start"
          className="mr_6 testclass-errorconstructor-filter-errorstart"
          placeholder="00:00:00"
          showNow={false}
          format="HH:mm:ss"
          value={values.timestamp_start}
          disabled={values.closedStart}
          disabledTime={disabledDateTime}
          onOpenChange={onOpenTimeStart}
          onChangeWithName={handleChangeTimepicker}
        />
        <Checkbox
          iconOnly
          name="closedStart"
          className="mr_10 testclass-errorconstructor-filter-errostart-lock"
          checked={values.closedStart}
          onChange={handleChangeCheckbox}
        >
          {values.closedStart ? <LockOutlined /> : <UnlockOutlined />}
        </Checkbox>
        <SwapRightOutlined className="mr_10" />
        <TimePicker
          noForm
          name="timestamp_end"
          className="mr_6 testclass-errorconstructor-filter-enderror"
          placeholder="00:00:00"
          format="HH:mm:ss"
          showNow={false}
          defaultValue={values?.timestamp_start}
          value={values.timestamp_end}
          disabled={values.closedEnd || !values.timestamp_start}
          disabledTime={disabledDateTime}
          onOpenChange={onOpenTimeEnd}
          onChangeWithName={handleChangeTimepicker}
        />
        <Checkbox
          className="testclass-errorconstructor-filter-enderror-lock"
          iconOnly
          name="closedEnd"
          checked={values.closedEnd}
          onChange={handleChangeCheckbox}
        >
          {values.closedEnd ? <LockOutlined /> : <UnlockOutlined />}
        </Checkbox>
      </div>
      <Block empty hidden={!values.issue}>
        <TextField
          noForm
          name="description"
          placeholder={t('form.field.description.placeholder')}
          type={TextFieldTypeEnum.textArea}
          autoSize={{ minRows: 1, maxRows: 6 }}
          className="mb_12 testclass-errorconstructor-enterdescription"
          onChange={handleChangeDescription}
        />
      </Block>

      {disabledSubmit ? (
        <Tooltip title={tooltipText}>
          <div className="error-constructor__wrap-btn">{t('pages.task.video.addError')}</div>
        </Tooltip>
      ) : (
        <Button
          disabled={disabledSubmit}
          onClick={onSubmit}
          type={ButtonTypesEnum.primary}
          className="add-error testclass-errorconstructor-adderror"
        >
          {t('pages.task.video.addError')}
        </Button>
      )}
    </div>
  );
}

export default ErrorConstructor;
