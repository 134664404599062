import { gql } from '@apollo/client';

export const GET_PROJECT_METRICS = gql`
  query GetProjectMetrics($project_id: Int) {
    metrics_assignees_missed_deadline_first_iteration(project_id: $project_id) {
      edges {
        node {
          id
          assignee {
            id
          }
          tasks_amount
          delay_duration_max
          calculation_date
          delay_duration_sum
        }
      }
    }
    metrics_assignees_missed_deadline_production(project_id: $project_id) {
      edges {
        node {
          id
          assignee {
            id
            first_name
            last_name
          }
          tasks_amount
          delay_duration_max
          calculation_date
          delay_duration_sum
        }
      }
    }
    metrics_assignees_tasks_with_four_iterations_annotated(project_id: $project_id) {
      edges {
        node {
          id
          assignee {
            id
            first_name
            last_name
            email
          }
          reviewer {
            id
            first_name
            last_name
            email
          }
          total_tasks_amount
          calculation_date
        }
      }
    }
    metrics_current_assignees_missed_deadline_first_iteration(project_id: $project_id) {
      edges {
        node {
          id
          assignee {
            id
          }
          tasks_amount
          delay_duration_max
          calculation_date
          delay_duration_sum
        }
      }
    }
    metrics_current_assignees_missed_deadline_production(project_id: $project_id) {
      edges {
        node {
          id
          assignee {
            id
            first_name
            last_name
          }
          tasks_amount
          delay_duration_max
          calculation_date
          delay_duration_sum
        }
      }
    }
    metrics_current_assignees_tasks_with_four_iterations_annotated(project_id: $project_id) {
      edges {
        node {
          id
          assignee {
            id
            first_name
            last_name
            email
          }
          reviewer {
            id
            first_name
            last_name
            email
          }
          total_tasks_amount
          calculation_date
        }
      }
    }
  }
`;
