import { TFunction } from 'react-i18next';

export const radioButtonMap = (t: TFunction) => [
  {
    label: t('pages.course.modal.video'),
    value: 'video',
  },
  {
    label: t('pages.course.modal.addMaterial'),
    value: 'addMaterial',
  },
  {
    label: t('pages.course.modal.invaluableTask'),
    value: 'invaluableTask',
  },
  {
    label: t('pages.course.modal.task'),
    value: 'task',
  },
];
