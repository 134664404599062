import React, { memo } from 'react';

import { Tabs as ATabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';
import { cx } from 'utils';

interface ITabsProps extends TabsProps {
  extraContent?: React.ReactNode;
  className?: string;
}

function Tabs({ className, children, onChange, extraContent, ...props }: ITabsProps) {
  const classNames = cx('ccm-tabs', className);
  return (
    <ATabs className={classNames} defaultActiveKey="1" onChange={onChange} tabBarExtraContent={extraContent} {...props}>
      {children}
    </ATabs>
  );
}

export default memo(Tabs);
