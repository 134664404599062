import { gql } from '@apollo/client';

export const GET_COLLABORATOR = gql`
  query Collaborator($companyUserId: Int) {
    collaborator(company_user_id: $companyUserId) {
      pk
      user {
        id
        name
        email
        state
      }
      technical_role
      business_roles {
        id
        name
      }
      is_active
      company {
        id
        name
      }
    }
  }
`;
