import React, { Suspense } from 'react';

import { createRoot } from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import history from 'router/history';
import { ApolloProvider } from '@apollo/client';
import { GlobalActionsProvider } from 'common/GlobalActionsProvider';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';

import store from './store/store';
import { client } from './schemas';
import App from './App';
import './i18n';
import { CommonPropsProvider } from './common/commonPropsProvider';
import ErrorBoundary from './components/errorBoundry';

import './styles/global-styles.scss';

posthog.init('phc_vnnSLZf6FcPxvZQGvch88v7P9hh8V4UX1WtM0UJ5SRR', {
  api_host: 'https://eu.posthog.com',
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <HistoryRouter history={history}>
        <Suspense fallback="">
          <CommonPropsProvider>
            <GlobalActionsProvider>
              <ErrorBoundary>
                <PostHogProvider client={posthog}>
                  <App />
                </PostHogProvider>
              </ErrorBoundary>
            </GlobalActionsProvider>
          </CommonPropsProvider>
        </Suspense>
      </HistoryRouter>
    </ApolloProvider>
  </Provider>
);
