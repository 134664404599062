import React, { memo } from 'react';

import { Skeleton } from 'antd';

import '../../styles.scss';

const styles = {
  widgetTitle: { width: '100%', maxWidth: '211px', height: '24px', marginBottom: '19px' },
  widgetBlock: { width: '100%', maxWidth: '800px', height: '88px' },
  title: { width: '100%', height: '24px', maxWidth: '180px', marginBottom: '16px' },
  block: { width: '100%', height: '40px' },
};

function DashboardSkeleton() {
  return (
    <div className="skeleton-dashboard">
      <div className="skeleton-dashboard__row mb_32">
        <Skeleton paragraph={false} avatar={false} active style={styles.widgetTitle} />
        <div className="skeleton-dashboard__row-sub">
          <Skeleton paragraph={false} avatar={false} active style={styles.widgetBlock} />
          <Skeleton paragraph={false} avatar={false} active style={styles.widgetBlock} />
          <Skeleton paragraph={false} avatar={false} active style={styles.widgetBlock} />
        </div>
      </div>
      <div className="skeleton-dashboard__row-b mb_32">
        <Skeleton paragraph={false} avatar={false} active style={styles.title} />
        <div className="skeleton-dashboard__row-s">
          <Skeleton paragraph={false} avatar={false} active style={styles.block} />
          <Skeleton paragraph={false} avatar={false} active style={styles.block} />
          <Skeleton paragraph={false} avatar={false} active style={styles.block} />
        </div>
      </div>
      <div className="skeleton-dashboard__row-b">
        <Skeleton paragraph={false} avatar={false} active style={styles.title} />
        <div className="skeleton-dashboard__row-s">
          <Skeleton paragraph={false} avatar={false} active style={styles.block} />
          <Skeleton paragraph={false} avatar={false} active style={styles.block} />
          <Skeleton paragraph={false} avatar={false} active style={styles.block} />
        </div>
      </div>
    </div>
  );
}

export default memo(DashboardSkeleton);
