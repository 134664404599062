import React, { memo } from 'react';

import { cx } from 'utils';
import { AvatarProps } from 'antd/lib/avatar/avatar';
import { Avatar as AvatarAnt } from 'antd';

import Popover from '../popover';
import Avatar from '../avatar';
import UserDetailPopupContent from '../userDetailWithPopup/UserDetailPopupContent';
import { CompanyUserItem } from '../../types/entities';

type CCMAvatarProps = {
  className?: string;
  hasPopover?: boolean;
  users?: CompanyUserItem[];
  maxPopoverTrigger?: 'hover' | 'focus' | 'click';
};

function AvatarGroup({ className, users, hasPopover, maxPopoverTrigger = 'click' }: CCMAvatarProps & AvatarProps) {
  const classNames = cx('ccm-avatar-group', className);

  return (
    <AvatarAnt.Group
      className={classNames}
      maxCount={3}
      maxStyle={{ color: '#1E1E1E', backgroundColor: '#E9E9E9', fontSize: '11px' }}
      maxPopoverTrigger={maxPopoverTrigger}
    >
      {users?.map((user: CompanyUserItem, index) => (
        <Popover
          key={index}
          open={hasPopover ? undefined : false}
          content={<UserDetailPopupContent user={user} noPadding />}
          placement="right"
        >
          <Avatar
            style={{
              color: '#1E1E1E',
              backgroundColor: '#E9E9E9',
              fontSize: '11px',
            }}
            src={user?.company_user?.user?.icon_image}
          >
            {user?.company_user?.user?.name?.[0]}
          </Avatar>
        </Popover>
      ))}
    </AvatarAnt.Group>
  );
}

export default memo(AvatarGroup);
