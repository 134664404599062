import React, { memo, SyntheticEvent } from 'react';

import { LoadingOutlined } from '@ant-design/icons';

import TimelineArtefactDeadlinesChanged from 'components/timeline/TimelineArtefactDeadlinesChanged';

import { LazyArtefactDeadlinesChangedItem } from 'hooks';

type ArtefactDeadlinesChangedContentProps = {
  data?: LazyArtefactDeadlinesChangedItem[];
  loading: boolean;
  isSeparate: boolean;
};

function ArtefactDeadlinesChangedContent({ data, loading, isSeparate }: ArtefactDeadlinesChangedContentProps) {
  const onHandelClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };
  return (
    <div className="ccm-popover-history__wrapper" onClick={onHandelClick}>
      {loading ? <LoadingOutlined /> : <TimelineArtefactDeadlinesChanged isSeparate={isSeparate} data={data} />}
    </div>
  );
}

export default memo(ArtefactDeadlinesChangedContent);
