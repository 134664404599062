import React, { useState } from 'react';

import { Menu } from 'antd';
import { cx } from 'utils';

import Button from 'components/button/Button';
import { ButtonTypesEnum } from 'components/button/types';
import Block from 'components/block';

import './styles.scss';

const data = [2, 1.5, 1];

type PlaybackRateProps = {
  className?: string;
  value?: number;
  callback: (val: number) => void;
};

function PlaybackRate({ className, value, callback }: PlaybackRateProps) {
  const [show, setShow] = useState<boolean>(false);

  const handleClick = (val: number) => {
    callback(val);
    setShow(false);
  };

  const onToggle = () => setShow(!show);

  const classNames = cx('playback-rate', className);

  const items = data.map((el) => {
    const classNamesButton = cx('playback-rate-btn', `testclass-video-speed-x${el}`, {
      'playback-rate-btn--colored': el === value,
    });
    return {
      key: el.toString(),
      label: (
        <div className={classNamesButton} onClick={() => handleClick(el)}>
          {`${el}x`}
        </div>
      ),
    };
  });

  return (
    <div className={classNames}>
      <Block empty hidden={!show}>
        <Menu className="playback-rate-menu" items={items} />
      </Block>
      <Button className="playback-rate__toggle" type={ButtonTypesEnum.text} onClick={onToggle}>
        {`${value}x`}
      </Button>
    </div>
  );
}

export default PlaybackRate;
