import { gql } from '@apollo/client';

export const GET_ARTEFACT = gql`
  query Artefact($artefactId: Int) {
    artefact(artefact_id: $artefactId) {
      id
      pk
      documents {
        id
        name
        file_type
        related_files {
          ... on DocumentFileSectionType {
            id
            file {
              id
            }
            parent_section {
              id
            }
            section_type
            section_content
            style
            level
            params
            order
          }
        }
      }
    }
  }
`;
