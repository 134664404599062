import { IAction } from 'types/store';

import IArtefactState from './interfaces';
import ArtefactsTypes from './types';

const initialState: IArtefactState = {
  count: 0,
  next: null,
  previous: null,
  artefact: null,
  learningObject: null,
  finalArtefactPipeline: null,
};

export default function (state = initialState, action: IAction<ArtefactsTypes>) {
  switch (action.type) {
    case ArtefactsTypes.SET_ARTEFACT_DATA:
      return {
        ...state,
        artefact: action.payload,
      };
    case ArtefactsTypes.SET_LEARNING_OBJECT_DATA:
      return {
        ...state,
        learningObject: action.payload,
      };
    case ArtefactsTypes.SET_FINAL_ARTEFACT_PIPELINE_DATA:
      return {
        ...state,
        finalArtefactPipeline: action.payload,
      };
    default:
      return state;
  }
}
