import React, { memo } from 'react';

import { HistoryOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { ButtonTypesEnum } from 'components/button/types';

import { ProjectVersionType } from '../../../../../../../../schemas/types';
import useCommonContext from '../../../../../../../../hooks/useCommonContext';
import { getFormattedEndByStr } from '../../../../../../../../utils';
import Block from '../../../../../../../../components/block';
import './styles.scss';

type ProjectVersionViewType = {
  version?: ProjectVersionType;
};

function VersionView({ version }: ProjectVersionViewType) {
  const common = useCommonContext();
  return (
    <Block hidden={!version?.number || !version?.updated_at}>
      <Button className="download-drawer-version" size="middle" disabled type={ButtonTypesEnum.link}>
        <HistoryOutlined />
        <span className="download-drawer-version__text">{`v${version?.number} ${common?.t<string>(
          'common.from'
        )} ${getFormattedEndByStr(version?.updated_at)}`}</span>
      </Button>
    </Block>
  );
}

export default memo(VersionView);
