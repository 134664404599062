import { useMemo } from 'react';

import { generatePath, useParams } from 'react-router-dom';
import { BusinessRoleEnum } from 'types/entities';
import { convertRoles } from 'utils';
import { buildAbilityFor } from 'config/ability';

import { checkUserBusinessRole } from 'utils/roles';

import useProject from 'hooks/queries/useProject';
import useCommonContext from 'hooks/useCommonContext';

import { PATH_NAMES } from '../../../router/constants';
import useProjectUsers from '../../../hooks/queries/useProjectUsers';

function useProjectAbility(projectId?: string) {
  const common = useCommonContext();
  const { id } = useParams();
  const { projectLoading, projectData, projectRefetch } = useProject(Number(projectId || id));
  const { projectUsers } = useProjectUsers(projectId || id);
  const userRolesByProject = projectUsers?.find((item) => item?.company_user?.user.id === common?.userId?.toString());

  const getUserRoles = useMemo(() => {
    if (
      (checkUserBusinessRole(common.businessRoles, BusinessRoleEnum.executiveManager) &&
        common.company?.id === projectData?.company?.id) ||
      (checkUserBusinessRole(common.businessRoles, BusinessRoleEnum.support_manager) &&
        common.company?.id === projectData?.company?.id)
    ) {
      return convertRoles(common.businessRoles);
    }

    return userRolesByProject?.roles;
  }, [common.businessRoles, common.company?.id, projectData?.company?.id, userRolesByProject?.roles]);

  const ability = buildAbilityFor(getUserRoles);

  const checkPermissions = () => {
    const allowed = ability.can('manage', 'Project.plan.create.separate');

    if (!allowed && getUserRoles) {
      const path = generatePath(PATH_NAMES.project.baseExtended, {
        id: id,
        entity: PATH_NAMES.project.entity.plan.tree,
      });
      common.navigate(path);
    }
  };

  return {
    ability,
    projectLoading,
    projectData,
    projectRefetch,
    checkPermissions,
  };
}

export default useProjectAbility;
