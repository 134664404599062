import React, { memo } from 'react';

import TruncateMarkup from 'react-truncate-markup';
import { ArrowLeftOutlined } from '@ant-design/icons';
import history from 'router/history/history';
import { PATH_NAMES } from 'router/constants';

import Button from 'components/button';

import ProjectNavigation from './Navigation';

import './styles.scss';
import { INavigationItem } from '../../../../types/global';

type ProjectHeaderProps = {
  title: string;
  naviMap?: INavigationItem[];
};

function ProjectHeader({ title, naviMap }: ProjectHeaderProps) {
  const onButtonClick = () => {
    history.push(PATH_NAMES.courses.base);
  };

  const projectNavigationMap = [
    {
      id: '1',
      name: 'pages.project.navigation.about',
      path: PATH_NAMES.project.entity.about,
    },
    {
      id: '2',
      name: 'pages.project.navigation.executors',
      path: PATH_NAMES.project.entity.participants,
    },
    {
      id: '3',
      name: 'pages.project.navigation.content',
      path: PATH_NAMES.project.entity.content,
    },
    {
      id: '4',
      name: 'pages.project.navigation.plan',
      path: PATH_NAMES.project.entity.plan.tree,
      end: '/project/:id/plan',
    },
  ];

  return (
    <div className="project-header">
      <div className="project-header__top">
        <Button shape="circle" icon={<ArrowLeftOutlined />} onClick={onButtonClick} />
        <TruncateMarkup lines={1}>
          <span className="project-header__title">{title}</span>
        </TruncateMarkup>
      </div>
      <div className="project-header__bottom">
        <ProjectNavigation list={naviMap || projectNavigationMap} />
      </div>
    </div>
  );
}

export default memo(ProjectHeader);
