import React from 'react';

import Header from 'pages/components/header';
import { buildAbilityFor } from 'config/ability';
import { convertRoles } from 'utils';
import Can, { AbilityContext } from 'config/Can';

import Block from 'components/block';

import useCommonContext from 'hooks/useCommonContext';

import DashboardCourses from './components/courses';
import DashboardUsers from './components/users';
import DashboardTasks from './components/tasks';
import useCoursesData from './hooks/useCoursesData';
import useCollaboratorsData from './hooks/useCollaboratorsData';
import useTasksData from './hooks/useTasksData';
import Skeleton from './components/skeletons';

import './styles.scss';

function DashboardPage() {
  const common = useCommonContext();
  const ability = buildAbilityFor(convertRoles(common.businessRoles));

  const {
    coursesLoading,
    coursesWidgetData,
    coursesNoAssigneesData,
    coursesWithIssuesData,
    coursesWithDeadlineData,
    coursesReady,
  } = useCoursesData();
  const { collaboratorsReady, collaboratorsWidgetData, collaboratorsGanttData } = useCollaboratorsData();
  const {
    tasksWidgetData,
    tasksDeadlinesMissingData,
    tasksMoreThanThreeIterationData,
    tasksDeadlinesInTwoDaysData,
    tasksWithNewCommentsData,
    tasksForTodayData,
    tasksLoading,
    tasksReady,
  } = useTasksData();

  const allDataReady = coursesReady && collaboratorsReady && tasksReady;

  return (
    <AbilityContext.Provider value={ability}>
      <div className="page-base page-dashboard">
        <Header loading={!allDataReady} hasBorder title={common.t<string>('pages.dashboard.title')} />

        <Block empty hidden={allDataReady}>
          <Skeleton />
        </Block>

        <Block className="page-dashboard__wrapper" hidden={!allDataReady}>
          <Can I="view" a="Dashboard.courses">
            <DashboardCourses
              coursesLoading={coursesLoading}
              allDataReady={allDataReady}
              coursesWidgetData={coursesWidgetData}
              coursesNoAssigneesData={coursesNoAssigneesData}
              coursesWithIssuesData={coursesWithIssuesData}
              coursesWithDeadlineData={coursesWithDeadlineData}
            />
          </Can>
          <Can I="view" a="Dashboard.collaborators">
            <DashboardUsers
              collaboratorsWidgetData={collaboratorsWidgetData}
              collaboratorsGanttData={collaboratorsGanttData}
            />
          </Can>
          <Can I="view" a="Dashboard.tasks">
            <DashboardTasks
              allDataReady={allDataReady}
              tasksLoading={tasksLoading}
              tasksWidgetData={tasksWidgetData}
              tasksDeadlinesMissingData={tasksDeadlinesMissingData}
              tasksMoreThanThreeIterationData={tasksMoreThanThreeIterationData}
              tasksDeadlinesInTwoDaysData={tasksDeadlinesInTwoDaysData}
              tasksWithNewCommentsData={tasksWithNewCommentsData}
              tasksForTodayData={tasksForTodayData}
            />
          </Can>
        </Block>
      </div>
    </AbilityContext.Provider>
  );
}

export default DashboardPage;
