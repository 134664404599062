import React, { memo, useMemo } from 'react';

import { convertUserData, cx, declOfNum, declTitleTask } from 'utils';
import { CcmUserState } from 'schemas/types';
import { noop } from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';

import useCommonContext from 'hooks/useCommonContext';

import Avatar from '../avatar';
import { UserExtended } from '../../types/entities';
import Popover from '../popover';
import UserDetailPopupContent from '../userDetailWithPopup/UserDetailPopupContent';
import StatusLabelFlat from '../statusLabelFlat/StatusLabelFlat';
import useProjectUsersWithTasksAmount from '../../hooks/queries/useProjectUsersWithTasksAmount';

import './styles.scss';

type UserInfoProps = {
  disabled?: boolean;
  size?: 'm' | 'l' | 'xl';
  user?: UserExtended;
  hasPopover?: boolean;
  onClick?: () => void;
};

const getAvatarSize = (size?: 'm' | 'l' | 'xl') => {
  switch (size) {
    case 'm':
      return 32;
    case 'l':
      return 48;
    case 'xl':
      return 64;
    default:
      return 32;
  }
};

function UserInfo({ disabled, size = 'm', user, hasPopover, onClick }: UserInfoProps) {
  const { id } = useParams();
  const common = useCommonContext();
  const { projectUsersWithTasksAmount } = useProjectUsersWithTasksAmount(Number(id));

  const tasksAmount = projectUsersWithTasksAmount?.find((u) => u.company_user.user.id === user?.user?.id);

  const path = `/project/${id}/plan/tree?assignees=${user?.user?.id}`;

  const name = useMemo(() => {
    if (user?.user.name) {
      return user?.user.name;
    }
    if (user?.user.first_name) {
      return `${user?.user.first_name} ${user?.user.last_name}`;
    }
    return user?.user.email;
  }, [user?.user.email, user?.user.first_name, user?.user.last_name, user?.user.name]);

  const short = name?.[0] || '';

  if (!user) {
    return null;
  }

  const classNames = cx('user-element', {
    [`user-element--${size}`]: size,
    'user-element--disabled': disabled,
  });

  const classNamesWrapper = cx('user-element-wrapper', {
    [`user-element-wrapper--${size}`]: size,
    'user-element-wrapper--disabled': disabled,
  });

  return (
    <div className={classNamesWrapper}>
      <Popover open={hasPopover ? undefined : false} content={<UserDetailPopupContent user={convertUserData(user)} />}>
        <div onClick={disabled ? noop : onClick} className={classNames}>
          <Avatar src={user?.user?.icon_image} size={getAvatarSize(size)}>
            {short}
          </Avatar>
          <div className="user-element__wrap">
            {size !== 'm' ? <span className="user-element__company">{user?.company?.name}</span> : null}
            <div className="user-element__name-wrap">
              <span className="user-element__name">{name}</span>
              {user.user.state === CcmUserState.Invited ? (
                <StatusLabelFlat
                  type={user?.user.state}
                  name={common.t(`statuses.${user?.user.state?.toLocaleLowerCase()}`)}
                />
              ) : null}
              {/*<span className="ccm-user-info__timezone">*/}
              {/*  {user?.user?.timezone &&*/}
              {/*    `${getCurrentTime(user?.user?.timezone)} (UTC ${getCurrentTimeOffset(user?.user?.timezone)})`}*/}
              {/*</span>*/}
            </div>
          </div>
        </div>
      </Popover>
      {tasksAmount?.tasks_amount && tasksAmount?.tasks_amount > 0 ? (
        <Link target="_blank" to={path} className="user-element__link">
          <span className="user-element__link-text">
            {`${tasksAmount?.tasks_amount} ${common.t<string>(
              declOfNum(tasksAmount?.tasks_amount || 0, declTitleTask)
            )}`}
          </span>
          <ArrowRightOutlined style={{ fontSize: 14 }} />
        </Link>
      ) : null}
    </div>
  );
}

export default memo(UserInfo);
