import React, { memo } from 'react';

import { BusinessRoleEnum } from 'types/entities';
import { convertProjectUsersToUsersExtended, getRoleItem } from 'utils';
import { useParams } from 'react-router-dom';
import { isEmpty, noop } from 'lodash';

import Block from 'components/block';

import { CompanyRole } from 'hooks/queries/useCompanyRoles';
import useAbility from 'hooks/useAbility';
import useCommonContext from 'hooks/useCommonContext';
import useProjectUsersWithTasksAmount from 'hooks/queries/useProjectUsersWithTasksAmount';

import ParticipantsBlock from '../participantsBlock';
import { UsersState } from '../../addCourse/hooks/useAddCourseControl';
import ParticipantsGroup from '../participantsGroup';
import { ProjectUserType } from '../../../hooks';
import UserElement from '../../../components/userElement/UserElement';

import './styles.scss';

type ParticipantsProps = {
  loading?: boolean;
  users: UsersState;
  separateProjectTaskAssignee?: ProjectUserType[];
  onOpenDrawer: (roleId: string) => void;
  showTitle?: boolean;
  companyRoles?: CompanyRole[];
};

function Participants({
  loading,
  showTitle,
  users,
  separateProjectTaskAssignee,
  onOpenDrawer,
  companyRoles,
}: ParticipantsProps) {
  const { id } = useParams();
  const common = useCommonContext();
  const ability = useAbility();
  useProjectUsersWithTasksAmount(Number(id));

  return (
    <div className="participants">
      <Block hidden={!showTitle} className="participants__title">
        {common.t<string>('pages.addCourse.participants.participants')}
      </Block>
      <Block
        className="participants__row mb_64"
        hidden={!getRoleItem(companyRoles, BusinessRoleEnum.stakeholder)?.name}
      >
        <ParticipantsBlock
          hasTitle
          canEdit={ability?.can('update', 'Project.participants.customer')}
          title={getRoleItem(companyRoles, BusinessRoleEnum.stakeholder)?.name}
          role={getRoleItem(companyRoles, BusinessRoleEnum.stakeholder)}
          users={users}
          onClick={onOpenDrawer}
        />
      </Block>
      <ParticipantsGroup
        loading={loading}
        className="participants__row-authors"
        groupTitle={common.t<string>('pages.addCourse.participants.production')}
      >
        <div className="participants__row">
          <ParticipantsBlock
            hasTitle
            canEdit={ability?.can('update', 'Project.participants.manager')}
            title={getRoleItem(companyRoles, BusinessRoleEnum.manager)?.name}
            role={getRoleItem(companyRoles, BusinessRoleEnum.manager)}
            users={users}
            onClick={onOpenDrawer}
          />
          <ParticipantsBlock
            hasTitle
            canEdit={ability?.can('update', 'Project.participants.instructionalDesigner')}
            title={getRoleItem(companyRoles, BusinessRoleEnum.instructionalDesigner)?.name}
            role={getRoleItem(companyRoles, BusinessRoleEnum.instructionalDesigner)}
            users={users}
            onClick={onOpenDrawer}
          />
          <ParticipantsBlock
            hasTitle
            canEdit={ability?.can('update', 'Project.participants.learningTechnologist')}
            title={getRoleItem(companyRoles, BusinessRoleEnum.learningTechnologist)?.name}
            role={getRoleItem(companyRoles, BusinessRoleEnum.learningTechnologist)}
            users={users}
            onClick={onOpenDrawer}
          />
        </div>
      </ParticipantsGroup>

      <ParticipantsGroup
        loading={loading}
        className="participants__row-authors"
        groupTitle={common.t<string>('pages.addCourse.participants.collectiveOfAuthors')}
      >
        <Block className="participants__row" hidden={!getRoleItem(companyRoles, BusinessRoleEnum.authorManager)}>
          <ParticipantsBlock
            canEdit={ability?.can('update', 'Project.participants.authorManager')}
            label={getRoleItem(companyRoles, BusinessRoleEnum.authorManager)?.name}
            // hint="Выбирает авторов курса и распределять задачи между ними "
            role={getRoleItem(companyRoles, BusinessRoleEnum.authorManager)}
            users={users}
            onClick={onOpenDrawer}
          />
        </Block>

        <ParticipantsBlock
          canEdit={ability?.can('update', 'Project.participants.author')}
          label={getRoleItem(companyRoles, BusinessRoleEnum.author)?.name}
          role={getRoleItem(companyRoles, BusinessRoleEnum.author)}
          users={users}
          onClick={onOpenDrawer}
        />
      </ParticipantsGroup>

      <ParticipantsGroup
        loading={loading}
        groupTitle={common.t<string>('pages.addCourse.participants.videoProduction')}
      >
        <Block
          className="participants__row"
          hidden={!getRoleItem(companyRoles, BusinessRoleEnum.videoProductionManager)}
        >
          <ParticipantsBlock
            canEdit={ability?.can('update', 'Project.participants.videoProductionManager')}
            role={getRoleItem(companyRoles, BusinessRoleEnum.videoProductionManager)}
            label={getRoleItem(companyRoles, BusinessRoleEnum.videoProductionManager)?.name}
            // hint="Выбирает участников команды видеопроизводства и распределяет задачи между ними "
            users={users}
            onClick={onOpenDrawer}
          />
        </Block>
        <div className="participants__row">
          {companyRoles
            ?.filter((cr) => cr.role === BusinessRoleEnum.productionExecutor)
            ?.map((cr) => {
              return (
                <ParticipantsBlock
                  key={cr.id}
                  canEdit={ability?.can('update', 'Project.participants.productionExecutor')}
                  role={cr}
                  label={cr.name}
                  users={users}
                  onClick={onOpenDrawer}
                />
              );
            })}
        </div>
      </ParticipantsGroup>

      <ParticipantsGroup
        loading={loading}
        groupTitle={common.t<string>('pages.addCourse.participants.separate')}
        hidden={isEmpty(separateProjectTaskAssignee)}
      >
        <div className="participants__row participants__row-wrap">
          {convertProjectUsersToUsersExtended(separateProjectTaskAssignee)?.map((stu) => {
            return <UserElement size="xl" key={stu.id} user={stu} hasPopover onClick={noop} />;
          })}
        </div>
      </ParticipantsGroup>

      <ParticipantsGroup
        loading={loading}
        groupTitle={common.t<string>('pages.addCourse.participants.support')}
        hidden={!getRoleItem(companyRoles, BusinessRoleEnum.support)}
      >
        <ParticipantsBlock
          canEdit={ability?.can('update', 'Project.participants.support')}
          label={getRoleItem(companyRoles, BusinessRoleEnum.support)?.name}
          role={getRoleItem(companyRoles, BusinessRoleEnum.support)}
          users={users}
          onClick={onOpenDrawer}
        />
      </ParticipantsGroup>
    </div>
  );
}

export default memo(Participants);
