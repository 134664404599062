import React from 'react';

export type UseBooleanOutput = [
  boolean,
  { setValue: (newValue: boolean) => void; toggle: () => void; off: () => void; on: () => void }
];

export default function useBoolean(initialValue: boolean): UseBooleanOutput {
  const [value, setValue] = React.useState(initialValue);

  const updateValue = React.useRef({
    toggle: () => setValue((oldValue) => !oldValue),
    on: () => setValue(true),
    off: () => setValue(false),
    setValue: (newValue: boolean) => setValue(newValue),
  });

  return [value, updateValue.current];
}
