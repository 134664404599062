import React from 'react';

import Button from '../button';
import Tooltip from '../tooltip/Tooltip';
import { ButtonTypesEnum } from '../button/types';

import './styles.scss';

type DrawerFooterProps = {
  loading?: boolean;
  labelSubmit: string;
  onSubmit?: () => void;
  labelCancel?: string;
  onCancel?: () => void;
  disabled?: boolean;
  disabledHint?: string;
  formId?: string;
};

function DrawerFooter({
  loading,
  labelSubmit,
  labelCancel,
  disabled,
  disabledHint,
  formId,
  onSubmit,
  onCancel,
}: DrawerFooterProps) {
  return (
    <div className="ccm-drawer__footer">
      {labelCancel ? (
        <Button size="large" onClick={onCancel} disabled={loading}>
          {labelCancel}
        </Button>
      ) : null}

      <Button
        form={formId}
        size="large"
        onClick={onSubmit}
        htmlType="submit"
        disabled={disabled}
        type={ButtonTypesEnum.primary}
        loading={loading}
        hintText={disabledHint}
      >
        {labelSubmit}
      </Button>
    </div>
  );
}

export default DrawerFooter;
