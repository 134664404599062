import { gql } from '@apollo/client';

import { PIPELINE_VERSION } from './fragments';

export const GET_PIPELINE = gql`
  query ArtefactPipeline($id: Int!) {
    artefact_pipeline(id: $id) {
      id
      last_version {
        ...PipelineVersion
      }
      actual_version {
        ...PipelineVersion
      }
    }
  }
  ${PIPELINE_VERSION}
`;
