import { useQuery } from '@apollo/client';
import { GET_PROJECT_SEPARATE_TASKS } from 'queries/getProjectSeparateTasks';
import { ProjectSeparateTaskItem } from 'types/entities/separate';

import { PlanFilters } from '../../pages/project/subPages/plan/types';

type ProjectSeparateTasksData = {
  project_plan_separate_tasks: ProjectSeparateTaskItem[];
};

function useProjectSeparateTasks(projectId?: string, planFilters?: PlanFilters) {
  const { data, refetch } = useQuery<ProjectSeparateTasksData>(GET_PROJECT_SEPARATE_TASKS, {
    variables: {
      project_id: Number(projectId),
      plan_filters: {
        search_text: planFilters?.searchText,
        all_tasks_state_names: planFilters?.statusName,
        artefact_type_names: planFilters?.artefactTypeNames,
        assignee_ids: planFilters?.assigneeIds,
        deadline_violations_only: planFilters?.missed,
      },
    },
    skip: !projectId,
    fetchPolicy: 'cache-and-network',
  });

  return {
    separateTasks: data?.project_plan_separate_tasks,
    separateTasksRefetch: refetch,
  };
}

export default useProjectSeparateTasks;
