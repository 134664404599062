import React from 'react';

import Can from 'config/Can';

import Tabpane from 'components/tabs/Tabpane';
import { Tabs } from 'components/tabs';

import { UseAddFormOutput } from '../../users/hooks/useAddForm';
import useCommonContext from '../../../hooks/useCommonContext';

import AddEmployeeForm from './AddEmployeeForm';

import './styles.scss';

type AddParticipantsFormProps = {
  addForm?: UseAddFormOutput;
  companyId?: string;
};

function AddParticipantsForm({ companyId, addForm }: AddParticipantsFormProps) {
  const common = useCommonContext();
  return (
    <>
      <Can I="manage" a="Project.participants.selectDrawer.allCompany">
        <Tabs defaultActiveKey="existing" onChange={addForm?.onChangeTab}>
          <Tabpane tab={common.t<string>('pages.users.inExistingOrganization')} key="existing">
            <AddEmployeeForm
              companyId={companyId}
              tab={addForm?.tab}
              form={addForm?.form}
              handleFormChange={addForm?.handleFormChange}
            />
          </Tabpane>
          <Tabpane tab={common.t<string>('pages.users.inNewOrganization')} key="new">
            <AddEmployeeForm
              companyId={companyId}
              tab={addForm?.tab}
              form={addForm?.form}
              handleFormChange={addForm?.handleFormChange}
            />
          </Tabpane>
        </Tabs>
      </Can>
      <Can I="manage" a="Project.participants.selectDrawer.onlyToMyCompany">
        <AddEmployeeForm companyId={companyId} form={addForm?.form} handleFormChange={addForm?.handleFormChange} />
      </Can>
    </>
  );
}

export default AddParticipantsForm;
