import React, { useCallback } from 'react';

import { DefaultOptionType } from 'rc-select/lib/Select';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RadioChangeEvent } from 'antd/lib/radio/interface';

import Checkbox from 'components/checkbox';
import Badge from 'components/badge';
import Block from 'components/block';
import { RadioGroup } from 'components/radio';
import { IRadioOption } from 'components/radio/types';

import './styles.scss';
import ChooseExportVideo from '../../../../../../../components/chooseExportVideo/ChooseExportVideo';
import { CCMBadgeVariant } from '../../../../../../../../components/badge/types';

type ElementsForDownloadProps = {
  name: string;
  label: string;
  count?: number;
  type?: 'radio' | 'select';
  disabled?: boolean;
  checked: boolean;
  valueRadio?: string;
  valueSelect?: DefaultOptionType;
  radioOptions?: Array<IRadioOption>;
  radioDefaultValue?: string;
  selectOptions?: DefaultOptionType[];
  onChangeSectionSelect: (name: string, e: CheckboxChangeEvent) => void;
  onChangeTextType?: (name: string, value: string) => void;
  onChangeVideoResolution?: (value: string, option: any) => void;
};

function ElementsForDownload({
  name,
  label,
  count,
  type = 'radio',
  disabled,
  checked,
  valueRadio,
  valueSelect,
  radioOptions,
  selectOptions,
  onChangeSectionSelect,
  onChangeTextType,
  onChangeVideoResolution,
}: ElementsForDownloadProps) {
  const onChangeCheckbox = useCallback(
    (e: CheckboxChangeEvent) => {
      onChangeSectionSelect(name, e);
    },
    [name, onChangeSectionSelect]
  );

  const onChangeRadio = useCallback(
    (e: RadioChangeEvent) => {
      onChangeTextType?.(name, e.target.value);
    },
    [name, onChangeTextType]
  );

  return (
    <div className="download-drawer-elements">
      <Checkbox disabled={disabled} checked={checked} onChange={onChangeCheckbox}>
        {label}
      </Checkbox>
      <Block empty hidden={!count}>
        <Badge variant={CCMBadgeVariant.light} count={count} />
      </Block>
      <Block className="download-drawer-elements__radio" hidden={type === 'select'}>
        <RadioGroup
          disabled={disabled}
          name={name}
          size="large"
          options={radioOptions}
          variant="button"
          value={disabled ? '' : valueRadio}
          onChange={onChangeRadio}
        />
      </Block>
      <Block className="download-drawer-elements__select" hidden={type === 'radio'}>
        <ChooseExportVideo
          disabled={disabled}
          options={selectOptions}
          value={valueSelect}
          onChange={onChangeVideoResolution}
        />
      </Block>
    </div>
  );
}

export default ElementsForDownload;
