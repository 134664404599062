import { IAction } from 'types/store';

import IProjectsState from './interfaces';
import ProjectsTypes from './types';

const initialState: IProjectsState = {
  projects: null,
  count: 0,
  next: null,
  previous: null,
  companyStates: [],
};

export default function (state = initialState, action: IAction<ProjectsTypes>) {
  switch (action.type) {
    case ProjectsTypes.SET_PROJECTS_DATA:
      return {
        ...state,
        projects: action.payload,
      };
    case ProjectsTypes.SET_COMPANY_STATES_DATA:
      return {
        ...state,
        companyStates: action.payload,
      };
    default:
      return state;
  }
}
