import React, { ReactNode } from 'react';

import { cx } from 'utils';

import './styles.scss';
import Skeleton from 'antd/es/skeleton';

type ParticipantsGroupProps = {
  loading?: boolean;
  className?: string;
  groupTitle: string;
  hidden?: boolean;
  children: ReactNode;
};

function ParticipantsGroup({ loading, className, groupTitle, hidden, children }: ParticipantsGroupProps) {
  if (hidden) {
    return null;
  }

  const classNames = cx('participants-group', className);

  return (
    <div className={classNames}>
      <div className="participants-group__title">{groupTitle}</div>
      <div className="participants-group__divider"></div>
      <div className="participants-group__content">
        {loading ? (
          <div className="participants-group--skeleton">
            <Skeleton.Avatar active size={60} />
            <Skeleton.Input active size="large" />
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
}

export default ParticipantsGroup;
