import { useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { GET_ARTEFACT_DELAYS_ITERATION_DEADLINE } from 'queries/getArtefactDelays';

import {
  ArtefactDelaysProdDeadlineDataItem,
  ComposeArtefactDelaysProdDeadline,
} from './useLazyArtefactDelaysProductionDeadline';

type ArtefactDelaysProdDeadlineDataItemNode = {
  node: ArtefactDelaysProdDeadlineDataItem;
};

function useLazyArtefactDelaysIterationDeadline(
  projectId: number,
  userId: number,
  isCurrent?: boolean,
  fetchPolicy?: WatchQueryFetchPolicy
) {
  const [getArtefactDelaysIterationDeadline, { data, loading }] = useLazyQuery(GET_ARTEFACT_DELAYS_ITERATION_DEADLINE, {
    variables: {
      project_id: projectId,
      user_id: userId,
      is_current: isCurrent,
    },
    fetchPolicy: fetchPolicy || 'network-only',
  });

  const list = useMemo(() => {
    const temp = data?.artefact_delays_iteration_deadline?.edges?.map(
      (el: ArtefactDelaysProdDeadlineDataItemNode) => el?.node
    );
    return temp?.map((el: ArtefactDelaysProdDeadlineDataItem) => {
      return {
        id: el.project_task_assignee?.project_task?.cached_linked_artefact?.id,
        artefact: {
          ...el?.project_task_assignee?.project_task?.cached_linked_artefact,
        },
        separateTask: el?.project_task_assignee?.project_task?.separate_task || null,
      };
    });
  }, [data]);

  return useMemo(() => {
    return {
      getArtefactDelaysIterationDeadline,
      delayIterationList: list as ComposeArtefactDelaysProdDeadline[],
      delayIterationLoading: loading,
    };
  }, [getArtefactDelaysIterationDeadline, list, loading]);
}

export default useLazyArtefactDelaysIterationDeadline;
