/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ExportFile = {
    resolution?: ExportFile.resolution;
    file_extension: ExportFile.file_extension;
};

export namespace ExportFile {

    export enum resolution {
        '_144' = 144,
        '_240' = 240,
        '_360' = 360,
        '_480' = 480,
        '_720' = 720,
        '_1080' = 1080,
        '_1440' = 1440,
        '_2160' = 2160,
        '_4320' = 4320,
    }

    export enum file_extension {
        MP4 = 'mp4',
        DOCX = 'docx',
        PDF = 'pdf',
    }


}
