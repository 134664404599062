import React, { ChangeEvent, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import Can, { AbilityContext } from 'config/Can';
import Layout from 'antd/lib/layout';
import { UserExtended } from 'types/entities';
import { convertRoles, cx } from 'utils';
import { buildAbilityFor } from 'config/ability';
import { isEmpty } from 'lodash';

import { ButtonTypesEnum } from 'components/button/types';
import Button from 'components/button';
import LoadMore from 'components/loadMore/LoadMore';
import Block from 'components/block';
import LoadingOverlay from 'components/loadingOverlay';
import EmptyData from 'components/emptyData/EmptyData';

import useBoolean from 'hooks/useBoolean';
import useCommonContext from 'hooks/useCommonContext';
import useTextEmpty from 'hooks/useTextEmpty';

import Header from '../components/header';
import usePageFilters from '../hooks/usePageFilters';

import UsersTable from './usersTable';
import Toolbar from './toolbar';
import AddEmployeeDrawer from './addEmployeeDrawer';
import ViewEmployeeDrawer from './viewEmployeeDrawer';
import useUsersData from './hooks/useUsersData';
import UsersFilterSkeleton from './skeletons/UsersFilterSkeleton';
import UsersPageSkeleton from './skeletons/Skeleton';

import './styles.scss';

function UsersPage() {
  const [open, onOpen] = useBoolean(false);
  const [openUser, onOpenUser] = useBoolean(false);
  const [user, setUser] = useState<UserExtended | null>(null);

  const { textEmptyTask } = useTextEmpty();
  const filters = usePageFilters();
  const common = useCommonContext();
  const ability = buildAbilityFor(convertRoles(common.businessRoles));

  const { hasNextPage, hasFirstLoad, loading, users, onLoadMore, onFetchUser, refetchUsers } = useUsersData({
    filter: {
      companyIds: filters.companies,
      roleIds: filters.roles,
      search: filters.text,
    },
    user,
    setUser,
  });

  const onOpenUserDrawer = (record: UserExtended) => {
    setUser(record);
    onOpenUser.on();
  };

  const onCloseUserDrawer = () => {
    onOpenUser.off();
    setUser(null);
  };

  const classNames = cx('page-users__wrapper', {
    mb_16: !hasNextPage,
  });

  return (
    <AbilityContext.Provider value={ability}>
      <Layout className="page-base page-users">
        <Block empty hidden={hasFirstLoad}>
          <UsersFilterSkeleton />
        </Block>

        <Block empty hidden={!hasFirstLoad}>
          <Header title={common.t<string>('pages.users.title')}>
            <Can I="manage" a="Users.add">
              <Button size="large" type={ButtonTypesEnum.primary} onClick={onOpen.on}>
                <PlusOutlined /> {common.t<string>('common.add')}
              </Button>
            </Can>
          </Header>
          <Toolbar />
        </Block>

        <Block empty hidden={hasFirstLoad}>
          <UsersPageSkeleton />
        </Block>

        <Block className={classNames} hidden={!hasFirstLoad}>
          {isEmpty(users) ? (
            <EmptyData title={textEmptyTask} />
          ) : (
            <>
              <UsersTable data={users} onSetUser={onOpenUserDrawer} hasFirstLoad={hasFirstLoad} />
              <LoadMore hasNextPage={hasNextPage} onFetch={onLoadMore} />
            </>
          )}
        </Block>
        <LoadingOverlay show={loading && hasFirstLoad} text={common.t<string>('common.loadingData')} />
        <AddEmployeeDrawer
          visible={open}
          companyId={common?.company?.id.toString()}
          onClose={onOpen.off}
          refetchUsers={refetchUsers}
        />
        <ViewEmployeeDrawer visible={openUser} user={user} onClose={onCloseUserDrawer} onFetchUser={onFetchUser} />
      </Layout>
    </AbilityContext.Provider>
  );
}

export default UsersPage;
