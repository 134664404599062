import Table from '@editorjs/table';
import List from '@editorjs/list';
import Image from '@editorjs/image';
import Header from '@editorjs/header';

import CommentTool from './comment/commentTool';
import { uploadImage } from './fetch';

export const getEditorTools = () => {
  return {
    // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
    commentTool: CommentTool,
    //linkTool: LinkTool,
    image: {
      class: Image,
      inlineToolbar: true,
      config: {
        uploader: {
          uploadByFile(file: any) {
            return uploadImage(file);
          },
        },
      },
    },
    table: { class: Table, inlineToolbar: true },
    list: { class: List, inlineToolbar: true },
    header: { class: Header, inlineToolbar: true },
  };
};
