import { RcFile } from 'antd/lib/upload/interface';

const videoMimeType: string[] = [
  'video/x-flv',
  'video/mp4',
  'video/3gpp',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-ms-wmv',
  'video/avi',
  'video/mpeg',
  'video/ogg',
  'video/webm',
  'video/x-flv',
];

type CheckMimeTypePresentationResult = {
  isAllowed?: boolean;
};

export const checkMimeTypeVideo = (data?: RcFile): CheckMimeTypePresentationResult => {
  return {
    isAllowed: data?.type ? videoMimeType.includes(data?.type) : false,
  };
};
