import React, { memo } from 'react';

import { cx } from 'utils';

import Avatar from 'components/avatar';
import Tag from 'components/tag';
import Block from 'components/block';

import useCommonContext from 'hooks/useCommonContext';

import { ProjectUserItem } from '../../../../types';

import './styles.scss';

type ProblemsUserInfoProps = {
  className?: string;
  user?: ProjectUserItem;
};

function ProblemsUserInfo({ className, user }: ProblemsUserInfoProps) {
  const common = useCommonContext();
  const classNames = cx('info-drawer-problems-user', className);

  const firstRole = user?.roles[0];
  const roleLength = user?.roles.length || 0;

  if (!user) {
    return (
      <div className={classNames}>
        <span className="info-drawer-problems-user__text">
          {common.t<string>('pages.courses.drawer.assigneeNotSelected')}
        </span>
      </div>
    );
  }

  return (
    <div className={classNames}>
      <Avatar size={32} src={user?.company_user.user.icon_image}>
        {user?.company_user?.user?.name?.[0]}
      </Avatar>
      <span className="info-drawer-problems-user__name">{user?.company_user?.user?.name}</span>
      <Block hidden={!firstRole} className="info-drawer-problems-user__roles">
        <Tag>{firstRole?.role.name}</Tag>
        {roleLength > 1 ? <Tag>{`+${roleLength - 1}`}</Tag> : null}
      </Block>
    </div>
  );
}

export default memo(ProblemsUserInfo);
