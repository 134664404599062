import React, { memo, SyntheticEvent } from 'react';

import { HistoryOutlined } from '@ant-design/icons';

import Popover from 'components/popover/Popover';
import Button from 'components/button/Button';

import useArtefactDeadlinesChanged from 'hooks/queries/useLazyArtefactDeadlinesChanged';
import useCommonContext from 'hooks/useCommonContext';

import { ButtonTypesEnum } from '../../button/types';
import Tooltip from '../../tooltip';

import ArtefactDeadlinesChangedContent from './ArtefactDeadlinesChangedContent';

type ArtefactDeadlinesChangedProps = {
  projectId?: string;
  artefactId?: string;
  separateTaskId?: string;
  deadlineType: 'first_iteration_deadline' | 'artefact_production_deadline';
  isSeparate: boolean;
};

function ArtefactDeadlinesChanged({
  projectId,
  artefactId,
  separateTaskId,
  deadlineType,
  isSeparate,
}: ArtefactDeadlinesChangedProps) {
  const common = useCommonContext();
  const { getArtefactDeadlinesChanged, timelineData, artefactDeadlineLoading } = useArtefactDeadlinesChanged(
    Number(projectId),
    Number(artefactId),
    Number(separateTaskId),
    deadlineType
  );

  const onHandleClick = (e: SyntheticEvent) => {
    getArtefactDeadlinesChanged();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  return (
    <div className="ccm-popover-history" onClick={onHandleClick}>
      <Tooltip title={common.t<string>('pages.dashboard.tooltips.lastChanged')}>
        <Popover
          trigger="click"
          title={common.t<string>('pages.courses.table.historyDeadlineChange')}
          content={
            <ArtefactDeadlinesChangedContent
              isSeparate={isSeparate}
              data={timelineData}
              loading={artefactDeadlineLoading}
            />
          }
        >
          <Button size="small" type={ButtonTypesEnum.link} icon={<HistoryOutlined />} />
        </Popover>
      </Tooltip>
    </div>
  );
}

export default memo(ArtefactDeadlinesChanged);
