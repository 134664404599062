import React, { useCallback, useEffect, useRef, useState } from 'react';

import uuid from 'react-uuid';
import { CommentOutlined } from '@ant-design/icons';
import { cx } from 'utils';

import Button from 'components/button';
import { ButtonTypesEnum } from 'components/button/types';

import useBoolean from 'hooks/useBoolean';

import './styles.scss';

type InlineToolProps = {
  editorReady: boolean;
  readOnly: boolean;
  editorCore: any;
  onChangeEditor: () => void;
};

const events = ['mouseup', 'dblclick'];

type SelectedType = {
  text: string | undefined;
  range: Range | undefined;
  position: DOMRect | undefined;
  node: Node | null;
};

const EDITOR_HOLDER_ID = 'editor-js';
const NEW_TAG = 'comment';
const CSS = 'mark-comment';

function InlineTool({ editorReady, readOnly, editorCore, onChangeEditor }: InlineToolProps) {
  const container = useRef<HTMLElement | null>(null);
  const toolEl = useRef<HTMLDivElement | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [selected, setSelected] = useState<SelectedType | null>(null);
  const [disable, setDisable] = useBoolean(false);

  useEffect(() => {
    if (editorReady) {
      container.current = document.getElementById(EDITOR_HOLDER_ID);
    }
  }, [editorReady]);

  const mouseEvent = useCallback(() => {
    if (editorReady && readOnly) {
      events.forEach((e) =>
        container.current?.addEventListener(e, () => {
          const selection = window?.getSelection();
          if (selection?.isCollapsed === false) {
            const text = selection?.toString();
            const range = selection?.getRangeAt(0);
            const position = range?.getBoundingClientRect();
            const node = selection?.anchorNode;

            if (!!node?.parentElement?.closest('comment')) {
              setDisable.on();
            } else {
              setDisable.off();
            }
            setSelected({ text, range, position, node });
            setShow(true);
          } else {
            setSelected(null);
            setShow(false);
            setDisable.off();
          }
        })
      );
    }
  }, [editorReady, readOnly, setDisable]);

  useEffect(() => {
    if (editorReady) {
      mouseEvent();
    }
  }, [editorReady, mouseEvent]);

  const onCreateComment = () => {
    if (selected) {
      const selectedText = selected?.range?.extractContents();

      // Create MARK element
      const mark = document.createElement(NEW_TAG);
      const newElId = uuid();

      if (selectedText) {
        mark.appendChild(selectedText);
        mark.classList.add(CSS);
        mark.setAttribute('id', newElId);
        mark.setAttribute('data-comment', newElId);
        selected?.range?.insertNode(mark);
        setDisable.on();
        onChangeEditor();
        setShow(false);
      }
    }
  };

  const posBottom = selected?.position?.bottom || 0;
  const posLeft = selected?.position?.left || 0;
  const posWidth = selected?.position?.width || 0;
  const toolWidth = toolEl.current?.getBoundingClientRect().width || 0;

  const styles = {
    top: posBottom + 5,
    left: posLeft + posWidth - toolWidth,
  };

  const classNames = cx('ccm-editor-tool', {
    'ccm-editor-tool--showed': show && readOnly,
  });

  return (
    <div ref={toolEl} className={classNames} style={styles}>
      <div className="ccm-editor-tool__wrapper">
        <div className="ccm-editor-tool__actions">
          <Button disabled={disable} type={ButtonTypesEnum.text} icon={<CommentOutlined />} onClick={onCreateComment} />
        </div>
      </div>
    </div>
  );
}

export default InlineTool;
