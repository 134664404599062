import { Map } from '../types/entities';

export const taskEntity: Map = {
  scenario: 'scenario',
  sourceFile: 'source-file',
  // draftPresentation: 'draft-presentation',
  // draftEditing: 'draft-editing',
  finalPresentation: 'final-presentation',
  finalEditing: 'video_shooting',
  //
  universal: 'universal',
  text: 'text',
  video_shooting: 'video_shooting', // <--  companyArtefactTypeMap
  draft_video: 'draft_video', // <--  companyArtefactTypeMap
  original_video: 'original_video', // <--  companyArtefactTypeMap
  draft_presentation: 'draft_presentation', // <--  companyArtefactTypeMap
  production_presentation: 'production_presentation', // <--  companyArtefactTypeMap
  separate: 'separate',
};

export const PATH_NAMES = {
  auth: {
    login: '/login',
    signup: '/signup',
    resetPassword: '/reset-password',
    linkSent: '/link-sent',
  },
  management: {
    base: 'internal-management-settings',
    pipeline: 'pipeline',
    pipelineEdit: 'pipeline/edit/:itemId',
    status: 'status-list',
    statusEdit: 'status-list/edit/:listId',
    artTypes: 'artefact-types',
    loTypes: 'learning-object-types',
    globalSettings: 'global-settings',
  },
  account: {
    base: '/user-account',
  },
  dashboard: {
    base: '/dashboard',
  },
  tasks: {
    base: '/tasks',
  },
  users: {
    base: '/users',
  },
  task: {
    base: '/learning_object/:learningObjectId/task/:taskId/iteration/:iterationId/artefact/:artefactId',
    baseExtended: '/learning_object/:learningObjectId/task/:taskId/iteration/:iterationId/artefact/:artefactId/:entity',
    baseExtendedIssue:
      '/learning_object/:learningObjectId/task/:taskId/iteration/:iterationId/artefact/:artefactId/:entity#:issue',
    entity: taskEntity,
  },
  separateTask: {
    base: '/separate_task/:taskId',
  },
  courses: {
    base: '/courses',
  },
  addCourse: {
    base: '/add-course',
  },
  project: {
    base: '/project/:id',
    baseExtended: '/project/:id/:entity',
    entity: {
      about: 'about',
      participants: 'participants',
      results: 'results',
      plan: {
        base: 'plan',
        tree: 'plan/tree',
        list: 'plan/list',
      },
      content: 'content',
      create: 'create-task',
    },
  },
  topics: {
    main: '/topics',
  },
  forms: {
    request: '/request-form/:id',
    requestPrint: '/application-document/:id',
  },
  modals: {
    topics: '/modal-topics',
    result: '/result',
  },
  organization: {
    main: '/organization',
  },
  ui: {
    main: '/ui',
  },
  errors: {
    base: '/error',
    error403: '/403',
    error404: '/404',
    error405: '/405',
    error500: '/500',
    error502: '/502',
    error503: '/503',
  },
};
