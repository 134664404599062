import React, { memo } from 'react';

import { UserExtended } from 'types/entities';

import Avatar from 'components/avatar/Avatar';
import StatusLabelFlat from 'components/statusLabelFlat/StatusLabelFlat';
import ContentBlock from 'components/contentBlock/ContentBlock';

import useCommonContext from 'hooks/useCommonContext';

import { getCurrentTime, getCurrentTimeOffset } from '../../../utils';

import RoleProductionView from './RoleProductionView';

type ViewContentProps = {
  user: UserExtended | null;
};

function ViewContent({ user }: ViewContentProps) {
  const common = useCommonContext();
  return (
    <div className="view-employee">
      <div className="view-employee__info">
        <div className="view-employee__photo">
          <Avatar size={200} src={user?.user?.icon_image}>
            {user?.user.name?.[0]}
          </Avatar>
        </div>
        <span className="view-employee__name">{user?.user.name}</span>
        <div className="view-employee__status">
          <StatusLabelFlat
            type={user?.user.state}
            name={common.t(`statuses.${user?.user.state?.toLocaleLowerCase()}`)}
          />
          <span className="view-employee__status-time">
            {user?.user?.timezone &&
              `${getCurrentTime(user?.user?.timezone)} (UTC ${getCurrentTimeOffset(user?.user?.timezone)})`}
          </span>
        </div>
      </div>
      <ContentBlock
        small
        className="mb_24"
        label={common.t<string>('pages.users.organisation')}
        text={user?.company?.name}
      />
      <ContentBlock
        small
        hasCopy
        className="mb_24"
        label={common.t<string>('form.field.email.label')}
        text={user?.user.email}
      />
      <ContentBlock
        label={common.t<string>('pages.users.roleProduction')}
        // hint={<RoleProductionPopover />}
        content={<RoleProductionView data={user?.business_roles || []} />}
      />
    </div>
  );
}

export default memo(ViewContent);
