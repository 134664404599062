import React, { memo } from 'react';

import { Skeleton } from 'antd';

import './styles.scss';

const styles = {
  title: { width: '100%', height: '16px', maxWidth: '269px' },
  block: { width: '100%', height: '16px', maxWidth: '800px' },
};

function SkeletonEditor() {
  return (
    <div className="task-page-skeleton-editor">
      <div className="task-page-skeleton-editor__block">
        <Skeleton paragraph={false} avatar={false} active style={styles.title} />
        <Skeleton paragraph={false} avatar={false} active style={styles.block} />
        <Skeleton paragraph={false} avatar={false} active style={styles.block} />
        <Skeleton paragraph={false} avatar={false} active style={styles.block} />
      </div>
    </div>
  );
}

export default memo(SkeletonEditor);
