import { Notification } from 'types/entities';
import { notification } from 'antd';

import i18n from './../i18n';

const notificationService = {
  show(notificationProps: Notification): void {
    const { type, description, title, errorCode, values } = notificationProps;
    const errorTitle = i18n?.t(`serverErrors.${errorCode}.title`, { values }) || title;
    const errorDescription = i18n?.t(`serverErrors.${errorCode}.description`, { values }) || description;
    notification[type]({
      message: errorTitle,
      description: errorDescription,
    });
  },
};

export default notificationService;
