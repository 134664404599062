import React, { SyntheticEvent, useCallback } from 'react';

import { ArrowRightOutlined, FieldNumberOutlined, MessageFilled } from '@ant-design/icons';
import { generatePath, Link } from 'react-router-dom';
import { getFormatDateByStr, getUrlToTask } from 'utils';
import { DashboardTaskItem } from 'pages/dashboard/types';
import { PATH_NAMES } from 'router/constants';
import TruncateMarkup from 'react-truncate-markup';

import StatusLabelFlat from 'components/statusLabelFlat/StatusLabelFlat';
import AvatarGroup from 'components/avatarGroup';
import { ProjectStatusChangedPopup } from 'components/historyPopup';
import TagDelayWithFlag from 'components/tagDelayWithFlag';
import Block from 'components/block';
import Tooltip from 'components/tooltip/Tooltip';
import ArtefactDeadlinesChanged from 'components/historyPopup/artefactDeadlinesChanged/ArtefactDeadlinesChanged';

import { getIconByUrl } from 'utils/icons';

import useGlobalActionsContext from 'hooks/useGlobalActionsContext';
import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

type TaskItemProps = {
  data: DashboardTaskItem;
};

function TaskItem({ data }: TaskItemProps) {
  const common = useCommonContext();
  const { data: actionsData, setData } = useGlobalActionsContext();

  const isSeparateTask = !!data.separate_task;

  const iterationDeadline = isSeparateTask
    ? data?.separate_task?.deadline_first_iteration_review || ''
    : data?.cached_linked_artefact?.first_iteration_deadline || '';
  const iterationDeadlineDelay = isSeparateTask
    ? data?.separate_task?.first_iteration_delay_duration_summary_days || 0
    : data?.cached_linked_artefact?.first_iteration_deadline_delay || 0;
  const iterationDeadlineChanged = isSeparateTask
    ? data.separate_task?.first_iteration_deadline_changed
    : data.cached_linked_artefact?.first_iteration_deadline_changed;

  const productionDeadline = isSeparateTask
    ? data?.separate_task?.deadline_production || ''
    : data?.cached_linked_artefact?.production_deadline || '';
  const productionDeadlineDelay = isSeparateTask
    ? data?.separate_task?.delay_duration_summary_days || 0
    : data?.cached_linked_artefact?.production_deadline_delay || 0;

  const productionDeadlineChanged = isSeparateTask
    ? data.separate_task?.production_deadline_changed
    : data.cached_linked_artefact.production_deadline_changed;

  const lastStateIsChanged = isSeparateTask
    ? data.last_state_is_changed
    : data?.artefact_production_sequence?.project_task?.last_state_is_changed;

  const lastStateUpdatedAt = isSeparateTask
    ? data.last_state_updated_at
    : data?.artefact_production_sequence?.project_task?.last_state_updated_at;

  const lastStateType = isSeparateTask
    ? data?.last_state?.state_type
    : data?.artefact_production_sequence?.project_task?.last_state?.state_type;
  const lastStateTypeName = isSeparateTask
    ? data?.last_state?.name
    : data?.artefact_production_sequence?.project_task?.last_state?.name;

  const onClickTasks = useCallback(() => {
    const pathSeparate = generatePath(PATH_NAMES.separateTask.base, {
      taskId: data?.separate_task?.project_task?.id || '',
    });

    const path = getUrlToTask(
      data.artefact_production_sequence?.project_learning_object?.id,
      data.artefact_production_sequence?.project_task?.id,
      data.cached_linked_artefact?.artefact_iteration_count,
      data.cached_linked_artefact?.pk,
      data.cached_linked_artefact?.artefact_type?.type,
      data.cached_linked_artefact?.artefact_versions[0]?.file.file_type
    );
    setData({ ...actionsData, prevUrl: window.location.pathname });
    window.open(isSeparateTask ? pathSeparate : path, '_blank');
    //common.navigate(isSeparateTask ? pathSeparate : path);
  }, [actionsData, data, isSeparateTask, setData]);

  const convertedUsers = data?.current_assignees?.map((a) => {
    return a.project_user;
  });

  const onHandelLinkClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  return (
    <div role="link" className="dashboard-task-item" onClick={onClickTasks}>
      <div className="dashboard-task-item__top mb_24">
        <div className="dashboard-task-item__top__left">
          <div className="dashboard-task-item__number mr_24">
            {data?.artefact_production_sequence?.project_learning_object?.parent?.human_readable_number}
          </div>
          <div className="dashboard-task-item__img-type mr_24">
            {isSeparateTask ? null : (
              <Tooltip
                placement="top"
                title={data?.artefact_production_sequence?.project_learning_object?.learning_object_type?.name}
              >
                {getIconByUrl(
                  data?.artefact_production_sequence?.project_learning_object?.learning_object_type?.icon_image
                )}
              </Tooltip>
            )}
          </div>
          <div className="dashboard-task-item__name mr_24">
            <TruncateMarkup lines={1}>
              <span className="dashboard-task-item__name-value">
                {isSeparateTask ? data?.name : data?.artefact_production_sequence?.project_learning_object?.name}
              </span>
            </TruncateMarkup>
          </div>
        </div>
        <div className="dashboard-task-item__top__right">
          <Link
            to={`/project/${data.project.id}/plan/tree`}
            className="dashboard-task-item__course-link"
            onClick={onHandelLinkClick}
          >
            {data.project.name} <ArrowRightOutlined />
          </Link>
        </div>
      </div>
      <div className="dashboard-task-item__bottom">
        <div className="dashboard-task-item__artefact-type">
          <div className="dashboard-task-item__artefact-type__icon">
            {isSeparateTask
              ? getIconByUrl(data?.separate_task?.task_type?.icon_image)
              : getIconByUrl(data?.cached_linked_artefact?.artefact_type?.icon_image)}
          </div>
          <Tooltip
            className="flex-row flex-align-items--center"
            title={common.t<string>('pages.dashboard.tooltips.taskType')}
          >
            <div>
              <TruncateMarkup lines={1}>
                <span className="dashboard-task-item__artefact-type__name">
                  {isSeparateTask
                    ? data?.separate_task?.task_type?.name
                    : data?.cached_linked_artefact?.artefact_type?.name}
                </span>
              </TruncateMarkup>
            </div>
          </Tooltip>
          <div className="dashboard-task-item__iteration ml_16">
            <Tooltip
              className="flex-row flex-align-items--center"
              title={common.t<string>('pages.dashboard.tooltips.iteration')}
            >
              <FieldNumberOutlined className="mr_4" style={{ color: '#848484' }} />
              {isSeparateTask
                ? data?.separate_task?.cached_artefact_iteration_count
                : data?.cached_linked_artefact?.artefact_iteration_count}
            </Tooltip>
          </div>
        </div>

        <div className="dashboard-task-item__users">
          <AvatarGroup users={convertedUsers} hasPopover />
          <div className="dashboard-task-item__comments ml_16">
            <Tooltip title={common.t<string>('pages.dashboard.tooltips.comments')}>
              <Block empty hidden={!data.cached_linked_artefact?.messages_amount}>
                <MessageFilled className="mr_4" /> {data.cached_linked_artefact?.messages_amount}
              </Block>
            </Tooltip>
          </div>
        </div>
        <div className="dashboard-task-item__deadlines">
          <TagDelayWithFlag
            className="mr_8"
            text={iterationDeadline}
            delay={iterationDeadlineDelay}
            tooltip={`${common.t<string>('pages.dashboard.tooltips.iterationDeadline')} ${iterationDeadlineDelay}`}
            extraText={
              iterationDeadlineDelay > 0 ? `${iterationDeadlineDelay}${common.t<string>('common.shortDay')}` : null
            }
          />
          {iterationDeadlineChanged ? (
            <ArtefactDeadlinesChanged
              isSeparate={isSeparateTask}
              separateTaskId={isSeparateTask ? data?.pk : undefined}
              projectId={isSeparateTask ? undefined : data?.project?.id}
              artefactId={isSeparateTask ? undefined : data?.cached_linked_artefact?.id}
              deadlineType="first_iteration_deadline"
            />
          ) : null}
        </div>
        <div className="dashboard-task-item__deadlines">
          <TagDelayWithFlag
            flagFilled
            className="mr_8"
            text={productionDeadline}
            delay={productionDeadlineDelay}
            tooltip={`${common.t<string>('pages.dashboard.tooltips.finalDeadline')} ${productionDeadlineDelay}`}
            extraText={
              productionDeadlineDelay > 0 ? `${productionDeadlineDelay}${common.t<string>('common.shortDay')}` : null
            }
          />
          {productionDeadlineChanged ? (
            <ArtefactDeadlinesChanged
              isSeparate={isSeparateTask}
              separateTaskId={isSeparateTask ? data?.pk : undefined}
              projectId={isSeparateTask ? undefined : data?.project?.id}
              artefactId={isSeparateTask ? undefined : data?.cached_linked_artefact?.id}
              deadlineType="artefact_production_deadline"
            />
          ) : null}
        </div>
        <div className="dashboard-task-item__date">
          {lastStateIsChanged ? (
            <Tooltip
              className="flex-row flex-align-items--center"
              title={common.t<string>('pages.dashboard.tooltips.lastChanged')}
            >
              <ProjectStatusChangedPopup
                text={getFormatDateByStr(lastStateUpdatedAt, 'DD MMM')}
                projectId={data?.project?.id}
                isSeparate={isSeparateTask}
                projectTaskId={
                  isSeparateTask
                    ? data?.separate_task?.project_task?.id
                    : data?.artefact_production_sequence?.project_task?.id
                }
              />
            </Tooltip>
          ) : null}
        </div>
        <div className="dashboard-task-item__status">
          <StatusLabelFlat type={lastStateType} name={lastStateTypeName} />
        </div>
      </div>
    </div>
  );
}

export default TaskItem;
