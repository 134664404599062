import { ReactNode } from 'react';

import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';

import { GET_PROJECT_GROUPS } from '../../queries/getProjectGroups';
import { Company } from '../../types/entities';
import { arrToSelect } from '../../utils';

export type ProjectGroup = {
  id: string;
  is_active: boolean;
  name: string;
  company: Company;
};

export interface ProjectGroupItemEx extends ProjectGroup {
  label: ReactNode;
  value: string | number | null;
}

export type ProjectGroups = {
  project_groups: ProjectGroup[];
};

function useProjectGroups(fetchPolicy?: WatchQueryFetchPolicy) {
  const { data } = useQuery<ProjectGroups>(GET_PROJECT_GROUPS, {
    fetchPolicy: fetchPolicy || 'network-only',
  });

  return {
    projectGroupsForSelect: arrToSelect<ProjectGroup, ProjectGroupItemEx>(data?.project_groups),
  };
}

export default useProjectGroups;
