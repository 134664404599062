import React from 'react';

import { DndProvider, getBackendOptions, MultiBackend, NodeModel, Tree } from '@minoru/react-dnd-treeview';

import { CustomData } from '../projectContentTree/types';

import ProjectPlanTreeItem from './components/treeItem';
import { PlanTreeProps } from './types';

function ProjectPlanTree({
  anchorTree,
  treeData,
  isEdit,
  selected,
  onToggleCheckbox,
  onHandleDrop,
  onToggleTask,
}: PlanTreeProps) {
  return (
    <div className="ccm-tree-container">
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <Tree
          ref={anchorTree}
          tree={treeData}
          rootId={0}
          initialOpen={true}
          render={(node: NodeModel<CustomData>, params) => (
            <ProjectPlanTreeItem
              node={node}
              params={params}
              isEdit={isEdit}
              selected={selected}
              onToggleTask={onToggleTask}
              onToggleCheckbox={onToggleCheckbox}
            />
          )}
          onDrop={onHandleDrop}
          sort={false}
          canDrag={() => false}
        />
      </DndProvider>
    </div>
  );
}

export default ProjectPlanTree;
