import React from 'react';

import { cx } from 'utils';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'config/Can';

import useCommonContext from 'hooks/useCommonContext';
import { DocumentDiscussionEx } from 'hooks/queries/useDocumentDiscussions';

import CommentPane from '../commentPane';
import useDiscussionControl from '../../pages/task/subPages/text/hooks/useDiscussionControl';
import { StateType } from '../../types/entities';

import DiscussionHead from './DiscussionHead';

import './styles.scss';

type DiscussionBlockProps = {
  data: DocumentDiscussionEx;
  className?: string;
  projectId: string | null;
  discussionsRefetch: () => Promise<any>;
  onRemoveMarkerById: (markerId: string) => void;
};

function DiscussionBlock({ data, projectId, discussionsRefetch, onRemoveMarkerById }: DiscussionBlockProps) {
  const common = useCommonContext();
  const discussionClosed = data.project_task.last_state.state_type === StateType.DONE;

  const {
    anchor,
    hovered,
    selected,
    editDis,
    editComment,
    editCommentId,
    onEditDis,
    onClickCard,
    onCancelEditDis,
    onClickLink,
    onAddComment,
    onCloseDiscussion,
    onResumeDiscussion,
    onCancelEditComment,
    onSaveEditDis,
    onStartEditComment,
    onSaveEditComment,
    onOpenRemoveDiscussionModal,
    onOpenRemoveCommentModal,
  } = useDiscussionControl({ data, projectId, discussionsRefetch, onRemoveMarkerById });

  const ability = useAbility(AbilityContext);
  const canIChangeState =
    (hovered || selected) && (ability.can('update', 'Task.discussion.state') || data.isMyDiscussion);
  const canIEdit = (hovered || selected) && data.isMyDiscussion;
  const canIRemove = (hovered || selected) && data.isMyDiscussion;
  const showLink = (hovered || selected) && !data.isMyDiscussion;

  const classNames = cx('discussion', {
    'discussion--selected': selected,
  });

  return (
    <div id={data.id} ref={anchor} className={classNames} onClick={onClickCard}>
      <div className="discussion__container">
        <DiscussionHead
          hasChangeStatus={canIChangeState}
          hasMore={canIEdit}
          showLink={showLink}
          canIEdit={!discussionClosed}
          canIRemove={canIRemove}
          user={data.creator}
          createdAt={data.created_at}
          status={data.project_task.last_state.state_type}
          onCloseDiscussion={onCloseDiscussion}
          onResumeDiscussion={onResumeDiscussion}
          onEdit={onEditDis.on}
          onRemove={onOpenRemoveDiscussionModal}
          onCopyLink={onClickLink}
        />

        {editDis && selected ? (
          <CommentPane
            className="mb_16"
            showActions
            labelSubmit={common.t<string>('common.save')}
            initText={data?.project_task?.description}
            onSubmit={onSaveEditDis}
            onCancel={onCancelEditDis}
          />
        ) : (
          <span className="discussion__message">{data?.project_task?.description}</span>
        )}

        {data.discussion_comments?.map((comment) => {
          return (
            <div className="discussion-comment" key={comment.id}>
              <DiscussionHead
                hasMore={canIEdit}
                canIRemove={canIRemove}
                isHideLinkAction
                user={comment.project_user.company_user.user}
                createdAt={comment.updated_at}
                commentId={comment.id}
                onEditComment={onStartEditComment}
                onRemoveComment={onOpenRemoveCommentModal}
              />
              {editComment && comment.id === editCommentId && selected ? (
                <CommentPane
                  className="mb_16"
                  showActions
                  labelSubmit={common.t<string>('common.save')}
                  initText={comment.message}
                  onSubmit={onSaveEditComment}
                  onCancel={onCancelEditComment}
                />
              ) : (
                <span className="discussion__message">{comment.message}</span>
              )}
            </div>
          );
        })}

        {selected && !discussionClosed ? <CommentPane onSubmit={onAddComment} /> : null}
      </div>
    </div>
  );
}

export default DiscussionBlock;
