import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { GET_DASHBOARD_FILTERS } from 'queries/getDashboardFilters';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { uniqBy } from 'lodash';

import { DropdownCheckboxItem } from 'components/filters/types';

import { CompanyStateItem } from './useCompanyState';
import { CompanyLearningObjectType } from './useCompanyLearningObjectTypes';

type CompanyArtefactType = {
  id: string;
  name: string;
};

type CompanyFilterArtefactTypes = {
  id: string;
  company_artefact_type: {
    id: string;
    name: string;
  };
};

type CompanyArtefactRoleAssignments = {
  id: string;
  company_artefact_type: CompanyArtefactType;
};

type DashboardFilterProjects = {
  id: string;
  name: string;
};

type DashboardFilterAssignees = {
  is_current_executor: boolean;
  project_user: {
    company_user: {
      id: string;
      user: {
        id: string;
        first_name: string;
        last_name: string;
        email: string;
      };
    };
  };
};

type CompanyArtefactRoleAssignmentsNode = {
  node: CompanyArtefactRoleAssignments;
};

export const getCompanyArtefactTypeIds = (arr: CompanyArtefactRoleAssignmentsNode[]) => {
  return arr
    ?.map((c: CompanyArtefactRoleAssignmentsNode) => c?.node)
    ?.map((a: CompanyArtefactRoleAssignments) => a.company_artefact_type)
    ?.map((a: CompanyArtefactType) => a.id);
};

function useDashboardFilters(
  companyId?: string | null,
  statesScope = 'PROJECT_TASK_STATE', // statesScope can be "ARTEFACT_ISSUE|PROJECT_TASK_STATE"
  userRoleIds?: number[],
  userId?: number,
  fetchPolicy?: WatchQueryFetchPolicy
) {
  const { data, loading } = useQuery(GET_DASHBOARD_FILTERS, {
    variables: {
      companyId,
      statesScope,
      userRoleIds,
      userId,
    },
    skip: !companyId || !userRoleIds,
  });

  const companyStateIds: string[] | undefined = useMemo(
    () => data?.company_states?.map((c: CompanyStateItem) => c?.id || ''),
    [data?.company_states]
  );

  const companyStatesForSelect: DropdownCheckboxItem[] = useMemo(() => {
    return (
      data?.dashboard_filters.dashboard_filter_states?.map((c: CompanyStateItem) => {
        return {
          id: c?.id || '',
          label: c?.name || '',
          value: c?.name || '',
        };
      }) || []
    );
  }, [data?.dashboard_filters.dashboard_filter_states]);

  const companyArtefactTypeIds: string[] | undefined = useMemo(() => {
    return getCompanyArtefactTypeIds(data?.company_artefact_role_assignments?.edges);
  }, [data?.company_artefact_role_assignments?.edges]);

  const companyArtefactTypeForSelect: DropdownCheckboxItem[] = useMemo(() => {
    return data?.dashboard_filters?.dashboard_filter_artefact_types?.map((el: CompanyFilterArtefactTypes) => {
      return {
        id: el.company_artefact_type.id,
        label: el.company_artefact_type.name,
        value: el.company_artefact_type.id,
      };
    });
  }, [data?.dashboard_filters?.dashboard_filter_artefact_types]);

  const companyLearningObjectTypeForSelect: DropdownCheckboxItem[] = useMemo(() => {
    return data?.company_learning_object_types?.map((el: CompanyLearningObjectType, index: number) => {
      return {
        id: el?.id || index.toString(),
        label: el?.name,
        value: el?.id,
      };
    });
  }, [data?.company_learning_object_types]);

  const companyProjects = useMemo(() => {
    return data?.dashboard_filters?.dashboard_filter_projects.map((el: DashboardFilterProjects) => {
      return {
        id: el.id,
        label: el.name,
        value: el.id,
      };
    });
  }, [data?.dashboard_filters?.dashboard_filter_projects]);

  const companyAssignees = useMemo(() => {
    return (
      data?.dashboard_filters?.dashboard_filter_assignees
        // ?.filter(
        //   (a: DashboardFilterAssignees) => a.project_user && Number(a.project_user.company_user.user.id) !== common.userId
        // )
        ?.map((el: DashboardFilterAssignees) => {
          return {
            id: el.project_user?.company_user?.user.id,
            label: `${el.project_user?.company_user?.user?.first_name} ${el.project_user?.company_user?.user?.last_name}`,
            value: el.project_user?.company_user?.user.id,
          };
        })
    );
  }, [data?.dashboard_filters?.dashboard_filter_assignees]);

  const companyMergedTypesForSelect = useMemo(() => {
    const baseTypes =
      data?.dashboard_filters?.dashboard_filter_artefact_types.map((el: CompanyFilterArtefactTypes) => {
        return {
          id: el.company_artefact_type.id,
          label: el.company_artefact_type.name,
          value: el.company_artefact_type.name,
        };
      }) || [];

    const separateTypes =
      data?.dashboard_filters?.dashboard_filter_separate_task_types?.map((el: any) => {
        return {
          id: el.id,
          label: el.name,
          value: el.name,
        };
      }) || [];

    const temp = [...baseTypes, ...separateTypes];
    return uniqBy(temp, 'value');
  }, [
    data?.dashboard_filters?.dashboard_filter_artefact_types,
    data?.dashboard_filters?.dashboard_filter_separate_task_types,
  ]);

  return {
    companyStates: data?.company_states as CompanyStateItem[],
    companyStateIds,
    companyStatesForSelect,
    companyArtefactTypeIds,
    companyArtefactTypeForSelect,
    companyLearningObjectTypeForSelect,
    companyProjectsSelect: companyProjects as DefaultOptionType[],
    companyAssigneesForSelect: companyAssignees as DefaultOptionType[],
    companyMergedTypesForSelect,
  };
}

export default useDashboardFilters;
