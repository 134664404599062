import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { cx } from 'utils';
import { onCopyFile, onCreateArtefactVersion, onUpdateFile } from 'api/requests/files';
import { useSearchParams } from 'react-router-dom';

import IssuePoint from 'components/issuePoint';
import Carousel from 'components/carousel';
import Block from 'components/block';
import { IssuePointTypeEnum } from 'components/issuePoint/types';

import { PresentationIssueRequestPayloadType } from 'store/issues/types';

import useVisible from 'hooks/useVisible';

import { StateType } from '../../../types/entities';
import { initCarouselState } from '../../task/subPages/finalPresentation/FinalPresentation';

import CarouselControl from './CarouselControl';
import { CarouselDisplayEnum, CarouselMainBlockProps, Coordinates, ErrorStateType } from './types';
import PopupSetErrorInfo from './PopupSetErrorInfo';
import GridPresentation from './GridPresentation';

import './styles.scss';

const initStateError = {
  issue: null,
  description: '',
};

function PresentationManagement({
  className,
  files,
  isCurrentExecutor,
  selectedIssue,
  userRole,
  onSelectedIssue,
  issuesTypesList,
  refetch,
  onAddIssue,
  currentArtefact,
  dataList,
  issuesRefetch,
  carouselRef,
  artefactFileVersion,
  currentTaskState,
  carouselState,
  setCarouselState,
}: CarouselMainBlockProps) {
  const [error, setError] = useState<ErrorStateType>(initStateError);
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const carouselWrapperRef = useRef<HTMLDivElement>(null);
  const slideRef = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (carouselWrapperRef?.current?.getBoundingClientRect()?.width) {
      setContainerWidth(carouselWrapperRef?.current?.getBoundingClientRect()?.width);
    }
  }, [carouselWrapperRef]);

  useEffect(() => {
    carouselRef.current?.goTo?.(carouselState.current - 1, true);
  }, [carouselRef, carouselState]);

  const onClearCoordinates = () => setCoordinates(null);

  const { ref, isVisible, setIsVisible } = useVisible(false, onClearCoordinates);

  const onDeleteAllSlides = async () => {
    if (files) {
      if (artefactFileVersion === null || artefactFileVersion?.is_active) {
        await onCopyFile(Number(files.pk)).then(async (response: any) => {
          if (response?.status === 401) {
            await onCopyFile(Number(files.pk)).then(async (resp: any) => {
              await onCreateArtefactVersion({ file_id: resp?.id, artefact_id: Number(currentArtefact?.id) }).then(
                (artNew) => onUpdateFile(files?.file_type.toLowerCase(), Number(artNew?.file_id))
              );
            });
          } else {
            await onCreateArtefactVersion({ file_id: response?.id, artefact_id: Number(currentArtefact?.id) }).then(
              (artNew) => onUpdateFile(files?.file_type.toLowerCase(), Number(artNew?.file_id))
            );
          }
        });
        if (currentArtefact?.artefact_versions?.length) {
          setSearchParams({ version: (currentArtefact?.artefact_versions?.length + 1).toString() });
        }
      } else {
        await onUpdateFile(files?.file_type.toLowerCase(), Number(files?.pk));
      }
      await refetch().then(() => setCarouselState(initCarouselState));
    }
  };

  const handleClickDisplay = (val: CarouselDisplayEnum) => {
    setCarouselState({ ...carouselState, display: val, current: 1 });
  };

  const onChangeCurrent = (v: number) => {
    const total = files?.related_files?.length || 0;
    setCarouselState({ ...carouselState, current: v > total ? total : v });
  };

  const onClickPrev = () => {
    setCarouselState({ ...carouselState, current: carouselState.current - 1 });
  };

  const onClickNext = () => {
    setCarouselState({ ...carouselState, current: carouselState.current + 1 });
  };

  const onClickSlide = (event: any) => {
    if (slideRef.current) {
      const { width, height } = slideRef?.current?.getBoundingClientRect();
      const x = (event.nativeEvent.offsetX * 100) / width;
      const y = (event.nativeEvent.offsetY * 100) / height;

      setCarouselState({
        ...carouselState,
        coordinates: { x, y },
        currentSlideId: Number(files?.related_files?.[carouselState?.current - 1]?.id),
      });
      setCoordinates({ x, y });
      setIsVisible(true);
    }
  };

  const onClickPreviewSlide = (slideNum: number) => {
    setCarouselState({ ...carouselState, current: slideNum, display: CarouselDisplayEnum.single });
  };

  const onClosePopup = () => {
    setIsVisible(false);
    onClearCoordinates();
    setError(initStateError);
  };

  const handleChangeIssue = (value: string, option: any) => {
    setError({ ...error, issue: option });
  };

  const handleChangeDescription = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setError({ ...error, description: e.target.value });
  };

  const onSubmitError = async () => {
    const slideNumber = carouselState.current;
    const addPayload: PresentationIssueRequestPayloadType = {
      artefact_id: Number(currentArtefact?.id),
      slide_number: slideNumber,
      artefact_content_issue_id: Number(issuesTypesList.find((el: any) => el.value === error.issue.value)?.pk),
      description: error.description,
      coordinate_x: carouselState.coordinates?.x,
      coordinate_y: carouselState.coordinates?.y,
    };

    onClosePopup();
    setError(initStateError);
    setCoordinates(null);
    await onAddIssue(addPayload); // This promise
    await issuesRefetch();
  };
  const classNames = cx('carousel-with-error-container', className);

  const showErrorConstructor = isCurrentExecutor && currentTaskState === StateType.REVIEW;

  return (
    <div className={classNames}>
      <CarouselControl
        controlState={carouselState}
        dataLength={files?.related_files?.length || 0}
        onClickDisplay={handleClickDisplay}
        onChangeCurrent={onChangeCurrent}
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
        onDeleteAllSlides={onDeleteAllSlides}
        userRole={userRole}
      />
      {carouselState.display === CarouselDisplayEnum.single ? (
        <div ref={carouselWrapperRef} className="carousel-with-error-wrapper">
          <Block empty>
            <Carousel cbRef={carouselRef} initWidth={containerWidth} className="ccm-carousel-error-mark" dots={false}>
              {files?.related_files?.map((el: any) => {
                return (
                  <div key={el.id} className="carousel-with-error__content">
                    <img src={el.source_url} alt="slide" />
                    {dataList?.map((e: any) => {
                      if (el?.order === e?.slide_number) {
                        return (
                          <IssuePoint
                            info={e}
                            type={IssuePointTypeEnum.new}
                            active={selectedIssue?.id === e?.id}
                            key={e.id}
                            style={{
                              left: `${e.coordinate_x}%`,
                              top: `${e.coordinate_y}%`,
                              transform: 'translate(-50%, -50%)',
                            }}
                            onClick={onSelectedIssue}
                          />
                        );
                      }
                    })}
                    <div className="carousel-with-error--handler" ref={slideRef} onClick={onClickSlide} />
                  </div>
                );
              })}
            </Carousel>
          </Block>

          {coordinates && showErrorConstructor ? (
            <IssuePoint
              style={{ left: `${coordinates.x}%`, top: `${coordinates.y}%`, transform: 'translate(-50%, -50%)' }}
            />
          ) : null}

          {coordinates && showErrorConstructor ? (
            <PopupSetErrorInfo
              style={{ left: `${coordinates.x}%`, top: `${coordinates.y}%`, transform: 'translate(calc(-50%), 10px' }}
              containerRef={ref}
              isVisible={isVisible}
              onClose={onClosePopup}
              values={error}
              onChangeSelect={handleChangeIssue}
              onChangeTextField={handleChangeDescription}
              onSubmit={onSubmitError}
              issuesTypesList={issuesTypesList}
            />
          ) : null}
        </div>
      ) : (
        <GridPresentation
          files={files}
          refetch={refetch}
          userRole={userRole}
          artefactFileVersion={artefactFileVersion}
          currentArtefact={currentArtefact}
          onClickPreviewSlide={onClickPreviewSlide}
        />
      )}
    </div>
  );
}

export default PresentationManagement;
