import React, { memo } from 'react';

import { Handle, Position, NodeProps, useReactFlow, useStoreApi } from 'reactflow';

import Select from 'components/select';

import useCommonContext from 'hooks/useCommonContext';
import useCompanyRoles from 'hooks/queries/useCompanyRoles';

import './styles.scss';

export type NodeData = {
  label: string;
  userRoleId: string;
};

const stopPropagation = (event: any) => event.stopPropagation();

const CustomNode = ({ data, ...props }: NodeProps<NodeData>) => {
  const common = useCommonContext();
  const { companyRolesForSelect } = useCompanyRoles(common.company?.id);

  const { setNodes } = useReactFlow();
  const store = useStoreApi();

  const onChange = (value: string) => {
    const { nodeInternals } = store.getState();
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === props.id) {
          node.data = {
            ...node.data,
            userRoleId: value,
          };
        }

        return node;
      })
    );
  };

  return (
    <div className="flow-custom-node">
      <Handle id={`target-top-${props?.id}`} className="customHandle" position={Position.Top} type="target" />
      <Handle id={`target-right-${props?.id}`} className="customHandle" position={Position.Right} type="target" />
      <Handle id={`target-bottom-${props?.id}`} className="customHandle" position={Position.Bottom} type="target" />
      <Handle id={`target-left-${props?.id}`} className="customHandle" position={Position.Left} type="target" />

      <Handle id={`source-top-${props?.id}`} className="customHandle" position={Position.Top} type="source" />
      <Handle id={`source-right-${props?.id}`} className="customHandle" position={Position.Right} type="source" />
      <Handle id={`source-bottom-${props?.id}`} className="customHandle" position={Position.Bottom} type="source" />
      <Handle id={`source-left-${props?.id}`} className="customHandle" position={Position.Left} type="source" />

      <span className="flow-custom-node__label">{data.label}</span>
      <Select
        className="mb_8 nodrag"
        noForm
        size="small"
        placeholder="Role"
        value={data?.userRoleId}
        options={companyRolesForSelect}
        onClick={stopPropagation}
        onChange={onChange}
      />
    </div>
  );
};

export default memo(CustomNode);
