import React from 'react';

import { CloseCircleFilled } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';

import useCommonContext from 'hooks/useCommonContext';

import Button from '../../../components/button';
import { PATH_NAMES } from '../../../router/constants';
import { ButtonTypesEnum } from '../../../components/button/types';
import './styles.scss';

type ServiceMessageType = {
  message?: string;
  commonTitle?: boolean;
};

function ServiceNotification({ message, commonTitle }: ServiceMessageType) {
  const common = useCommonContext();
  const location = useLocation();
  const navigate = useNavigate();
  const errorNumber = location?.pathname.substring(1);

  const onClickReturn = () => {
    navigate(PATH_NAMES.dashboard.base);
    window.location.reload();
  };
  const onClickRefresh = () => {
    navigate(-1);
    window.location.reload();
  };
  return (
    <div className="service-notification">
      <div className="service-notification__icon mb_24">
        <CloseCircleFilled style={{ color: '#D73C1D', fontSize: '70px' }} />
      </div>
      <div className="service-notification__title mb_8">
        {common.t<string>(`service.${commonTitle ? 'error' : errorNumber}.title`)}
      </div>
      <div className="service-notification__description mb_24">
        {message || common.t<string>(`service.${errorNumber || 'error'}.description`)}
      </div>
      <div className="service-notification__button">
        <Button size="large" onClick={onClickReturn} className="mr_8">
          {common.t<string>(`service.return`)}
        </Button>
        {(errorNumber === '500' || errorNumber === '502' || errorNumber === '503') && (
          <Button type={ButtonTypesEnum.primary} size="large" onClick={onClickRefresh} className="mr_8">
            {common.t<string>(`service.refresh`)}
          </Button>
        )}
      </div>
    </div>
  );
}

export default ServiceNotification;
