import React, { memo } from 'react';

import { Timeline as ATimeline, TimelineProps } from 'antd';
import { cx, getFormatDateByStr } from 'utils';

import { LazyArtefactDeadlinesChangedItem } from 'hooks';

import useCompanyUsers from 'hooks/queries/useCompanyUsers';
import useCommonContext from 'hooks/useCommonContext';

import Block from '../block';

interface TimelineArtefactDeadlinesChangedProps extends TimelineProps {
  className?: string;
  data?: LazyArtefactDeadlinesChangedItem[];
  isSeparate: boolean;
}

function TimelineArtefactDeadlinesChanged({ className, data, isSeparate }: TimelineArtefactDeadlinesChangedProps) {
  const common = useCommonContext();
  const { companyUsersData } = useCompanyUsers(Number(common?.company?.id), 'cache-and-network');
  const classNames = cx('ccm-timeline ccm-timeline-artefact-dates', className);

  return (
    <ATimeline className={classNames}>
      {data?.map((elem) => {
        const user = companyUsersData?.active_users?.find((item) => item?.user.id === elem?.creator?.id);
        const newDate = elem?.deadline_new ? getFormatDateByStr(elem?.deadline_new, 'D MMM YYYY') : '';

        return (
          <ATimeline.Item key={elem.id}>
            <Block empty hidden={!user}>
              <span className="ccm-timeline__text">
                {`${isSeparate ? '' : user?.business_roles?.[0]?.name} ${user?.user?.first_name} ${
                  user?.user?.last_name
                } ${common.t<string>('pages.courses.table.changedDatesProduction')}`}
              </span>
            </Block>
            <Block empty hidden={!!user}>
              <span className="ccm-timeline__text">
                {`${common.t<string>('pages.courses.table.user')} ${common.t<string>(
                  'pages.courses.table.changedDatesProduction'
                )}`}
              </span>
            </Block>
            <span className="ccm-timeline__bold">{`${newDate}`}</span>
            <span className="ccm-timeline__time">{getFormatDateByStr(elem.created_at, 'D MMM HH:mm')}</span>
          </ATimeline.Item>
        );
      })}
    </ATimeline>
  );
}

export default memo(TimelineArtefactDeadlinesChanged);
