import React, { memo } from 'react';

import { v4 as uuid } from 'uuid';

import { Instruction } from 'hooks/queries/useArtefact';

import './styles.scss';

type UniversalTaskInstructionProps = {
  instruction?: Instruction;
};

function UniversalTaskInstruction({ instruction }: UniversalTaskInstructionProps) {
  const arr = instruction?.related_files?.sort((a, b) => a?.order - b?.order);

  return (
    <div className="instruction">
      <div className="instruction__wrapper">
        {arr?.map((r) => {
          const content = JSON.parse(r.section_content);

          if (r.section_type === 'header' && r.level === 1) {
            return <h4 key={uuid()}>{content?.text}</h4>;
          }

          if (r.section_type === 'header' && r.level === 4) {
            return <h5 key={uuid()}>{content?.text}</h5>;
          }

          if (r.section_type === 'paragraph') {
            return <p key={uuid()} dangerouslySetInnerHTML={{ __html: content?.text }} />;
          }

          if (r.section_type === 'list') {
            return (
              <ul key={uuid()}>
                {content.items.map((item: string) => (
                  <li key={uuid()}>{item}</li>
                ))}
              </ul>
            );
          }
        })}
      </div>
    </div>
  );
}

export default memo(UniversalTaskInstruction);
