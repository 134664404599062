import React, { memo, ReactNode } from 'react';

import { cx } from 'utils';

import Block from '../../../../components/block';

type AboutDescriptionProps = {
  title?: string;
  value?: ReactNode;
  fontSize?: 'normal' | 'large';
};

function AboutDescription({ title, value, fontSize = 'normal' }: AboutDescriptionProps) {
  const classNames = cx('course-page-about__description', {
    [`course-page-about__description--${fontSize}`]: fontSize,
  });
  return (
    <Block className={classNames} hidden={!value}>
      <div className="course-page-about__title">{title}</div>
      <div className="course-page-about__value">{value}</div>
    </Block>
  );
}

export default memo(AboutDescription);
