import React, { memo } from 'react';

import { Badge as ABadge } from 'antd';
import { cx } from 'utils';

import { CCMBadgeProps, CCMBadgeVariant } from './types';

function Badge({ className, variant, ...props }: CCMBadgeProps) {
  const classNames = cx('ccm-badge', className, {
    [`ccm-badge--${variant}`]: variant,
  });
  const styles = () => {
    switch (variant) {
      case CCMBadgeVariant.dark:
        return { color: '#1e1e1e', text: '#f8f8f8' };
      case CCMBadgeVariant.light:
        return { color: '#ffffff', borderColor: '#c8c6c5', text: '#1e1e1e' };
      case CCMBadgeVariant.green:
        return { color: '#65B129' };
      case CCMBadgeVariant.red:
        return { color: '#d73c1d' };
      case CCMBadgeVariant.blue:
        return { color: '#5149D3' };
      default:
        return { color: '#d73c1d' };
    }
  };
  return <ABadge {...props} color={styles().color} className={classNames} />;
}

export default memo(Badge);
