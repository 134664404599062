import { message } from 'antd';
import i18n from 'i18next';
import { v4 as uuid } from 'uuid';

export const noImplementation = () => message.warning('Not implemented');

export const tempLoader = (loading: boolean, text?: string) => {
  const uid = uuid();
  if (loading) {
    message.loading({ content: text || i18n?.t<string>('common.loading'), duration: 0, key: uid });
    return uid;
  }
  if (!loading) {
    message.destroy(uid);
  }
};
