import { createContext, Dispatch, SetStateAction, useState } from 'react';

type Context = {
  actions: any;
  setAction: any;
  data: any;
  setData: Dispatch<SetStateAction<any>>;
};

const initContext: Context = {
  actions: {
    taskInfoRefetch: null,
  },
  setAction: (): void => {},
  data: {
    prevUrl: '',
  },
  setData: (): void => {},
};

const GlobalActionsContext = createContext(initContext);

export const GlobalActionsProvider = ({ ...props }) => {
  const [state, setState] = useState<any>({});
  const [data, setData] = useState<any>({});

  const value = {
    actions: { ...state },
    setAction: setState,
    data,
    setData,
  };

  return <GlobalActionsContext.Provider {...props} value={value} />;
};

export default GlobalActionsContext;
