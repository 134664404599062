import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';

import PopupCard from 'components/popupCard';
import Select from 'components/select';
import TextField from 'components/textField';
import { TextFieldTypeEnum } from 'components/textField/types';
import Button from 'components/button';

import { ButtonTypesEnum } from '../../../components/button/types';

import { PopupSetErrorInfoProps } from './types';

function PopupSetErrorInfo({
  isVisible,
  containerRef,
  style,
  values,
  onClose,
  onChangeSelect,
  onChangeTextField,
  onSubmit,
  issuesTypesList,
}: PopupSetErrorInfoProps) {
  const { t } = useTranslation();
  if (!isVisible) {
    return null;
  }

  return (
    <PopupCard style={style} className="popup-set-error-info p_16" containerRef={containerRef}>
      <div>
        <Select
          noForm
          fullWidth
          options={issuesTypesList}
          className="mb_14"
          getPopupContainer={(triggerNode) => triggerNode}
          placeholder={t('form.field.errorType.placeholder')}
          value={values.issue}
          onChange={onChangeSelect}
        />
        <TextField
          noForm
          className="mb_14"
          placeholder={t('form.field.description.placeholder')}
          autoSize={{ minRows: 2, maxRows: 6 }}
          type={TextFieldTypeEnum.textArea}
          value={values.description}
          onChange={onChangeTextField}
        />
      </div>
      <div className="popup-set-error-info__footer">
        <Button onClick={onClose} className="mr_8" type={ButtonTypesEnum.default}>
          {t('common.cancel')}
        </Button>
        <Button disabled={!values.issue} onClick={onSubmit} type={ButtonTypesEnum.primary}>
          {t('common.addError')}
        </Button>
      </div>
    </PopupCard>
  );
}

export default memo(PopupSetErrorInfo);
