import React, { memo, useMemo } from 'react';

import i18next from 'i18next';
import 'dayjs/locale/ru';
import 'dayjs/locale/en-gb';
import ruRu from 'antd/es/date-picker/locale/ru_RU';
import enUS from 'antd/es/date-picker/locale/en_US';
import { Form, DatePicker } from 'antd';
import { calculateHeight, cx } from 'utils';

import { RangePickerProps } from './types';

const { RangePicker } = DatePicker;

import '../datePicker/styles.scss';

function CCMRangePicker({
  className,
  label,
  rules,
  name,
  noForm,
  size = 'middle',
  getPopupContainer,
  disabledDate,
  status,
  format,
  ...props
}: RangePickerProps) {
  const locale = i18next.language === 'ru' ? ruRu : enUS;
  const classNames = cx('ccm-date-picker', className, {
    'ccm-date-picker--no-form': noForm,
    [`ccm-date-picker--${size}`]: size,
    [`ccm-date-picker--${name}`]: name,
  });
  const dateFormat = format || 'DD/MM/YYYY';
  const styles = useMemo(() => {
    return {
      height: `${calculateHeight(Boolean(label), Boolean(noForm), size)}px`,
    };
  }, [label, noForm, size]);

  if (noForm) {
    return (
      <RangePicker
        locale={locale}
        format={dateFormat}
        className={classNames}
        size={size}
        disabledDate={disabledDate}
        getPopupContainer={getPopupContainer}
        style={styles}
        status={status}
        {...props}
      />
    );
  }

  return (
    <Form.Item
      style={styles}
      label={label}
      name={name}
      className={classNames}
      colon={false}
      labelAlign="left"
      rules={rules}
    >
      <RangePicker
        locale={locale}
        format={dateFormat}
        size={size}
        className={classNames}
        disabledDate={disabledDate}
        getPopupContainer={getPopupContainer}
        status={status}
        {...props}
      />
    </Form.Item>
  );
}

export default memo(CCMRangePicker);
