import React, { memo, useMemo } from 'react';

import { Outlet, useSearchParams } from 'react-router-dom';
import { TaskSidebarLayout } from 'layout/sidebar';
import { BusinessRoleEnum, CompanyUserItem } from 'types/entities';
import { buildAbilityFor } from 'config/ability';
import { AbilityContext } from 'config/Can';
import { convertRoles } from 'utils';

import { checkUserBusinessRole } from 'utils/roles';

import useProjectTask from 'hooks/queries/useProjectTask';
import useCommonContext from 'hooks/useCommonContext';

type TaskPageProps = {
  withoutSidebar?: boolean;
};

function TaskPage({ withoutSidebar }: TaskPageProps) {
  const [searchParams] = useSearchParams();
  const version = searchParams.get('version');
  const { currentProject } = useProjectTask(version, 'cache-and-network');
  const common = useCommonContext();

  const getAbility = useMemo(() => {
    const userRolesByProject = currentProject?.active_users?.find(
      (u: CompanyUserItem) => u.company_user.user.id === common?.userId?.toString()
    );
    if (common?.company?.id === currentProject?.company.id) {
      if ((checkUserBusinessRole(common.businessRoles), BusinessRoleEnum.executiveManager)) {
        return convertRoles(common.businessRoles);
      } else {
        return userRolesByProject.roles;
      }
    }

    return [];
  }, [
    common?.businessRoles,
    common?.company?.id,
    common?.userId,
    currentProject?.active_users,
    currentProject?.company.id,
  ]);

  const ability = buildAbilityFor(getAbility);

  return (
    <AbilityContext.Provider value={ability}>
      <TaskSidebarLayout withoutSidebar={withoutSidebar}>
        <Outlet />
      </TaskSidebarLayout>
    </AbilityContext.Provider>
  );
}

export default memo(TaskPage);
