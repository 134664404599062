import React, { memo } from 'react';

import {
  FullscreenOutlined,
  LeftCircleOutlined,
  PauseCircleFilled,
  PlayCircleFilled,
  RightCircleOutlined,
} from '@ant-design/icons';
import { ReactPlayerProps } from 'react-player';
import { ArtefactContentVideoFoundIssue } from 'types/entities/artefact';

import Button from 'components/button';

import { ButtonTypesEnum } from '../../../button/types';
import VideoProgressBar from '../progressBar';
import Duration from '../duration';
import PlaybackRate from '../playbackRate';

import './styles.scss';

type ControlPanelProps = {
  state: ReactPlayerProps;
  errors?: ArtefactContentVideoFoundIssue[];
  fullScreen: boolean;
  selectedIssue: ArtefactContentVideoFoundIssue | null;
  onPlayPause: () => void;
  onSeekChange: (value: number) => void;
  onSelectedIssue: (value: any) => void;
  onClickFullScreen: () => void;
  onPlaybackRate: (val: number) => void;
  onNextIssue: () => void;
  onPrevIssue: () => void;
};

function ControlPanel({
  state,
  errors,
  fullScreen,
  selectedIssue,
  onPlayPause,
  onSeekChange,
  onSelectedIssue,
  onClickFullScreen,
  onPlaybackRate,
  onNextIssue,
  onPrevIssue,
}: ControlPanelProps) {
  return (
    <div className="video-control-panel">
      <Button
        onClick={onPlayPause}
        type={ButtonTypesEnum.text}
        icon={
          state.playing ? <PauseCircleFilled style={{ fontSize: 17 }} /> : <PlayCircleFilled style={{ fontSize: 17 }} />
        }
        className="video-control-panel__btn-play testclass-video-play"
      />
      <PlaybackRate value={state.playbackRate} callback={onPlaybackRate} />
      <VideoProgressBar
        fullScreen={fullScreen}
        value={state.played}
        duration={state.duration}
        errors={errors}
        selectedIssue={selectedIssue}
        onSeekChange={onSeekChange}
        onSelectedIssue={onSelectedIssue}
      />
      <Button
        onClick={onPrevIssue}
        type={ButtonTypesEnum.link}
        icon={<LeftCircleOutlined />}
        className="ml_12 testclass-video-errors-previous"
      />
      <Button
        onClick={onNextIssue}
        type={ButtonTypesEnum.link}
        icon={<RightCircleOutlined />}
        className="ml_6 testclass-video-errors-next"
      />
      <Duration seconds={state.duration} played={state.played} className="ml_10" />
      <Button
        onClick={onClickFullScreen}
        type={ButtonTypesEnum.link}
        icon={<FullscreenOutlined />}
        className="ml_10 testclass-video-fullscreen"
      />
    </div>
  );
}

export default memo(ControlPanel);
