import React, { memo } from 'react';

import { Tag as ATag } from 'antd';
import { cx } from 'utils';

import { ITagProps, TagShapeEnum, TagSizeEnum } from './types';

function Tag({ className, size, shape, ...props }: ITagProps) {
  const classNames = cx('ccm-tag', className, {
    [`ccm-tag--${size}`]: size,
    [`ccm-tag--${shape}`]: shape,
    [`ccm-tag--${props?.color}`]: props?.color,
  });
  return <ATag {...props} className={classNames} />;
}

Tag.defaultProps = {
  size: TagSizeEnum.small,
  shape: TagShapeEnum.default,
};

export default memo(Tag);
