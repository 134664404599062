import { Edge, Node } from 'reactflow';

import { ArtefactPipelineItem, ArtefactPipelineSequence, Management } from 'hooks/queries/getArtefactPipeline';

import { NodeData } from './customNode/CustomNode';

export const convertToNode = (sequences?: ArtefactPipelineSequence[], getPipeline?: () => Promise<void>) => {
  return sequences?.map((sequence, index) => {
    const management: Management = sequence.management_data ? JSON.parse(sequence.management_data) : null;
    return {
      id: sequence.id,
      type: 'custom',
      position: {
        x: management ? management?.position?.x : -400,
        y: management ? management?.position?.y : 400 * sequence?.order,
      },
      data: {
        order: sequence?.order,
        isFinal: sequence?.is_final,
        artefactType: sequence?.artefact_type,
        defaultState: sequence?.default_state,
        statusListVerId: sequence?.default_state?.company_state_list_version?.id,
        sequences: sequences,
        rules: sequence.sequence_rules,
        onRefetch: getPipeline,
      },
    };
  });
};

export const convertToEdge = (sequences?: ArtefactPipelineSequence[]) => {
  const result: any = [];
  sequences?.forEach((sequence, index) => {
    const management: Management = sequence.management_data ? JSON.parse(sequence.management_data) : null;
    if (management?.links) {
      management?.links?.forEach((l) => result.push(l));
    }
  });

  return result;
};

export const getPayload = (pipeline: ArtefactPipelineItem, nodes: Node<NodeData>[], edges: Edge[]) => {
  return {
    steps: [
      {
        order: pipeline?.actual_version.steps[0].order,
        sequences: nodes.map((nodeItem) => {
          return {
            is_final: nodeItem.data.isFinal,
            order: nodeItem.data.order,
            artefact_type_id: Number(nodeItem?.data?.artefactType?.id),
            default_state_id: Number(nodeItem?.data?.defaultState?.id),
            management_data: {
              statusListVerId: nodeItem?.data?.statusListVerId,
              position: {
                x: nodeItem.position.x,
                y: nodeItem.position.y,
              },
              links: edges.filter((edgeItem) => edgeItem.source === nodeItem.id),
            },
          };
        }),
      },
    ],
  };
};

export const getPayloadAddArtefact = (
  artId: string,
  pipeline: ArtefactPipelineItem,
  nodes: Node<NodeData>[],
  edges: Edge[]
) => {
  return {
    steps: [
      {
        order: pipeline?.actual_version.steps[0].order,

        sequences: [
          ...nodes.map((nodeItem) => {
            return {
              is_final: nodeItem.data.isFinal,
              order: nodeItem.data.order + 1,
              artefact_type_id: Number(nodeItem?.data?.artefactType?.id),
              default_state_id: Number(nodeItem?.data?.defaultState?.id),
              management_data: {
                statusListVerId: nodeItem?.data?.statusListVerId,
                position: {
                  x: nodeItem.position.x,
                  y: nodeItem.position.y,
                },
                links: edges.filter((edgeItem) => edgeItem.source === nodeItem.id),
              },
            };
          }),
          {
            is_final: false,
            order: 1,
            artefact_type_id: Number(artId),
            management_data: {
              position: {
                x: nodes[0].position.x,
                y: nodes[0].position.y - 560,
              },
            },
          },
        ],
      },
    ],
  };
};
