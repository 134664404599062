import React, { useMemo, useState } from 'react';

import { cx } from 'utils';
import { Form, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { isEmpty, noop } from 'lodash';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';

import HeaderModal from 'components/modal/components/header';

import TextField from '../../components/textField';
import Select from '../../components/select/Select';
import InputNum from '../../components/inputNum';
import Button from '../../components/button/Button';
import FooterModal from '../../components/modal/components/footer';
import { AdminCompanyLearningObjectType } from '../../types';
import { onCreateLearningObjectType } from '../../api/requests/companyState';
import useBoolean from '../../hooks/useBoolean';

type Props = {
  companyId: string;
  refetch: () => Promise<any>;
  onCancel: () => void;
};

type FormData = {
  name: string;
  type: string;
  order: number;
};

const CreateNewLOType = ({ companyId, refetch, onCancel }: Props) => {
  const [loading, onLoading] = useBoolean(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const classNames = cx('ccm-modal--wrapper create-lo-type');

  const typeOptions = useMemo(() => {
    const typeOptionsArr = Object.keys(AdminCompanyLearningObjectType.type);
    return typeOptionsArr.map((el) => {
      return {
        label: el,
        value: el.toLowerCase(),
      };
    });
  }, []);

  const onSubmit = async (values: FormData) => {
    onLoading.on();
    if (companyId) {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('type', values.type);
      if (values.order) {
        formData.append('order', `${values.order}`);
      }
      if (!isEmpty(fileList)) {
        const file = fileList[0];
        formData.append('icon_image', file.originFileObj as Blob);
      }
      await onCreateLearningObjectType(companyId, formData);
      await refetch().then(() => {
        onCancel();
        onLoading.off();
      });
    }
  };

  const customRequest = () => null;

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    onChange: (info) => {
      setFileList(info.fileList);
    },
    beforeUpload: () => {
      return false;
    },
    fileList,
  };

  return (
    <div style={{ width: 520 }} className={classNames}>
      <HeaderModal isSmallTitle title="Add new learning object type" />
      <Form
        disabled={loading}
        className="create-art-type__form"
        name="create-art-type"
        layout="vertical"
        requiredMark={false}
        onFinish={onSubmit}
      >
        <TextField
          label="Name"
          name="name"
          size="xl"
          placeholder="Artefact type name"
          rules={[{ required: true, message: 'required field' }]}
        />
        <Select
          label="Type"
          name="type"
          size="xl"
          options={typeOptions}
          placeholder="Select type"
          rules={[{ required: true, message: 'required field' }]}
        />
        <Form.Item label="Order" name="order" style={{ minHeight: 98 }}>
          <InputNum size="xl" placeholder="Order" />
        </Form.Item>
        <div className="mb_24">
          <Upload {...props} name="logo" action="" listType="text" customRequest={customRequest}>
            <Button icon={<UploadOutlined />}>Upload icon</Button>
          </Upload>
        </div>
        <FooterModal
          loading={loading}
          labelCancel="Cancel"
          labelSubmit="Save"
          onCancel={onCancel}
          onSubmit={noop}
          isForm
        />
      </Form>
    </div>
  );
};

export default CreateNewLOType;
