import React, { ReactNode } from 'react';

import './styles.scss';

type DashboardEntityProps = {
  children?: ReactNode;
  title?: string;
  hidden?: boolean;
};

function EntityBlock({ children, title, hidden }: DashboardEntityProps) {
  if (hidden) {
    return null;
  }
  return (
    <div className="dashboard-entity-block mb_32">
      <div className="dashboard-entity-block__title mb_16">{title}</div>
      {children}
    </div>
  );
}

export default EntityBlock;
