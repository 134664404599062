import React, { memo } from 'react';

import { INavigationItem } from 'types/global';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import useCommonContext from 'hooks/useCommonContext';

type ProjectNavigationProps = {
  list: INavigationItem[];
};

function ProjectNavigation({ list }: ProjectNavigationProps) {
  const common = useCommonContext();
  const location = useLocation();
  const { id } = useParams();
  return (
    <ul className="project-nav">
      {list.map((el) => {
        return (
          <li className="project-nav__item" key={el.id}>
            <NavLink
              className={({ isActive }) => {
                return isActive || (el.path === 'plan/tree' && location.pathname === `/project/${id}/plan/list`)
                  ? 'active'
                  : '';
              }}
              to={el.path}
            >
              {common.t<string>(el.name)}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
}

export default memo(ProjectNavigation);
