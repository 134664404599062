import { gql } from '@apollo/client';

export const GET_ARTEFACT_ISSUES_PRESENTATION_LIST = gql`
  query GetPresentationIssuesQuery($artefactId: Int, $ordering: String, $tasks_state: String) {
    artefact_content_presentation_found_issues(
      artefact_id: $artefactId
      ordering: $ordering
      tasks_state: $tasks_state
    ) {
      edges {
        node {
          id
          created_at
          updated_at
          creator {
            id
            first_name
            last_name
            email
          }
          artefact {
            id
            artefact_type {
              id
              name
              type
              icon_image
            }
            estimate_capacity
            produced_capacity
            production_deadline
            artefact_versions {
              id
              created_at
              updated_at
              creator {
                id
                first_name
                last_name
                email
              }
              artefact {
                id
                artefact_type {
                  id
                  name
                  type
                  icon_image
                }
                artefact_pipeline {
                  id
                  actual_version {
                    id
                    is_actual
                  }
                  company_artefact_type {
                    id
                    name
                    type
                    icon_image
                  }
                  company {
                    id
                    name
                    slug
                    company_type
                  }
                }
                estimate_capacity
                produced_capacity
                production_deadline

                artefact_iteration_count
              }

              number
              is_active
            }
            artefact_iteration_count
            artefact_pipeline {
              id
              company {
                id
                created_at
                updated_at
                name
                slug
                company_type
              }
              actual_version {
                id
                artefact_pipeline {
                  id
                  company {
                    id
                    name
                    slug
                  }
                  company_artefact_type {
                    id
                    name
                    type
                    icon_image
                  }
                }
                is_actual
              }
              company_artefact_type {
                id
                name
                type
                icon_image
              }
            }
          }
          issue {
            id
            created_at
            updated_at
            pk
            creator {
              id
              first_name
              last_name
              email
            }
            name
            description
            image_url
            company_artefact_type {
              id
              name
              type
              icon_image
            }
            pk
          }
          coordinate_x
          coordinate_y
          changed_by {
            id
            first_name
            last_name
            email
          }
          is_active
          pk
          slide_number

          project_task {
            id
            name
            description
            projecttaskassignee_set {
              id
            }
            discuss_messages {
              id
              created_at
              updated_at
              creator {
                id
                first_name
                last_name
                email
              }
              project_user {
                id
                company_user {
                  user {
                    id
                    first_name
                    last_name
                    email
                  }
                }
                roles {
                  role {
                    id
                    name
                    role
                  }
                }
                is_active
              }
              message
              is_active
            }
            last_state {
              id
              name
              state_type
              created_at
              updated_at
              creator {
                id
                first_name
                last_name
                email
              }
              company_state_list_version {
                id
                company_state_list {
                  id
                  created_at
                  updated_at
                  creator {
                    id
                    first_name
                    last_name
                    email
                  }
                  name
                  company {
                    id
                    name
                    slug
                  }
                  actual_version {
                    id
                    company_state_list {
                      id
                      created_at
                      updated_at
                      creator {
                        id
                        first_name
                        last_name
                        email
                      }
                      name
                      company {
                        id
                        name
                        slug
                      }
                    }
                  }
                }
              }
              order
              is_new_iteration_initiation
            }
            task_type
            assignees {
              id
              company_user {
                user {
                  id
                  first_name
                  last_name
                  email
                }
              }
              roles {
                role {
                  id
                  name
                  role
                }
              }
              is_active
            }
            pk
            state_list_version_id
            project_task_state_history {
              id
              created_at
              updated_at
              creator {
                id
                first_name
                last_name
                email
              }
              project_task {
                id
                name
                description
                task_type
                pk
                state_list_version_id
                last_state {
                  id
                  state_type
                  name
                  created_at
                  updated_at
                  creator {
                    id
                    first_name
                    last_name
                    email
                  }
                }
              }
              state {
                id
                name
                state_type
                created_at
                updated_at
                creator {
                  id
                  first_name
                  last_name
                  email
                }
                order
                is_new_iteration_initiation
              }
              changed_by {
                id
                first_name
                last_name
                email
              }
              is_rollback
            }
          }
          is_slide_number_changed
          artefact_version_creation {
            number
          }
          artefact_version_solution {
            number
          }
        }
      }
    }
  }
`;
