import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';

import { GET_ARTEFACT } from '../../queries/getArtefact';

export type InstructionRelatedFiles = {
  id: string;
  file: {
    id: string;
  };
  parent_section?: {
    id: string;
  } | null;
  section_type: string;
  section_content: any;
  style: string;
  level?: number | null;
  params?: any | null;
  order: number;
};

export type Instruction = {
  id: string;
  name?: string | null;
  file_type: string;
  related_files?: Array<InstructionRelatedFiles> | null;
  file: { id: string };
};

function useArtefact(artefactId: number, fetchPolicy?: WatchQueryFetchPolicy) {
  const { data, loading, refetch } = useQuery(GET_ARTEFACT, {
    variables: {
      artefactId,
    },
    fetchPolicy: fetchPolicy || 'network-only',
    skip: !artefactId,
  });
  // TODO find temporary solution
  const instruction: Instruction | undefined = data?.artefact?.documents?.find(
    (d: Instruction) => d.file_type === 'document_instruction'
  );

  // TODO find temporary solution
  const checkList: Instruction | undefined = data?.artefact?.documents?.find(
    (d: Instruction) => d.file_type === 'document_checklist'
  );
  return useMemo(() => {
    return {
      artefactData: data?.artefact,
      artefactLoading: loading,
      instruction,
      checkList,
    };
  }, [checkList, data?.artefact, instruction, loading]);
}

export default useArtefact;
