import { gql } from '@apollo/client';

import { COMPANY_TYPE, LAST_STATE, SEPARATE_TASK, USER_ROLE_TYPE, USER_TYPE } from './fragments';

export const GET_SEPARATE_TASK = gql`
  query GetSeparateTask($task_id: Int) {
    separate_task(task_id: $task_id) {
      id
      name
      last_state_updated_at
      last_state_is_changed
      creator {
        ...UserData
      }
      project {
        id
        separate_task_state_list_version {
          id
        }
        company {
          id
        }
      }
      cached_linked_artefact {
        id
      }
      last_state {
        ...LastState
      }
      current_assignees {
        id
        is_current_executor
        is_active
        project_user {
          id
          company_user {
            id
            user {
              ...UserData
            }
            company {
              ...CompanyData
            }
          }
          roles {
            id
            role {
              ...RoleData
            }
          }
        }
      }
      separate_task {
        ...SeparateTask
      }
      active_artefacts {
        id
        is_active
        artefact_versions {
          id
          file {
            id
            pk
            is_active
            file_type
            name
            state
            related_files {
              ... on DocumentFileVersionType {
                id
                original_data
                sections {
                  id
                  parent_section {
                    id
                  }
                  section_type
                  section_content
                  style
                  level
                  params
                  order
                }
              }
              ... on CommonFileType {
                id
                file
              }
            }
            uploaded_by {
              id
              name
              email
            }
          }
        }
      }
    }
  }
  ${USER_TYPE}
  ${USER_ROLE_TYPE}
  ${LAST_STATE}
  ${SEPARATE_TASK}
  ${COMPANY_TYPE}
`;
