import { gql } from '@apollo/client';

export const GET_PROJECT_USERS = gql`
  query ProjectUsers($project_id: Int) {
    project_users(project_id: $project_id) {
      id
      project {
        id
        name
      }
      roles {
        role {
          id
          name
          role
          pk
        }
      }
      company_user {
        id
        is_active
        company {
          id
          name
          slug
          company_type
        }
        user {
          id
          name
          first_name
          last_name
          email
          state
          icon_image
          timezone
        }
      }
      is_external_company
      is_active
      tasks_amount
      is_separate_project_task_assignee
    }
  }
`;
