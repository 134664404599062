import { StateType } from 'types/entities';

import { CompanyArtefactType, CompanyState } from '../../types';

type IndicatorCourseElementItem = {
  status: CompanyState.state_type;
  type: CompanyArtefactType.type;
  progress: number;
  state: StateType;
};

export enum IndicatorCourseElementGroupType {
  video = 'video',
  material = 'material',
  tasks = 'tasks',
}

export type IndicatorCourseElement = {
  groupType: IndicatorCourseElementGroupType;
  items: IndicatorCourseElementItem[];
};

export interface IndicatorProps {
  className?: string;
  hasTooltip?: boolean;
  element: IndicatorCourseElement;
}
