import React from 'react';

import { message } from 'antd';

import Button from 'components/button';

const MessagesStories = () => {
  const info = () => {
    message.info('This is a normal message');
  };
  const success = () => {
    message.success('This is a success message');
  };

  const error = () => {
    message.error('This is an error message');
  };

  const warning = () => {
    message.warning('This is a warning message');
  };

  const loading = () => {
    message.loading('Action in progress....');
  };

  return (
    <div>
      <Button onClick={info} className="mr_8">
        Info
      </Button>
      <Button onClick={success} className="mr_8">
        Success
      </Button>
      <Button onClick={error} className="mr_8">
        Error
      </Button>
      <Button onClick={warning} className="mr_8">
        Warning
      </Button>
      <Button onClick={loading} className="mr_8">
        Loading
      </Button>
    </div>
  );
};

export default MessagesStories;
