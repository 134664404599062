import React, { memo } from 'react';

import { Checkbox as ACheckbox } from 'antd';

import { cx } from '../../utils';

import { ICheckboxProps } from './types';

function Checkbox({ className, iconOnly, ...props }: ICheckboxProps) {
  const classNames = cx('ccm-checkbox', className, {
    'ccm-checkbox--icon': iconOnly,
  });
  return <ACheckbox className={classNames} {...props} />;
}

export default memo(Checkbox);
