import React, { ChangeEvent, RefObject, useState } from 'react';

import { cx } from 'utils';

import useBoolean from 'hooks/useBoolean';
import useCommonContext from 'hooks/useCommonContext';

import Button from '../button';
import { ButtonTypesEnum } from '../button/types';
import Block from '../block';
import TextField from '../textField';
import { TextFieldTypeEnum } from '../textField/types';

import './styles.scss';

type CommentPaneProps = {
  anchor?: RefObject<any>;
  className?: string;
  placeholder?: string;
  labelSubmit?: string;
  labelCancel?: string;
  initText?: string;
  showActions?: boolean;
  onCancel?: () => void;
  onSubmit?: (text: string) => Promise<any>;
  autoFocus?: boolean;
};

function CommentPane({
  anchor,
  initText = '',
  className,
  placeholder,
  labelSubmit,
  labelCancel,
  showActions,
  autoFocus,
  onCancel,
  onSubmit,
}: CommentPaneProps) {
  const [text, setText] = useState<string>(initText);
  const [focused, onFocused] = useBoolean(!!showActions);
  const common = useCommonContext();

  const handleChange = (event: ChangeEvent<any>) => {
    setText(event.target.value);
  };

  const onClickSubmit = () => {
    onSubmit?.(text).then(() => {
      setText('');
      onFocused.off();
    });
  };

  const onClickCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      setText('');
      onFocused.off();
    }
  };

  const onFocus = () => {
    if (!showActions) {
      onFocused.on();
    }
  };

  const onBlur = () => {
    if (!showActions && !text) {
      onFocused.off();
    }
  };

  return (
    <div ref={anchor} className={cx('comment-pane', className)}>
      <TextField
        autoFocus={autoFocus}
        onFocus={onFocus}
        onBlur={onBlur}
        type={TextFieldTypeEnum.textArea}
        placeholder={placeholder || common.t<string>('common.comment')}
        size="large"
        value={text}
        autoSize={{ minRows: 1, maxRows: 50 }}
        onChange={handleChange}
      />

      <Block className="comment-pane__actions" hidden={!focused}>
        <Button size="large" type={ButtonTypesEnum.primary} className="mr_8" disabled={!text} onClick={onClickSubmit}>
          {labelSubmit ? labelSubmit : common.t<string>('common.add')}
        </Button>
        <Button size="large" onClick={onClickCancel}>
          {labelCancel ? labelCancel : common.t<string>('common.canceling')}
        </Button>
      </Block>
    </div>
  );
}

export default CommentPane;
