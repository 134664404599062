import { useQuery } from '@apollo/client';

import useCommonContext from '../useCommonContext';
import { GET_PIPELINES } from '../../queries/getArtefactPipelines';
import { AdminCompanyLearningObjectType } from '../../types';

export type ArtefactPipelinesItem = {
  id: string;
  pk: string;
  company_artefact_type: {
    id: string;
    name: string;
    type: string;
    learning_object_type: AdminCompanyLearningObjectType;
  };
};

type ArtefactPipelineData = {
  artefact_pipelines: {
    edges: {
      node: ArtefactPipelinesItem;
    }[];
  };
};

const useArtefactPipelines = () => {
  const common = useCommonContext();
  const { loading, data, refetch } = useQuery<ArtefactPipelineData>(GET_PIPELINES, {
    variables: {
      companyId: common.company?.id,
    },
    fetchPolicy: 'network-only',
    skip: !common.company?.id,
  });

  return {
    loading,
    artefactPipeline: data?.artefact_pipelines?.edges?.map((el) => el.node),
    refetch,
  };
};

export default useArtefactPipelines;
