import { useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { GET_ARTEFACT_MORE_ITERATION, GET_CURRENT_ARTEFACT_MORE_ITERATION } from 'queries/getArtefactDelays';

import { ArtefactDelaysProdDeadline } from './useLazyArtefactDelaysProductionDeadline';

export type ArtefactMoreIterationDataItem = {
  id: string;
  project_task: {
    cached_linked_artefact: ArtefactDelaysProdDeadline;
  };
};

type ArtefactMoreIterationDataItemNode = {
  node: ArtefactMoreIterationDataItem;
};
function useLazyArtefactMoreIteration(
  projectId: number,
  userId: number,
  isCurrent?: boolean,
  fetchPolicy?: WatchQueryFetchPolicy
) {
  const [getArtefactMoreIteration, { data, loading }] = useLazyQuery(
    isCurrent ? GET_CURRENT_ARTEFACT_MORE_ITERATION : GET_ARTEFACT_MORE_ITERATION,
    {
      variables: {
        project_id: projectId,
        user_id: userId,
      },
      fetchPolicy: fetchPolicy || 'network-only',
    }
  );

  const path = isCurrent
    ? 'metrics_current_assignees_tasks_with_four_iterations'
    : 'metrics_assignees_tasks_with_four_iterations';

  const list = useMemo(() => {
    const temp = data?.[path]?.edges?.map((el: ArtefactMoreIterationDataItemNode) => el?.node);
    return temp?.map((el: ArtefactMoreIterationDataItem) => {
      return {
        id: el.project_task.cached_linked_artefact.id,
        artefact: {
          ...el?.project_task?.cached_linked_artefact,
        },
      };
    });
  }, [data]);

  return useMemo(() => {
    return {
      getArtefactMoreIteration,
      moreIterationList: list,
      moreIterationLoading: loading,
    };
  }, [getArtefactMoreIteration, list, loading]);
}

export default useLazyArtefactMoreIteration;
