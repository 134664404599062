import React, { memo, ReactNode } from 'react';

import { FieldTimeOutlined, RetweetOutlined } from '@ant-design/icons';
import { cx } from 'utils';

import './styles.scss';

type BlockProblemsProps = {
  className?: string;
  hidden?: boolean;
  type: 'delay' | 'iteration';
  title: string;
  children?: ReactNode;
};

function BlockProblems({ className, hidden, type, title, children }: BlockProblemsProps) {
  const classNames = cx('info-drawer-problems', className);
  if (hidden) {
    return null;
  }
  return (
    <div className={classNames}>
      <div className="info-drawer-problems__header">
        {type === 'delay' ? (
          <FieldTimeOutlined style={{ fontSize: 16 }} />
        ) : (
          <RetweetOutlined style={{ fontSize: 16 }} />
        )}
        <span className="info-drawer-problems__title">{title}</span>
      </div>
      <div className="info-drawer-problems__content">{children}</div>
    </div>
  );
}

export default memo(BlockProblems);
