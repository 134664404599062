import React, { ChangeEvent, useCallback, useState } from 'react';

import { UserExtended } from 'types/entities';
import { DeleteOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty, noop } from 'lodash';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import SeparateTaskState from 'pages/viewSeparateTask/modules/separateTaskState';
import { updateProjectTaskAssignee } from 'api/requests/projectTask';
import { AbilityContext } from 'config/Can';
import { PATH_NAMES } from 'router/constants';

import Block from 'components/block';
import Button from 'components/button';

import useCommonContext from 'hooks/useCommonContext';
import useCollaboratorsByRoleQuery from 'hooks/queries/useCollaboratorsByRoleQuery';
import useGlobalActionsContext from 'hooks/useGlobalActionsContext';

import HeaderSmall from '../components/headerSmall';
import SeparateTaskPanel from '../components/separateTaskPanel';
import modalService from '../../services/modalService';
import useSeparateTaskQuery from '../../hooks/queries/useSeparateTaskQuery';
import useSeparateTask from '../hooks/useSeparateTask';
import SeparateTaskUserDrawer from '../components/separateTaskUserDrawer';
import useAddForm from '../users/hooks/useAddForm';
import useProjectAbility from '../project/hooks/useProjectAbility';
import useSeparateTaskAbility from '../hooks/useSeparateTaskAbility';

import EditorBlock from './components/editorBlock';
import SeparateTaskUploadWall from './modules/separateTaskUploadWall';
import SkeletonSeparateTask from './components/skeletons';

import './styles.scss';
import { message } from 'antd';

function ViewSeparateTaskPage() {
  const [selected, setSelected] = useState<UserExtended[] | null>(null);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const { data } = useGlobalActionsContext();
  const { taskId } = useParams();
  const common = useCommonContext();

  const { canIRemoveTask } = useSeparateTaskAbility(taskId);

  useSeparateTaskAbility(taskId);
  const { hasFirstLoad, separateTask, editorData, documentData, separateTaskRefetch } = useSeparateTaskQuery(taskId);
  const { collaborators, collaboratorsRefetch } = useCollaboratorsByRoleQuery({ userRoleId: null });
  const { ability } = useProjectAbility(separateTask?.project.id);

  const separateTaskInstance = useSeparateTask({ separateTask, documentData });
  const userAddInstance = useAddForm(null, undefined, noop, separateTaskInstance.openDrawer);

  const openDrawer = () => separateTaskInstance.onOpenDrawer.on();
  const closeDrawer = useCallback(() => {
    separateTaskInstance.onOpenDrawer.off();
    separateTaskInstance.setView('select');
  }, [separateTaskInstance]);

  const onChangeUsers = useCallback(
    (checkedValue: Array<CheckboxValueType>) => {
      const temp = collaborators?.filter((user) => checkedValue.some((v) => user.pk === v));
      if (temp) {
        setSelected(temp);
      }
    },
    [collaborators]
  );

  const onChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearch(e.target.value);
  }, []);

  const finallySubmitUsers = useCallback(() => {
    return new Promise(() => {
      setSelected(null);
      closeDrawer();
    });
  }, [closeDrawer]);

  const onSubmitSelectUsers = useCallback(async () => {
    const payload = {
      assignees: selected?.map((s) => Number(s.id)) as number[],
    };
    await updateProjectTaskAssignee(separateTask?.id || '', payload).then(() => {
      message.info(common.t<string>('common.changesSaved'));
    });
    await separateTaskRefetch();
    separateTaskInstance.setSelectedUsers(selected);
    await finallySubmitUsers();
  }, [common, finallySubmitUsers, selected, separateTask?.id, separateTaskInstance, separateTaskRefetch]);

  const onSubmitNewUser = async () => {
    await userAddInstance?.onSubmit();
    await collaboratorsRefetch().then(() => {
      separateTaskInstance.setView('select');
      userAddInstance?.form?.resetFields();
    });
  };

  const onConfirmRemoveTask = useCallback(() => {
    modalService.openConfirmModal({
      labelCancel: common.t<string>('common.no'),
      labelConfirm: common.t<string>('common.yes'),
      isDanger: true,
      title: common.t<string>('pages.task.freeTask.deleteTask.title'),
      text: common.t<string>('pages.task.freeTask.deleteTask.text'),
      onConfirm: separateTaskInstance.onRemoveSeparateTask,
    });
  }, [common, separateTaskInstance.onRemoveSeparateTask]);

  const goBack = useCallback(() => {
    if (data?.prevUrl?.includes('plan')) {
      navigate(data?.prevUrl);
    } else if (data?.prevUrl?.includes('tasks')) {
      navigate(PATH_NAMES.tasks.base);
    } else {
      navigate(PATH_NAMES.dashboard.base);
    }
  }, [data.prevUrl, navigate]);

  return (
    <>
      <Block hidden={hasFirstLoad} empty>
        <SkeletonSeparateTask />
      </Block>
      <Block hidden={!hasFirstLoad} className="view-free-task-page">
        <HeaderSmall title={separateTask?.name} onGoBack={goBack}>
          <SeparateTaskState />
          {canIRemoveTask ? (
            <Button className="ml_16" size="large" danger icon={<DeleteOutlined />} onClick={onConfirmRemoveTask} />
          ) : null}
        </HeaderSmall>
        <Block hidden={!hasFirstLoad} className="view-free-task-page__content">
          <EditorBlock
            initialize={separateTaskInstance.initializeEditor}
            readonly={separateTask?.last_state?.state_type === 'done'}
            data={editorData}
            onReadyEditor={separateTaskInstance.onReadyEditor}
            onChangeEditor={separateTaskInstance.onChangeEditor}
          />
          <SeparateTaskUploadWall altLabel />
        </Block>
        <div className="view-free-task-page__panel">
          <SeparateTaskPanel task={separateTask} openDrawer={openDrawer} />
        </div>
        <AbilityContext.Provider value={ability}>
          <SeparateTaskUserDrawer
            userAddInstance={userAddInstance}
            visible={separateTaskInstance.openDrawer}
            companyId={separateTask?.project?.company?.id}
            disabled={separateTaskInstance.view === 'add' ? userAddInstance?.disableSubmit : isEmpty(selected)}
            view={separateTaskInstance.view}
            onChangeView={separateTaskInstance.onChangeView}
            search={search}
            collaborators={collaborators}
            selectedUsers={separateTaskInstance.selectedUsers}
            onChangeUsers={onChangeUsers}
            onChangeSearch={onChangeSearch}
            onClose={closeDrawer}
            onSubmit={separateTaskInstance.view === 'add' ? onSubmitNewUser : onSubmitSelectUsers}
          />
        </AbilityContext.Provider>
      </Block>
    </>
  );
}

export default ViewSeparateTaskPage;
