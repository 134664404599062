import React from 'react';

import { Form } from 'antd';
import { noop } from 'lodash';
import { cx } from 'utils';

import Select from 'components/select/Select';

import HeaderModal from '../../components/modal/components/header';
import FooterModal from '../../components/modal/components/footer';
import useBoolean from '../../hooks/useBoolean';

type Props = {
  options: any[];
  onCancel: () => void;
  onSave: (value: string, onLoading: any) => void;
};

type FormValues = {
  state_type: string;
};

const AddStateRelationship = ({ options, onCancel, onSave }: Props) => {
  const [loading, onLoading] = useBoolean(false);
  const classNames = cx('ccm-modal--wrapper state-relationship');

  const onSubmit = (values: FormValues) => {
    onSave(values.state_type, onLoading);
    onCancel();
  };

  return (
    <div className={classNames}>
      <HeaderModal isSmallTitle title={'State'} onClose={onCancel} />
      <Form disabled={loading} className="state-relationship-form" name="state-relationship" onFinish={onSubmit}>
        <Select
          name="state_type"
          size="xl"
          options={options}
          placeholder="Select type"
          rules={[{ required: true, message: 'required field' }]}
        />
        <FooterModal
          loading={loading}
          labelCancel="Cancel"
          labelSubmit="Save"
          onCancel={onCancel}
          onSubmit={noop}
          isForm
        />
      </Form>
    </div>
  );
};

export default AddStateRelationship;
