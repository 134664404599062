import React from 'react';

import i18next from 'i18next';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import ruRu from 'antd/es/locale/ru_RU';
import enUS from 'antd/es/locale/en_US';
import { ConfigProvider } from 'antd';
import { Helmet } from 'react-helmet';
import { YMInitializer } from 'react-yandex-metrika';
import TagManager from 'react-gtm-module';

import AppRoutes from './router/Routes';
import 'react-notifications/lib/notifications.css';
import { theme } from './theme/theme';

const tagManagerArgs = {
  gtmId: 'G-NBN1L4MBPF',
};

if (process.env.NODE_ENV === 'production') {
  TagManager.initialize(tagManagerArgs);
}
const appCompany = process.env.REACT_APP_CCM_COMPANY;

const App: React.FC = () => {
  const locale = i18next.language === 'ru' ? ruRu : enUS;
  const iconSelf = i18next.language === 'ru' ? '/favicon.ico' : '/cffavicon.ico';

  const iconLink = appCompany === 'constr' ? '/constructor_favicon.ico' : iconSelf;

  return (
    <ConfigProvider locale={locale} theme={theme}>
      {process.env.NODE_ENV === 'production' && process.env.REACT_APP_CCM_LANG === 'ru' ? (
        <YMInitializer
          accounts={[92612627]}
          options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }}
        />
      ) : null}
      <Helmet>
        <link id="favicon" rel="icon" href={iconLink} type="image/x-icon" />
      </Helmet>
      <AppRoutes />
    </ConfigProvider>
  );
};

export default App;
