import React, { memo, useMemo } from 'react';

import { FlagFilled, FlagOutlined } from '@ant-design/icons';
import { cx, getFormatDateByStr } from 'utils';

import Tag from '../tag/Tag';
import Tooltip from '../tooltip';

import './styles.scss';

type TablePlanFactTagProps = {
  text: string;
  delay: number;
  className?: string;
  flagFilled?: boolean;
  filled?: boolean;
  noFlag?: boolean;
  extraText?: string | null;
  tooltip?: string;
};

function TagDelayWithFlag({
  className,
  delay,
  text,
  extraText,
  filled = true,
  flagFilled,
  noFlag,
  tooltip,
}: TablePlanFactTagProps) {
  const classNames = cx('delay-tag', className, {
    'ccm-tag--filled': filled,
    'ccm-tag--orange': delay > 0 && delay < 7,
    'ccm-tag--red': delay >= 7 && delay < 30,
    'ccm-tag--black': delay >= 30,
  });

  const extraClassNames = cx('delay-tag-ex-text', {
    'delay-tag-ex-text--orange': delay > 0 && delay < 7,
    'delay-tag-ex-text--red': delay >= 7 && delay < 30,
    'delay-tag-ex-text--black': delay >= 30,
  });

  const content = useMemo(() => {
    return (
      <>
        <Tag className={classNames} icon={!noFlag && (flagFilled ? <FlagFilled /> : <FlagOutlined />)}>
          {!noFlag ? getFormatDateByStr(text, 'DD MMM') : text}
        </Tag>
        {extraText ? <span className={extraClassNames}>{extraText}</span> : null}
      </>
    );
  }, [classNames, extraClassNames, extraText, flagFilled, noFlag, text]);

  return <>{tooltip ? <Tooltip title={tooltip}>{content}</Tooltip> : content}</>;
}

export default memo(TagDelayWithFlag);
