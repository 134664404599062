import React from 'react';

import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import { AbilityContext } from 'config/Can';

import ProjectHeader from './components/header';
import useProjectAbility from './hooks/useProjectAbility';
import SkeletonHeader from './components/skeletons/SkeletonHeader';

import './styles.scss';

function ProjectPage() {
  const { projectLoading, ability, projectData, projectRefetch } = useProjectAbility();

  return (
    <AbilityContext.Provider value={ability}>
      <Layout className="project-page">
        {projectLoading ? <SkeletonHeader /> : <ProjectHeader title={projectData?.name} />}
        <Outlet context={projectRefetch} />
      </Layout>
    </AbilityContext.Provider>
  );
}

export default ProjectPage;
