import React, { memo, ReactNode, RefObject } from 'react';

import { cx } from 'utils';

import './styles.scss';

type BlockProps = {
  children?: ReactNode;
  className?: string;
  hidden?: boolean | null;
  invisible?: boolean | null;
  empty?: boolean;
  item?: boolean;
  blockRef?: RefObject<any>;
  onClick?: () => void;
};

function Block({ children, className, hidden, invisible, empty, item, blockRef, onClick }: BlockProps) {
  const classNames = cx('ccm-block', className, {
    'ccm-block--invisible': invisible,
  });

  if (hidden) {
    return null;
  }

  if (empty) {
    return <>{children}</>;
  }

  if (item) {
    return (
      <span ref={blockRef} className={classNames}>
        {children}
      </span>
    );
  }

  return (
    <div role={onClick ? 'button' : 'none'} onClick={onClick} ref={blockRef} className={classNames}>
      {children}
    </div>
  );
}

export default memo(Block);
