import React, { memo } from 'react';

import TruncateMarkup from 'react-truncate-markup';

type ImageCardFileNameProps = {
  name: string;
};

function ImageCardFileName({ name }: ImageCardFileNameProps) {
  return (
    <div className="upload-wall-preview__text-wrap">
      <TruncateMarkup lines={3}>
        <span className="upload-wall-preview__text">{name}</span>
      </TruncateMarkup>
    </div>
  );
}

export default memo(ImageCardFileName);
