import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import message from 'antd/lib/message';
import { addCommentToDiscussion } from 'api/requests/projectTask';
import { StateType } from 'types/entities';
import { onChangeProjectStatus } from 'api/requests/projects';
import {
  onDeleteDiscussionComment,
  onDeleteIssueDocument,
  onUpdateDiscussionComment,
  onUpdateIssueDocument,
} from 'api/requests/issues';
import modalService from 'services/modalService';
import { executeScroll } from 'utils';

import { DocumentDiscussionEx } from 'hooks';

import useProjectTask from 'hooks/queries/useProjectTask';
import useCommonContext from 'hooks/useCommonContext';
import useProjectStates from 'hooks/queries/useProjectStates';
import useBoolean from 'hooks/useBoolean';
import useHover from 'hooks/useHover';
import useOnScreen from 'hooks/useOnScreen';

type DiscussionProps = {
  data: DocumentDiscussionEx;
  className?: string;
  projectId: string | null;
  discussionsRefetch: () => Promise<any>;
  onRemoveMarkerById: (markerId: string) => void;
};

export const CONTAINER_ID = 'editor-wrap';
export const CONTAINER_DISCUSSION_ID = 'comments__wrapper';

function useDiscussionControl({ data, projectId, discussionsRefetch, onRemoveMarkerById }: DiscussionProps) {
  const [editDis, onEditDis] = useBoolean(false);
  const [editComment, onEditComment] = useBoolean(false);
  const [editCommentId, setEditCommentId] = useState<string | null>(null);
  const { projectDiscussionStates } = useProjectStates(projectId);
  const anchor = useRef<HTMLDivElement>(null);
  const autoScroll = useRef<boolean>(false);
  const hovered = useHover(anchor);
  const common = useCommonContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const version = searchParams.get('version');
  const issue = searchParams.get('issue');

  const selected = useMemo(() => {
    return issue === data.external_id;
  }, [data.external_id, issue]);

  const { currentProject } = useProjectTask(version, 'cache-and-network');

  const target = document.getElementById(data.external_id);
  const isTargetVisible = useOnScreen(target);

  const onClickCard = useCallback(() => {
    if (selected) {
      return;
    }
    autoScroll.current = true;
    setSearchParams({ issue: data.external_id });

    if (!isTargetVisible) {
      executeScroll(data.external_id, CONTAINER_ID);
    }
  }, [data.external_id, isTargetVisible, selected, setSearchParams]);

  const onCancelEditDis = useCallback(() => onEditDis.off(), [onEditDis]);

  const onClickLink = useCallback(() => {
    const link = `${document.URL}`;
    navigator.clipboard.writeText(link);
    message.success(common.t<string>('comments.linkCopied'), 2.5);
  }, [common]);

  const onAddComment = useCallback(
    async (textMessage: string) => {
      const payload = {
        message: textMessage,
      };
      await addCommentToDiscussion(data.project_task.id, payload);
      return discussionsRefetch();
    },
    [data.project_task.id, discussionsRefetch]
  );

  const hiddenMark = (markId: string) => {
    const node = document.getElementById(markId);
    node?.classList.add('mark-hidden');
  };

  const showMark = (markId: string) => {
    const node = document.getElementById(markId);
    node?.classList.remove('mark-hidden');
  };

  const onCloseDiscussion = useCallback(async () => {
    const payload = {
      state_id: projectDiscussionStates?.find((el) => el.state_type === StateType.DONE)?.id,
    };
    await onChangeProjectStatus(Number(data.project_task.id), payload);
    await discussionsRefetch().then(() => {
      hiddenMark(data.external_id);
    });
  }, [data.external_id, data.project_task.id, discussionsRefetch, projectDiscussionStates]);

  const onResumeDiscussion = useCallback(async () => {
    const payload = {
      state_id: projectDiscussionStates?.find((el) => el.state_type === StateType.NEW)?.id,
    };
    await onChangeProjectStatus(Number(data.project_task.id), payload);
    await discussionsRefetch().then(() => {
      showMark(data.external_id);
    });
  }, [data.external_id, data.project_task.id, discussionsRefetch, projectDiscussionStates]);

  const onSaveEditDis = useCallback(
    async (text: string) => {
      const payload = {
        description: text,
      };
      await onUpdateIssueDocument(data.pk, payload);
      return discussionsRefetch().then(() => onEditDis.off());
    },
    [data.pk, discussionsRefetch, onEditDis]
  );

  const onRemoveDiscussion = useCallback(async () => {
    await onDeleteIssueDocument(data.pk);
    await discussionsRefetch().then(() => {
      onEditDis.off();
      onRemoveMarkerById(data.external_id);
      searchParams.delete('issue');
      setSearchParams(searchParams);
    });
  }, [data.external_id, data.pk, discussionsRefetch, onEditDis, onRemoveMarkerById, searchParams, setSearchParams]);

  const onStartEditComment = useCallback(
    (commentId?: string) => {
      onEditComment.on();
      setEditCommentId(commentId || '');
    },
    [onEditComment]
  );

  const onCancelEditComment = useCallback(() => {
    onEditComment.off();
    setEditCommentId(null);
  }, [onEditComment]);

  const onSaveEditComment = useCallback(
    async (text: string) => {
      const payload = {
        message: text,
      };
      if (editCommentId) {
        await onUpdateDiscussionComment(editCommentId, payload);
        return discussionsRefetch().then(() => {
          onCancelEditComment();
        });
      }
    },
    [discussionsRefetch, editCommentId, onCancelEditComment]
  );

  const onRemoveComment = useCallback(async () => {
    if (editCommentId) {
      await onDeleteDiscussionComment(editCommentId);
      await discussionsRefetch().then(() => {
        setEditCommentId(null);
      });
    }
  }, [discussionsRefetch, editCommentId]);

  const onOpenRemoveDiscussionModal = useCallback(() => {
    modalService.openConfirmModal({
      title: common.t<string>('pages.task.modal.removeDis.title'),
      text: common.t<string>('pages.task.modal.removeDis.text'),
      labelCancel: common.t<string>('pages.task.modal.removeDis.cancel'),
      labelConfirm: common.t<string>('pages.task.modal.removeDis.confirm'),
      onConfirm: onRemoveDiscussion,
    });
  }, [common, onRemoveDiscussion]);

  const onOpenRemoveCommentModal = useCallback(
    (commentId?: string) => {
      setEditCommentId(commentId || '');
      modalService.openConfirmModal({
        title: common.t<string>('pages.task.modal.removeComment.title'),
        labelCancel: common.t<string>('pages.task.modal.removeComment.cancel'),
        labelConfirm: common.t<string>('pages.task.modal.removeComment.confirm'),
        onConfirm: onRemoveComment,
      });
    },
    [common, onRemoveComment]
  );

  return {
    anchor,
    hovered,
    selected,
    editDis,
    editComment,
    editCommentId,
    onEditDis,
    onClickCard,
    onCancelEditDis,
    onClickLink,
    onAddComment,
    onCloseDiscussion,
    onResumeDiscussion,
    onCancelEditComment,
    onSaveEditDis,
    onStartEditComment,
    onSaveEditComment,
    onOpenRemoveDiscussionModal,
    onOpenRemoveCommentModal,
  };
}

export default useDiscussionControl;
