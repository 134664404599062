import { createAction } from '@reduxjs/toolkit';
import { localStorageService } from 'services/localStorageService';
import jwtDecode from 'jwt-decode';
import { AxiosResponse } from 'axios';
import { IThunkResult } from 'types/store';
import { onLogin, onRefreshToken, onResetPassword, onSignup } from 'api/requests/auth';
import { isExpiredToken } from 'utils';
import history from 'router/history/history';
import { PATH_NAMES } from 'router/constants';

import { DEV_API_URL } from '../../constants';
import store from '../store';

import { IAuthData, IAuthInfo, IResetPasswordPayload, LoginPayload, SignupPayload } from './interfaces';
import AuthTypes from './types';

export const setAuthDataAction = createAction<IAuthData>(AuthTypes.SET_AUTH_DATA);
export const setMeDataAction = createAction<any>(AuthTypes.ME);
export const logoutAction = createAction(AuthTypes.LOGOUT);

const loginCobRequest = async (email?: string, password?: string) => {
  const URL =
    process.env.NODE_ENV === 'development' ? `${DEV_API_URL}/account/api/` : `${window.location.origin}/account/api/`;

  await fetch(`${URL}token/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  })
    .then((response) => response.json())
    .then((response: IAuthInfo) => {
      window.open(`https://cob.coursefactory.net/login?log=${response.refresh}`, '_self');
    });
};

export const loginRequest = (payload: LoginPayload): IThunkResult<Promise<any>> => {
  return async (dispatch) => {
    await onLogin(payload)
      .then((response: IAuthInfo | any) => {
        if (response?.access) {
          localStorageService.setAuthData(response);
          const data = jwtDecode<IAuthData>(response.access);
          dispatch(setAuthDataAction(data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const signupRequest = (
  payload: SignupPayload,
  formData: FormData,
  companyType: string | null
): IThunkResult<void> => {
  return async (dispatch) => {
    await onSignup(formData).then(() => {
      const loginPayload: LoginPayload = { email: payload?.email, password: payload?.password };
      if (companyType === 'outline_builder') {
        loginCobRequest(payload?.email, payload?.password);
      } else {
        dispatch(loginRequest(loginPayload));
      }
    });
  };
};

export const resetPasswordRequest = (payload: IResetPasswordPayload): IThunkResult<void> => {
  return async (dispatch) => {
    await onResetPassword(payload);
  };
};

export const checkToken = (): IThunkResult<void> => {
  return async (dispatch) => {
    const authData = localStorageService.getAuthData();
    if (authData) {
      const decode = jwtDecode<IAuthData>(authData?.access);
      const isTokenExpired = isExpiredToken(decode?.exp);
      if (isTokenExpired) {
        await onRefreshToken({ refresh: authData.refresh })
          .then((response: IAuthInfo) => {
            if (response?.access) {
              const { access, refresh } = response;
              const newAuthData = {
                access,
                refresh,
              };
              const data = jwtDecode<IAuthData>(access);
              dispatch(setAuthDataAction(data));
              localStorageService.setAuthData(newAuthData);
            }
          })
          .catch(() => {
            localStorageService.removeAuthData();
            history.push(PATH_NAMES.auth.login);
          });
      } else {
        const data = jwtDecode<IAuthData>(authData.access);
        dispatch(setAuthDataAction(data));
      }
    } else {
      if (
        history.location.pathname !== PATH_NAMES.auth.signup &&
        history.location.pathname !== PATH_NAMES.auth.resetPassword
      ) {
        history.push(PATH_NAMES.auth.login);
      }
    }
  };
};

export const logoutRequest = (): IThunkResult<void> => {
  return (dispatch) => {
    localStorageService.removeAuthData();
    dispatch(logoutAction());
    history.push(PATH_NAMES.auth.login);
  };
};
