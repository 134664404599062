import { useQuery } from '@apollo/client';

import { GET_COMPANY_STATE_LISTS } from '../../queries/getCompanyStateLists';

export type StateItem = {
  id: string;
  name: string;
  button_name: string;
  state_type: string;
  order: number;
  is_new_iteration_initiation: boolean;
  is_require_discuss_message: boolean;
  is_could_close_without_complete_issues: boolean;
};

export type CompanyStateListItem = {
  id: string;
  pk: string;
  name: string;
  updated_at: string;
  last_version: {
    id: string;
    states: StateItem[];
  };
  actual_version: {
    id: string;
    states: StateItem[];
  };
};

type Data = {
  company_state_lists: {
    edges: {
      node: CompanyStateListItem;
    }[];
  };
};

export const useCompanyStateLists = (companyId?: string) => {
  const { data, refetch } = useQuery<Data>(GET_COMPANY_STATE_LISTS, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const companyStateList = data?.company_state_lists?.edges?.map((el) => el.node);
  const companyStateListForSelect = companyStateList?.map((el) => {
    return {
      id: el?.actual_version?.id,
      label: el?.name,
      value: el?.actual_version?.id,
    };
  });
  return {
    companyStateList,
    companyStateListForSelect,
    refetch,
  };
};
