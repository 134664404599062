import React, { useCallback, useEffect, useMemo, useState } from 'react';

import HistoryDrawer from 'pages/components/historyDrawer';
import TaskHeader from 'pages/task/components/taskHeader';
import UniversalTaskInstruction from 'pages/task/components/instruction';
import CheckList from 'pages/task/components/checkList';
import { useParams, useSearchParams } from 'react-router-dom';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { connect, ConnectedProps } from 'react-redux';
import { BusinessRoleEnum, CompanyUserItem, StateType } from 'types/entities';
import icon from 'assets/image/empty-img-simple.svg';
import SkeletonTabs from 'pages/task/components/skeleton/SkeletonTabs';
import SkeletonEditor from 'pages/task/components/skeleton/SkeletonEditor';
import { changeRollbackPossibility } from 'api/requests/projectTask';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { cx } from 'utils';
import WordCounterView from 'pages/task/components/wordCounterView';

import { TabPane, Tabs } from 'components/tabs';
import TabPaneSkeleton from 'components/tabs/TabPaneSkeleton';
import Block from 'components/block';
import Editor from 'components/editor';
import Comments from 'components/comments';
import TabTitleWithBadge from 'components/tabs/TabTitleWithBadge';
import MaterialItem from 'components/materialItem/Materials';
import Button from 'components/button/Button';
import { ButtonTypesEnum } from 'components/button/types';

import { checkUserBusinessRole } from 'utils/roles';

import { changeTaskStatusRequest, rollbackTaskStatusRequest } from 'store/projects/actions';

import useArtefact from 'hooks/queries/useArtefact';
import useProjectTask from 'hooks/queries/useProjectTask';
import useProjectUser from 'hooks/queries/useProjectUser';
import useCommonContext from 'hooks/useCommonContext';
import useTaskFlowSteps from 'hooks/queries/useTaskFlowSteps';
import useTaskChangeHistory from 'hooks/queries/useTaskChangeHistory';
import useProjectStates from 'hooks/queries/useProjectStates';
import useDocumentDiscussions from 'hooks/queries/useDocumentDiscussions';
import useMaterials from 'hooks/queries/useMaterials';

import Empty from '../../components/empty';
import InlineTool from '../../components/inlineTool';

import useEditor from './hooks/useEditor';
import useWordCount from './hooks/useWordCount';

import './styles.scss';

function TaskTextPage({ onRollbackTaskStatus }: PropsFromRedux) {
  const [selected, setSelected] = useState<string[]>([]);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [panel, setPanel] = useState<boolean>(true);

  const common = useCommonContext();

  const onTogglePanel = useCallback(() => setPanel(!panel), [panel]);
  const onOpenHistory = useCallback(() => setShowHistory(true), [setShowHistory]);
  const onCloseHistory = useCallback(() => setShowHistory(false), [setShowHistory]);

  const { artefactId } = useParams();
  const { instruction, checkList } = useArtefact(Number(artefactId));

  const [searchParams, setSearchParams] = useSearchParams();

  const version = searchParams.get('version');

  // const {
  //   projectId,
  //   companyId,
  //   projectTask,
  //   currentArtefact,
  //   artefactFile,
  //   refetchProjectTask,
  //   currentProject,
  //   loadingProjectTask,
  // } = useProjectTask(version, 'network-only');

  const {
    projectTask,
    currentArtefact,
    currentProject,
    projectId,
    companyId,
    refetchProjectTask,
    loadingProjectTask,
    artefactFile,
    readOnly,
    initializeEditor,
    onChangeEditor,
    initBlocks,
    discussionId,
    onCancelTempDiscussion,
    discussionsLoading,
    onAddDiscussion,
    onRemoveMarkerById,
    onReadyEditor,
    blocks,
    editorReady,
    editorCore,
  } = useEditor();

  const { userRole, userRoles, loadingProjectUser } = useProjectUser(common?.userId, projectId);
  const { discussionsActive } = useDocumentDiscussions({
    artefactId: Number(currentArtefact?.id),
  });
  const currentTaskStatusType = projectTask?.project_task?.last_state?.state_type;
  const currentTaskStatusId = Number(projectTask?.project_task?.last_state?.id);
  const { artefactFlowSteps } = useTaskFlowSteps({
    companyArtefactTypeId: Number(currentArtefact?.artefact_type?.id),
    companyStateListVersionId: Number(currentProject?.project_task_state_list_version?.id),
    initialStateId: currentTaskStatusId,
  });

  const { words, charsWithSpace, charsWithoutSpace } = useWordCount(blocks, editorReady);

  const { materials } = useMaterials(Number(artefactId));
  const onToggleCheckbox = useCallback(
    (event: CheckboxChangeEvent) => {
      const itemId = event?.target?.name || '';
      const hasId = !!selected.find((s) => s === itemId);
      const item = checkList?.related_files?.find((el) => el.id === itemId);
      const allChildrenParent = checkList?.related_files
        ?.filter((el) => el?.parent_section?.id === item?.parent_section?.id)
        .map((el) => el.id);

      const isAllChildrenSelected = allChildrenParent?.every((el) => [...selected, itemId]?.includes(el));

      const descendants: string[] =
        checkList?.related_files?.filter((el) => el?.parent_section?.id === itemId).map((el) => el.id) || [];

      if (!hasId) {
        if (item?.parent_section) {
          const p = isAllChildrenSelected ? [item?.parent_section.id, itemId] : [itemId];
          setSelected([...selected, ...p]);
        } else {
          setSelected([...selected, ...descendants, itemId]);
        }
      } else {
        const arr = [...descendants, itemId];
        const temp = selected.filter((el) => !arr.includes(el));

        if (item?.parent_section) {
          const p = temp.filter((t) => t !== item?.parent_section?.id);
          setSelected([...p]);
        } else {
          setSelected([...temp]);
        }
      }
    },
    [checkList?.related_files, selected]
  );

  const { taskChangeHistory, taskChangeHistoryRefetch } = useTaskChangeHistory(projectTask?.project_task?.id);

  const { companyStates } = useProjectStates(projectId);

  const getStatusDisabled = () => {
    if (currentTaskStatusType === StateType.NEW) {
      return blocks?.blocks?.length;
    } else {
      return initBlocks?.blocks?.length;
    }
  };

  const isCurrentExecutorEntity = projectTask?.project_task?.projecttaskassignee_set?.find(
    (assigner) => assigner.is_current_executor && assigner.is_active
  );
  const currentExecutor = projectTask?.project_task?.projecttaskassignee_set?.find(
    (assigner) => assigner.is_current_executor && assigner.is_active
  );
  const isCurrentExecutor =
    Number(isCurrentExecutorEntity?.project_user?.company_user?.user?.id) === Number(common?.userId);
  const isCurrentExecutorMethodist = currentExecutor?.project_user?.roles.some(
    (r) => r.role.role === BusinessRoleEnum.instructionalDesigner
  );
  const isCanRollback = projectTask?.project_task?.project_task_state_history?.is_rollback_possible;

  useEffect(() => {
    if (isCanRollback && isCurrentExecutor) {
      if (projectTask?.id) {
        changeRollbackPossibility(projectTask?.project_task?.id).then(() => refetchProjectTask());
      }
    }
  }, [isCanRollback, isCurrentExecutor, projectTask?.id, projectTask?.project_task?.id, refetchProjectTask]);

  const isMemberOfProject = useMemo(() => {
    if (
      checkUserBusinessRole(common.businessRoles, BusinessRoleEnum.executiveManager) &&
      common.company?.id === companyId
    ) {
      return true;
    }
    return !!currentProject?.active_users?.find(
      (u: CompanyUserItem) => u.company_user.user.id === common?.userId?.toString()
    );
  }, [common.businessRoles, common.company?.id, common?.userId, companyId, currentProject?.active_users]);

  const editorRender = useMemo(() => {
    return (
      <div id="editor-wrap" className="task-text__view-wrap">
        <Editor
          initialize={initializeEditor}
          data={blocks}
          defaultValue={initBlocks}
          onChange={onChangeEditor}
          onReadyEditor={onReadyEditor}
        />
        <InlineTool
          editorReady={editorReady}
          readOnly={readOnly}
          editorCore={editorCore}
          onChangeEditor={onChangeEditor}
        />
        <WordCounterView words={words} charsWithSpace={charsWithSpace} charsWithoutSpace={charsWithoutSpace} />
      </div>
    );
  }, [
    blocks,
    charsWithSpace,
    charsWithoutSpace,
    editorCore,
    editorReady,
    initBlocks,
    initializeEditor,
    onChangeEditor,
    onReadyEditor,
    readOnly,
    words,
  ]);

  const contentRender = useMemo(() => {
    if (loadingProjectTask || loadingProjectUser) {
      return <SkeletonEditor />;
    }
    if (!blocks && !initBlocks && isCurrentExecutor && !isCurrentExecutorMethodist) {
      return editorRender;
    }
    if (!blocks && !initBlocks && isMemberOfProject) {
      return <Empty image={icon} text={common.t<string>('common.textNotWrittenYet')} />;
    }
    if ((blocks || initBlocks) && isMemberOfProject) {
      return editorRender;
    }
    return <Empty image={icon} text={common.t<string>('common.textNotWrittenYet')} />;
  }, [
    blocks,
    common,
    editorRender,
    initBlocks,
    isCurrentExecutor,
    isCurrentExecutorMethodist,
    isMemberOfProject,
    loadingProjectTask,
    loadingProjectUser,
  ]);

  const tabItems = [
    {
      key: '1',
      label: (
        <TabPaneSkeleton loading={loadingProjectTask}>
          <TabTitleWithBadge
            title={common.t<string>('common.comments')}
            counter={discussionsActive?.length}
            showZero={false}
          />
        </TabPaneSkeleton>
      ),
      children:
        loadingProjectTask || discussionsLoading ? (
          <SkeletonTabs />
        ) : (
          <Comments
            discussionId={discussionId}
            onCancelTemp={onCancelTempDiscussion}
            onCreateDiscussion={onAddDiscussion}
            onRemoveMarkerById={onRemoveMarkerById}
          />
        ),
    },
    {
      key: '2',
      label: <TabPaneSkeleton loading={loadingProjectTask}>{common.t<string>('common.instruction')}</TabPaneSkeleton>,
      children: <UniversalTaskInstruction instruction={instruction} />,
    },
    {
      key: '3',
      label: <TabPaneSkeleton loading={loadingProjectTask}>{common.t<string>('common.checkList')}</TabPaneSkeleton>,
      children: (
        <CheckList
          checkList={checkList}
          selected={selected}
          onChangeCheckbox={onToggleCheckbox}
          currentTaskStatus={currentTaskStatusType}
        />
      ),
    },
    {
      key: '4',
      label: <TabPaneSkeleton loading={loadingProjectTask}>{common.t<string>('common.materials')}</TabPaneSkeleton>,
      children: (
        <div className="task-text__materials">
          <MaterialItem materials={materials} />
        </div>
      ),
    },
  ];

  const classNames = cx('task-page task-text', {
    'task-text__panel--closed': !panel,
  });

  return (
    <div className={classNames}>
      <TaskHeader
        loading={loadingProjectTask}
        onOpenHistory={onOpenHistory}
        iterations={currentArtefact?.artefact_versions}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
        hintText="common.writeText"
        isHaveText={getStatusDisabled()}
        onRollbackTaskStatus={onRollbackTaskStatus}
        projectTask={projectTask}
        refetchProjectTask={refetchProjectTask}
        userRole={userRole}
        userRoles={userRoles}
        artefactFlowSteps={artefactFlowSteps}
        artefactFile={artefactFile}
        onTaskHistoryRefetch={taskChangeHistoryRefetch}
      />

      <Block className="task-text__view">{contentRender}</Block>

      <Tabs
        className="task-text__tabs"
        defaultActiveKey="1"
        items={!panel ? [] : tabItems}
        tabBarExtraContent={
          <Button
            size="large"
            className="task-sider__button"
            type={ButtonTypesEnum.text}
            icon={
              panel ? <DoubleRightOutlined style={{ fontSize: 20 }} /> : <DoubleLeftOutlined style={{ fontSize: 20 }} />
            }
            onClick={onTogglePanel}
          />
        }
      />

      <HistoryDrawer
        open={showHistory}
        onClose={onCloseHistory}
        data={taskChangeHistory}
        companyStates={companyStates}
      />
    </div>
  );
}

const connector = connect(() => ({}), {
  onRollbackTaskStatus: rollbackTaskStatusRequest,
});
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(TaskTextPage);
