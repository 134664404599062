import React, { memo } from 'react';

import Empty from 'pages/task/components/empty';

import { Timeline } from 'components/timeline';
import { Drawer, DrawerHeader } from 'components/drawer';

import { AllProjectTaskStateHistory, ProjectStateItem } from 'hooks';

import useCommonContext from 'hooks/useCommonContext';

type HistoryDrawerProps = {
  open: boolean;
  onClose: () => void;
  data?: AllProjectTaskStateHistory[];
  companyStates?: ProjectStateItem[] | null;
};

function HistoryDrawer({ open, onClose, data, companyStates }: HistoryDrawerProps) {
  const common = useCommonContext();
  return (
    <Drawer
      className="history-timeline"
      placement="right"
      width={440}
      open={open}
      onClose={onClose}
      maskClosable={true}
      closable={false}
      title={<DrawerHeader onClose={onClose} title={common.t<string>('pages.task.history')} />}
    >
      {data?.length ? (
        <Timeline className="testclass-historyofchanges-field" data={data} companyStates={companyStates} />
      ) : (
        <Empty text={common.t<string>('pages.task.noHistory')} />
      )}
    </Drawer>
  );
}

export default memo(HistoryDrawer);
