import React, { memo } from 'react';

import { Link, useParams } from 'react-router-dom';
import { cx, getUrlToTask } from 'utils';
import i18next from 'i18next';
import dayjs from 'dayjs';

import StatusLabelFlat from 'components/statusLabelFlat/StatusLabelFlat';

import { ArtefactSidebar } from '../../hooks/useArtefactSidebar';

type SidebarMenuItemProps = {
  item: ArtefactSidebar;
  className?: string;
};

function TaskSidebarMenuItem({ item, className }: SidebarMenuItemProps) {
  const { taskId, learningObjectId = '', iterationId = '' } = useParams();

  const currentArtefact = item?.project_task?.cached_linked_artefact;

  const productionDeadlineDate = currentArtefact?.production_deadline
    ? dayjs(currentArtefact?.production_deadline).locale(i18next.language).format('D MMM')
    : '-';
  const iterationDeadlineDate = currentArtefact?.first_iteration_deadline
    ? dayjs(currentArtefact?.first_iteration_deadline).locale(i18next.language).format('D MMM')
    : '-';

  const name = currentArtefact?.artefact_type?.name;
  const currentProjectTaskId = item?.project_task?.id;
  const artefactType = currentArtefact?.artefact_type.type;

  const classNames = cx('sidebar__menu-item', `testclass-sequence-${artefactType}`, className, {
    'sidebar__menu-item--active testclass-sidebar-pipeline-item-active': taskId === currentProjectTaskId,
  });

  const fileType = currentArtefact?.current_file?.file_type;

  const path = getUrlToTask(
    learningObjectId,
    currentProjectTaskId,
    iterationId,
    currentArtefact?.id,
    artefactType,
    fileType
  );

  return (
    <li className={classNames}>
      <Link className="sidebar__menu-item-link testclass-sidebar-pipeline-item-link" to={path}>
        <StatusLabelFlat type={item.project_task.last_state?.state_type} onlyIco />
        <div className="sidebar__menu-item-name testclass-sidebar-pipeline-item-name mr_20">
          <span className="sidebar__menu-item-name-container testclass-sidebar-pipeline-item-name-container">
            {name}
          </span>
        </div>
        <span className="sidebar__menu-item-date-iteration testclass-sidebar-pipeline-item-iteration-date">
          {iterationDeadlineDate}
        </span>
        <span className="sidebar__menu-item-date testclass-sidebar-pipeline-item-date">{productionDeadlineDate}</span>
      </Link>
    </li>
  );
}

export default memo(TaskSidebarMenuItem);
