import { useQuery } from '@apollo/client';

import { GET_FINAL_ARTEFACT_PIPELINE } from '../../queries/getFinalArtefactPipeline';

import { FinalArtefactPipelineData } from './useLazyFinalArtefactPipeline';

function useTaskChangeHistory(projectTaskId?: string | null) {
  const { data, loading, refetch } = useQuery<FinalArtefactPipelineData>(GET_FINAL_ARTEFACT_PIPELINE, {
    variables: {
      project_task_id: projectTaskId,
    },
    skip: !projectTaskId,
    fetchPolicy: 'network-only',
  });

  return {
    taskChangeHistory: data?.final_artefact_pipeline?.all_project_task_state_history,
    taskChangeHistoryLoading: loading,
    taskChangeHistoryRefetch: refetch,
  };
}

export default useTaskChangeHistory;
