import React from 'react';

import { Skeleton } from 'antd';

const styles = {
  title: { width: '100%', marginBottom: '40px' },
  content: { width: '183px' },
  contentSmall: { width: '54px' },
};

function TaskSiderInfoSkeleton() {
  return (
    <>
      <Skeleton.Button active style={styles.title} />
      <Skeleton active />
      <Skeleton active />
    </>
  );
}

function TaskSiderContentSkeleton() {
  return (
    <div className="flex-row flex-justify-content--space-between mr_24 ml_24 mt_24">
      <div className="flex-col">
        <Skeleton style={styles.content} active paragraph={false} avatar={false} />
        <Skeleton style={styles.content} active paragraph={false} avatar={false} />
        <Skeleton style={styles.content} active paragraph={false} avatar={false} />
        <Skeleton style={styles.content} active paragraph={false} avatar={false} />
        <Skeleton style={styles.content} active paragraph={false} avatar={false} />
      </div>
      <div className="flex-col">
        <Skeleton style={styles.contentSmall} active paragraph={false} avatar={false} />
        <Skeleton style={styles.contentSmall} active paragraph={false} avatar={false} />
        <Skeleton style={styles.contentSmall} active paragraph={false} avatar={false} />
        <Skeleton style={styles.contentSmall} active paragraph={false} avatar={false} />
        <Skeleton style={styles.contentSmall} active paragraph={false} avatar={false} />
      </div>
    </div>
  );
}

export { TaskSiderInfoSkeleton, TaskSiderContentSkeleton };
