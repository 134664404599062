import { useCallback, useEffect, useMemo } from 'react';

import { Form, message } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { NewCompanyResponse, onAddUserToCompany, onCreateCompany, onUpdateUserToCompany } from 'api/requests/company';
import { UserExtended } from 'types/entities';
import { arrStrToArrNum, validateEmail } from 'utils';
import { isEmpty, isEqual } from 'lodash';

import useTabsControl from 'hooks/components/useTabsControl';
import useBoolean from 'hooks/useBoolean';

import useCommonContext from '../../../hooks/useCommonContext';

export type UseAddFormOutput = {
  loading: boolean;
  form: FormInstance;
  disableSubmit: boolean;
  tab?: string;
  onChangeTab?: (v: string) => void;
  onSubmit: () => void;
  onUpdate: () => void;
  onResetFields: () => void;
  handleFormChange: () => void;
};

type AddFormState = {
  name: string;
  email: string;
  roles: string[];
  company: string;
  new_company: string;
  user?: UserExtended;
};

export default function useAddForm(
  user?: UserExtended | null,
  onFetchUser?: () => Promise<any>,
  onEditOff?: () => void,
  visible?: boolean,
  skipInit?: boolean
): UseAddFormOutput {
  const [loading, onLoading] = useBoolean(false);
  const [disabled, onDisabled] = useBoolean(true);
  const [tab, onChangeTab] = useTabsControl({ defaultActiveKey: 'existing' });
  const common = useCommonContext();
  const [form] = Form.useForm<AddFormState>();

  const organization = Form.useWatch('company', form);
  const newOrganization = Form.useWatch('new_company', form);
  const name = Form.useWatch('name', form);
  const email = Form.useWatch('email', form);
  const roles = Form.useWatch('roles', form);

  const disableSubmit =
    tab === 'existing'
      ? !organization || !validateEmail(email) || isEmpty(roles) || !name
      : !newOrganization || !validateEmail(email) || isEmpty(roles) || !name;

  const defaultValues = useMemo(() => {
    if (tab === 'existing' && visible) {
      return {
        company: user ? user?.company?.id : common?.company?.id.toString(),
        name: user?.user.name,
        email: user?.user.email,
        roles: user?.business_roles ? user?.business_roles.map((el) => el.id) : [],
      };
    } else {
      return {
        new_company: undefined,
        name: undefined,
        email: undefined,
        roles: [],
      };
    }
  }, [common?.company?.id, tab, user, visible]);

  useEffect(() => {
    if (!skipInit) {
      form.setFieldsValue(defaultValues);
    }
  }, [defaultValues, form, skipInit]);

  const handleFormChange = useCallback(() => {
    const fields = form.getFieldsValue();

    onDisabled.setValue(isEqual(defaultValues, fields));
  }, [defaultValues, form, onDisabled]);

  const onSubmit = async () => {
    onLoading.on();

    const values = form.getFieldsValue();

    const userPayload = {
      name: values.name,
      email: values.email,
      business_role_ids: arrStrToArrNum(values.roles),
    };

    if (tab === 'new') {
      const companyPayload = {
        name: values.new_company,
      };

      await onCreateCompany(companyPayload)
        .then((res: NewCompanyResponse) => {
          onAddUserToCompany({ companyId: res.id, payload: userPayload })
            .then(() => onLoading.off())
            .catch(() => onLoading.off());
        })
        .catch(() => onLoading.off());
    } else {
      await onAddUserToCompany({
        companyId: values.company || common.company?.id?.toString() || '',
        payload: userPayload,
      })
        .then(() => onLoading.off())
        .catch(() => onLoading.off());
    }
  };

  const onUpdate = async () => {
    onLoading.on();

    const values = form.getFieldsValue();

    const payload = {
      name: values.name,
      email: values.email,
      business_role_ids: arrStrToArrNum(values.roles),
    };

    await onUpdateUserToCompany({ companyId: user?.company?.id || '', userId: user?.user.id || '', payload })
      .then(() => {
        onFetchUser?.().then(() => {
          onLoading.off();
          onEditOff?.();
          message.success(common.t<string>('pages.users.dataHasBeenChanged'), 2.5);
        });
      })
      .catch(() => onLoading.off());
  };

  return {
    loading,
    form,
    disableSubmit: disabled || disableSubmit,
    tab,
    handleFormChange,
    onChangeTab,
    onSubmit,
    onUpdate,
    onResetFields: form.resetFields,
  };
}
