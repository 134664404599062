import { gql } from '@apollo/client';

export const GET_PROJECT = gql`
  query Project($id: Int) {
    project(id: $id) {
      id
      pk
      name
      description
      timezone
      production_progress
      production_start_date
      production_finish_date
      metrics_missing_deadlines
      metrics_completed_tasks_plan
      metrics_completed_tasks_fact
      metrics_maximium_artefact_delay
      metrics_changed_deadlines_exist
      metrics_artefact_delays_exist
      metrics_project_changed_deadlines_exist
      metrics_project_health
      metrics_total_tasks
      metrics_total_completed_tasks
      metrics_author_total_tasks
      metrics_author_total_completed_tasks
      metrics_production_executor_total_tasks
      metrics_production_executor_total_completed_tasks
      metrics_total_elements
      metrics_completed_elements
      learning_objects_exist
      separate_tasks_progress
      current_version {
        id
        number
        json_content
        is_current
        updated_at
      }
      project_group {
        id
        name
      }
      company {
        name
        slug
        id
      }
      state {
        id
        name
        button_name
        state_type
        icon_image
        order
      }
      phase_state {
        id
        name
        button_name
        state_type
        icon_image
        order
      }
      project_task_state_list_version {
        id
      }
      artefact_issue_state_list_version {
        id
      }
      project_state_state_list_version {
        id
      }
      project_phase_state_list_version {
        id
      }
    }
  }
`;
