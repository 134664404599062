import React, { memo } from 'react';

import { cx } from 'utils';
import TruncateMarkup from 'react-truncate-markup';

import SvgInline from '../svgInline';

import { StatusLabelFlatProps } from './types';

import './styles.scss';

function StatusLabelFlat({ type, width, className, onlyIco, name, icon }: StatusLabelFlatProps) {
  if (!type) {
    return null;
  }

  const classNames = cx('status-flat', className, {
    [`status-flat--${type.toLowerCase()}`]: !onlyIco ? type : undefined,
    [`testclass-status-flat--${type.toLowerCase()}`]: !onlyIco ? type : undefined,
  });
  const dotClassNames = cx('dot', className, {
    [`dot--${type.toLowerCase()}`]: type,
  });
  return (
    <div className={classNames}>
      {icon ? <SvgInline url={icon} /> : <span className={dotClassNames}></span>}
      {!onlyIco ? (
        <>
          {width ? (
            <TruncateMarkup lines={1}>
              <span style={{ width: width }} className="status-flat__value">
                {name}
              </span>
            </TruncateMarkup>
          ) : (
            <span style={{ width: width }} className="status-flat__value">
              {name}
            </span>
          )}
        </>
      ) : null}
    </div>
  );
}

export default memo(StatusLabelFlat);
