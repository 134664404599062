import React from 'react';

import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PATH_NAMES } from 'router/constants';

import { TextFieldTypeEnum } from 'components/textField/types';
import { ButtonTypesEnum } from 'components/button/types';

import { loginRequest } from 'store/auth/actions';
import { LoginPayload } from 'store/auth/interfaces';

import useBoolean from 'hooks/useBoolean';

import AuthCard from '../../../components/authCard';
import TextField from '../../../../components/textField';
import Button from '../../../../components/button';

import '../../styles.scss';
import './styles.scss';

const LoginScreen: React.FC<PropsFromRedux> = ({ onLoginRequest }: PropsFromRedux) => {
  const [loading, onLoading] = useBoolean(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const form = Form.useFormInstance();

  const onSubmit = async (values: LoginPayload) => {
    onLoading.on();
    await onLoginRequest(values).finally(() => {
      onLoading.off();
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log(errorInfo);
  };

  const onNavigateToResetPassword = () => navigate(PATH_NAMES.auth.resetPassword);

  return (
    <AuthCard className="login-page">
      <Form
        form={form}
        className="login-page__form"
        onFinish={onSubmit}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <span className="login-page__title mb_16">{t('pages.login.title')}</span>
        <TextField
          size="xl"
          name="email"
          className="mb_8"
          disabled={loading}
          label={t('form.field.email.label')}
          placeholder={t('form.field.email.placeholder')}
          rules={[{ required: true, type: 'email', message: t('errors.required.email') }]}
        />
        <TextField
          size="xl"
          className="mb_8"
          disabled={loading}
          name="password"
          label={t('form.field.password.label')}
          placeholder={t('form.field.password.placeholder')}
          type={TextFieldTypeEnum.password}
          rules={[
            { required: true, message: t('errors.required.password') },
            { min: 4, message: t('errors.required.passwordMinLength') },
          ]}
        />
        <Button className="button-login" fullWidth htmlType="submit" size="xl" loading={loading}>
          {t('pages.login.button')}
        </Button>
        <Button onClick={onNavigateToResetPassword} className="button-reset" type={ButtonTypesEnum.link} fullWidth>
          {t('pages.login.buttonResetPassword')}
        </Button>
      </Form>
    </AuthCard>
  );
};

const connector = connect(null, {
  onLoginRequest: loginRequest,
});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LoginScreen);
