import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import moment from 'moment';

import { GET_ARTEFACT_DEADLINES_CHANGED } from '../../queries/getArtefactDeadlinesChanged';
import { UserBase } from '../../types/entities/user';
import { CompanyArtefactType } from '../../types';

export type ArtefactDeadlinesChangedItem = {
  id: string;
  created_at: string;
  created_date: string;
  creator: UserBase;
  deadline_new: string;
  artefact?: {
    id: string;
    artefact_type: {
      name: string;
      type: CompanyArtefactType.type;
      icon_image: string;
    };
    artefact_versions: {
      id: string;
      file: {
        file_type: string;
      };
    }[];
    artefact_iteration_count: string;
    cached_task: {
      id: string;
      artefact_production_sequence: {
        id: string;
        project_learning_object: {
          id: string;
          name: string;
          parent: {
            human_readable_number: string;
          };
        };
      };
    };
  };
  separateTask: {
    id: string;
    project_task: {
      id: string;
      name: string;
    };
    task_type: {
      id: string;
      name: string;
      icon_image: string;
    };
  } | null;
};

type ArtefactDeadlinesChangedItemNode = {
  node: ArtefactDeadlinesChangedItem;
};

export type ArtefactTimelineItem = {
  [key in string]: {
    creator: UserBase;
    items: ArtefactDeadlinesChangedItem[];
  };
};

function useArtefactDeadlinesChanged(
  projectId: number,
  artefactId?: number,
  deadlineType?: 'first_iteration_deadline' | 'artefact_production_deadline',
  fetchPolicy?: WatchQueryFetchPolicy
) {
  const { data } = useQuery(GET_ARTEFACT_DEADLINES_CHANGED, {
    variables: {
      project_id: projectId,
    },
    fetchPolicy: fetchPolicy || 'network-only',
    skip: !projectId,
  });

  const converted: ArtefactDeadlinesChangedItem[] = data?.artefact_deadlines_changed.edges?.map(
    (item: ArtefactDeadlinesChangedItemNode) => item.node
  );

  const timelineData = useMemo(() => {
    const temp: ArtefactTimelineItem = {};
    converted?.forEach((item: ArtefactDeadlinesChangedItem) => {
      const key = `${item.created_date}-user-${item?.creator?.id}`;
      if (!temp[key]) {
        temp[key] = {
          creator: item.creator,
          items: [item],
        };
      } else {
        if (temp[key]?.creator?.id === item?.creator?.id) {
          temp[key] = {
            ...temp[key],
            items: [...temp[key].items, item],
          };
        } else {
          temp[key] = {
            creator: item.creator,
            items: [item],
          };
        }
      }
    });
    return temp;
  }, [converted]);

  // const unique = converted?.filter(
  //   (a: any, i: number) => converted.findIndex((s: any) => s.artefact.id === a.artefact.id) === i
  // );
  //
  // console.log(unique);
  return useMemo(() => {
    return {
      timelineData,
    };
  }, [timelineData]);
}

export default useArtefactDeadlinesChanged;
