import React from 'react';

import { Outlet } from 'react-router-dom';

import '../styles.scss';

function AuthLayout() {
  return (
    <div className="layout layout--auth">
      <Outlet />
    </div>
  );
}

export default AuthLayout;
