import React, { memo } from 'react';

import { TaskSiderContentSkeleton } from 'layout/sidebar/TaskSiderSkeleton';

import { ArtefactProductionSequence } from '../../types';
import { ArtefactSidebar } from '../../hooks/useArtefactSidebar';

import TaskSidebarMenuItem from './TaskSidebarMenuItem';

type TaskSidebarMenuProps = {
  artefacts?: ArtefactSidebar[];
  loading: boolean;
};
function TaskSidebarMenu({ artefacts, loading }: TaskSidebarMenuProps) {
  if (loading) {
    return <TaskSiderContentSkeleton />;
  }

  if (!artefacts) {
    return null;
  }
  return (
    <ul className="sidebar__info-menu">
      {artefacts?.map((el) => {
        if (el.is_turn_off) {
          return null;
        }
        return <TaskSidebarMenuItem key={el.id} item={el} />;
      })}
    </ul>
  );
}

export default memo(TaskSidebarMenu);
