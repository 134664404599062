import React, { memo } from 'react';

import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { UserExtended } from 'types/entities';

import UserItemInfo from 'components/userItemInfo';

type MultipleChoiceProps = {
  data: UserExtended[] | null;
  userList?: UserExtended[];
  onChangeMultiple: (checkedValue: Array<CheckboxValueType>) => void;
};

function MultipleChoice({ data, userList, onChangeMultiple }: MultipleChoiceProps) {
  const options = userList?.map((user) => {
    return {
      label: <UserItemInfo user={user} />,
      value: user.pk,
    };
  });

  const value = data?.map((el) => el.pk);

  return (
    <div className="select-user-multiple">
      <Checkbox.Group
        defaultValue={value}
        className="ccm-checkbox-group"
        options={options}
        onChange={onChangeMultiple}
      />
    </div>
  );
}

export default memo(MultipleChoice);
