const importMimeType: string[] = [
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

type CheckMimeTypePresentationResult = {
  isAllowed?: boolean;
};

export const checkMimeTypeImport = (data: File): CheckMimeTypePresentationResult => {
  return {
    isAllowed: data?.type ? importMimeType.includes(data?.type) : false,
  };
};
