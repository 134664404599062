import React from 'react';

import { cx } from 'utils';
import { Form } from 'antd';
import { noop } from 'lodash';

import HeaderModal from 'components/modal/components/header';

import TextField from '../../components/textField';
import FooterModal from '../../components/modal/components/footer';
import { onCompanyState, onCompanyStateVersion } from '../../api/requests/companyState';
import useBoolean from '../../hooks/useBoolean';

type Props = {
  companyId?: string;
  onCancel: () => void;
  refetch: () => Promise<any>;
};

type FormValues = {
  name: string;
};

const AddNewStatusList = ({ companyId, onCancel, refetch }: Props) => {
  const [loading, onLoading] = useBoolean(false);
  const onSubmit = async (values: FormValues) => {
    onLoading.on();
    if (companyId) {
      const payload = {
        ...values,
        company_id: companyId,
        last_states: [],
      };
      await onCompanyState(payload).then((res: any) => {
        if (res?.id) {
          onCompanyStateVersion(res?.id).then(() => {
            refetch();
            onCancel();
            onLoading.off();
          });
        }
      });
    }
  };

  const classNames = cx('ccm-modal--wrapper');
  return (
    <div className={classNames}>
      <HeaderModal isSmallTitle title="Add new status list" />
      <Form disabled={loading} name="new-list" onFinish={onSubmit}>
        <TextField name="name" size="large" placeholder="List name" />
        <FooterModal
          loading={loading}
          labelCancel="Cancel"
          labelSubmit="Save"
          onCancel={onCancel}
          onSubmit={noop}
          isForm
        />
      </Form>
    </div>
  );
};

export default AddNewStatusList;
