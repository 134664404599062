import { useEffect, useState } from 'react';

function useOnScreen(node: any) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

  useEffect(() => {
    if (node) {
      observer.observe(node);
    }
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [observer, node]);

  return isIntersecting;
}

export default useOnScreen;
