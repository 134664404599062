import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Form, message } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { RcFile } from 'antd/es/upload';
import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';

import StatusLabelFlat from 'components/statusLabelFlat/StatusLabelFlat';

import useCommonContext from 'hooks/useCommonContext';

import { CcmUserState } from '../../schemas/types';
import { onUpdateUserToCompanyFormData } from '../../api/requests/company';
import { arrStrToArrNum, fileToDataUri } from '../../utils';
import useBoolean from '../../hooks/useBoolean';
import useTimezones from '../../hooks/queries/useTimezones';
import { setMeDataAction } from '../../store/auth/actions';
import Block from '../../components/block';

import AvatarBlock from './components/avatarBlock';
import AccountForm from './components/accountForm';

import './styles.scss';

function AccountPage() {
  const common = useCommonContext();
  const dispatch = useDispatch();
  const { timezones } = useTimezones();
  const [form] = Form.useForm();
  const [loading, onLoading] = useBoolean(false);
  const [file, setFile] = useState<RcFile | undefined>(undefined);
  const [dataUri, setDataUri] = useState<any>(null);
  const [disabled, onDisabled] = useBoolean(true);

  const defaultValues = useMemo(() => {
    return {
      name: common.user?.name || `${common.user?.first_name} ${common.user?.last_name}`,
      timezone: common.user?.timezone,
    };
  }, [common.user?.first_name, common.user?.last_name, common.user?.name, common.user?.timezone]);

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  useEffect(() => {
    if (!file) {
      setDataUri(common?.user?.icon_image);
    }
  }, [file, common?.user?.icon_image]);

  const handleFormChange = useCallback(() => {
    const fields = form.getFieldsValue();
    onDisabled.setValue(isEqual(defaultValues, fields) || fields?.name === '');
  }, [defaultValues, form, onDisabled]);

  const resetToDefault = useCallback(() => {
    form.setFieldsValue(defaultValues);
    onDisabled.on();
  }, [defaultValues, form, onDisabled]);

  const onChangeUpload = (info: UploadChangeParam<UploadFile>) => {
    const f = info.file.originFileObj;
    setFile(f);
    if (f) {
      fileToDataUri(f).then((dataUrl) => {
        setDataUri(dataUrl);
        onDisabled.off();
      });
    }
  };

  const onUpdate = async () => {
    onLoading.on();

    const values = form.getFieldsValue();
    const formData = new FormData();
    if (file) {
      formData.append('icon_image', file as Blob);
    }
    formData.append('name', values.name || common?.user?.name);
    if (common?.user?.email) {
      formData.append('email', common?.user?.email);
    }
    if (values.timezone) {
      formData.append('timezone', values.timezone);
    }
    if (arrStrToArrNum(values.roles)?.length) {
      for (let i = 0; i < arrStrToArrNum(values.roles)?.length; i++) {
        formData.append('business_role_ids', values.roles[i]);
      }
    } else if (common?.businessRoleIds?.length) {
      for (let i = 0; i < common?.businessRoleIds?.length; i++) {
        formData.append('business_role_ids', common?.businessRoleIds?.[i].toString());
      }
    }

    await onUpdateUserToCompanyFormData(
      {
        companyId: common?.company?.id || '',
        userId: common?.userId?.toString() || '',
      },
      formData
    )
      .then((response) => {
        onLoading.off();
        onDisabled.on();
        dispatch(
          setMeDataAction({
            ...common?.user,
            name: response?.name,
            timezone: response?.timezone,
            icon_image: response?.icon_image,
          })
        );
        message.success(common.t<string>('pages.users.dataHasBeenChanged'), 2.5);
      })
      .catch(() => {
        onDisabled.on();
        onLoading.off();
      });
  };

  return (
    <div className="account-page">
      <div className="account-page-header">
        <span className="account-page-header__title">{common.t<string>('pages.account.title')}</span>
      </div>
      <Block hidden={!common?.user} className="account-page__wrap">
        <AvatarBlock onChangeUpload={onChangeUpload} loading={loading} file={dataUri} />
        <StatusLabelFlat
          type={CcmUserState.Registered}
          name={common.t(`statuses.${CcmUserState.Registered.toLocaleLowerCase()}`)}
        />
        <AccountForm
          resetToDefault={resetToDefault}
          form={form}
          handleFormChange={handleFormChange}
          loading={loading}
          onUpdate={onUpdate}
          timezones={timezones}
          disabled={disabled}
        />
      </Block>
    </div>
  );
}

export default AccountPage;
