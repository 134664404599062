/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AdminCompanyLearningObjectType = {
    readonly id?: number;
    name: string;
    type: AdminCompanyLearningObjectType.type;
    readonly icon_image?: string;
    order?: number;
};

export namespace AdminCompanyLearningObjectType {

    export enum type {
        VIDEO = 'video',
        TEXT = 'text',
        TASK = 'task',
        UNGRADED_TASK = 'ungraded_task',
    }


}
