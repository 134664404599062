import { gql } from '@apollo/client';

export const GET_COMPANY_STATES_LIST = gql`
  query Company_states($companyId: Int, $statesScope: String, $stateListVersionId: Int) {
    company_states(company_id: $companyId, states_scope: $statesScope, state_list_version_id: $stateListVersionId) {
      id
      name
      state_type
      created_at
      updated_at
      order
      is_could_close_without_complete_issues
    }
  }
`;
