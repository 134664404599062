import React from 'react';

import { Progress } from 'antd';

import withCommonProps from '../../hocs/withCommonProps';
import { TemplateCommonProps } from '../../common/commonPropsProvider';
import { cx } from '../../utils';

import styles from './ProgressLoading.module.scss';

type ProgressLoadingProps = {
  className?: string;
  progress: number | null;
};

function ProgressLoading({ className, progress, common }: ProgressLoadingProps & TemplateCommonProps) {
  if (!progress) {
    return null;
  }

  const classNames = cx(styles.container, className);

  return (
    <div className={classNames}>
      <span className={styles.text}>{common.t<string>('common.loading')}</span>
      <Progress
        percent={progress || 0}
        size="small"
        showInfo={false}
        strokeColor="#1E1E1E"
        strokeWidth={5}
        style={{ width: '100px' }}
      />
    </div>
  );
}

export default withCommonProps(ProgressLoading);
