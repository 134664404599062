import React, { useRef } from 'react';

import { NodeModel } from '@minoru/react-dnd-treeview/dist/types';
import { logDOM } from '@storybook/testing-library';

import Block from 'components/block/Block';
import AddMaterialBlock from 'components/addMaterialBlock/AddMaterialBlock';

import useCommonContext from 'hooks/useCommonContext';

import { CustomData, TreeSettings } from '../../types';
import DocumentFileBlock from '../../../../../../components/documentFileBlock';
import LearningOutcomes from '../../../../subPages/content/components/learningOutcomes';
import useHover from '../../../../../../hooks/useHover';

type AddContentProps = {
  node: NodeModel<CustomData>;
  settings: TreeSettings;
  isEdit: boolean;
  elementHovered: boolean;
};

function AddContent({ node, settings, isEdit, elementHovered }: AddContentProps) {
  const common = useCommonContext();
  const anchor1 = useRef<HTMLDivElement | null>(null);
  const anchor2 = useRef<HTMLDivElement | null>(null);
  const element1Hovered = useHover(anchor1);
  const element2Hovered = useHover(anchor2);
  return (
    <div>
      <Block className="course-tree-item__content-item" hidden={!settings.content || !node.data?.externalUrl}>
        <AddMaterialBlock url={node?.data?.externalUrl} text={node?.data?.learningObjectType?.name} />
      </Block>

      <Block className="course-tree-item__content-item" hidden={!settings.content || !node.data?.documentFile}>
        <DocumentFileBlock documentFile={node.data?.documentFile} />
      </Block>

      <Block className="course-tree-item__content-item" hidden={!settings.content || !node.data?.videoUrl}>
        <video width="640" height="360" src={node.data?.videoUrl} controls />
      </Block>

      <Block className="course-tree-item__image-wrapper" hidden={isEdit || !node?.data?.imageUrl}>
        <img src={node?.data?.imageUrl} alt="image" />
      </Block>

      {/*<Block className="course-tree-item__content-item" hidden={!settings.description || !node.data?.description}>*/}
      {/*  <div></div>*/}
      {/*  <span className="course-tree-item__content-title">{common.t<string>('modals.description')}</span>*/}
      {/*  <span className="course-tree-item__content-value">{node.data?.description}</span>*/}
      {/*</Block>*/}

      <Block
        blockRef={anchor1}
        className={settings.description && node.data?.description ? 'course-tree-item__content-item' : ''}
      >
        {settings.description && node.data?.description && (
          <LearningOutcomes
            title={common.t<string>('modals.description')}
            text={node.data?.description}
            showButton={element1Hovered}
          />
        )}
      </Block>

      <Block
        blockRef={anchor2}
        className={settings.learningOutcomes && node.data?.learningOutcomes ? 'course-tree-item__content-item' : ''}
      >
        {settings.learningOutcomes && node.data?.learningOutcomes && (
          <LearningOutcomes
            title={common.t<string>('modals.learningResult')}
            text={node.data?.learningOutcomes}
            showButton={element2Hovered}
          />
        )}
      </Block>
    </div>
  );
}

export default AddContent;
