import React, { memo } from 'react';

import { cx, getFormatDateByStr } from 'utils';

import Block from 'components/block';

import useCommonContext from 'hooks/useCommonContext';

import Tooltip from '../tooltip';

import './styles.scss';

type DeadlineIndicatorProps = {
  className?: string;
  date?: string;
  delay?: number;
  type: 'warning' | 'error';
};

function DeadlineIndicator({ className, date, delay = 0, type }: DeadlineIndicatorProps) {
  const classNames = cx('deadline-indicator', className, {
    'deadline-indicator--warning': type === 'warning' && delay > 0,
    'deadline-indicator--error': type === 'error' && delay > 0,
  });
  const common = useCommonContext();

  const dateRender = date ? getFormatDateByStr(date, 'D MMM') : '-';

  return delay > 0 ? (
    <Tooltip title={`${common.t<string>('pages.project.tooltips.taskOverdue')} ${delay}`}>
      <div className={classNames}>
        <span className="deadline-indicator__date">{dateRender}</span>
        <span className="deadline-indicator__delay">{`${delay}${common.t<string>('common.shortDay')}`}</span>
      </div>
    </Tooltip>
  ) : (
    <div className={classNames}>
      <span className="deadline-indicator__date">{dateRender}</span>
      <Block hidden={delay === 0}>
        <span className="deadline-indicator__delay">{`${delay}${common.t<string>('common.shortDay')}`}</span>
      </Block>
    </div>
  );
}

export default memo(DeadlineIndicator);
