import { IAction } from 'types/store';
import { localStorageService } from 'services/localStorageService';
import jwtDecode from 'jwt-decode';

import IAuthState, { IAuthData } from 'store/auth/interfaces';
import AuthTypes from 'store/auth/types';

import { isExpiredToken } from '../../utils';

let initData = null;
let isAuth = false;
const authData = localStorageService.getAuthData();

if (authData) {
  const decode = jwtDecode<IAuthData>(authData?.access);
  const isExpired = isExpiredToken(decode?.exp);

  if (isExpired) {
    //localStorageService.removeAuthData();
  } else {
    initData = decode;
    isAuth = isExpiredToken(decode?.exp);
  }
}

const initialState: IAuthState = {
  isAuthenticated: isAuth,
  authData: initData || null,
  errors: {},
  me: null,
};

export default function (state = initialState, action: IAction<AuthTypes>) {
  switch (action.type) {
    case AuthTypes.SET_AUTH_DATA:
      return {
        ...state,
        isAuthenticated: true,
        authData: action.payload,
      };
    case AuthTypes.LOGOUT:
      return {
        isAuthenticated: false,
        authData: null,
      };
    case AuthTypes.ME:
      return {
        ...state,
        me: action.payload,
      };
    default:
      return state;
  }
}
