import React, { memo } from 'react';

import { cx } from '../../utils';

import { TitleVariantEnum } from './types';

import './styles.scss';

const elements = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
};

type TitleProps = {
  variant: TitleVariantEnum;
  children?: React.ReactNode;
  className?: string;
};

function Title({ variant, children, className, ...props }: TitleProps) {
  const classNames = cx('ccm-title', className, {
    [`ccm-title--${variant}`]: variant,
  });
  return React.createElement(elements[variant] || elements.h1, { ...props, className: classNames }, children);
}

Title.defaultProps = {
  level: 'h1',
};

export default memo(Title);
