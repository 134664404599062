import React, { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import useCommonContext from 'hooks/useCommonContext';
import { CompanyStateListItem, useCompanyStateLists } from 'hooks/queries/useCompanyStateLists';

import StateManagementBlock from '../../components/stateManagementBlock';
import ArtefactFlow from '../../components/artefactFlow';

import './styles.scss';

const StatusListEditPage = () => {
  const [selected, setSelected] = useState<CompanyStateListItem | null>(null);
  const { listId } = useParams();
  const { company } = useCommonContext();

  const { companyStateList, refetch } = useCompanyStateLists(company?.id);

  const onSelectList = useCallback(() => {
    const temp = companyStateList?.find((el) => el.pk === listId);
    if (temp) {
      setSelected(temp);
    }
  }, [companyStateList, listId]);

  useEffect(() => {
    onSelectList();
  }, [companyStateList, listId, onSelectList]);

  return (
    <div className="status-list-edit">
      <div className="status-list-edit__left">
        <ArtefactFlow selectedList={selected} />
      </div>
      <div className="status-list-edit__right">
        <div className="status-list-edit__list">
          {companyStateList?.map((el) => {
            return <StateManagementBlock key={el.pk} item={el} isSelected={listId === el.pk} onRefetch={refetch} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default StatusListEditPage;
