import React, { memo, useCallback } from 'react';

import { DefaultOptionType } from 'rc-select/lib/Select';
import Can from 'config/Can';
import usePageFilters from 'pages/hooks/usePageFilters';

import Select from 'components/select';
import Search from 'components/search';

import useCompaniesForInvite from 'hooks/queries/useCompaniesForInvite';
import useCompanyRoles from 'hooks/queries/useCompanyRoles';
import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

function Toolbar() {
  const common = useCommonContext();
  const filters = usePageFilters();
  const { companiesForSelect } = useCompaniesForInvite();
  const { companyRolesForSelect } = useCompanyRoles(common.company?.id.toString());

  const filterOption = useCallback((inputValue: string, option?: DefaultOptionType) => {
    const label = option?.label || '';
    return label?.toString()?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  }, []);

  return (
    <div className="users-toolbar">
      <Can I="manage" a="Users.toolbar.companies">
        <Select
          name="companies"
          noForm
          placeholder={common.t<string>('pages.users.filter.organization')}
          mode="multiple"
          maxTagCount="responsive"
          value={filters.companies}
          options={companiesForSelect}
          onChange={filters.onChangeCompanies}
          filterOption={filterOption}
        />
      </Can>

      <Select
        name="roles"
        noForm
        placeholder={common.t<string>('pages.users.filter.role')}
        mode="multiple"
        maxTagCount="responsive"
        value={filters.roles}
        options={companyRolesForSelect}
        onChange={filters.onChangeRoles}
      />
      <Search
        name="search"
        placeholder={common.t<string>('pages.users.filter.nameEmail')}
        value={filters.textSearch}
        onChange={filters.onChangeText}
        onSearch={filters.onSearch}
      />
    </div>
  );
}

export default memo(Toolbar);
