import React, { memo } from 'react';

import { cx } from '../../utils';

import './styles.scss';

type TablePlanFactTagProps = {
  plan: number;
  fact: number;
  delay: number;
  text?: string | React.ReactNode;
  className?: string;
  flat?: boolean;
};

function PlanFactTag({ className, plan, fact, delay, text, flat }: TablePlanFactTagProps) {
  const classNames = cx('plan-fact-tag', className, {
    'plan-fact-tag--level-1': delay === 0,
    'plan-fact-tag--level-2': delay > 0 && delay < 7,
    'plan-fact-tag--level-3': delay >= 7 && delay < 30,
    'plan-fact-tag--level-4': delay >= 30,
    'plan-fact-tag--flat': flat,
  });

  return <span className={classNames}>{text ? text : `${plan}/${fact}`}</span>;
}

export default memo(PlanFactTag);
