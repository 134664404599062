import React from 'react';

import { Outlet } from 'react-router-dom';

import useCommonContext from '../../hooks/useCommonContext';

const AdminLayout = () => {
  const { user } = useCommonContext();

  return <Outlet />;
};

export default AdminLayout;
