import { useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';

import {
  ArtefactFile,
  ArtefactFileIteration,
  ArtefactListItem,
  ArtefactProductionSequence,
  ArtefactVersion,
  TaskInfoQueryData,
} from '../../pages/task/types';
import { StateType } from '../../types/entities';
import { GET_TASK_INFO } from '../../queries/getTaskInfo';

function useProjectTask(
  version: string | null,
  fetchPolicy?: WatchQueryFetchPolicy,
  onCompleted?: (data: any) => void
) {
  const { taskId, learningObjectId } = useParams();

  //TODO projectuser_set active_users
  const { data, loading, refetch, networkStatus } = useQuery<TaskInfoQueryData>(GET_TASK_INFO, {
    variables: {
      learning_id: learningObjectId,
      project_task_id: taskId,
    },
    onCompleted,
    fetchPolicy: fetchPolicy || 'network-only',
  });

  //Current project task
  const projectTask: ArtefactProductionSequence | undefined = useMemo(() => {
    return data?.artefact_production_sequence?.find((art) => art?.project_task?.id === taskId);
  }, [data?.artefact_production_sequence, taskId]);

  //Current artefact in progress
  const currentArtefact: ArtefactListItem | undefined = useMemo(() => {
    return projectTask?.project_task.artefact_list?.[0];
  }, [projectTask?.project_task.artefact_list]);

  //Current file version
  const artefactFileVersion: ArtefactFileIteration | null = useMemo(() => {
    //Current version
    if (version) {
      const selectedVersion = currentArtefact?.artefact_versions?.find(
        (artefactVersion) => artefactVersion?.number === Number(version)
      );
      return selectedVersion || null;
    } else {
      const currentVersion = currentArtefact?.artefact_versions.find((a) => a.is_current);
      return currentVersion || null;
    }
  }, [currentArtefact, version]);

  //Current file entity
  const artefactFile: ArtefactFile | null = useMemo(() => {
    const artefactVer: ArtefactVersion | undefined =
      projectTask?.project_task.artefact_list?.[0]?.artefact_versions?.find(
        (ver) => ver?.id === artefactFileVersion?.id
      );
    return artefactVer?.file || null;
  }, [artefactFileVersion?.id, projectTask?.project_task.artefact_list]);

  //User's current role in the project
  // const userRole: BusinessRoleEnum | null = useMemo(() => {
  //   // const userRole = projectTask?.project_task?.assignees?.find((role) => role?.is_current_executor)?.role?.role;
  //   return projectTask?.project_task?.assignees?.find((role) => role?.is_current_executor)?.role?.role || null;
  // }, [projectTask?.project_task?.assignees]);

  //User's current task executor in the project
  const isCurrentExecutor: boolean | null = useMemo(() => {
    return projectTask?.project_task?.projecttaskassignee_set?.[0]?.is_current_executor || null;
  }, [projectTask?.project_task?.projecttaskassignee_set]);

  //Project task status
  const taskStatus: StateType | null = useMemo(() => {
    return projectTask?.project_task?.last_state?.state_type || null;
  }, [projectTask?.project_task?.last_state?.state_type]);

  //Project ID
  const projectId: string | null = useMemo(() => {
    return data?.learning_object?.project?.pk || null;
  }, [data?.learning_object?.project?.pk]);
  //Current project
  const currentProject: any | null = useMemo(() => {
    return data?.learning_object?.project || null;
  }, [data?.learning_object?.project]);

  //Company ID
  const companyId: string | null = useMemo(
    () => data?.learning_object?.project?.company?.id || null,
    [data?.learning_object?.project?.company?.id]
  );

  return {
    data,
    projectId,
    companyId,
    projectTask,
    currentArtefact,
    artefactFile,
    artefactFileVersion,
    taskStatus,
    isCurrentExecutor,
    refetchProjectTask: refetch,
    loadingProjectTask: loading,
    currentProject,
    currentArtefactId: projectTask?.project_task.artefact_list?.[0]?.id,
    // artefactFileId:
  };
}

export default useProjectTask;
