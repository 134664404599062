import React, { memo } from 'react';

import { cx, getElementStatus } from 'utils';
import CourseInfoPopover from 'pages/project/components/courseInfoPopover';
import { StateType } from 'types/entities';

import SvgInline from '../svgInline';
import Tooltip from '../tooltip';

import { ElementTypeProps } from './types';

import './styles.scss';

const getColor = (state?: StateType) => {
  switch (state) {
    case StateType.PLANNED:
      return 'grey';
    case StateType.IN_PROGRESS:
      return 'violet';
    case StateType.DONE:
      return 'green';
    default:
      return 'grey';
  }
};

function ElementType({ className, url, objectState, progress = 0, hasTooltip, name, sequences }: ElementTypeProps) {
  const progressColor = getColor(objectState);

  const length = sequences?.length || 0;

  const classNames = cx('ccm-element-type', className, {
    [`ccm-element-type--${progressColor}`]: true,
    [`ccm-element-type--tooltip`]: hasTooltip,
  });

  if (!url) {
    return null;
  }

  return length < 2 ? (
    <Tooltip title={getElementStatus(name, objectState)}>
      <div className={classNames}>
        <SvgInline size={14} url={url} />
      </div>
    </Tooltip>
  ) : (
    <CourseInfoPopover title={name} sequences={sequences}>
      <div className={classNames}>
        <SvgInline size={14} url={url} />
      </div>
    </CourseInfoPopover>
  );
}

export default memo(ElementType);
