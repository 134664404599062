import React, { memo } from 'react';

import { v4 as uuid } from 'uuid';
import { Tooltip as ATooltip } from 'antd';
import { cx } from 'utils';

import { TooltipProps } from './types';

const styles = {
  fontSize: '13px',
  lineHeight: '22px',
  color: '#ffffff',
  zIndex: 3000,
};

function Tooltip({ title, children, ...props }: TooltipProps) {
  const classNames = cx('ccm-tooltip');

  return (
    <ATooltip id={uuid()} className={classNames} title={title} color="#1E1E1E" overlayInnerStyle={styles} {...props}>
      {children}
    </ATooltip>
  );
}

export default memo(Tooltip);
