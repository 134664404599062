import React, { memo } from 'react';

import { BusinessRoleEnum } from 'types/entities';

import AvatarWithInfo from 'components/avatarWithInfo/AvatarWithInfo';

import './styles.scss';

type GanttUserProps = {
  user: {
    id: string;
    name: string;
    icon_image?: string;
    roles: {
      id: string;
      name: string;
      role: BusinessRoleEnum;
    }[];
    projects: {
      id: string;
      name: string;
      production_finish_date: string;
      production_start_date: string;
      production_progress: number;
    }[];
  };
};

function GanttUser({ user }: GanttUserProps) {
  const projectHeight = user.projects.length * 24;
  const gap = (user.projects.length - 1) * 4;

  const containerStyles = {
    height: projectHeight < 64 ? `64px` : `${projectHeight + gap}px`,
  };

  return (
    <div style={containerStyles} key={user.id} className="gantt-user">
      <AvatarWithInfo user={user} role={user.roles?.[0].name} />
      <span className="gantt-user__value">{user?.projects?.length}</span>
    </div>
  );
}

export default memo(GanttUser);
