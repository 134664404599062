import React, { useCallback, useEffect, useRef, useState } from 'react';

import { findLastIndex } from 'lodash';
import ReactPlayer from 'react-player';
import { cx } from 'utils';

import { ProgressState, VideoPlayerProps, VideoPlayerState } from './types';
import ControlPanel from './components/controlPanel';

import './styles.scss';

const initState: VideoPlayerState = {
  pip: false,
  playing: false,
  controls: false,
  light: false,
  volume: 0.8,
  muted: false,
  played: 0,
  loaded: 0,
  duration: 0,
  playbackRate: 1.0,
  loop: false,
  seeking: false,
};

function VideoPlayer({
  url,
  className,
  errors = [],
  selectedIssue,
  durationCallback,
  playedCallback,
  onSelectedIssue,
}: VideoPlayerProps) {
  const [state, setState] = useState(initState);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const playerRef = useRef<ReactPlayer>(null);

  const handleSeekChange = useCallback(
    (value: number) => {
      setState({ ...state, played: value });
      playerRef?.current?.seekTo(value);
    },
    [state]
  );

  useEffect(() => {
    if (selectedIssue?.timestamp_start && state.duration) {
      const value = (selectedIssue.timestamp_start * 100) / state.duration / 100;
      handleSeekChange(value);
    }
    //TODO don't change deps, only selectedIssue
  }, [selectedIssue, state.duration]);

  const handlePlay = () => {
    setState({ ...state, playing: true });
  };

  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing });
  };

  const handleProgress = (s: ProgressState) => {
    //We only want to update time slider if we are not currently seeking
    if (!state?.seeking) {
      setState({ ...state, ...s });
      playedCallback?.(s.played);
    }
  };

  const handleDuration = (duration: number) => {
    setState({ ...state, duration });
    durationCallback?.(duration);
  };

  const handlePlaybackRate = (val: number) => {
    setState({ ...state, playbackRate: val });
  };

  const onClickFullScreen = useCallback(() => {
    setFullScreen(!fullScreen);
  }, [fullScreen]);

  const onPrevIssue = useCallback(() => {
    if (selectedIssue) {
      const issueKey = errors?.findIndex((i) => i.pk === selectedIssue.pk);
      if (issueKey > -1) {
        onSelectedIssue(errors[issueKey - 1]);
      }
    } else {
      const lastKey = findLastIndex(errors);
      onSelectedIssue(errors[lastKey]);
    }
  }, [errors, onSelectedIssue, selectedIssue]);

  const onNextIssue = useCallback(() => {
    if (selectedIssue) {
      const issueKey = errors?.findIndex((i) => i.pk === selectedIssue.pk);

      if (issueKey > -1) {
        onSelectedIssue(errors[issueKey + 1]);
      }
    } else {
      onSelectedIssue(errors[0]);
    }
  }, [errors, onSelectedIssue, selectedIssue]);

  const classNamesContainer = cx('video-player-wrapper', className, {
    'video-player-wrapper--full-screen': fullScreen,
  });
  const classNames = cx('video-player', 'testclass-video-main');

  return (
    <div className={classNamesContainer}>
      <ReactPlayer
        ref={playerRef}
        className={classNames}
        url={url}
        playing={state.playing}
        onPlay={handlePlay}
        playbackRate={state.playbackRate}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onBuffer={() => {}}
      />
      <ControlPanel
        state={state}
        errors={errors}
        fullScreen={fullScreen}
        selectedIssue={selectedIssue}
        onPlayPause={handlePlayPause}
        onSeekChange={handleSeekChange}
        onSelectedIssue={onSelectedIssue}
        onClickFullScreen={onClickFullScreen}
        onPlaybackRate={handlePlaybackRate}
        onNextIssue={onNextIssue}
        onPrevIssue={onPrevIssue}
      />
    </div>
  );
}

export default VideoPlayer;
