import { gql } from '@apollo/client';

export const GET_ARTEFACT_DELAYS_PRODUCTION_DEADLINE = gql`
  query GetArtefactDelaysProductionDeadline($project_id: Int, $user_id: Int, $is_current: Boolean) {
    artefact_delays_production_deadline(project_id: $project_id, user_id: $user_id, is_current: $is_current) {
      edges {
        node {
          id
          delay {
            project {
              id
            }
          }
          project_task_assignee {
            project_task {
              cached_linked_artefact {
                id
                artefact_type {
                  name
                  type
                  icon_image
                }
                artefact_iteration_count
                cached_task {
                  id
                  artefact_production_sequence {
                    id
                    project_learning_object {
                      id
                      name
                      parent {
                        human_readable_number
                      }
                    }
                  }
                }
              }
              separate_task {
                id
                project_task {
                  id
                  name
                }
                task_type {
                  id
                  name
                  icon_image
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ARTEFACT_DELAYS_ITERATION_DEADLINE = gql`
  query GetArtefactDelaysIterationDeadline($project_id: Int, $user_id: Int, $is_current: Boolean) {
    artefact_delays_iteration_deadline(project_id: $project_id, user_id: $user_id, is_current: $is_current) {
      edges {
        node {
          id
          delay {
            project {
              id
            }
          }
          project_task_assignee {
            project_task {
              cached_linked_artefact {
                id
                artefact_type {
                  name
                  type
                  icon_image
                }
                artefact_iteration_count
                cached_task {
                  id
                  artefact_production_sequence {
                    id
                    project_learning_object {
                      id
                      name
                      parent {
                        human_readable_number
                      }
                    }
                  }
                }
              }
              separate_task {
                id
                project_task {
                  id
                  name
                }
                task_type {
                  id
                  name
                  icon_image
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ARTEFACT_MORE_ITERATION = gql`
  query GetArtefactMoreIteration($project_id: Int, $user_id: Int) {
    metrics_assignees_tasks_with_four_iterations(project_id: $project_id, user_id: $user_id) {
      edges {
        node {
          id
          project_task {
            cached_linked_artefact {
              id
              artefact_type {
                name
                type
                icon_image
              }
              artefact_iteration_count
              cached_task {
                id
                artefact_production_sequence {
                  id
                  project_learning_object {
                    id
                    name
                    parent {
                      human_readable_number
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CURRENT_ARTEFACT_MORE_ITERATION = gql`
  query GetCurrentArtefactMoreIteration($project_id: Int, $user_id: Int) {
    metrics_current_assignees_tasks_with_four_iterations(project_id: $project_id, user_id: $user_id) {
      edges {
        node {
          id
          project_task {
            cached_linked_artefact {
              id
              artefact_type {
                name
                type
                icon_image
              }
              artefact_iteration_count
              cached_task {
                id
                artefact_production_sequence {
                  id
                  project_learning_object {
                    id
                    name
                    parent {
                      human_readable_number
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
