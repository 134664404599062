import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { Layout } from 'antd';
import { cx } from 'utils';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import TaskSidebarContent from 'pages/task/components/sidebarContent';
import { BusinessRoleEnum } from 'types/entities/user';
import { PATH_NAMES } from 'router/constants';

import Button from 'components/button';
import { ButtonTypesEnum } from 'components/button/types';

import { setFieldActions } from 'store/app/actions';

import useGlobalActionsContext from 'hooks/useGlobalActionsContext';
import useProjectTask from 'hooks/queries/useProjectTask';
import useCommonContext from 'hooks/useCommonContext';

const { Sider } = Layout;

type SidebarLayoutProps = {
  children: ReactNode;
  withoutSidebar?: boolean;
};

function TaskSidebarLayout({ withoutSidebar, children, appSetField }: SidebarLayoutProps & PropsFromRedux) {
  const [searchParams] = useSearchParams();
  const common = useCommonContext();
  const version = searchParams.get('version');
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const navigate = useNavigate();
  const { data } = useGlobalActionsContext();

  const { projectTask } = useProjectTask(version);

  useEffect(() => {
    if (projectTask?.is_turn_off) {
      const userRoles = common.businessRoles;

      if (
        userRoles?.some((item) => item.role === BusinessRoleEnum.executiveManager) ||
        userRoles?.some((item) => item.role === BusinessRoleEnum.manager)
      ) {
        navigate(PATH_NAMES.courses.base);
      } else {
        navigate(PATH_NAMES.tasks.base);
      }
    }
  }, [common.businessRoles, common.userRole, navigate, projectTask?.is_turn_off]);

  useEffect(() => {
    appSetField({ field: 'leftPanel', value: collapsed });
  }, [appSetField, collapsed]);

  const onToggle = useCallback(() => setCollapsed(!collapsed), [collapsed]);

  const pageClassNames = cx('page-layout');

  const sidebarClassNames = cx('sidebar', {
    'sidebar--opened': !collapsed,
  });

  const goBack = useCallback(() => {
    if (data?.prevUrl?.includes('plan')) {
      navigate(data?.prevUrl);
    } else if (data?.prevUrl?.includes('tasks')) {
      navigate(PATH_NAMES.tasks.base);
    } else {
      navigate(PATH_NAMES.dashboard.base);
    }
  }, [data.prevUrl, navigate]);

  return (
    <Layout className={pageClassNames} hasSider>
      <div className="page-layout__header" onClick={goBack}>
        <Button
          onClick={goBack}
          className="page-layout__go-back testclass-go-back"
          type={ButtonTypesEnum.link}
          icon={<ArrowLeftOutlined style={{ fontSize: 18 }} />}
        />
      </div>

      {withoutSidebar ? null : (
        <Sider
          className={sidebarClassNames}
          collapsible
          collapsed={collapsed}
          width={400}
          collapsedWidth={40}
          trigger={null}
          onClick={collapsed ? () => setCollapsed(false) : () => {}}
        >
          <TaskSidebarContent collapsed={collapsed} onToggle={onToggle} />
        </Sider>
      )}
      <>{children}</>
    </Layout>
  );
}

const connector = connect(null, {
  appSetField: setFieldActions,
});
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(TaskSidebarLayout);
