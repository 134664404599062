/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateCoursePlan = {
    id: number;
    parent_id: number | null;
    name: string;
    description?: string;
    order: number;
    learning_structure_type: UpdateCoursePlan.learning_structure_type;
    outcome_description?: string;
    project_learning_outcome_id?: number | null;
    learning_object_type_id?: number | null;
    is_changed?: boolean;
    is_moved?: boolean;
    is_added?: boolean;
    is_deleted?: boolean;
};

export namespace UpdateCoursePlan {

    export enum learning_structure_type {
        PROJECT_ROOT = 'project_root',
        GROUP = 'group',
        PRODUCTION_ITEM = 'production_item',
    }


}
