import { generatePath } from 'react-router-dom';

import { PATH_NAMES, taskEntity } from '../router/constants';
import { CompanyArtefactType } from '../types';

const getEntity = (artefactType?: CompanyArtefactType.type | string) => {
  switch (artefactType) {
    case CompanyArtefactType.type.DRAFT_VIDEO:
    case CompanyArtefactType.type.ORIGINAL_VIDEO:
      return taskEntity.video_shooting;
    case CompanyArtefactType.type.PRODUCTION_PRESENTATION:
      return taskEntity.production_presentation;
    case CompanyArtefactType.type.TEXT:
    case CompanyArtefactType.type.GRADER:
    case CompanyArtefactType.type.QUIZ:
    case CompanyArtefactType.type.PEER_REVIEW:
    case CompanyArtefactType.type.SCRIPT:
      return taskEntity.text;
    default:
      return taskEntity.universal;
  }
};

export const getUrlToTask = (
  learningObjectId?: string,
  taskId?: string,
  iterationId?: string | null | number,
  artefactId?: string,
  artefactType?: CompanyArtefactType.type | string,
  fileType?: string
): string => {
  if (!learningObjectId || !taskId || !artefactId || !iterationId) {
    return PATH_NAMES.tasks.base;
  }

  return generatePath(PATH_NAMES.task.baseExtended, {
    learningObjectId: learningObjectId,
    taskId: taskId,
    artefactId: artefactId,
    iterationId: iterationId?.toString(),
    entity: fileType === 'url' ? taskEntity.universal : getEntity(artefactType),
  });
};
