import React, { memo } from 'react';

import { Popover as APopover } from 'antd';
import { cx } from 'utils';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { PopoverProps } from 'antd/lib/popover';

interface CCMPopoverProps extends PopoverProps {
  className?: string;
  placement?: TooltipPlacement;
}

function Popover({ className, placement, children, ...props }: CCMPopoverProps) {
  const classNames = cx('ccm-popover', className);
  return (
    <APopover {...props} className={classNames} placement={placement}>
      {children}
    </APopover>
  );
}

export default memo(Popover);
