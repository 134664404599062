import React from 'react';

import { UploadFile } from 'antd/lib/upload/interface';
import { UploadChangeParam } from 'antd/es/upload';

import UploadAvatar from 'components/uploadAvatar';
import './styles.scss';

type AvatarBlockType = {
  onChangeUpload: (info: UploadChangeParam<UploadFile>) => void;
  loading?: boolean;
  file?: string | undefined;
};

function AvatarBlock({ onChangeUpload, loading, file }: AvatarBlockType) {
  return (
    <div className="avatar-block mb_8">
      <UploadAvatar onChangeUpload={onChangeUpload} loading={loading} file={file} />
    </div>
  );
}

export default AvatarBlock;
