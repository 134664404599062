import { useQuery } from '@apollo/client';

import { GET_NEXT_TASK_INFO } from '../../queries/getNextTaskInfo';
import { DashboardSingleTaskType } from '../../schemas/types';

type NextTaskType = {
  next_task: DashboardSingleTaskType;
};

function useNextTaskInfo() {
  const { data, loading, refetch } = useQuery<NextTaskType>(GET_NEXT_TASK_INFO, {
    fetchPolicy: 'no-cache',
  });

  return {
    taskInfo: data?.next_task,
    loadingNextTaskInfo: loading,
    taskInfoNextRefetch: refetch,
  };
}

export default useNextTaskInfo;
