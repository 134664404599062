export const iconComment = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_125934_3543)">
<path d="M9.08955 6.37502C8.67705 6.37502 8.35741 6.69466 8.35741 7.08931C8.35741 7.48395 8.67705 7.80359 9.08955 7.80359C9.46634 7.80359 9.78598 7.48395 9.78598 7.08931C9.78598 6.69466 9.46634 6.37502 9.08955 6.37502ZM4.08955 6.37502C3.67705 6.37502 3.35741 6.69466 3.35741 7.08931C3.35741 7.48395 3.67705 7.80359 4.08955 7.80359C4.46634 7.80359 4.78598 7.48395 4.78598 7.08931C4.78598 6.69466 4.46634 6.37502 4.08955 6.37502Z" fill="#1E1E1E"/>
<path d="M14.8212 5.01649C13.995 3.87924 12.8037 3.05982 11.4462 2.69506V2.69684C11.1408 2.35756 10.7962 2.04506 10.4105 1.76648C7.48726 -0.358515 3.38369 0.289699 1.24976 3.21291C-0.469881 5.58791 -0.396667 8.78256 1.3569 11.0522L1.37119 13.4201C1.37119 13.4772 1.38012 13.5343 1.39798 13.5879C1.42044 13.6595 1.45679 13.7259 1.50494 13.7834C1.5531 13.8409 1.61212 13.8883 1.67863 13.923C1.74514 13.9577 1.81783 13.9789 1.89254 13.9854C1.96726 13.992 2.04253 13.9837 2.11405 13.9611L4.37476 13.2486C4.97298 13.4611 5.59083 13.5826 6.20512 13.6165L6.19619 13.6236C7.78726 14.7826 9.87298 15.1308 11.7855 14.4986L14.0551 15.2379C14.1123 15.2558 14.1712 15.2665 14.2319 15.2665C14.548 15.2665 14.8033 15.0111 14.8033 14.6951V12.3022C16.3765 10.1665 16.4176 7.2147 14.8212 5.01649ZM4.62476 11.9808L4.41048 11.8915L2.64262 12.4451L2.62476 10.5879L2.4819 10.4272C0.97119 8.58434 0.87119 5.92898 2.28548 3.98077C4.00691 1.62006 7.30691 1.09863 9.66048 2.8022C12.0212 4.51827 12.5444 7.81291 10.839 10.1593C9.40869 12.1218 6.84798 12.8468 4.62476 11.9808ZM13.6426 11.6772L13.4998 11.8558L13.5176 13.7129L11.7676 13.1236L11.5533 13.2129C10.5533 13.5843 9.48726 13.6147 8.49976 13.3379L8.49619 13.3361C9.85339 12.9199 11.0389 12.0746 11.8748 10.9272C13.239 9.04684 13.4605 6.68434 12.6676 4.67006L12.6783 4.6772C13.089 4.97184 13.4658 5.3397 13.7855 5.78434C15.0819 7.56291 15.0087 9.98434 13.6426 11.6772Z" fill="#1E1E1E"/>
<path d="M6.58955 6.37502C6.17705 6.37502 5.85741 6.69466 5.85741 7.08931C5.85741 7.48395 6.17705 7.80359 6.58955 7.80359C6.96634 7.80359 7.28598 7.48395 7.28598 7.08931C7.28598 6.69466 6.96634 6.37502 6.58955 6.37502Z" fill="#1E1E1E"/>
</g>
<defs>
<clipPath id="clip0_125934_3543">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
`;
