import React, { memo } from 'react';

import { Dropdown as ADropdown, DropdownProps, Space } from 'antd';
import { cx } from 'utils';
import { DownOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import Button from '../button';

import './styles.scss';
import { ButtonTypesEnum } from '../button/types';

type DropdownCustomProps = {
  className?: string;
  buttonType?: ButtonTypesEnum;
  buttonIcon?: React.ReactNode;
  children?: React.ReactNode;
  cursor?: boolean;
  size?: SizeType;
  variant?: 'link' | 'button';
  hasArrow?: boolean;
};

function Dropdown({
  className,
  variant = 'link',
  buttonType = ButtonTypesEnum.default,
  buttonIcon,
  size,
  cursor,
  overlay,
  children,
  hasArrow = true,
  ...props
}: DropdownCustomProps & DropdownProps) {
  const classNames = cx('ccm-dropdown', className, {
    [`ccm-dropdown--disabled`]: props.disabled,
    [`ccm-dropdown--${variant}`]: variant,
  });

  return (
    <ADropdown
      className={classNames}
      trigger={['click']}
      overlay={overlay}
      //getPopupContainer={(triggerNode) => triggerNode}
      {...props}
    >
      {variant === 'link' ? (
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <Space>
            {children}
            {hasArrow ? <DownOutlined style={{ fontSize: 12 }} /> : null}
          </Space>
        </a>
      ) : (
        <Button type={buttonType} icon={buttonIcon} size={size} onClick={(e) => e.preventDefault()}>
          <Space>{children}</Space>
          {hasArrow ? <DownOutlined style={{ fontSize: 12 }} /> : null}
        </Button>
      )}
    </ADropdown>
  );
}

export default memo(Dropdown);
