import { gql } from '@apollo/client';

export const GET_PARTICIPANTS_GANTT = gql`
  query ParticipantsGantt($companyId: Int, $roleId: Int) {
    gantt(company_id: $companyId, role_id: $roleId) {
      min_date
      max_date
      users {
        id
        last_name
        first_name
        projects {
          name
          production_finish_date
          production_start_date
          production_progress
        }
      }
    }
  }
`;
