import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Navigate, Outlet, RouteProps } from 'react-router-dom';
import { IStore } from 'types/store';
import { usePostHog } from 'posthog-js/react';

import { PATH_NAMES } from '../../router/constants';
import useCommonContext from '../../hooks/useCommonContext';

type IProps = {
  isAuthenticated?: boolean;
} & RouteProps;

function ProtectedLayout({ isAuthenticated }: IProps) {
  const posthog = usePostHog();
  const { user } = useCommonContext();

  useEffect(() => {
    if (user) {
      posthog?.identify(user.id, {
        email: user.email,
      });
    }
  }, [posthog, user]);

  if (!isAuthenticated) {
    return <Navigate to={PATH_NAMES.auth.login} />;
  }

  return (
    <div className="main">
      <Outlet />
    </div>
  );
}

ProtectedLayout.defaultProps = {
  isAuthenticated: false,
};

export default connect((state: IStore) => ({
  isAuthenticated: true,
}))(ProtectedLayout);
