import React, { FC, memo, useContext } from 'react';

import CommonPropsContext, { TemplateCommonProps } from '../common/commonPropsProvider';

const withCommonProps = function <P extends TemplateCommonProps>(
  Comp: FC<P>
): FC<Omit<P, keyof Omit<TemplateCommonProps, 'className'>>> {
  const WithCommonPropsComp: FC<Omit<P, keyof Omit<TemplateCommonProps, 'className'>>> = (props: any) => {
    const common = useContext(CommonPropsContext);

    const compProps: P = {
      ...props,
      common,
    } as P;

    return <Comp {...compProps} />;
  };
  return memo(WithCommonPropsComp);
};

export default withCommonProps;
