import React from 'react';

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import modalService from 'services/modalService';

import Title from 'components/title/Title';
import { TitleVariantEnum } from 'components/title/types';
import Button from 'components/button/Button';
import { ButtonTypesEnum } from 'components/button/types';
import Table from 'components/table/Table';

import { getIconByUrl } from 'utils/icons';

import useCompanyLearningObjectTypes, { CompanyLearningObjectType } from 'hooks/queries/useCompanyLearningObjectTypes';
import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

const LearningObjTypes = () => {
  const common = useCommonContext();
  const { companyLearningObjectTypes, loading, refetch } = useCompanyLearningObjectTypes();

  const onAddLOType = () => {
    modalService.openCreateNewLOType({
      companyId: common.company?.id,
      refetch,
    });
  };

  const columns = [
    {
      key: 'name',
      title: 'Name',
      render: (record: CompanyLearningObjectType) => record.name,
    },
    {
      key: 'type',
      title: 'Type',
      render: (record: CompanyLearningObjectType) => record.type,
    },
    {
      key: 'order',
      title: 'Order',
      render: (record: CompanyLearningObjectType) => record.order,
    },
    {
      key: 'related',
      title: 'Has related',
      render: (record: CompanyLearningObjectType) =>
        record.has_related_artefact_type ? (
          <CheckCircleOutlined style={{ color: '#65B129' }} />
        ) : (
          <CloseCircleOutlined style={{ color: '#ff4d4f' }} />
        ),
    },
    {
      key: 'icon',
      title: 'Icon',
      render: (record: CompanyLearningObjectType) => getIconByUrl(record.icon_image),
    },
  ];

  return (
    <div className="ms-lo-types">
      <div className="ms-lo-types__top">
        <Title variant={TitleVariantEnum.h4}>Learning object types</Title>
        <Button size="xl" type={ButtonTypesEnum.primary} onClick={onAddLOType}>
          New learning object type
        </Button>
      </div>
      <Table columns={columns} dataSource={companyLearningObjectTypes} loading={loading} pagination={false} />
    </div>
  );
};

export default LearningObjTypes;
