import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { localStorageService } from "../services/localStorageService";
import { onError } from "@apollo/client/link/error";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { onRefreshToken } from "../api/requests/auth";
import { getAccessExpToken } from "../utils";
import { relayStylePagination } from "@apollo/client/utilities/policies/pagination.js";
import { GraphQLError } from "graphql/index";
import {
  DEMO_API_URL, DEMO_RU_API_URL,
  DEV_AMAZON_API_URL, PROD_API_URL, PROD_CONSTRUCTOR, PROD_RU_API_URL, PROD_TEST
} from "../constants";

interface IGraphQLErrorsExtended extends GraphQLError {
  context: {
    status: number
  };
}

//Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, forward, operation }: any) => {
  if (graphQLErrors)
    graphQLErrors.forEach(async ({ message, locations, path, context }: IGraphQLErrorsExtended) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      if (message === "Signature has expired") {
        const authData = localStorageService.getAuthData();
        await onRefreshToken({ refresh: authData?.refresh || "" }).then((resp: any) => {
          console.log(resp);
        });
        return forward(operation);
      }
      // if (context?.status === 403) {
      //   history.push(PATH_NAMES.errors.error403);
      // }
      // if (context?.status === 404) {
      //   history.push(PATH_NAMES.errors.error404);
      // }
      // if (context?.status === 405) {
      //   history.push(PATH_NAMES.errors.error405);
      // }
      // if (context?.status === 500) {
      //   history.push(PATH_NAMES.errors.error500);
      // }
      // if (context?.status === 502) {
      //   history.push(PATH_NAMES.errors.error502);
      // }
      // if (context?.status === 503) {
      //   history.push(PATH_NAMES.errors.error503);
      // }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const getBaseUrl = () => {
  return process.env.NODE_ENV === "development"
    ? `${DEV_AMAZON_API_URL}/graphql/pm/graphql/`
    : `${window.location.origin}/graphql/pm/graphql/`;
};

const httpLink = createHttpLink({
  uri: getBaseUrl()
});

//Add JWT header
const authLink = setContext(async (_, { headers }) => {
  const authData = localStorageService.getAuthData();
  return {
    headers: {
      ...headers,
      Authorization: authData?.access ? `JWT ${authData?.access}` : ""
    }
  };
});

//generate link with refresh token
const link = ApolloLink.from([
  new TokenRefreshLink<{ access: string; refresh: string }>({
    isTokenValidOrUndefined: () => getAccessExpToken(),
    fetchAccessToken: async () => {
      const authData = localStorageService.getAuthData();
      if (authData?.refresh) {
        return await onRefreshToken({ refresh: authData?.refresh });
      }
    },
    handleFetch: async (accessToken) => {
      if (accessToken?.access) {
        const { access, refresh } = accessToken;
        localStorageService.setAuthData({ access, refresh });
      }
    },
    handleResponse: (operation, accessTokenField) => async (response: any) => {
      if (response?.access) {
        localStorageService.setAuthData(response);
      }
    },
    handleError: (err) => {
      // full control over handling token fetch Error
      // console.warn("Your refresh token is invalid. Try to relogin");
      // console.error(err);
      // When the browser is offline and an error occurs we don’t want the user to be logged out of course.
      // We also don’t want to delete a JWT token from the `localStorage` in this case of course.
      // if (
      //     !navigator.onLine || (err.message === "Network request failed")) {
      //     console.log("Offline")
      // } else {
      //   // if (err?.message === 'Error decoding signature') {
      //     localStorageService.removeAuthData();
      //     history.push(PATH_NAMES.auth.login);
      //     console.log("log out")
      //   // }
      // }
    }
  }),
  errorLink,
  authLink,
  httpLink
]);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        collaborators: relayStylePagination([]),
        projects: relayStylePagination([]),
        project_tasks: relayStylePagination([]),
        front_page_courses_no_assignees: relayStylePagination([]),
        front_page_courses_with_issues: relayStylePagination([]),
        front_page_courses_deadline_within_fortnight: relayStylePagination([]),
        front_page_tasks_deadlines_missing: relayStylePagination([]),
        front_page_tasks_more_than_three_iterations: relayStylePagination([]),
        front_page_tasks_deadlines_in_two_days: relayStylePagination([]),
        front_page_tasks_for_today: relayStylePagination([]),
        front_page_tasks_with_new_comments: relayStylePagination([])
      }
    }
  }
});

// from - combine links into a single link
export const client = new ApolloClient({
  link: link,
  connectToDevTools: true,
  // link: from([errorLink, authLink, httpLink]),
  cache
});
