import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';

import { GET_MATERIALS } from '../../queries/getMaterials';
import { CompanyArtefactType, CompanyState } from '../../types';

export type MaterialItem = {
  id: string;
  artefact_type: {
    id: string;
    type: CompanyArtefactType.type;
    name: string;
    icon_image: string;
  };
  current_file: {
    id: string;
    file_type: 'document' | 'video' | 'presentation' | 'url';
    state: CompanyState.state_type;
    source_files: {
      id: string;
      source_url: string;
      file_size: number;
      external_source_url: string;
      file_extension: string;
    }[];
    related_files: any[];
  };
};

type MaterialsData = {
  materials: MaterialItem[];
};

function useMaterials(artefactId: number, fetchPolicy?: WatchQueryFetchPolicy) {
  const { data, loading, refetch } = useQuery<MaterialsData>(GET_MATERIALS, {
    variables: {
      artefactId,
    },
    fetchPolicy: fetchPolicy || 'network-only',
    skip: !artefactId,
  });
  return {
    materials: data?.materials as MaterialItem[],
    materialsLoading: loading,
    getMaterials: refetch,
  };
}

export default useMaterials;
