import React, { memo } from 'react';

import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

type Props = {
  itemId: string | number;
  onClick: (val: string | number) => void;
};

const RemoveButtonWithConfirm = ({ itemId, onClick }: Props) => {
  const [modal, contextHolder] = Modal.useModal();

  const onHandleClick = () => {
    onClick(itemId);
  };

  const confirm = () => {
    modal.confirm({
      title: 'Do you really want to delete this record?',
      icon: <WarningOutlined style={{ color: '#D73C1D' }} />,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: onHandleClick,
      //confirmLoading: true,
    });
  };

  return (
    <>
      <Button danger shape="circle" type="text" icon={<DeleteOutlined />} onClick={confirm} />
      {contextHolder}
    </>
  );
};

export default RemoveButtonWithConfirm;
