import React, { memo, useCallback } from 'react';

import { ButtonTypesEnum } from 'components/button/types';
import Button from 'components/button';

import SvgInline from '../../../../../../components/svgInline';

import { ElementButtonProps } from './types';

function ElementButton({ item, callback }: ElementButtonProps) {
  const onClick = useCallback(() => {
    callback(Number(item.id), item?.type, item.name, item?.icon_image);
  }, [callback, item?.icon_image, item.id, item.name, item?.type]);
  return (
    <Button
      className="mr_8"
      type={ButtonTypesEnum.link}
      icon={<SvgInline url={item?.icon_image} />}
      size="small"
      onClick={onClick}
    >
      {item?.name}
    </Button>
  );
}

export default memo(ElementButton);
