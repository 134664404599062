/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ExportCourseResponse = {
    state: ExportCourseResponse.state;
    final_path?: string | null;
    export_id: number;
};

export namespace ExportCourseResponse {

    export enum state {
        PREPARING = 'preparing',
        DONE = 'done',
        CANCELLED = 'cancelled',
        FAILED = 'failed',
    }


}
