import { useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { UserBase } from 'types/entities/user';
import { GET_PROJECT_DEADLINES_CHANGED } from 'queries/getProjectDeadlinesChanged';

export type ProjectDeadlinesChangedItem = {
  pk: string;
  created_at: string;
  creator: UserBase;
  deadline_new: string | null;
  deadline_old: string | null;
  production_start_date: string | null;
  production_finish_date: string | null;
  relation_changed: {
    id: string;
  };
};

type ProjectDeadlinesChangedItemNode = {
  node: ProjectDeadlinesChangedItem;
};

function useLazyProjectDeadlinesChanged(projectId: number, fetchPolicy?: WatchQueryFetchPolicy) {
  const [getProjectDeadlines, { data, loading }] = useLazyQuery(GET_PROJECT_DEADLINES_CHANGED, {
    variables: {
      project_id: projectId,
    },
    fetchPolicy: fetchPolicy || 'network-only',
  });

  return useMemo(() => {
    return {
      getProjectDeadlines,
      projectDeadlines: data?.project_deadlines_changed?.edges?.map(
        (item: ProjectDeadlinesChangedItemNode) => item.node
      ),
      projectDeadlinesLoading: loading,
    };
  }, [data?.project_deadlines_changed?.edges, getProjectDeadlines, loading]);
}

export default useLazyProjectDeadlinesChanged;
