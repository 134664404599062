import React, { memo } from 'react';

import { Skeleton } from 'antd';

const arr = Array.from(Array(5).keys());

const styles = {
  one: { width: '22%' },
  two: { width: '17%' },
};

function TasksPageSkeleton() {
  return (
    <div className="skeleton-tasks-table">
      {arr.map((e) => {
        return (
          <div key={e} className="skeleton-row">
            <Skeleton paragraph={false} avatar={false} active style={styles.one} />
            <Skeleton paragraph={false} avatar={false} active style={styles.one} />
            <Skeleton paragraph={false} avatar={false} active style={styles.one} />
            <Skeleton paragraph={false} avatar={false} active style={styles.two} />
            <Skeleton paragraph={false} avatar={false} active style={styles.two} />
          </div>
        );
      })}
    </div>
  );
}

export default memo(TasksPageSkeleton);
