import { useLazyQuery } from '@apollo/client';
import { GET_ME_INFO } from 'queries/getMeInfo';
import { CompanyTypeEnum, TechnicalRoleEnum, UserRoleBase } from 'types/entities';
import { CcmUserState } from 'schemas/types';

export type CompanyInfo = {
  id: string;
  slug: string;
  name: string;
  company_type: CompanyTypeEnum;
  default_artefact_issue_list_version: {
    id: string;
  };
  default_project_phase_state_list_version: {
    id: string;
  };
  default_project_state_state_list_version: {
    id: string;
  };
  default_project_task_state_list_version: {
    id: string;
  };
  default_separate_task_state_list_version: {
    id: string;
  };
};

export type CompaniesInfo = {
  id: string;
  pk: string;
  created_at: string;
  updated_at: string;
  technical_role: TechnicalRoleEnum;
  business_roles: UserRoleBase[];
  company: CompanyInfo;
};

export type Me = {
  id: string;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  icon_image: string;
  timezone: string;
  is_staff?: boolean;
  state: CcmUserState;
  companies: CompaniesInfo[];
};

type MeData = {
  me: Me;
};

function useMeInfo() {
  const [getMe, { data, loading }] = useLazyQuery<MeData>(GET_ME_INFO, {
    fetchPolicy: 'network-only',
  });

  return {
    me: data?.me,
    meLoading: loading,
    getMe,
  };
}

export default useMeInfo;
