import { gql } from '@apollo/client';

export const GET_PROJECTS = gql`
  query GetProjectsQuery(
    $company_id: Int
    $user_id: Int
    $name: String
    $delaysExist: Boolean
    $companyDelaysExist: Boolean
    $delays: [Int]
    $assigned: [Int]
    $offset: Int
    $first: Int
    $after: String
  ) {
    projects(
      company_id: $company_id
      user_id: $user_id
      name: $name
      metrics_artefact_delays_exist: $delaysExist
      artefact_delay: $delays
      assignee_ids: $assigned
      offset: $offset
      first: $first
      after: $after
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          name
          production_progress
          production_start_date
          production_finish_date
          metrics_missing_deadlines
          metrics_completed_tasks_plan
          metrics_completed_tasks_fact
          metrics_maximium_artefact_delay
          metrics_changed_deadlines_exist
          metrics_artefact_delays_exist
          metrics_project_changed_deadlines_exist
          metrics_total_completed_tasks
          metrics_total_tasks
          metrics_project_health
          updated_at
          last_updated
          phase_state {
            name
          }
          company {
            name
            slug
            id
          }
          state {
            name
            state_type
            name
            icon_image
          }
          phase_state {
            state_type
            name
            icon_image
          }
          project_type
          projectuser_set {
            id
            company_user {
              company {
                id
                name
              }
              user {
                id
                first_name
                last_name
                email
                name
                icon_image
                timezone
                state
              }
            }
            roles {
              role {
                id
                name
                role
                pk
              }
            }
            is_active
            is_external_company
          }
          id
          pk
        }
      }
    }
    company_metrics(
      company_id: $company_id
      user_id: $user_id
      name: $name
      metrics_artefact_delays_exist: $companyDelaysExist
      artefact_delay: $delays
      assignee_ids: $assigned
    ) {
      courses_with_missed_deadlines
    }
  }
`;
