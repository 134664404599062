import React, { memo, useState } from 'react';

import { cx } from 'utils';

import './styles.scss';
import { Menu, MenuProps, message } from 'antd';
import { TFunction } from 'i18next';

import Dropdown from '../../../../../components/dropdown/DropDown';
import DropdownMenu from '../../../../../components/dropdownMenu/DropdownMenu';
import withCommonProps from '../../../../../hocs/withCommonProps';
import { TemplateCommonProps } from '../../../../../common/commonPropsProvider';

const getSortVideoFilter = (t: TFunction) => [
  {
    id: 'by_timeline',
    label: t<string>('pages.task.filter.onTimelines'),
    className: 'testclass-errors-filter-timeline-choose-timeline',
  },
  {
    id: 'by_creation_date',
    label: t<string>('pages.task.filter.byCreatingDate'),
    className: 'testclass-errors-filter-timeline-choose-date',
  },
];
const getSortPresentation = (t: TFunction) => [
  {
    id: 'by_slide_number',
    label: t<string>('pages.task.filter.onSlides'),
    className: 'testclass-errors-filter-timeline-choose-timeline',
  },
  {
    id: 'by_creation_date',
    label: t<string>('pages.task.filter.byCreatingDate'),
    className: 'testclass-errors-filter-timeline-choose-date',
  },
];
const getFilter = (t: TFunction) => [
  {
    id: 'OPEN',
    label: t<string>('pages.task.filter.opened'),
    className: 'testclass-errors-filter-timeline-choose-open',
  },
  {
    id: 'CLOSED',
    label: t<string>('pages.task.filter.finished'),
    className: 'testclass-errors-filter-timeline-choose-completed',
  },
  {
    id: 'ALL',
    label: t<string>('pages.task.filter.all'),
    className: 'testclass-errors-filter-timeline-choose-all',
  },
];
type IProps = {
  className?: string;
  isVideo?: boolean;
  changeOrder: (value: string) => void;
  changeFilter: (value: string) => void;
  filter: string;
  ordering: string;
};

function ErrorsFilter({
  className,
  common,
  isVideo,
  changeOrder,
  changeFilter,
  filter,
  ordering,
}: IProps & TemplateCommonProps) {
  const classNames = cx('errors-filter', className);
  const onChangeFilterClick: MenuProps['onClick'] = ({ key }) => {
    changeFilter(key);
  };
  const onChangeOrderClick: MenuProps['onClick'] = ({ key }) => {
    changeOrder(key);
  };
  const sortTypeName = isVideo ? getSortVideoFilter(common?.t) : getSortPresentation(common?.t);
  const orderName = sortTypeName?.find((elem: any) => elem?.id === ordering)?.label;
  const filterName = getFilter(common?.t)?.find((elem: any) => elem?.id === filter)?.label;
  return (
    <div className={classNames}>
      <Dropdown
        className="mr_8 testclass-errors-filter-sort"
        size="small"
        cursor
        overlay={
          <DropdownMenu
            list={isVideo ? getSortVideoFilter(common?.t) : getSortPresentation(common?.t)}
            onClick={onChangeOrderClick}
          />
        }
      >
        {orderName}
      </Dropdown>
      <Dropdown
        className="mr_8 testclass-errors-filter-filter"
        size="small"
        cursor
        overlay={<DropdownMenu list={getFilter(common?.t)} onClick={onChangeFilterClick} />}
      >
        {filterName}
      </Dropdown>
    </div>
  );
}

export default withCommonProps(ErrorsFilter);
