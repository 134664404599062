import { gql } from '@apollo/client';

import { FRONT_PAGE_TASKS, PAGE_INFO } from './fragments';

export const GET_DASHBOARD_TASKS = gql`
  query DashboardTasks {
    front_page_tasks {
      total_amount
      first_deadline_missed
      second_deadline_missed
      for_today
    }
  }
`;

export const GET_DASHBOARD_TASKS_DEADLINES_MISSING = gql`
  query DashboardTasksDeadlinesMissing($offset: Int, $first: Int, $after: String) {
    front_page_tasks_deadlines_missing(offset: $offset, first: $first, after: $after) {
      totalCount
      edges {
        cursor
        node {
          ...FrontPageTasks
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PAGE_INFO}
  ${FRONT_PAGE_TASKS}
`;

export const GET_DASHBOARD_TASKS_MORE_THAN_THREE_ITERATION = gql`
  query DashboardTasksMoreThanThreeIteration($offset: Int, $first: Int, $after: String) {
    front_page_tasks_more_than_three_iterations(offset: $offset, first: $first, after: $after) {
      totalCount
      edges {
        cursor
        node {
          ...FrontPageTasks
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PAGE_INFO}
  ${FRONT_PAGE_TASKS}
`;

export const GET_DASHBOARD_TASKS_DEADLINES_IN_TWO_DAYS = gql`
  query DashboardTasksDeadlinesInTwoDays($offset: Int, $first: Int, $after: String) {
    front_page_tasks_deadlines_in_two_days(offset: $offset, first: $first, after: $after) {
      totalCount
      edges {
        cursor
        node {
          ...FrontPageTasks
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PAGE_INFO}
  ${FRONT_PAGE_TASKS}
`;

export const GET_DASHBOARD_TASKS_FOR_TODAY = gql`
  query DashboardTasksForToday($offset: Int, $first: Int, $after: String) {
    front_page_tasks_for_today(offset: $offset, first: $first, after: $after) {
      totalCount
      edges {
        cursor
        node {
          ...FrontPageTasks
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PAGE_INFO}
  ${FRONT_PAGE_TASKS}
`;

export const GET_DASHBOARD_TASKS_WITH_NEW_COMMENTS = gql`
  query DashboardTasksWithNewComments($offset: Int, $first: Int, $after: String) {
    front_page_tasks_with_new_comments(offset: $offset, first: $first, after: $after) {
      totalCount
      edges {
        cursor
        node {
          ...FrontPageTasks
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PAGE_INFO}
  ${FRONT_PAGE_TASKS}
`;
