import React from 'react';

import modalService from 'services/modalService';

import Title from 'components/title/Title';
import { TitleVariantEnum } from 'components/title/types';
import Button from 'components/button/Button';
import { ButtonTypesEnum } from 'components/button/types';
import Table from 'components/table/Table';

import useArtefactPipelines, { ArtefactPipelinesItem } from 'hooks/queries/useArtefactPipelines';
import useCompanyArtefactTypes from 'hooks/queries/useCompanyArtefactTypes';
import useCommonContext from 'hooks/useCommonContext';
import { CompanyStateListItem } from 'hooks/queries/useCompanyStateLists';

import './styles.scss';

const PipelinePage = () => {
  const { company } = useCommonContext();
  const { loading, artefactPipeline } = useArtefactPipelines();
  const { companyArtefactTypes } = useCompanyArtefactTypes();
  const columns = [
    {
      key: 'name',
      title: 'Name',
      width: 400,
      render: (record: ArtefactPipelinesItem) => record.company_artefact_type?.name,
    },
    {
      key: 'final',
      title: 'Final artefact type',
      width: 300,
      render: (record: ArtefactPipelinesItem) => record.company_artefact_type?.type,
    },
    {
      key: 'lo-name',
      title: 'Learning object name',
      width: 300,
      render: (record: ArtefactPipelinesItem) => record.company_artefact_type?.learning_object_type?.name,
    },
    {
      key: 'lo',
      title: 'Learning object type',
      width: 300,
      render: (record: ArtefactPipelinesItem) => record.company_artefact_type?.learning_object_type?.type,
    },
    {
      key: 'edit',
      title: '',
      render: (record: CompanyStateListItem) => {
        const path = `pipeline/edit/${record?.pk}`;
        return (
          <Button href={path} type={ButtonTypesEnum.link}>
            edit
          </Button>
        );
      },
    },
    {
      key: 'empty',
      title: '',
    },
  ];

  const onAddPipeline = () => {
    modalService.openCreateNewPipeline({
      companyId: company?.id,
      companyArtefactTypes: companyArtefactTypes.filter((el) => el?.is_final),
    });
  };

  return (
    <div className="ms-pipeline">
      <div className="ms-pipeline__top">
        <Title variant={TitleVariantEnum.h4}>Artefact types</Title>
        <Button size="xl" type={ButtonTypesEnum.primary} onClick={onAddPipeline}>
          New pipeline
        </Button>
      </div>
      <Table columns={columns} dataSource={artefactPipeline} loading={loading} pagination={false} />
    </div>
  );
};

export default PipelinePage;
