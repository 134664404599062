import React, { memo } from 'react';

import { Menu } from 'antd';
import { noop } from 'lodash';
import { cx } from 'utils';

import Checkbox from '../checkbox';

import { DropdownMenuProps } from './types';

function DropdownMenu({ className, list, items, hasCheckbox, multiple, onClick, onClickCheckbox }: DropdownMenuProps) {
  if (!list || !list.length) {
    return null;
  }

  const defaultItems = list
    .filter((el) => !el.hidden)
    .map((item) => {
      return {
        key: item?.key || item.id,
        //icon: item.icon,
        label: hasCheckbox ? (
          <Checkbox onClick={() => onClickCheckbox?.(item.id)} name={item.value}>
            {item?.label}
          </Checkbox>
        ) : (
          <span
            role="button"
            className={cx('ccm-dropdown-menu__value-text', item?.className)}
            onClick={item?.action || noop}
          >
            <span className="ccm-dropdown-menu-item__icon">{item.icon}</span>
            {item?.label}
          </span>
        ),
      };
    });

  return (
    <Menu
      className={cx('ccm-dropdown-menu', className, { 'ccm-dropdown-menu--checkbox': hasCheckbox })}
      multiple={multiple}
      items={items || defaultItems}
      onClick={onClick ? onClick : noop}
    />
  );
}

export default memo(DropdownMenu);
