import { BadgeProps } from 'antd/lib/badge';

export enum CCMBadgeVariant {
  red = 'red',
  blue = 'blue',
  green = 'green',
  light = 'light',
  dark = 'dark',
}

export interface CCMBadgeProps extends BadgeProps {
  variant?: CCMBadgeVariant;
}
