import React from 'react';

import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/es/input-number';
import { cx } from 'utils';

import './styles.scss';

const InputNum = (props: InputNumberProps) => {
  const classNames = cx('ccm-input-number', props.className, {
    [`ccm-input-number--${props.size}`]: props.size,
  });
  return <InputNumber {...props} className={classNames} />;
};

export default InputNum;
