import React, { memo } from 'react';

import { UserRoleBase } from 'types/entities';

import Tag from 'components/tag';

import { cx } from '../../../utils';

type RoleProductionViewProps = {
  data: UserRoleBase[] | null;
  className?: string;
};

function RoleProductionView({ data, className }: RoleProductionViewProps) {
  if (!data || !data.length) {
    return null;
  }
  const classNames = cx('role-prod-view', className);
  return (
    <div className={classNames}>
      {data?.map((el: UserRoleBase, index) => (
        <Tag key={index}>{el.name}</Tag>
      ))}
    </div>
  );
}

export default memo(RoleProductionView);
