import { RcFile } from 'antd/lib/upload/interface';
import i18next from 'i18next';
import { message } from 'antd';
import {
  onCopyFile,
  onCreateArtefactVersion,
  onCreateFile,
  onGetFile,
  onSetFileState,
  onUpdateFile,
  onUploadFile,
  onUploadSlide,
} from 'api/requests/files';
import { FileState, UploadStateEnum } from 'types/entities';
import { checkMimeTypePresentation, chunkSize, createChunks } from 'utils';
import { ArtefactVersionPayload, CreateFileResponse } from 'types/api';

import { ArtefactFile, ArtefactFileIteration } from '../../types';
import { messageDurability } from '../../../../constants';

const onUploadImages = async (
  files: any,
  fileId: number,
  callback: () => void,
  setProgress: (data: number | null) => void
) => {
  let iterator = 1;
  let loaded = 0;
  const ascending = (a: any, b: any) => (a > b ? -1 : 1);
  files.sort(ascending);
  for await (const f of files) {
    const formData = new FormData();
    const file: Blob | undefined = f?.originFileObj || f;

    if (file) {
      formData.append('file', file);

      // eslint-disable-next-line @typescript-eslint/no-loop-func
      await onUploadSlide(fileId, formData).then(() => {
        loaded = loaded + 1;
        iterator = iterator + 1;
        setProgress((loaded / files.length) * 100);

        if (loaded === files.length) {
          callback();
        }
      });
    }
  }
};

const onCheckFileStatus = async (fileId: number, callback: () => void) => {
  const response = await onGetFile(fileId);

  if (response.state === 'uploading') {
    setTimeout(() => onCheckFileStatus(fileId, callback), 3000);
  } else {
    callback();
  }
};

const onUploadPresentation = async (
  files: RcFile,
  fileId: number,
  callback: () => void,
  setProgress: (data: number | null) => void
) => {
  const chunks = createChunks(files, chunkSize);
  let iterator = 1;
  let loaded = 0;
  for await (const chunk of chunks) {
    const formData = new FormData();
    formData.append('part', iterator.toString());
    formData.append('file', chunk);
    await onUploadFile(fileId, formData)
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      .then(() => {
        loaded = loaded + 1;
        iterator = iterator + 1;
        setProgress((loaded / chunks.length) * 100);
        if (loaded === chunks.length) {
          console.log('Process is complete, counter', loaded);
          onSetFileState(fileId, { state: UploadStateEnum.UPLOADED }).then(() => {
            onCheckFileStatus(fileId, callback);
          });
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      .catch((error) => {
        console.log('Error Occurred:', error);
        onSetFileState(fileId, { state: UploadStateEnum.CANCELLED }).then(() => {
          callback();
        });
      });
  }
};
export const createArtefactVersion = async (payload: ArtefactVersionPayload) => {
  const artefactVersion = await onCreateArtefactVersion(payload);
  return artefactVersion;
};

export const onUploadManagement = async (
  files: RcFile[],
  artId: string,
  artefactFileVersion: ArtefactFileIteration | null,
  artefactFiles: ArtefactFile | null,
  callback: () => void,
  setProgress: (data: number | null) => void,
  clearFiles: () => void
) => {
  if (artefactFiles?.state === FileState.uploading || artefactFiles?.state === FileState.UPLOADING) {
    await onSetFileState(Number(artefactFiles?.pk), { state: UploadStateEnum.CANCELLED });
  }
  const { isAllImages, isAllPresentations, isAllowed } = checkMimeTypePresentation(files);

  if (!isAllImages && !isAllPresentations) {
    message.error(i18next.t<string>('errors.messages.upload.sameTypes'), messageDurability);
  }

  if (isAllowed) {
    setProgress(0.1);
    let fileId = null;

    if (!artefactFiles?.pk) {
      await onCreateFile('presentation')
        .then(async (response: any) => {
          if (response?.status === 401) {
            await onCreateFile('presentation')
              .then((resp) => {
                fileId = resp.id;
              })
              .catch(() => {
                fileId = null;
                callback();
              });
          } else {
            fileId = response.id;
          }
        })
        .catch(() => {
          fileId = null;
          callback();
        });
    } else {
      await onUpdateFile(artefactFiles?.file_type?.toLowerCase(), Number(artefactFiles.pk)).then(
        async (response: any) => {
          if (response?.status === 401) {
            await onUpdateFile(artefactFiles?.file_type?.toLowerCase(), Number(artefactFiles.pk));
          }
        }
      );

      fileId = Number(artefactFiles.pk);
    }

    if (fileId) {
      if (isAllImages) {
        await onSetFileState(fileId, { state: UploadStateEnum.UPLOADED });
      }
      if (artefactFileVersion === null || artefactFileVersion?.is_active) {
        await onCreateArtefactVersion({ file_id: fileId, artefact_id: Number(artId) })
          .then((newArt) => (fileId = newArt.file_id))
          .catch(() => {
            fileId = null;
            callback();
          });
      }

      if (isAllImages) {
        onUploadImages(files, fileId, callback, setProgress);
      }

      if (isAllPresentations) {
        if (files.length > 1) {
          message.error(i18next.t<string>('errors.messages.upload.onePresentation'), messageDurability);
        } else {
          const file = files[0];
          if (file) {
            onUploadPresentation(file, fileId, callback, setProgress);
          }
        }
      }
    }
  } else {
    message.error(i18next.t<string>('errors.messages.upload.allowedImagesFormats'), messageDurability);
  }
  clearFiles();
};
