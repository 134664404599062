import React, { memo, useMemo } from 'react';

import { isEmpty } from 'lodash';
import { cx, getIndicatorTooltipTitle } from 'utils';

import Tooltip from 'components/tooltip';

import getIconElementType from 'utils/getIconElementType';

import { IndicatorCourseElementGroupType, IndicatorProps } from './types';

import './styles.scss';
import { StateType } from '../../types/entities';

const renderIndicator = (
  className: string,
  inProgress: number,
  done: number,
  all: number,
  type: IndicatorCourseElementGroupType
) => {
  return (
    <div className={className}>
      <span className="ccm-indicator__from">{done ?? done}</span>
      <span className="ccm-indicator__to">{all === done ? null : `/${all}`}</span>
      {getIconElementType(type)}
    </div>
  );
};

function Indicator({ className, hasTooltip, element }: IndicatorProps) {
  const type = element.groupType;

  const inProgress = useMemo(
    () => element.items.filter((item) => item.state === StateType.IN_PROGRESS).length,
    [element.items]
  );

  const done = useMemo(() => element.items.filter((item) => item.state === StateType.DONE).length, [element.items]);

  const all = element?.items?.length;

  const classNames: string = cx('ccm-indicator', className, {
    [`ccm-indicator--in_progress`]: inProgress || (done !== 0 && done < all),
    [`ccm-indicator--done`]: all === done,
    [`ccm-indicator--tooltip`]: hasTooltip,
  });

  if (isEmpty(element.items)) {
    return null;
  }

  return hasTooltip ? (
    <Tooltip title={getIndicatorTooltipTitle(type, inProgress, done, all)}>
      {renderIndicator(classNames, inProgress, done, all, type)}
    </Tooltip>
  ) : (
    renderIndicator(classNames, inProgress, done, all, type)
  );
}

export default memo(Indicator);
