import { gql } from '@apollo/client';

export const GET_PIPELINES = gql`
  query ArtefactPipelines($companyId: Float) {
    artefact_pipelines(company__id: $companyId) {
      edges {
        node {
          id
          pk
          company_artefact_type {
            id
            name
            type
            learning_object_type {
              id
              name
              type
            }
          }
        }
      }
    }
  }
`;
