import { ReactNode } from 'react';
import * as React from 'react';

import JSSoup, { SoupTag } from 'jssoup';
import { omit } from 'lodash';
import { DefaultOptionType } from 'rc-select/lib/Select';

import { DocumentDiscussionsSet, PresentationFile } from '../pages/task/types';
import { OutputData } from '../components/editor/types';
import {
  BusinessRoleEnum,
  Company,
  CompanyUserItem,
  ProjectTaskAssignees,
  UserBase,
  UserExtended,
  UserExtendedSelect,
  UserRoleBase,
  UserRoles,
} from '../types/entities';
import { CcmUserState, TimezoneInfoType } from '../schemas/types';
import { CompanyRole, ProjectUserType } from '../hooks';
import { UsersState } from '../pages/addCourse/hooks/useAddCourseControl';
import { ProjectUserItem } from '../pages/courses/types';

import { checkUserBusinessRole } from './roles';

export const changeOrderSequence = (arr: PresentationFile[], currentOrder: number, newOrder: number) => {
  return arr.map((el) => {
    if (el.order === currentOrder) {
      return {
        id: el.id,
        order: newOrder,
      };
    }
    if (el.order === newOrder) {
      return {
        id: el.id,
        order: currentOrder,
      };
    }
    return {
      id: el.id,
      order: el.order,
    };
  });
};

const removeDataAttr = (data: OutputData, id: string): OutputData => {
  const temp = data.blocks.map((el) => {
    if (el.data.text) {
      return {
        ...el,
        data: {
          ...el.data,
          text: el.data.text.replace(`data-comment=\"${id}\"`, ''),
        },
      };
    } else {
      return el;
    }
  });

  return {
    ...data,
    blocks: temp,
  };
};

export const removeTempHtmlTags = (text: string) =>
  text
    .replace('<head>', '')
    .replace('</head>', '')
    .replace('<body>', '')
    .replace('</body>', '')
    .replace('</remove>', '')
    .replace('<remove>', '');

const replaceCommentChild = (text: string, comments?: DocumentDiscussionsSet[]) => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(text, 'text/html');
  const allNodes = htmlDoc.querySelectorAll('.mark-comment');

  allNodes?.forEach((node) => {
    const nodeId = node.getAttributeNode('id')?.value;
    const isValidId = comments?.find((c) => c.external_id === nodeId);

    if (!isValidId) {
      const nodeContent = node.textContent || '';
      const newElement = htmlDoc.createElement('remove');
      newElement.innerText = nodeContent;
      node?.parentNode?.replaceChild(newElement, node);
    }
  });

  return htmlDoc.documentElement.innerHTML;
};

export const getInitContent = (data: OutputData, comments?: DocumentDiscussionsSet[]) => {
  const tempBlocks = { ...data };

  const temp = data.blocks.map((b) => {
    if (b.type === 'paragraph' || b.type === 'header') {
      const text = b.data.text || '';
      const convertedToText = replaceCommentChild(text, comments);

      return {
        ...b,
        data: {
          ...b.data,
          text: removeTempHtmlTags(convertedToText),
        },
      };
    }

    if (b.type === 'list') {
      const list = b.data.items;
      const items = list?.map((text) => {
        const convertedToText = replaceCommentChild(text, comments);
        return removeTempHtmlTags(convertedToText);
      });
      return {
        ...b,
        data: {
          ...b.data,
          items,
        },
      };
    }
    if (b.type === 'table') {
      const list = b.data.content;
      const content = list?.map((c) => {
        return c.map((text) => {
          const convertedToText = replaceCommentChild(text, comments);
          return removeTempHtmlTags(convertedToText);
        });
      });
      return {
        ...b,
        data: {
          ...b.data,
          content,
        },
      };
    }
    if (b.type === 'image') {
      const caption = b.data.caption || '';
      const convertedToText = replaceCommentChild(caption, comments);
      return {
        ...b,
        data: {
          ...b.data,
          caption: removeTempHtmlTags(convertedToText),
        },
      };
    }
  });

  return {
    ...tempBlocks,
    blocks: temp,
  };
};

export const getExtractCommentId = (data: OutputData): { updatedData: OutputData; blockId: string; newId: string } => {
  let commentId = '';
  let blockId = '';

  data.blocks?.forEach((b) => {
    const recursiveExtractId = (contents: SoupTag[]) => {
      contents.forEach((s) => {
        if (s?.attrs?.['data-comment']) {
          commentId = s?.attrs['data-comment'];
          blockId = b.id;
          omit(s.attrs, 'data-comment');
          const target = document.getElementById(commentId);
          if (target) {
            target.removeAttribute('data-comment');
          }
        }
        if (s.contents) {
          recursiveExtractId(s.contents);
        }
      });
    };

    if (b.type === 'paragraph' || b.type === 'header') {
      const text = b.data.text || '';
      const soup = new JSSoup(text);

      recursiveExtractId(soup.contents);
    }

    if (b.type === 'list') {
      const list = b.data.items;
      list?.forEach((el) => {
        const soup = new JSSoup(el);
        recursiveExtractId(soup.contents);
      });
    }
    if (b.type === 'table') {
      const content = b.data.content;
      content?.forEach((c) => {
        c.forEach((text) => {
          const soup = new JSSoup(text);
          recursiveExtractId(soup.contents);
        });
      });
    }
    if (b.type === 'image') {
      const caption = b.data.caption || '';
      const soup = new JSSoup(caption);

      recursiveExtractId(soup.contents);
    }
  });

  return {
    newId: commentId,
    blockId,
    updatedData: removeDataAttr(data, commentId),
  };
};

export const arrStrToArrNum = (values?: string[]): number[] => {
  return values?.map((v) => Number(v)) || [];
};

export const userMapToSelect = (arr: UserExtended[]): UserExtendedSelect[] => {
  return arr
    ?.filter((u: UserExtended) => u.user.state === CcmUserState.Registered)
    ?.map((u) => {
      return {
        ...u,
        label: `${u?.user?.first_name} ${u?.user?.last_name}`,
        value: u?.id,
      };
    });
};

export const splitActiveUsersByRole = (arr: UserExtended[], roleId?: string | null) => {
  const noBlockedUsers = arr?.filter((u) => u.user.state !== CcmUserState.Blocked);
  const stakeholders = noBlockedUsers?.filter((user) =>
    user.business_roles?.some((u) => u.role === BusinessRoleEnum.stakeholder)
  );

  const producers = noBlockedUsers?.filter((user) =>
    user.business_roles?.some((u) => u.role === BusinessRoleEnum.manager)
  );

  const methodists = noBlockedUsers?.filter((user) =>
    user.business_roles?.some((u) => u.role === BusinessRoleEnum.instructionalDesigner)
  );

  const authors = noBlockedUsers?.filter((user) =>
    user.business_roles?.some((u) => u.role === BusinessRoleEnum.author)
  );

  const videoProductions = noBlockedUsers?.filter((user) =>
    user.business_roles?.some((u) => u.role === BusinessRoleEnum.videoProductionManager)
  );

  const executors = noBlockedUsers?.filter((user) =>
    user.business_roles?.some((u) => u.role === BusinessRoleEnum.productionExecutor)
  );

  const usersByRoleId = noBlockedUsers?.filter((user) => user.business_roles?.some((u) => u.id === roleId));

  return { stakeholders, producers, methodists, authors, videoProductions, executors, usersByRoleId };
};

export const splitProjectUsersByRole = (arr?: ProjectUserType[]) => {
  const noBlockedUsers = arr?.filter((u) => u?.company_user?.user?.state !== CcmUserState.Blocked);

  const authors = noBlockedUsers?.filter((user) => user.roles?.some((u) => u.role.role === BusinessRoleEnum.author));
  const executors = noBlockedUsers?.filter((user) =>
    user.roles?.some((u) => u.role.role === BusinessRoleEnum.productionExecutor)
  );
  const managers = noBlockedUsers?.filter((user) => user.roles?.some((u) => u.role.role === BusinessRoleEnum.manager));
  const instructionalDesigners = noBlockedUsers?.filter((user) =>
    user.roles?.some((u) => u.role.role === BusinessRoleEnum.instructionalDesigner)
  );

  return { authors, executors, managers, instructionalDesigners };
};

export const projectUsersToSelect = (arr?: ProjectUserType[]) => {
  return arr?.map((user) => {
    return {
      ...user,
      id: user.company_user.user.id,
      label: `${user.company_user?.user?.first_name} ${user.company_user?.user?.last_name}`,
      value: user.company_user.user.id,
    };
  });
};

// Need use to convert data
type BaseOptionType = {
  id: string | number;
  name: React.ReactNode;
  value?: string | number;
};

export const convertArrToSelectOptions = <T extends BaseOptionType>(array: T[]): DefaultOptionType[] => {
  return array?.map((el) => {
    return {
      id: el.id,
      label: el.name,
      value: el.id,
    };
  });
};

export const convertUserData = (user: UserExtended): ProjectUserItem => {
  const roles = user?.business_roles?.map((el) => {
    return {
      role: el,
    };
  });

  return {
    id: user?.id,
    roles: roles,
    company_user: {
      id: user?.id,
      company: {
        id: user?.company?.id,
        name: user?.company?.name,
      },
      user: {
        ...user?.user,
      },
    },
  };
};

export const filteredUsersByRoles = (users?: ProjectUserType[], role?: BusinessRoleEnum) => {
  return users?.filter((user) => !user.roles.some((r) => r.role.role === role));
};

export const filteredRolesAllowed = (companyRoles?: CompanyRole[], rolesNeedToRemove?: BusinessRoleEnum[]) => {
  return companyRoles?.filter((role) => rolesNeedToRemove?.some((r) => role.role === r))?.map((el) => el.id) || [''];
};

export const allowedRolesToSelect = (
  companyRoles?: CompanyRole[],
  businessRoles?: UserRoleBase[],
  isAdmin?: boolean
): string[] => {
  if (isAdmin) {
    return filteredRolesAllowed(companyRoles, [
      BusinessRoleEnum.author,
      BusinessRoleEnum.authorManager,
      BusinessRoleEnum.manager,
      BusinessRoleEnum.instructionalDesigner,
      BusinessRoleEnum.reviewer,
      BusinessRoleEnum.watcher,
      BusinessRoleEnum.reader,
      BusinessRoleEnum.videoProductionExecutor,
      BusinessRoleEnum.videoProductionManager,
      BusinessRoleEnum.productionExecutor,
      BusinessRoleEnum.stakeholder,
      BusinessRoleEnum.admin,
      BusinessRoleEnum.support,
      BusinessRoleEnum.support_manager,
      BusinessRoleEnum.learningTechnologist,
      BusinessRoleEnum.executiveManager,
    ]);
  }
  if (checkUserBusinessRole(businessRoles, BusinessRoleEnum.executiveManager)) {
    return filteredRolesAllowed(companyRoles, [
      BusinessRoleEnum.author,
      BusinessRoleEnum.authorManager,
      BusinessRoleEnum.manager,
      BusinessRoleEnum.instructionalDesigner,
      BusinessRoleEnum.reviewer,
      BusinessRoleEnum.watcher,
      BusinessRoleEnum.reader,
      BusinessRoleEnum.videoProductionExecutor,
      BusinessRoleEnum.videoProductionManager,
      BusinessRoleEnum.productionExecutor,
      BusinessRoleEnum.stakeholder,
      BusinessRoleEnum.admin,
      BusinessRoleEnum.support,
      BusinessRoleEnum.support_manager,
      BusinessRoleEnum.learningTechnologist,
    ]);
  }
  if (checkUserBusinessRole(businessRoles, BusinessRoleEnum.manager)) {
    return filteredRolesAllowed(companyRoles, [
      BusinessRoleEnum.author,
      BusinessRoleEnum.authorManager,
      BusinessRoleEnum.reviewer,
      BusinessRoleEnum.watcher,
      BusinessRoleEnum.reader,
      BusinessRoleEnum.videoProductionExecutor,
      BusinessRoleEnum.videoProductionManager,
      BusinessRoleEnum.productionExecutor,
      BusinessRoleEnum.stakeholder,
      BusinessRoleEnum.admin,
      BusinessRoleEnum.support,
      BusinessRoleEnum.support_manager,
    ]);
  }
  if (checkUserBusinessRole(businessRoles, BusinessRoleEnum.authorManager)) {
    return filteredRolesAllowed(companyRoles, [BusinessRoleEnum.author]);
  }
  if (checkUserBusinessRole(businessRoles, BusinessRoleEnum.videoProductionManager)) {
    return filteredRolesAllowed(companyRoles, [BusinessRoleEnum.productionExecutor]);
  }
  if (checkUserBusinessRole(businessRoles, BusinessRoleEnum.support_manager)) {
    return filteredRolesAllowed(companyRoles, [BusinessRoleEnum.support]);
  }
  return [];
};

export const convertProjectUsersToUsersExtended = (users?: ProjectUserType[]): UserExtended[] | undefined => {
  return users?.map((u) => {
    return {
      pk: u.company_user.id,
      id: u.company_user.id,
      user: u.company_user.user,
      company: u.company_user.company,
      business_roles: u.roles.map((r) => r.role),
      isActive: u.is_active,
      taskAmount: u.tasks_amount,
    };
  });
};

export const getPayloadChangeProjectUsersList = (users: UsersState) => {
  const projectUsers = Object.entries(users).map((el) => {
    return {
      role_id: el[0],
      project_users: el[1].map((u) => {
        return u?.pk;
      }),
    };
  });

  return {
    roles: projectUsers,
  };
};

export const convertRoles = (roles?: UserRoleBase[]): UserRoles[] | undefined => {
  if (!roles) {
    return undefined;
  }
  return roles.map((r) => {
    return {
      role: r,
    };
  });
};

type DefaultElementType = {
  id?: string;
  name?: string | null;
  [name: string]: any;
};

// type ArrToSelectOutput<T, U> = Omit<T, keyof U> & Omit<U, keyof T>;

export const arrToSelect = <T extends DefaultElementType, R>(
  arr?: T[]
): ({ label: any; value: any } & T)[] | undefined => {
  return arr?.map((el: T) => {
    return {
      label: el?.name,
      value: el?.id,
      ...el,
    };
  });
};

export const arrToSelectPlain = <T extends DefaultElementType, R>(
  arr?: T[]
): ({ label: any; value: any } & T)[] | undefined => {
  return arr?.map((el: T) => {
    return {
      label: el,
      value: el,
      ...el,
    };
  });
};

export const arrTimezonesToSelect = <T extends TimezoneInfoType, R>(
  arr?: T[]
): ({ label: any; value: any } & T)[] | undefined => {
  return arr?.map((el: T) => {
    return {
      label: `UTC ${el?.utc_offset} (${el?.tz_name})`,
      value: el.tz_name,
      ...el,
    };
  });
};
export const fileToDataUri = (file: any) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
  });
};

export function onlyUnique(value: string, index: number, self: string[]) {
  return self.indexOf(value) === index;
}

export function bytesToMb(bytes: number) {
  return parseFloat((bytes / 1048576).toFixed(2));
}

export const projectTaskAssigneesToCompanyUserItem = (assignee: ProjectTaskAssignees): CompanyUserItem => {
  return assignee?.project_user;
};

export const separateTaskAssigneeToUserExtended = (assignee: ProjectTaskAssignees[]) => {
  const users = assignee.map((u) => {
    const user = {
      ...u.project_user.company_user.user,
    } as UserBase;

    const businessRoles = u?.project_user?.roles.map((r) => r.role) as UserRoleBase[];
    const company = {
      ...u.project_user.company_user.company,
    } as Company;

    return {
      id: u?.project_user.company_user.id,
      pk: u?.project_user.company_user.id,
      is_active: u.is_active,
      user,
      business_roles: [...businessRoles],
      company,
    };
  });
  return users as UserExtended[];
};
