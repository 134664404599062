import { useState, useEffect } from 'react';

export default function useDebounce<T>(value: T, delay?: number, callback?: any) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      callback?.();
    }, delay || 500);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay, value]);

  return debouncedValue;
}
