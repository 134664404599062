import { gql } from '@apollo/client';

export const GET_ARTEFACT_ISSUES_VIDEO_LIST = gql`
  query GetVideoIssuesQuery($artefactId: Int, $ordering: String, $tasks_state: String) {
    artefact_content_video_found_issues(artefact_id: $artefactId, ordering: $ordering, tasks_state: $tasks_state) {
      edges {
        node {
          id
          created_at
          updated_at
          creator {
            id
            first_name
            last_name
            email
          }
          artefact {
            id
            artefact_type {
              id
              name
              type
              icon_image
            }
            artefact_pipeline {
              id
              actual_version {
                id
                is_actual
              }
            }
            estimate_capacity
            produced_capacity
            production_deadline
            artefact_versions {
              id
              created_at
              updated_at
              number
              is_active
              is_current
            }
            artefact_iteration_count
            last_artefact_iteration {
              id
              number
              state_initiation {
                id
                created_at
                updated_at
                is_rollback
              }
              is_active
              artefact_file_version {
                id
                created_at
                updated_at
                number
                is_active
                is_current
              }
            }
          }
          issue {
            id
            created_at
            updated_at
            creator {
              first_name
              last_name
              email
            }
            company {
              id
              created_at
              updated_at
              name
              slug
              company_type
            }
            name
            description
            image_url
            company_artefact_type {
              id
              name
              type
              icon_image
            }
            pk
          }
          project_task {
            id
            name
            description
            last_state {
              id
              name
              state_type
              created_at
              updated_at
              company_state_list_version {
                id
                company_state_list {
                  id
                  created_at
                  updated_at
                  name
                }
              }
              order
              is_new_iteration_initiation

              is_require_discuss_message
              projecttask_set {
                id
                name
                description
                task_type
                pk
                state_list_version_id
              }
              projecttaskstatehistory_set {
                id
                created_at
                updated_at
                is_rollback
              }
            }
            task_type
            assignees {
              id
              company_user {
                user {
                  id
                  first_name
                  last_name
                  email
                }
              }
              roles {
                role {
                  id
                  name
                  role
                }
              }
              is_active
            }
            pk
            state_list_version_id
            artefact_list {
              id
              estimate_capacity
              produced_capacity
              production_deadline
              artefact_iteration_count
            }
            project_task_state_history {
              id
              created_at
              updated_at
              state {
                id
                name
                state_type
                created_at
                updated_at
                order
                is_new_iteration_initiation
                is_require_discuss_message
              }
              is_rollback
              projecttaskstatediscussmessage_set {
                id
                created_at
                updated_at
                message
                is_active
              }
            }
            projecttaskassignee_set {
              id
            }
            discuss_messages {
              id
              created_at
              updated_at
              project_user {
                id
                is_active
                company_user {
                  user {
                    id
                    first_name
                    last_name
                    email
                  }
                }
              }
              message
              is_active
            }
          }
          artefact_version_creation {
            id
            created_at
            updated_at
            file {
              id
              is_active
              uploaded_by {
                id
                first_name
                last_name
                email
              }
              file_type
              name
              state
              pk
            }
            number
            is_active
            is_current
            artefactiteration_set {
              id
              number
              is_active
              pk
            }
          }
          artefact_version_solution {
            id
            created_at
            updated_at
            number
            is_active
            is_current
          }
          changed_by {
            id
            first_name
            last_name
            email
          }
          is_active
          timestamp_start
          timestamp_end
          is_timerange_changed
          pk
        }
      }
    }
  }
`;
