import { gql } from '@apollo/client';

export const GET_ME_INFO = gql`
  query Me {
    me {
      id
      name
      first_name
      last_name
      email
      state
      icon_image
      timezone
      #      is_staff
      companies {
        id
        pk
        company {
          id
          slug
          name
          company_type
          default_artefact_issue_list_version {
            id
          }
          default_project_phase_state_list_version {
            id
          }
          default_project_state_state_list_version {
            id
          }
          default_project_task_state_list_version {
            id
          }
          default_separate_task_state_list_version {
            id
          }
        }
        technical_role
        business_roles {
          id
          name
          role
        }
        created_at
        updated_at
      }
    }
  }
`;
