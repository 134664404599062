import React, { memo } from 'react';

import image from 'assets/image/empty-img-simple.svg';

import useCommonContext from 'hooks/useCommonContext';

type EmptyDataProps = {
  text?: string;
  hasFirstLoad?: boolean;
};

function EmptyData({ text, hasFirstLoad }: EmptyDataProps) {
  const common = useCommonContext();
  return (
    <div className="ccm-table__empty">
      {hasFirstLoad ? (
        <>
          <img src={image} alt="image" />
          <span className="ccm-table__empty-text">{text ? text : common.t<string>('pages.tasks.tasksNotFound')}</span>
        </>
      ) : null}
    </div>
  );
}

export default memo(EmptyData);
