import axios, { AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import { setupInterceptorsTo } from 'api/interceptors';

import {
  DEMO_API_URL,
  DEMO_RU_API_URL,
  DEV_AMAZON_API_URL,
  PROD_API_URL,
  PROD_CONSTRUCTOR,
  PROD_RU_API_URL,
  PROD_TEST,
} from '../constants';

import { ApiModules } from './constants';

const getBaseUrl = (type: ApiModules) => {
  return process.env.NODE_ENV === 'development'
    ? `${DEV_AMAZON_API_URL}/${type}/api/`
    : `${window.location.origin}/${type}/api/`;
};

const getInstance = (type: ApiModules) => {
  return setupInterceptorsTo(
    axios.create({
      baseURL: getBaseUrl(type),
      timeout: 25000,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  );
};

const API = (type: ApiModules) => {
  const instance = getInstance(type);
  return {
    get: <P, R>(url: string, config: AxiosRequestConfig = {}, params?: P): Promise<AxiosResponse<any> | R> =>
      instance.get(url, { params, responseType: 'json', ...config }).then((data) => data),
    post: <D, R>(url: string, payload?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<any> | R> =>
      instance.post<R>(url, payload, config).then((data) => data),
    patch: <D, R>(url: string, payload: D, config?: AxiosRequestConfig): Promise<AxiosResponse<any> | R> =>
      instance.patch(url, payload, config).then((data) => data),
    put: <D, R>(url: string, payload?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<any> | R> =>
      instance.put(url, payload, config).then((data) => data),
    delete: <D, R>(url: string, payload?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<any> | R> =>
      instance.delete(url, { ...config, data: payload }).then((data) => data),
  };
};

export const fetchImage = (src: string, callback: (data: any) => void) => {
  const instance = getInstance(ApiModules.ARTEFACT_MANAGEMENT);
  callback({ source: '', percent: 0, loading: true });
  const config = {
    responseType: 'arraybuffer',
    onDownloadProgress: (progressEvent: any) => {
      const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      callback({ source: '', percent: percentCompleted, loading: true });
    },
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  instance.get(src, config).then((response) => {
    const base64Src = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    callback({ source: `data:;base64,${base64Src}`, percent: null, loading: false });
  });
};

export default API;
