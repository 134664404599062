import React, { memo } from 'react';

import { Popconfirm as APopconfirm, PopconfirmProps } from 'antd';

import './styles.scss';

function Popconfirm({ children, ...props }: PopconfirmProps) {
  return (
    <APopconfirm
      className="ccm-popconfirm"
      okButtonProps={{ shape: 'round', size: 'middle' }}
      cancelButtonProps={{ shape: 'round', size: 'middle' }}
      icon={false}
      {...props}
    >
      {children}
    </APopconfirm>
  );
}

export default memo(Popconfirm);
