import React, { useState } from 'react';

import { Layout } from 'antd';
import { convertRoles, cx } from 'utils';
import Can, { AbilityContext } from 'config/Can';
import { PlusOutlined } from '@ant-design/icons';
import { PATH_NAMES } from 'router/constants';
import { buildAbilityFor } from 'config/ability';
import { isEmpty } from 'lodash';
import usePageFilters from 'pages/hooks/usePageFilters';

import Block from 'components/block';
import LoadMore from 'components/loadMore';
import Button from 'components/button/Button';
import { ButtonTypesEnum } from 'components/button/types';
import LoadingOverlay from 'components/loadingOverlay';

import useCommonContext from 'hooks/useCommonContext';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import useTextEmpty from 'hooks/useTextEmpty';

import Header from '../components/header/Header';
import EmptyData from '../../components/emptyData/EmptyData';

import useCoursesData from './hooks/useCoursesData';
import { CoursesTable } from './components/coursesTable';
import CoursesFilterContainer from './components/coursesFilterContainer';
import InfoDrawer from './components/infoDrawer';
import CoursesPageSkeleton from './Skeleton';
import CoursesFilterSkeleton from './components/coursesFilterContainer/CoursesFilterSkeleton';
import CoursesTableHeader from './components/coursesTableHeader';

import './styles.scss';

function CoursesPage() {
  const [show, setShow] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string>('');
  const { textEmptyTask } = useTextEmpty();
  const common = useCommonContext();
  const ability = buildAbilityFor(convertRoles(common.businessRoles));
  const filters = usePageFilters();

  const { courses, metrics, loading, hasFirstLoad, hasNextPage, onLoadMore } = useCoursesData({
    filter: {
      searchText: filters.text,
      delaysExist: filters.missed,
      delays: filters.delays,
      assignees: filters.assignees,
    },
  });

  useLockBodyScroll(loading);

  const onAddCourse = () => common.navigate(PATH_NAMES.addCourse.base);

  const onOpenInfo = (projId: string) => {
    const temp = courses.find((item) => item.pk === projId);
    if (temp) {
      setShow(true);
      setProjectId(temp?.pk);
    }
  };

  const onCloseInfo = () => setShow(false);

  const classNames = cx('courses-page__wrapper', {
    mb_16: !hasNextPage,
  });

  return (
    <AbilityContext.Provider value={ability}>
      <Layout className="courses-page">
        <Block empty hidden={hasFirstLoad}>
          <CoursesFilterSkeleton />
        </Block>
        <Block empty hidden={!hasFirstLoad}>
          <Header className="courses-page__header" title={common.t<string>('pages.courses.title')}>
            <Can I="manage" a="Courses.addCourse">
              <Button
                className="courses-page__add-course"
                onClick={onAddCourse}
                type={ButtonTypesEnum.primary}
                size="large"
              >
                <PlusOutlined style={{ fontSize: 14 }} />
                {common.t<string>('pages.courses.addCourse')}
              </Button>
            </Can>
          </Header>

          <CoursesFilterContainer companyMetricsData={metrics} />
        </Block>

        <Block empty hidden={hasFirstLoad}>
          <CoursesPageSkeleton />
        </Block>

        <Block hidden={!hasFirstLoad} className={classNames}>
          {isEmpty(courses) ? (
            <EmptyData title={textEmptyTask} />
          ) : (
            <>
              <CoursesTableHeader />
              <CoursesTable data={courses} onOpenDrawer={onOpenInfo} hasFirstLoad={hasFirstLoad} />
            </>
          )}

          <LoadMore hasNextPage={hasNextPage} onFetch={onLoadMore} />
        </Block>
        <LoadingOverlay show={loading && hasFirstLoad} text={common.t<string>('common.loadingData')} />
        <InfoDrawer show={show} projectId={projectId} onClose={onCloseInfo} />
      </Layout>
    </AbilityContext.Provider>
  );
}

export default CoursesPage;
