import { IAction } from '../../types/store';

import AppTypes from './types';
import { IAppState } from './interfacrs';

const initialState: IAppState = {
  leftPanel: false,
  rightPanel: false,
};

export default function (state = initialState, action: IAction<AppTypes>) {
  switch (action.type) {
    case AppTypes.SET_FIELD:
      const { field, value } = action.payload;
      return {
        ...state,
        [field]: value,
      };
    default:
      return state;
  }
}
