import React, { memo } from 'react';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

type WorkProgressProps = {
  production_progress: number;
  totalTasks: number;
  totalCompletedTasks: number;
  authorTotalTasks: number;
  authorTotalCompletedTasks: number;
  productionExecutorTotalTasks: number;
  productionExecutorTotalCompletedTasks: number;
};

function WorkProgress({
  production_progress,
  totalTasks,
  totalCompletedTasks,
  authorTotalTasks,
  authorTotalCompletedTasks,
  productionExecutorTotalTasks,
  productionExecutorTotalCompletedTasks,
}: WorkProgressProps) {
  const common = useCommonContext();
  return (
    <div className="info-drawer-work-progress">
      <div className="info-drawer-work-progress__title">{common.t<string>('pages.courses.drawer.workProgress')}</div>
      <div className="info-drawer-work-progress__row mb_8">
        <span className="info-drawer-work-progress__label">{common.t<string>('pages.courses.drawer.allTasks')}</span>
        <span className="info-drawer-work-progress__from">{`${totalCompletedTasks} ${common.t<string>(
          'pages.courses.drawer.from'
        )} ${totalTasks}`}</span>
        <span className="info-drawer-work-progress__value-large">{`${Math.ceil(production_progress)}%`}</span>
      </div>
      <div className="info-drawer-work-progress__row mb_16">
        <span className="info-drawer-work-progress__label">
          {common.t<string>('pages.courses.drawer.collectiveOfAuthors')}
        </span>
        <span className="info-drawer-work-progress__from">{`${authorTotalCompletedTasks} ${common.t<string>(
          'pages.courses.drawer.from'
        )} ${authorTotalTasks}`}</span>
        <span className="info-drawer-work-progress__value">
          {`${Math.ceil((authorTotalCompletedTasks * 100) / authorTotalTasks || 0)}%`}
        </span>
      </div>
      <div className="info-drawer-work-progress__row">
        <span className="info-drawer-work-progress__label">
          {common.t<string>('pages.courses.drawer.collectiveOfVideo')}
        </span>
        <span className="info-drawer-work-progress__from">{`${productionExecutorTotalCompletedTasks} ${common.t<string>(
          'pages.courses.drawer.from'
        )} ${productionExecutorTotalTasks}`}</span>
        <span className="info-drawer-work-progress__value">{`${Math.ceil(
          (productionExecutorTotalCompletedTasks * 100) / productionExecutorTotalTasks || 0
        )}%`}</span>
      </div>
    </div>
  );
}

export default memo(WorkProgress);
