import React, { memo } from 'react';

import { Upload as AUpload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { cx } from 'utils';
import { UploadProps } from 'antd/lib/upload/interface';

import useCommonContext from 'hooks/useCommonContext';

const { Dragger } = AUpload;

interface UploaderProps extends UploadProps {
  className?: string;
  onlyVideo?: boolean;
}

function Uploader({ className, onlyVideo, ...props }: UploaderProps) {
  const common = useCommonContext();
  const classNames = cx('ccm-upload', className);
  return (
    <Dragger id="slider-upload" {...props} className={classNames}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{common.t<string>('common.clickOrDrag')}</p>
      {onlyVideo ? (
        <p className="ant-upload-hint">{common.t<string>('common.uploadOnlyVideo')}</p>
      ) : (
        <p className="ant-upload-hint">{common.t<string>('common.uploadOnlyPresentation')}</p>
      )}
    </Dragger>
  );
}

export default memo(Uploader);
