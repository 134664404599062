import { gql } from '@apollo/client';

export const GET_LAZY_ARTEFACT_DEADLINES_CHANGED = gql`
  query LazyArtefactDeadlinesChanged(
    $project_id: Int
    $artefact_id: Int
    $separate_task_id: Int
    $deadline_type: String
  ) {
    artefact_deadlines_changed(
      project_id: $project_id
      artefact_id: $artefact_id
      separate_project_task_id: $separate_task_id
      deadline_type: $deadline_type
    ) {
      edges {
        node {
          id
          pk
          created_at
          created_date
          creator {
            id
            first_name
            last_name
          }
          deadline_new
        }
      }
    }
  }
`;
