/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ExportTextParams = {
    file_extension?: ExportTextParams.file_extension;
    export_text: boolean;
};

export namespace ExportTextParams {

    export enum file_extension {
        PDF = 'pdf',
        DOCX = 'docx',
    }


}
