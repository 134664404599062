import React, { useCallback, useEffect } from 'react';

import { cx } from 'utils';
import { isEmpty } from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';

import Title from 'components/title';
import Text from 'components/text';
import { TitleVariantEnum } from 'components/title/types';
import Block from 'components/block';
import AddMaterialBlock from 'components/addMaterialBlock';
import Spin from 'components/spin';

import { TextVariantEnum } from '../../components/text/types';
import DocumentFileBlock from '../../components/documentFileBlock';
import useLazyProjectTaskAssignees from '../../hooks/queries/useLazyProjectTaskAssignees';
import useCommonContext from '../../hooks/useCommonContext';

import CoursePreviewHeader from './CoursePreviewHeader';
import { CoursePreviewBaseProps } from './types';
import TypeDummy from './TypeDummy';
import TaskItem from './TaskItem';

import styles from './course.module.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

function CoursePreview({ node, count, projectId, onCloseCallback, onCancel }: CoursePreviewBaseProps) {
  const common = useCommonContext();
  const classNames = cx('ccm-modal--wrapper', styles.root);
  const { getProjectTaskAssignees, assignees, loading } = useLazyProjectTaskAssignees(Number(projectId));

  useEffect(() => {
    getProjectTaskAssignees();
  }, [getProjectTaskAssignees]);

  useEffect(() => {
    return () => {
      onCloseCallback?.();
    };
  }, [onCloseCallback]);

  const onClickLink = useCallback(() => {
    const link = `${document.URL}`;
    navigator.clipboard.writeText(link);
  }, []);

  const hasContent =
    Boolean(node?.data?.videoUrl) || Boolean(node?.data?.externalUrl) || Boolean(node.data?.documentFile);

  return (
    <div className={classNames}>
      <CoursePreviewHeader
        node={node}
        count={count}
        projectId={projectId}
        onClickLink={onClickLink}
        onCancel={onCancel}
      />
      <div className={styles.content}>
        <Title className="mb_16" variant={TitleVariantEnum.h5}>
          {node?.text}
        </Title>

        <Block empty hidden={!node?.data?.externalUrl}>
          <AddMaterialBlock className="mb_16" url={node?.data?.externalUrl} text={node?.data?.artefactTypeName} />
        </Block>

        <Block className="mb_24" hidden={!node.data?.documentFile}>
          <DocumentFileBlock documentFile={node.data?.documentFile} />
        </Block>

        <Block empty>
          <Block hidden={!node?.data?.videoUrl} className={styles.video}>
            <video width="952" src={node.data?.videoUrl} controls />
          </Block>
          <Block empty hidden={hasContent}>
            <TypeDummy type={node?.data?.artefactType || node?.data?.learningObjectType?.type} />
          </Block>
        </Block>

        {/*<Block className={styles.image} hidden={!imageUrl}>*/}
        {/*  <img src={imageUrl} alt="pic" />*/}
        {/*</Block>*/}
        <Block empty hidden={!node?.data?.description}>
          <span className={styles.contentTitle}>{common.t<string>('modals.description')}</span>
          <Text className="mb_16" variant={TextVariantEnum.h5}>
            {node?.data?.description}
          </Text>
        </Block>
        <Block empty hidden={!node?.data?.learningOutcomes}>
          <span className={styles.contentTitle}>{common.t<string>('modals.learningResult')}</span>
          <Text className="mb_16" variant={TextVariantEnum.h5}>
            {node?.data?.learningOutcomes}
          </Text>
        </Block>
        <Block className={styles.tasks} hidden={isEmpty(node?.data?.artefactSequence)}>
          {loading ? (
            <Spin indicator={antIcon} />
          ) : (
            <>
              <span className={styles.tasksTitle}>{common.t<string>('pages.project.tasks')}</span>
              {node?.data?.artefactSequence
                ?.filter((a) => !a.is_turn_off)
                ?.map((art, index) => {
                  return (
                    <TaskItem
                      key={index}
                      learningObjectId={node?.id.toString()}
                      projectId={projectId}
                      artefact={art}
                      assignees={assignees}
                      onClose={onCancel}
                    />
                  );
                })}
            </>
          )}
        </Block>
      </div>
    </div>
  );
}

export default CoursePreview;
