import API from 'api/api';
import { ApiModules, ENDPOINTS } from 'api/constants';
import { ArtefactVersionPayload, UpdatePresentationSequencePayload } from 'types/api';
import { PutUrlRequestType } from 'pages/task/subPages/universal/fetches';
import { UploadStateEnum } from 'types/entities';

import { CompanyArtefactType } from '../../types';
import { BlockBase } from '../../components/editor/types';

export type CreateFileResponse = {
  file_list: any;
  file_type: string;
  id: number;
  name: string | null;
  state: UploadStateEnum;
  uploaded_by: {
    id: number;
    first_name: string;
    last_name: string;
  };
};

type CreateFilePayload = {
  file_name: string;
};

export const onCreateFile = (
  fileType: CompanyArtefactType.type | string,
  payload?: CreateFilePayload
): Promise<any | CreateFileResponse> =>
  API(ApiModules.ARTEFACT_MANAGEMENT).post(ENDPOINTS.CREATE_FILE(fileType), payload);

export const onCreateArtefactVersion = (payload: ArtefactVersionPayload): Promise<any> =>
  API(ApiModules.PM).post(ENDPOINTS.CREATE_ARTEFACT_VERSION, payload);
export const onRemoveArtefactVersion = (artVerId: string): Promise<any> =>
  API(ApiModules.PM).delete(ENDPOINTS.UPDATE_ARTEFACT_VERSION(artVerId));
export const onUploadFile = (fileId: number, payload: any, config?: any): Promise<any> =>
  API(ApiModules.ARTEFACT_MANAGEMENT).put(ENDPOINTS.UPLOAD_FILE(fileId), payload, config);
export const onDeleteFile = (fileType: string, fileId: number): Promise<any> =>
  API(ApiModules.ARTEFACT_MANAGEMENT).put(ENDPOINTS.DELETE_FILE(fileType, fileId));
export const onRemoveFile = (fileType: string, fileId: number): Promise<any> =>
  API(ApiModules.ARTEFACT_MANAGEMENT).delete(ENDPOINTS.DELETE_FILE(fileType, fileId));
export const onUpdateFile = (fileType: string, fileId: number, payload?: any): Promise<any> =>
  API(ApiModules.ARTEFACT_MANAGEMENT).put(ENDPOINTS.UPDATE_FILE(fileType, fileId), payload);
export const onCopyFile = (fileId: number): Promise<any> =>
  API(ApiModules.ARTEFACT_MANAGEMENT).post(ENDPOINTS.COPY_FILE(fileId));
export const onPutFileUrl = (fileId: number, payload: PutUrlRequestType): Promise<any> =>
  API(ApiModules.ARTEFACT_MANAGEMENT).put(ENDPOINTS.PUT_FILE_URL(fileId), payload);
export const onPutFileUrlNew = (fileId: number, payload?: PutUrlRequestType): Promise<any> =>
  API(ApiModules.ARTEFACT_MANAGEMENT).put(ENDPOINTS.PUT_FILE_URL_NEW(fileId), payload);
export const onPutCheckListUrl = (
  artefactFileVersionId: number,
  documentFileId: number,
  payload: Array<{ file_document_section_id: number }>
): Promise<any> => API(ApiModules.PM).put(ENDPOINTS.PUT_CHECK_LIST_URL(artefactFileVersionId, documentFileId), payload);

export const onUploadSlide = (
  fileId: number,
  payload: any,
  updateProgress?: (progressEvent: any) => void
): Promise<any> =>
  API(ApiModules.ARTEFACT_MANAGEMENT).post(ENDPOINTS.UPLOAD_PRESENTATION_SLIDE(fileId), payload, {
    onUploadProgress: updateProgress,
  });
export const onDeleteSlide = (fileId: number, slideId: number): Promise<any> =>
  API(ApiModules.ARTEFACT_MANAGEMENT).delete(ENDPOINTS.DELETE_PRESENTATION_SLIDE(fileId, slideId));

export const onUpdateSlide = (
  fileId: number,
  slideId: number,
  payload: any,
  updateProgress?: (progressEvent: any) => void
): Promise<any> =>
  API(ApiModules.ARTEFACT_MANAGEMENT).put(ENDPOINTS.UPDATE_PRESENTATION_SLIDE(fileId, slideId), payload, {
    onUploadProgress: updateProgress,
  });

export const onUpdatePresentationSequence = (
  fileId: number,
  payload: UpdatePresentationSequencePayload[]
): Promise<any> => API(ApiModules.ARTEFACT_MANAGEMENT).put(ENDPOINTS.UPDATE_PRESENTATION_SEQUENCE(fileId), payload);

export const onSetFileState = (fileId: number, params: any): Promise<any> =>
  API(ApiModules.ARTEFACT_MANAGEMENT).put(ENDPOINTS.FILE_STATE(fileId), params);
export const onDeleteVideo = (fileId: number): Promise<any> =>
  API(ApiModules.ARTEFACT_MANAGEMENT).delete(ENDPOINTS.FILE_STATE(fileId));
export const onUpdateVideo = (fileId: number): Promise<any> =>
  API(ApiModules.ARTEFACT_MANAGEMENT).put(ENDPOINTS.FILE_STATE(fileId));
export const onGetFile = (fileId: number): Promise<any> =>
  API(ApiModules.ARTEFACT_MANAGEMENT).get(ENDPOINTS.GET_FILE(fileId));
export const onGetAsyncStatusFile = (fileId: number): Promise<any> =>
  API(ApiModules.PM).get(ENDPOINTS.GET_ASYNC_FILE_STATUS(fileId));

//Editor

type UpdateDocumentPayload = {
  version?: string;
  time?: number;
  blocks: BlockBase[];
};

export type UpdateDocumentResponse = {
  document_file_version_id: string;
};

export const onUpdateDocument = (fileId: string, payload: UpdateDocumentPayload) =>
  API(ApiModules.ARTEFACT_MANAGEMENT).put(ENDPOINTS.UPDATE_DOCUMENT(fileId), payload);

export const onUploadEditorImage = (payload: FormData) =>
  API(ApiModules.ARTEFACT_MANAGEMENT).post(ENDPOINTS.UPLOAD_IMAGE, payload);
