import React from 'react';

import { cx } from 'utils';

import Button from '../../../button';
import { ButtonTypesEnum } from '../../../button/types';
import Tooltip from '../../../tooltip';

interface IFooterModalProps {
  loading?: boolean;
  labelSubmit: string;
  onSubmit: () => void;
  labelCancel: string;
  onCancel: () => void;
  disabled?: boolean;
  disabledHint?: string;
  className?: string;
  isForm?: boolean;
}

const FooterModal = ({
  loading,
  labelSubmit,
  onSubmit,
  labelCancel,
  onCancel,
  disabled,
  className,
  isForm,
  disabledHint,
}: IFooterModalProps) => {
  const classNames = cx('ccm-modal__footer', className, {
    'ccm-modal__footer-form': isForm,
  });
  return (
    <div className={classNames}>
      <Button onClick={onCancel} type={ButtonTypesEnum.default} className="mr_8">
        {labelCancel}
      </Button>
      {disabled ? (
        <Tooltip placement="top" title={disabledHint}>
          <div>
            <Button onClick={onSubmit} htmlType="submit" disabled={disabled} type={ButtonTypesEnum.primary}>
              {labelSubmit}
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button
          loading={loading}
          onClick={onSubmit}
          htmlType="submit"
          disabled={disabled}
          type={ButtonTypesEnum.primary}
        >
          {labelSubmit}
        </Button>
      )}
    </div>
  );
};

export default FooterModal;
