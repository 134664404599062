import React, { memo } from 'react';

import { ExtraContentProps } from '../../types';

import ExtraContentItem from './ExtraContentItem';

function ExtraContent({
  depth,
  data = [],
  isEdit,
  learningObjectId,
  selected,
  onToggleCheckbox,
  onToggleTask,
}: ExtraContentProps) {
  if (!data || !data.length) {
    return null;
  }
  return (
    <div className="course-tree-plan-item__extra">
      {data?.map((item) => {
        return (
          <ExtraContentItem
            depth={depth}
            key={item.id}
            learningObjectId={learningObjectId}
            item={item}
            isEdit={isEdit}
            selected={selected}
            onToggleTask={onToggleTask}
            onToggleCheckbox={onToggleCheckbox}
          />
        );
      })}
    </div>
  );
}

export default memo(ExtraContent);
