import React, { useCallback, useEffect, useRef, useState } from 'react';

import { message, Skeleton } from 'antd';
import { useParams, useSearchParams } from 'react-router-dom';
import { RcFile } from 'antd/lib/upload/interface';
import withCommonProps from 'hocs/withCommonProps';
import { TemplateCommonProps } from 'common/commonPropsProvider';
import PresentationManagement from 'pages/components/presentationManagement';
import TaskErrors from 'pages/components/taskErrors/TaskErrors';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IStore } from 'types/store';
import { CarouselRef } from 'antd/lib/carousel';
import { checkMimeTypePresentation, cx } from 'utils';
import i18next from 'i18next';
import { changeRollbackPossibility } from 'api/requests/projectTask';
import { BusinessRoleEnum } from 'types/entities';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';

import Block from 'components/block/Block';
import TabTitleWithBadge from 'components/tabs/TabTitleWithBadge';
import Uploader from 'components/uploader';
import { TabPane, Tabs } from 'components/tabs';
import ProgressLoading from 'components/progressLoading';
import TabPaneSkeleton from 'components/tabs/TabPaneSkeleton';
import Button from 'components/button/Button';
import { ButtonTypesEnum } from 'components/button/types';

import { changeTaskStatusRequest, rollbackTaskStatusRequest } from 'store/projects/actions';
import { addIssueRequest } from 'store/issues/actions';

import useArtefactPresentationIssues from 'hooks/queries/useArtefactPresentationIssues';
import useProjectTask from 'hooks/queries/useProjectTask';
import useProjectUser from 'hooks/queries/useProjectUser';
import useArtefactContentIssues from 'hooks/queries/useArtefactContentIssues';
import useProjectStates from 'hooks/queries/useProjectStates';
import useTaskFlowSteps from 'hooks/queries/useTaskFlowSteps';
import useIssuesFlowSteps from 'hooks/queries/useIssuesFlowSteps';
import useTaskChangeHistory from 'hooks/queries/useTaskChangeHistory';
import useMaterials from 'hooks/queries/useMaterials';

import { CarouselDisplayEnum, ControlStateType } from '../../../components/presentationManagement/types';
import TaskHeader from '../../components/taskHeader';
import HistoryDrawer from '../../../components/historyDrawer/HistoryDrawer';
import { messageDurability } from '../../../../constants';
import Empty from '../../components/empty';
import SkeletonTabs from '../../components/skeleton/SkeletonTabs';
import MaterialItem from '../../../../components/materialItem';
import AppTypes from '../../../../store/app/types';

import { onUploadManagement } from './fetches';

import './styles.scss';

export const initCarouselState = {
  current: 1,
  currentSlideId: 1,
  display: CarouselDisplayEnum.single,
  coordinates: null,
  isOpen: false,
};

function TaskFinalPresentationPage({
  common,
  onRollbackTaskStatus,
  onAddIssue,
}: TemplateCommonProps & PropsFromRedux): JSX.Element {
  const dispatch = useDispatch();
  const [panel, setPanel] = useState<boolean>(true);
  const [carouselState, setCarouselState] = useState<ControlStateType>(initCarouselState);
  const [selectedIssue, setSelectedIssue] = useState<any>(null);
  const [filter, setFilter] = useState<string>('ALL');
  const [ordering, setOrdering] = useState<string>('by_slide_number');
  const [files, setFiles] = useState<RcFile[] | null>(null);
  const [progress, setProgress] = useState<number | null>(null);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const onOpenHistory = useCallback(() => setShowHistory(true), [setShowHistory]);
  const onCloseHistory = useCallback(() => setShowHistory(false), [setShowHistory]);
  const trigger = useRef<boolean | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { artefactId } = useParams();

  const issueA = searchParams.get('issue');
  const version = searchParams.get('version');
  const carouselRef = useRef<CarouselRef>(null);

  const onTogglePanel = useCallback(() => {
    setPanel(!panel);
  }, [panel]);

  useEffect(() => {
    dispatch({
      type: AppTypes.SET_FIELD,
      payload: {
        field: 'rightPanel',
        value: !panel,
      },
    });
  }, [dispatch, panel]);

  const {
    data,
    projectId,
    projectTask,
    currentArtefact,
    artefactFile,
    artefactFileVersion,
    taskStatus,
    refetchProjectTask,
    loadingProjectTask,
    currentProject,
  } = useProjectTask(version);
  const { materials } = useMaterials(Number(currentArtefact?.id));
  const currentProjectTaskArtefactTypeId = Number(projectTask?.project_task?.artefact_list?.[0]?.artefact_type?.id);
  const isCurrentExecutorEntity = projectTask?.project_task?.projecttaskassignee_set?.find(
    (assigner) => assigner.is_current_executor && assigner.is_active
  );

  const isCurrentExecutor =
    Number(isCurrentExecutorEntity?.project_user?.company_user?.user?.id) === Number(common?.userId);

  const { issuesTypesList } = useArtefactContentIssues(
    Number(data?.learning_object?.project?.company?.id),
    currentProjectTaskArtefactTypeId
  );

  const { issuesLoading, issuesRefetch, issuesList } = useArtefactPresentationIssues({
    artefactId: Number(artefactId),
    ordering,
    filter,
  });

  console.log(issuesLoading);
  console.log(issuesList, 'issuesList');

  const { userRole, userRoles, isProductExecutor, isMethodist, userRoleId } = useProjectUser(common?.userId, projectId);

  const projectTaskStateListVersionId = Number(currentProject?.project_task_state_list_version?.id);
  const projectIssuesStateListVersionId = Number(currentProject?.artefact_issue_state_list_version?.id);
  const currentArtefactTypeId = Number(currentArtefact?.artefact_type?.id);
  const currentTaskStatusId = Number(projectTask?.project_task?.last_state?.id);
  const { artefactFlowSteps } = useTaskFlowSteps({
    companyArtefactTypeId: currentArtefactTypeId,
    companyStateListVersionId: projectTaskStateListVersionId,
    initialStateId: currentTaskStatusId,
  });
  const { issuesFlowSteps } = useIssuesFlowSteps({
    companyArtefactTypeId: currentArtefactTypeId,
    companyStateListVersionId: projectIssuesStateListVersionId,
    requiredUserRoleIds: userRoleId ? [Number(userRoleId)] : null,
  });

  const { companyStates, companyIssuesStates } = useProjectStates(projectId);
  const changeFilter = (value: string) => {
    setFilter(value);
  };
  const changeOrder = (value: string) => {
    setOrdering(value);
  };

  const { taskChangeHistory, taskChangeHistoryRefetch } = useTaskChangeHistory(projectTask?.project_task?.id);

  const currentArtefactVersion = currentArtefact?.artefact_versions;

  const callbackAfterFetch = useCallback(() => {
    setFiles(null);
    trigger.current = false;
    refetchProjectTask()
      .then(() => setProgress(null))
      .catch(() => setProgress(null));
  }, [refetchProjectTask]);

  const clearFiles = () => setFiles(null);

  useEffect(() => {
    if (files && !trigger.current && currentArtefact?.id) {
      trigger.current = true;
      onUploadManagement(
        files,
        currentArtefact?.id,
        artefactFileVersion,
        artefactFile,
        callbackAfterFetch,
        setProgress,
        clearFiles
      );
    }
  }, [artefactFileVersion, artefactFile, callbackAfterFetch, currentArtefact?.id, files, refetchProjectTask]);

  const onBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
    const { isAllImages, isAllPresentations, isAllowed } = checkMimeTypePresentation(fileList);

    if (!isAllowed) {
      message.error(i18next.t<string>('errors.messages.upload.allowedImagesFormats'), messageDurability);
      clearFiles();
      return false;
    } else {
      if (isAllImages || isAllPresentations) {
        setFiles(fileList);
      } else {
        message.error(i18next.t<string>('errors.messages.upload.sameTypes'), messageDurability);
        clearFiles();
        return false;
      }
    }
  };

  const customRequest = () => null;

  const onSelectedIssue = (issue: any) => {
    setSelectedIssue(issue);
    setSearchParams({ issue: issue.pk });
  };
  const isCanRollback = projectTask?.project_task?.project_task_state_history?.is_rollback_possible;

  useEffect(() => {
    if (isCanRollback && isCurrentExecutor) {
      if (projectTask?.id) {
        changeRollbackPossibility(projectTask?.project_task?.id).then(() => refetchProjectTask());
      }
    }
  }, [isCanRollback]);

  useEffect(() => {
    if (issueA) {
      const issueCurrent = issuesList?.find((issue: any) => issue?.pk === issueA);
      if (issueCurrent && carouselState?.current !== issueCurrent?.slide_number) {
        const totalSlide = artefactFile?.related_files?.length || 0;
        setSelectedIssue(issueCurrent);
        carouselRef.current?.goTo?.(issueCurrent?.slide_number - 1);
        setCarouselState({
          ...carouselState,
          current: totalSlide < Number(issueCurrent?.slide_number) ? totalSlide : Number(issueCurrent?.slide_number),
        });
      }
    }
    // Don't change dependencies!!! It will bring carousel slides changes
  }, [issueA]);
  const unresolvedErrors = issuesList?.filter((error: any) => error?.project_task?.last_state?.state_type !== 'done');

  const tabItems = [
    {
      key: '1',
      label: (
        <TabPaneSkeleton loading={loadingProjectTask}>
          <TabTitleWithBadge title={common.t<string>('common.errors')} counter={unresolvedErrors?.length} />
        </TabPaneSkeleton>
      ),
      children: loadingProjectTask ? (
        <SkeletonTabs />
      ) : (
        <TaskErrors
          loading={issuesLoading}
          dataList={issuesList}
          selectedIssue={selectedIssue}
          onSelectedIssue={onSelectedIssue}
          isMethodist={isMethodist}
          isProductExecutor={isProductExecutor}
          companyStates={companyStates}
          companyIssuesStates={companyIssuesStates}
          issuesRefetch={issuesRefetch}
          issuesTypesList={issuesTypesList}
          isCurrentExecutor={isCurrentExecutor}
          userRole={userRole}
          userRoleId={userRoleId}
          currentTaskState={taskStatus}
          changeOrder={changeOrder}
          changeFilter={changeFilter}
          filter={filter}
          ordering={ordering}
          issuesFlowSteps={issuesFlowSteps}
        />
      ),
    },
    {
      key: '2',
      label: (
        <TabPaneSkeleton className="testclass-materials-tab" loading={loadingProjectTask}>
          {common.t<string>('common.materials')}
        </TabPaneSkeleton>
      ),
      children: (
        <div className="task-final-presentation__materials" style={{ padding: '0 24px' }}>
          <MaterialItem materials={materials} />
        </div>
      ),
    },
  ];

  const classNames = cx('task-page task-final-presentation', {
    'task-final-presentation__panel--closed': !panel,
  });

  return (
    <div className={classNames}>
      <TaskHeader
        loading={loadingProjectTask}
        onOpenHistory={onOpenHistory}
        iterations={currentArtefactVersion}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
        hintText={userRole === BusinessRoleEnum.instructionalDesigner ? 'common.completedIssues' : 'common.uploadAFile'}
        isHaveFile={Boolean(artefactFile?.related_files?.length)}
        onRollbackTaskStatus={onRollbackTaskStatus}
        projectTask={projectTask}
        refetchProjectTask={refetchProjectTask}
        userRole={userRole}
        userRoles={userRoles}
        issuesList={issuesList}
        artefactFlowSteps={artefactFlowSteps}
        artefactFile={artefactFile}
        onTaskHistoryRefetch={taskChangeHistoryRefetch}
      />
      <Block className="task-final-presentation__container pt_16 pb_14">
        <Block className="fullSize" hidden={!loadingProjectTask}>
          <Skeleton.Image className="fullSize" style={{ width: '100%', height: '100%' }} />
        </Block>

        <Block className="fullSize" hidden={!progress || loadingProjectTask}>
          <ProgressLoading progress={progress} />
        </Block>

        <Block
          className="fullSize testclass-upload"
          hidden={!!progress || loadingProjectTask || !!artefactFile?.related_files?.length}
        >
          {isCurrentExecutor ? (
            <Uploader multiple beforeUpload={onBeforeUpload} customRequest={customRequest} showUploadList={false} />
          ) : (
            <Empty />
          )}
        </Block>

        <Block empty hidden={!artefactFile?.related_files?.length}>
          <PresentationManagement
            className="pt_16"
            isCurrentExecutor={isCurrentExecutor}
            files={artefactFile}
            userRole={userRole}
            selectedIssue={selectedIssue}
            onSelectedIssue={onSelectedIssue}
            refetch={refetchProjectTask}
            issuesTypesList={issuesTypesList}
            onAddIssue={onAddIssue}
            currentArtefact={currentArtefact}
            dataList={issuesList}
            issuesRefetch={issuesRefetch}
            carouselRef={carouselRef}
            artefactFileVersion={artefactFileVersion}
            currentTaskState={taskStatus}
            carouselState={carouselState}
            setCarouselState={setCarouselState}
          />
        </Block>
      </Block>
      <Tabs
        className="task-final-presentation__tabs task-final-presentation__tabs-second"
        defaultActiveKey="1"
        items={!panel ? [] : tabItems}
        tabBarExtraContent={
          <Button
            size="large"
            className="task-sider__button"
            type={ButtonTypesEnum.text}
            icon={
              panel ? <DoubleRightOutlined style={{ fontSize: 20 }} /> : <DoubleLeftOutlined style={{ fontSize: 20 }} />
            }
            onClick={onTogglePanel}
          />
        }
      />

      <HistoryDrawer
        open={showHistory}
        onClose={onCloseHistory}
        data={taskChangeHistory}
        companyStates={companyStates}
      />
    </div>
  );
}

const connector = connect((state: IStore) => ({}), {
  onRollbackTaskStatus: rollbackTaskStatusRequest,
  onAddIssue: addIssueRequest,
});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withCommonProps(TaskFinalPresentationPage));
