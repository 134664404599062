import API from 'api/api';
import { ApiModules, ENDPOINTS } from 'api/constants';

import { IRefreshPayload, IResetPasswordPayload, LoginPayload, SignupPayload } from 'store/auth/interfaces';

export const onLogin = (payload: LoginPayload): Promise<any> => API(ApiModules.ACCOUNT).post(ENDPOINTS.LOGIN, payload);

export const onSignup = (payload: FormData): Promise<any> => API(ApiModules.ACCOUNT).post(ENDPOINTS.SIGNUP, payload);

// export const onGetMeInfo = (): Promise<any> => API(ApiModules.ACCOUNT).get(ENDPOINTS.ME);

export const onRefreshToken = (payload: IRefreshPayload): Promise<any> =>
  API(ApiModules.ACCOUNT).post(ENDPOINTS.REFRESH_TOKEN, payload);

export const onResetPassword = (payload: IResetPasswordPayload): Promise<any> =>
  API(ApiModules.ACCOUNT).post(ENDPOINTS.RESET_PASSWORD, payload);
