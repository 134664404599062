import { RcFile } from 'antd/lib/upload/interface';
import { onCreateFile, onSetFileState, onUpdateFile, onUploadFile } from 'api/requests/files';
import { chunkSize, createChunks } from 'utils';
import { message } from 'antd';
import i18next from 'i18next';
import { FileState, UploadStateEnum } from 'types/entities';
import { isEmpty } from 'lodash';

import { checkMimeTypeVideo } from 'utils/videos';

import { ArtefactTypeName } from 'store/projects/interfaces';

import { ArtefactFile, ArtefactFileIteration } from '../../types';
import { createArtefactVersion } from '../finalPresentation/fetches';
import { messageDurability } from '../../../../constants';

const onUploadVideo = async (
  file: RcFile,
  fileId: number,
  callback: () => void,
  setProgress: (data: number | null) => void,
  canceled: any
) => {
  const chunks = createChunks(file, chunkSize);
  let iterator = 1;
  let loaded = 0;
  let stopLoop = false;
  for await (const chunk of chunks) {
    const formData = new FormData();
    formData.append('part', iterator.toString());
    formData.append('file', chunk);

    if (!stopLoop) {
      if (!canceled?.current) {
        await onUploadFile(fileId, formData)
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          .then(async (re) => {
            loaded = loaded + 1;
            iterator = iterator + 1;
            setProgress((loaded / chunks.length) * 100);

            if (canceled?.current) {
              onSetFileState(fileId, { state: UploadStateEnum.CANCELLED })
                .then(() => callback())
                .catch(() => {
                  stopLoop = true;
                  callback();
                });
            }

            if (loaded === chunks.length) {
              console.log('Process is complete, counter', loaded);
              await onSetFileState(fileId, { state: UploadStateEnum.UPLOADED })
                .then(() => callback())
                .catch(() => {
                  stopLoop = true;
                  callback();
                });
            }
          })
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          .catch(async (error) => {
            stopLoop = true;
            console.log('Error Occurred:', error);
            await onSetFileState(fileId, { state: UploadStateEnum.CANCELLED })
              .then(() => callback())
              .catch(() => callback());
          });
      }
    } else {
      break;
    }
  }
};

export const onUploadVideoManagement = async (
  file: RcFile,
  artId: string | undefined,
  artefactFileVersion: ArtefactFileIteration | null,
  artefactFiles: ArtefactFile | null,
  callback: () => void,
  setProgress: (data: number | null) => void,
  skipCheckFile: boolean,
  canceled: any
) => {
  const { isAllowed } = checkMimeTypeVideo(file);
  let fileId: string | null = null;
  if (artefactFiles?.state === FileState.uploading || artefactFiles?.state === FileState.UPLOADING) {
    await onSetFileState(Number(artefactFiles?.pk), { state: UploadStateEnum.CANCELLED });
  }
  const createNewFile = async () => {
    await onCreateFile(ArtefactTypeName.video)
      .then(async (resp) => {
        if (resp?.status === 401) {
          await onCreateFile(ArtefactTypeName.video)
            .then((response) => {
              fileId = response?.id;
            })
            .catch(() => {
              callback();
            });
        } else {
          fileId = resp?.id;
        }
      })
      .catch(() => callback());
  };
  const createNewArtVersion = async () => {
    await createArtefactVersion({ file_id: Number(fileId), artefact_id: Number(artId) })
      .then((newArt) => (fileId = newArt.file_id))
      .catch(() => {
        fileId = null;
        callback();
      });
  };

  if (isAllowed) {
    setProgress(0.1);

    if (artefactFiles?.state === FileState.uploading) {
      await onSetFileState(Number(artefactFiles?.pk), { state: UploadStateEnum.CANCELLED });
      await createNewFile();
      if (file) {
        await onUploadVideo(file, Number(fileId), callback, setProgress, canceled);
      }
    } else {
      if (!artefactFiles?.pk) {
        await createNewFile();
      } else {
        await onUpdateFile(artefactFiles?.file_type?.toLowerCase(), Number(artefactFiles.pk)).then(
          async (response: any) => {
            if (response?.status === 401) {
              await onUpdateFile(artefactFiles?.file_type?.toLowerCase(), Number(artefactFiles.pk));
            }
          }
        );
        fileId = artefactFiles?.pk;
      }

      if (skipCheckFile) {
        await createNewFile();
        if (fileId) {
          await createNewArtVersion();
        }
      } else {
        if (fileId) {
          if (isEmpty(artefactFileVersion) || artefactFileVersion === null || artefactFileVersion?.is_active) {
            await createNewArtVersion();
          }
        }
      }

      if (fileId) {
        if (file) {
          await onUploadVideo(file, Number(fileId), callback, setProgress, canceled);
        }
      }
    }
  } else {
    message.error(i18next.t<string>('errors.messages.upload.allowedVideoFormats'), messageDurability);
  }
};
