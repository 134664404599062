import React from 'react';

import { Timeline } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

const TimelineStories = () => {
  return (
    <div className="mb_8">
      <Timeline className="mb_8 ccm-timeline">
        <Timeline.Item className="ccm-timeline__item">
          <span className="ccm-timeline__title">Network problems being solved</span>{' '}
          <span className="ccm-timeline__date">2015-09-01</span>
        </Timeline.Item>
        <Timeline.Item className="ccm-timeline__item">
          <span className="ccm-timeline__title">Network problems being solved</span>{' '}
          <span className="ccm-timeline__date">2015-09-01</span>
        </Timeline.Item>
        <Timeline.Item className="ccm-timeline__item">
          <span className="ccm-timeline__title">Network problems being solved</span>{' '}
          <span className="ccm-timeline__date">2015-09-01</span>
        </Timeline.Item>
        <Timeline.Item className="ccm-timeline__item">
          <span className="ccm-timeline__title">Network problems being solved</span>{' '}
          <span className="ccm-timeline__date">2015-09-01</span>
        </Timeline.Item>
      </Timeline>
      <Timeline className="mb_8 ccm-timeline" mode="right">
        <Timeline.Item className="ccm-timeline__item">
          <span className="ccm-timeline__title">Network problems being solved</span>{' '}
          <span className="ccm-timeline__date">2015-09-01</span>
        </Timeline.Item>
        <Timeline.Item className="ccm-timeline__item">
          <span className="ccm-timeline__title">Network problems being solved</span>{' '}
          <span className="ccm-timeline__date">2015-09-01</span>
        </Timeline.Item>
        <Timeline.Item className="ccm-timeline__item">
          <span className="ccm-timeline__title">Network problems being solved</span>{' '}
          <span className="ccm-timeline__date">2015-09-01</span>
        </Timeline.Item>
        <Timeline.Item className="ccm-timeline__item">
          <span className="ccm-timeline__title">Network problems being solved</span>{' '}
          <span className="ccm-timeline__date">2015-09-01</span>
        </Timeline.Item>
      </Timeline>
      <Timeline className="mb_8 ccm-timeline">
        <Timeline.Item
          dot={<ClockCircleOutlined style={{ fontSize: '16px', background: 'transparent' }} />}
          color="#848484"
          className="ccm-timeline__item"
        >
          <span className="ccm-timeline__title">Network problems being solved</span>{' '}
          <span className="ccm-timeline__date">2015-09-01</span>
        </Timeline.Item>
        <Timeline.Item
          dot={<ClockCircleOutlined style={{ fontSize: '16px', background: 'transparent' }} />}
          color="#848484"
          className="ccm-timeline__item"
        >
          <span className="ccm-timeline__title">Network problems being solved</span>{' '}
          <span className="ccm-timeline__date">2015-09-01</span>
        </Timeline.Item>
        <Timeline.Item
          dot={<ClockCircleOutlined style={{ fontSize: '16px', background: 'transparent' }} />}
          color="#848484"
          className="ccm-timeline__item"
        >
          <span className="ccm-timeline__title">Network problems being solved</span>{' '}
          <span className="ccm-timeline__date">2015-09-01</span>
        </Timeline.Item>
        <Timeline.Item className="ccm-timeline__item">
          <span className="ccm-timeline__title">Network problems being solved</span>{' '}
          <span className="ccm-timeline__date">2015-09-01</span>
        </Timeline.Item>
      </Timeline>

      <Timeline className="mb_8 ccm-timeline" mode="left">
        <Timeline.Item label="2015-09-01">Create a services</Timeline.Item>
        <Timeline.Item label="2015-09-01 09:12:11">Solve initial network problems</Timeline.Item>
        <Timeline.Item>Technical testing</Timeline.Item>
        <Timeline.Item label="2015-09-01 09:12:11">Network problems being solved</Timeline.Item>
      </Timeline>
    </div>
  );
};

export default TimelineStories;
