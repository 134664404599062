import { gql } from '@apollo/client';

export const GET_LEARNING_OBJECTS_PLAN = gql`
  query LearningObjectsPlan($project_id: Int, $is_active: Boolean, $plan_filters: PlanFilter) {
    learning_objects(project_id: $project_id, is_active: $is_active, plan_filters: $plan_filters) {
      edges {
        node {
          id
          pk
          name
          description
          is_active
          is_active_changed_at
          production_progress
          learning_object_type {
            id
            name
            type
            icon_image
          }
          project {
            id
            name
            production_progress
          }
          project_learning_structure_type {
            id
            name
            level_type
            pk
          }
          project_learning_outcome {
            id
            description
            pk
          }
          parent {
            id
            created_at
            updated_at
            order
            depth
            absolute_order
            human_readable_number
            project_learning_object_parent {
              id
            }
          }
        }
      }
    }
    project_learning_object_final_artefacts(project_id: $project_id, plan_filters: $plan_filters) {
      project_learning_object {
        id
      }
      final_artefact {
        id
        cached_task {
          id
          last_state {
            id
            state_type
          }
        }
        artefact_type {
          id
          created_at
          updated_at
          name
          type
          icon_image
        }
        artefact_pipeline {
          id
          actual_version {
            id
            is_actual
          }
          company_artefact_type {
            id
            created_at
            updated_at
            name
            type
            icon_image
          }
        }
        artefact_versions {
          id
          created_at
          updated_at
          artefact {
            id
            estimate_capacity
            produced_capacity
            production_deadline
            artefact_iteration_count
          }
          file {
            id
            is_active
            related_files {
              ... on PresentationFileSlideType {
                id
                source_url
                is_active
                order
              }
              ... on VideoFileType {
                id
                source_url
                resolution
                is_source
              }
            }
            uploaded_by {
              id
              first_name
              last_name
              email
            }
            file_type
            name
            state
            pk
          }
          number
          is_active
          is_current
        }
      }
    }
    current_artefact_production_sequence(project_id: $project_id, plan_filters: $plan_filters) {
      id
      is_turn_off
      project_learning_object {
        id
      }
      project_task {
        id
        name
        last_state_is_changed
        last_state_updated_at
        last_state {
          id
          state_type
          name
          updated_at
        }
        cached_linked_artefact {
          id
          artefact_iteration_count
          first_iteration_deadline
          first_iteration_deadline_delay
          first_iteration_deadline_changed
          production_deadline
          production_deadline_delay
          production_deadline_changed
          artefact_type {
            id
            type
            name
            icon_image
          }
          artefact_versions {
            file {
              file_type
            }
          }
        }
      }
    }
    project_task_assignees(project_id: $project_id) {
      edges {
        node {
          id
          is_active
          is_current_executor
          project_task {
            id
          }
          project_user {
            id
            is_active
          }

          by_artefact_role_assignment {
            is_executor
            user_role {
              id
              name
              role
            }
          }
        }
      }
    }
  }
`;
