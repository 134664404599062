import { useMemo, useRef } from 'react';

import { useQuery } from '@apollo/client';
import { ProjectSeparateTaskItem } from 'types/entities/separate';
import { GET_SEPARATE_TASK } from 'queries/getSeparateTask';
import { FileType } from 'types/entities';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';

import { DocumentFileVersionType } from '../../pages/task/types';

type SeparateTaskData = {
  separate_task: ProjectSeparateTaskItem;
};

function useSeparateTaskQuery(taskId?: string, fetchPolicy?: WatchQueryFetchPolicy) {
  const hasFirstLoad = useRef<boolean>(false);
  const onCompleted = () => {
    if (!hasFirstLoad.current) {
      hasFirstLoad.current = true;
    }
  };

  const { data, refetch } = useQuery<SeparateTaskData>(GET_SEPARATE_TASK, {
    variables: {
      task_id: Number(taskId),
    },
    skip: !taskId,
    fetchPolicy: fetchPolicy || 'network-only',
    onCompleted: onCompleted,
  });

  const documentData = useMemo(() => {
    const document = data?.separate_task.active_artefacts?.find((a) =>
      a.artefact_versions?.find((v) => v.file.file_type === FileType.DOCUMENT)
    );
    return document?.artefact_versions?.[0] || null;
  }, [data?.separate_task.active_artefacts]);

  const editorData = useMemo(() => {
    const document = data?.separate_task.active_artefacts?.find((a) =>
      a.artefact_versions?.find((v) => v.file.file_type === FileType.DOCUMENT)
    );
    const relatedFiles = document?.artefact_versions?.[0].file.related_files?.[0] as DocumentFileVersionType;
    return relatedFiles?.original_data || null;
  }, [data?.separate_task.active_artefacts]);

  const commonFiles = useMemo(() => {
    const files = data?.separate_task.active_artefacts?.filter((a) =>
      a.artefact_versions?.find((v) => v.file.file_type === FileType.FILE)
    );
    return files?.map((f) => f.artefact_versions?.[0]) || null;
  }, [data?.separate_task.active_artefacts]);

  return {
    hasFirstLoad: hasFirstLoad.current,
    separateTask: data?.separate_task as ProjectSeparateTaskItem,
    editorData,
    documentData,
    commonFiles,
    separateTaskRefetch: refetch,
  };
}

export default useSeparateTaskQuery;
