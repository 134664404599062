import React, { useCallback, useState } from 'react';

import { PATH_NAMES } from 'router/constants';
import { isEmpty } from 'lodash';
import Can from 'config/Can';
import Empty from 'pages/task/components/empty';
import { DashboardCourseItem, DashboardCoursesWidget } from 'pages/dashboard/types';

import LoadMore from 'components/loadMore';
import Block from 'components/block';
import LoadingOverlay from 'components/loadingOverlay';

import useCommonContext from 'hooks/useCommonContext';

import BlockHeader from '../blockHeader';
import DashboardWidget from '../widget';
import EntityBlock from '../entityBlock';
import CourseItem from '../../../components/courseItem';
import InfoDrawer from '../../../courses/components/infoDrawer';

type BaseData = {
  data?: DashboardCourseItem[];
  hasNextPage?: boolean;
  fetchMore?: () => void;
};

type DashboardCoursesProps = {
  coursesLoading: boolean;
  allDataReady: boolean;
  coursesWidgetData: DashboardCoursesWidget | undefined;
  coursesNoAssigneesData: BaseData;
  coursesWithIssuesData: BaseData;
  coursesWithDeadlineData: BaseData;
};

function DashboardCourses({
  coursesLoading,
  allDataReady,
  coursesWidgetData,
  coursesNoAssigneesData,
  coursesWithIssuesData,
  coursesWithDeadlineData,
}: DashboardCoursesProps) {
  const common = useCommonContext();
  const [show, setShow] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string>('');
  const onCloseInfo = () => setShow(false);

  const onOpenInfo = useCallback((projId: string) => {
    setProjectId(projId);
    setShow(true);
  }, []);

  const showEmpty =
    isEmpty(coursesNoAssigneesData.data) &&
    isEmpty(coursesWithIssuesData.data) &&
    isEmpty(coursesWithDeadlineData.data);

  return (
    <div className="dashboard-courses mb_32">
      <BlockHeader
        className="mb_16"
        title={common.t<string>('pages.dashboard.courses.title')}
        path={PATH_NAMES.courses.base}
        count={coursesWidgetData?.available}
      />
      <div className="page-dashboard-widgets mb_32">
        <DashboardWidget
          label={common.t<string>('pages.dashboard.coursesProduction')}
          value={coursesWidgetData?.in_production}
        />
        <DashboardWidget
          label={common.t<string>('pages.dashboard.withDelay')}
          value={coursesWidgetData?.with_delays}
          type="danger"
        />
        <DashboardWidget
          label={common.t<string>('pages.dashboard.needAttention')}
          value={coursesWidgetData?.require_attention}
          type="warning"
        />
      </div>

      <Block className="page-dashboard__empty-wrap" hidden={!showEmpty}>
        <Empty text={common.t<string>('pages.dashboard.courses.empty')} />
      </Block>

      <Block empty hidden={showEmpty}>
        <Can I="view" a="Dashboard.courses.noAssignees">
          <EntityBlock
            hidden={isEmpty(coursesNoAssigneesData?.data)}
            title={common.t<string>('pages.dashboard.courses.notAssignedPerformers')}
          >
            <>
              {coursesNoAssigneesData?.data?.map((el) => {
                return <CourseItem key={el.id} item={el} onOpenDrawer={onOpenInfo} />;
              })}
              <LoadMore hasNextPage={coursesNoAssigneesData.hasNextPage} onFetch={coursesNoAssigneesData?.fetchMore} />
            </>
          </EntityBlock>
        </Can>
        <Can I="view" a="Dashboard.courses.hasIssues">
          <EntityBlock
            title={common.t<string>('pages.dashboard.courses.hasIssues')}
            hidden={isEmpty(coursesWithIssuesData?.data)}
          >
            <>
              {coursesWithIssuesData?.data?.map((el) => {
                return <CourseItem key={el.id} item={el} onOpenDrawer={onOpenInfo} />;
              })}
              <LoadMore hasNextPage={coursesWithIssuesData.hasNextPage} onFetch={coursesWithIssuesData?.fetchMore} />
            </>
          </EntityBlock>
        </Can>
        <Can I="view" a="Dashboard.courses.deadline">
          <EntityBlock
            title={common.t<string>('pages.dashboard.courses.weeksDeadline')}
            hidden={isEmpty(coursesWithDeadlineData?.data)}
          >
            <>
              {coursesWithDeadlineData?.data?.map((el) => {
                return <CourseItem key={el.id} item={el} onOpenDrawer={onOpenInfo} />;
              })}
              <LoadMore
                hasNextPage={coursesWithDeadlineData.hasNextPage}
                onFetch={coursesWithDeadlineData?.fetchMore}
              />
            </>
          </EntityBlock>
        </Can>
        <InfoDrawer show={show} projectId={projectId} onClose={onCloseInfo} />
      </Block>
      {allDataReady ? <LoadingOverlay show={coursesLoading} text={common.t<string>('common.loadingData')} /> : null}
    </div>
  );
}

export default DashboardCourses;
