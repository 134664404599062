import React from 'react';

import HeaderNavigation from 'pages/components/headerNavigation';
import { navMap } from 'pages/components/header/constants';

function MainLayoutMenu() {
  return <HeaderNavigation hasMain list={navMap} />;
}

export default MainLayoutMenu;
