import { useQuery } from '@apollo/client';

import { GET_TIMEZONES } from '../../queries/getTimezones';
import { arrTimezonesToSelect } from '../../utils';

function useTimezones() {
  const { data, loading, refetch } = useQuery(GET_TIMEZONES, {
    fetchPolicy: 'cache-and-network',
  });

  return {
    timezones: arrTimezonesToSelect(data?.timezones?.all),
    loadingTimezones: loading,
    taskTimezonesRefetch: refetch,
  };
}

export default useTimezones;
