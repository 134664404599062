import { useQuery } from '@apollo/client';

import { GET_COLLABORATORS_BY_ROLE } from '../../queries/getCollaboratorsByRole';
import { UserExtended } from '../../types/entities';

type CollaboratorsByRoleProps = {
  userRoleId?: number | string | null;
};

type CollaboratorsByRole = {
  collaborators_by_role: UserExtended[];
};

function useCollaboratorsByRoleQuery({ userRoleId }: CollaboratorsByRoleProps) {
  const { data, refetch, loading } = useQuery<CollaboratorsByRole>(GET_COLLABORATORS_BY_ROLE, {
    variables: {
      userRoleId,
    },
    skip: !userRoleId && userRoleId !== null,
  });

  return {
    collaborators: data?.collaborators_by_role,
    collaboratorsRefetch: refetch,
    collaboratorsLoading: loading,
  };
}

export default useCollaboratorsByRoleQuery;
