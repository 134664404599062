import React, { memo, useCallback, useMemo } from 'react';

import { Form, Select as ASelect } from 'antd';

import { calculateHeight, cx } from '../../utils';

import { ISelectProps } from './types';

import './styles.scss';

const Select = ({
  options,
  className,
  testClassName,
  classNameDropdown,
  name,
  label,
  rules,
  noForm,
  fullWidth,
  mode,
  size,
  onClearByName,
  ...props
}: ISelectProps) => {
  const classNames = cx('ccm-select', className, testClassName, {
    [`ccm-select--${name}`]: name,
    'ccm-select--no-form': noForm,
    'ccm-select--full': fullWidth,
    [`ccm-select--${size}`]: size,
  });

  const classNamesDropdown = cx('ccm-select-dropdown', classNameDropdown);

  const styles = useMemo(() => {
    return {
      minHeight: `${calculateHeight(Boolean(label), Boolean(noForm), size)}px`,
    };
  }, [label, noForm, size]);

  const handleClear = useCallback(() => {
    if (onClearByName && name) {
      onClearByName(name);
    }
  }, [name, onClearByName]);

  if (noForm) {
    return (
      <ASelect
        size={size}
        className={classNames}
        options={options}
        mode={mode}
        popupClassName={classNamesDropdown}
        {...props}
        style={{ ...styles, ...props.style }}
        onClear={handleClear}
      />
    );
  }

  return (
    <Form.Item
      style={styles}
      label={label}
      name={name}
      className={classNames}
      colon={false}
      labelAlign="left"
      rules={rules}
    >
      <ASelect
        size={size}
        options={options}
        mode={mode}
        popupClassName={classNamesDropdown}
        {...props}
        onClear={handleClear}
      />
    </Form.Item>
  );
};

export default memo(Select);
