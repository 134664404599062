import React from 'react';

import withCommonProps from 'hocs/withCommonProps';

import { TemplateCommonProps } from '../../common/commonPropsProvider';
import getIconElementType from '../../utils/getIconElementType';

import { DummyTypeProps } from './types';

import styles from './course.module.scss';

function TypeDummy({ type, common }: DummyTypeProps & TemplateCommonProps) {
  return (
    <div className={styles.videoDummy}>
      {getIconElementType(type, styles.videoDummy__icon)}
      <span className={styles.videoDummy__text}>{common.t<string>(`courseType.will_${type}`)}</span>
    </div>
  );
}

export default withCommonProps(TypeDummy);
