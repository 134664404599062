import React from 'react';

import { ProjectVersionType } from 'schemas/types';

import useCommonContext from 'hooks/useCommonContext';

import FormatView from '../formatView';
import ElementsForDownload from '../elementsForDownload';
import { structureCourseOptions, textCourseOptions, videoResolutionOptions } from '../../constants';
import { UseExportOutput } from '../../../../hooks/useExport';
import VersionView from '../versionView';

import './styles.scss';

type DownloadDrawerContentProps = {
  title?: string;
  version: ProjectVersionType;
  exportInstance: UseExportOutput;
};

function DownloadDrawerContent({ title, exportInstance, version }: DownloadDrawerContentProps) {
  const common = useCommonContext();
  return (
    <div className="download-drawer-content">
      <FormatView />
      <span className="download-drawer-content__title">{title}</span>
      {version && <VersionView version={version} />}
      <span className="download-drawer-content__sub-title">
        {common.t<string>('pages.project.export.itemsToDownload')}
      </span>
      <ElementsForDownload
        name="structure"
        label={common.t<string>('pages.project.export.courseStructure')}
        radioOptions={structureCourseOptions}
        checked={exportInstance.elements.structure.selected}
        valueRadio={exportInstance.elements.structure.radio}
        onChangeSectionSelect={exportInstance.onChangeSelectItem}
        onChangeTextType={exportInstance.onChangeTextType}
      />
      <ElementsForDownload
        name="video"
        label={common.t<string>('pages.project.export.video')}
        type="select"
        disabled={!exportInstance.learningObjectDone.video.length}
        count={exportInstance.learningObjectSelected.video.length}
        selectOptions={videoResolutionOptions}
        checked={exportInstance.elements.video.selected}
        valueSelect={exportInstance.elements.video.value}
        onChangeSectionSelect={exportInstance.onChangeSelectItem}
        onChangeVideoResolution={exportInstance.onChangeVideoResolution}
      />
      <ElementsForDownload
        name="addMaterials"
        label={common.t<string>('pages.project.export.additionalMaterials')}
        radioOptions={textCourseOptions}
        disabled={!exportInstance.learningObjectDone.text.length}
        count={exportInstance.learningObjectSelected.text.length}
        checked={exportInstance.elements.addMaterials.selected}
        valueRadio={exportInstance.elements.addMaterials.radio}
        onChangeSectionSelect={exportInstance.onChangeSelectItem}
        onChangeTextType={exportInstance.onChangeTextType}
      />
      <ElementsForDownload
        name="tasks"
        label={common.t<string>('pages.project.export.tasks')}
        disabled={!exportInstance.learningObjectDone.task.length}
        radioOptions={textCourseOptions}
        count={exportInstance.learningObjectSelected.task.length}
        checked={exportInstance.elements.tasks.selected}
        valueRadio={exportInstance.elements.tasks.radio}
        onChangeSectionSelect={exportInstance.onChangeSelectItem}
        onChangeTextType={exportInstance.onChangeTextType}
      />
    </div>
  );
}

export default DownloadDrawerContent;
