/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ExportVideoParams = {
    file_extension?: ExportVideoParams.file_extension;
    export_video: boolean;
    resolution?: ExportVideoParams.resolution;
};

export namespace ExportVideoParams {

    export enum file_extension {
        MP4 = 'mp4',
    }

    export enum resolution {
        '_144' = 144,
        '_240' = 240,
        '_360' = 360,
        '_480' = 480,
        '_720' = 720,
        '_1080' = 1080,
        '_1440' = 1440,
        '_2160' = 2160,
        '_4320' = 4320,
    }


}
