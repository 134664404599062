import uuid from 'react-uuid';

import { iconComment } from './iconComment';

class CommentTool {
  static get isInline() {
    return true;
  }

  static get CSS() {
    return 'mark-comment';
  }

  constructor(props) {
    this.button = null;
    this.state = false;
    this.commentId = uuid();
    this.config = props.config;
    this.api = props.api;
    this.tag = 'comment';
  }

  static get title() {
    return 'Comment';
  }

  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.innerHTML = iconComment;
    this.button.classList.add('ce-inline-tool');
    this.button.classList.add('ce-inline-tool--comment');

    if (this.config.disable) {
      this.button.setAttribute('disabled', true);
    }

    return this.button;
  }

  surround(range) {
    if (this.state) {
      // If highlights is already applied, do nothing for now
      this.button.setAttribute('disabled', true);
      return;
    }

    this.wrap(range);
  }

  wrap(range) {
    const selectedText = range.extractContents();

    // Create MARK element
    const mark = document.createElement(this.tag);

    // Append to the MARK element selected TextNode
    mark.appendChild(selectedText);
    mark.classList.add(CommentTool.CSS);
    mark.setAttribute('id', this.commentId);
    mark.setAttribute('data-comment', this.commentId);

    // Insert new element
    range.insertNode(mark);
    this.button.setAttribute('disabled', true);
  }

  checkState(selection) {
    const text = selection.anchorNode;

    if (!text) {
      return;
    }

    const anchorElement = text instanceof Element ? text : text.parentElement;

    if (!!anchorElement.closest('comment')) {
      this.button.setAttribute('disabled', true);
    }

    this.state = !!anchorElement.closest('comment');
  }

  static get sanitize() {
    return {
      comment: true,
    };
  }

  save(blockContent) {
    // console.log(blockContent);
  }
}

export default CommentTool;
