import { useQuery } from '@apollo/client';
import { GET_COMPANY_SEPARATE_TASK_TYPES } from 'queries/getCompanySeparateTaskTypes';

import SelectLabel from 'components/selectLabel';

type CompanySeparateTaskTypeItem = {
  id: string;
  name: string;
  icon_image?: string;
};

type CompanySeparateTaskTypesData = {
  company_separate_task_types: CompanySeparateTaskTypeItem[];
};

function useCompanySeparateTaskTypes(companyId: string) {
  const { data } = useQuery<CompanySeparateTaskTypesData>(GET_COMPANY_SEPARATE_TASK_TYPES, {
    variables: {
      companyId: companyId,
    },
    skip: !companyId,
  });

  const companySeparateTaskTypesForSelect = data?.company_separate_task_types.map((item) => {
    return {
      id: item.id,
      label: <SelectLabel text={item.name} icon={item.icon_image} />,
      value: item.id,
    };
  });

  return {
    companySeparateTaskTypes: data?.company_separate_task_types,
    companySeparateTaskTypesForSelect,
  };
}

export default useCompanySeparateTaskTypes;
