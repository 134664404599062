import { ContentFilterData } from './subPages/content/types';

export const initSettings = {
  content: false,
  description: false,
  learningOutcomes: false,
};

export const contentFilterInit: ContentFilterData = {
  states: [],
  artefacts: [],
  search: '',
};

export const deadLinesInit = {
  artefact_iteration_deadline: null,
  artefact_production_deadline: null,
};
