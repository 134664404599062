import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { BusinessRoleEnum, TechnicalRoleEnum, UserExtended } from 'types/entities';
import { useParams } from 'react-router-dom';
import {
  allowedRolesToSelect,
  convertProjectUsersToUsersExtended,
  filteredUsersByRoles,
  getPayloadChangeProjectUsersList,
  usersKeyInValue,
} from 'utils';
import { noop, pick } from 'lodash';
import { message } from 'antd';
import { onChangeProjectUsersList } from 'api/requests/projects';

import useCommonContext from 'hooks/useCommonContext';
import useProjectUsers, { ProjectUsersData, ProjectUserType } from 'hooks/queries/useProjectUsers';
import useBoolean from 'hooks/useBoolean';
import useCompanyRoles from 'hooks/queries/useCompanyRoles';

import { messageDurability } from '../../../constants';
import useAddForm, { UseAddFormOutput } from '../../users/hooks/useAddForm';

type Output = {
  loading: boolean;
  roleId: string | null;
  users: UsersState;
  separateProjectTaskAssignee: ProjectUserType[];
  userAddForm: UseAddFormOutput;
  setRoleId: Dispatch<SetStateAction<string | null>>;
  onSave: (user: UserExtended | UserExtended[]) => void;
  projectUserRefetch: () => Promise<any>;
};

export type UsersState = {
  [name: string]: UserExtended[];
};

type ParticipantsControlProps = {
  visible?: boolean;
  companyId?: string;
  onCloseDrawer: () => void;
};

function useParticipantsControl({ visible, companyId, onCloseDrawer }: ParticipantsControlProps): Output {
  const [loading, onLoading] = useBoolean(false);
  const [roleId, setRoleId] = useState<string | null>(null);
  const [users, setUsers] = useState<UsersState>({});
  const common = useCommonContext();
  const { id } = useParams();
  const userAddForm = useAddForm(null, undefined, noop, visible);

  const { companyRoles } = useCompanyRoles(companyId);

  const onCompleted = (usersData: ProjectUsersData) => {
    if (usersData?.project_users) {
      const temp = convertProjectUsersToUsersExtended(
        usersData?.project_users
        //filteredUsersByRoles(, BusinessRoleEnum.executiveManager)
      );
      setUsers(usersKeyInValue(temp));
    }
  };

  const { projectUsers, projectUserRefetch } = useProjectUsers(id, 'network-only', onCompleted);

  const onSubmit = useCallback(
    async (value: UserExtended | UserExtended[]) => {
      onLoading.on();
      let updatedUsers = { ...users };

      if (roleId) {
        if (Array.isArray(value)) {
          updatedUsers = {
            ...updatedUsers,
            [`${roleId}`]: [...value],
          };
        } else {
          updatedUsers = {
            ...updatedUsers,
            [`${roleId}`]: [value],
          };
        }
      }
      //TODO if 2 or more companies, compare tech role to current project company
      const isAdmin = common?.user?.companies?.[0]?.technical_role === TechnicalRoleEnum.ADMIN;
      const filtered = pick(updatedUsers, allowedRolesToSelect(companyRoles, common.businessRoles, isAdmin));

      await onChangeProjectUsersList(Number(id), getPayloadChangeProjectUsersList(filtered))
        .then(() => {
          projectUserRefetch()
            .then(() => {
              message.success(common.t<string>('pages.project.participantsAdded'), 2.5);
              onCloseDrawer();
              setRoleId(null);
              onLoading.off();
            })
            .catch(() => {
              message.error(common.t<string>('common.errorTryAgain'), messageDurability);
              onLoading.off();
            });
        })
        .catch(() => {
          message.error(common.t<string>('common.errorTryAgain'), messageDurability);
          onLoading.off();
        });
    },
    [common, companyRoles, id, onCloseDrawer, onLoading, projectUserRefetch, roleId, users]
  );

  const separateProjectTaskAssignee = projectUsers?.filter((u) => u.is_separate_project_task_assignee) || [];

  return {
    loading,
    roleId,
    users,
    separateProjectTaskAssignee,
    userAddForm,
    setRoleId,
    onSave: onSubmit,
    projectUserRefetch,
  };
}

export default useParticipantsControl;
