import React, { memo } from 'react';

import { DeleteOutlined, ReloadOutlined, VerticalLeftOutlined, VerticalRightOutlined } from '@ant-design/icons';

import { ButtonTypesEnum } from '../../../components/button/types';
import Button from '../../../components/button';

type SlidePreviewControlsProps = {
  onClickDeleteSlide?: () => void;
  onReloadSlide: (event: any) => void;
  onMoveRight?: () => void;
  onMoveLeft?: () => void;
  handleSetOnInputFile: () => void;
  disabledRight?: boolean;
  disabledLeft?: boolean;
};

function SlidePreviewControls({
  disabledRight,
  disabledLeft,
  onReloadSlide,
  onClickDeleteSlide,
  onMoveRight,
  onMoveLeft,
  handleSetOnInputFile,
}: SlidePreviewControlsProps) {
  const uploaderRef = React.useRef<HTMLInputElement>(null);
  const onReloadSlideHandler = (event: any) => {
    onReloadSlide(event);
  };
  const onClickSlideHandler = () => {
    handleSetOnInputFile();
    uploaderRef?.current?.click();
  };

  return (
    <div className="slide-preview-controls">
      <div className="slide-preview-controls__row">
        <div id="slider-upload" className="upload-btn" onClick={onClickSlideHandler}>
          <ReloadOutlined style={{ color: '#F8F8F8' }} />
        </div>
        <Button
          onClick={onClickDeleteSlide}
          type={ButtonTypesEnum.link}
          icon={<DeleteOutlined style={{ color: '#F8F8F8' }} />}
        />
        <input ref={uploaderRef} type="file" style={{ display: 'none' }} onChange={onReloadSlideHandler} />
        <Button
          disabled={disabledRight}
          type={ButtonTypesEnum.link}
          icon={<VerticalRightOutlined style={{ color: '#F8F8F8' }} />}
          onClick={onMoveLeft}
        />
        <Button
          disabled={disabledLeft}
          type={ButtonTypesEnum.link}
          icon={<VerticalLeftOutlined style={{ color: '#F8F8F8' }} />}
          onClick={onMoveRight}
        />
      </div>
    </div>
  );
}

export default memo(SlidePreviewControls);
