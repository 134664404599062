import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';

import { GET_COMPANY_LEARNING_OBJECT_TYPES } from '../../queries/getCompanyLearningObjectTypes';
import useCommonContext from '../useCommonContext';
import { AdminCompanyLearningObjectType } from '../../types';

export type CompanyLearningObjectType = {
  pk: string;
  id: string;
  name: string;
  icon_image: string | null;
  order: number;
  type?: AdminCompanyLearningObjectType.type;
  has_related_artefact_type: boolean;
};

type Data = {
  company_learning_object_types: CompanyLearningObjectType[];
};

function useCompanyLearningObjectTypes(fetchPolicy?: WatchQueryFetchPolicy) {
  const common = useCommonContext();

  const { data, loading, refetch } = useQuery<Data>(GET_COMPANY_LEARNING_OBJECT_TYPES, {
    variables: {
      companyId: common?.company?.id,
    },
    fetchPolicy: fetchPolicy || 'network-only',
    skip: !common?.company?.id,
  });

  const companyLearningObjectTypesForSelect = data?.company_learning_object_types?.map((el, index) => {
    return {
      id: el?.id || index.toString(),
      label: el?.name,
      value: el?.id,
    };
  });

  const companyLOTAdminForSelect = data?.company_learning_object_types
    ?.filter((el) => !el.has_related_artefact_type)
    .map((el, index) => {
      return {
        id: el?.id || index.toString(),
        label: el?.name,
        value: el?.id,
      };
    });

  return useMemo(() => {
    return {
      loading,
      companyLearningObjectTypes: data?.company_learning_object_types as CompanyLearningObjectType[] | undefined,
      companyLearningObjectTypesForSelect,
      companyLOTAdminForSelect,
      refetch,
    };
  }, [
    companyLOTAdminForSelect,
    companyLearningObjectTypesForSelect,
    data?.company_learning_object_types,
    loading,
    refetch,
  ]);
}

export default useCompanyLearningObjectTypes;
