import React, { memo } from 'react';

import { cx } from 'utils';
import { CheckboxProps } from 'antd';

import Checkbox from 'components/checkbox';

import { CheckListItemProps } from './types';

function CheckListItem({ children, isTitle, ...props }: CheckListItemProps & CheckboxProps) {
  const classNames = cx('check-list__item', {
    'check-list__item--title': isTitle,
  });

  return (
    <Checkbox className={classNames} {...props}>
      {children}
    </Checkbox>
  );
}

export default memo(CheckListItem);
