import React, { memo, useCallback, useMemo } from 'react';

import i18next from 'i18next';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import ruRu from 'antd/es/date-picker/locale/ru_RU';
import enUS from 'antd/es/date-picker/locale/en_US';
import { Form, DatePicker as ADatePicker } from 'antd';
import { calculateHeight, cx } from 'utils';
import { Dayjs } from 'dayjs';

import { DatePickerProps } from './types';

function DatePicker({
  className,
  label,
  rules,
  name,
  onChange,
  onChangeWithName,
  noForm,
  value,
  placeholder,
  defaultValue,
  format,
  size = 'middle',
  getPopupContainer,
  disabledDate,
  status,
  ...props
}: DatePickerProps) {
  const locale = i18next.language === 'ru' ? ruRu : enUS;
  const classNames = cx('ccm-date-picker', className, {
    'ccm-date-picker--no-form': noForm,
    [`ccm-date-picker--${size}`]: size,
  });
  const dateFormat = format || 'DD/MM/YYYY';
  const styles = useMemo(() => {
    return {
      height: `${calculateHeight(Boolean(label), Boolean(noForm), size)}px`,
    };
  }, [label, noForm, size]);

  const handleOnChange = useCallback(
    (time: Dayjs | null, dateString: string) => {
      if (name && onChangeWithName) {
        onChangeWithName(name, time, dateString);
      }
    },
    [name, onChangeWithName]
  );

  if (noForm) {
    return (
      <ADatePicker
        locale={locale}
        format={dateFormat}
        placeholder={placeholder}
        onChange={onChangeWithName ? handleOnChange : onChange}
        className={classNames}
        value={value}
        defaultValue={defaultValue}
        size={size}
        disabledDate={disabledDate}
        getPopupContainer={getPopupContainer}
        style={styles}
        status={status}
        {...props}
      />
    );
  }

  return (
    <Form.Item
      style={styles}
      label={label}
      name={name}
      className={classNames}
      colon={false}
      labelAlign="left"
      rules={rules}
    >
      <ADatePicker
        locale={locale}
        format={dateFormat}
        placeholder={placeholder}
        onChange={onChangeWithName ? handleOnChange : onChange}
        className={classNames}
        value={value}
        defaultValue={defaultValue}
        size={size}
        disabledDate={disabledDate}
        getPopupContainer={getPopupContainer}
        status={status}
        {...props}
      />
    </Form.Item>
  );
}

export default memo(DatePicker);
