import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import CoursesPage from 'pages/courses';
import CourseAboutPage from 'pages/project/subPages/about';
import ProjectContentPage from 'pages/project/subPages/content';
import TasksPage from 'pages/tasks';
import ProtectedLayout from 'layout/protected';
import AuthLayout from 'layout/auth';
import LoginScreen from 'pages/auth/screens/login';
import SignupScreen from 'pages/auth/screens/signup';
import ResetPasswordScreen from 'pages/auth/screens/reset';
import TaskPage from 'pages/task';
import Users from 'pages/users';
import TaskDraftEditingPage from 'pages/task/subPages/draftEditing';
import TaskFinalPresentationPage from 'pages/task/subPages/finalPresentation';
import ProjectPage from 'pages/project';
import Plan from 'pages/project/subPages/plan/Plan';

import UseInitApp from 'hooks/useInitApp';

import CourseParticipantsPage from '../pages/project/subPages/participants';
import TaskUniversalPage from '../pages/task/subPages/universal';
import UiPage from '../pages/ui/ui';
import MainLayout from '../layout/main';
import AddCoursePage from '../pages/addCourse';
import TaskTextPage from '../pages/task/subPages/text';
import DashboardPage from '../pages/dashboard';
import AccountPage from '../pages/account';
import ServicePage from '../pages/service';
import ViewSeparateTaskPage from '../pages/viewSeparateTask';
import CreateSeparateTask from '../pages/createSeparateTask';
import PlanTable from '../pages/project/subPages/plan/PlanTable';
import AdminLayout from '../layout/admin';
import ManagementSettings from '../pages/managementSettings';
import StatusListPage from '../pages/managementSettings/pages/statusList';
import ArtefactTypesPage from '../pages/managementSettings/pages/artefactTypes';
import StatusListEditPage from '../pages/managementSettings/pages/statusListEdit';
import GlobalSettingsPage from '../pages/managementSettings/pages/globalSettings';
import LearningObjTypes from '../pages/managementSettings/pages/learningObjTypes';
import PipelinePage from '../pages/managementSettings/pages/pipeline';
import PipelineEdit from '../pages/managementSettings/pages/pipelineEdit';

import { PATH_NAMES } from './constants';

function AppRoutes() {
  UseInitApp();

  return (
    <>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path={PATH_NAMES.auth.login} element={<LoginScreen />} />
          <Route path={PATH_NAMES.auth.signup} element={<SignupScreen />} />
          <Route path={PATH_NAMES.auth.resetPassword} element={<ResetPasswordScreen />} />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route element={<AdminLayout />}>
            <Route path={PATH_NAMES.management.base} element={<ManagementSettings />}>
              {/*<Route index element={<div>pipeline</div>} />*/}
              <Route path={PATH_NAMES.management.pipeline} element={<PipelinePage />} />
              <Route path={PATH_NAMES.management.pipelineEdit} element={<PipelineEdit />} />
              <Route path={PATH_NAMES.management.globalSettings} element={<GlobalSettingsPage />} />
              <Route path={PATH_NAMES.management.status} element={<StatusListPage />} />
              <Route path={PATH_NAMES.management.statusEdit} element={<StatusListEditPage />} />
              <Route path={PATH_NAMES.management.artTypes} element={<ArtefactTypesPage />} />
              <Route path={PATH_NAMES.management.loTypes} element={<LearningObjTypes />} />
            </Route>
          </Route>
          <Route element={<MainLayout />}>
            <Route path={PATH_NAMES.dashboard.base} element={<DashboardPage />} />
            <Route path={PATH_NAMES.account.base} element={<AccountPage />} />
            <Route path={PATH_NAMES.errors.error403} element={<ServicePage />} />
            <Route path={PATH_NAMES.errors.error404} element={<ServicePage />} />
            <Route path={PATH_NAMES.errors.error405} element={<ServicePage />} />
            <Route path={PATH_NAMES.errors.error500} element={<ServicePage cleanAuth />} />
            <Route path={PATH_NAMES.errors.error502} element={<ServicePage />} />
            <Route path={PATH_NAMES.errors.error503} element={<ServicePage />} />
            <Route path={PATH_NAMES.errors.base} element={<ServicePage />} />
          </Route>

          <Route element={<MainLayout />}>
            <Route path={PATH_NAMES.tasks.base} element={<TasksPage />} />
            <Route path={PATH_NAMES.courses.base} element={<CoursesPage />} />
            <Route path={PATH_NAMES.addCourse.base} element={<AddCoursePage />} />
            <Route path={PATH_NAMES.separateTask.base} element={<ViewSeparateTaskPage />} />
            <Route path={PATH_NAMES.users.base} element={<Users />} />
          </Route>

          <Route path={PATH_NAMES.task.base} element={<TaskPage />}>
            <Route path={PATH_NAMES.task.entity.universal} element={<TaskUniversalPage />} />
            <Route path={PATH_NAMES.task.entity.text} element={<TaskTextPage />} />
            <Route path={PATH_NAMES.task.entity.draft_presentation} element={<TaskUniversalPage />} />
            <Route path={PATH_NAMES.task.entity.video_shooting} element={<TaskDraftEditingPage />} />
            <Route
              path={`${PATH_NAMES.task.entity.production_presentation}/:issueId`}
              element={<TaskFinalPresentationPage />}
            />
            <Route path={PATH_NAMES.task.entity.production_presentation} element={<TaskFinalPresentationPage />} />
          </Route>
          <Route path={PATH_NAMES.project.base} element={<MainLayout />}>
            <Route path={PATH_NAMES.project.base} element={<ProjectPage />}>
              <Route path={PATH_NAMES.project.entity.about} element={<CourseAboutPage />} />
              <Route path={PATH_NAMES.project.entity.participants} element={<CourseParticipantsPage />} />
              <Route path={PATH_NAMES.project.entity.content} element={<ProjectContentPage />} />
              <Route path={PATH_NAMES.project.entity.plan.tree} element={<Plan />} />
              <Route path={PATH_NAMES.project.entity.plan.list} element={<PlanTable />} />
            </Route>
            <Route path={PATH_NAMES.project.entity.create} element={<CreateSeparateTask />} />
          </Route>
          <Route path={PATH_NAMES.ui.main} element={<UiPage />} />
        </Route>
        <Route index element={<Navigate to={PATH_NAMES.auth.login} />} />
      </Routes>
      <NotificationContainer />
    </>
  );
}

export default AppRoutes;
