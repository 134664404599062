import React from 'react';

import { cx } from 'utils';
import { Input } from 'antd';

import { InputSearchProps } from './types';

import './styles.scss';

const ASearch = Input.Search;

function Search({ className, testClassName, ...props }: InputSearchProps) {
  const classNames = cx('ccm-search', className, testClassName, {
    [`ccm-search--${props.size}`]: props.size,
    'ccm-search--icon-only': props.enterButton,
  });

  return <ASearch className={classNames} {...props} />;
}

export default Search;
