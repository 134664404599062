import React from 'react';

import { noop } from 'lodash';
import { cx, getViewTimeVideoIssue } from 'utils';
import { useSearchParams } from 'react-router-dom';
import { BoxPlotOutlined, CommentOutlined, FieldNumberOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import withCommonProps from 'hocs/withCommonProps';
import { TemplateCommonProps } from 'common/commonPropsProvider';
import { StateType } from 'types/entities';
import dayjs from 'dayjs';

import Block from 'components/block';
import Text from 'components/text';
import { TextVariantEnum } from 'components/text/types';
import { StatusRow } from 'components/statusLabel';

import ErrorCardControls from './ErrorCardControls';
import { ErrorCardProps } from './types';

import './styles.scss';

function ErrorCard({
  className,
  isVideo,
  item,
  type,
  isSelectedIssue,
  onSelectedIssue,
  isMethodist,
  isProductExecutor,
  companyStates,
  issuesRefetch,
  issuesTypesList,
  userRole,
  companyIssuesStates,
  common,
  currentTaskState,
  issuesFlowSteps,
  userRoleId,
  isCurrentExecutor,
}: ErrorCardProps & TemplateCommonProps) {
  const classNames = cx('error-card', 'testclass-errors-error-card-item', className, {
    [`error-card--${type}`]: type,
    'error-card--selected': isSelectedIssue,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const status = item?.project_task?.last_state?.state_type;
  const taskStatus = item?.project_task?.last_state?.state_type;
  const creationVersion = item?.artefact_version_creation?.number;
  const solutionVersion = item?.artefact_version_solution?.number;

  const handleClick = () => {
    if (isSelectedIssue) {
      return noop();
    } else {
      setSearchParams({ issue: item?.pk });
      return onSelectedIssue(item);
    }
  };

  return (
    <div onClick={handleClick} className={classNames}>
      <div className="error-card__head mb_8">
        <Block
          item
          hidden={!item?.slide_number}
          className="error-card__timeline testclass-errors-error-card-item-head-timeline"
        >
          {common.t('common.slide')} {item?.slide_number}
        </Block>
        <Block item hidden={!item?.timestamp_start} className="error-card__timeline">
          {getViewTimeVideoIssue(item?.timestamp_start, item?.timestamp_end)}
        </Block>

        <span className="error-card__version">
          v{creationVersion} {solutionVersion && `- v${solutionVersion}`}
        </span>
        <Block hidden={!item.is_timerange_changed}>
          <BoxPlotOutlined />
        </Block>
        <Block item hidden={!item?.is_slide_number_changed} className="error-card__timeline">
          <Tooltip title={common.t<string>('common.slideNumberChanged')}>
            <span className="error-card__version-changed">
              <FieldNumberOutlined style={{ color: '#E98529' }} />
            </span>
          </Tooltip>
        </Block>
        <StatusRow type={status} className="ml_auto">
          {item?.project_task?.last_state?.name}
        </StatusRow>
      </div>
      <Text className="error-card__title" variant={TextVariantEnum.body}>
        {item?.issue?.name}
      </Text>
      <div className="error-card__content flex-col">
        <div className="error-card__info mb_4">
          <span className="error-card__user-name">
            {item?.changed_by?.first_name} {item?.changed_by?.last_name}
          </span>
          <span className="error-card__time">{dayjs(item?.created_at).format('Do MMMM h:mm')}</span>

          <Block item hidden={!item?.countMessage} className="error-card__count-message">
            {item?.countMessage}
            <CommentOutlined />
          </Block>
        </div>
        {item?.project_task?.description ? (
          <span className="error-card__message mb_16">{item?.project_task?.description}</span>
        ) : null}
        {item?.project_task?.discuss_messages?.length
          ? item?.project_task?.discuss_messages?.map((message: any) => {
              const user = message?.project_user?.company_user?.user;
              return (
                <div key={message?.id} className="error-card__discuss">
                  <div className="error-card__info">
                    <span className="error-card__user-name">
                      {user?.first_name} {user?.last_name}
                    </span>
                    <span className="error-card__time">{dayjs(message.created_at).format('Do MMMM h:mm')}</span>
                  </div>
                  {message?.message ? <span className="error-card__message">{message?.message}</span> : null}
                </div>
              );
            })
          : null}
        {isSelectedIssue && currentTaskState !== StateType.DONE ? (
          <ErrorCardControls
            isVideo={isVideo}
            isProductExecutor={isProductExecutor}
            isMethodist={isMethodist}
            item={item}
            status={taskStatus}
            companyStates={companyStates}
            issuesRefetch={issuesRefetch}
            issuesTypesList={issuesTypesList}
            userRole={userRole}
            companyIssuesStates={companyIssuesStates}
            issuesFlowSteps={issuesFlowSteps}
            userRoleId={userRoleId}
            isCurrentExecutor={isCurrentExecutor}
          />
        ) : null}
      </div>
    </div>
  );
}

export default withCommonProps(ErrorCard);
