import React, { useCallback, useEffect, useRef, useState } from 'react';

import { message, Progress, Skeleton } from 'antd';
import { DoubleLeftOutlined, DoubleRightOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { ArtefactContentVideoFoundIssue } from 'types/entities/artefact';
import withCommonProps from 'hocs/withCommonProps';
import { TemplateCommonProps } from 'common/commonPropsProvider';
import { useSearchParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { onDeleteFile } from 'api/requests/files';
import { BusinessRoleEnum, StateType } from 'types/entities';
import { IStore } from 'types/store';
import modalService from 'services/modalService';
import { ConfirmModalIcon } from 'modals/confirm/ConfirmModal';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import TaskErrors from 'pages/components/taskErrors/TaskErrors';
import HistoryDrawer from 'pages/components/historyDrawer/HistoryDrawer';
import { cx } from 'utils';
import { changeRollbackPossibility } from 'api/requests/projectTask';

import { ButtonTypesEnum } from 'components/button/types';
import Button from 'components/button';
import { TabPane, Tabs } from 'components/tabs';
import Block from 'components/block';
import VideoPlayer from 'components/videoPlayer/VideoPlayer';
import Uploader from 'components/uploader';
import ErrorConstructor from 'components/errorConstructor';
import TabTitleWithBadge from 'components/tabs/TabTitleWithBadge';
import TabPaneSkeleton from 'components/tabs/TabPaneSkeleton';

import { checkMimeTypeVideo } from 'utils/videos';
import { checkUserRole } from 'utils/roles';

import { changeTaskStatusRequest, rollbackTaskStatusRequest } from 'store/projects/actions';

import useProjectTask from 'hooks/queries/useProjectTask';
import useArtefactVideoIssues from 'hooks/queries/useArtefactVideoIssues';
import useProjectUser from 'hooks/queries/useProjectUser';
import useArtefactContentIssues from 'hooks/queries/useArtefactContentIssues';
import useProjectStates from 'hooks/queries/useProjectStates';
import useTaskFlowSteps from 'hooks/queries/useTaskFlowSteps';
import useIssuesFlowSteps from 'hooks/queries/useIssuesFlowSteps';
import useTaskChangeHistory from 'hooks/queries/useTaskChangeHistory';
import useMaterials from 'hooks/queries/useMaterials';

import TaskHeader from '../../components/taskHeader';
import { messageDurability } from '../../../../constants';
import Empty from '../../components/empty';
import MaterialItem from '../../../../components/materialItem/Materials';
import { VideoFile } from '../../types';
import SkeletonTabs from '../../components/skeleton/SkeletonTabs';

import { onUploadVideoManagement } from './fetches';

import './styles.scss';

export type DraftEditingState = {
  errors: ArtefactContentVideoFoundIssue[];
  duration: number;
  played: number;
};

const initState: DraftEditingState = {
  errors: [],
  duration: 0,
  played: 0,
};

function TaskDraftEditingPage({ common, onRollbackTaskStatus }: TemplateCommonProps & PropsFromRedux) {
  const [panel, setPanel] = useState<boolean>(true);
  const [files, setFiles] = useState<RcFile | null>(null);
  const [state, setState] = useState(initState);
  const [filter, setFilter] = useState<string>('ALL');
  const [ordering, setOrdering] = useState<string>('by_timeline');
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const onOpenHistory = useCallback(() => setShowHistory(true), [setShowHistory]);
  const onCloseHistory = useCallback(() => setShowHistory(false), [setShowHistory]);
  const uploaderRef = React.useRef<HTMLInputElement>(null);
  const uploaderRefNewVersion = React.useRef<HTMLInputElement>(null);

  const [selectedIssue, setSelectedIssue] = useState<ArtefactContentVideoFoundIssue | null>(null);
  const [progress, setProgress] = useState<number | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const issueA = searchParams.get('issue');
  const version = searchParams.get('version');
  const trigger = useRef<boolean | null>(null);
  const canceled = useRef<boolean | null>(null);

  const onTogglePanel = useCallback(() => setPanel(!panel), [panel]);

  const {
    data,
    projectId,
    companyId,
    projectTask,
    currentArtefact,
    artefactFile,
    artefactFileVersion,
    taskStatus,
    refetchProjectTask,
    loadingProjectTask,
    currentProject,
  } = useProjectTask(version);
  const { materials } = useMaterials(Number(currentArtefact?.id));
  const { issuesLoading, issuesRefetch, issuesList } = useArtefactVideoIssues({
    artefactId: Number(currentArtefact?.id),
    ordering,
    filter,
  });

  const { userRole, userRoles, userRoleId } = useProjectUser(common?.userId, projectId);

  const currentProjectTaskArtefactTypeId = Number(projectTask?.project_task?.artefact_list?.[0]?.artefact_type?.id);
  const relatedFiles = artefactFile?.related_files as VideoFile[];

  const currentSourceFile =
    relatedFiles?.find((file) => file.resolution === 720 && !file.is_source)?.source_url ||
    relatedFiles?.[0]?.source_url;

  const { issuesTypesList } = useArtefactContentIssues(
    Number(data?.learning_object?.project?.company?.id),
    currentProjectTaskArtefactTypeId
  );

  const { companyStates, companyIssuesStates } = useProjectStates(projectId);

  const { taskChangeHistory, taskChangeHistoryRefetch } = useTaskChangeHistory(projectTask?.project_task?.id);

  const projectTaskStateListVersionId = Number(currentProject?.project_task_state_list_version?.id);
  const projectIssuesStateListVersionId = Number(currentProject?.artefact_issue_state_list_version?.id);
  const currentArtefactTypeId = Number(currentArtefact?.artefact_type?.id);
  const currentTaskStatusId = Number(projectTask?.project_task?.last_state?.id);

  const { artefactFlowSteps } = useTaskFlowSteps({
    companyArtefactTypeId: currentArtefactTypeId,
    companyStateListVersionId: projectTaskStateListVersionId,
    initialStateId: currentTaskStatusId,
  });
  const { issuesFlowSteps } = useIssuesFlowSteps({
    companyArtefactTypeId: currentArtefactTypeId,
    companyStateListVersionId: projectIssuesStateListVersionId,
    requiredUserRoleIds: userRoleId ? [Number(userRoleId)] : null,
  });

  const currentArtefactVersion = currentArtefact?.artefact_versions;

  const callbackAfterFetch = useCallback(() => {
    setFiles(null);
    trigger.current = false;
    canceled.current = false;
    refetchProjectTask()
      .then(() => setProgress(null))
      .catch(() => setProgress(null));
  }, [refetchProjectTask]);

  useEffect(() => {
    if (files && !trigger.current && currentArtefact?.id) {
      trigger.current = true;
      onUploadVideoManagement(
        files,
        currentArtefact?.id,
        artefactFileVersion,
        artefactFile,
        callbackAfterFetch,
        setProgress,
        false,
        canceled
      );
    }
  }, [artefactFile, artefactFileVersion, currentArtefact?.id, files, refetchProjectTask, callbackAfterFetch, canceled]);

  const customRequest = () => null;
  const changeFilter = (value: string) => {
    setFilter(value);
  };
  const changeOrder = (value: string) => {
    setOrdering(value);
  };
  const onChangeUpload = async (info: UploadChangeParam<UploadFile>) => {
    const f = info.file.originFileObj;
    const { isAllowed } = checkMimeTypeVideo(f);
    if (f && isAllowed) {
      setFiles(f);
    } else {
      message.error(common?.t<string>('errors.messages.upload.allowedVideoFormats'), messageDurability);
    }
  };
  const onCancelUpload = () => {
    canceled.current = true;
  };

  const onReloadVideoHandle = () => {
    uploaderRef?.current?.click();
  };
  const onAddNewVersionVideoHandle = () => {
    uploaderRefNewVersion?.current?.click();
  };
  const fileSelectedHandler = async (event: any) => {
    const selectedFile = event?.target?.files[0];
    await onDeleteFile('video', Number(artefactFile?.pk)).then(() => {
      refetchProjectTask().then(() => {
        setFiles(selectedFile);
      });
    });
  };

  const fileSelectedNewVersionHandler = async (event: any) => {
    const selectedFile = event?.target?.files[0];
    await onUploadVideoManagement(
      selectedFile,
      currentArtefact?.id,
      artefactFileVersion,
      artefactFile,
      callbackAfterFetch,
      setProgress,
      true,
      canceled
    );
  };

  useEffect(() => {
    if (issueA) {
      const issueCurrent = issuesList?.filter((issue: any) => issue?.pk === issueA)?.[0];
      if (issueCurrent) {
        setSelectedIssue(issueCurrent);
      }
    }
  }, [issueA, issuesList]);

  const isCurrentExecutorEntity = projectTask?.project_task?.projecttaskassignee_set?.find(
    (assigner) => assigner.is_current_executor && assigner.is_active
  );
  const isCurrentExecutor =
    Number(isCurrentExecutorEntity?.project_user?.company_user?.user?.id) === Number(common?.userId);
  //is_rollback_possible
  const isCanRollback = projectTask?.project_task?.project_task_state_history?.is_rollback_possible;

  useEffect(() => {
    if (isCanRollback && isCurrentExecutor) {
      if (projectTask?.id) {
        changeRollbackPossibility(projectTask?.project_task?.id).then(() => refetchProjectTask());
      }
    }
  }, [isCanRollback]);

  const onSetDuration = (value: number) => setState({ ...state, duration: value });
  const onSetProgress = (value: number) => setState({ ...state, played: value });

  const onSelectedIssue = (issue: ArtefactContentVideoFoundIssue) => setSelectedIssue(issue);
  const onDeleteFileConfirm = async () => {
    await onDeleteFile('video', Number(artefactFile?.pk));
    await refetchProjectTask();
  };
  const onDeleteFileClick = async () => {
    modalService.openConfirmModal({
      icon: ConfirmModalIcon.warning,
      title: common.t('modals.deleteVideo'),
      text: common.t('modals.areYouSureToDeleteVideoFile'),
      labelCancel: common.t('modals.no'),
      labelConfirm: common.t('modals.yesDelete'),
      onConfirm: onDeleteFileConfirm,
    });
  };
  const unresolvedErrors = issuesList?.filter((error: any) => error?.project_task?.last_state?.state_type !== 'done');

  const tabItems = [
    {
      key: '1',
      label: (
        <TabPaneSkeleton loading={loadingProjectTask}>
          <TabTitleWithBadge title={common.t<string>('common.errors')} counter={unresolvedErrors?.length} />
        </TabPaneSkeleton>
      ),
      children: loadingProjectTask ? (
        <SkeletonTabs />
      ) : (
        <TaskErrors
          isVideo
          loading={issuesLoading}
          dataList={issuesList}
          selectedIssue={selectedIssue}
          onSelectedIssue={onSelectedIssue}
          companyStates={companyStates}
          companyIssuesStates={companyIssuesStates}
          issuesRefetch={issuesRefetch}
          issuesTypesList={issuesTypesList}
          isCurrentExecutor={isCurrentExecutor}
          userRole={userRole}
          userRoleId={userRoleId}
          currentTaskState={taskStatus}
          changeOrder={changeOrder}
          changeFilter={changeFilter}
          filter={filter}
          ordering={ordering}
          issuesFlowSteps={issuesFlowSteps}
        />
      ),
    },
    {
      key: '2',
      label: <TabPaneSkeleton loading={loadingProjectTask}>{common.t<string>('common.materials')}</TabPaneSkeleton>,
      children: (
        <div className="task-draft-editing__materials">
          <MaterialItem materials={materials} />
        </div>
      ),
    },
  ];

  const classNames = cx('task-page task-draft-editing', {
    'task-draft-editing__panel--closed': !panel,
  });

  // const showErrorConstructor =
  //   ((userRole === BusinessRoleEnum.instructionalDesigner || userRole === BusinessRoleEnum.manager) &&
  //     taskStatus !== StateType.REOPEN) ||
  //   taskStatus !== StateType.DONE ||
  //   isCurrentExecutor;

  const showErrorConstructor = isCurrentExecutor && taskStatus === StateType.REVIEW;

  return (
    <div className={classNames}>
      <TaskHeader
        loading={loadingProjectTask}
        onOpenHistory={onOpenHistory}
        iterations={currentArtefactVersion}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
        hintText={userRole === BusinessRoleEnum.instructionalDesigner ? 'common.completedIssues' : 'common.uploadAFile'}
        isHaveFile={Boolean(artefactFile?.related_files?.length)}
        onRollbackTaskStatus={onRollbackTaskStatus}
        projectTask={projectTask}
        refetchProjectTask={refetchProjectTask}
        userRole={userRole}
        userRoles={userRoles}
        issuesList={issuesList}
        artefactFlowSteps={artefactFlowSteps}
        artefactFile={artefactFile}
        onTaskHistoryRefetch={taskChangeHistoryRefetch}
      />

      <Block className="task-draft-editing__container">
        <Block className="fullSize" hidden={!loadingProjectTask}>
          <Skeleton.Image className="fullSize" style={{ width: '100%', height: '100%' }} />
        </Block>

        <Block hidden={!currentSourceFile || !!progress || loadingProjectTask}>
          <VideoPlayer
            url={currentSourceFile}
            className="mb_16"
            durationCallback={onSetDuration}
            playedCallback={onSetProgress}
            errors={issuesList}
            selectedIssue={selectedIssue}
            onSelectedIssue={onSelectedIssue}
          />

          {showErrorConstructor ? (
            <ErrorConstructor
              errorTypes={issuesTypesList}
              duration={state.duration}
              played={state.played}
              refetch={issuesRefetch}
              currentArtefact={currentArtefact}
            />
          ) : null}

          <Block
            className="flex-row flex-align-items--center flex-justify-content--flex-end"
            hidden={!checkUserRole(userRoles, BusinessRoleEnum.productionExecutor) || artefactFileVersion?.is_active}
          >
            <Button danger type={ButtonTypesEnum.default} className="mr_10" onClick={onDeleteFileClick}>
              {common.t<string>('common.delete')}
            </Button>
            <Button onClick={onReloadVideoHandle} type={ButtonTypesEnum.default} icon={<ReloadOutlined />}>
              {common.t<string>('common.reloadVideo')}
            </Button>
          </Block>
          <Block
            className="flex-row flex-align-items--center flex-justify-content--center task-draft-editing__new-version"
            hidden={
              !checkUserRole(userRoles, BusinessRoleEnum.productionExecutor) ||
              taskStatus !== StateType.REOPEN ||
              !artefactFileVersion?.is_active ||
              !artefactFileVersion?.is_current
            }
          >
            <Button type={ButtonTypesEnum.default} onClick={onAddNewVersionVideoHandle}>
              <PlusOutlined />
              {common.t<string>('common.loadNextVersion')}
            </Button>
          </Block>
        </Block>

        <Block className="fullSize" hidden={!progress || loadingProjectTask}>
          <Block className="fullSize flex-col flex-align-items--center flex-justify-content--center file-upload-progress">
            <Progress
              type="circle"
              strokeColor="#1E1E1E"
              percent={Number(progress?.toFixed(0)) || 0}
              format={(percent) => <div className="ccm-tab--video-file--percent">{`${percent}%`}</div>}
            />
            <Button type={ButtonTypesEnum.link} onClick={onCancelUpload}>
              {common.t<string>('common.cancel')}
            </Button>
          </Block>
        </Block>

        <Block className="fullSize" hidden={!!progress || !!artefactFile?.related_files?.length || loadingProjectTask}>
          {isCurrentExecutor ? (
            <Uploader
              showUploadList={false}
              onChange={onChangeUpload}
              customRequest={customRequest}
              className="testclass-videoshooting-upload"
              onlyVideo
            />
          ) : (
            <Empty />
          )}
        </Block>

        <input ref={uploaderRef} type="file" style={{ display: 'none' }} onChange={fileSelectedHandler} />
        <input
          ref={uploaderRefNewVersion}
          type="file"
          style={{ display: 'none' }}
          onChange={fileSelectedNewVersionHandler}
        />
      </Block>

      <Tabs
        className="task-draft-editing__tabs task-draft-editing__tabs-second"
        defaultActiveKey="1"
        items={!panel ? [] : tabItems}
        tabBarExtraContent={
          <Button
            size="large"
            className="task-sider__button"
            type={ButtonTypesEnum.text}
            icon={
              panel ? <DoubleRightOutlined style={{ fontSize: 20 }} /> : <DoubleLeftOutlined style={{ fontSize: 20 }} />
            }
            onClick={onTogglePanel}
          />
        }
      />

      <HistoryDrawer
        open={showHistory}
        onClose={onCloseHistory}
        data={taskChangeHistory}
        companyStates={companyStates}
      />
    </div>
  );
}

const connector = connect((state: IStore) => ({}), {
  onRollbackTaskStatus: rollbackTaskStatusRequest,
});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withCommonProps(TaskDraftEditingPage));
