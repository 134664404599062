import API from 'api';

import { ApiModules, ENDPOINTS } from '../constants';
import { ExportCourseElements, ExportVideoParams } from '../../pages/project/subPages/content/types';

export const exportProjectAll = (projectId: string, payload: ExportCourseElements): Promise<any> =>
  API(ApiModules.PM).put(ENDPOINTS.EXPORT_ALL_PROJECT_LEARNING_OBJECT(projectId), payload);

export const exportCancelProjectAll = (projectId: string, payload: ExportCourseElements): Promise<any> =>
  API(ApiModules.PM).delete(ENDPOINTS.EXPORT_ALL_PROJECT_LEARNING_OBJECT(projectId), payload);

export const exportCheckStatus = (exportId: string): Promise<any> =>
  API(ApiModules.PM).get(ENDPOINTS.CHECK_EXPORT_PREPARING_STATUS(exportId));

export const exportOneProject = (
  projectId: string,
  projectLearningObjectId: string,
  payload: ExportVideoParams
): Promise<any> =>
  API(ApiModules.PM).put(ENDPOINTS.EXPORT_ONE_PROJECT_LEARNING_OBJECT(projectId, projectLearningObjectId), payload);
