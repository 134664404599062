import { gql } from '@apollo/client';

import { USER_ROLE_TYPE, USER_TYPE } from './fragments';

export const GET_TASK_LO_SIDEBAR = gql`
  query GetTaskLOSidebar($learning_id: Int) {
    learning_object(id: $learning_id) {
      id
      name
      description
      project {
        id
        pk
        name
        company {
          id
        }
      }
      learning_object_type {
        icon_image
      }
      parent {
        human_readable_number
      }
      project_learning_outcome {
        description
      }
    }
  }
`;

export const GET_TASK_ARTEFACTS_SIDEBAR = gql`
  query GetTaskArtefactsSidebar($learning_id: Int) {
    artefact_production_sequence(learning_object_id: $learning_id) {
      id
      is_turn_off
      project_task {
        id
        current_assignees {
          is_current_executor
          is_active
          by_artefact_role_assignment {
            user_role {
              name
            }
          }
          project_user {
            company_user {
              id
              user {
                ...UserData
              }
            }
            roles {
              role {
                ...RoleData
              }
            }
          }
        }
        last_state {
          state_type
        }
        cached_linked_artefact {
          id
          first_iteration_deadline
          production_deadline
          artefact_type {
            id
            name
            type
          }
          current_file {
            file_type
          }
        }
      }
    }
  }
  ${USER_TYPE}
  ${USER_ROLE_TYPE}
`;
