import React, { memo, ReactNode } from 'react';

import './styles.scss';

type Props = {
  text: string;
  children?: ReactNode;
};

const Label = ({ text, children }: Props) => {
  return (
    <div className="ccm-label">
      <span className="ccm-label__text">{text}</span>
      {children}
    </div>
  );
};

export default memo(Label);
