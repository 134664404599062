import React from 'react';

import { convertProjectUsersToUsersExtended, cx, usersKeyInValue } from 'utils';
import Participants from 'pages/components/participants';

import HeaderModal from 'components/modal/components/header';

import { ProjectUserType } from 'hooks';

import useCompanyRoles from 'hooks/queries/useCompanyRoles';
import useCommonContext from 'hooks/useCommonContext';
import useProjectUsers from 'hooks/queries/useProjectUsers';

import './styles.scss';

type EditUserProps = {
  projectId: string;
  projectCompanyId: string;
  onCancel: () => void;
  users: ProjectUserType[];
};

const AssignersUsers = ({ projectId, projectCompanyId, onCancel }: EditUserProps) => {
  const common = useCommonContext();
  const { companyRoles, loading } = useCompanyRoles(projectCompanyId);
  const { projectUsers, projectUserLoading } = useProjectUsers(projectId);

  const separateProjectTaskAssignee = projectUsers?.filter((u) => u.is_separate_project_task_assignee) || [];
  const users = usersKeyInValue(convertProjectUsersToUsersExtended(projectUsers));

  const openDrawer = () => {};
  const classNames = cx('ccm-modal--wrapper');
  return (
    <div className={classNames}>
      <HeaderModal title={common.t('modals.participants')} onClose={onCancel} />
      <Participants
        loading={loading || projectUserLoading}
        users={users}
        separateProjectTaskAssignee={separateProjectTaskAssignee}
        onOpenDrawer={openDrawer}
        companyRoles={companyRoles}
      />
    </div>
  );
};

export default AssignersUsers;
