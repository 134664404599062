import React, { memo } from 'react';

import { cx, declOfNum, declTitleDay, declTitleTask } from 'utils';

import SvgInline from 'components/svgInline';
import Popover from 'components/popover';

import { ComposeArtefactDelaysProdDeadline } from 'hooks';

import useCommonContext from 'hooks/useCommonContext';
import useLazyArtefactDelaysProductionDeadline from 'hooks/queries/useLazyArtefactDelaysProductionDeadline';
import useLazyArtefactDelaysIterationDeadline from 'hooks/queries/useLazyArtefactDelaysIterationDeadline';
import useLazyArtefactMoreIteration from 'hooks/queries/useLazyArtefactMoreIteration';

import TaskInfoPopover from '../taskInfoPopover';

import iconArrow from './ph_arrow-bend-down-right.svg';

import './styles.scss';

type ProblemViewProps = {
  isCurrent?: boolean;
  className?: string;
  title?: string;
  hiddenDays?: boolean;
  days?: number;
  tasks?: number;
  projectId?: string;
  userId?: string;
  problemType?: 'taskInLate' | 'overdueDeadlines' | 'taskIteration';
  list?: ComposeArtefactDelaysProdDeadline[];
};

function ProblemView({
  isCurrent,
  className,
  title,
  days = 0,
  tasks = 0,
  hiddenDays,
  problemType,
  projectId,
  userId,
}: ProblemViewProps) {
  const common = useCommonContext();
  const classNames = cx('info-drawer-problem-view', className);

  const { getArtefactDelaysIterationDeadline, delayIterationList, delayIterationLoading } =
    useLazyArtefactDelaysIterationDeadline(Number(projectId), Number(userId), isCurrent);

  const { getArtefactDelaysProdDeadline, delayProdList, delayProdLoading } = useLazyArtefactDelaysProductionDeadline(
    Number(projectId),
    Number(userId),
    isCurrent
  );

  const { getArtefactMoreIteration, moreIterationList, moreIterationLoading } = useLazyArtefactMoreIteration(
    Number(projectId),
    Number(userId),
    isCurrent
  );

  const getAction = () => {
    if (problemType === 'taskInLate') {
      return getArtefactDelaysIterationDeadline();
    }
    if (problemType === 'overdueDeadlines') {
      return getArtefactDelaysProdDeadline();
    }

    return getArtefactMoreIteration();
  };

  const getList = () => {
    if (problemType === 'taskInLate') {
      return delayIterationList;
    }
    if (problemType === 'overdueDeadlines') {
      return delayProdList;
    }

    return moreIterationList;
  };

  const loading = delayIterationLoading || delayProdLoading || moreIterationLoading;

  return (
    <div className={classNames}>
      <SvgInline url={iconArrow} />
      <span className="info-drawer-problem-view__text">{title}</span>
      {hiddenDays ? null : (
        <span className="info-drawer-problem-view__days">{`${days} ${common.t<string>(
          declOfNum(days, declTitleDay)
        )}`}</span>
      )}

      {userId ? (
        <span className="info-drawer-problem-view__tasks" onClick={getAction}>
          <Popover
            content={<TaskInfoPopover list={getList()} loading={loading} />}
            trigger="click"
            placement="leftBottom"
          >
            {`${tasks} ${common.t<string>(declOfNum(tasks, declTitleTask))}`}
          </Popover>
        </span>
      ) : (
        <span className="info-drawer-problem-view__tasks info-drawer-problem-view__tasks-no-user">
          {`${tasks} ${common.t<string>(declOfNum(tasks, declTitleTask))}`}
        </span>
      )}
    </div>
  );
}

export default memo(ProblemView);
