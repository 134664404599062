import { useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { BusinessRoleEnum } from 'types/entities/user';

import { checkUserRole } from 'utils/roles';

import useCommonContext from '../useCommonContext';
import useProjectUsers from '../queries/useProjectUsers';

export enum ProjectPageEnum {
  about = 'about',
  executors = 'executors',
  content = 'content',
  plan = 'plan',
}

export type PermissionsProjectProps = {
  page: ProjectPageEnum;
};

function usePermissionsProject({ page }: PermissionsProjectProps) {
  const common = useCommonContext();
  const { id } = useParams();
  const { projectUsers } = useProjectUsers(id);

  const userRolesByProject = projectUsers?.filter((item) => item?.company_user?.user.id === common?.userId?.toString());

  const canIEdit = useMemo(() => {
    if (page === ProjectPageEnum.about) {
      return userRolesByProject?.some((item) => checkUserRole(item.roles, BusinessRoleEnum.executiveManager));
    }

    if (page === ProjectPageEnum.executors) {
      return (
        userRolesByProject?.some((item) => checkUserRole(item.roles, BusinessRoleEnum.executiveManager)) ||
        userRolesByProject?.some((item) => checkUserRole(item.roles, BusinessRoleEnum.videoProductionManager))
      );
    }

    if (page === ProjectPageEnum.content || page === ProjectPageEnum.plan) {
      return (
        userRolesByProject?.some((item) => checkUserRole(item.roles, BusinessRoleEnum.executiveManager)) ||
        userRolesByProject?.some((item) => checkUserRole(item.roles, BusinessRoleEnum.manager)) ||
        userRolesByProject?.some((item) => checkUserRole(item.roles, BusinessRoleEnum.instructionalDesigner)) ||
        userRolesByProject?.some((item) => checkUserRole(item.roles, BusinessRoleEnum.author)) ||
        userRolesByProject?.some((item) => checkUserRole(item.roles, BusinessRoleEnum.authorManager)) ||
        userRolesByProject?.some((item) => checkUserRole(item.roles, BusinessRoleEnum.videoProductionManager))
      );
    }
    return false;
  }, [page, userRolesByProject]);

  return useMemo(() => {
    return {
      canIEdit,
    };
  }, [canIEdit]);
}

export default usePermissionsProject;
