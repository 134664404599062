import { gql } from '@apollo/client';

import { USER_TYPE, USER_ROLE_TYPE, COMPANY_TYPE, COMPANY_STATE_TYPE, PAGE_INFO } from './fragments';

const FRONT_PAGE_COURSE = gql`
  fragment FrontPageCourse on ProjectFPNodeType {
    id
    pk
    name
    production_start_date
    production_finish_date
    metrics_changed_deadlines_exist
    metrics_completed_tasks_plan
    metrics_completed_tasks_fact
    metrics_missing_deadlines
    metrics_maximium_artefact_delay
    metrics_project_health
    production_progress
    updated_at
    last_updated
    metrics_total_completed_tasks
    metrics_total_tasks
  }
`;

export const GET_DASHBOARD_COURSES_WIDGET = gql`
  query DashboardCoursesWidget {
    front_page_courses {
      available
      in_production
      with_delays
      require_attention
    }
  }
`;

export const GET_DASHBOARD_COURSES_NO_ASSIGNEES = gql`
  query DashboardCoursesNoAssignees($offset: Int, $first: Int, $after: String) {
    front_page_courses_no_assignees(offset: $offset, first: $first, after: $after) {
      totalCount
      edges {
        cursor
        node {
          ...FrontPageCourse
          state {
            ...CompanyState
          }
          phase_state {
            ...CompanyState
          }
          metrics_project_changed_deadlines_exist
          metrics_total_completed_tasks
          metrics_total_tasks
          projectuser_set {
            id
            is_active
            __typename
            company_user {
              id
              is_active
              __typename
              company {
                ...CompanyData
              }
              user {
                id
                email
                timezone
                name
                state
                icon_image
              }
            }
            roles {
              role {
                ...RoleData
              }
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${COMPANY_TYPE}
  ${USER_ROLE_TYPE}
  ${COMPANY_STATE_TYPE}
  ${PAGE_INFO}
  ${FRONT_PAGE_COURSE}
`;

export const GET_DASHBOARD_COURSES_WITH_ISSUES = gql`
  query DashboardCoursesWithIssues($offset: Int, $first: Int, $after: String) {
    front_page_courses_with_issues(offset: $offset, first: $first, after: $after) {
      totalCount
      edges {
        cursor
        node {
          ...FrontPageCourse
          state {
            ...CompanyState
          }
          phase_state {
            ...CompanyState
          }
          projectuser_set {
            id
            is_active
            company_user {
              id
              is_active
              company {
                ...CompanyData
              }
              user {
                id
                email
                timezone
                name
                state
                icon_image
              }
            }
            roles {
              role {
                ...RoleData
              }
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${COMPANY_TYPE}
  ${USER_ROLE_TYPE}
  ${COMPANY_STATE_TYPE}
  ${PAGE_INFO}
  ${FRONT_PAGE_COURSE}
`;

export const GET_DASHBOARD_COURSES_DEADLINE = gql`
  query DashboardCoursesDeadlineWithinFortnight($offset: Int, $first: Int, $after: String) {
    front_page_courses_deadline_within_fortnight(offset: $offset, first: $first, after: $after) {
      totalCount
      edges {
        cursor
        node {
          ...FrontPageCourse
          state {
            ...CompanyState
          }
          phase_state {
            ...CompanyState
          }
          projectuser_set {
            id
            is_active
            company_user {
              id
              company {
                ...CompanyData
              }
              user {
                id
                email
                timezone
                name
                state
                icon_image
              }
            }
            roles {
              role {
                ...RoleData
              }
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${COMPANY_TYPE}
  ${USER_ROLE_TYPE}
  ${COMPANY_STATE_TYPE}
  ${PAGE_INFO}
  ${FRONT_PAGE_COURSE}
`;
