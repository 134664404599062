import { gql } from '@apollo/client';

export const GET_COMPANY_STATE_LISTS = gql`
  query CompanyStateLists($companyId: Int) {
    company_state_lists(company_id: $companyId) {
      edges {
        node {
          id
          pk
          name
          updated_at
          last_version {
            id
            states {
              id
              name
              order
              button_name
              state_type
              is_new_iteration_initiation
              is_require_discuss_message
              is_could_close_without_complete_issues
            }
          }
          actual_version {
            id
            states {
              id
              name
              order
              button_name
              state_type
              is_new_iteration_initiation
              is_require_discuss_message
              is_could_close_without_complete_issues
            }
          }
        }
      }
    }
  }
`;
