import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';
import { onCreateArtefactVersion, onCreateFile, onUpdateDocument } from 'api/requests/files';
import { onAddIssueDocument } from 'api/requests/issues';
import { BusinessRoleEnum, StateType } from 'types/entities';

import { getExtractCommentId, getInitContent, removeTempHtmlTags } from 'utils/convert';

import useProjectTask from 'hooks/queries/useProjectTask';
import useBoolean from 'hooks/useBoolean';
import useDocumentDiscussions from 'hooks/queries/useDocumentDiscussions';
import useCommonContext from 'hooks/useCommonContext';

import { DocumentFileVersion, TaskInfoQueryData } from '../../../types';

function useEditor() {
  const [ready, onReady] = useBoolean(false);
  const [editorReady, onEditorReady] = useBoolean(false);
  const [readOnly, onReadOnly] = useBoolean(false);
  const [fileId, setFileId] = useState<string | null>(null);
  const [fileVerId, setFileVerId] = useState<string>(''); //Current document file version id
  const [discussionId, setDiscussionId] = useState<null | string>(null);
  const [disBlockId, setDisBlockId] = useState<null | string>(null);
  const [blocks, setBlocks] = useState<any>(null);
  const [initBlocks, setInitBlocks] = useState<any>(null);
  const editorCore = useRef<any>(null);
  const common = useCommonContext();

  const { taskId } = useParams();
  const [searchParams] = useSearchParams();
  const version = searchParams.get('version');

  const issue = searchParams.get('issue');

  const toggleActiveText = useCallback(() => {
    if (issue) {
      const node = document.getElementById(issue);
      const allMarks = document.querySelectorAll('.mark-comment');
      if (node) {
        node.classList.add('mark-comment--active');
      }
      if (allMarks?.length) {
        allMarks.forEach((el) => {
          if (el.id !== issue) {
            el.classList.remove('mark-comment--active');
          }
        });
      }
    }
  }, [issue]);

  // Toggle read only mode
  const toggleContentEditable = useCallback(() => {
    const nodes = document.querySelectorAll('.cdx-block');
    const headers = document.querySelectorAll('.ce-header');
    const imageCaption = document.querySelectorAll('.image-tool__caption');
    const tableCells = document.querySelectorAll('.tc-cell');
    const ceToolbar = document.querySelector('.ce-toolbar') as HTMLElement;
    const ceRedactor = document.querySelector('.codex-editor__redactor') as HTMLElement;
    const tcToolboxRow = document.querySelector('.tc-toolbox--row') as HTMLElement;
    const tcToolboxColumn = document.querySelector('.tc-toolbox--column') as HTMLElement;
    const tcAddColumn = document.querySelector('.tc-add-column') as HTMLElement;
    const tcAddRow = document.querySelector('.tc-add-row') as HTMLElement;
    const tcRow = document.querySelectorAll('.tc-row') as NodeListOf<Element>;

    if (readOnly) {
      if (nodes) {
        nodes.forEach((node) => node.setAttribute('contenteditable', 'false'));
      }
      if (headers) {
        headers.forEach((node) => node.setAttribute('contenteditable', 'false'));
      }
      if (tableCells) {
        tableCells.forEach((node) => node.setAttribute('contenteditable', 'false'));
      }
      if (imageCaption) {
        imageCaption.forEach((node) => node.setAttribute('contenteditable', 'false'));
      }
      if (tcToolboxRow) {
        tcToolboxRow.style.display = 'none';
      }
      if (tcToolboxColumn) {
        tcToolboxColumn.style.display = 'none';
      }
      if (tcAddColumn) {
        tcAddColumn.style.display = 'none';
      }
      if (tcAddRow) {
        tcAddRow.style.display = 'none';
      }
      if (tcRow) {
        tcRow.forEach((n) => n.classList.add('tc-row-hidden'));
      }
      if (ceToolbar) {
        ceToolbar.style.display = 'none';
      }
      if (ceRedactor) {
        ceRedactor.style.setProperty('padding-bottom', '0', 'important');
      }
    } else {
      if (nodes) {
        nodes.forEach((node) => node.setAttribute('contenteditable', 'true'));
      }
      if (headers) {
        headers.forEach((node) => node.setAttribute('contenteditable', 'true'));
      }
      if (tableCells) {
        tableCells.forEach((node) => node.setAttribute('contenteditable', 'true'));
      }
      if (imageCaption) {
        imageCaption.forEach((node) => node.setAttribute('contenteditable', 'true'));
      }
      if (tcToolboxRow) {
        tcToolboxRow.style.display = 'block';
      }
      if (tcToolboxColumn) {
        tcToolboxColumn.style.display = 'block';
      }
      if (tcAddColumn) {
        tcAddColumn.style.display = 'flex';
      }
      if (tcAddRow) {
        tcAddRow.style.display = 'flex';
      }
      if (tcRow) {
        tcRow.forEach((n) => n.classList.remove('tc-row-hidden'));
      }
      if (ceToolbar) {
        ceToolbar.style.display = 'block';
      }
      if (ceRedactor) {
        ceRedactor.style.paddingBottom = '300px';
      }
    }
  }, [readOnly]);

  useEffect(() => {
    if (editorReady) {
      toggleActiveText();
      toggleContentEditable();
    }
  }, [editorReady, toggleActiveText, toggleContentEditable]);

  useEffect(() => {}, []);

  const onReadyEditor = () => onEditorReady.on();

  const getArtFileVersion = useCallback(
    (art: any) => {
      if (version) {
        return (
          art.artefact_versions?.find((artefactVersion: any) => artefactVersion?.number === Number(version)) || null
        );
      }
      return art?.last_artefact_iteration?.artefact_file_version || null;
    },
    [version]
  );

  const dataProcessing = useCallback(
    (data?: TaskInfoQueryData) => {
      const projectTask = data?.artefact_production_sequence?.find((art: any) => art?.project_task?.id === taskId);
      const currentArtefact = projectTask?.project_task.artefact_list?.[0];
      const artefactFileVersion = getArtFileVersion(currentArtefact);

      const currentExecutor = projectTask?.project_task?.projecttaskassignee_set?.find(
        (assigner) => assigner.is_current_executor && assigner.is_active
      );

      const isCurrentExecutor = Number(currentExecutor?.project_user.company_user.user.id) === Number(common.userId);
      const isCurrentExecutorMethodist = currentExecutor?.project_user.roles.some(
        (r) => r.role.role === BusinessRoleEnum.instructionalDesigner
      );

      const artefactFile =
        projectTask?.project_task.artefact_list?.[0]?.artefact_versions?.find(
          (ver: any) => ver?.id === artefactFileVersion?.id
        )?.file || null;

      const relatedFiles = artefactFile?.related_files as DocumentFileVersion[];
      const initFileVerId = relatedFiles?.[0]?.id;

      const temp = relatedFiles?.[0]?.original_data ? JSON.parse(relatedFiles?.[0]?.original_data) : null;

      if (projectTask?.project_task?.last_state?.state_type === StateType.DONE) {
        onReadOnly.on();
      }

      if (projectTask?.project_task?.last_state?.state_type !== StateType.DONE) {
        if (artefactFileVersion === null && isCurrentExecutorMethodist) {
          onReadOnly.on();
        }
        if (artefactFileVersion === null && !isCurrentExecutorMethodist && isCurrentExecutor) {
          onReadOnly.off();
        }

        if (artefactFileVersion && !artefactFileVersion?.is_current) {
          onReadOnly.on();
        }

        if (isCurrentExecutor && artefactFileVersion?.is_current && !isCurrentExecutorMethodist) {
          onReadOnly.off();
        }
        if (isCurrentExecutor && artefactFileVersion?.is_current && isCurrentExecutorMethodist) {
          onReadOnly.on();
        }
        if (!isCurrentExecutor) {
          onReadOnly.on();
        }
      }

      if (initFileVerId) {
        setFileVerId(initFileVerId);
      }
      setInitBlocks(temp);
      editorCore.current.render(temp);
    },
    [common, getArtFileVersion, onReadOnly, taskId]
  );

  const onCompleted = useCallback(
    (data: TaskInfoQueryData) => {
      const projectTask = data?.artefact_production_sequence?.find((art: any) => art?.project_task?.id === taskId);
      const currentArtefact = projectTask?.project_task.artefact_list?.[0];
      const artefactFileVersion = getArtFileVersion(currentArtefact);

      const currentExecutor = projectTask?.project_task?.projecttaskassignee_set?.find(
        (assigner) => assigner.is_current_executor && assigner.is_active
      );

      const isCurrentExecutor = Number(currentExecutor?.project_user?.company_user?.user.id) === Number(common.userId);

      const isCurrentExecutorMethodist = currentExecutor?.project_user?.roles?.some(
        (r) => r.role.role === BusinessRoleEnum.instructionalDesigner
      );
      const artefactFile =
        projectTask?.project_task.artefact_list?.[0]?.artefact_versions?.find(
          (ver: any) => ver?.id === artefactFileVersion?.id
        )?.file || null;

      const relatedFiles = artefactFile?.related_files as DocumentFileVersion[];

      const temp = relatedFiles?.[0]?.original_data ? JSON.parse(relatedFiles?.[0]?.original_data) : null;

      const initFileId = artefactFile?.id || null;
      const initFileVerId = relatedFiles?.[0]?.id;

      if (projectTask?.project_task?.last_state?.state_type === StateType.DONE) {
        onReadOnly.on();
      }

      if (projectTask?.project_task?.last_state?.state_type !== StateType.DONE) {
        if (artefactFileVersion === null && isCurrentExecutorMethodist) {
          onReadOnly.on();
        }
        if (artefactFileVersion === null && !isCurrentExecutorMethodist && isCurrentExecutor) {
          onReadOnly.off();
        }

        if (artefactFileVersion && !artefactFileVersion?.is_current) {
          onReadOnly.on();
        }

        if (isCurrentExecutor && artefactFileVersion?.is_current && !isCurrentExecutorMethodist) {
          onReadOnly.off();
        }
        if (isCurrentExecutor && artefactFileVersion?.is_current && isCurrentExecutorMethodist) {
          onReadOnly.on();
        }
        if (!isCurrentExecutor) {
          onReadOnly.on();
        }
      }

      if (temp) {
        setInitBlocks(getInitContent(temp, currentArtefact?.document_discussions_set));
      }
      if (initFileId) {
        setFileId(initFileId);
      }
      if (initFileVerId) {
        setFileVerId(initFileVerId);
      }

      if (!ready) {
        onReady.on();
      }
    },
    [common, getArtFileVersion, onReadOnly, onReady, ready, taskId]
  );

  const {
    data,
    projectTask,
    currentArtefact,
    currentProject,
    artefactFileVersion,
    projectId,
    companyId,
    loadingProjectTask,
    artefactFile,
    refetchProjectTask,
  } = useProjectTask(version, 'cache-and-network', onCompleted);

  const { loading: discussionsLoading, discussionsRefetch } = useDocumentDiscussions({
    artefactId: Number(currentArtefact?.id),
  });

  useEffect(() => {
    if (editorReady && version) {
      dataProcessing(data);
    }
  }, [data, dataProcessing, editorReady, version]);

  const initializeEditor = useCallback((instance: any) => {
    editorCore.current = instance;
  }, []);

  const onSaveChanges = async () => {
    const currentStatus = projectTask?.project_task?.last_state?.state_type;
    const currentExecutor = projectTask?.project_task?.projecttaskassignee_set?.find(
      (assigner) => assigner.is_current_executor && assigner.is_active
    );
    const isCurrentExecutor = Number(currentExecutor?.project_user?.company_user?.user.id) === Number(common.userId);

    if (currentStatus === StateType.NEW || currentStatus === StateType.REOPEN) {
      if (fileId) {
        if (artefactFileVersion?.is_current) {
          if (artefactFileVersion?.is_active && isCurrentExecutor) {
            const newFile = await onCreateFile('document');
            const newFilePayload = {
              file_id: newFile.id || '',
              artefact_id: currentArtefact?.id || '',
            };
            await onCreateArtefactVersion(newFilePayload);
            await onUpdateDocument(newFile.id, blocks).then((res: any) => {
              setFileVerId(res?.document_file_version_id);
              refetchProjectTask();
            });
          } else {
            onUpdateDocument(fileId, blocks).then((res: any) => {
              setFileVerId(res?.document_file_version_id);
            });
          }
        }
      } else {
        if (isCurrentExecutor) {
          const newFile = await onCreateFile('document');
          const newFilePayload = {
            file_id: newFile.id || '',
            artefact_id: currentArtefact?.id || '',
          };
          setFileId(newFile.id);
          await onCreateArtefactVersion(newFilePayload);
          onUpdateDocument(newFile.id, blocks).then((res: any) => {
            setFileVerId(res?.document_file_version_id);
            refetchProjectTask();
          });
        }
      }
    } else {
      if (fileId) {
        onUpdateDocument(fileId, blocks).then((res: any) => {
          setFileVerId(res?.document_file_version_id);
        });
      }
    }
  };

  const onChangeEditor = useCallback(async () => {
    const savedData = await editorCore?.current?.save();
    const { newId, blockId, updatedData } = getExtractCommentId(savedData);

    if (newId) {
      setDiscussionId(newId);
      setDisBlockId(blockId);
      if (editorCore.current && discussionId) {
        editorCore.current.configuration.tools.commentTool.config.disable = true;
      }
    }

    setBlocks(updatedData);
  }, [common.userId, discussionId, projectTask?.project_task?.projecttaskassignee_set]);

  const onRemoveMarkerById = (markerId: string) => {
    const tempBlocks = blocks ? { ...blocks } : { ...initBlocks };

    const temp = tempBlocks.blocks.map((el: any) => {
      const text = el.data.text;
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(text, 'text/html');
      const node = htmlDoc.getElementById(markerId);

      if (node) {
        const nodeContent = node.textContent || '';
        const newElement = htmlDoc.createElement('remove');
        newElement.innerText = nodeContent;
        node?.parentNode?.replaceChild(newElement, node);
      }
      const convertedToText = htmlDoc.documentElement.innerHTML;
      return {
        ...el,
        data: {
          ...el.data,
          text: removeTempHtmlTags(convertedToText),
        },
      };
    });

    const newBlock = {
      ...tempBlocks,
      blocks: temp,
    };
    setBlocks(newBlock);
    editorCore.current.render(newBlock);
    setTimeout(() => toggleContentEditable(), 1000);
  };

  useEffect(() => {
    if (blocks) {
      onSaveChanges();
    }
    //Don't update deps
  }, [blocks, readOnly]);

  const onCancelTempDiscussion = () => {
    if (discussionId) {
      onRemoveMarkerById(discussionId);
      setDiscussionId(null);
    }
  };

  const onAddDiscussion = async (text?: string) => {
    const payload = {
      artefact_id: currentArtefact?.id || '',
      description: text || '',
      document_file_version_id: fileVerId,
      document_file_section_external_id: disBlockId || '',
      external_id: discussionId || '',
    };
    await onAddIssueDocument(payload).then(() => {
      setDiscussionId(null);
      setDisBlockId(null);
      //editorCore.current.render(blocks);
    });
    return discussionsRefetch();
  };

  return {
    projectTask,
    currentArtefact,
    currentProject,
    projectId,
    companyId,
    refetchProjectTask,
    loadingProjectTask,
    artefactFile,
    editorCore: editorCore.current,
    readOnly,
    hasReady: ready,
    discussionsLoading,
    discussionId,
    onCancelTempDiscussion,
    onRemoveMarkerById,
    onAddDiscussion,
    setDiscussionId,
    initBlocks,
    initializeEditor,
    onChangeEditor,
    blocks,
    editorReady,
    onReadOnly,
    onReadyEditor,
  };
}

export default useEditor;
