import API from 'api';

import { ApiModules, ENDPOINTS } from '../constants';

export const updateProjectLOAll = (projectId: number, payload: any) =>
  API(ApiModules.PM).put(ENDPOINTS.PROJECT_LO_UPDATE_ALL(projectId), payload);
export const importProgramFile = (projectId: number, payload: any) =>
  API(ApiModules.PM).post(ENDPOINTS.IMPORT_COURSE, payload);

export type ArtefactProductionSequencePayload = {
  id: number;
  is_turn_off?: boolean;
  artefact_production_deadline?: string | null;
  first_iteration_deadline?: string | null;
  executors: {
    user_role: number;
    project_user: number;
  }[];
};

export const updateProjectArtefactProductionSequence = (
  projectId: number,
  payload: ArtefactProductionSequencePayload[]
) => API(ApiModules.PM).put(ENDPOINTS.PROJECT_LO_UPDATE_ART_PROD_SEQUENCE(projectId), payload);
