import { gql } from '@apollo/client';

export const GET_COMPANIES_FOR_INVITE = gql`
  query CompaniesForInvite {
    companies_for_invite {
      id
      slug
      name
      company_type
    }
  }
`;
