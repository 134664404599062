import React, { memo } from 'react';

import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { INavigationItem } from 'types/global';
import { convertRoles, cx } from 'utils';

import Block from 'components/block';

import './styles.scss';
import useCommonContext from '../../../hooks/useCommonContext';
import { buildAbilityFor } from '../../../config/ability';

type HeaderNavigationProps = {
  list: INavigationItem[];
  hasPermissions?: boolean;
  hasMain?: boolean;
};

function HeaderNavigation({ list, hasMain }: HeaderNavigationProps) {
  const common = useCommonContext();
  const baseClassName = hasMain ? 'main_navigation' : 'header-navigation';
  const ability = buildAbilityFor(convertRoles(common.businessRoles));

  return (
    <div className={baseClassName}>
      <ul className={`${baseClassName}__list`}>
        <Block empty>
          {list.map((item) => {
            const classNames = cx(`${baseClassName}__item`, item.testClassName);

            const canISeeProtectedPage = ability.can('view', 'Dashboard');

            if (item.path === '/dashboard' && !canISeeProtectedPage) {
              return null;
            }

            return (
              <li key={item.id} className={classNames}>
                <NavLink to={item.path} className={`${baseClassName}__link`}>
                  {({ isActive }) => (
                    <>
                      {hasMain ? (
                        <img
                          className={`${baseClassName}__icon`}
                          src={isActive ? item.iconActive : item.icon}
                          alt="icon"
                        />
                      ) : null}
                      <span className={`${baseClassName}__label`}>{common.t<string>(item.name)}</span>
                    </>
                  )}
                </NavLink>
              </li>
            );
          })}
        </Block>
      </ul>
    </div>
  );
}

export default memo(HeaderNavigation);
