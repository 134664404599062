import { gql } from '@apollo/client';

export const GET_COMPANY_SEPARATE_TASK_TYPES = gql`
  query GetCompanySeparateTaskTypes($companyId: Int) {
    company_separate_task_types(company_id: $companyId) {
      id
      name
      icon_image
    }
  }
`;
