import React, { memo, ReactNode } from 'react';

import { cx } from 'utils';

import './styles.scss';

type DashboardWidgetProps = {
  className?: string;
  label: string;
  value?: ReactNode;
  type?: 'warning' | 'danger';
};

function DashboardWidget({ className, label, value, type }: DashboardWidgetProps) {
  const classNames = cx('dashboard-widget', className);
  const classNamesValue = cx('dashboard-widget__value', {
    [`dashboard-widget__value--${type}`]: type && value != 0,
    [`dashboard-widget__value--gray`]: value === 0,
  });
  return (
    <div className={classNames}>
      <div className="dashboard-widget__top mb_16">
        <span className="dashboard-widget__label">{label}</span>
      </div>
      <div className="dashboard-widget__bottom">
        <span className={classNamesValue}>{value}</span>
      </div>
    </div>
  );
}

export default memo(DashboardWidget);
