import React from 'react';

import { cx } from 'utils';

import Dropdown from 'components/dropdown';
import DropdownMenu from 'components/dropdownMenu/DropdownMenu';

import { CompanyLearningObjectType } from 'hooks';

import useVisible from 'hooks/useVisible';
import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

type ElementsDropdownProps = {
  data?: CompanyLearningObjectType[];
  action: (id: number, type: any, name: string, iconImage: string | null) => void;
};

function ElementsDropdown({ data, action }: ElementsDropdownProps) {
  const { isVisible, setIsVisible } = useVisible(false);

  const common = useCommonContext();

  if (!data || !data?.length) {
    return null;
  }

  const list = data?.map((el) => {
    return {
      id: el.id,
      label: el.name,
      action: () => action(Number(el.id), el?.type, el.name, el?.icon_image),
    };
  });

  const classNames = cx('elements-dropdown', {
    'elements-dropdown--opened': isVisible,
  });

  return (
    <Dropdown
      className={classNames}
      placement="top"
      variant="button"
      open={isVisible}
      onOpenChange={setIsVisible}
      overlay={<DropdownMenu list={list} />}
    >
      {common.t<string>('common.anotherElement')}
    </Dropdown>
  );
}

export default ElementsDropdown;
