import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { GET_PROJECT_TASK_METRICS } from 'queries/getProjectTaskMetrics';

export type ProjectTaskMetricsItem = {
  tasks_with_missed_deadlines: number;
};

type PlanFilters = {
  searchText?: string | null;
  statusIds?: number[] | string[];
  statusName?: string[];
  artefactTypeIds?: number[];
  artefactTypeNames?: string[];
  assigneeIds?: number[];
  missed?: boolean | null;
};

function useProjectTaskMetrics(projectId?: string, planFilters?: PlanFilters, hasSequence?: boolean) {
  const { data, refetch } = useQuery(GET_PROJECT_TASK_METRICS, {
    variables: {
      project_id: Number(projectId),
      plan_filters: {
        search_text: planFilters?.searchText,
        all_tasks_state_names: planFilters?.statusName,
        artefact_type_names: planFilters?.artefactTypeNames,
        assignee_ids: planFilters?.assigneeIds,
      },
    },
    skip: !projectId,
  });

  return useMemo(() => {
    return {
      projectTaskMetrics: data?.project_metrics as ProjectTaskMetricsItem,
      projectTaskMetricsRefetch: refetch,
    };
  }, [data?.project_metrics, refetch]);
}

export default useProjectTaskMetrics;
