import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_TASK_LO_SIDEBAR } from 'queries/getTaskInfoSidebar';

export type LearningObjectSidebar = {
  id: string;
  name: string;
  description: string;
  project: {
    id: string;
    pk: string;
    name: string;
    company: {
      id: string;
    };
  };
  learning_object_type: {
    icon_image: string;
  };
  parent: {
    human_readable_number: number;
  };
  project_learning_outcome: {
    description: string;
  };
};

type LearningObjectSidebarData = {
  learning_object: LearningObjectSidebar;
};

const useLearningObjectSidebar = () => {
  const { learningObjectId } = useParams();
  const { data, loading, refetch } = useQuery<LearningObjectSidebarData>(GET_TASK_LO_SIDEBAR, {
    variables: {
      learning_id: learningObjectId,
    },
    fetchPolicy: 'no-cache',
  });

  return {
    data: data?.learning_object,
    loading,
    refetch,
  };
};

export default useLearningObjectSidebar;
