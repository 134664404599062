import { gql } from '@apollo/client';

export const GET_FINAL_ARTEFACT_PIPELINE = gql`
  query FinalArtefactPipeline($project_task_id: Int!) {
    final_artefact_pipeline(project_task_id: $project_task_id) {
      pk
      name
      description
      last_state {
        id
      }
      state_list_version_id
      task_type
      all_project_task_state_history {
        id
        project_task {
          id
        }
        state {
          id
          name
          state_type
        }
        changed_by {
          id
          name
          first_name
          last_name
          email
        }
        creator {
          id
          name
        }
        created_at
        is_rollback
        changed_by_role {
          id
          role
          name
        }
      }
    }
  }
`;
