import { useQuery } from '@apollo/client';
import { GET_PROJECTS } from 'queries/getProjects';

import useCommonContext from '../useCommonContext';
import { ProjectsResponseData, UseProjectsProps } from '../../pages/courses/types';

function useProjects({
  textSearch,
  delaysExist,
  companyDelaysExist,
  delays,
  assigned,
  offset,
  first,
  onCompleted,
  skip,
}: UseProjectsProps) {
  const common = useCommonContext();

  const { data, loading, fetchMore } = useQuery<ProjectsResponseData>(GET_PROJECTS, {
    variables: {
      user_id: common?.userId,
      company_id: common?.company?.id,
      name: textSearch,
      delaysExist,
      companyDelaysExist,
      delays,
      assigned,
      offset,
      first,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    onCompleted,
    skip,
    notifyOnNetworkStatusChange: true,
  });

  return {
    data,
    hasNextPage: data?.projects?.pageInfo?.hasNextPage,
    loading,
    fetchMore,
  };
}

export default useProjects;
