/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ProjectReadShort = {
    readonly id?: number;
    name: string;
    project_type: ProjectReadShort.project_type;
};

export namespace ProjectReadShort {

    export enum project_type {
        CONTENT_CREATION = 'content_creation',
        TRANSLATE = 'translate',
    }


}
