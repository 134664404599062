import { onCreateFile, onPutCheckListUrl, onPutFileUrl, onSetFileState } from 'api/requests/files';
import { isEmpty } from 'lodash';
import { UploadStateEnum } from 'types/entities';

import { ArtefactTypeName } from 'store/projects/interfaces';

import { ArtefactFile, ArtefactFileIteration } from '../../types';
import { createArtefactVersion } from '../finalPresentation/fetches';

export type PutUrlRequestType = {
  url?: string;
};

export const onUploadExternalUrl = async (
  externalUrl: string,
  artId: string | undefined,
  artefactFileVersion: ArtefactFileIteration | null,
  artefactFiles: ArtefactFile | null
) => {
  if (externalUrl?.length) {
    const payload: PutUrlRequestType = {
      url: externalUrl,
    };
    if (
      !artefactFiles?.pk ||
      isEmpty(artefactFileVersion) ||
      artefactFileVersion === null ||
      artefactFileVersion?.is_active
    ) {
      await onCreateFile(ArtefactTypeName.url).then(async (resp) => {
        await onPutFileUrl(resp?.id, payload)
          .then(async () => {
            await onSetFileState(resp?.id, { state: UploadStateEnum.UPLOADED });
          })
          .then(async () => {
            await createArtefactVersion({ file_id: resp?.id, artefact_id: Number(artId) });
          });
      });
    } else {
      await onPutFileUrl(Number(artefactFiles?.pk), payload);
    }
  } else {
    // message.error(i18next.t<string>('errors.messages.upload.allowedVideoFormats'));
  }
};

export const onUploadCheckListUrl = async (
  checklistArr: Array<{ file_document_section_id: number }>,
  artefactFileVersionId: string | undefined,
  fileId: number | null
) => {
  if (fileId && artefactFileVersionId) {
    await onPutCheckListUrl(Number(artefactFileVersionId), fileId, checklistArr);
  }
};
