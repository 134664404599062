import React, { memo } from 'react';

import { TabPaneProps, Tabs } from 'antd';
import { cx } from 'utils';

interface ITabPaneProps extends TabPaneProps {
  className?: string;
}

function TabPane({ children, className, ...props }: ITabPaneProps) {
  const classNames = cx('ccm-tab-pane', className);
  return (
    <Tabs.TabPane className={classNames} {...props}>
      {children}
    </Tabs.TabPane>
  );
}

export default memo(TabPane);
