import React, { memo } from 'react';

import { generatePath, Link, useParams } from 'react-router-dom';
import { PATH_NAMES } from 'router/constants';

import Block from 'components/block/Block';

import useCommonContext from 'hooks/useCommonContext';

function PlanEmpty() {
  const { id } = useParams();
  const common = useCommonContext();
  const link = generatePath(PATH_NAMES.project.baseExtended, {
    id: id,
    entity: PATH_NAMES.project.entity.content,
  });
  return (
    <Block className="project-page-plan-empty">
      <div className="project-page-plan-empty__wrapper">
        <span className="project-page-plan-empty__title">{common.t<string>('pages.project.emptyPlan')}</span>
        <span className="project-page-plan-empty__text">{common.t<string>('pages.project.planWillAppear')}</span>
        <Link className="project-page-plan-empty__link" to={link}>
          {common.t<string>('pages.project.emptyPlanLink')}
        </Link>
      </div>
    </Block>
  );
}

export default memo(PlanEmpty);
