import React, { useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { notification } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { getParents } from '@minoru/react-dnd-treeview';
import { flattenDeep } from 'lodash';
import { onlyUnique } from 'utils';
import { ExportVideoParams } from 'types';

import { Drawer, DrawerFooter, DrawerHeader } from 'components/drawer';
import Button from 'components/button/Button';

import useCommonContext from 'hooks/useCommonContext';
import useProject from 'hooks/queries/useProject';

import { UseExportOutput } from '../../hooks/useExport';
import { ExportCourseElements } from '../../types';

import DownloadDrawerContent from './components/content';
import { cancelExportFile, getExportFile } from './fetches';

import './styles.scss';

type DownloadDrawerProps = {
  visible: boolean;
  exportInstance: UseExportOutput;
  onClose: () => void;
};

function DownloadDrawer({ visible, exportInstance, onClose }: DownloadDrawerProps) {
  const common = useCommonContext();
  const { id } = useParams();
  const key = `open${Date.now()}`;
  const { projectData } = useProject(Number(id));

  const header = useMemo(() => {
    return <DrawerHeader title={common.t<string>('pages.project.export.courseExport')} onClose={onClose} />;
  }, [common, onClose]);

  const parentsMatrix: string[][] = exportInstance.selected.map((el) => {
    const temp = getParents(exportInstance.treeData, Number(el));
    return temp.map((node) => node.id.toString());
  });

  const parents: string[] = flattenDeep(parentsMatrix).filter(onlyUnique);
  const projectLearningObjectIds = [...exportInstance?.selected, ...parents].map((el) => Number(el));

  function closeNotification() {
    notification.destroy(key);
  }

  const exportPayload: ExportCourseElements = {
    project_learning_object_ids: projectLearningObjectIds,
    export_content_params: {
      file_extension: exportInstance?.elements?.structure.radio,
      export_project_content: exportInstance.elements.structure.selected,
    },
    export_video_params: {
      export_video: exportInstance.elements.video.selected,
      file_extension: ExportVideoParams.file_extension.MP4,
      resolution: exportInstance?.elements?.video?.value?.value,
    },
    export_text_params: {
      file_extension: exportInstance.elements.addMaterials.radio,
      export_text: exportInstance.elements.addMaterials.selected,
    },
    export_task_params: {
      file_extension: exportInstance.elements.tasks.radio,
      export_task: exportInstance.elements.tasks.selected,
    },
  };

  const cancelExport = async () => {
    await cancelExportFile(projectData?.id, exportPayload, closeNotification);
    notification.destroy(key);
  };

  const showNotification = () => {
    notification.open({
      message: (
        <>
          <Loading3QuartersOutlined className="mr_16" spin />
          {common?.t<string>('common.formingFiles')}
        </>
      ),
      description: common?.t<string>('common.tenMinutes'),
      btn: (
        <Button onClick={cancelExport} size="middle">
          {common?.t<string>('common.canceling')}
        </Button>
      ),
      key,
      duration: 0,
      onClose: close,
    });
  };

  const onExport = async () => {
    showNotification();
    await getExportFile(projectData?.id, exportPayload, closeNotification);
  };

  const disableSubmit =
    !exportInstance.elements.structure.selected &&
    !exportInstance.elements.video.selected &&
    !exportInstance.elements.addMaterials.selected &&
    !exportInstance.elements.tasks.selected;

  const footer = useMemo(() => {
    return (
      <DrawerFooter
        //loading={loading}
        labelSubmit={common.t<string>('common.download')}
        disabled={disableSubmit}
        disabledHint={common.t<string>('pages.project.tooltips.selectMaterials')}
        onSubmit={onExport}
        labelCancel={common.t<string>('common.canceling')}
        onCancel={onClose}
      />
    );
  }, [common, disableSubmit, onClose, onExport]);

  return (
    <Drawer
      className="drawer-download"
      open={visible}
      title={header}
      footer={footer}
      closable={false}
      maskClosable={true}
      mask={false}
      destroyOnClose
      placement="right"
      width={560}
    >
      <DownloadDrawerContent
        version={projectData?.current_version}
        title={projectData?.name}
        exportInstance={exportInstance}
      />
    </Drawer>
  );
}

export default DownloadDrawer;
