import { TitleVariantEnum } from '../title/types';

export const getTitleType = (level?: number) => {
  switch (level) {
    case 1:
      return TitleVariantEnum.h1;
    case 2:
      return TitleVariantEnum.h2;
    case 3:
      return TitleVariantEnum.h3;
    case 4:
      return TitleVariantEnum.h4;
    case 5:
      return TitleVariantEnum.h5;
    case 6:
      return TitleVariantEnum.h6;
    default:
      return TitleVariantEnum.h4;
  }
};
