import React, { memo, useCallback, useRef } from 'react';

import { useParams } from 'react-router-dom';
import { last } from 'lodash';

import { MaterialItem } from 'hooks/queries/useMaterials';

import Block from '../block';
import SvgInline from '../svgInline';
import { bytesToMb } from '../../utils';
import Editor from '../editor';

import './styles.scss';

type MaterialsProps = {
  materials: MaterialItem[];
};

const getMaterialByType = (material: MaterialItem, initializeEditor?: any) => {
  const currentFileType = material?.current_file?.file_type;
  const currentFileTypeName = material?.artefact_type?.name;
  const sourceFiles = last(material?.current_file?.source_files);

  switch (currentFileType) {
    case 'video': {
      const url = sourceFiles?.source_url;
      const fileExtension = sourceFiles?.file_extension;
      return (
        <div className="ccm-material-item">
          <div className="ccm-material-item__video">
            <div className="ccm-material-item__video__title">
              {/*<DownloadLink material={material} />*/}
              <a href={url} target="_blank" download="file.mp4">
                <SvgInline url={material?.artefact_type?.icon_image} />
                <span>{currentFileTypeName}</span>
              </a>
            </div>
            <div className="ccm-material-item__video__info">{`${fileExtension}, ${bytesToMb(
              sourceFiles?.file_size || 0
            )} MB`}</div>
          </div>
        </div>
      );
    }
    case 'presentation': {
      const url = sourceFiles?.source_url;
      const fileType = sourceFiles?.file_extension;
      const fileSize = sourceFiles?.file_size;
      return (
        <div className="ccm-material-item">
          <div className="ccm-material-item__video">
            <div className="ccm-material-item__video__title">
              <a href={url} target="_blank" download>
                <SvgInline url={material?.artefact_type?.icon_image} />
                <span>{currentFileTypeName}</span>
              </a>
            </div>
            <div className="ccm-material-item__video__info">
              {fileType}, {fileSize && `${bytesToMb(fileSize)} MB`}
            </div>
          </div>
        </div>
      );
    }
    case 'url': {
      const url = sourceFiles?.external_source_url;
      return (
        <div className="ccm-material-item">
          <div className="ccm-material-item__link__title">
            <a href={url} target="_blank">
              <SvgInline url={material?.artefact_type?.icon_image} />
              <span>{currentFileTypeName}</span>
            </a>
          </div>
        </div>
      );
    }
    case 'document': {
      const initData = material?.current_file?.related_files?.[0].original_data;
      const data = JSON.parse(initData);
      return (
        <div className="ccm-material-item">
          <div className="ccm-material-item__document__title mb_16">{material?.artefact_type?.name}</div>
          <div className="ccm-material-item__document__editor">
            <Editor readOnly editorId="editor-js-materials" initialize={initializeEditor} defaultValue={data} />
          </div>
        </div>
      );
    }
  }
};

function Materials({ materials }: MaterialsProps) {
  const editorCore = useRef<any>(null);
  const initializeEditor = useCallback((instance: any) => {
    editorCore.current = instance;
  }, []);

  const { artefactId } = useParams();

  const filtered = materials?.filter((m) => Number(m.id) !== Number(artefactId));
  return (
    <Block>
      {filtered?.map((material) => (
        <div key={material?.id}>{getMaterialByType(material, initializeEditor)}</div>
      ))}
    </Block>
  );
}

export default memo(Materials);
