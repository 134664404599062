import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { getFilteredUserExt, getIsMultipleChoice, sortUserExtended } from 'utils';
import { BusinessRoleEnum, UserExtended } from 'types/entities';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import { Drawer, DrawerFooter, DrawerHeader } from 'components/drawer';
import Block from 'components/block';
import LoadingOverlay from 'components/loadingOverlay';

import useCommonContext from 'hooks/useCommonContext';
import useCompanyRoles from 'hooks/queries/useCompanyRoles';
import useBoolean from 'hooks/useBoolean';

import { UsersState } from '../../addCourse/hooks/useAddCourseControl';
import AddParticipantsForm from '../addParticipantsForm';
import ParticipantsGantt from '../participantsGantt';
import { UseAddFormOutput } from '../../users/hooks/useAddForm';
import useCollaboratorsByRoleQuery from '../../../hooks/queries/useCollaboratorsByRoleQuery';
import Button from '../../../components/button/Button';
import { ProjectUserType } from '../../../hooks';

import SingleChoice from './components/SingleChoice';
import MultipleChoice from './components/MultipleChoice';
import UserAddBlock from './components/UserAddBlock';
import SelectUserSkeleton from './components/Skeleton';

import './styles.scss';

type SelectUserDrawerProps = {
  loading?: boolean;
  companyId?: string;
  closeAfterSave?: boolean;
  visible: boolean;
  userAddForm?: UseAddFormOutput;
  users: UsersState;
  roleId: string | null;
  onClose: () => void;
  onSave: (user: UserExtended | UserExtended[]) => void;
};

function SelectUserDrawer({
  closeAfterSave,
  companyId,
  loading,
  visible,
  userAddForm,
  users,
  roleId,
  onClose,
  onSave,
}: SelectUserDrawerProps) {
  const [expanded, onExpanded] = useBoolean(false);
  const [selected, setSelected] = useState<UserExtended | UserExtended[] | null>(null);
  const [search, setSearch] = useState<string>('');
  const [view, setView] = useState<'select' | 'add'>('select');

  const common = useCommonContext();
  const { companyRoles } = useCompanyRoles(companyId);

  const { collaborators, collaboratorsRefetch, collaboratorsLoading } = useCollaboratorsByRoleQuery({
    userRoleId: roleId,
  });

  const selectedRole = useMemo(() => companyRoles?.find((el) => el.id === roleId), [companyRoles, roleId]);

  const isSingleChoice = useMemo(() => getIsMultipleChoice(selectedRole?.role), [selectedRole?.role]);

  const onChangeSingle = useCallback((user: UserExtended) => setSelected(user), []);

  const onChangeView = useCallback(() => {
    if (view === 'add') {
      setView('select');
    } else {
      setView('add');
    }
  }, [view]);

  const onChangeMultiple = useCallback(
    (checkedValue: Array<CheckboxValueType>) => {
      const temp = collaborators?.filter((user) => checkedValue.some((v) => user.pk === v));
      if (temp) {
        setSelected(temp);
      }
    },
    [collaborators]
  );

  const current = roleId ? users?.[roleId] : null;

  const onSubmit = () => {
    if (selected) {
      onSave(selected);
      if (closeAfterSave) {
        setSelected(null);
        onClose();
      }
    }
  };

  const onSubmitNewUser = async () => {
    await userAddForm?.onSubmit();
    await collaboratorsRefetch().then(() => {
      setView('select');
      userAddForm?.form?.resetFields();
    });
  };

  const onClickClose = useCallback(() => {
    setSelected(null);
    onClose();
  }, [onClose]);

  const handleChangeSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearch(e.target.value);
    },
    [setSearch]
  );

  const showGantBtn =
    selectedRole?.role === BusinessRoleEnum.executiveManager || selectedRole?.role === BusinessRoleEnum.manager;

  const selectHeader = useMemo(
    () => (
      <DrawerHeader
        onClose={onClickClose}
        title={common.t<string>('pages.addCourse.participants.chooseCourseParticipant')}
      >
        <Button size="middle" onClick={onChangeView}>
          {common.t<string>('common.add')}
        </Button>
        {/*<Block empty hidden={!showGantBtn}>*/}
        {/*  <Can I="manage" a="Project.userDrawer.gantButton">*/}
        {/*    <Button*/}
        {/*      onClick={onExpanded.toggle}*/}
        {/*      type={ButtonTypesEnum.text}*/}
        {/*      icon={expanded ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}*/}
        {/*    />*/}
        {/*  </Can>*/}
        {/*</Block>*/}
      </DrawerHeader>
    ),
    [common, onChangeView, onClickClose]
  );

  const addHeader = useMemo(
    () => (
      <DrawerHeader
        goBack={onChangeView}
        onClose={onClose}
        title={common.t<string>('pages.users.addingEmployee')}
      ></DrawerHeader>
    ),
    [common, onChangeView, onClose]
  );

  return (
    <Drawer
      className="drawer-select-user"
      open={visible}
      closable={false}
      destroyOnClose
      maskClosable={true}
      onClose={onClickClose}
      placement="right"
      width={expanded ? 1120 : 560}
      title={view === 'select' ? selectHeader : addHeader}
      footer={
        <DrawerFooter
          labelSubmit={common.t<string>('common.save')}
          disabled={view === 'add' ? userAddForm?.disableSubmit : !selected && !current}
          loading={loading}
          onSubmit={view === 'select' ? onSubmit : onSubmitNewUser}
          labelCancel={common.t<string>('common.canceling')}
          onCancel={onClickClose}
          formId="add-employee"
        />
      }
    >
      <div className="drawer-select-user__wrap">
        <Block empty hidden={view === 'add'}>
          <div className="drawer-select-user__base">
            <UserAddBlock roleName={selectedRole?.name} search={search} onChange={handleChangeSearch} />

            <Block empty hidden={!collaboratorsLoading}>
              <SelectUserSkeleton />
            </Block>

            <Block empty hidden={collaboratorsLoading}>
              {isSingleChoice ? (
                <SingleChoice
                  data={current}
                  userList={sortUserExtended(getFilteredUserExt(search, collaborators))}
                  onChangeSingle={onChangeSingle}
                />
              ) : (
                <MultipleChoice
                  data={current}
                  userList={sortUserExtended(getFilteredUserExt(search, collaborators))}
                  onChangeMultiple={onChangeMultiple}
                />
              )}
            </Block>
          </div>
          <Block hidden={!expanded} className="drawer-select-user__gantt">
            <ParticipantsGantt companyId={common.company?.id} roleId={roleId} />
          </Block>
        </Block>
        <Block className="drawer-select-user__base" hidden={view === 'select'}>
          <AddParticipantsForm companyId={companyId} addForm={userAddForm} />
        </Block>
        <LoadingOverlay show={userAddForm?.loading} text={common.t<string>('pages.users.sendInvitation')} />
      </div>
    </Drawer>
  );
}

export default SelectUserDrawer;
