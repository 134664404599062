import { CompanyState } from '../models/CompanyState';
import { AdminCompanyLearningObjectType } from '../models/AdminCompanyLearningObjectType';

export interface Map {
  [key: string]: string | undefined;
}

const companyArtefactTypes = [
  'original_video',
  'draft_video',
  'draft_presentation',
  'production_presentation',
  'text',
  'grader',
  'peer_review',
  'quiz',
  'script',
  'video',
  'free_task',
];

export type CustomCompanyArtefactTypes = (typeof companyArtefactTypes)[number];

export enum StateType {
  BLOCKED = 'blocked',
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  READY = 'ready',
  REOPEN = 'reopen',
  DONE = 'done',
  REVIEW = 'review',
  CANCELLED = 'cancelled',
  REJECTED = 'rejected',
  PLANNED = 'planned',
}

export const stateTypeMap = {
  BLOCKED: 'blocked',
  NEW: 'new',
  IN_PROGRESS: 'in_progress',
  READY: 'ready',
  REOPEN: 'reopen',
  DONE: 'done',
  REVIEW: 'review',
};

export type LastState = {
  id: string;
  name: string;
  state_type: CompanyState.state_type;
};

export enum CompanyArtefactType {
  VIDEO = 'VIDEO',
  DRAFT_PRESENTATION = 'DRAFT_PRESENTATION',
  PRODUCTION_PRESENTATION = 'PRODUCTION_PRESENTATION',
  TEXT = 'TEXT',
  GRADER = 'GRADER',
}

export const companyArtefactTypeMap: Map = {
  original_video: 'video_shooting',
  draft_video: 'video_shooting',
  draft_presentation: 'draft_presentation',
  production_presentation: 'production_presentation',
  text: 'text',
  grader: 'grader',
  peer_review: 'peer_review',
  quiz: 'quiz',
  script: 'script',
};

export enum FileType {
  VIDEO = 'video',
  PRESENTATION = 'presentation',
  DOCUMENT_INSTRUCTION = 'document_instruction',
  DOCUMENT_CHECKLIST = 'document_checklist',
  DOCUMENT_SCENARIO = 'document_scenario',
  DOCUMENT = 'document',
  URL = 'url',
  FILE = 'file',
}

export const fileTypeMap: Map = {
  PRESENTATION: 'presentation',
  TEXT: 'text',
  VIDEO: 'video',
};

export enum FileState {
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  NEW = 'NEW',
  PREPARING = 'PREPARING',
  UPLOADED = 'UPLOADED',
  uploaded = 'uploaded',
  UPLOADING = 'UPLOADING',
  uploading = 'uploading',
}

export enum UploadStateEnum {
  NEW = 'new',
  CANCELLED = 'cancelled',
  UPLOADED = 'uploaded',
}

export enum ProjectLearningStructureEnum {
  project_root = 'project_root',
  group = 'group',
  production_item = 'production_item',
}
