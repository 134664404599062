import React, { memo } from 'react';

import { Skeleton } from 'antd';

import './styles.scss';

const styles = {
  block: { width: '100%', height: '24px', maxWidth: '94px' },
};

function SkeletonHeader() {
  return (
    <div className="task-page-skeleton-header">
      <Skeleton paragraph={false} avatar={false} active style={styles.block} />
      <Skeleton paragraph={false} avatar={false} active style={styles.block} />
    </div>
  );
}

export default memo(SkeletonHeader);
