import { gql } from '@apollo/client';

export const GET_COMPANY_ROLES_LIST = gql`
  query Company_roles($companyId: Float) {
    user_roles(company__id: $companyId) {
      edges {
        node {
          id
          name
          role
          pk
        }
      }
    }
  }
`;
