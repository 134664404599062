import { useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { GET_LAZY_ARTEFACT_DEADLINES_CHANGED } from 'queries/getLazyArtefactDeadlinesChanged';

import { UserBase } from '../../types/entities/user';

export type LazyArtefactDeadlinesChangedItem = {
  id: string;
  created_at: string;
  created_date: string;
  creator: UserBase;
  deadline_new: string;
};

type ArtefactDeadlinesChangedItemNode = {
  node: LazyArtefactDeadlinesChangedItem;
};

function useLazyArtefactDeadlinesChanged(
  projectId: number,
  artefactId?: number,
  separateTaskId?: number,
  deadlineType?: 'first_iteration_deadline' | 'artefact_production_deadline',
  fetchPolicy?: WatchQueryFetchPolicy
) {
  const [getArtefactDeadlinesChanged, { data, loading }] = useLazyQuery(GET_LAZY_ARTEFACT_DEADLINES_CHANGED, {
    variables: {
      project_id: projectId,
      artefact_id: artefactId,
      separate_task_id: separateTaskId,
      deadline_type: deadlineType,
    },
    fetchPolicy: fetchPolicy || 'network-only',
  });

  const converted: LazyArtefactDeadlinesChangedItem[] = data?.artefact_deadlines_changed.edges?.map(
    (item: ArtefactDeadlinesChangedItemNode) => item.node
  );

  return useMemo(() => {
    return {
      timelineData: converted,
      getArtefactDeadlinesChanged,
      artefactDeadlineLoading: loading,
    };
  }, [converted, getArtefactDeadlinesChanged, loading]);
}

export default useLazyArtefactDeadlinesChanged;
