import React, { memo } from 'react';

import { PATH_NAMES } from 'router/constants';
import { isEmpty } from 'lodash';
import Can from 'config/Can';

import LoadMore from 'components/loadMore/LoadMore';
import Block from 'components/block';
import LoadingOverlay from 'components/loadingOverlay';

import useCommonContext from 'hooks/useCommonContext';

import TaskItem from '../taskItem';
import EntityBlock from '../entityBlock';
import DashboardWidget from '../widget';
import BlockHeader from '../blockHeader';
import Empty from '../../../task/components/empty';
import { DashboardTaskItem, DashboardTasksWidget } from '../../types';

type BaseData = {
  data?: DashboardTaskItem[];
  hasNextPage?: boolean;
  fetchMore?: () => void;
};

type DashboardTasksProps = {
  allDataReady: boolean;
  tasksLoading: boolean;
  tasksWidgetData?: DashboardTasksWidget;
  tasksDeadlinesMissingData: BaseData;
  tasksMoreThanThreeIterationData: BaseData;
  tasksDeadlinesInTwoDaysData: BaseData;
  tasksWithNewCommentsData: BaseData;
  tasksForTodayData: BaseData;
};

function DashboardTasks({
  allDataReady,
  tasksLoading,
  tasksWidgetData,
  tasksDeadlinesMissingData,
  tasksMoreThanThreeIterationData,
  tasksDeadlinesInTwoDaysData,
  tasksWithNewCommentsData,
  tasksForTodayData,
}: DashboardTasksProps) {
  const common = useCommonContext();

  const showEmpty =
    isEmpty(tasksDeadlinesMissingData.data) &&
    isEmpty(tasksMoreThanThreeIterationData.data) &&
    isEmpty(tasksDeadlinesInTwoDaysData.data) &&
    isEmpty(tasksWithNewCommentsData.data) &&
    isEmpty(tasksForTodayData.data);

  return (
    <div className="dashboard-tasks mb_32">
      <BlockHeader
        className="mb_16"
        title={common.t<string>('pages.dashboard.tasks.title')}
        path={PATH_NAMES.tasks.base}
      />
      <Can I="view" a="Dashboard.tasks.widgets">
        <div className="page-dashboard-widgets mb_32">
          <DashboardWidget label={common.t<string>('pages.dashboard.forToday')} value={tasksWidgetData?.for_today} />
          <DashboardWidget label={common.t<string>('pages.dashboard.total')} value={tasksWidgetData?.total_amount} />
          <DashboardWidget
            label={common.t<string>('pages.dashboard.notCompletedOnTime')}
            value={tasksWidgetData?.first_deadline_missed}
            type="warning"
          />
          <DashboardWidget
            label={common.t<string>('pages.dashboard.deadlineOverdue')}
            value={tasksWidgetData?.second_deadline_missed}
            type="danger"
          />
        </div>
      </Can>

      <Block className="page-dashboard__empty-wrap" hidden={!showEmpty}>
        <Empty text={common.t<string>('pages.dashboard.courses.empty')} />
      </Block>

      <Block hidden={showEmpty}>
        <Can I="view" a="Dashboard.tasks.deadlines">
          <EntityBlock
            title={common.t<string>('pages.dashboard.tasks.violationDeadlines')}
            hidden={isEmpty(tasksDeadlinesMissingData?.data)}
          >
            <>
              {tasksDeadlinesMissingData?.data?.map((task) => {
                return <TaskItem key={task.id} data={task} />;
              })}
              <LoadMore
                hasNextPage={tasksDeadlinesMissingData.hasNextPage}
                onFetch={tasksDeadlinesMissingData.fetchMore}
              />
            </>
          </EntityBlock>
        </Can>
        <Can I="view" a="Dashboard.tasks.iteration">
          <EntityBlock
            title={common.t<string>('pages.dashboard.tasks.moreThenThreeIterations')}
            hidden={isEmpty(tasksMoreThanThreeIterationData?.data)}
          >
            <>
              {tasksMoreThanThreeIterationData?.data?.map((task) => {
                return <TaskItem key={task.id} data={task} />;
              })}
              <LoadMore
                hasNextPage={tasksMoreThanThreeIterationData.hasNextPage}
                onFetch={tasksMoreThanThreeIterationData.fetchMore}
              />
            </>
          </EntityBlock>
        </Can>
        <Can I="view" a="Dashboard.tasks.twoDays">
          <EntityBlock
            title={common.t<string>('pages.dashboard.tasks.deadlineWithinTwoDays')}
            hidden={isEmpty(tasksDeadlinesInTwoDaysData?.data)}
          >
            <>
              {tasksDeadlinesInTwoDaysData?.data?.map((task) => {
                return <TaskItem key={task.id} data={task} />;
              })}
              <LoadMore
                hasNextPage={tasksDeadlinesInTwoDaysData.hasNextPage}
                onFetch={tasksDeadlinesInTwoDaysData.fetchMore}
              />
            </>
          </EntityBlock>
        </Can>
        <Can I="view" a="Dashboard.tasks.comments">
          <EntityBlock
            title={common.t<string>('pages.dashboard.tasks.newComments')}
            hidden={isEmpty(tasksWithNewCommentsData?.data)}
          >
            <>
              {tasksWithNewCommentsData?.data?.map((task) => {
                return <TaskItem key={task.id} data={task} />;
              })}
              <LoadMore
                hasNextPage={tasksWithNewCommentsData.hasNextPage}
                onFetch={tasksWithNewCommentsData.fetchMore}
              />
            </>
          </EntityBlock>
        </Can>
        <Can I="view" a="Dashboard.tasks.today">
          <EntityBlock
            title={common.t<string>('pages.dashboard.tasks.today')}
            hidden={isEmpty(tasksForTodayData?.data)}
          >
            <>
              {tasksForTodayData?.data?.map((task) => {
                return <TaskItem key={task.id} data={task} />;
              })}
              <LoadMore hasNextPage={tasksForTodayData.hasNextPage} onFetch={tasksForTodayData.fetchMore} />
            </>
          </EntityBlock>
        </Can>
      </Block>
      {allDataReady ? <LoadingOverlay show={tasksLoading} text={common.t<string>('common.loadingData')} /> : null}
    </div>
  );
}

export default memo(DashboardTasks);
