import { gql } from '@apollo/client';

export const GET_COMPANY_ARTEFACT_ROLE_ASSIGNMENTS = gql`
  query CompanyArtefactRole($artefactIds: [Int]!) {
    company_artefact_role_assignments(artefact_ids: $artefactIds) {
      id
      pk
      user_roles {
        id
        name
        role
      }
      company_artefact_type {
        id
        name
        type
      }
    }
  }
`;
