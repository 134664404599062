import { gql } from '@apollo/client';

export const GET_TIMEZONES = gql`
  query GetTimezones {
    timezones {
      all {
        tz_name
        utc_offset
      }
    }
  }
`;
