import React from 'react';

import moment from 'moment';

import './styles.scss';

type GanttItemProps = {
  minDate?: string;
  item: {
    name: string;
    production_start_date: string;
    production_finish_date: string;
    production_progress: number;
  };
};

function GanttItem({ minDate, item }: GanttItemProps) {
  if (!minDate) {
    return null;
  }

  const step = 4;
  const startOfMonth = moment(minDate).startOf('month').format('YYYY-MM-DD');
  const start = moment(item.production_start_date);
  const end = moment(item.production_finish_date);

  const diff = start.diff(startOfMonth, 'days');
  const projectDays = end.diff(start, 'days');

  const styles = {
    left: step * diff || 4,
    width: projectDays * step || 4,
    background: `linear-gradient(90deg, #ECE9E5 ${item.production_progress}%, #ffffff ${
      item.production_progress
    }%, #ffffff ${100 - item.production_progress}%)`,
  };

  return (
    <div style={styles} className="gantt-item">
      <span className="gantt-item__text">{item.name}</span>
    </div>
  );
}

export default GanttItem;
