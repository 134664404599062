import React from 'react';

import { cx, formatDuration } from 'utils';

import './styles.scss';

type DurationProps = {
  className?: string;
  seconds: number;
  played: number;
};

function Duration({ className, seconds, played }: DurationProps) {
  const classNames = cx('video-duration', className);
  return (
    <div className={classNames}>
      <time dateTime={`P${Math.round(seconds)}S`} className="video-duration__time video-duration__elapsed">
        {formatDuration(seconds * played)}
      </time>
      <span className="video-duration__separator"> / </span>
      <time dateTime={`P${Math.round(seconds)}S`} className="video-duration__time video-duration__duration">
        {formatDuration(seconds)}
      </time>
    </div>
  );
}

export default Duration;
