import React, { useCallback, useMemo, useRef } from 'react';

import { FileTextOutlined } from '@ant-design/icons';
import { SeparateTaskArtefactVersions } from 'types/entities/separate';
import { useParams } from 'react-router-dom';
import useSeparateTaskAbility from 'pages/hooks/useSeparateTaskAbility';
import TruncateMarkup from 'react-truncate-markup';

import useHover from 'hooks/useHover';
import useBoolean from 'hooks/useBoolean';

import { CommonFile } from '../../task/types';
import useCommonContext from '../../../hooks/useCommonContext';

import ImageCardMask from './ImageCardMask';
import ImageCardFileName from './ImageCardFileName';

type ImageCardExProps = {
  item: SeparateTaskArtefactVersions;
  onRemove?: (artVer: string, fileId: string) => void;
};

function ImageCardEx({ item, onRemove }: ImageCardExProps) {
  const anchor = useRef<HTMLDivElement>(null);
  const [openConfirm, onOpen] = useBoolean(false);
  const common = useCommonContext();
  const { taskId } = useParams();
  const { canIEdit } = useSeparateTaskAbility(taskId);

  const hovered = useHover(anchor);
  const relatedFiles = item.file.related_files[0] as CommonFile;
  const extension = item.file.name?.split('.').pop();
  const isImg = extension === 'jpeg' || extension === 'jpg' || extension === 'png' || extension === 'gif';

  const onClickRemove = useCallback(() => {
    onRemove?.(item.id, item?.file.id);
    onOpen.off();
  }, [item?.file.id, item.id, onOpen, onRemove]);

  const text = useMemo(() => {
    return <span>{`${common.t<string>('pages.task.freeTask.textDeleteFile')} “${item.file.name}” ?`}</span>;
  }, [common, item.file.name]);

  return (
    <div ref={anchor} className="upload-wall-preview">
      {(hovered || openConfirm) && canIEdit ? (
        <ImageCardMask
          hasPopover
          openConfirm={openConfirm}
          downloadLink={relatedFiles.file}
          onOpenChange={onOpen.toggle}
          popoverContent={text}
          onRemove={onClickRemove}
        />
      ) : null}
      {isImg ? (
        <img src={relatedFiles.file} alt="image" />
      ) : (
        <>
          <FileTextOutlined style={{ fontSize: 32 }} />
          <ImageCardFileName name={item.file.name} />
        </>
      )}
    </div>
  );
}

export default ImageCardEx;
