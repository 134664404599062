import React, { useState } from 'react';

import { StateType, UserRoles } from 'types/entities';
import { BusinessRoleEnum } from 'types/entities/user';
import { CompanyStateType } from 'schemas/types';
import { RightOutlined } from '@ant-design/icons';
import { getUrlToTask } from 'utils';
import { CompanyArtefactType } from 'types';
import { onChangeProjectStatus } from 'api/requests/projects';

import Button from 'components/button/Button';
import Block from 'components/block';
import StatusLabelFlat from 'components/statusLabelFlat';
import { ButtonTypesEnum } from 'components/button/types';
import Tooltip from 'components/tooltip/Tooltip';

import { checkUserRole } from 'utils/roles';

import useCommonContext from 'hooks/useCommonContext';
import useNextTaskInfo from 'hooks/queries/useNextTaskInfo';
import useCompanyState from 'hooks/queries/useCompanyState';
import { usePublish } from 'hooks/useSubscribe';

import { ArtefactProductionSequence, ArtefactStateRequest, TaskStateRollbackRequest } from '../../types';

import './styles.scss';

type HeaderButtonsProps = {
  onRollbackTaskStatus?: (payload: TaskStateRollbackRequest) => void;
  refetch: () => Promise<any>;
  isHaveFile: boolean;
  isHaveText?: boolean;
  isCurrentExecutor?: boolean;
  artefactFlowSteps: any[] | undefined;
  projectTask: ArtefactProductionSequence | undefined;
  companyStates?: Array<CompanyStateType> | null;
  userRole: BusinessRoleEnum | null;
  userRoles: UserRoles[] | null;
  issuesList: any[] | undefined;
  callback?: any;
  onTaskHistoryRefetch?: () => Promise<any>;
  hintText: string;
};

function HeaderButtons({
  isHaveFile,
  projectTask,
  refetch,
  onRollbackTaskStatus,
  userRole,
  userRoles,
  issuesList,
  artefactFlowSteps,
  callback,
  onTaskHistoryRefetch,
  hintText,
  isHaveText,
}: HeaderButtonsProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const common = useCommonContext();
  const { taskInfo, taskInfoNextRefetch } = useNextTaskInfo();

  const publish = usePublish();

  const states = useCompanyState(
    common?.company?.id,
    'network-only',
    null,
    Number(common?.company?.default_project_state_state_list_version?.id)
  );

  const projectTaskId = projectTask?.project_task?.id;
  const isMethodist = checkUserRole(userRoles, BusinessRoleEnum.instructionalDesigner);
  const isHaveAnyNewIssues = issuesList?.map(
    (issue) =>
      issue?.project_task?.last_state?.state_type === StateType.NEW ||
      issue?.project_task?.last_state?.state_type === StateType.REOPEN
  );
  const allIssuesCompleted = issuesList?.map((issue) => issue?.project_task?.last_state?.state_type === StateType.DONE);

  const isAnyNewIssues = isHaveAnyNewIssues?.includes(true);
  const isAllIssuesCompleted = !allIssuesCompleted?.includes(false);
  const isCanRollback = projectTask?.project_task?.project_task_state_history?.is_rollback_possible;
  const isChangedById = projectTask?.project_task?.project_task_state_history?.changed_by?.id;
  const isChangedByCurrentUser = isChangedById === common?.user?.id;
  const nextTaskStepChangeStatusStepEntity = artefactFlowSteps?.find(
    (step: any) => Number(step?.state_initiation?.id) === Number(projectTask?.project_task?.last_state?.id)
  );

  const artefactFlowStepNextArr = nextTaskStepChangeStatusStepEntity?.artefactflowstepnext_set;

  artefactFlowStepNextArr?.sort(
    (a: any, b: any) =>
      parseInt(a?.state_next?.state_initiation?.order) - parseInt(b?.state_next?.state_initiation?.order)
  );
  const currentTaskStatusName = projectTask?.project_task?.last_state?.name;
  const currentTaskStatusType = projectTask?.project_task?.last_state?.state_type;

  const currentArtefactType = projectTask?.project_task?.artefact_list?.[0]?.artefact_type?.type;
  const onChangeProjectTaskStatus = async (status: number | undefined) => {
    if (projectTaskId && status) {
      const payload: ArtefactStateRequest = {
        projectTaskId: Number(projectTaskId),
        state_id: status,
      };
      setLoading(true);
      await callback?.();
      await onChangeProjectStatus(Number(projectTaskId), payload);
      await refetch();
      publish('update-sidebar');
      await onTaskHistoryRefetch?.();
    }
    await taskInfoNextRefetch().finally(() => setLoading(false));
  };

  const onClickNExtTask = async () => {
    const path = getUrlToTask(
      taskInfo?.learning_object?.id,
      taskInfo?.pk,
      taskInfo?.cached_linked_artefact?.cached_artefact_iteration_count?.toString(),
      taskInfo?.artefacts?.[0]?.id,
      taskInfo?.artefacts[0]?.artefact_type.type,
      taskInfo?.artefacts[0]?.artefact_versions?.[0]?.file?.file_type
    );
    common.navigate(path);
  };
  const onRollbackTaskStatusHandle = async () => {
    if (projectTaskId) {
      const payload: TaskStateRollbackRequest = {
        projectTaskId: Number(projectTaskId),
      };
      await onRollbackTaskStatus?.(payload);
      await refetch();
    }
  };
  const onClick = async () => {
    await onRollbackTaskStatusHandle();
    await refetch();
  };

  const isCurrentExecutorEntity = projectTask?.project_task?.projecttaskassignee_set?.find(
    (assigner) => assigner.is_current_executor === true && assigner.is_active
  );
  const isCurrentExecutor =
    Number(isCurrentExecutorEntity?.project_user?.company_user?.user?.id) === Number(common?.userId);
  return (
    <Block hidden={!projectTask || !artefactFlowSteps} className="task-header-buttons">
      <StatusLabelFlat type={currentTaskStatusType} name={currentTaskStatusName} />
      {isChangedByCurrentUser && isCanRollback ? (
        <Button size="large" className="mr_8" onClick={onClick}>
          {common?.t<string>('common.returnInWork')}
        </Button>
      ) : null}

      {(artefactFlowStepNextArr?.length && isCurrentExecutor) ||
      ((checkUserRole(userRoles, BusinessRoleEnum.manager) ||
        checkUserRole(userRoles, BusinessRoleEnum.executiveManager)) &&
        currentTaskStatusType === StateType.REVIEW)
        ? artefactFlowStepNextArr?.map((step: any, index: number) => {
            const nextStepName = step?.state_next?.state_initiation?.button_name;
            const nextStepType = step?.state_next?.state_initiation?.state_type;
            const nextTaskStepChangeStatusStepId = Number(step?.state_next?.state_initiation?.id);
            const isDoneStatus = step?.state_next?.state_initiation?.state_type === StateType.DONE;

            const getDisabledButtonState = () => {
              if (step?.state_next?.state_initiation?.is_could_close_without_complete_issues) {
                return false;
              }
              if (
                checkUserRole(userRoles, BusinessRoleEnum.manager) ||
                checkUserRole(userRoles, BusinessRoleEnum.executiveManager)
              ) {
                return false;
              }
              if (isMethodist && isAllIssuesCompleted && !isAnyNewIssues) {
                return false;
              }
              if (callback && isHaveFile) {
                return false;
              }
              if (
                (currentArtefactType === CompanyArtefactType.type.TEXT ||
                  currentArtefactType === CompanyArtefactType.type.GRADER ||
                  currentArtefactType === CompanyArtefactType.type.QUIZ ||
                  currentArtefactType === CompanyArtefactType.type.PEER_REVIEW ||
                  currentArtefactType === CompanyArtefactType.type.SCRIPT) &&
                isHaveText
              ) {
                return false;
              }
              if (!isHaveFile) {
                return true;
              }
              if (isDoneStatus && !isAllIssuesCompleted) {
                return true;
              }
              if (
                (currentArtefactType === CompanyArtefactType.type.TEXT ||
                  currentArtefactType === CompanyArtefactType.type.GRADER ||
                  currentArtefactType === CompanyArtefactType.type.QUIZ ||
                  currentArtefactType === CompanyArtefactType.type.PEER_REVIEW ||
                  currentArtefactType === CompanyArtefactType.type.SCRIPT) &&
                !isHaveText
              ) {
                return true;
              }
            };
            return (
              <div key={nextTaskStepChangeStatusStepId}>
                {getDisabledButtonState() && isCurrentExecutor ? (
                  <Tooltip
                    placement="bottomRight"
                    title={common.t<string>(hintText)}
                    trigger="hover"
                    key={nextTaskStepChangeStatusStepId}
                  >
                    <div>
                      <Button
                        loading={loading}
                        className={`mr_8 testclass-status-button-${nextStepType}`}
                        type={
                          artefactFlowStepNextArr?.length !== index + 1 && artefactFlowStepNextArr?.length > 1
                            ? ButtonTypesEnum.default
                            : ButtonTypesEnum.primary
                        }
                        disabled={getDisabledButtonState()}
                        key={nextTaskStepChangeStatusStepId}
                        size="large"
                        onClick={() =>
                          onChangeProjectTaskStatus(Number(nextTaskStepChangeStatusStepId))
                            .then(() => setLoading(false))
                            .catch(() => setLoading(false))
                        }
                      >
                        {nextStepName}
                      </Button>
                    </div>
                  </Tooltip>
                ) : (
                  <Button
                    loading={loading}
                    size="large"
                    key={nextTaskStepChangeStatusStepId}
                    className={`mr_8 testclass-status-button-${nextStepType}`}
                    type={
                      artefactFlowStepNextArr?.length !== index + 1 && artefactFlowStepNextArr?.length > 1
                        ? ButtonTypesEnum.default
                        : ButtonTypesEnum.primary
                    }
                    disabled={getDisabledButtonState()}
                    onClick={() =>
                      onChangeProjectTaskStatus(Number(nextTaskStepChangeStatusStepId))
                        .then(() => setLoading(false))
                        .catch(() => setLoading(false))
                    }
                  >
                    {nextStepName}
                  </Button>
                )}
              </div>
            );
          })
        : null}

      <Block
        hidden={
          isCurrentExecutor ||
          (isMethodist && (currentTaskStatusType === StateType.BLOCKED || currentTaskStatusType === StateType.NEW))
        }
      >
        <Button onClick={onClickNExtTask} type={ButtonTypesEnum.primary} size="large">
          <RightOutlined />
          {common.t<string>('common.toTheNextTask')}
        </Button>
      </Block>
    </Block>
  );
}

export default HeaderButtons;
