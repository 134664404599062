import React, { memo, useMemo } from 'react';

import { hideTableColumn } from 'utils';
import { BusinessRoleEnum, UserBase, UserExtended } from 'types/entities';

import Table from 'components/table';
import StatusLabelFlat from 'components/statusLabelFlat/StatusLabelFlat';
import Tag from 'components/tag';
import Avatar from 'components/avatar';

import { checkUserBusinessRole } from 'utils/roles';

import { IUserSmallInfo, IUserTableInfo } from 'store/users/interfaces';

import useCommonContext from 'hooks/useCommonContext';

import { IUserTableProps } from '../types';

import './styles.scss';

function UsersTable({ data, hasFirstLoad, loading, onSetUser }: IUserTableProps) {
  const common = useCommonContext();

  const columns = useMemo(
    () => [
      {
        title: common.t<string>('pages.users.table.org'),
        key: 'company',
        render: (record: UserExtended) => <div>{record.company?.name}</div>,
      },
      {
        title: common.t<string>('pages.users.table.name'),
        dataIndex: 'user',
        key: 'name',
        ellipsis: true,
        render: (user: UserBase) => {
          return (
            <>
              <Avatar src={user?.icon_image}>{user?.name?.[0]}</Avatar> {`${user.name}`}
            </>
          );
        },
      },
      {
        title: common.t<string>('pages.users.table.email'),
        dataIndex: 'user',
        key: 'email',
        ellipsis: true,
        render: (user: UserBase) => user?.email,
      },
      {
        title: common.t<string>('pages.users.table.business_roles'),
        dataIndex: 'business_roles',
        key: 'business_roles',
        render: (user: IUserSmallInfo, row: IUserTableInfo) => {
          const length = row?.business_roles?.length || 0;
          return (
            <div>
              <Tag className="users-table__role-name mr_8">{row?.business_roles?.[0]?.name}</Tag>
              {length > 1 ? <Tag className="users-table__role-name mr_8">{`+${length - 1}`}</Tag> : null}
            </div>
          );
        },
      },
      {
        title: common.t<string>('pages.users.table.status'),
        dataIndex: 'user',
        key: 'status',
        render: (user: UserBase) => (
          <StatusLabelFlat type={user.state} name={common.t(`statuses.${user.state?.toLocaleLowerCase()}`)} />
        ),
      },
    ],
    [common]
  );

  const columnsCondition = checkUserBusinessRole(common.businessRoles, BusinessRoleEnum.executiveManager)
    ? columns
    : hideTableColumn(columns, 'company');

  return (
    <Table
      hasFirstLoad={hasFirstLoad}
      className="users-table"
      columns={columnsCondition}
      data={data}
      loading={loading}
      pagination={false}
      onRow={(record) => {
        return {
          onClick: () => onSetUser(record),
        };
      }}
    />
  );
}

export default memo(UsersTable);
