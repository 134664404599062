import { gql } from '@apollo/client';

export const GET_PROJECT_GROUPS = gql`
  query GetProjectGroups {
    project_groups {
      id
      is_active
      name
      company {
        id
        name
        slug
        company_type
      }
    }
  }
`;
