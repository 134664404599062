import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { GET_COLLABORATORS } from 'queries/getCollaborators';
import { UserExtended } from 'types/entities';

import { CcmUserState, PageInfo } from '../../schemas/types';

export type Collaborators = {
  collaborators: {
    pageInfo: PageInfo;
    edges: {
      node: UserExtended;
    }[];
  };
};

type Filter = {
  company_ids: number[];
  role_ids: number[];
  search_text: string;
};

type CollaboratorsProps = {
  filter?: Filter | unknown;
  offset?: number;
  first?: number;
  skip?: boolean;
  onCompleted?: (data: Collaborators) => void;
  roleId?: string | null;
};

function useCollaborators({ filter, offset, first, skip, onCompleted, roleId }: CollaboratorsProps) {
  const { data, loading, fetchMore, refetch } = useQuery<Collaborators>(GET_COLLABORATORS, {
    variables: {
      filter,
      offset,
      first,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    onCompleted,
    skip,
    notifyOnNetworkStatusChange: true,
  });

  const usersByRoleId = useMemo(() => {
    const list = data?.collaborators.edges.map((el) => el.node);

    return (
      list
        ?.filter((user) => user.user.state !== CcmUserState.Blocked)
        .filter((user) => user.business_roles.some((r) => r.id === roleId)) || []
    );
  }, [data?.collaborators.edges, roleId]);

  return {
    data,
    usersByRoleId,
    loading,
    hasNextPage: data?.collaborators?.pageInfo?.hasNextPage,
    fetchMore,
    refetch,
  };
}

export default useCollaborators;
