import React, { memo } from 'react';

import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';

import Button from 'components/button/Button';
import { ButtonTypesEnum } from 'components/button/types';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

type InfoDrawerHeaderProps = {
  projectId: string;
  onClose: () => void;
};

function InfoDrawerHeader({ projectId, onClose }: InfoDrawerHeaderProps) {
  const common = useCommonContext();
  return (
    <div className="info-drawer-header">
      <div className="info-drawer-header__inner">
        <a href={`/project/${projectId}/content`}>
          <Button disabled={!projectId} type={ButtonTypesEnum.default}>
            <ArrowRightOutlined />
            {common.t<string>('pages.courses.drawer.goCourse')}
          </Button>
        </a>
        <Button
          onClick={onClose}
          type={ButtonTypesEnum.text}
          icon={<CloseOutlined />}
          className="close-button testclass-historyofchanges-field-close"
        />
      </div>
    </div>
  );
}

export default memo(InfoDrawerHeader);
