import React, { memo, useMemo } from 'react';

import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { Instruction } from 'hooks/queries/useArtefact';

import { StateType } from '../../../../types/entities';

import CheckListItem from './CheckListItem';
import './styles.scss';

type CheckListProps = {
  checkList?: Instruction;
  selected: string[];
  onChangeCheckbox: (event: CheckboxChangeEvent) => void;
  currentTaskStatus?: StateType;
};

function CheckList({ checkList, selected, onChangeCheckbox, currentTaskStatus }: CheckListProps) {
  const blocks = useMemo(() => {
    const parents = checkList?.related_files?.filter((b) => !b.parent_section);
    return parents?.map((f) => {
      const children = checkList?.related_files?.filter((c) => c?.parent_section?.id === f.id);
      return {
        ...f,
        children,
      };
    });
  }, [checkList?.related_files]);

  return (
    <div className="check-list">
      <div className="check-list__wrapper">
        {blocks?.map((block) => {
          const content = JSON.parse(block.section_content);
          const isChecked = !!selected.find((s) => s === block.id);
          const isIndeterminate = block?.children?.some((c) => selected.includes(c.id));
          return (
            <div key={block.id} className="check-list__block">
              <CheckListItem
                indeterminate={isIndeterminate && !isChecked}
                name={block.id}
                checked={currentTaskStatus === StateType.DONE ? true : isChecked}
                onChange={onChangeCheckbox}
                disabled={currentTaskStatus === StateType.DONE}
                isTitle
              >
                {content?.items?.[0]}
              </CheckListItem>
              {block?.children?.map((c) => {
                const contentChild = JSON.parse(c.section_content);
                const isCheckedChild = !!selected.find((s) => s === c.id);
                return (
                  <CheckListItem
                    name={c.id}
                    key={c.id}
                    checked={currentTaskStatus === StateType.DONE ? true : isCheckedChild}
                    disabled={currentTaskStatus === StateType.DONE}
                    onChange={onChangeCheckbox}
                  >
                    {contentChild?.items?.[0]}
                  </CheckListItem>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default memo(CheckList);
