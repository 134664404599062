import React from 'react';

import Popover from '../../../components/popover/Popover';
import Button from '../../../components/button/Button';

const PopoverStories = () => {
  return (
    <div className="mb_8 flex-row flex-align-items--center flex-justify-content--center">
      <Popover content="content" title="Title" trigger="click">
        <Button onClick={() => {}} className="mr_8">
          Click
        </Button>
      </Popover>
      <Popover content="content" title="Title" trigger="hover">
        <Button onClick={() => {}} className="mr_8">
          Hover
        </Button>
      </Popover>
    </div>
  );
};

export default PopoverStories;
