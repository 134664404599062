import React from 'react';

import DatePicker from '../../../components/datePicker';
import TimePicker from '../../../components/timePicker';

const DatePickerStories = () => {
  return (
    <div className="flex-row">
      <div style={{ width: '250px', marginRight: '20px' }}>
        <DatePicker className="mb_16" noForm size="xl" />
        <DatePicker
          showTime
          format="YYYY-MM-DD HH:mm:ss"
          className="mb_16"
          noForm
          size="xl"
          placeholder="YYYY-MM-DD HH:mm:ss"
        />
        <DatePicker className="mb_16" noForm size="large" />
        <DatePicker className="mb_16" noForm size="middle" />
        <DatePicker disabled className="mb_16" noForm size="middle" />
      </div>
      <div style={{ width: '250px', marginRight: '20px' }}>
        <DatePicker status="warning" className="mb_16" noForm size="large" />
        <DatePicker status="error" className="mb_16" noForm size="large" />
      </div>
      <div style={{ width: '150px', marginRight: '20px' }}>
        <TimePicker className="mb_16" noForm size="xl" />
        <TimePicker className="mb_16" noForm size="large" />
        <TimePicker className="mb_16" noForm size="middle" />
        <TimePicker disabled className="mb_16" noForm size="middle" />
      </div>
      <div style={{ width: '150px', marginRight: '20px' }}>
        <TimePicker status="warning" className="mb_16" noForm size="xl" />
        <TimePicker status="error" className="mb_16" noForm size="xl" />
      </div>
    </div>
  );
};

export default DatePickerStories;
