import React from 'react';

import { Collapse as ACollapse, Avatar as AvatarAnt } from 'antd';
import { CaretRightOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { declOfNum, declTitleDay } from 'utils';
import { BusinessRoleEnum, CompanyUserItem } from 'types/entities';

import Collapse from 'components/collapse';
import TagDelayWithFlag from 'components/tagDelayWithFlag/TagDelayWithFlag';
import PlanFactTag from 'components/tableTagDelay/TableTagDelay';
import Block from 'components/block';
import Avatar from 'components/avatar';

import { getHealthIcon } from 'utils/icons';

import { ProjectData, ProjectUserType } from 'hooks';

import useCommonContext from 'hooks/useCommonContext';
import useArtefactDeadlinesChanged from 'hooks/queries/useArtefactDeadlinesChanged';
import useProjectMetrics from 'hooks/queries/useProjectMetrics';
import useCompanyRoles from 'hooks/queries/useCompanyRoles';
import useProjectUsersWithTasksAmount from 'hooks/queries/useProjectUsersWithTasksAmount';

import WorkProgress from '../workProgress';
import ContentProgress from '../contentProgress';
import ContentState from '../contentState';
import ProductionDates from '../productionDates';
import CollapseHeader from '../collapseHeader';
import Deadlines from '../deadlines';
import UserList from '../userList';
import Problems from '../problems';

import './styles.scss';

const { Panel } = ACollapse;

type InfoDrawerContentProps = {
  data?: ProjectData;
  projectUsers?: ProjectUserType[];
};

function InfoDrawerContent({ data, projectUsers }: InfoDrawerContentProps) {
  const common = useCommonContext();
  const { timelineData } = useArtefactDeadlinesChanged(Number(data?.id));
  const {
    currentMetricsDeadlineProduction,
    currentMetricsDeadlineFirstIteration,
    currentMetricsTaskFourIteration,
    metricsDeadlineFirstIteration,
    metricsDeadlineProduction,
    metricsTaskFourIteration,
  } = useProjectMetrics(Number(data?.id));
  const { projectUsersWithTasksAmount } = useProjectUsersWithTasksAmount(Number(data?.id));

  const { companyRoles } = useCompanyRoles(data?.company?.id);

  const filteredUsers = projectUsers?.filter(
    (u) => !u?.roles?.some((r) => r?.role?.role === BusinessRoleEnum.executiveManager)
  );

  return (
    <div className="info-drawer-content">
      <h3 className="info-drawer-title">{data?.name}</h3>
      <WorkProgress
        production_progress={data?.production_progress || 0}
        totalTasks={data?.metrics_total_tasks || 0}
        totalCompletedTasks={data?.metrics_total_completed_tasks || 0}
        authorTotalTasks={data?.metrics_author_total_tasks || 0}
        authorTotalCompletedTasks={data?.metrics_author_total_completed_tasks || 0}
        productionExecutorTotalTasks={data?.metrics_production_executor_total_tasks || 0}
        productionExecutorTotalCompletedTasks={data?.metrics_production_executor_total_completed_tasks || 0}
      />

      <ContentProgress completed={data?.metrics_completed_elements || 0} total={data?.metrics_total_elements || 0} />
      <ContentState phaseState={data?.phase_state} projectState={data?.state} />
      <ProductionDates
        start={data?.production_start_date}
        end={data?.production_finish_date}
        projectId={data?.id}
        hasChanges={data?.metrics_project_changed_deadlines_exist}
      />

      <Collapse
        ghost
        expandIcon={({ isActive }) => <CaretRightOutlined style={{ fontSize: 14 }} rotate={isActive ? 90 : 0} />}
      >
        <Panel
          key="1"
          header={
            <CollapseHeader title={common.t<string>('pages.courses.drawer.currentProblems')}>
              <TagDelayWithFlag
                delay={data?.metrics_maximium_artefact_delay || 0}
                text={(data?.metrics_missing_deadlines || 0).toString()}
                noFlag
              />
              <Block className="ml_12" hidden={(data?.metrics_maximium_artefact_delay || 0) <= 0}>
                <PlanFactTag
                  plan={data?.metrics_completed_tasks_plan || 0}
                  fact={data?.metrics_completed_tasks_fact || 0}
                  delay={data?.metrics_maximium_artefact_delay || 0}
                  text={`${data?.metrics_maximium_artefact_delay || 0} ${t<string>(
                    declOfNum(data?.metrics_maximium_artefact_delay || 0, declTitleDay)
                  )}`}
                  flat
                />
              </Block>
            </CollapseHeader>
          }
        >
          <Problems
            isCurrent
            empty={!data?.metrics_maximium_artefact_delay || data?.metrics_maximium_artefact_delay === 0}
            emptyTextKey="pages.courses.drawer.emptyCurrentProblems"
            projectId={data?.id}
            users={projectUsers}
            metricsDeadlineProduction={currentMetricsDeadlineProduction}
            metricsDeadlineFirstIteration={currentMetricsDeadlineFirstIteration}
            metricsTaskFourIteration={currentMetricsTaskFourIteration}
          />
        </Panel>
        <Panel
          key="2"
          header={
            <CollapseHeader title={common.t<string>('pages.courses.drawer.problemsAllTime')}>
              {getHealthIcon(data?.metrics_maximium_artefact_delay || 0, 20)}
            </CollapseHeader>
          }
        >
          <Problems
            isCurrent={false}
            projectId={data?.id}
            users={projectUsers}
            metricsDeadlineProduction={metricsDeadlineProduction}
            metricsDeadlineFirstIteration={metricsDeadlineFirstIteration}
            metricsTaskFourIteration={metricsTaskFourIteration}
          />
        </Panel>
        <Panel
          key="3"
          header={
            <CollapseHeader title={common.t<string>('pages.courses.drawer.reschedules')}>
              <ClockCircleOutlined style={{ fontSize: 20 }} />
            </CollapseHeader>
          }
        >
          <Deadlines timelineData={timelineData} />
        </Panel>
        <Panel
          key="4"
          header={
            <CollapseHeader title={common.t<string>('pages.courses.drawer.participants')}>
              <AvatarAnt.Group className="" maxCount={5}>
                {filteredUsers?.map((user) => {
                  return (
                    <Avatar key={user.company_user.user.id} src={user.company_user.user.icon_image}>
                      {user?.company_user?.user?.name?.[0]}
                    </Avatar>
                  );
                })}
              </AvatarAnt.Group>
            </CollapseHeader>
          }
        >
          <UserList
            projectId={data?.id}
            users={filteredUsers}
            companyRoles={companyRoles}
            projectUsersWithTasksAmount={projectUsersWithTasksAmount}
          />
        </Panel>
      </Collapse>
    </div>
  );
}

export default InfoDrawerContent;
