import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { GET_PARTICIPANTS_GANTT } from 'queries/getParticipantsGantt';

import { getMonthRange } from '../../../utils';

type GanttDataProps = {
  companyId?: string;
  roleId?: string | null;
};

export type GanttProjectType = {
  name: string;
  production_finish_date: string;
  production_start_date: string;
  production_progress: number;
};

export type GanttUserType = {
  id: string;
  last_name: string;
  first_name: string;
  projects: GanttProjectType[];
};

type GanttData = {
  gantt: {
    min_date: string;
    max_date: string;
    users: GanttUserType[];
  };
};

function useParticipantsGanttData({ companyId, roleId }: GanttDataProps) {
  const { data } = useQuery<GanttData>(GET_PARTICIPANTS_GANTT, {
    variables: {
      companyId,
      roleId,
    },
    skip: !companyId || !roleId,
  });

  const monthRange = getMonthRange(data?.gantt.min_date, data?.gantt.max_date);

  return useMemo(() => {
    return {
      monthRange,
      users: data?.gantt.users,
    };
  }, [data?.gantt.users, monthRange]);
}

export default useParticipantsGanttData;
