import React, { memo } from 'react';

import { Timeline as ATimeline, TimelineProps } from 'antd';
import { cx } from 'utils';
import moment from 'moment';

import useCommonContext from 'hooks/useCommonContext';

import { AllProjectTaskStateHistory, ProjectStateItem } from '../../hooks';

interface ITimeline extends TimelineProps {
  data: AllProjectTaskStateHistory[];
  companyStates?: ProjectStateItem[] | null;
}

function Timeline({ className, data, companyStates }: ITimeline) {
  const common = useCommonContext();
  if (!data || !data.length) {
    return null;
  }
  const classNames = cx('ccm-timeline', className);

  //TODO ROLES
  return (
    <ATimeline className={classNames}>
      {data.map((item) => {
        const eventType = common?.t(`eventTypes.post`);

        const userName = item.changed_by.name
          ? item.changed_by.name
          : `${item?.changed_by?.first_name} ${item?.changed_by?.last_name}`;

        // const companyState = companyStates?.find(
        //   (companyStateItem) => Number(companyStateItem?.id) === item?.entity_id
        // )?.name;

        const companyState = item.state.name;

        // const userRoleName = item?.project?.projectuser_set?.find(
        //   (user) => Number(user?.company_user?.user?.id) === Number(item?.created_by?.id)
        // )?.roles[0]?.role.name;

        const userRoleName = item.changed_by_role.name;

        return (
          <ATimeline.Item className="ccm-timeline__item" key={item.id} color={'blue'}>
            {userName && (
              <span className="ccm-timeline__title">{`${userRoleName && userRoleName} ${
                userName && userName
              } ${eventType} ${common.t<string>('common.to')}  ${common.t<string>(
                'common.status'
              )} "${companyState}"`}</span>
            )}
            {item?.created_at && (
              <span className="ccm-timeline__date">{moment(item?.created_at).format('Do MMMM HH:mm')}</span>
            )}
          </ATimeline.Item>
        );
      })}
    </ATimeline>
  );
}

export default memo(Timeline);
