import React, { useCallback } from 'react';

import SelectUserDrawer from 'pages/components/selectUserDrawer';
import Participants from 'pages/components/participants';
import { useParams } from 'react-router-dom';

import useBoolean from 'hooks/useBoolean';
import useProject from 'hooks/queries/useProject';
import useCompanyRoles from 'hooks/queries/useCompanyRoles';

import useParticipantsControl from '../../hooks/useParticipantsControl';

import './styles.scss';

function CourseParticipantsPage() {
  const [open, onOpen] = useBoolean(false);

  const { id } = useParams();
  const { projectData } = useProject(Number(id));

  const { loading, roleId, users, separateProjectTaskAssignee, userAddForm, setRoleId, onSave } =
    useParticipantsControl({
      onCloseDrawer: onOpen.off,
      companyId: projectData?.company?.id,
      visible: open,
    });

  const openDrawer = useCallback(
    (roleID: string) => {
      setRoleId(roleID);
      onOpen.on();
    },
    [onOpen, setRoleId]
  );

  const closeDrawer = useCallback(() => {
    setRoleId(null);
    onOpen.off();
  }, [onOpen, setRoleId]);
  const { companyRoles } = useCompanyRoles(projectData?.company?.id);

  return (
    <div className="participants-page-about">
      <Participants
        companyRoles={companyRoles}
        users={users}
        separateProjectTaskAssignee={separateProjectTaskAssignee}
        onOpenDrawer={openDrawer}
      />
      <SelectUserDrawer
        loading={loading}
        companyId={projectData?.company?.id}
        users={users}
        userAddForm={userAddForm}
        roleId={roleId}
        visible={open}
        onClose={closeDrawer}
        onSave={onSave}
      />
    </div>
  );
}

export default CourseParticipantsPage;
