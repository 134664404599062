import { gql } from '@apollo/client';

export const GET_COMPANY_USERS = gql`
  query CompanyUsers($company_id: Int) {
    company_users(company_id: $company_id) {
      pk
      id
      name
      company_type
      userrole_set {
        id
        name
        role
      }
      companyuser_set {
        id
        user {
          id
          email
          name
          first_name
          last_name
          state
        }
        technical_role
        business_roles {
          id
          name
          role
        }
      }
      active_users {
        id
        pk
        company {
          id
          slug
          name
          company_type
        }
        user {
          id
          email
          name
          first_name
          last_name
          state
          icon_image
        }
        technical_role
        business_roles {
          id
          name
          role
        }
      }
    }
  }
`;
