import React, { memo, SyntheticEvent } from 'react';

import { LoadingOutlined } from '@ant-design/icons';

import { TimelineProjectDeadlinesChanged } from 'components/timeline';

import { ProjectDeadlinesChangedItem } from 'hooks';

import '../styles.scss';
import useCompanyUsers from '../../../hooks/queries/useCompanyUsers';
import useCommonContext from '../../../hooks/useCommonContext';

type HistoryPopupContentProps = {
  data?: ProjectDeadlinesChangedItem[];
  loading: boolean;
};

function ProjectDeadlinesChangedPopupContent({ data, loading }: HistoryPopupContentProps) {
  const common = useCommonContext();
  const onHandelClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };
  const { companyUsersData, companyUsersLoading } = useCompanyUsers(Number(common?.company?.id));
  return (
    <div className="ccm-popover-history__wrapper" onClick={onHandelClick}>
      {loading || companyUsersLoading ? (
        <LoadingOutlined />
      ) : (
        <TimelineProjectDeadlinesChanged data={data} companyUsersData={companyUsersData} />
      )}
    </div>
  );
}

export default memo(ProjectDeadlinesChangedPopupContent);
