/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CompanyLearningStructure = {
    readonly id?: number;
    readonly created_at?: string;
    readonly updated_at?: string;
    name: string;
    level_type?: CompanyLearningStructure.level_type;
    company_id: number;
};

export namespace CompanyLearningStructure {

    export enum level_type {
        PROJECT_ROOT = 'project_root',
        GROUP = 'group',
        PRODUCTION_ITEM = 'production_item',
    }


}
