import React, { memo } from 'react';

import { Skeleton } from 'antd';

const styles = {
  t1: { width: '23.61%' },
  t2: { width: '23.61%' },
  tab: { width: '14.53%' },
};

function SkeletonHeader() {
  return (
    <div className="skeleton-project-header">
      <div className="skeleton-project-header__title">
        <Skeleton paragraph={false} avatar={false} active style={styles.t1} />
        <Skeleton paragraph={false} avatar={false} active style={styles.t2} />
      </div>
      <div className="skeleton-project-header__row">
        <Skeleton paragraph={false} avatar={false} active style={styles.tab} />
        <Skeleton paragraph={false} avatar={false} active style={styles.tab} />
        <Skeleton paragraph={false} avatar={false} active style={styles.tab} />
        <Skeleton paragraph={false} avatar={false} active style={styles.tab} />
      </div>
    </div>
  );
}

export default memo(SkeletonHeader);
