import React, { memo } from 'react';

import icon from 'assets/image/empty-img-simple.svg';

import useCommonContext from '../../../../hooks/useCommonContext';

import styles from './s.module.scss';

type EmptyDataProps = {
  title?: string;
};

function EmptyData({ title }: EmptyDataProps) {
  const common = useCommonContext();
  return (
    <div className={styles.container}>
      <div className={styles.wrap}>
        <img className={styles.image} src={icon} alt="icon" />
        <span className={styles.text}>{common.t<string>('common.nothingFound')}</span>
      </div>
    </div>
  );
}

export default memo(EmptyData);
