import React from 'react';

import { Timeline as ATimeline } from 'antd';
import { cx } from 'utils';
import moment from 'moment/moment';

import { AllProjectTaskStateHistory, ProjectUserType } from 'hooks';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

type TimelineFinalArtefactPipelineProps = {
  className?: string;
  data?: AllProjectTaskStateHistory[];
  projectUsers?: ProjectUserType[];
  isSeparate: boolean;
};

function TimelineFinalArtefactPipeline({ className, data, isSeparate }: TimelineFinalArtefactPipelineProps) {
  const common = useCommonContext();
  const classNames = cx('ccm-timeline', className);

  return (
    <ATimeline className={classNames}>
      {data?.map((item) => {
        const eventType = common?.t(`eventTypes.put`);
        const roleName = isSeparate ? '' : item?.changed_by_role?.name || '';
        const userName = item?.changed_by?.name || '';

        return (
          <ATimeline.Item className="ccm-timeline__item" key={item.id} color={'blue'}>
            <span className="ccm-timeline__title">{`${roleName} ${userName} ${eventType} ${common.t<string>(
              'common.to'
            )}  ${common.t<string>('common.status')} "${item.state.name}"`}</span>
            {item?.created_at && (
              <span className="ccm-timeline__date">{moment(item?.created_at).format('Do MMMM HH:mm')}</span>
            )}
          </ATimeline.Item>
        );
      })}
    </ATimeline>
  );
}

export default TimelineFinalArtefactPipeline;
