import React, { memo } from 'react';

import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { cx } from 'utils';

import Badge from 'components/badge';
import { CCMBadgeVariant } from 'components/badge/types';

import './styles.scss';

type BlockHeaderProps = {
  className?: string;
  title: string;
  path: string;
  count?: number;
};

function BlockHeader({ className, title, path, count }: BlockHeaderProps) {
  const classNames = cx('dashboard-block-header dashboard-block-header__link', className);
  return (
    <Link className={classNames} to={path}>
      <div className="dashboard-block-header__wrapper">
        <span className="dashboard-block-header__title">{title}</span>
        <Badge variant={CCMBadgeVariant.light} count={count || 0} />
        <ArrowRightOutlined />
      </div>
    </Link>
  );
}

export default memo(BlockHeader);
