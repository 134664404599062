import { CompanyInfo } from '../../hooks';

export interface Project {
  company: CompanyInfo;
  id: number;
  name: string;
  project_state_list_name: string;
  project_task_state_list_version_id: number;
  project_type: string;
}
export enum ProjectType {
  content_creation = 'content_creation',
  translation = 'translation',
}
export enum ArtefactTypeName {
  video = 'video',
  presentation = 'presentation',
  text = 'text',
  grader = 'grader',
  url = 'url',
}
export type ProjectShort = {
  id: number;
  name: string;
  project_type: ProjectType;
};

export type ArtefactType = {
  name: string;
  type: ArtefactTypeName;
  icon_image: string;
};
export type TaskState = {
  name: string;
  state_type: string;
  is_new_iteration_initiation: boolean;
  assignee_user_role_id: number;
};
export type LastArtefactIteration = {
  id: number;
  deadline: string;
};
export type AssignerUser = {
  id: number;
  first_name: string;
  last_name: string;
};
export type AssignerRole = {
  id?: number;
  name: string;
  role_type: string;
};
export type AssignersCompany = {
  id: number;
  slug: string;
  name: string;
  type: string;
};
export type Assigner = {
  id: number;
  user: AssignerUser;
  role: AssignerRole;
  is_external_company: boolean;
  company: AssignersCompany;
};
export type TaskArtefactType = {
  id: number;
  last_state: TaskState;
  task_type: string;
  company_state_list_version_id: number;
  assignees: Assigner[];
};
export type CurrentStepSequenceArtefact = {
  id: number;
  artefact_type: ArtefactType;
  production_deadline: string;
  task: TaskArtefactType;
  last_artefact_iteration: LastArtefactIteration;
  artefact_iteration_count: number;
};

export type FinalArtefact = {
  id: number;
  task_id: number;
  artefact_type: ArtefactType;
  production_deadline: string;
  current_step_sequence_artefact_list: CurrentStepSequenceArtefact[];
};
export type ArtefactFull = {
  id: number;
  name: string;
  project: ProjectShort;
  final_artefact: FinalArtefact[];
};
export interface FullProjectInfo extends Project {
  id: number;
  name: string;
  project: ProjectShort;
  artefacts: ArtefactFull[];
}
export interface IProjectsResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Project[];
}
export interface IArtefactResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: ArtefactFull[];
}
export interface IProjectsState {
  count: number;
  next: string | null;
  previous: string | null;
  projects: FullProjectInfo[] | null;
  companyStates: any | null;
}
export default IProjectsState;
