import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import { RadioChangeEvent } from 'antd/lib/radio/interface';

const usePageFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const missed = searchParams.get('missed');
  const statuses = searchParams.getAll('statuses');
  const types = searchParams.getAll('types');
  const assignee = searchParams.get('assignee');
  const assignees = searchParams.getAll('assignees');
  const text = searchParams.get('text');
  const projectName = searchParams.get('project');
  const taskFormToday = searchParams.get('taskFormToday');
  const elements = searchParams.getAll('elements');
  const delays = searchParams.getAll('delays');
  const companies = searchParams.getAll('companies');
  const roles = searchParams.getAll('roles');

  const [textSearch, setTextSearch] = useState<string>(text || '');

  const onClear = useCallback(
    (name: string, value?: string) => {
      if (value) {
        searchParams.set(name, value);
      } else {
        searchParams.delete(name);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const onChangeMissed = useCallback(
    (checked: boolean) => {
      searchParams.set('missed', checked ? 'true' : 'false');
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const onChangeStatuses = useCallback(
    (values: string[]) => {
      searchParams.delete('statuses');
      for (const v of values) {
        searchParams.append('statuses', v);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const onChangeTypes = useCallback(
    (values: string[]) => {
      searchParams.delete('types');
      for (const v of values) {
        searchParams.append('types', v);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const onChangeAssignee = useCallback(
    (value: string) => {
      onClear('assignee', value);
    },
    [onClear]
  );

  const onChangeAssignees = useCallback(
    (values: string[]) => {
      searchParams.delete('assignees');
      for (const v of values) {
        searchParams.append('assignees', v);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const onChangeProjectName = useCallback(
    (value: string) => {
      onClear('project', value);
    },
    [onClear]
  );

  const onChangeElements = useCallback(
    (values: string[]) => {
      searchParams.delete('elements');
      for (const v of values) {
        searchParams.append('elements', v);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const onChangeDelays = useCallback(
    (values: string[]) => {
      searchParams.delete('delays');
      for (const v of values) {
        searchParams.append('delays', v);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const onChangeCompanies = useCallback(
    (values: string[]) => {
      searchParams.delete('companies');
      for (const v of values) {
        searchParams.append('companies', v);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const onChangeRoles = useCallback(
    (values: string[]) => {
      searchParams.delete('roles');
      for (const v of values) {
        searchParams.append('roles', v);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const onChangeTaskDay = useCallback(
    (e: RadioChangeEvent) => {
      searchParams.set('taskFormToday', e.target.value);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const onSearch = useCallback(
    (value: string) => {
      if (value) {
        searchParams.set('text', value);
      } else {
        searchParams.delete('text');
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const onChangeText = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTextSearch(e.target.value);
  }, []);

  return {
    projectName,
    missed: missed === 'true' ? true : null,
    elements,
    statuses,
    types,
    assignee,
    assignees,
    taskFormToday,
    delays,
    companies,
    roles,
    text,
    textSearch,
    onChangeMissed,
    onChangeStatuses,
    onChangeTypes,
    onChangeAssignee,
    onChangeAssignees,
    onSearch,
    onChangeText,
    onChangeProjectName,
    onChangeElements,
    onChangeTaskDay,
    onChangeDelays,
    onChangeCompanies,
    onChangeRoles,
  };
};

export default usePageFilters;
