import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { PlusOutlined, PullRequestOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { Edge, Node } from 'reactflow';
import { onPublishPipelineVer, onUpdatePipeline } from 'api/requests/companyState';
import modalService from 'services/modalService';
import { message } from 'antd';

import Button from 'components/button';
import { ButtonTypesEnum } from 'components/button/types';
import LoadingOverlay from 'components/loadingOverlay';

import { ArtefactPipelineItem, pipelineQuery } from 'hooks/queries/getArtefactPipeline';
import useBoolean from 'hooks/useBoolean';
import useCompanyArtefactTypes from 'hooks/queries/useCompanyArtefactTypes';

import PipelineFlow from '../../components/pipelineFlow';
import { convertToEdge, convertToNode, getPayload, getPayloadAddArtefact } from '../../components/pipelineFlow/untils';
import { NodeData } from '../../components/pipelineFlow/customNode/CustomNode';

import './styles.scss';

const PipelineEdit = () => {
  const [pipeline, setPipeline] = useState<ArtefactPipelineItem | null>(null);
  const { companyArtefactTypes } = useCompanyArtefactTypes();
  const [loading, onLoading] = useBoolean(false);
  const { itemId } = useParams();

  console.log(pipeline);

  const getPipeline = useCallback(async () => {
    if (itemId) {
      onLoading.on();
      await pipelineQuery(itemId)
        .then((response) => {
          setPipeline(response.data.artefact_pipeline);
          onLoading.off();
        })
        .catch(() => onLoading.off());
    }
  }, [itemId, onLoading]);

  useEffect(() => {
    if (itemId) {
      void getPipeline();
    }
  }, [getPipeline, itemId]);

  const nodes = useMemo(() => {
    return convertToNode(pipeline?.last_version.steps?.[0].sequences, getPipeline) || [];
  }, [pipeline?.last_version.steps]);

  const edges = useMemo(() => {
    return convertToEdge(pipeline?.last_version.steps?.[0].sequences) || [];
  }, [pipeline?.last_version.steps]);

  const onSaveDraft = async (n: Node<NodeData>[], e: Edge[]) => {
    if (pipeline) {
      onLoading.on();
      const isFinalId = pipeline.actual_version.steps[0].sequences.find((el) => el.is_final)!;
      const payload = getPayload(pipeline, n, e);
      await onUpdatePipeline(isFinalId.artefact_type.id, payload)
        .then(() => {
          getPipeline();
        })
        .catch(() => onLoading.off());
    }
  };

  const onSubmitAddArtefact = async (artId: string) => {
    if (pipeline) {
      onLoading.on();
      const isFinalId = pipeline.actual_version.steps[0].sequences.find((el) => el.is_final)!;
      const payload = getPayloadAddArtefact(artId, pipeline, nodes, edges);
      await onUpdatePipeline(isFinalId.artefact_type.id, payload)
        .then(() => {
          getPipeline();
        })
        .catch(() => onLoading.off());
    }
  };

  const onAddArtefact = () => {
    modalService.openAddArtefactType({
      onSubmit: onSubmitAddArtefact,
      companyArtefactTypes: companyArtefactTypes.filter(
        (art) => !pipeline?.actual_version.steps[0].sequences.some((p) => p.artefact_type.id === art.id)
      ),
    });
  };

  const onPublishVersion = async () => {
    if (pipeline?.last_version.id) {
      await onPublishPipelineVer(pipeline?.last_version.id).then(() => {
        message.info('Version published');
      });
      await getPipeline();
    }
  };

  return (
    <div className="ms-pipeline-edit">
      <div className="ms-pipeline-edit__top">
        <Button type={ButtonTypesEnum.primary} size="xl" icon={<PlusOutlined />} onClick={onAddArtefact}>
          Add Artefact
        </Button>
        <Button
          className="ml_auto"
          type={ButtonTypesEnum.primary}
          size="xl"
          disabled={pipeline?.actual_version.id === pipeline?.last_version.id}
          icon={<PullRequestOutlined />}
          onClick={onPublishVersion}
        >
          Publish a draft
        </Button>
      </div>
      {!isEmpty(nodes) ? <PipelineFlow dataEdges={edges} dataNodes={nodes} onSaveDraft={onSaveDraft} /> : null}
      <LoadingOverlay show={loading} />
    </div>
  );
};

export default PipelineEdit;
