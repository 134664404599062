import React, { memo } from 'react';

import { CaretDownOutlined, CaretLeftOutlined } from '@ant-design/icons';

import Button from 'components/button/Button';
import { ButtonTypesEnum } from 'components/button/types';

import useCommonContext from 'hooks/useCommonContext';

type GlobalTasksCollapseHeaderProps = {
  open: boolean;
  onToggle: () => void;
  progress?: number;
};

function SeparateTasksCollapseHeader({ open, onToggle, progress = 0 }: GlobalTasksCollapseHeaderProps) {
  const common = useCommonContext();
  const onClickToggle = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    onToggle();
  };
  return (
    <div role="button" onClick={onToggle} className="global-tasks-collapse-header">
      <span className="global-tasks-collapse-header__title">{common.t<string>('pages.project.globalTasks')}</span>
      <div className="global-tasks-collapse-header__extra">
        <div className="global-tasks-collapse-header__progress">
          <span className="global-tasks-collapse-header__progress-value">{Math.ceil(progress)}</span>
          <span className="global-tasks-collapse-header__progress-text">%</span>
        </div>
        <Button
          size="large"
          type={ButtonTypesEnum.text}
          icon={
            open ? (
              <CaretDownOutlined style={{ color: '#848484' }} />
            ) : (
              <CaretLeftOutlined style={{ color: '#848484' }} />
            )
          }
          onClick={onClickToggle}
        />
      </div>
    </div>
  );
}

export default memo(SeparateTasksCollapseHeader);
