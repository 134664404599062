import React, { useMemo, useRef, useState } from 'react';

import { Carousel as ACarousel } from 'antd';
import { cx } from 'utils';
import { connect, ConnectedProps } from 'react-redux';
import { IStore } from 'types/store';

import { ICarouselProps } from './types';

function Carousel({
  initWidth = 0,
  className,
  children,
  cbRef,
  leftPanel,
  rightPanel,
  ...props
}: ICarouselProps & PropsFromRedux) {
  const refContainer = useRef<HTMLDivElement>(null);
  const classNames = cx('ccm-carousel', className);

  const settings = {
    variableWidth: false,
    adaptiveHeight: true,
    infinite: false,
  };

  const addedWidth = useMemo(() => {
    let temp = 0;
    if (leftPanel) temp = temp + 360;
    if (rightPanel) temp = temp + 496;
    return temp;
  }, [leftPanel, rightPanel]);

  const style = {
    width: initWidth + addedWidth,
  };

  return (
    <div ref={refContainer} className={classNames} style={style}>
      <ACarousel ref={cbRef} {...props} {...settings}>
        {children}
      </ACarousel>
    </div>
  );
}

const connector = connect(
  (state: IStore) => ({
    leftPanel: state.app.leftPanel,
    rightPanel: state.app.rightPanel,
  }),
  {}
);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Carousel);
