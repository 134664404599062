import React, { memo } from 'react';

import { Skeleton } from 'antd';

import './styles.scss';

const styles = {
  block: { width: '100%', height: '24px', maxWidth: '94px' },
};

function SkeletonSeparateTask() {
  return (
    <div className="separate-task-skeleton">
      <div className="separate-task-skeleton__header">
        <Skeleton paragraph={false} avatar={false} active style={styles.block} />
        <Skeleton paragraph={false} avatar={false} active style={styles.block} />
      </div>
      <div className="separate-task-skeleton__content">
        <Skeleton />
        <Skeleton />
        <div className="separate-task-skeleton__images">
          <Skeleton.Image active />
          <Skeleton.Image active />
          <Skeleton.Image active />
          <Skeleton.Image active />
        </div>
      </div>
    </div>
  );
}

export default memo(SkeletonSeparateTask);
