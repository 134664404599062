import React, { memo, ReactNode, SyntheticEvent } from 'react';

import { cx } from 'utils';
import { CopyFilled } from '@ant-design/icons';

import Tooltip from '../tooltip';
import Button from '../button';
import { ButtonTypesEnum } from '../button/types';
import useCommonContext from '../../hooks/useCommonContext';

import './styles.scss';

type ContentBlockProps = {
  className?: string;
  label?: string;
  text?: string | null;
  content?: ReactNode;
  hint?: ReactNode;
  small?: boolean;
  hasCopy?: boolean;
};

function ContentBlock({ className, label, text, content, hint, small, hasCopy }: ContentBlockProps) {
  const common = useCommonContext();
  const classNames = cx('content-block', className, {
    'content-block--small': small,
  });

  const onClipboard = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    navigator.clipboard.writeText(text || '');
  };

  return (
    <div className={classNames}>
      <div className="content-block__label-wrap">
        <span className="content-block__label">{label}</span>
        {hint ? hint : null}
      </div>
      <div className="content-block__text-wrap">
        <span className="content-block__text">{text}</span>
        {hasCopy ? (
          <Tooltip title={common.t<string>('pages.users.copyLink')}>
            <Button type={ButtonTypesEnum.text} icon={<CopyFilled />} onClick={onClipboard} />
          </Tooltip>
        ) : null}
      </div>
      {content}
    </div>
  );
}

export default memo(ContentBlock);
