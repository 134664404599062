import React, { memo } from 'react';

import { CopyFilled, DeleteFilled, EditFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { ProjectLearningStructureEnum } from 'types/entities';

import Button from 'components/button/Button';
import Block from 'components/block/Block';
import Popconfirm from 'components/popconfirm';
import Popover from 'components/popover';
import { ButtonTypesEnum } from 'components/button/types';

import useCommonContext from 'hooks/useCommonContext';

type ExtraButtonsProps = {
  isElement: boolean;
  show: boolean;
  canDelete: boolean;
  learningStructureType?: ProjectLearningStructureEnum | null;
  hasChild?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  onCopy?: () => void;
};

function ExtraButtons({
  isElement,
  show,
  canDelete,
  learningStructureType,
  onDelete,
  onCopy,
  onEdit,
}: ExtraButtonsProps) {
  const common = useCommonContext();
  if (!show) {
    return null;
  }

  const titleDelete =
    learningStructureType === ProjectLearningStructureEnum.production_item
      ? common.t<string>('common.deleteElement')
      : common.t<string>('common.deleteSection');

  return (
    <Block className="course-tree-item__extra-buttons">
      <Block empty hidden={!canDelete}>
        <Popconfirm
          title={titleDelete}
          okText={common.t<string>('common.delete')}
          cancelText={common.t<string>('common.cancel')}
          onConfirm={onDelete}
          okButtonProps={{ danger: true }}
          icon={<ExclamationCircleFilled style={{ color: '#e5263e' }} />}
        >
          <Button
            className="course-tree-item__extra-buttons--delete"
            type={ButtonTypesEnum.link}
            icon={<DeleteFilled />}
          />
        </Popconfirm>
      </Block>

      <Block empty hidden={canDelete}>
        <Popover
          overlayStyle={{ width: '240px' }}
          title={common.t<string>('pages.project.popover.deleteElement.title')}
          content={common.t<string>('pages.project.popover.deleteElement.content')}
        >
          <div className="course-tree-item__extra-buttons--delete-fake">
            <DeleteFilled />
          </div>
        </Popover>
      </Block>

      <Block empty hidden={!isElement}>
        <Button
          className="course-tree-item__extra-buttons--copy ml_6"
          onClick={onCopy}
          type={ButtonTypesEnum.link}
          icon={<CopyFilled />}
        />
      </Block>

      <Button
        className="course-tree-item__extra-buttons--edit ml_6"
        onClick={onEdit}
        type={ButtonTypesEnum.link}
        icon={<EditFilled />}
      />
    </Block>
  );
}

export default memo(ExtraButtons);
