import React, { useEffect, useRef } from 'react';

import EditorJS, { API, OutputData } from '@editorjs/editorjs';
import { cx } from 'utils';
import { ToolConstructable, ToolSettings } from '@editorjs/editorjs/types/tools';

import useCommonContext from '../../hooks/useCommonContext';

import { getEditorTools } from './tools';

import './styles.scss';

type EditorProps = {
  placeholder?: string;
  data?: OutputData;
  initialize?: (core: EditorJS | null) => void;
  readOnly?: boolean;
  editorId?: string;
  defaultValue?: OutputData;
  onChange?(api: API, event: CustomEvent): void;
  onReadyEditor?: () => void;
  tools?: {
    [toolName: string]: ToolConstructable | ToolSettings;
  };
};

export const EDITOR_HOLDER_ID = 'editor-js';

function Editor({
  placeholder,
  initialize,
  readOnly,
  defaultValue,
  onChange,
  onReadyEditor,
  editorId = EDITOR_HOLDER_ID,
  tools,
}: EditorProps) {
  const instance = useRef<EditorJS | null>(null);
  const classNames = cx('editor');
  const common = useCommonContext();
  useEffect(() => {
    instance.current = new EditorJS({
      holder: editorId,
      placeholder: placeholder || common.t<string>('common.typeTheScript'),
      readOnly,
      tools: tools || getEditorTools(),
      data: defaultValue,
      onReady: () => onReadyEditor?.(),
      onChange: (api, event) => onChange?.(api, event),
    });
    initialize?.(instance.current);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      instance.current?.destroy?.();
    };
    // This will run only once
  }, []);

  return <div id={editorId} className={classNames} />;
}

export default Editor;
