import React, { memo } from 'react';

import { Progress } from 'antd';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

type ContentProgressProps = {
  completed: number;
  total: number;
};

function ContentProgress({ completed, total }: ContentProgressProps) {
  const common = useCommonContext();
  const progress = total ? (completed / total) * 100 : 0;

  return (
    <div className="info-drawer-content-progress">
      <span className="info-drawer-content-progress__label">{common.t<string>('pages.courses.drawer.progress')}</span>
      <div className="info-drawer-content-progress__inner">
        <span className="info-drawer-content-progress__percent">{`${Math.floor(progress)}%`}</span>
        <span className="info-drawer-content-progress__completed">{`${completed} ${common.t<string>(
          'pages.courses.drawer.outOf'
        )} ${total}`}</span>
      </div>
      <Progress percent={Math.floor(progress)} showInfo={false} />
    </div>
  );
}

export default memo(ContentProgress);
