import React, { memo, ReactNode } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import TruncateMarkup from 'react-truncate-markup';

import Button from 'components/button/Button';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

type HeaderSmallProps = {
  title?: string;
  children?: ReactNode;
  onGoBack?: () => void;
};

function HeaderSmall({ title, children, onGoBack }: HeaderSmallProps) {
  const common = useCommonContext();
  const goBack = () => common.navigate(-1);
  return (
    <header className="header-small">
      <Button className="mr_16" shape="circle" icon={<ArrowLeftOutlined />} onClick={onGoBack || goBack} />
      <TruncateMarkup lines={1}>
        <span className="header-small__title">{title}</span>
      </TruncateMarkup>

      <div className="header-small__actions">{children}</div>
    </header>
  );
}

export default memo(HeaderSmall);
