import React, { memo } from 'react';

import { getUserIdsFromMetrics } from 'utils';
import { isEmpty } from 'lodash';

import EmptyData from 'components/emptyData/EmptyData';

import { ProjectMetricsDelayItem, ProjectMetricsIterationItem } from 'hooks';

import useCommonContext from 'hooks/useCommonContext';

import BlockProblems from '../blockProblems';
import { ProjectUserItem } from '../../../../types';
import ProblemsUserInfo from '../problemsUserInfo/ProblemsUserInfo';
import ProblemView from '../problemView/ProblemView';

import './styles.scss';

type AllProblemsProps = {
  isCurrent?: boolean;
  empty?: boolean;
  emptyTextKey?: string;
  projectId?: string;
  users?: ProjectUserItem[];
  metricsDeadlineProduction?: ProjectMetricsDelayItem[];
  metricsDeadlineFirstIteration?: ProjectMetricsDelayItem[];
  metricsTaskFourIteration?: ProjectMetricsIterationItem[];
};

function Problems({
  isCurrent,
  empty,
  emptyTextKey,
  projectId,
  users,
  metricsDeadlineProduction,
  metricsDeadlineFirstIteration,
  metricsTaskFourIteration,
}: AllProblemsProps) {
  const common = useCommonContext();

  if (
    empty ||
    (isEmpty(metricsDeadlineProduction) && isEmpty(metricsDeadlineFirstIteration) && isEmpty(metricsDeadlineProduction))
  ) {
    return <EmptyData title={common.t<string>(emptyTextKey || 'pages.courses.drawer.emptyAllProblems')} />;
  }

  const userIds = getUserIdsFromMetrics(metricsDeadlineFirstIteration, metricsDeadlineProduction);
  const iterationNotAssignee = metricsDeadlineFirstIteration?.filter((el) => !el?.assignee?.id);
  const deadlinesNotAssignee = metricsDeadlineProduction?.filter((el) => !el?.assignee?.id);

  return (
    <>
      <BlockProblems
        hidden={isEmpty(metricsDeadlineFirstIteration) && isEmpty(metricsDeadlineProduction)}
        className="mb_32"
        type="delay"
        title={common.t<string>('pages.courses.drawer.delays')}
      >
        {deadlinesNotAssignee?.map((el) => {
          return (
            <div key={el.id} className="info-drawer-problems-user__wrapper">
              <ProblemsUserInfo className="mb_20" />
              <ProblemView
                isCurrent={isCurrent}
                problemType="taskInLate"
                projectId={projectId}
                className="mb_16"
                title={common.t<string>('pages.courses.drawer.tasksHandedInLate')}
                days={el?.delay_duration_max}
                tasks={el?.tasks_amount}
              />
            </div>
          );
        })}
        {iterationNotAssignee?.map((el) => {
          return (
            <div key={el.id} className="info-drawer-problems-user__wrapper">
              <ProblemsUserInfo className="mb_20" />
              <ProblemView
                isCurrent={isCurrent}
                problemType="taskInLate"
                projectId={projectId}
                className="mb_16"
                title={common.t<string>('pages.courses.drawer.tasksHandedInLate')}
                days={el?.delay_duration_max}
                tasks={el?.tasks_amount}
              />
            </div>
          );
        })}
        {userIds?.map((userId) => {
          const user = users?.find((u) => u?.company_user?.user?.id === userId);
          const tasksInLate = metricsDeadlineFirstIteration?.find(
            (el) => user?.company_user?.user?.id === el?.assignee?.id
          );
          const overdueDeadlines = metricsDeadlineProduction?.find(
            (el) => user?.company_user?.user?.id === el?.assignee?.id
          );
          if (!tasksInLate && !overdueDeadlines) {
            return null;
          }
          const classNames = tasksInLate && overdueDeadlines ? 'mb_16' : '';

          return (
            <div key={userId} className="info-drawer-problems-user__wrapper">
              <ProblemsUserInfo className="mb_20" user={user} />
              {tasksInLate ? (
                <ProblemView
                  isCurrent={isCurrent}
                  problemType="taskInLate"
                  projectId={projectId}
                  userId={user?.company_user.user.id}
                  className={classNames}
                  title={common.t<string>('pages.courses.drawer.tasksHandedInLate')}
                  days={tasksInLate?.delay_duration_max}
                  tasks={tasksInLate?.tasks_amount}
                />
              ) : null}
              {overdueDeadlines ? (
                <ProblemView
                  isCurrent={isCurrent}
                  problemType="overdueDeadlines"
                  projectId={projectId}
                  userId={user?.company_user.user.id}
                  title={common.t<string>('pages.courses.drawer.deadlineDelay')}
                  days={overdueDeadlines?.delay_duration_max}
                  tasks={overdueDeadlines?.tasks_amount}
                />
              ) : null}
            </div>
          );
        })}
      </BlockProblems>
      <BlockProblems
        hidden={isEmpty(metricsTaskFourIteration)}
        type="iteration"
        title={common.t<string>('pages.courses.drawer.iterations')}
      >
        {metricsTaskFourIteration?.map((el, index) => {
          const assignee = users?.find((u) => u?.company_user?.user?.id === el?.assignee?.id);
          const reviewer = users?.find((u) => u?.company_user.user?.id === el?.reviewer?.id);

          return (
            <div key={index} className="info-drawer-problems-user__wrapper">
              {assignee ? <ProblemsUserInfo className="mb_20" user={assignee} /> : null}
              {reviewer ? <ProblemsUserInfo className="mb_20" user={reviewer} /> : null}
              <ProblemView
                isCurrent={isCurrent}
                hiddenDays={true}
                problemType="taskIteration"
                projectId={projectId}
                userId={assignee?.company_user.user.id}
                title={common.t<string>('pages.courses.drawer.moreThreeIterations')}
                tasks={el.total_tasks_amount}
              />
            </div>
          );
        })}
      </BlockProblems>
    </>
  );
}

export default memo(Problems);
