import { RefObject, useEffect, useState } from 'react';

function useHover<T extends HTMLElement = HTMLElement>(elementRef: RefObject<T>): boolean {
  const [hovered, setHovered] = useState<boolean>(false);
  const enter = () => setHovered(true);
  const leave = () => setHovered(false);

  useEffect(() => {
    const el = elementRef.current; // cache external ref value for cleanup use
    if (el) {
      el.addEventListener('mouseenter', enter);
      el.addEventListener('mouseleave', leave);

      return () => {
        el.removeEventListener('mouseenter', enter);
        el.removeEventListener('mouseleave', leave);
      };
    }
  }, [elementRef]);

  return hovered;
}

export default useHover;
