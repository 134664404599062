/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CompanyStateAdd = {
    readonly id?: number;
    name: string;
    state_type: CompanyStateAdd.state_type;
    button_name?: string;
    is_new_iteration_initiation: boolean;
    is_require_discuss_message: boolean;
    is_could_close_without_complete_issues?: boolean | null;
};

export namespace CompanyStateAdd {

    export enum state_type {
        BLOCKED = 'blocked',
        NEW = 'new',
        IN_PROGRESS = 'in_progress',
        REVIEW = 'review',
        READY = 'ready',
        REOPEN = 'reopen',
        DONE = 'done',
        CANCELLED = 'cancelled',
        REJECTED = 'rejected',
        PLANNING = 'planning',
        LAUNCH = 'launch',
        SUPPORT = 'support',
        PRODUCTION = 'production',
        ARCHIVAL = 'archival',
    }


}
