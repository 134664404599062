import API from 'api/api';
import { ApiModules, ENDPOINTS } from 'api/constants';

import {
  PresentationIssueRequestPayloadType,
  PresentationUpdateIssueRequestPayloadType,
  VideoIssueRequestPayloadType,
} from '../../store/issues/types';

export const onAddIssue = (payload: PresentationIssueRequestPayloadType): Promise<any> =>
  API(ApiModules.ARTEFACT_AUDIT).post(ENDPOINTS.ADD_ISSUE, payload);
export const onAddCommentToStatus = (stateId: number, payload: any): Promise<any> =>
  API(ApiModules.PM).post(ENDPOINTS.ADD_COMMENT(stateId), payload);
export const onDeleteIssue = (issueId: number): Promise<any> =>
  API(ApiModules.ARTEFACT_AUDIT).delete(ENDPOINTS.MODIFY_ISSUE(issueId));

export const onUpdateIssue = (issueId: number, payload: PresentationUpdateIssueRequestPayloadType): Promise<any> =>
  API(ApiModules.ARTEFACT_AUDIT).put(ENDPOINTS.MODIFY_ISSUE(issueId), payload);

//Video
export const onAddIssueVideo = (payload: VideoIssueRequestPayloadType): Promise<any> =>
  API(ApiModules.ARTEFACT_AUDIT).post(ENDPOINTS.ADD_ISSUE_VIDEO, payload);

export const onDeleteIssueVideo = (issueId: number): Promise<any> =>
  API(ApiModules.ARTEFACT_AUDIT).delete(ENDPOINTS.MODIFY_ISSUE_VIDEO(issueId));

export const onUpdateIssueVideo = (issueId: number, payload: PresentationUpdateIssueRequestPayloadType): Promise<any> =>
  API(ApiModules.ARTEFACT_AUDIT).put(ENDPOINTS.MODIFY_ISSUE_VIDEO(issueId), payload);

//Document

export type AddIssueDocumentPayload = {
  artefact_id: string;
  description: string;
  document_file_version_id: string;
  document_file_section_external_id: string;
  external_id: string;
};

export const onAddIssueDocument = (payload: AddIssueDocumentPayload): Promise<any> =>
  API(ApiModules.ARTEFACT_AUDIT).post(ENDPOINTS.ADD_ISSUE_DOCUMENT, payload);

type UpdateIssueDocumentPayload = {
  description: string;
};

export const onUpdateIssueDocument = (issueId: string, payload: UpdateIssueDocumentPayload): Promise<any> =>
  API(ApiModules.ARTEFACT_AUDIT).put(ENDPOINTS.UPDATE_ISSUE_DOCUMENT(issueId), payload);

export const onDeleteIssueDocument = (issueId: string): Promise<any> =>
  API(ApiModules.ARTEFACT_AUDIT).delete(ENDPOINTS.UPDATE_ISSUE_DOCUMENT(issueId));

type UpdateDiscussionComment = {
  message: string;
};

export const onUpdateDiscussionComment = (messageId: string, payload: UpdateDiscussionComment): Promise<any> =>
  API(ApiModules.PM).put(ENDPOINTS.UPDATE_COMMENT_TO_DISCUSSION(messageId), payload);

export const onDeleteDiscussionComment = (messageId: string): Promise<any> =>
  API(ApiModules.PM).delete(ENDPOINTS.UPDATE_COMMENT_TO_DISCUSSION(messageId));
