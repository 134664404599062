import { useCallback, useEffect, useRef, useState } from 'react';

import { useQuery } from '@apollo/client';
import { BusinessRoleEnum, CompanyUserItem, LastState, StateType, UserRoleBase } from 'types/entities';
import { isEmpty } from 'lodash';
import { arrStrToArrNum } from 'utils';

import { checkUserBusinessRole } from 'utils/roles';

import useCommonContext from 'hooks/useCommonContext';
import useBoolean from 'hooks/useBoolean';

import { ProjectStatesItem, TasksDataProps } from '../types';
import { GET_TASKS } from '../../../queries/getTasks';

type TaskItem = {
  id: string;
  name: string;
  last_state_updated_at?: string;
  last_state_is_changed?: boolean;
  project: {
    id: string;
    name: string;
  };
  cached_linked_artefact: {
    pk: string;
    first_iteration_deadline: string;
    first_iteration_delay_duration_summary: string;
    production_deadline: string;
    delay_duration_summary: string;
    first_iteration_deadline_delay: number;
    production_deadline_delay: number;
    deadline_first_iteration_review: string;
    deadline_production: string;
    artefact_iteration_count: number;
    messages_amount?: number;
    project_task?: {
      id: string;
    };
    artefact_type: {
      name: string;
      type: string;
      icon_image: string;
    };
    artefact_versions: {
      file: {
        file_type: string;
      };
    }[];
  };
  artefact_production_sequence: {
    project_task: {
      id: string;
      last_state_updated_at: string;
      last_state_is_changed: string;
      last_state: {
        state_type: StateType;
        name: string;
      };
    } | null;
    project_learning_object: {
      id: string;
      name: string;
      learning_object_type: {
        name: string;
        icon_image: string;
      };
      parent: {
        human_readable_number: string;
      };
    };
  }[];
  current_assignees: {
    project_task: {
      id: string;
    };
    id: string;
    is_current_executor: boolean;
    project_user: CompanyUserItem;
  }[];
  last_state: LastState;
  separate_task: {
    cached_artefact_iteration_count: number;
    deadline_first_iteration_review: string;
    deadline_production: string;
    delay_duration_summary: number;
    first_iteration_delay_duration_summary: number;
    is_delayed: boolean;
    project_task: {
      id: string;
    };
    task_type: {
      id: string;
      name: string;
      icon_image?: string;
    };
  } | null;
};

type TasksData = {
  project_tasks: {
    edges: TaskItem[];
    totalCount: number;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
  };
  project_states: ProjectStatesItem[];
};

const getIsShowAllTasks = (roles?: UserRoleBase[]) => {
  return (
    checkUserBusinessRole(roles, BusinessRoleEnum.admin) ||
    checkUserBusinessRole(roles, BusinessRoleEnum.instructionalDesigner)
  );
};

const getIsNotCurrentExecutor = (roles?: UserRoleBase[]) => {
  return !(
    checkUserBusinessRole(roles, BusinessRoleEnum.manager) ||
    checkUserBusinessRole(roles, BusinessRoleEnum.executiveManager)
  );
};

const LIMIT_PAGE_ITEM = 10;

const getDataFromNode = (edges: any[]) => edges?.map((el) => el.node);

function useTasksData({ filter, skip }: TasksDataProps) {
  const [tasks, setTasks] = useState<TaskItem[] | null>(null);
  const [hasFirstLoad, onFirstLoad] = useBoolean(false);

  const common = useCommonContext();

  useEffect(() => {
    if (!hasFirstLoad && tasks) {
      onFirstLoad.on();
    }
  }, [hasFirstLoad, onFirstLoad, tasks]);

  const onCompleted = useCallback((d: TasksData) => {
    setTasks(getDataFromNode(d?.project_tasks.edges));
  }, []);

  const { data, loading, fetchMore } = useQuery<TasksData>(GET_TASKS, {
    variables: {
      user_id: getIsShowAllTasks(common?.businessRoles) ? undefined : common?.userId,
      last_state_names: !isEmpty(filter.statuses) ? filter.statuses : undefined,
      artefact_type_names: !isEmpty(filter.artefact) ? filter.artefact : undefined,
      learning_object_name: filter.searchText,
      project_ids: filter.projectName ? [Number(filter.projectName)] : undefined,
      element_type_ids: !isEmpty(filter.elements) ? arrStrToArrNum(filter.elements) : undefined,
      user_ids: filter.assignee ? [Number(filter.assignee)] : undefined,
      artefact_last_iteration_deadline_filter:
        filter.taskFormToday?.to_date === 'all' ? { to_date: '' } : filter.taskFormToday,
      //is_current_executor: getIsCurrentExecutor(common?.businessRoles),
      is_current_executor: getIsNotCurrentExecutor(common?.businessRoles),
      //is_supervisor: !getIsCurrentExecutor(common?.businessRoles),
      task_types: ['video_shooting', 'artefact_production', 'separate_task'],
      offset: 0,
      first: LIMIT_PAGE_ITEM,
    },
    skip,
    onCompleted,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
  });

  const onLoadMore = () =>
    fetchMore({
      variables: {
        user_id: getIsShowAllTasks(common?.businessRoles) ? undefined : common?.userId,
        last_state_ids: !isEmpty(filter.statuses) ? arrStrToArrNum(filter.statuses) : undefined,
        artefact_types: !isEmpty(filter.artefact) ? arrStrToArrNum(filter.artefact) : undefined,
        learning_object_name: filter.searchText,
        project_ids: filter.projectName ? [Number(filter.projectName)] : undefined,
        element_type_ids: !isEmpty(filter.elements) ? arrStrToArrNum(filter.elements) : undefined,
        user_ids: filter.assignee ? [Number(filter.assignee)] : undefined,
        artefact_last_iteration_deadline_filter:
          filter.taskFormToday?.to_date === 'all' ? { to_date: '' } : filter.taskFormToday,
        is_current_executor: getIsNotCurrentExecutor(common?.businessRoles),
        //is_supervisor: !getIsCurrentExecutor(common?.businessRoles),
        task_types: ['video_shooting', 'artefact_production', 'separate_task'],
        after: data?.project_tasks.pageInfo.endCursor,
        first: LIMIT_PAGE_ITEM,
        onCompleted,
      },
    });

  return {
    loading,
    hasFirstLoad,
    hasNextPage: data?.project_tasks.pageInfo.hasNextPage,
    tasks,
    onLoadMore,
  };
}

export default useTasksData;
