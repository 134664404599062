import React, { memo } from 'react';

import { Collapse as ACollapse } from 'antd';
import { cx } from 'utils';
import { CollapseProps } from 'antd/lib/collapse/Collapse';

interface CCMCollapseProps extends CollapseProps {
  className?: string;
  children: React.ReactNode;
}

function Collapse({ className, children, ...props }: CCMCollapseProps) {
  const classNames = cx('ccm-collapse', className);
  return (
    <ACollapse className={classNames} {...props}>
      {children}
    </ACollapse>
  );
}

export default memo(Collapse);
