import React, { memo } from 'react';

import { Table as ATable, TableProps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { cx } from '../../utils';
import Spin from '../spin';

import EmptyData from './EmptyData';

// import 'antd/dist/antd.css';

import './styles.scss';

type CCMTableProps = {
  className?: string;
  columns: any;
  data: any;
  emptyText?: string;
  loading?: boolean;
  hasFirstLoad?: boolean;
};

const getRowKey = (record: any): string => {
  return record?.id?.toString();
};

function Table({
  className,
  columns,
  data,
  emptyText,
  loading,
  hasFirstLoad,
  ...props
}: CCMTableProps & TableProps<any>) {
  const classNames = cx('ccm-table', className);

  const spinner = {
    spinning: loading,
    indicator: <Spin className="mr_8" size="large" indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />,
    tip: 'Загружаем данные',
  };

  return (
    <ATable
      className={classNames}
      columns={columns}
      dataSource={data}
      rowKey={getRowKey}
      loading={loading}
      locale={{ emptyText: <EmptyData hasFirstLoad={hasFirstLoad} text={emptyText} /> }}
      {...props}
    />
  );
}

Table.defaultProps = {
  data: [],
};

export default memo(Table);
