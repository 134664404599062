import React, { useCallback } from 'react';

import { DefaultOptionType } from 'rc-select/lib/Select';
import { useParams } from 'react-router-dom';
import {
  DownloadOutlined,
  EditFilled,
  ExclamationCircleFilled,
  ExpandAltOutlined,
  ShrinkOutlined,
  WarningFilled,
} from '@ant-design/icons';
import { noop } from 'lodash';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Can from 'config/Can';
import usePageFilters from 'pages/hooks/usePageFilters';

import Select from 'components/select/Select';
import Search from 'components/search';
import Button from 'components/button';
import { ButtonTypesEnum } from 'components/button/types';
import Block from 'components/block/Block';
import Popconfirm from 'components/popconfirm/Popconfirm';
import Tooltip from 'components/tooltip';

import useCommonContext from 'hooks/useCommonContext';
import useProjectStates from 'hooks/queries/useProjectStates';
import useCompanyLearningObjectTypes from 'hooks/queries/useCompanyLearningObjectTypes';

import SettingsButton from '../settings';
import { SettingsData } from '../../types';

import './styles.scss';

type ContentToolbarProps = {
  courseEmpty?: boolean;
  settings: SettingsData;
  isEdit: boolean;
  hasChangedTree?: boolean;
  onChangeSettings?: (e: CheckboxChangeEvent) => void;
  onCloseAll?: () => void;
  onOpenAll?: () => void;
  onStartEdit?: () => void;
  onUndoEdit?: () => void;
  onSave?: () => void;
  onDownload?: () => void;
};

function ContentToolbar({
  courseEmpty,
  settings,
  isEdit,
  hasChangedTree,
  onChangeSettings,
  onCloseAll,
  onOpenAll,
  onUndoEdit,
  onStartEdit,
  onSave,
  onDownload,
}: ContentToolbarProps) {
  const { id } = useParams();
  const common = useCommonContext();

  const filters = usePageFilters();

  const { objectStatesForSelect } = useProjectStates(id);
  const { companyLearningObjectTypesForSelect } = useCompanyLearningObjectTypes('cache-only');
  const filterOption = useCallback((inputValue: string, option?: DefaultOptionType) => {
    const label = option?.label || '';
    return label?.toString()?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  }, []);

  return (
    <div className="content-toolbar">
      <div className="content-toolbar__filters">
        <Select
          name="status"
          noForm
          placeholder={common.t<string>('pages.project.filter.status')}
          mode="multiple"
          maxTagCount="responsive"
          disabled={courseEmpty}
          value={filters.statuses}
          options={objectStatesForSelect}
          onChange={filters.onChangeStatuses}
          filterOption={filterOption}
        />
        <Select
          name="artefact"
          noForm
          placeholder={common.t<string>('pages.project.filter.item')}
          mode="multiple"
          maxTagCount="responsive"
          disabled={courseEmpty}
          value={filters.types}
          options={companyLearningObjectTypesForSelect}
          onChange={filters.onChangeTypes}
          filterOption={filterOption}
        />
        <Search
          name="search"
          placeholder={common.t<string>('pages.project.filter.itemName')}
          disabled={courseEmpty}
          value={filters.textSearch}
          onChange={filters.onChangeText}
          onSearch={filters.onSearch}
        />
      </div>
      <div className="content-toolbar__actions">
        <Block empty hidden={courseEmpty}>
          <Button
            size="large"
            className="mr_8"
            type={ButtonTypesEnum.text}
            icon={<ExpandAltOutlined style={{ fontSize: 20 }} />}
            onClick={onOpenAll}
          />
          <Button
            className="mr_24 ml_8"
            size="large"
            type={ButtonTypesEnum.text}
            icon={<ShrinkOutlined style={{ fontSize: 20 }} />}
            onClick={onCloseAll}
          />
          <SettingsButton settings={settings} onChange={onChangeSettings} />
        </Block>

        <Can I="update" a="Project.content.edit">
          <div className="plan-toolbar__edit ml_8">
            <Block className="ml_auto" hidden={!isEdit}>
              <Popconfirm
                open={hasChangedTree ? undefined : false}
                placement="bottom"
                title={common.t<string>('common.cancelEdit')}
                okText={common.t<string>('common.yes')}
                cancelText={common.t<string>('common.no')}
                onConfirm={onUndoEdit}
                icon={<WarningFilled style={{ color: '#e98529' }} />}
              >
                <Button type={ButtonTypesEnum.default} size="large" onClick={hasChangedTree ? noop : onUndoEdit}>
                  {common.t<string>('common.canceling')}
                </Button>
              </Popconfirm>

              <Popconfirm
                placement="bottom"
                title={common.t<string>('common.saveEdit')}
                okText={common.t<string>('common.yes')}
                cancelText={common.t<string>('common.no')}
                onConfirm={onSave}
                icon={<ExclamationCircleFilled style={{ color: '#1E1E1E' }} />}
              >
                <Button className="ml_6" size="large" type={ButtonTypesEnum.primary}>
                  {common.t<string>('common.save')}
                </Button>
              </Popconfirm>
            </Block>
            <Block empty hidden={isEdit}>
              <Button
                type={ButtonTypesEnum.text}
                size="large"
                icon={<EditFilled style={{ fontSize: 20 }} />}
                onClick={onStartEdit}
              />
            </Block>
          </div>
        </Can>

        <Can I="manage" a="Project.content.download.button">
          <Tooltip placement="topRight" title={common.t<string>('pages.project.tooltips.exportMaterials')}>
            <Button
              className="ml_8"
              type={ButtonTypesEnum.text}
              size="large"
              icon={<DownloadOutlined style={{ fontSize: 20 }} />}
              onClick={onDownload}
            />
          </Tooltip>
        </Can>
      </div>
    </div>
  );
}

export default ContentToolbar;
