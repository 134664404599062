import React, { memo, ReactNode } from 'react';

import { cx } from 'utils';

import './styles.scss';
import { Skeleton } from 'antd';

type HeaderProps = {
  loading?: boolean;
  children?: ReactNode;
  className?: string;
  title?: string;
  hasBorder?: boolean;
};

function Header({ loading, children, className, title, hasBorder }: HeaderProps) {
  const classNames = cx('header', className, {
    ['header__border']: hasBorder,
  });
  return (
    <header className={classNames}>
      <div className="header__left">
        {loading ? (
          <Skeleton paragraph={false} avatar={false} active style={{ width: 325 }} />
        ) : (
          <span className="header__title">{title}</span>
        )}
      </div>
      <div className="header__right">{children}</div>
    </header>
  );
}

export default memo(Header);
