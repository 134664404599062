import { Form } from 'antd';

import TextField from '../../../components/textField';
import { TextFieldTypeEnum } from '../../../components/textField/types';
import Search from '../../../components/search';

const InputStories = () => {
  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <Form
      className="flex-row"
      requiredMark={false}
      layout="vertical"
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div style={{ width: '250px', marginRight: '20px' }}>
        <TextField size="xl" placeholder="XL" />
        <TextField size="large" placeholder="large" />
        <TextField size="middle" placeholder="middle" />
        <TextField type={TextFieldTypeEnum.password} size="middle" placeholder="middle" />
      </div>
      <div style={{ width: '250px', marginRight: '20px' }}>
        <TextField size="large" placeholder="XL" status="warning" />
        <TextField size="large" placeholder="large" status="error" />
        <TextField size="large" placeholder="middle" disabled />
      </div>
      <div style={{ width: '250px' }}>
        <Search size="middle" className="mb_16" placeholder="Input text" />
        <Search size="large" className="mb_16" placeholder="Input text" />
        <Search size="xl" className="mb_16" placeholder="Input text" />

        <Search size="middle" className="mb_16" placeholder="Input text" enterButton />
        <Search size="large" className="mb_16" placeholder="Input text" enterButton />
        <Search size="xl" className="mb_16" placeholder="Input text" enterButton />

        <Search size="middle" className="mb_16" placeholder="Input text" enterButton="Search" />
        <Search size="large" className="mb_16" placeholder="Input text" enterButton="Search" />
        <Search size="xl" className="mb_16" placeholder="Input text" enterButton="Search" />
      </div>
    </Form>
  );
};

export default InputStories;
