import React from 'react';

import { Form } from 'antd';

import Select from 'components/select';

import Button from '../../../components/button';
import { ButtonTypesEnum } from '../../../components/button/types';

const data = [
  {
    id: '1',
    label: 'menu item 1',
    value: '1',
  },
  {
    id: '2',
    label: 'menu item 2',
    value: '2',
  },
  {
    id: '3',
    label: 'menu item 3',
    value: '3',
  },
  {
    id: '4',
    label: 'menu item 4',
    value: '4',
  },
  {
    id: '5',
    label: 'menu item 5',
    value: '5',
  },
];

const SelectStories = () => {
  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form requiredMark={false} layout="vertical" name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <div className="flex-row">
        <div style={{ width: '300px' }} className="mb_8 flex-col mr_16">
          <Select className="mb_16" size="xl" noForm placeholder="XLarge" options={data} />
          <Select className="mb_16" size="large" noForm placeholder="Large" options={data} />
          {/*<Select*/}
          {/*  name="username"*/}
          {/*  className="mb_16"*/}
          {/*  size="large"*/}
          {/*  placeholder="Large form"*/}
          {/*  label="Label"*/}
          {/*  options={data}*/}
          {/*  rules={[{ required: true, message: 'Please input your username!' }]}*/}
          {/*/>*/}

          <Select className="mb_16" size="middle" noForm placeholder="Middle" options={data} />
          {/*<Select*/}
          {/*  name="username2"*/}
          {/*  className="mb_16"*/}
          {/*  size="middle"*/}
          {/*  placeholder="Middle form"*/}
          {/*  label="Label"*/}
          {/*  options={data}*/}
          {/*  rules={[{ required: true, message: 'Please input your username!' }]}*/}
          {/*/>*/}

          <Select className="mb_16" size="small" noForm placeholder="Small" options={data} />
          {/*<Select*/}
          {/*  name="username3"*/}
          {/*  className="mb_16"*/}
          {/*  size="small"*/}
          {/*  placeholder="Small form"*/}
          {/*  label="Label"*/}
          {/*  options={data}*/}
          {/*  rules={[{ required: true, message: 'Please input your username!' }]}*/}
          {/*/>*/}
        </div>
        <div style={{ width: '300px' }} className="mb_8 flex-col mr_16">
          <Select className="mb_16" mode="multiple" size="xl" noForm placeholder="XLarge" options={data} />
          <Select className="mb_16" mode="multiple" size="large" noForm placeholder="Large" options={data} />
          <Select className="mb_16" mode="multiple" size="middle" noForm placeholder="Middle" options={data} />
          <Select className="mb_16" mode="multiple" size="small" noForm placeholder="Small" options={data} />
        </div>
        <div style={{ width: '300px' }} className="mb_8 flex-col mr_16">
          <Select status="warning" className="mb_16" size="middle" noForm placeholder="Middle" options={data} />
          <Select status="error" className="mb_16" size="middle" noForm placeholder="Middle" options={data} />
          <Select disabled className="mb_16" size="middle" noForm placeholder="Middle" options={data} />
        </div>
      </div>

      <Button type={ButtonTypesEnum.primary} htmlType="submit">
        Submit
      </Button>
    </Form>
  );
};

export default SelectStories;
