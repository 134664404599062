import React, { memo } from 'react';

import { EditFilled, MailFilled, StopFilled } from '@ant-design/icons';
import { CcmUserState } from 'schemas/types';

import Button from 'components/button';
import { ButtonTypesEnum } from 'components/button/types';
import Tooltip from 'components/tooltip';
import Block from 'components/block';

import useCommonContext from 'hooks/useCommonContext';

type ActionBarProps = {
  userState?: CcmUserState;
  onBlock: () => void;
  onInvite: () => void;
  onEdit: () => void;
};

function ActionBar({ userState, onBlock, onInvite, onEdit }: ActionBarProps) {
  const common = useCommonContext();
  return (
    <div className="action-bar">
      <Block empty hidden={userState === CcmUserState.Blocked}>
        <Tooltip placement="bottom" title={common.t<string>('pages.users.blockEmployee')}>
          <Button
            className="button-block"
            size="middle"
            type={ButtonTypesEnum.text}
            icon={<StopFilled />}
            onClick={onBlock}
          />
        </Tooltip>
      </Block>

      <Tooltip placement="bottom" title={common.t<string>('pages.users.sendFollowUpInvitation')}>
        <Button
          className="button-send ml_8 mr_8"
          size="middle"
          type={ButtonTypesEnum.text}
          icon={<MailFilled />}
          onClick={onInvite}
        />
      </Tooltip>
      <Tooltip placement="bottom" title={common.t<string>('pages.users.editData')}>
        <Button
          className="button-edit"
          size="middle"
          type={ButtonTypesEnum.text}
          icon={<EditFilled />}
          onClick={onEdit}
        />
      </Tooltip>
    </div>
  );
}

export default memo(ActionBar);
