import React, { useEffect, useMemo, useState } from 'react';

import { cx } from 'utils';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { StateType } from 'types/entities';
import Empty from 'pages/task/components/empty';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import DiscussionBlock from 'components/discussionBlock';
import DropdownMenu from 'components/dropdownMenu';

import useDocumentDiscussions, { DocumentDiscussionEx } from 'hooks/queries/useDocumentDiscussions';
import useProjectTask from 'hooks/queries/useProjectTask';
import useCommonContext from 'hooks/useCommonContext';

import CommentPane from '../commentPane/CommentPane';
import Dropdown from '../dropdown';
import { MenuInfo } from '../dropdownMenu/types';
import useVisible from '../../hooks/useVisible';
import { CommentSortType } from '../../pages/task/types';
import { CONTAINER_DISCUSSION_ID } from '../../pages/task/subPages/text/hooks/useDiscussionControl';

import './styles.scss';
import useScrollDiscussion from '../../pages/task/subPages/text/hooks/useScrollDiscussion';

type CommentsProps = {
  discussionId: string | null;
  onCancelTemp: () => void;
  onCreateDiscussion: (text: string) => Promise<any>;
  onRemoveMarkerById: (markerId: string) => void;
};

const getDataState = (t: TFunction) => [
  {
    id: '0',
    label: t<string>('common.opened'),
    value: StateType.NEW,
  },
  {
    id: '1',
    label: t<string>('common.closed'),
    value: StateType.DONE,
  },
];

const getSortState = (t: TFunction) => [
  {
    id: '0',
    label: t<string>('common.byText'),
    value: CommentSortType.text,
  },
  {
    id: '1',
    label: t<string>('common.byCreateAt'),
    value: CommentSortType.time,
  },
];

function Comments({ discussionId, onCancelTemp, onCreateDiscussion, onRemoveMarkerById }: CommentsProps) {
  const { t } = useTranslation();
  const [disState, setDisState] = useState(getDataState(t)[0]);
  const [sort, setSort] = useState(getSortState(t)[0]);

  const [searchParams] = useSearchParams();
  const version = searchParams.get('version');
  const common = useCommonContext();
  const { currentArtefact, projectId } = useProjectTask(version, 'cache-and-network');
  const { discussionsExtended, discussionsActive, discussionsClosed, discussionsRefetch } = useDocumentDiscussions({
    artefactId: Number(currentArtefact?.id),
  });

  useScrollDiscussion({ discussions: discussionsExtended });
  const { ref, isVisible, setIsVisible } = useVisible(false, onCancelTemp);

  useEffect(() => {
    if (discussionId) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [discussionId, setIsVisible]);

  const classNames = cx('comments');

  const onClickMenu = (info: MenuInfo) => {
    setDisState(getDataState(t)[Number(info.key)]);
  };

  const onClickSort = (info: MenuInfo) => {
    setSort(getSortState(t)[Number(info.key)]);
  };

  const discussions = disState.value === StateType.NEW ? discussionsActive : discussionsClosed;

  const discussionsSort = useMemo(() => {
    if (sort.value === CommentSortType.time) {
      return discussions?.sort((a, b) => {
        return Number(moment(b.created_at).format('X')) - Number(moment(a.created_at).format('X'));
      });
    }
    return discussions;
  }, [discussions, sort.value]);

  return (
    <div className={classNames}>
      <div className="comments__menus">
        <Dropdown overlay={<DropdownMenu onClick={onClickSort} list={getSortState(t)} />}>{sort.label}</Dropdown>
        <Dropdown overlay={<DropdownMenu onClick={onClickMenu} list={getDataState(t)} />}>{disState.label}</Dropdown>
      </div>
      <div id={CONTAINER_DISCUSSION_ID} className={CONTAINER_DISCUSSION_ID}>
        {isVisible ? (
          <CommentPane
            anchor={ref}
            autoFocus
            showActions
            className="mb_16"
            onCancel={onCancelTemp}
            onSubmit={onCreateDiscussion}
          />
        ) : null}

        {!discussionId && isEmpty(discussions) ? <Empty text={common.t<string>('pages.task.noComments')} /> : null}

        {discussionsSort?.map((discussion: DocumentDiscussionEx) => {
          return (
            <DiscussionBlock
              key={discussion.pk}
              projectId={projectId}
              data={discussion}
              discussionsRefetch={discussionsRefetch}
              onRemoveMarkerById={onRemoveMarkerById}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Comments;
