import React, { memo } from 'react';

import { Button as AntButton } from 'antd';
import { cx } from 'utils';

import Tooltip from '../tooltip';

import { ButtonTypesEnum, IButton } from './types';

function Button({
  children,
  className,
  fullWidth,
  type = ButtonTypesEnum.default,
  loading,
  size,
  disabled,
  hintText,
  ...props
}: IButton) {
  const classNames = cx('ccm-button', className, {
    [`ccm-button--${size}`]: size,
  });

  if (disabled && hintText) {
    return (
      <Tooltip title={hintText}>
        <AntButton loading={loading} size={size} type={type} disabled={disabled} className={classNames} {...props}>
          {children}
        </AntButton>
      </Tooltip>
    );
  }

  return (
    <AntButton loading={loading} size={size} type={type} disabled={disabled} className={classNames} {...props}>
      {children}
    </AntButton>
  );
}

export default memo(Button);
