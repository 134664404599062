import React from 'react';

import { useTranslation } from 'react-i18next';

import Text from '../../components/text/Text';
import { TextVariantEnum } from '../../components/text/types';
import Select from '../../components/select';
import TextField from '../../components/textField';
import { TextFieldTypeEnum } from '../../components/textField/types';

export function StepVideo() {
  const { t } = useTranslation();
  return (
    <>
      <Text className="mb_12" variant={TextVariantEnum.descriptionBold}>
        {t('pages.course.modal.location')}
      </Text>
      <Select name="module" label={t('form.field.module.label')} placeholder={t('form.field.module.placeholder')} />
      <Select name="lesson" label={t('form.field.lesson.label')} placeholder={t('form.field.lesson.placeholder')} />
      <Select
        name="afterElement"
        label={t('form.field.afterElement.label')}
        placeholder={t('form.field.afterElement.placeholder')}
      />
      <Text className="mb_12" variant={TextVariantEnum.descriptionBold}>
        {t('pages.course.modal.info')}
      </Text>
      <TextField name="title" label={t('form.field.title.label')} placeholder={t('form.field.title.placeholder')} />
      <TextField
        name="learningResults"
        label={t('form.field.learningResults.label')}
        placeholder={t('form.field.learningResults.placeholder')}
      />
      <TextField
        name="description"
        type={TextFieldTypeEnum.textArea}
        label={t('form.field.description.label')}
        placeholder={t('form.field.description.placeholder')}
      />
    </>
  );
}

export function StepAddMaterial() {
  const { t } = useTranslation();
  return (
    <>
      <TextField name="title2" label={t('form.field.title.label')} placeholder={t('form.field.title.placeholder')} />
      <TextField
        name="learningResults2"
        label={t('form.field.learningResults.label')}
        placeholder={t('form.field.learningResults.placeholder')}
      />
    </>
  );
}
