import React, { useRef } from 'react';

import parse from 'html-react-parser';
import { v4 as uuid } from 'uuid';
import { ExpandAltOutlined, ShrinkOutlined } from '@ant-design/icons';

import { DocumentFile } from '../../pages/project/components/projectContentTree/types';
import Button from '../button';
import useBoolean from '../../hooks/useBoolean';
import { ButtonTypesEnum } from '../button/types';
import Title from '../title';

import { getTitleType } from './helpers';

import './styles.scss';

type DocumentFileBlockProps = {
  documentFile?: DocumentFile;
};

function DocumentFileBlock({ documentFile }: DocumentFileBlockProps) {
  const [expanded, onExpanded] = useBoolean(false);
  const anchor = useRef<HTMLDivElement>(null);

  const styles = {
    maxHeight: expanded ? 'inherit' : 237,
  };

  const initHeight = anchor.current?.getBoundingClientRect().height || 0;

  return (
    <div style={styles} className="document-file-block">
      {initHeight >= 190 ? (
        <Button
          className="document-file-block__toggle"
          size="large"
          type={ButtonTypesEnum.text}
          icon={!expanded ? <ExpandAltOutlined /> : <ShrinkOutlined />}
          onClick={onExpanded.toggle}
        />
      ) : null}

      <div ref={anchor} className="document-file-block__content">
        {documentFile?.sections?.map((s, index) => {
          const content = JSON.parse(s.section_content);

          if (s.section_type === 'header') {
            return (
              <Title key={index} className="mb_8" variant={getTitleType(Number(content?.level))}>
                {parse(content?.text)}
              </Title>
            );
          }

          if (s.section_type === 'image') {
            return (
              <div key={index} className="document-file-block__image mb_24">
                <img src={content?.file?.url} />
              </div>
            );
          }

          if (s.section_type === 'list') {
            return (
              <ol key={index} className="document-file-block__list mb_24">
                {content.items.map((el: string, idx: number) => {
                  console.log(el);
                  return <li key={idx}>{parse(el)}</li>;
                })}
              </ol>
            );
          }

          if (s.section_type === 'table') {
            return (
              <div key={index} className="document-file-block__table mb_24">
                {content.content.map((el: string[], idx: number) => {
                  return (
                    <div key={idx} className="document-file-block__table-row">
                      {el.map((v: string, i: number) => {
                        return (
                          <div className="document-file-block__table-cell" key={i}>
                            {parse(v)}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          }

          if (s.section_type === 'paragraph') {
            return <p key={uuid()}>{parse(content?.text)}</p>;
          }
        })}
      </div>
    </div>
  );
}

export default DocumentFileBlock;
