import React, { useMemo } from 'react';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import { calculateHeight, cx } from 'utils';
import { TextAreaProps } from 'antd/lib/input/TextArea';

import { ITextField, TextFieldTypeEnum } from './types';

const { TextArea } = Input;

function TextFieldPassword(props: ITextField) {
  return <Input.Password {...props} iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)} />;
}

function TextFieldArea(props: TextAreaProps) {
  return <TextArea {...props} />;
}

function TextField({ className, name, label, rules, type, noForm, normalize, ...props }: ITextField) {
  const classNames = cx('text-field', className, {
    [`text-field--${name}`]: name,
    'text-field--no-form': noForm,
    [`text-field--${props.size}`]: props.size,
  });

  const styles = useMemo(() => {
    if (type === TextFieldTypeEnum.textArea) {
      return {
        height: 'auto',
      };
    }
    return {
      height: `${calculateHeight(Boolean(label), Boolean(noForm), props.size)}px`,
    };
  }, [label, noForm, props.size, type]);

  const Component = useMemo(() => {
    if (type === TextFieldTypeEnum.password) {
      return TextFieldPassword;
    }
    if (type === TextFieldTypeEnum.textArea) {
      return TextFieldArea;
    }
    return Input;
  }, [type]);

  if (noForm) {
    return <Component style={styles} className={classNames} name={name} {...props} autoComplete="off" />;
  }

  return (
    <Form.Item
      style={styles}
      label={label}
      name={name}
      className={classNames}
      colon={false}
      labelAlign="left"
      rules={rules}
      normalize={normalize}
    >
      <Component {...props} autoComplete="off" />
    </Form.Item>
  );
}

TextField.defaultProps = {
  type: TextFieldTypeEnum.text,
};

export default TextField;
