import { gql } from '@apollo/client';

export const GET_COMPANY_ISSUES_LIST = gql`
  query GetCompanyIssues($companyId: Int, $company_artefact_type_id: Int) {
    artefact_content_issues(company_id: $companyId, company_artefact_type_id: $company_artefact_type_id) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
          description
          image_url
          pk
        }
        cursor
      }
    }
  }
`;
