import React, { memo } from 'react';

import { Skeleton } from 'antd';

const styles = {
  title: { width: '100%', maxWidth: '325px', height: '24px' },
  input: { width: '100%', maxWidth: '200px', height: '24px' },
  block: { width: '100%', height: '24px', maxWidth: '240px', marginRight: '12px' },
};

function UsersFilterSkeleton() {
  return (
    <div className="skeleton-users-header">
      <div className="skeleton-filter__top">
        <Skeleton paragraph={false} avatar={false} active style={styles.title} />
        <Skeleton paragraph={false} avatar={false} active style={styles.input} />
      </div>
      <div className="skeleton-filter__bottom">
        <Skeleton paragraph={false} avatar={false} active style={styles.block} />
        <Skeleton paragraph={false} avatar={false} active style={styles.block} />
      </div>
    </div>
  );
}

export default memo(UsersFilterSkeleton);
