import { gql } from '@apollo/client';

export const GET_ARTEFACT_DEADLINES_CHANGED = gql`
  query ArtefactDeadlinesChanged($project_id: Int, $artefact_id: Int, $deadline_type: String) {
    artefact_deadlines_changed(
      project_id: $project_id
      artefact_id: $artefact_id
      deadline_type: $deadline_type
      skip_duplicates: true
    ) {
      edges {
        node {
          id
          created_at
          created_date
          creator {
            id
            name
            first_name
            last_name
          }
          deadline_new
          artefact {
            id
            artefact_type {
              name
              type
              icon_image
            }
            artefact_versions {
              id
              file {
                file_type
              }
            }
            artefact_iteration_count
            cached_task {
              id
              artefact_production_sequence {
                id
                project_learning_object {
                  id
                  name
                  parent {
                    human_readable_number
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
