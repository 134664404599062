import React, { useMemo } from 'react';

import { Drawer } from 'components/drawer';

import useProject from 'hooks/queries/useProject';
import useLockBodyScroll from 'hooks/useLockBodyScroll';

import InfoDrawerContent from './ui/infoDrawerContent';
import InfoDrawerHeader from './ui/header';

import './styles.scss';
import useProjectUsers from '../../../../hooks/queries/useProjectUsers';

type InfoDrawerProps = {
  show: boolean;
  projectId: string;
  onClose: () => void;
};

function InfoDrawer({ show, projectId, onClose }: InfoDrawerProps) {
  const { projectData } = useProject(Number(projectId));
  const { projectUsers } = useProjectUsers(projectId);

  useLockBodyScroll(show);

  const headerRender = useMemo(() => {
    return <InfoDrawerHeader projectId={projectId} onClose={onClose} />;
  }, [onClose, projectId]);

  return (
    <Drawer
      className="info-drawer"
      width={560}
      mask={false}
      open={show}
      onClose={onClose}
      maskClosable={true}
      closable={false}
      title={headerRender}
      destroyOnClose
    >
      <InfoDrawerContent data={projectData} projectUsers={projectUsers} />
    </Drawer>
  );
}

export default InfoDrawer;
