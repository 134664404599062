import { PureAbility, AbilityBuilder, AbilityClass } from '@casl/ability';

import { BusinessRoleEnum, UserRoles } from '../types/entities';
import { checkUserRole } from '../utils/roles';

type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete' | 'view';
type Subjects = any;

export type ClaimAbilityType = PureAbility<[Actions, Subjects]>;
export const ClaimAbility = PureAbility as AbilityClass<ClaimAbilityType>;

//Руководитель производства
const defineRulesForExecutiveManager = (roles?: UserRoles[], can?: any) => {
  can('update', 'Project.participants.customer');
  can('update', 'Project.participants.manager');
  can('update', 'Project.participants.instructionalDesigner');
  can('update', 'Project.participants.author');
  can('update', 'Project.participants.authorManager');
  can('update', 'Project.participants.videoProductionManager');
  can('update', 'Project.participants.productionExecutor');
  can('update', 'Project.participants.learningTechnologist');
  can('update', 'Project.participants.supportManager');
  can('update', 'Project.participants.support');

  can('manage', 'Project.userDrawer.gantButton');

  can('manage', 'Project.participants.selectDrawer.allCompany');

  can('update', 'Project.content.edit');
  can('update', 'Project.plan.edit');
  can('manage', 'Project.plan.create.separate');

  can('update', 'Project.about.form');

  can('manage', 'Users.add');
  can('update', 'Users.info');
  can('manage', 'Users.toolbar.companies');

  can('view', 'Users.info.company');
  can('manage', 'Courses.addCourse');

  can('manage', 'Tasks.filter.assignees');
  can('manage', 'Tasks.filter.element');

  can('update', 'Task.discussion.state');
  can('update', 'Task.discussion.text');

  can('update', 'SeparateTask.edit');

  can('view', 'Dashboard');

  can('view', 'Dashboard.courses');
  can('view', 'Dashboard.courses.noAssignees');
  can('view', 'Dashboard.courses.hasIssues');
  can('view', 'Dashboard.courses.deadline');

  can('view', 'Dashboard.collaborators');

  can('manage', 'Project.content.download.button');
};

//Продюсер
const defineRulesForManager = (roles?: UserRoles[], can?: any) => {
  can('update', 'Project.participants.customer');
  can('view', 'Project.participants.manager');
  can('view', 'Project.participants.instructionalDesigner');
  can('update', 'Project.participants.author');
  can('update', 'Project.participants.authorManager');
  can('update', 'Project.participants.videoProductionManager');
  can('update', 'Project.participants.productionExecutor');
  can('manage', 'Project.participants.selectDrawer.allCompany');
  can('update', 'Project.participants.supportManager');
  can('update', 'Project.participants.support');

  can('update', 'Project.about.form');

  can('update', 'Project.content.edit');
  can('update', 'Project.plan.edit');
  can('manage', 'Project.plan.create.separate');

  can('manage', 'Users.add');

  can('manage', 'Tasks.filter.assignees');
  can('manage', 'Tasks.filter.element');
  can('manage', 'Users.toolbar.companies');

  can('update', 'Task.discussion.state');
  can('update', 'Task.discussion.text');

  can('update', 'SeparateTask.edit');

  can('view', 'Dashboard');

  can('view', 'Dashboard.courses');
  can('view', 'Dashboard.courses.noAssignees');
  can('view', 'Dashboard.courses.hasIssues');
  can('view', 'Dashboard.courses.deadline');

  can('view', 'Dashboard.tasks');
  can('view', 'Dashboard.tasks.deadlines');
  can('view', 'Dashboard.tasks.iteration');
  can('view', 'Dashboard.tasks.twoDays');

  can('manage', 'Project.content.download.button');
};

//Руководитель авторского коллектива
const defineRulesForAuthorManager = (roles?: UserRoles[], can?: any) => {
  can('view', 'Project.participants.stakeholder');
  can('view', 'Project.participants.manager');
  can('view', 'Project.participants.instructionalDesigner');
  can('update', 'Project.participants.author');
  can('view', 'Project.participants.videoProductionManager');
  can('view', 'Project.participants.productionExecutor');
  can('manage', 'Project.participants.selectDrawer.onlyToMyCompany');

  can('view', 'Project.about.info');

  can('update', 'Project.plan.edit');

  can('manage', 'Users.add');
  can('update', 'Users.info');
  can('view', 'Users.info.company');

  can('manage', 'Tasks.filter.assignees');
  can('manage', 'Tasks.filter.element');
};

//Руководитель видеопроизводства
const defineRulesForVideoProductionManager = (roles?: UserRoles[], can?: any) => {
  can('view', 'Project.participants.stakeholder');
  can('view', 'Project.participants.manager');
  can('view', 'Project.participants.instructionalDesigner');
  can('view', 'Project.participants.author');
  can('view', 'Project.participants.videoProductionManager');
  can('update', 'Project.participants.productionExecutor');
  can('manage', 'Project.participants.selectDrawer.onlyToMyCompany');

  can('view', 'Project.about.info');

  can('update', 'Project.plan.edit');

  can('manage', 'Users.add');
  can('update', 'Users.info');
  can('view', 'Users.info.company');

  can('manage', 'Tasks.filter.assignees');
  can('manage', 'Tasks.filter.element');

  can('view', 'Dashboard');

  can('view', 'Dashboard.courses');
  can('view', 'Dashboard.courses.noAssignees');
  can('view', 'Dashboard.courses.hasIssues');

  can('view', 'Dashboard.tasks');
  can('view', 'Dashboard.tasks.deadlines');
  can('view', 'Dashboard.tasks.iteration');
  can('view', 'Dashboard.tasks.twoDays');
};

//Методист
const defineRulesForInstructionalDesigner = (roles?: UserRoles[], can?: any) => {
  can('view', 'Project.participants.stakeholder');
  can('view', 'Project.participants.manager');
  can('view', 'Project.participants.instructionalDesigner');
  can('view', 'Project.participants.author');
  can('view', 'Project.participants.videoProductionManager');
  can('view', 'Project.participants.productionExecutor');

  can('view', 'Project.about.info');

  can('update', 'Project.content.edit');

  can('view', 'Tasks.table.assignees');
  can('manage', 'Tasks.filter.assignees');
  can('manage', 'Tasks.filter.element');

  can('update', 'Task.discussion.state');
  can('update', 'Task.discussion.text');

  can('view', 'Dashboard');
  can('view', 'Dashboard.tasks');
  can('view', 'Dashboard.tasks.widgets');
  can('view', 'Dashboard.tasks.comments');
  can('view', 'Dashboard.tasks.today');
};

//Технолог по обучению
const defineRulesForLearningTechnologist = (roles?: UserRoles[], can?: any) => {
  can('view', 'Project.participants.stakeholder');
  can('view', 'Project.participants.manager');
  can('view', 'Project.participants.instructionalDesigner');
  can('view', 'Project.participants.author');
  can('view', 'Project.participants.videoProductionManager');
  can('view', 'Project.participants.productionExecutor');

  can('view', 'Project.about.info');

  can('view', 'Tasks.table.assignees');
  can('manage', 'Tasks.filter.assignees');
  can('manage', 'Tasks.filter.element');

  can('update', 'Task.discussion.state');
  can('update', 'Task.discussion.text');

  can('view', 'Dashboard');
  can('view', 'Dashboard.tasks');
  can('view', 'Dashboard.tasks.widgets');
  can('view', 'Dashboard.tasks.comments');
  can('view', 'Dashboard.tasks.today');
};

//Исполнитель
const defineRulesForProductionExecutor = (roles?: UserRoles[], can?: any) => {
  can('view', 'Project.participants.stakeholder');
  can('view', 'Project.participants.manager');
  can('view', 'Project.participants.instructionalDesigner');
  can('view', 'Project.participants.author');
  can('view', 'Project.participants.videoProductionManager');
  can('view', 'Project.participants.productionExecutor');

  can('view', 'Project.about.info');

  can('view', 'Dashboard');
  can('view', 'Dashboard.tasks');
  can('view', 'Dashboard.tasks.widgets');
  can('view', 'Dashboard.tasks.comments');
  can('view', 'Dashboard.tasks.today');
};

//Автор
const defineRulesForAuthor = (roles?: UserRoles[], can?: any) => {
  can('view', 'Project.participants.stakeholder');
  can('view', 'Project.participants.manager');
  can('view', 'Project.participants.instructionalDesigner');
  can('view', 'Project.participants.author');
  can('view', 'Project.participants.videoProductionManager');
  can('view', 'Project.participants.productionExecutor');

  can('view', 'Project.about.info');

  can('view', 'Dashboard');
  can('view', 'Dashboard.tasks');
  can('view', 'Dashboard.tasks.widgets');
  can('view', 'Dashboard.tasks.comments');
  can('view', 'Dashboard.tasks.today');
};

//Руководитель поддержки
const defineRulesForSupportManager = (roles?: UserRoles[], can?: any) => {
  can('view', 'Project.participants.stakeholder');
  can('view', 'Project.participants.manager');
  can('view', 'Project.participants.instructionalDesigner');
  can('update', 'Project.participants.support');
  can('view', 'Project.participants.videoProductionManager');
  can('view', 'Project.participants.productionExecutor');
  can('manage', 'Project.participants.selectDrawer.onlyToMyCompany');

  can('view', 'Project.about.info');

  can('manage', 'Users.add');
  can('update', 'Users.info');
  can('view', 'Users.info.company');

  can('manage', 'Tasks.filter.assignees');
  can('manage', 'Tasks.filter.element');

  can('manage', 'Project.content.download.button');
};

//Поддержка
const defineRulesForSupport = (roles?: UserRoles[], can?: any) => {
  can('view', 'Project.participants.stakeholder');
  can('view', 'Project.participants.manager');
  can('view', 'Project.participants.instructionalDesigner');
  can('view', 'Project.participants.author');
  can('view', 'Project.participants.videoProductionManager');
  can('view', 'Project.participants.productionExecutor');

  can('view', 'Project.about.info');

  can('view', 'Dashboard');
  can('view', 'Dashboard.tasks');
  can('view', 'Dashboard.tasks.widgets');
  can('view', 'Dashboard.tasks.comments');
  can('view', 'Dashboard.tasks.today');

  can('manage', 'Project.content.download.button');
};

//Заказчик
const defineRulesStakeholder = (roles?: UserRoles[], can?: any) => {
  can('view', 'Project.participants.stakeholder');
  can('view', 'Project.participants.manager');
  can('view', 'Project.participants.instructionalDesigner');
  can('view', 'Project.participants.author');
  can('view', 'Project.participants.videoProductionManager');
  can('view', 'Project.participants.productionExecutor');

  can('view', 'Project.about.info');

  can('view', 'Dashboard');

  can('view', 'Dashboard.courses');
  can('view', 'Dashboard.courses.noAssignees');
  can('view', 'Dashboard.courses.hasIssues');
  can('view', 'Dashboard.courses.deadline');

  can('view', 'Dashboard.tasks');
  can('view', 'Dashboard.tasks.deadlines');
  can('view', 'Dashboard.tasks.iteration');
  can('view', 'Dashboard.tasks.twoDays');
};

const defineRulesFor = (roles?: UserRoles[]) => {
  const { can, rules } = new AbilityBuilder(ClaimAbility);

  if (checkUserRole(roles, BusinessRoleEnum.admin)) {
    defineRulesForExecutiveManager(roles, can);
  }

  if (checkUserRole(roles, BusinessRoleEnum.executiveManager)) {
    defineRulesForExecutiveManager(roles, can);
  }
  if (checkUserRole(roles, BusinessRoleEnum.manager)) {
    defineRulesForManager(roles, can);
  }
  if (checkUserRole(roles, BusinessRoleEnum.videoProductionManager)) {
    defineRulesForVideoProductionManager(roles, can);
  }
  if (checkUserRole(roles, BusinessRoleEnum.authorManager)) {
    defineRulesForAuthorManager(roles, can);
  }
  if (checkUserRole(roles, BusinessRoleEnum.instructionalDesigner)) {
    defineRulesForInstructionalDesigner(roles, can);
  }
  if (checkUserRole(roles, BusinessRoleEnum.learningTechnologist)) {
    defineRulesForLearningTechnologist(roles, can);
  }
  if (checkUserRole(roles, BusinessRoleEnum.productionExecutor)) {
    defineRulesForProductionExecutor(roles, can);
  }
  if (checkUserRole(roles, BusinessRoleEnum.author)) {
    defineRulesForAuthor(roles, can);
  }
  if (checkUserRole(roles, BusinessRoleEnum.support_manager)) {
    defineRulesForSupportManager(roles, can);
  }
  if (checkUserRole(roles, BusinessRoleEnum.support)) {
    defineRulesForSupport(roles, can);
  }
  if (checkUserRole(roles, BusinessRoleEnum.stakeholder)) {
    defineRulesStakeholder(roles, can);
  }

  return rules;
};

export function buildAbilityFor(roles?: UserRoles[]): ClaimAbilityType {
  const ability = new ClaimAbility(defineRulesFor(roles), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    detectSubjectType: (object) => object!.type,
  });
  ability.can = ability.can.bind(ability);
  ability.cannot = ability.cannot.bind(ability);

  return ability;
}
