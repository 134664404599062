import React from 'react';

import useCommonContext from 'hooks/useCommonContext';

function ProjectTableHead() {
  const common = useCommonContext();
  return (
    <div className="project-table-head">
      <div className="project-table-head__cell project-table-cell__number"></div>
      <div className="project-table-head__cell project-table-cell__icon mr_12"></div>
      <div className="project-table-head__cell project-table-cell__element mr_16">
        {common.t<string>('pages.dashboard.table.name')}
      </div>
      <div className="project-table-head__cell project-table-cell__task-type mr_16">
        {common.t<string>('pages.dashboard.table.taskType')}
      </div>
      <div className="project-table-head__cell project-table-cell__iteration mr_16">
        {common.t<string>('pages.dashboard.table.iteration')}
      </div>
      <div className="project-table-head__cell project-table-cell__team mr_16">
        {common.t<string>('pages.dashboard.table.participants')}
      </div>
      <div className="project-table-head__cell project-table-cell__deadline mr_16">
        {common.t<string>('pages.dashboard.table.firstIteration')}
      </div>
      <div className="project-table-head__cell project-table-cell__deadline mr_16">
        {common.t<string>('pages.dashboard.table.deadline')}
      </div>
      <div className="project-table-head__cell project-table-cell__changed mr_16">
        {common.t<string>('pages.dashboard.table.changed')}
      </div>
      <div className="project-table-head__cell project-table-cell__status">
        {common.t<string>('pages.dashboard.table.status')}
      </div>
    </div>
  );
}

export default ProjectTableHead;
