import { useRef } from 'react';

import { useQuery } from '@apollo/client';

import { GET_DASHBOARD } from '../../queries/getDashboard';
import { ProjectStatesItem } from '../../pages/tasks/types';
import { CompanyArtefactType } from '../../types';
import { CompanyArtefactTypeWithId, CompanyUserItem, LastState } from '../../types/entities';
import { PlanFilters } from '../../pages/project/subPages/plan/types';

type UseDashboardProps = {
  projectIds?: number[];
  filter: PlanFilters;
};

export type ProjectTaskItem = {
  pk: string;
  id: string;
  name: string;
  last_state_is_changed: boolean;
  last_state_updated_at: string;
  artefacts: {
    id: string;
    artefact_type: CompanyArtefactTypeWithId;
    artefact_versions: {
      file: {
        file_type: string;
      };
    }[];
  }[];
  project: {
    pk: string;
    id: string;
  };
  active_artefacts: {
    artefact_iteration_count: string;
  };
  last_state: LastState;
  learning_object: {
    id: string;
    name: string;
    learning_object_type: {
      id: string;
      name: string;
      icon_image: string;
    };
    parent: {
      human_readable_number: string;
    };
  } | null;
  cached_linked_artefact: {
    id: string;
    artefact_type: CompanyArtefactType;
    cached_artefact_iteration_count: string;
    first_iteration_deadline: string;
    first_iteration_deadline_delay: number;
    first_iteration_deadline_changed: boolean;
    production_deadline: string;
    production_deadline_delay: number;
    production_deadline_changed: boolean;
  };
  current_assignees: {
    project_task: {
      id: string;
    };
    id: string;
    is_current_executor: boolean;
    project_user: CompanyUserItem;
  }[];
  separate_task: {
    cached_artefact_iteration_count: number;
    deadline_first_iteration_review: string;
    deadline_production: string;
    delay_duration_summary: number;
    delay_duration_summary_days: number;
    first_iteration_delay_duration_summary: number;
    first_iteration_delay_duration_summary_days: number;
    first_iteration_deadline_changed: boolean;
    production_deadline_changed: boolean;
    is_delayed: boolean;
    project_task: {
      id: string;
    };
    task_type: {
      id: string;
      name: string;
      icon_image?: string;
    };
  } | null;
};

type ProjectTaskItemNode = {
  node: ProjectTaskItem;
};

export type ProjectTasksData = {
  project_tasks: {
    edges: ProjectTaskItemNode[];
    totalCount: number;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
  };
  project_states: ProjectStatesItem[];
};

const LIMIT_PAGE_ITEM = 50;

function useDashboard({ projectIds, filter }: UseDashboardProps) {
  const hasFirstLoad = useRef<boolean>(false);

  const onCompleted = () => {
    if (!hasFirstLoad.current) {
      hasFirstLoad.current = true;
    }
  };

  const { loading, data, fetchMore, refetch } = useQuery<ProjectTasksData>(GET_DASHBOARD, {
    variables: {
      project_ids: projectIds,
      artefact_type_names: filter.artefactTypeNames,
      last_state_names: filter.statusName,
      user_ids: filter.assigneeIds,
      learning_object_name: filter.searchText,
      deadline_violations_only: filter?.missed,
      task_types: ['video_shooting', 'artefact_production', 'separate_task'],
      offset: 0,
      first: LIMIT_PAGE_ITEM,
      is_course_plan: true,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: onCompleted,
  });

  const onLoadMore = () =>
    fetchMore({
      variables: {
        project_ids: projectIds,
        artefact_type_names: filter.artefactTypeNames,
        last_state_names: filter.statusName,
        user_ids: filter.assigneeIds,
        learning_object_name: filter.searchText,
        deadline_violations_only: filter?.missed,
        task_types: ['video_shooting', 'artefact_production', 'separate_task'],
        offset: 0,
        first: LIMIT_PAGE_ITEM,
        is_course_plan: true,
        after: data?.project_tasks.pageInfo.endCursor,
      },
    });

  return {
    data,
    tasks: data?.project_tasks?.edges?.map((edge) => edge?.node) || [],
    tasksLoading: loading,
    hasFirstLoad: hasFirstLoad.current,
    tasksRefetch: refetch,
    tasksLoadMore: onLoadMore,
    tasksHasNextPage: data?.project_tasks.pageInfo.hasNextPage,
  };
}

export default useDashboard;
