import React from 'react';

import Switch from 'components/switch';

const SwitchStories = () => {
  return (
    <div>
      <Switch className="mb_8" name="test1" />
      <Switch className="mb_8" name="test1" checkedChildren="ON" unCheckedChildren="OFF" />
      <Switch className="mb_8" name="test1" loading={true} />
      <Switch className="mb_8" name="test1" disabled />

      <Switch className="mb_8" name="test1" size="small" />
      <Switch className="mb_8" name="test1" size="small" checkedChildren="ON" unCheckedChildren="OFF" />
      <Switch className="mb_8" name="test1" size="small" loading={true} />
      <Switch className="mb_8" name="test1" size="small" disabled />
    </div>
  );
};

export default SwitchStories;
