import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { localStorageService } from 'services/localStorageService';
import { ENDPOINTS } from 'api/constants';
import history from 'router/history';
import { message } from 'antd';

import { PATH_NAMES } from '../router/constants';
import { messageDurability } from '../constants';
import { NotificationType } from '../types/entities';
import notificationService from '../services/notificationService';
import i18n from '../i18n';

const API_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://dev-ccm.xpdev.ru/account/api'
    : `${window.location.origin}/account/api`;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const authData = localStorageService.getAuthData();

  if (authData && config?.headers) {
    config.headers.Authorization = `Bearer ${authData.access}`;
  }

  return config;
};

const onRequestError = async (error: AxiosError): Promise<AxiosResponse<any, any>> => {
  const originalConfig = error.config;
  if (error.request) {
    if (error?.request?.status === 401 && error?.request?.data?.code === 'token_not_valid') {
      const authData = localStorageService.getAuthData();
      if (authData?.refresh) {
        await axios
          .post(`${API_URL}/${ENDPOINTS.REFRESH_TOKEN}`, {
            refresh: authData?.refresh,
          })
          .then(async (response: AxiosResponse) => {
            const { access, refresh } = response?.data;
            if (access && refresh) {
              const newAuthData = {
                access,
                refresh,
              };
              await localStorageService.setAuthData(newAuthData);
              originalConfig!.headers!.Authorization = 'Bearer ' + access;
              // return Promise.resolve();
              return Promise.resolve(axios(originalConfig));
            } else {
              localStorageService.removeAuthData();
              history.push(PATH_NAMES.auth.login);
            }
          })
          .catch((_error) => {
            localStorageService.removeAuthData();
            history.push(PATH_NAMES.auth.login);
            return Promise.reject(_error.request);
          });
      }
    }
  }

  if ((error?.request?.data?.detail || error?.request?.data?.error) && error?.response?.data?.error_code) {
    if (error?.request?.status !== 401 && error?.request?.data?.code !== 'token_not_valid') {
      const notification = {
        type: NotificationType.error,
        description: `${error.response?.data?.detail || error?.response?.data.error}`,
        errorCode: error?.response?.data?.error_code,
        values: { ...error.response?.data?.arguments },
      };
      notificationService.show(notification);
    }
  } else {
    message.error(
      `${error?.request?.data?.detail || error?.request?.data?.error || `Something went wrong`}`,
      messageDurability
    );
    // notificationService.on(notification);
  }
  // if (error?.request?.status === 403) {
  //   window.location.replace(PATH_NAMES.errors.error403);
  // }
  if (process.env.NODE_ENV !== 'development') {
    if (error?.request?.status === 403) {
      history.push(PATH_NAMES.errors.error403);
    }
    if (error?.request?.status === 404) {
      history.push(PATH_NAMES.errors.error404);
    }
    if (error?.request?.status === 405) {
      history.push(PATH_NAMES.errors.error405);
    }
    if (error?.request?.status === 500) {
      history.push(PATH_NAMES.errors.error500);
    }
    if (error?.request?.status === 502) {
      history.push(PATH_NAMES.errors.error502);
    }
    if (error?.request?.status === 503) {
      history.push(PATH_NAMES.errors.error503);
    }
  }

  if (error?.request?.status === 401) {
    return Promise.resolve(error.request);
  } else {
    return Promise.reject(error.request);
  }
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response?.data;
};

const onResponseError = async (error: AxiosError): Promise<AxiosResponse<any, any>> => {
  const originalConfig = error.config;
  if (error.response) {
    if (error?.response?.status === 401 && error.response?.data?.code === 'token_not_valid') {
      const authData = localStorageService.getAuthData();
      await axios
        .post(`${API_URL}/${ENDPOINTS.REFRESH_TOKEN}`, {
          refresh: authData?.refresh,
        })
        .then(async (response: AxiosResponse) => {
          const { access, refresh } = response?.data;
          if (access && refresh) {
            const newAuthData = {
              access,
              refresh,
            };
            localStorageService.setAuthData(newAuthData);
            originalConfig!.headers!.Authorization = 'Bearer ' + access;
            // return Promise.resolve();
            return Promise.resolve(axios(originalConfig));
          } else {
            localStorageService.removeAuthData();
            history.push(PATH_NAMES.auth.login);
          }
        })
        .catch((_error) => {
          localStorageService.removeAuthData();
          history.push(PATH_NAMES.auth.login);
          return Promise.reject(_error.response);
        });
    }
  }

  if ((error?.response?.data?.detail || error?.response?.data?.error) && error?.response?.data?.error_code) {
    if (error?.response?.status === 401 && error.response?.data?.error_code) {
      const notification = {
        type: NotificationType.error,
        description: `${error.response?.data?.detail || error?.response?.data.error}`,
        errorCode: error?.response?.data?.error_code,
        values: { ...error.response?.data?.arguments },
      };
      if (error?.config?.url !== ENDPOINTS.IMPORT_COURSE) {
        notificationService.show(notification);
      }
    }

    if (error?.response?.status !== 401 && error.response?.data?.code !== 'token_not_valid') {
      console.log(4);
      const notification = {
        type: NotificationType.error,
        description: `${error.response?.data?.detail || error?.response?.data.error}`,
        errorCode: error?.response?.data?.error_code,
        values: { ...error.response?.data?.arguments },
      };
      if (error?.config?.url !== ENDPOINTS.IMPORT_COURSE) {
        notificationService.show(notification);
      }
    }
  } else {
    console.log(5);
    message.error(
      `${error.response?.data?.detail || error?.response?.data?.error || `Something went wrong`}`,
      messageDurability
    );
  }
  // if (error?.request?.status === 403) {
  //   window.location.replace(PATH_NAMES.errors.error403);
  // }
  if (process.env.NODE_ENV !== 'development') {
    if (error?.request?.status === 404) {
      window.location.replace(PATH_NAMES.errors.error404);
    }
    if (error?.request?.status === 405) {
      window.location.replace(PATH_NAMES.errors.error405);
    }
    if (error?.request?.status === 500) {
      window.location.replace(PATH_NAMES.errors.error500);
    }
    if (error?.request?.status === 502) {
      window.location.replace(PATH_NAMES.errors.error502);
    }
    if (error?.request?.status === 503) {
      window.location.replace(PATH_NAMES.errors.error503);
    }
  }
  if (error?.response?.status === 401) {
    return Promise.resolve(error.response);
  } else {
    return Promise.reject(error.response);
  }
};

export const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
