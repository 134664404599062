import React from 'react';

import TruncateMarkup from 'react-truncate-markup';

import { MainContentPlanTreeItemProps } from '../../types';

function MainContent({ node }: MainContentPlanTreeItemProps) {
  return (
    <div className="course-tree-plan-item__main-content">
      <TruncateMarkup lines={1}>
        <span title={node?.text} className="course-tree-plan-item__title">
          {node?.text}
        </span>
      </TruncateMarkup>
    </div>
  );
}

export default MainContent;
