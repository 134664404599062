import { IAction } from 'types/store';

import IssuesTypes from './types';
import IIssuesState from './interfaces';

const initialState: IIssuesState = {
  issues: [],
};

export default function (state = initialState, action: IAction<IssuesTypes>) {
  switch (action.type) {
    case IssuesTypes.SET_ISSUES_DATA:
      return {
        ...state,
        projects: action.payload,
      };
    default:
      return state;
  }
}
