import React, { useCallback, useEffect, useRef } from 'react';

import ReactFlow, {
  addEdge,
  Background,
  Controls,
  Edge,
  MarkerType,
  Node,
  useEdgesState,
  useNodesState,
  updateEdge,
  Panel,
} from 'reactflow';
import { Connection } from '@reactflow/core/dist/esm/types';

import { ButtonTypesEnum } from 'components/button/types';
import Button from 'components/button/Button';

import CustomNode, { NodeData } from '../customNode/CustomNode';
import CustomEdge from '../customEdge';
import { EdgeData } from '../customEdge/CustomEdge';

const nodeTypes = {
  custom: CustomNode,
};

const edgeTypes = {
  smoothstep: CustomEdge,
};

type Props = {
  dataNodes: Node<NodeData>[];
  dataEdges: Edge<EdgeData>[];
  onSave: (nodes: Node<NodeData>[], edges: Edge<EdgeData>[]) => void;
};

const connectionLineStyle = { stroke: '#413aa9' };

const FlowContent = ({ dataNodes, dataEdges, onSave }: Props) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const edgeUpdateSuccessful = useRef(true);

  useEffect(() => {
    setEdges(dataEdges);
    setNodes(dataNodes);
  }, [dataEdges, dataNodes, setEdges, setNodes]);

  const onConnect = useCallback(
    (params: Connection) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: 'smoothstep',
            markerEnd: { type: MarkerType.ArrowClosed },
          },
          eds
        )
      ),
    []
  );

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge: Edge<any>, newConnection: Connection) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  const onEdgeUpdateEnd = useCallback((_: any, edge: Edge<any>) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeUpdateSuccessful.current = true;
  }, []);

  const onClickSave = () => onSave(nodes, edges);

  return (
    <div className="flow__content">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onEdgeUpdate={onEdgeUpdate}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onEdgeUpdateEnd={onEdgeUpdateEnd}
        fitView
        connectionLineStyle={connectionLineStyle}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
      >
        <Controls />
        <Background color="#aaa" gap={16} />
        <Panel position="top-right">
          <Button type={ButtonTypesEnum.primary} onClick={onClickSave}>
            Save
          </Button>
        </Panel>
      </ReactFlow>
    </div>
  );
};

export default FlowContent;
