import React, { useState } from 'react';

import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { Radio } from 'antd';

import { RadioGroup } from '../../../components/radio';

const options = [
  { label: 'Apple', value: 'Apple' },
  { label: 'Pear', value: 'Pear' },
  { label: 'Orange', value: 'Orange' },
  { label: 'Pink', value: 'Cherry', disabled: true },
];
const optionsWithDisabled = [
  { label: 'Apple', value: 'Apple' },
  { label: 'Pear', value: 'Pear' },
  { label: 'Orange', value: 'Orange', disabled: true },
];
const RadioStories = () => {
  const [value1, setValue1] = useState('Apple');
  const [value2, setValue2] = useState('Apple');
  const [value3, setValue3] = useState('Apple');
  const [value4, setValue4] = useState('Apple');

  const onChange1 = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio1 checked', value);
    setValue1(value);
  };

  const onChange2 = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio2 checked', value);
    setValue2(value);
  };

  const onChange3 = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio3 checked', value);
    setValue3(value);
  };

  const onChange4 = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio4 checked', value);
    setValue4(value);
  };
  return (
    <div>
      <Radio.Group options={optionsWithDisabled} onChange={onChange1} value={value1} />
      <br />
      <br />
      <RadioGroup options={options} onChange={onChange2} value={value2} />
      <br />
      <br />
      <RadioGroup options={options} onChange={onChange3} value={value3} optionType="button" />
      <br />
      <br />
      <RadioGroup options={options} onChange={onChange4} value={value4} optionType="button" buttonStyle="solid" />
      <br />
      <br />
      <RadioGroup options={options} onChange={onChange4} value={value4} optionType="button" buttonStyle="solid" />
    </div>
  );
};

export default RadioStories;
