import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { createBrowserHistory } from 'history';

import appReducer from './app';
import authReducer from './auth';
import usersReducer from './users';
import projectsReducer from './projects';
import artefactReducer from './artefact';
import iterationsReducer from './iterations';
import issuesReducer from './issues';

const history = createBrowserHistory();

export default combineReducers({
  app: appReducer,
  auth: authReducer,
  users: usersReducer,
  projects: projectsReducer,
  artefact: artefactReducer,
  iterations: iterationsReducer,
  issues: issuesReducer,

  router: connectRouter(history),
});
