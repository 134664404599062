import { createAction } from '@reduxjs/toolkit';

import { IThunkResult } from '../../types/store';
import {
  onAddCommentToStatus,
  onAddIssue,
  onDeleteIssue,
  onDeleteIssueVideo,
  onUpdateIssue,
  onUpdateIssueVideo,
} from '../../api/requests/issues';

import IssuesTypes, {
  PresentationIssueRequestPayloadType,
  PresentationUpdateIssueRequestPayloadType,
  StatusCommentStateRequestPayloadType,
} from './types';

export const setIssuesDataAction = createAction<any>(IssuesTypes.SET_ISSUES_DATA);

export const addIssueRequest = (payload: PresentationIssueRequestPayloadType): IThunkResult<void> => {
  return async (dispatch) => {
    await onAddIssue({
      artefact_id: payload?.artefact_id,
      slide_number: payload?.slide_number,
      artefact_content_issue_id: payload?.artefact_content_issue_id,
      description: payload?.description,
      coordinate_x: payload?.coordinate_x,
      coordinate_y: payload?.coordinate_y,
    });
  };
};
export const deleteIssueRequest = (issueId: number, isVideo?: boolean): IThunkResult<void> => {
  if (isVideo) {
    return async () => {
      await onDeleteIssueVideo(issueId);
    };
  }
  return async () => {
    await onDeleteIssue(issueId);
  };
};
export const updateIssueRequest = (
  issueId: number,
  payload: PresentationUpdateIssueRequestPayloadType,
  isVideo?: boolean
): IThunkResult<void> => {
  if (isVideo) {
    return async () => {
      await onUpdateIssueVideo(issueId, payload);
    };
  }

  return async () => {
    await onUpdateIssue(issueId, payload);
  };
};
export const addStatusCommentRequest = (
  stateId: number,
  payload: StatusCommentStateRequestPayloadType
): IThunkResult<void> => {
  return async (dispatch) => {
    await onAddCommentToStatus(stateId, payload);
  };
};
