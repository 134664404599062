import { UserRoles } from 'types/entities/user';

import { FileState, FileType, StateType } from '../../types/entities';
import {
  ArtefactContentIssueType,
  ArtefactFileVersionType,
  FinalArtefactPipelineType,
  Maybe,
  UserType,
} from '../../schemas/types';
import { IUserSmallInfo } from '../../store/users/interfaces';
import { CompanyArtefactType } from '../../types';
import { ProjectUserType } from '../../hooks';

export enum CommentSortType {
  text = 'text',
  time = 'time',
}

export type LearningObjectQueryData = {
  id: string;
  name: string;
  description?: string;
  parent: { human_readable_number: string };
  learning_object_type: {
    name: string;
    icon_image: string;
    type: string;
  };
  project: {
    pk: string;
    name: string;
    company: {
      id: string;
    };
    project_task_state_list_version: {
      id: string;
    };
    artefact_issue_state_list_version: {
      id: string;
    };
    projectuser_set: ProjectUserType[];
    active_users: ProjectUserType[];
  };
  project_learning_outcome?: {
    description?: string;
  } | null;
  final_artefacts?: Array<{
    artefact_type?: {
      name: string;
    };
  } | null> | null;
};

type ArtefactType = {
  id: string;
  name: string;
  type: CompanyArtefactType.type;
  icon_image?: string;
};

export type PresentationFile = {
  id: string;
  is_active: boolean;
  order: number;
  source_url: string;
  url?: string;
};

export type VideoFile = {
  id: string;
  is_active: boolean;
  order: number;
  source_url: string;
  url?: string;
  is_source: boolean;
  resolution: number;
};

export type DocumentFileVersion = {
  id: string;
  original_data: string;
  sections: {
    id: string;
    parent_section: {
      id: string;
    };
    section_type: string;
    section_content: string;
    style: string;
    level: number;
    params: string;
    order: number;
  };
};

export type DocumentFileVersionType = {
  id: string;
  original_data: string;
  sections?: {
    id: string;
    parent_section: {
      id: string;
    };
    section_type: string;
    section_content: string;
    style: string;
    level: number;
    params: string;
    order: number;
  }[];
};

export type CommonFile = {
  id: string;
  file: string;
};

export type ArtefactFile = {
  id: string;
  pk: string;
  is_active: boolean;
  name: string;
  state: FileState;
  file_type: FileType;
  related_files?: PresentationFile[] | VideoFile[] | DocumentFileVersion[] | DocumentFileVersionType[];
};

export type ArtefactVersion = {
  id: string;
  is_active: boolean;
  file: ArtefactFile;
  created_at: string;
  updated_at: string;
  number: number;
  is_current: boolean;
};

export type ArtefactFileIteration = {
  id: string;
  is_active: boolean;
  is_current: boolean;
};

export type LastArtefactIteration = {
  artefact_file_version: ArtefactFileIteration | null;
};

export type DocumentDiscussionsSet = {
  id: string;
  external_id: string;
  project_task: {
    id: string;
    description: string;
    last_state: {
      id: string;
      state_type: StateType;
    };
  };
};

export type ArtefactListItem = {
  id: string;
  artefact_type: ArtefactType;
  artefact_versions: ArtefactVersion[] | [];
  production_deadline: string;
  artefact_iteration_count: number;
  last_artefact_iteration: LastArtefactIteration | null;
  first_iteration_deadline: string;
  document_discussions_set?: DocumentDiscussionsSet[];
};

//TODO ROLES
export type AssigneesUser = {
  company_user: {
    id: string;
    user: {
      first_name: string;
      last_name: string;
      id: string;
    };
  };
  roles: UserRoles[];
};

export type ProjectTypeHistoryType = {
  id: string;
  is_rollback_possible: boolean;
  changed_by: {
    id: string;
  };
  state: {
    updated_at: string;
  };
};

export type ArtefactProductionSequenceProjectTask = {
  id: string;
  description: string;
  assignees: AssigneesUser[];
  projecttaskassignee_set: {
    is_active: boolean;
    is_current_executor: boolean;
    project_user: {
      company_user: {
        id: string;
        user: {
          id: string;
          first_name: string;
          last_name: string;
        };
      };
      roles: UserRoles[];
    };
  }[];
  updated_at: string;
  last_state_updated_at: string;
  created_at: string;
  last_state: {
    id: string;
    name: string;
    button_name: string;
    state_type: StateType;
  };
  artefact_list: ArtefactListItem[];
  project_task_state_history: ProjectTypeHistoryType;
};

export type ArtefactProductionSequence = {
  id: string;
  updated_at: string;
  created_at: string;
  is_atrefact: boolean;
  order?: number;
  is_turn_off: boolean;
  project_task: ArtefactProductionSequenceProjectTask;
};

export type ArtefactStateRequest = {
  projectTaskId: number;
  state_id: number;
  message?: string;
  history_id?: number;
};

export type TaskStateRollbackRequest = {
  projectTaskId: number;
  history_id?: number;
};

export type CreateProjectRequest = {
  name: string;
  project_type: string;
  company_id?: string;
  production_start_date: string | null;
  production_finish_date: string | null;
  phase_state_id: number | null;
  state_id: number | null;
};

export type TaskInfoQueryData = {
  artefact_production_sequence: ArtefactProductionSequence[];
  learning_object: LearningObjectQueryData;
};

export type CompanyIssueItem = {
  description: string;
  id: string;
  image_url: string;
  label: string;
  name: string;
  pk: string;
  value: string;
};

export type ArtefactContentVideoFoundIssueType = {
  artefact: ArtefactType;
  artefact_version_creation?: Maybe<ArtefactFileVersionType>;
  artefact_version_solution?: Maybe<ArtefactFileVersionType>;
  changed_by: UserType;
  created_at: string;
  creator?: Maybe<UserType>;
  /** The ID of the object. */
  id: string;
  is_active: boolean;
  is_timerange_changed: boolean;
  issue: ArtefactContentIssueType;
  pk: string;
  project_task?: Maybe<FinalArtefactPipelineType>;
  timestamp_end?: number | null;
  timestamp_start: number;
  updated_at: string;
};

export enum EventTypes {
  post = 'post',
  put = 'put',
  delete = 'delete',
  get = 'get',
}

export type AuditLog = {
  affect_entity_id: number;
  affect_entity_type: string;
  affect_user: IUserSmallInfo;
  component_name: string;
  created_at: string;
  created_by: IUserSmallInfo;
  data: string;
  entity_id: number;
  entity_type: string;
  event_type: EventTypes;
  id: string;
  is_success: boolean;
  pk: string;
  posted_at: string;
  project: {
    pk: string;
    projectuser_set: AssigneesUser[];
  };
};
