import React, { useMemo } from 'react';

import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { useTranslation } from 'react-i18next';

import TextField from 'components/textField';
import Select from 'components/select';
import CCMRangePicker from 'components/rangePicker';

import useCompanyState from 'hooks/queries/useCompanyState';
import useCommonContext from 'hooks/useCommonContext';
import useProjectGroups from 'hooks/queries/useProjectGroups';
import useTimezones from 'hooks/queries/useTimezones';
import './styles.scss';

type AddCourseFormProps = {
  form: FormInstance;
  onFieldsChange?: any;
  title?: string;
};

function AddCourseForm({ title, form, onFieldsChange }: AddCourseFormProps) {
  const common = useCommonContext();
  const { t } = useTranslation();
  const { timezones } = useTimezones();
  const currentUserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const states = useCompanyState(
    common?.company?.id,
    'network-only',
    null,
    Number(common?.company?.default_project_state_state_list_version?.id)
  );

  const phaseStates = useCompanyState(
    common?.company?.id,
    'network-only',
    null,
    Number(common?.company?.default_project_phase_state_list_version?.id)
  );

  const { projectGroupsForSelect } = useProjectGroups();
  const defaultValues = useMemo(() => {
    return {
      timezone: currentUserTimezone,
    };
  }, [currentUserTimezone]);

  return (
    <Form
      id="course-form"
      className="add-course-form"
      layout="vertical"
      form={form}
      onFieldsChange={onFieldsChange}
      initialValues={defaultValues}
    >
      <div className="add-course-form__description">
        {title ? <span className="add-course-form__title">{title}</span> : null}

        <TextField
          className="mb_16"
          size="xl"
          name="name"
          label={t('createCourse.course')}
          placeholder={t('createCourse.coursePlaceholder')}
          rules={[{ required: true, message: `${common.t<string>('common.requiredFields')}` }]}
        />
        <Select
          className="mb_16"
          size="xl"
          name="specialization"
          label={t('createCourse.specialization')}
          placeholder={t('createCourse.specializationPlaceholder')}
          options={projectGroupsForSelect}
        />
        <div className="flex-row mb_16">
          <CCMRangePicker
            size="xl"
            name="production"
            label={t('createCourse.courseProductionPeriod')}
            placeholder={[t('createCourse.start'), t('createCourse.end')]}
            className="mr_16"
          />
          <Select
            size="xl"
            name="timezone"
            label={common.t<string>('common.timezone')}
            placeholder={common.t<string>('common.selectTimezone')}
            options={timezones}
            showSearch
            rules={[{ required: true, message: `${common.t<string>('common.requiredFields')}` }]}
          />
        </div>
        <div className="flex-row mb_16">
          <Select
            className="mr_16"
            size="xl"
            name="phase_state"
            label={t('createCourse.stage')}
            placeholder={t('createCourse.chooseStage')}
            rules={[{ required: true, message: `${common.t<string>('common.requiredFields')}` }]}
            options={phaseStates?.companyStatesDropdownCheckbox}
          />
          <Select
            size="xl"
            name="state"
            label={t('createCourse.status')}
            placeholder={t('createCourse.chooseStatus')}
            rules={[{ required: true, message: `${common.t<string>('common.requiredFields')}` }]}
            options={states?.companyStatesDropdownCheckbox}
          />
        </div>
        <TextField
          size="xl"
          name="description"
          label={t('createCourse.description')}
          placeholder={t('createCourse.about')}
        />
      </div>
    </Form>
  );
}

export default AddCourseForm;
