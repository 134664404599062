import React, { memo } from 'react';

import { Timeline as ATimeline, TimelineProps } from 'antd';
import { cx, getFormatDateByStr } from 'utils';

import { CompanyUsersType, ProjectDeadlinesChangedItem } from 'hooks';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

interface TimelineProdDatesProps extends TimelineProps {
  className?: string;
  data?: ProjectDeadlinesChangedItem[];
  companyUsersData?: CompanyUsersType;
}

function TimelineProjectDeadlinesChanged({ className, data, companyUsersData }: TimelineProdDatesProps) {
  const common = useCommonContext();
  const classNames = cx('ccm-timeline ccm-timeline-prod-dates', className);
  return (
    <ATimeline className={classNames}>
      {data &&
        companyUsersData &&
        data?.map((elem) => {
          const user = companyUsersData?.active_users?.find((item) => item?.user.id === elem?.creator?.id);

          const oldDate = elem?.production_start_date
            ? getFormatDateByStr(elem?.production_start_date, 'D MMM YYYY')
            : '';
          const newDate = elem?.production_finish_date
            ? getFormatDateByStr(elem?.production_finish_date, 'D MMM YYYY')
            : '';

          return (
            <ATimeline.Item key={elem.pk}>
              <span className="ccm-timeline__text">
                {`${user?.business_roles?.[0]?.name && user?.business_roles?.[0]?.name} ${
                  elem?.creator?.name || `${elem?.creator?.first_name} ${elem?.creator?.last_name}`
                }  ${common.t<string>('pages.courses.table.changedDatesProduction')}`}
              </span>
              <span className="ccm-timeline__bold">{`${oldDate}${oldDate ? '-' : ''}${newDate}`}</span>
              <span className="ccm-timeline__time">{getFormatDateByStr(elem.created_at, 'D MMM HH:mm')}</span>
            </ATimeline.Item>
          );
        })}
    </ATimeline>
  );
}

export default memo(TimelineProjectDeadlinesChanged);
