import React, { memo } from 'react';

import { LinkOutlined } from '@ant-design/icons';
import icon from 'assets/image/empty-img-gray.png';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';
import { StateType } from '../../../../types/entities';
import Button from '../../../../components/button';
import Empty from '../empty';

type ResourceLinkTypeProps = {
  link: string | undefined;
  type: string | undefined;
  isCurrentExecutor: boolean | undefined;
  currentTaskStatus: StateType | undefined;
  showInputHandler: () => void;
};

function ResourceLink({ link, type, showInputHandler, currentTaskStatus, isCurrentExecutor }: ResourceLinkTypeProps) {
  const common = useCommonContext();
  return (
    <div className="resource-link">
      {link ? (
        <>
          <a href={link} target="_blank">
            <LinkOutlined style={{ fontSize: '56px', color: '#1E1E1E', marginBottom: '24px' }} />
          </a>
          <span className="resource-link__type mb_16">{type}</span>
          {currentTaskStatus === StateType.REOPEN && isCurrentExecutor ? (
            <Button onClick={() => showInputHandler()}>{common.t<string>('common.newLink')}</Button>
          ) : null}
        </>
      ) : (
        <>
          <Empty text={common.t<string>('pages.task.artifactNotCreated')} />
          {/*<img src={icon} alt="icon" />*/}
          {/*<span className="resource-link__text">{common.t<string>('pages.task.artifactNotCreated')}</span>*/}
        </>
      )}
    </div>
  );
}

export default memo(ResourceLink);
