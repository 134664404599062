import React from 'react';

import { isEmpty } from 'lodash';

import UserElement from 'components/userElement/UserElement';
import UserElementButton from 'components/userElementButton';

import useCommonContext from 'hooks/useCommonContext';

import { GlobalTaskOutput } from '../../hooks/useSeparateTask';

import './styles.scss';

type TaskPerformersProps = {
  instance: GlobalTaskOutput;
  onOpenDrawer: () => void;
};

function GlobalTaskPerformers({ instance, onOpenDrawer }: TaskPerformersProps) {
  const common = useCommonContext();
  return (
    <div className="task-performers">
      <span className="task-performers__label">{common.t<string>('pages.project.create.performer')}</span>
      <div className="task-performers__content">
        {isEmpty(instance.selectedUsers) ? (
          <UserElementButton size="m" onClick={onOpenDrawer} />
        ) : (
          instance.selectedUsers?.map((user) => <UserElement key={user.id} user={user} onClick={onOpenDrawer} />)
        )}
      </div>
    </div>
  );
}

export default GlobalTaskPerformers;
