import { useLazyQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { GET_FINAL_ARTEFACT_PIPELINE } from 'queries/getFinalArtefactPipeline';
import { StateType } from 'types/entities';

export type AllProjectTaskStateHistory = {
  id: string;
  project_task: {
    id: string;
  };
  state: {
    id: string;
    name: string;
    state_type: StateType;
  };
  changed_by: {
    id: string;
    name: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  changed_by_role: {
    id: string;
    role: string;
    name: string;
  };
  created_at: string;
  is_rollback: boolean;
};

export type FinalArtefactPipelineData = {
  final_artefact_pipeline: {
    pk: string;
    id: string;
    all_project_task_state_history: AllProjectTaskStateHistory[];
  };
};

type UseLazyFinalArtefactPipelineProps = {
  projectTaskId: number;
  fetchPolicy?: WatchQueryFetchPolicy;
};

function useLazyFinalArtefactPipeline({ projectTaskId, fetchPolicy }: UseLazyFinalArtefactPipelineProps) {
  const [getFinalArtefactPipeline, { data, loading }] = useLazyQuery<FinalArtefactPipelineData>(
    GET_FINAL_ARTEFACT_PIPELINE,
    {
      variables: {
        project_task_id: projectTaskId,
      },
      fetchPolicy: fetchPolicy || 'network-only',
    }
  );

  return {
    getFinalArtefactPipeline,
    allProjectTaskStateHistory: data?.final_artefact_pipeline?.all_project_task_state_history,
    allProjectTaskStateHistoryLoading: loading,
  };
}

export default useLazyFinalArtefactPipeline;
