import React from 'react';

import { CheckOutlined, FileTextOutlined, VideoCameraOutlined } from '@ant-design/icons';

import { CustomCompanyArtefactTypes } from '../types/entities';
import { CompanyArtefactType } from '../types';
import { IndicatorCourseElementGroupType } from '../components/indicator/types';

function getIconElementType(
  type?: CustomCompanyArtefactTypes | IndicatorCourseElementGroupType | null,
  className?: string
) {
  //TODO Нужно переделать под нолвые типы
  switch (type) {
    case IndicatorCourseElementGroupType.video:
    case CompanyArtefactType.type.ORIGINAL_VIDEO:
    case CompanyArtefactType.type.DRAFT_VIDEO:
      return <VideoCameraOutlined className={className} />;
    case CompanyArtefactType.type.TEXT:
    case IndicatorCourseElementGroupType.material:
      return <FileTextOutlined className={className} />;
    case IndicatorCourseElementGroupType.tasks:
    case CompanyArtefactType.type.GRADER:
      return <CheckOutlined className={className} />;
    // case IndicatorType.discussion:
    //   return 'D';
    // case IndicatorType.programming_assignment:
    //   return 'PA';
    // case IndicatorType.peer_review:
    //   return 'PR';
    // case IndicatorType.quiz:
    //   return 'Q';
    // case IndicatorType.lti:
    //   return 'LTI';
    // case IndicatorType.ungraded_lab:
    //   return 'UL';
    // case IndicatorType.ungraded_plugin:
    //   return 'UP';
    // case IndicatorType.guided_project:
    //   return 'GP';
    default:
      return <FileTextOutlined className={className} />;
  }
}

export default getIconElementType;
