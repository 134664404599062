import { ReactNode } from 'react';

export enum CompanyTypeEnum {
  opm = 'OPM',
  video_production = 'VIDEO_PRODUCTION',
}

export interface CompanyStateListVersion {
  company_state_list: CompanyStateList;
}

export interface CompanyStateList {
  id: string;
  name: string;
  company: Company;
  actual_version: CompanyStateListVersion | null;
  last_version: CompanyStateListVersion | null;
}

export interface Company {
  id: string;
  slug?: string;
  name: string | null;
  company_type: CompanyTypeEnum;
}

export interface CompanyEx extends Company {
  label: ReactNode;
  value: string | number | null;
}
