import React, { useCallback } from 'react';

import modalService from 'services/modalService';

import Title from 'components/title';
import { TitleVariantEnum } from 'components/title/types';
import Button from 'components/button';
import { ButtonTypesEnum } from 'components/button/types';
import Table from 'components/table';

import useCommonContext from 'hooks/useCommonContext';
import { CompanyStateListItem, useCompanyStateLists } from 'hooks/queries/useCompanyStateLists';

import './styles.scss';

const StatusListPage = () => {
  const { company } = useCommonContext();
  const { companyStateList, refetch } = useCompanyStateLists(company?.id);

  const onOpenModal = useCallback(() => {
    modalService.openAddNewStatusList({ companyId: company?.id, refetch });
  }, [company?.id, refetch]);

  const columns = [
    {
      key: 'name',
      title: 'Name',
      width: 400,
      render: (record: CompanyStateListItem) => record.name,
    },
    {
      key: 'art',
      title: 'Artefacts',
      width: 400,
    },
    {
      key: 'pipe',
      title: 'Pipelines',
      width: 400,
    },
    {
      key: 'edit',
      title: '',
      render: (record: CompanyStateListItem) => {
        const path = `status-list/edit/${record.pk}`;
        return (
          <Button href={path} type={ButtonTypesEnum.link}>
            edit
          </Button>
        );
      },
    },
  ];

  return (
    <div className="status-list-page">
      <div className="status-list__top">
        <Title variant={TitleVariantEnum.h4}>Status lists</Title>
        <Button size="large" type={ButtonTypesEnum.primary} onClick={onOpenModal}>
          New status list
        </Button>
      </div>

      <Table columns={columns} dataSource={companyStateList} pagination={false} />
    </div>
  );
};

export default StatusListPage;
