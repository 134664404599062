import React from 'react';

import Tag from '../../../components/tag';

const TagStories = () => {
  return (
    <div className="flex-col">
      <Tag className="mb_8">Tag</Tag>
      <Tag className="mb_8" color="red">
        Tag
      </Tag>
      <Tag className="mb_8" color="orange">
        Tag
      </Tag>
      <Tag className="mb_8" color="blue">
        Tag
      </Tag>
      <Tag className="mb_8" color="green">
        Tag
      </Tag>
      <Tag className="mb_8" color="black">
        Tag
      </Tag>
    </div>
  );
};

export default TagStories;
