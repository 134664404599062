import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { GET_COMPANY_STATES_LIST } from 'queries/getCompanyStates';
import { CompanyState } from 'types';
import { convertArrToSelectOptions } from 'utils';

export type CompanyStateItem = {
  id: string;
  name: string;
  state_type: CompanyState.state_type;
  created_at: string;
  updated_at: string;
  order: number;
  is_could_close_without_complete_issues: boolean;
};

type CompanyStateData = {
  company_states?: CompanyStateItem[];
};

function useCompanyState(
  companyId?: string | null,
  fetchPolicy?: WatchQueryFetchPolicy,
  statesScope?: string | null, // statesScope can be "ARTEFACT_ISSUE|PROJECT_TASK_STATE"
  stateListVersionId?: number | null
) {
  const { data, loading } = useQuery<CompanyStateData>(GET_COMPANY_STATES_LIST, {
    variables: {
      companyId,
      statesScope,
      stateListVersionId,
    },
    skip: !companyId || !stateListVersionId,
    fetchPolicy: fetchPolicy || 'cache-and-network',
  });

  const companyStatesDropdownCheckbox = data?.company_states?.map((c) => {
    return {
      id: c?.id || '',
      label: c?.name || '',
      value: c?.id || '',
    };
  });

  const companySeparateStatesForSelect = useMemo(() => {
    return (
      data?.company_states?.map((c) => {
        return {
          id: c?.id || '',
          label: c?.name || '',
          value: c?.name || '',
        };
      }) || []
    );
  }, [data?.company_states]);

  const companyStatesForSelect = useMemo(() => {
    return convertArrToSelectOptions<CompanyStateItem>(data?.company_states || []);
  }, [data?.company_states]);

  const companyStateIds: string[] | undefined = useMemo(
    () => data?.company_states?.map((c) => c?.id || ''),
    [data?.company_states]
  );

  return useMemo(() => {
    return {
      companyStates: data?.company_states as CompanyStateItem[],
      companyStatesLoading: loading,
      companyStatesDropdownCheckbox,
      companyStateIds,
      companySeparateStatesForSelect,
      companyStatesForSelect,
    };
  }, [
    companySeparateStatesForSelect,
    companyStateIds,
    companyStatesDropdownCheckbox,
    companyStatesForSelect,
    data?.company_states,
    loading,
  ]);
}

export default useCompanyState;
