import i18next from 'i18next';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { find, findKey, set } from 'lodash';

import { IndicatorCourseElementGroupType } from '../components/indicator/types';
import { CompanyRole, CompanyRolesEx, ProjectMetricsDelayItem } from '../hooks';
import { BusinessRoleEnum, StateType, UserExtended, UserRoleBase } from '../types/entities';

import { durationToString } from './times';
import { checkUserBusinessRole } from './roles';

const defaultHeightMap = (size?: SizeType) => {
  switch (size) {
    case 'xl':
      return 48;
    case 'large':
      return 40;
    case 'middle':
      return 32;
    case 'small':
      return 24;
    default:
      return 32;
  }
};

export const calculateHeight = (hasLabel: boolean, hasForm: boolean, size?: SizeType): number => {
  let defaultHeight = defaultHeightMap(size);
  if (hasLabel) {
    defaultHeight = defaultHeight + 26;
  }
  if (!hasForm) {
    defaultHeight = defaultHeight + 24;
  }
  return defaultHeight;
};

export const getElementStatus = (name?: string | null, status?: StateType) => {
  const statusStr = i18next.t<string>(`courseType.${status}`);

  return `${name}: ${statusStr}`;
};

export const getIndicatorTooltipTitle = (
  type: IndicatorCourseElementGroupType,
  inProgress: number,
  done: number,
  to: number
) => {
  const readyStr = i18next.t<string>('courseType.done');
  const progressStr = i18next.t<string>('courseType.in_progress');
  const planStr = i18next.t<string>('courseType.planned');

  const typeStr = i18next.t<string>(`courseType.${type}`);

  if (done !== to && !inProgress) {
    return `${typeStr}: ${readyStr} ${done}, ${planStr} ${to - done}`;
  }

  if (done !== to && inProgress) {
    return `${typeStr}: ${readyStr} ${done}, ${progressStr} ${inProgress}`;
  }

  if (done === to) {
    return `${typeStr}: ${readyStr} ${done}`;
  }

  if (inProgress === 0 && to) {
    return `${typeStr}: ${readyStr} ${inProgress}, ${planStr} ${to}`;
  }

  return typeStr;
};

export const getViewTimeVideoIssue = (start: number | null, end: number | null): string => {
  if (start && end && start !== end) {
    return `${durationToString(start)}-${durationToString(end)}`;
  }
  return `${durationToString(start)}`;
};

export const hideTableColumn = (columns: any[], key: string) => {
  return columns.filter((col) => col?.key !== key);
};

type UsersKeyInValueArr = {
  [name: string]: UserExtended;
};

export const usersKeyInValue = (users?: UserExtended[]) => {
  const arr: UsersKeyInValueArr[] = [];
  let result: any = {};

  users?.forEach((el) => {
    el.business_roles.forEach((r) => {
      arr.push({
        [`${r.id}`]: el,
      });
    });
  });

  arr.forEach((user) => {
    const key = Object.keys(user)[0] as string;
    const value = Object.values(user)[0] as object;

    if (!(`${key}` in result)) {
      result = {
        ...result,
        [key]: [{ ...value }],
      };
    } else {
      result = {
        ...result,
        [key]: [...result[key], { ...value }],
      };
    }
  });

  return result;
};

const singleChoiceRole = [BusinessRoleEnum.videoProductionManager, BusinessRoleEnum.authorManager];

export const getIsMultipleChoice = (role?: BusinessRoleEnum | null) => {
  return singleChoiceRole.some((el) => el === role);
};

export const getRoleItem = (roleList?: CompanyRole[], roleType?: BusinessRoleEnum) => {
  return roleList?.find((el) => el.role === roleType);
};

export const getFilteredUserExt = (search: string, users?: UserExtended[]): UserExtended[] | undefined => {
  const s = search?.toLowerCase();

  if (s?.length < 2) {
    return users;
  }

  return users?.filter((user: UserExtended) => {
    const arr = [user.user.name, user.user.first_name, user.user.last_name, user.user.email, user.company?.name];
    return arr.some((el) => el?.toLowerCase().indexOf(s) !== -1);
  });
};

export const getCompanyRolesByUserRoles = (
  roles?: CompanyRolesEx[],
  userRoles?: UserRoleBase[]
): CompanyRolesEx[] | undefined => {
  if (checkUserBusinessRole(userRoles, BusinessRoleEnum.executiveManager)) {
    return roles?.map((role) => {
      if (role.role === BusinessRoleEnum.executiveManager) {
        return {
          ...role,
          disabled: true,
        };
      }
      return role;
    });
  }
  if (checkUserBusinessRole(userRoles, BusinessRoleEnum.manager)) {
    return roles?.map((role) => {
      if (role.role === BusinessRoleEnum.executiveManager || role.role === BusinessRoleEnum.manager) {
        return {
          ...role,
          disabled: true,
        };
      }
      return role;
    });
  }
  return roles;
};

const scrollStickyHeader = (targetId: string, containerId?: string, offset?: number) => {
  const yOffset = offset || -70;
  const target = document.getElementById(targetId);
  const container = document.getElementById(containerId || '');

  if (container && target) {
    const y = target?.getBoundingClientRect()?.top + window.scrollY + yOffset;

    container.scrollTo({ top: y, behavior: 'smooth' });
    return;
  }

  if (target) {
    const y = target?.getBoundingClientRect()?.top + window.scrollY + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};

export const executeScroll = (targetId: string, containerId?: string, offset?: number) => {
  scrollStickyHeader(targetId, containerId, offset);
};

export const executeScrollWithDelay = (targetId: string, containerId?: string, offset?: number) => {
  if (targetId) {
    setTimeout(() => scrollStickyHeader(targetId, containerId, offset), 500);
  }
};

export const getUserIdsFromMetrics = (iteration?: ProjectMetricsDelayItem[], deadlines?: ProjectMetricsDelayItem[]) => {
  const userIds: string[] = [];
  iteration?.forEach((el) => {
    if (el.assignee) {
      if (!userIds.find((item) => item === el?.assignee?.id)) {
        userIds.push(el.assignee.id);
      }
    }
  });
  deadlines?.forEach((el) => {
    if (el.assignee) {
      if (!userIds.find((item) => item === el?.assignee?.id)) {
        userIds.push(el.assignee.id);
      }
    }
  });

  return userIds;
};
