import React, { memo, ReactNode } from 'react';

import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

import Button from 'components/button';
import Popconfirm from 'components/popconfirm';
import { ButtonTypesEnum } from 'components/button/types';

import useCommonContext from 'hooks/useCommonContext';

type ImageCardMaskProps = {
  hasPopover?: boolean;
  openConfirm?: boolean;
  downloadLink?: string;
  popoverContent?: ReactNode;
  onShow?: () => void;
  onRemove?: () => void;
  onOpenChange?: () => void;
};

function ImageCardMask({
  hasPopover,
  openConfirm,
  downloadLink,
  popoverContent,
  onOpenChange,
  onRemove,
}: ImageCardMaskProps) {
  const common = useCommonContext();
  return (
    <div className="upload-wall-preview-mask">
      <Button
        className="mr_12"
        type={ButtonTypesEnum.primary}
        href={downloadLink}
        download
        target="_blank"
        icon={<DownloadOutlined />}
      />

      {hasPopover ? (
        <Popconfirm
          okText={common.t<string>('common.yes')}
          cancelText={common.t<string>('common.no')}
          placement="topLeft"
          open={openConfirm}
          onOpenChange={onOpenChange}
          overlayStyle={{ width: '260px' }}
          title={null}
          description={popoverContent}
          onConfirm={onRemove}
        >
          <Button danger icon={<DeleteOutlined style={{ fontSize: 16 }} />} />
        </Popconfirm>
      ) : (
        <Button danger icon={<DeleteOutlined style={{ fontSize: 16 }} />} onClick={onRemove} />
      )}
    </div>
  );
}

export default memo(ImageCardMask);
