enum UsersTypes {
  SET_USERS_DATA = 'USERS/SET_USERS_DATA',
  SET_USER_DATA = 'USERS/SET_USER_DATA',
  CLEAR_USERS_DATA = 'USERS/CLEAR_USERS_DATA',
  SET_BUSINESS_ROLES_DATA = 'USERS/SET_BUSINESS_ROLES_DATA',
  SET_SYSTEM_ROLES_DATA = 'USERS/SET_SYSTEM_ROLES_DATA',
  CLEAR_BUSINESS_ROLES_DATA = 'USERS/CLEAR_BUSINESS_ROLES_DATA',
  CLEAR_SYSTEM_ROLES_DATA = 'USERS/CLEAR_SYSTEM_ROLES_DATA',
}

export default UsersTypes;
